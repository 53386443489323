<template>
  <div class="text-area">
    <div class="text-area__container">
      <div class="row">
        <div class="col textarea-group">
          <label for="textarea" class="textarea-group--label"
            >{{ label }}
          </label>
          <textarea
            required
            name="textarea"
            class="textarea-group--textarea"
            placeholder=" "
            v-model="data"
          >
          </textarea>
          <Error v-if="v$.data.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'data'">
                Please provide notes
              </small>
            </p>
          </Error>
        </div>
      </div>
    </div>
    <hr class="my-3" />
    <div class="text-area__button-section">
      <div class="row justify-content-end">
        <div class="col-sm-2 col-6">
          <BtnPrimary
            class="btn--primary ml-3 align-self-end"
            :buttonName="`Submit`"
            @click="submit()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import Error from "@/components/Error.vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

// import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  components: {
    BtnPrimary,
    Error,
    // BtnSecondary
  },
  data() {
    return {
      data: "",
      label: "Notes",
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      data: { required },
    };
  },
  methods: {
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      let param = {
        storeData: this.$store.state.borrower,
        triggerdCommandName: this.$store.getters.commandname,
        applicationID: this.$store.getters.applicationID,
        selectedProgramInvitationID: this.$store.getters.programInvitationID,
        transitionComments: this.data,
        affiliateID: this.$store.getters.affiliateID,
        ein: this.$store.getters.ein,
        businessName: this.$store.getters.businessName,
      };
      if (isFormCorrect) {
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("SaveApplication", param, { root: true })
          .then((res) => {
            
            this.$store.dispatch("updateLoaderFlag", false);
            if (
              this.$store.state.borrower.applicantData.applicationStatus ==
              "Requested More Information"
            ) {
              if (res.data.isSuccess === true) {
                this.toasterMessage("", res.data.message, "success");
                this.$router.push("/tracks");

                 /** Get workflow command and button for submit loan application */
            this.$store
              .dispatch("getWorkFlowCommand", this.selectedLoanApplicationID, {
                root: true,
              })
              .then((workflowCommand) => {
                this.workFlowCommandList = workflowCommand.data;
                // this.workflowCommandForReviewtab.buttonName = workflowCommand.data.workFlowCommands[0].name;
              })
              .catch((err) => {
                this.$store.dispatch("updateLoaderFlag", false);
                Promise.reject(err);
              });
              } else if (
                res.data.isSuccess === false &&
                res.data.invalidInput === true
              ) {
                this.toasterMessage("", res.data.message, "danger");
                if (res.data.validationErrors.length > 0) {
                  res.data.validationErrors.forEach((validationError) => {
                    this.toasterMessage("", validationError, "danger");
                  });
                }
              } else {
                this.toasterMessage("", res.data.message, "danger");
              }
            }
            // this.toasterMessage('','Successfully submitted grant application','success')
            else{
              if (res.data.isSuccess === true){
                this.toasterMessage("", res.data.message, "success");
            this.$router.push("/tracks");
              }
              else{
                this.toasterMessage("", res.data.message, "danger");
            }
            }
           
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.reject(err);
            // this.toasterMessage('','Failed to save grant application','danger')
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.text-area {
  padding-right: 10%;//42.7rem;
  &__container {
    .row {
      margin: 0;
    }
    .textarea-group {
      &--label,
      &--textarea {
        font-size: 1.6rem;
        color: $primary-color-black;
        line-height: 2.2rem;
        padding: 1rem;
        width: 100%;
        outline: none;
      }
      &--textarea {
        height: 20rem;
        border: $primary-color-black;
        box-shadow: 0px 0.01rem 0.3rem;
        border-radius: 0.3rem;
      }
      &--label {
        font-weight: bold;
        padding: 1rem 0rem;
        color: $primary-color-black;
      }
    }
  }
}
</style>
