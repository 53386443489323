import axios from "axios";
import { Number } from "core-js";

const state = {
  IsPaymentRequested:false,
  isFromProgramInvitationTOProfile: false,
  businessId: 0,
  programId:null,
  businessEntityId:null,
  isFundingApp: false,
  isBusinessProfile: false,
  isApplicationDocuments: false,
  isCfoORControllerAcceptByRMI: false,
  applicantData: {},
  applicationID: 0,
  programInvitationID: 0,
  showFundingEntityLogo: false,
  showProgramLogo: false,
  programLogoSource: "",
  fundingEntityLogoSource: "",
  businessData: {
    inputs: {
      businessName: "",
      dba: "",
      address: "",
      emailAddress: "",
      phoneNumber: "",
      url: "",
      zip: "",
      city: "",
      ein: "",
      duns: "",
      Affiliate: "",
      startDate: "",
      numberOfYearsInBusiness: 0,
      employeeStrength: "",
      bankAccountNumber: "",
      bankRoutingNumber: "",
      comment: "",
      naicS_ID: null,
      stateID: null,
      industryTypeID: null,
      businessTypeID: null,
      siC_ID: null,
      naicsCode: null,
    },
  },
  ownersData: [
    {
      businessOwnerName: "",
      ownedPercentage: "",
      gender: {
        id: Number,
        value: "",
      },
      race: {
        id: Number,
        value: "",
      },
      ethnicity: {
        id: Number,
        value: "",
      },
      veteran: {
        id: Number,
        value: "",
      },
      emailAddress: "",
      phoneNumber: "",
      id: "",
    },
  ],
  fundingData: {
    inputs: {
      BusinessName: "",
      FundingProgram: "",
      FundingSource: "",
      UseOfFunds: "",
      hasDefaultFundAmount: true,
      requestedFundAmount: "",
      purpose: "",
      maximumFundAmount: "",
      minimumFundAmount: "",
      isbankruptcyProtection: "",
      isAppliedBankLoan: "",
      isAnyOWnerBankruptcy: "",
      isAtleast51percentBlackOwned: "",
      isApplicantAtleast25percentBlack: "",
      isApplicantUScitizen: "",
      SPADocumentTypeID: 0,
      isPaymentSchedule:false,
    },
  },
  documentData: {
    inputs: {
      FormW9: {
        documentTypeID: 2,
        fileName: "",
        documentGUID: "",
        physicalFileStorageKey: "",
        applicationDocumentID: 0,
      },
      ProofOfOwnership: {
        documentTypeID: 3,
        fileName: "",
        documentGUID: "",
        physicalFileStorageKey: "",
        applicationDocumentID: 0,
      },
      ACHVendorForm: {
        documentTypeID: 4,
        fileName: "",
        documentGUID: "",
        physicalFileStorageKey: "",
        applicationDocumentID: 0,
      },
      InvoiceLetterhead: {
        documentTypeID: 5,
        fileName: "",
        documentGUID: "",
        physicalFileStorageKey: "",
        applicationDocumentID: 0,
      },
      FilesOthers: [],
    },
    ProgressReportDocument:[],
  },
  programDocumentData: [],
  AccountDisbursedInformation : [],
  pendingTransactionDetails:[],
  uploadedProgramDocumentData: [],
  ApplicationDocumentsData: [],
  actionProgress: 0,
  uploadedDocuments: [],
  prePopulatedData: {
    businessTypeList: [],
    ethnicityList: [],
    genderList: [],
    industryTypeList: [],
    raceList: [],
    stateList: [],
    veteranList: [],
    programQuestions: [],
    programDocuments: [],
  },
  commandName: "",
  fundingDetails: {
    FundedAmount: "",
    DateOfFunding: "",
    AccountNumber: "",
    RoutingNumber: "",
    OriginatingBankAccount: "",
    DestinationBankAccount: "",
    BankRoutingNumber: "",
    AddNotes: "",
    document: {
      documentTypeID: 7,
      fileName: "",
      documentGUID: "00000000-0000-0000-0000-000000000000",
      physicalFileStorageKey: "",
    },
    paymentScheduleSummary: {
      businessID: 0,
      fundAllocatedAmount: 0,
      fundDisbursedAmount: 0,
      fundPendingAmount: 0,
      fundRequestedAmount: 0,
      id: 0,
      loanApplicationID: 0,
      programID: 0,
    },
    paymentScheduleTransaction: [
      // {
      //   // businessID: 6
      //   // fundedAmount: 100000
      //   // fundedAmountFormat: "$ 100,000"
      //   // fundingTypeID: 0
      //   // loanApplicationID: 364
      //   // paymentScheduleID: 58
      //   // programID: 228
      //   // transactionDate: "0001-01-01T00:00:00"
      //   // transactionStatusID: 1
      // },
    ],
  },
  businessProfileData: {
    businessEntity: [],
    businessInputs: {
      businessName: "",
      dba: "",
      address: "",
      emailAddress: "",
      phoneNumber: "",
      url: "",
      zip: "",
      city: "",
      ein: "",
      duns: "",
      affiliateName: "",
      startDate: "",
      numberOfYearsInBusiness: 0,
      employeeStrength: "",
      bankAccountNumber: "",
      bankRoutingNumber: "",
      comment: "",
      naicS_ID: null,
      stateID: null,
      industryTypeID: null,
      businessTypeID: null,
      siC_ID: null,
      naicsCode: null,
    },
    ownersData: [
      {
        businessOwnerName: "",
        ownedPercentage: "",
        gender: {
          id: Number,
          value: "",
        },
        race: {
          id: Number,
          value: "",
        },
        ethnicity: {
          id: Number,
          value: "",
        },
        veteran: {
          id: Number,
          value: "",
        },
        emailAddress: "",
        phoneNumber: "",
        id: "",
      },
    ],
    prePopulatedData: {},
  },
};
const getters = {
  getappicantData: (state) => {
    return state.appicantData;
  },
  getbusinessData: (state) => {
    return state.businessData;
  },
  getfundingData: (state) => {
    return state.fundingData;
  },
  getAccountDisbursedInformation: (state)=> {
    return state.AccountDisbursedInformation;
  },
  getdocumentData: (state) => {
    return state.documentData;
  },
  getownersData: (state) => {
    return state.ownersData;
  },
  getapplicationID: (state) => {
    return state.applicationID;
  },
  getapplicationComments: (state) => {
    return state.commandName;
  },
  getaffiliateID: (state) => {
    return state.affiliateID;
  },
};

/** Action code start here */
const actions = {
  getPrePopulatedApplicationData: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ProgramInvitationID: parseInt(payload),
      },
    };
    state.programDocumentData = [];
    state.ApplicationDocumentsData = [];
    // state.documentData.inputs.FilesOthers=[];
    return new Promise((resolve, reject) => {
      axios
        .get("/LoanApplication/GetPrePopulatedApplicationData", config)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.isSuccess === false) {
              resolve(res);
            }
            /** store required data in store */
            const logoInfo = {
              showFundingEntityLogo: res.data.showFundingEntityLogo,
              showProgramLogo: res.data.showProgramLogo,
              programLogoSource: res.data.programLogoSource,
              fundingEntityLogoSource: res.data.fundingEntityLogoSource,
            };

            const businessProfileData = {
              businessName: res.data.businessName,
              ein: res.data.ein,
              Affiliate: res.data.affiliate,
            };
            const fundingApplicationData = {
              BusinessName: res.data.businessName,
              FundingSource: res.data.fundingSource,
              FundingProgram: res.data.programName,
              hasDefaultFundAmount: res.data.hasDefaultFundAmount,
              maximumFundAmount: res.data.maximumFundAmount,
              minimumFundAmount: res.data.minimumFundAmount,
              requestedFundAmount: res.data.requestedFundAmount,
              SPADocumentTypeID : res.data.progressReportId
            };  
            state.fundingData.inputs.SPADocumentTypeID =   res.data.progressReportId;        
            let programquestions = res.data.programQuestions;
            if (state.applicationID > 0) {
              programquestions = state.fundingData.inputs.programQuestions;
            }
            let prePopulated = {
              businessTypeList: res.data.businessTypeList,
              ethnicityList: res.data.ethnicityList,
              genderList: res.data.genderList,
              industryTypeList: res.data.industryTypeList,
              raceList: res.data.raceList,
              stateList: res.data.stateList,
              veteranList: res.data.veteranList,
              programQuestions: programquestions,
            };
            // update programDocuments from prePopulated data

            let ApplicationDocumentsData = state.ApplicationDocumentsData;
            if (state.ApplicationDocumentsData.length > 0) {
              state.programDocumentData = state.ApplicationDocumentsData;
            if(state.applicantData.length!==undefined){
              if(state.applicantData.applicationDocuments.length>0 || state.applicantData.applicationDocuments !==undefined || state.applicantData.applicationDocuments!==null){
            vueContext.commit("mutateApplicationDocumentsData", state.applicantData.applicationDocuments);
            }
            }

            } 
            else {
              if (res.data.programDocuments.length > 0) 
              {
                prePopulated.programDocuments = res.data.programDocuments;
                let programDoc = res.data.programDocuments;
                programDoc.forEach((documentData) => {
                  if (documentData.isActive == true) {
                    let docData = {
                      documentTypeID: documentData.documentID,
                      documentName: documentData.documentName,
                      fileName: "",
                      documentGUID: "00000000-0000-0000-0000-000000000000",
                      physicalFileStorageKey: "",
                      fileSize: "",
                      applicationDocumentID: documentData.programDocumentID,
                      isRequired: documentData.isRequired,
                      isActive: documentData.isActive,
                      displayOrder: documentData.displayOrder,
                      documentCategoryID: documentData.documentCategoryID,
                      programID: documentData.programID,
                    };
                    state.programDocumentData.push(docData);
                  }
                });
                if (ApplicationDocumentsData.length > 0) 
                {
                  ApplicationDocumentsData.forEach((documentData) => {
                    let docIdIndex = state.programDocumentData.findIndex(
                      (obj) => obj.documentTypeID == documentData.documentTypeID
                    );

                    if (docIdIndex !== -1) {
                      state.programDocumentData[docIdIndex].documentGUID = documentData.documentGUID;
                      state.programDocumentData[docIdIndex].physicalFileStorageKey = documentData.physicalFileStorageKey;
                      state.programDocumentData[docIdIndex].applicationDocumentID = documentData.applicationDocumentID;
                      state.programDocumentData[docIdIndex].fileName = documentData.fileName;
                    } else {
                      if ((documentData.documentTypeID !== 6) && (documentData.documentTypeID !== state.fundingData.inputs.SPADocumentTypeID))
                        state.programDocumentData.push(documentData);
                    }
                  });
                } else {
                  state.ApplicationDocumentsData = [];
                }
              }
            if(state.applicantData.length!==undefined){
              if(state.applicantData.applicationDocuments.length>0 || state.applicantData.applicationDocuments !==undefined || state.applicantData.applicationDocuments!==null){
            vueContext.commit("mutateApplicationDocumentsData", state.applicantData.applicationDocuments);
            }
            }
            }

            if (res.data.loanBusinessDetailMasterPreParam !== null) {
              vueContext.commit("mutatebusinessData",res.data.loanBusinessDetailMasterPreParam);
            }
            if (res.data.businessOwnerMasterParam !== null) {
              vueContext.commit("mutateownersData",res.data.businessOwnerMasterParam);
            }
            
            vueContext.commit("mutatebusinessData", businessProfileData);
            vueContext.commit("mutatefundingData", fundingApplicationData);
            vueContext.commit("mutatePrePopulatedData", prePopulated);
            vueContext.commit("mutatelogoInfo", logoInfo);
            if(state.applicantData.length!==undefined){
              if(state.applicantData.applicationDocuments.length>0 || state.applicantData.applicationDocuments !==undefined || state.applicantData.applicationDocuments!==null){
            vueContext.commit("mutateApplicationDocumentsData", state.applicantData.applicationDocuments);
            }
            }
            
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  UpdatebusinessData: (vueContext, payload) => {
    vueContext.commit("mutatebusinessData", { payload });
  },
  UpdateappicantData: (vueContext, payload) => {
    vueContext.commit("mutateappicantData", { payload });
  },
  UpdateownersData: (vueContext, payload) => {
    vueContext.commit("mutateownersData", { payload });
  },
  UpdatefundingData: (vueContext, payload) => {
    vueContext.commit("mutatefundingData", { payload });
  },
  UpdatedocumentData: (vueContext, payload) => {
    vueContext.commit("mutatedocumentData", { payload });
  },
//

///Master/GetSPADocument
GetSPADocument: (vueContext, payload) => {
  let token = localStorage.getItem("token");
  let config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      ApplicationId: Number(payload),
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .get("/Master/GetSPADocument", config)
      .then((res) => {
        if (res.status === 200) {
          resolve(res);
        }
      })

      .catch((err) => {
        reject(err);
      });
  });
},

  UploadFile: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    const data = new FormData();
    // add file or files
    data.append("file", payload.file);
    data.append("programInvitationID", 1);
    data.append("documentTypeID", payload.documentTypeID);
    data.append("applicationDocumentID", payload.applicationDocumentID);
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Upload/UploadApplicationDocument", data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200) {
              var documentData = {
                documentID: res.data.documentID,
                documentTypeID: payload.documentTypeID,
                documentName: payload.documentName,
                fileName: res.data.fileName,
                physicalFileStorageKey: res.data.storageKey,
                fileSize: payload.file.size,
                value: payload.count,
                applicationDocumentID: payload.applicationDocumentID,
              };
              vueContext.commit("mutateDocumentsData", documentData);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  getWorkFlowCommand: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        applicationID: Number(payload),
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/LoanApplication/GetWorkFlowCommands", config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })

        .catch((err) => {
          reject(err);
        });
    });
  },

  // /FundingSource/DeleteAllPaymentScheduleTransactionByLoan
  DeleteAllPaymentScheduleTransactionByLoan: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
   
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/FundingSource/DeleteAllPaymentScheduleTransactionByLoan",payload, config)
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },


  SaveApplication: (vueContext, payload) => {
    const businessProfile = payload.storeData.businessData.inputs;
    const businessOwners = payload.storeData.ownersData;
    const fundingData = payload.storeData.fundingData.inputs;
    // const documentsData = payload.storeData.uploadedDocuments;
    const fundUtilization = payload.storeData.fundingDetails;
    let fundingDataRequestedFundAmount = Number(
      String(fundingData.requestedFundAmount).replace(/[^\d]/g, "")
    );
    let param = {
      programInvitationID: Number(payload.selectedProgramInvitationID),
      applicationStatusID: 1,
      BusinessOwners: [],
      applicationDocuments: [],
      fundUtilization: {
        transactionAmount: Number(
          String(fundUtilization.FundedAmount).replace(/[^\d]/g, "")
        ),
        dateofDisbursement: fundUtilization.DateOfFunding,
        comment: fundUtilization.AddNotes,
        OriginatingBankAccount: fundUtilization.OriginatingBankAccount,
        applicationDocument: fundUtilization.document,
        destinationBankAccount: fundUtilization.DestinationBankAccount,
        bankRoutingNumber: fundUtilization.BankRoutingNumber,
      },
      loanBusinessDetails: {
        businessName: payload.businessName,
        businessTypeID: businessProfile.businessTypeID,
        emailAddress: businessProfile.emailAddress,
        phoneNumber: businessProfile.phoneNumber,
        ein: payload.ein,
        dba: businessProfile.dba,
        startDate: businessProfile.startDate,
        industryTypeID: businessProfile.industryTypeID,
        employeeStrength:
          businessProfile.employeeStrength == ""
            ? 0
            : Number(businessProfile.employeeStrength),
        numberOfYearsInBusiness:
          businessProfile.numberOfYearsInBusiness == ""
            ? 0
            : Number(businessProfile.numberOfYearsInBusiness),
        duns: businessProfile.duns,
        affiliateID: payload.affiliateID,
        url: businessProfile.url,
        siC_ID: businessProfile.siC_ID,
        naicS_ID: businessProfile.naicS_ID,
        address: businessProfile.address,
        city: businessProfile.city,
        stateID: businessProfile.stateID,
        zip: businessProfile.zip,
        bankAccountNumber: businessProfile.bankAccountNumber,
        bankRoutingNumber: businessProfile.bankRoutingNumber,
        comment: businessProfile.comment,
        naicsCode: businessProfile.naicsCode,
      },

      fundingApplication: {
        requestedFundAmount:
          fundingDataRequestedFundAmount == ""
            ? 0
            : Number(fundingDataRequestedFundAmount),
        purpose: fundingData.purpose,
        questionResponse: [],
      },
    };
    if (typeof fundingData.programQuestions != "undefined") {
      fundingData.programQuestions.forEach((data) => {
        let questionRes = {
          questionID: data.questionID,
          response: data.response,
          id: fundingData.id,
        };
        param.fundingApplication.questionResponse.push(questionRes);
      });
    } else {
      if (typeof state.prePopulatedData.programQuestions != "undefined") {
        state.prePopulatedData.programQuestions.forEach((data) => {
          let questionRes = {
            questionID: data.questionID,
            response: data.response,
            id: fundingData.id,
          };
          param.fundingApplication.questionResponse.push(questionRes);
        });
      }
    }

    // Add list of business owners
    businessOwners.forEach((businessOwner) => {
      let businessOwnerData = {
        businessOwnerName: businessOwner.businessOwnerName,
        emailAddress: businessOwner.emailAddress,
        phoneNumber: businessOwner.phoneNumber,
        ownedPercentage:
          businessOwner.ownedPercentage == ""
            ? 0
            : Number(businessOwner.ownedPercentage),
        veteranID: businessOwner.veteranID,
        genderID: businessOwner.genderID,
        raceID: businessOwner.raceID,
        ethnicityID: businessOwner.ethnicityID,
        id: businessOwner.id,
      };
      param.BusinessOwners.push(businessOwnerData);
    });
    payload.storeData.programDocumentData.forEach((documentData) => {
      if (
        documentData.physicalFileStorageKey != null ||
        typeof documentData.physicalFileStorageKey != undefined ||
        documentData.physicalFileStorageKey !== ""
      ) {
        let docData = {
          documentGUID: documentData.documentGUID,
          documentTypeID: documentData.documentTypeID,
          documentName: documentData.documentName,
          fileName: documentData.fileName,
          physicalFileStorageKey: documentData.physicalFileStorageKey,
          fileSize: 10,
          IsActive: true,
          applicationDocumentID: documentData.applicationDocumentID,
        };
        if (docData.physicalFileStorageKey) {
          // if(docData.documentTypeID===6){
          //   param.applicationDocuments.others=(docData);
          // }

          param.applicationDocuments.push(docData);
        }
      }
    });
    if (payload.storeData.documentData.inputs.FilesOthers.length > 0) {
      payload.storeData.documentData.inputs.FilesOthers.forEach((otherDoc) => {
        if (
          otherDoc.physicalFileStorageKey !== "" ||
          otherDoc.physicalFileStorageKey !== null ||
          otherDoc.physicalFileStorageKey !== undefined
        ) {
          otherDoc.isActive = true;
          param.applicationDocuments.push(otherDoc);
        }
      });
    }

    if (state.documentData.ProgressReportDocument.length > 0) {
      state.documentData.ProgressReportDocument.forEach((otherDoc) => {
        if (otherDoc.physicalFileStorageKey.length>2) {
          otherDoc.isActive = true;
          param.applicationDocuments.push(otherDoc);
        }
      });
    }
    

    // Add workflow realted data
    param.commandName = payload.triggerdCommandName;
    param.loanApplicationID =
      payload.applicationID == 0 ? state.applicationID : payload.applicationID;
    param.transitionComments = payload.transitionComments;
    param.isFundingApp = payload.isFundingApp;
    param.isBusinessProfile = payload.isBusinessProfile;
    param.isApplicationDocuments = payload.isApplicationDocuments;
    param.isCfoORControllerAcceptByRMI = payload.isCfoORControllerAcceptByRMI;

    if(state.fundingDetails.paymentScheduleSummary!=null){
    param.fundingApplication.paymentScheduleSummary=(state.fundingDetails.paymentScheduleSummary)
    }
    if(state.fundingDetails.paymentScheduleTransaction!=null){
    param.fundingApplication.paymentScheduleTransaction=(state.fundingDetails.paymentScheduleTransaction)
    }
    if(state.fundingData.inputs.isPaymentSchedule==true){
      param.fundingApplication.isPaymentSchedule=state.fundingData.inputs.isPaymentSchedule
      }
    else{
      param.fundingApplication.isPaymentSchedule=false
    }
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("LoanApplication/ApplicationCommandHandler", param, config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit("mutateApplicationID", res.data.id);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  // send the notification to mail
  SendUpdateFundDetailEmailNotifiaction: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        loanApplicationID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/Admin/SendUpdateFundDetailEmailNotifiaction",payload, config)
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //
  RequestedAddFundAllocation: (vueContext, payload) => {
    const businessProfile = payload.storeData.businessData.inputs;
    const businessOwners = payload.storeData.ownersData;
    const fundingData = payload.storeData.fundingData.inputs;
    // const documentsData = payload.storeData.uploadedDocuments;
    const fundUtilization = payload.storeData.fundingDetails;
    let fundingDataRequestedFundAmount = Number(
      String(fundingData.requestedFundAmount).replace(/[^\d]/g, "")
    );
    let param = {
      programInvitationID: Number(payload.selectedProgramInvitationID),
      applicationStatusID: 1,
      BusinessOwners: [],
      applicationDocuments: [],
      fundUtilization: {
        transactionAmount: Number(
          String(fundUtilization.FundedAmount).replace(/[^\d]/g, "")
        ),
        dateofDisbursement: fundUtilization.DateOfFunding,
        comment: fundUtilization.AddNotes,
        OriginatingBankAccount: fundUtilization.OriginatingBankAccount,
        applicationDocument: fundUtilization.document,
        destinationBankAccount: fundUtilization.DestinationBankAccount,
        bankRoutingNumber: fundUtilization.BankRoutingNumber,
      },
      loanBusinessDetails: {
        businessName: payload.businessName,
        businessTypeID: businessProfile.businessTypeID,
        emailAddress: businessProfile.emailAddress,
        phoneNumber: businessProfile.phoneNumber,
        ein: payload.ein,
        dba: businessProfile.dba,
        startDate: businessProfile.startDate,
        industryTypeID: businessProfile.industryTypeID,
        employeeStrength:
          businessProfile.employeeStrength == ""
            ? 0
            : Number(businessProfile.employeeStrength),
        numberOfYearsInBusiness:
          businessProfile.numberOfYearsInBusiness == ""
            ? 0
            : Number(businessProfile.numberOfYearsInBusiness),
        duns: businessProfile.duns,
        affiliateID: payload.affiliateID,
        url: businessProfile.url,
        siC_ID: businessProfile.siC_ID,
        naicS_ID: businessProfile.naicS_ID,
        address: businessProfile.address,
        city: businessProfile.city,
        stateID: businessProfile.stateID,
        zip: businessProfile.zip,
        bankAccountNumber: businessProfile.bankAccountNumber,
        bankRoutingNumber: businessProfile.bankRoutingNumber,
        comment: businessProfile.comment,
        naicsCode: businessProfile.naicsCode,
      },

      fundingApplication: {
        requestedFundAmount:
          fundingDataRequestedFundAmount == ""
            ? 0
            : Number(fundingDataRequestedFundAmount),
        purpose: fundingData.purpose,
        questionResponse: [],
      },
    };
    if (typeof fundingData.programQuestions != "undefined") {
      fundingData.programQuestions.forEach((data) => {
        let questionRes = {
          questionID: data.questionID,
          response: data.response,
          id: fundingData.id,
        };
        param.fundingApplication.questionResponse.push(questionRes);
      });
    } else {
      if (typeof state.prePopulatedData.programQuestions != "undefined") {
        state.prePopulatedData.programQuestions.forEach((data) => {
          let questionRes = {
            questionID: data.questionID,
            response: data.response,
            id: fundingData.id,
          };
          param.fundingApplication.questionResponse.push(questionRes);
        });
      }
    }

    // Add list of business owners
    businessOwners.forEach((businessOwner) => {
      let businessOwnerData = {
        businessOwnerName: businessOwner.businessOwnerName,
        emailAddress: businessOwner.emailAddress,
        phoneNumber: businessOwner.phoneNumber,
        ownedPercentage:
          businessOwner.ownedPercentage == ""
            ? 0
            : Number(businessOwner.ownedPercentage),
        veteranID: businessOwner.veteranID,
        genderID: businessOwner.genderID,
        raceID: businessOwner.raceID,
        ethnicityID: businessOwner.ethnicityID,
        id: businessOwner.id,
      };
      param.BusinessOwners.push(businessOwnerData);
    });
    payload.storeData.programDocumentData.forEach((documentData) => {
      if (
        documentData.physicalFileStorageKey != null ||
        typeof documentData.physicalFileStorageKey != undefined ||
        documentData.physicalFileStorageKey !== ""
      ) {
        let docData = {
          documentGUID: documentData.documentGUID,
          documentTypeID: documentData.documentTypeID,
          documentName: documentData.documentName,
          fileName: documentData.fileName,
          physicalFileStorageKey: documentData.physicalFileStorageKey,
          fileSize: 10,
          IsActive: true,
          applicationDocumentID: documentData.applicationDocumentID,
        };
        if (docData.physicalFileStorageKey) {
          // if(docData.documentTypeID===6){
          //   param.applicationDocuments.others=(docData);
          // }

          param.applicationDocuments.push(docData);
        }
      }
    });
    if (payload.storeData.documentData.inputs.FilesOthers.length > 0) {
      payload.storeData.documentData.inputs.FilesOthers.forEach((otherDoc) => {
        if (
          otherDoc.physicalFileStorageKey !== "" ||
          otherDoc.physicalFileStorageKey !== null ||
          otherDoc.physicalFileStorageKey !== undefined
        ) {
          otherDoc.isActive = true;
          param.applicationDocuments.push(otherDoc);
        }
      });
    }

    if (state.documentData.ProgressReportDocument.length > 0) {
      state.documentData.ProgressReportDocument.forEach((otherDoc) => {
        if (otherDoc.physicalFileStorageKey.length>2) {
          otherDoc.isActive = true;
          param.applicationDocuments.push(otherDoc);
        }
      });
    }
    

    // Add workflow realted data
    param.commandName = payload.triggerdCommandName;
    param.loanApplicationID =
      payload.applicationID == 0 ? state.applicationID : payload.applicationID;
    param.transitionComments = payload.transitionComments;
    param.isFundingApp = payload.isFundingApp;
    param.isBusinessProfile = payload.isBusinessProfile;
    param.isApplicationDocuments = payload.isApplicationDocuments;
    param.isCfoORControllerAcceptByRMI = payload.isCfoORControllerAcceptByRMI;

    if(state.fundingDetails.paymentScheduleSummary!=null){
    param.fundingApplication.paymentScheduleSummary=(state.fundingDetails.paymentScheduleSummary)
    }
    if(state.fundingDetails.paymentScheduleTransaction!=null){
    param.fundingApplication.paymentScheduleTransaction=(state.fundingDetails.paymentScheduleTransaction)
    }
    if(state.fundingData.inputs.isPaymentSchedule==true){
      param.fundingApplication.isPaymentSchedule=state.fundingData.inputs.isPaymentSchedule
      }
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/LoanApplication/RequestedAddFundAllocation", param, config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit("mutateApplicationID", res.data.id);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  SaveBusinessProfileData: (vueContext, payload) => {
    const businessOwners = payload.businessOwnerMasterParam;
    let param = {
      businessOwnerMasterParam: [],
      loanBusinessDetailMasterParam: {
        id: payload.loanBusinessDetailMasterParam.id,
        businessID: payload.loanBusinessDetailMasterParam.businessID,
        businessName: payload.loanBusinessDetailMasterParam.businessName,
        businessTypeID: payload.loanBusinessDetailMasterParam.businessTypeID,
        businessTypeName:
          payload.loanBusinessDetailMasterParam.businessTypeName,
        emailAddress: payload.loanBusinessDetailMasterParam.emailAddress,
        phoneNumber: payload.loanBusinessDetailMasterParam.phoneNumber,
        ein: payload.loanBusinessDetailMasterParam.ein,
        dba: payload.loanBusinessDetailMasterParam.dba,
        startDate: payload.loanBusinessDetailMasterParam.startDate,
        industryTypeID: payload.loanBusinessDetailMasterParam.industryTypeID,
        industryTypeName:
          payload.loanBusinessDetailMasterParam.industryTypeName,
        employeeStrength:
          payload.loanBusinessDetailMasterParam.employeeStrength,
        numberOfYearsInBusiness:
          payload.loanBusinessDetailMasterParam.numberOfYearsInBusiness,
        averageMonthlyPayroll:
          payload.loanBusinessDetailMasterParam.averageMonthlyPayroll,
        duns: payload.loanBusinessDetailMasterParam.duns,
        affiliateID: payload.loanBusinessDetailMasterParam.affiliateID,
        affiliateName: payload.loanBusinessDetailMasterParam.affiliateName,
        url: payload.loanBusinessDetailMasterParam.url,
        siC_ID: payload.loanBusinessDetailMasterParam.siC_ID,
        siC_Name: payload.loanBusinessDetailMasterParam.siC_Name,
        naicS_ID: payload.loanBusinessDetailMasterParam.naicS_ID,
        naicsCode: payload.loanBusinessDetailMasterParam.naicsCode,
        address: payload.loanBusinessDetailMasterParam.address,
        city: payload.loanBusinessDetailMasterParam.city,
        stateID: payload.loanBusinessDetailMasterParam.stateID,
        stateName: payload.loanBusinessDetailMasterParam.stateName,
        zip: payload.loanBusinessDetailMasterParam.zip,
        bankAccountNumber:
          payload.loanBusinessDetailMasterParam.bankAccountNumber,
        bankRoutingNumber:
          payload.loanBusinessDetailMasterParam.bankRoutingNumber,
        comment: payload.loanBusinessDetailMasterParam.comment,
      },
    };
    businessOwners.forEach((businessOwner) => {
      let businessOwnerData = {
        businessID: payload.loanBusinessDetailMasterParam.businessID,
        businessOwnerName: businessOwner.businessOwnerName,
        demographic: businessOwner.demographic,
        emailAddress: businessOwner.emailAddress,
        ethnicityID: businessOwner.ethnicityID,
        // "ethnicityName": businessOwner.ethnicityName,
        genderID: businessOwner.genderID,
        // "genderName": businessOwner.genderName,
        id: businessOwner.id,
        ownedPercentage: businessOwner.ownedPercentage,
        phoneNumber: businessOwner.phoneNumber,
        raceID: businessOwner.raceID,
        // "raceName": businessOwner.raceName,
        veteranID: businessOwner.veteranID,
        // "veteranName": businessOwner.veteranName
      };

      if (
        !(
          businessOwnerData.businessOwnerName == "" &&
          businessOwnerData.demographic == "" &&
          businessOwnerData.businessOwnerName == "" &&
          businessOwnerData.emailAddress == "" &&
          businessOwnerData.ethnicityID == "" &&
          businessOwnerData.genderID == "" &&
          businessOwnerData.ownedPercentage == "" &&
          businessOwnerData.phoneNumber == "" &&
          businessOwnerData.raceID == "" &&
          businessOwnerData.veteranID == ""
        )
      ) {
        param.businessOwnerMasterParam.push(businessOwnerData);
      }
    });
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/LoanApplication/SaveBusinessProfileData", param, config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit("mutateApplicationID", res.data.id);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  clearStore: (vueContext) => {
    vueContext.commit("mutateclearLoanApplicationBusinessData", null);
    vueContext.commit("mutateclearLoanApplicationBusinessOwnerData", null);
    vueContext.commit("mutateclearLoanApplicationFundingData", null);
    vueContext.commit("mutateclearLoanApplicationDocumentData", null);
    vueContext.commit("mutateclearLoanApplicationfundingDetails", null);
  },
  clearBusinessProfileStore: (vueContext) => {
    vueContext.commit("mutateclearBusinessProfileData", null);
    vueContext.commit("mutateclearBusinessProfileOwnerData", null);
  },

  fetchLoanApplicationDetails: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    actions.clearStore(vueContext);
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        applicationID: parseInt(payload.applicationID),
      },
    };
    state.documentData.FilesOthers = [];
    state.documentData.ProgressReportDocument=[]
    state.AccountDisbursedInformation=[]
    state.pendingTransactionDetails=[]
    return new Promise((resolve, reject) => {
      axios
        .get("/LoanApplication/GetLoanApplicationData", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess == true) {
            vueContext.commit("mutatepaymentSchedule", [
              res.data.loanApplication.fundingApplication
                .paymentScheduleSummary,
              res.data.loanApplication.fundingApplication
                .paymentScheduleTransaction,
            ]);
            state.IsPaymentRequested=res.data.loanApplication.isPaymentRequested
            state.programId=res.data.loanApplication.programId
            state.businessEntityId=res.data.loanApplication.businessId
            vueContext.commit("mutatebusinessData",res.data.loanApplication.loanBusinessDetails);
            vueContext.commit( "mutateownersData",res.data.loanApplication.businessOwners);
            vueContext.commit("mutatefundingData",res.data.loanApplication.fundingApplication);
            vueContext.commit("mutateapplicantData", res.data.loanApplication);
            state.fundingData.inputs.SPADocumentTypeID =   res.data.loanApplication.progressReportId;   
            vueContext.commit("mutateApplicationDocumentsData", res.data.loanApplication.applicationDocuments);
            vueContext.commit("mutateApplicationID",res.data.loanApplication.applicationID);
            vueContext.commit("mutateProgramInvitationID", res.data.loanApplication.programInvitationID);
            vueContext.commit( "mutatecommandName", res.data.loanApplication.commandName);
            vueContext.commit( "mutateaffiliateID", res.data.loanApplication.loanBusinessDetails.affiliateID);
            if(res.data.loanApplication.disbursedTransactionDetails.length > 0)
            {
              res.data.loanApplication.disbursedTransactionDetails.forEach(element => {
                state.AccountDisbursedInformation.push(element)
              });
           }
           else{
            state.AccountDisbursedInformation=[]
           }

           if(res.data.loanApplication.pendingTransactionDetails.length > 0)
            {
              res.data.loanApplication.pendingTransactionDetails.forEach(element => {
                state.pendingTransactionDetails.push(element)
              });
           }
           else{
            state.pendingTransactionDetails=[]
           }

            resolve(res);
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //
  GetBusinessProfileData: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    actions.clearStore(vueContext);
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        businessID: parseInt(payload),
      },
    };
    state.documentData.FilesOthers = [];
    state.documentData.ProgressReportDocument=[]
    return new Promise((resolve, reject) => {
      axios
        .get("/Admin/GetBusinessProfileMasterData", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess == true) {
            vueContext.commit(
              "mutatebusinessProfileData",
              res.data.loanBusinessDetailMasterPreParam
            );
            vueContext.commit(
              "mutatebusinessProfileownersData",
              res.data.businessOwnerMasterParam
            );
            vueContext.commit(
              "mutatebusinessProfileprePopulatedData",
              res.data.businessProfileMasterDataEntity
            );
            resolve(res);
          } else {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  GetAllBusinessEntityByUser: (vueContext, payload) => {
    let token = localStorage.getItem("token");

    // actions.clearStore(vueContext);
    let userName = "";
    userName = payload;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        userName: userName,
      },
    };
    state.documentData.FilesOthers = [];
    state.documentData.ProgressReportDocument=[];
    return new Promise((resolve, reject) => {
      axios
        .get("/Admin/GetAllBusinessEntityByUser", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess == true) {
            state.businessProfileData.businessEntity = res.data.businessEntity;
            resolve(res.data);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //Fetch created loan application listing page
  fetchLoanApplications: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let param = {
      length: 10,
      start: 0,
      draw: 10,
      sortBy: "string",
      sortDesc: true,
      search: {
        searchByAttribute: "string",
        value: "string",
        inActive: true,
      },
      order: [
        {
          column: 0,
          name: "string",
          dir: "string",
        },
      ],
      filterParameters: payload,
      //  [
      //   {
      //     key: "string",
      //     value: "string",
      //   },
      // ],
      isColumnFilter: true,
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/LoanApplication/FetchLoanApplications", param, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getProgramInvitationList: (vueContext,payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/Admin/FetchProgramInvitationsByProgram", payload,config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  UpdateInvitation: (vueContext, payload) => {
    state.ApplicationDocumentsData = [];
    console.log(vueContext, payload);
  },
  UpdateFundDetail: (vueContext, payload) => {
    vueContext.commit("mutateFundDetail", { payload });
  },
  Download(vueContext, payload) {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        storageKey: payload.physicalFileStorageKey,
      },
      responseType: "blob",
    };
    if (config) {
      return new Promise((resolve, reject) => {
        axios
          .get("Upload/Download", config)
          .then((res) => {
            if (res.status === 200) {
              let blob = new Blob([res.data], {
                type: res.headers["content-type"],
              });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = payload.fileName;
              link.click();
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  getAgreementData: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        ApplicationId: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Master/GetAgreementData", config)
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  SaveConsent: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return new Promise((resolve, reject) => {
      axios
        .post("Account/SaveConsent", payload, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  },
  exportLoanApplications(vueContext,payload) {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
      
    };
    /*let params = { 
      filterParameters: payload, 
      
      
    }*/
    if (config) {
      return new Promise((resolve, reject) => {
        axios
          .post("LoanApplication/ExportLoanApplications", payload,config)
          .then((res) => {
            if (res.status === 200) {
              let blob = new Blob([res.data], {
                type: res.headers["content-type"],
              });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "Grant Applications";
              link.click();
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  exportProgramInvitationdReport(vueContext,payload) {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
      
    };
    if (config) {
      return new Promise((resolve, reject) => {
        axios
          .post("Admin/ExportProgramInvitations", payload,config)
          .then((res) => {
            if (res.status === 200) {
              let blob = new Blob([res.data], {
                type: res.headers["content-type"],
              });
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "Program Invitations";
              link.click();
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
};
/** Action Code Ends e=here */

const mutations = {
  mutateappicantData: (state, payload) => {
    state.appicantData = payload;
  },
  mutateownersData: (state, payload) => {
    Object.assign(state.ownersData, payload);
  },
  mutatebusinessData: (state, payload) => {
    //Clear all the properties
    Object.assign(state.businessData.inputs, payload); //// important , to update the data of state from payload object
  },
  mutatepaymentSchedule: (state, payload) => {
    if (payload[0] !== null) {
      state.fundingDetails.paymentScheduleSummary=payload[0]
      // Object.assign(state.fundingDetails.paymentScheduleSummary, payload[0]);
    }
    else{
      state.fundingDetails.paymentScheduleSummary={
        businessID: 0,
        fundAllocatedAmount: 0,
        fundDisbursedAmount: 0,
        fundPendingAmount: 0,
        fundRequestedAmount: 0,
        id: 0,
        loanApplicationID: 0,
        programID: 0,
      }
    }
    if (payload[1] !== null) {
      state.fundingDetails.paymentScheduleTransaction=payload[1]
    }
    else{
      state.fundingDetails.paymentScheduleTransaction=[]
    }
  },
  mutatebusinessProfileData: (state, payload) => {
    //Clear all the properties
    Object.assign(state.businessProfileData.businessInputs, payload); //// important , to update the data of state from payload object
  },
  mutatebusinessProfileownersData: (state, payload) => {
    Object.assign(state.businessProfileData.ownersData, payload);
  },
  mutatebusinessProfileprePopulatedData: (state, payload) => {
    Object.assign(state.businessProfileData.prePopulatedData, payload);
  },
  mutatelogoInfo: (state, payload) => {
    (state.showProgramLogo = payload.showProgramLogo),
      (state.programLogoSource = payload.programLogoSource),
      (state.fundingEntityLogoSource = payload.fundingEntityLogoSource),
      (state.showFundingEntityLogo = payload.showFundingEntityLogo);
  },
  mutatefundingData: (state, payload) => {
    Object.assign(state.fundingData.inputs, payload);
    //  state.fundingData=payload
  },
  mutateApplicationID: (state, payload) => {
    state.applicationID = payload;
  },
  mutateProgramInvitationID: (state, payload) => {
    state.programInvitationID = payload;
  },
  mutatecommandName: (state, payload) => {
    state.commandName = payload;
  },
  mutateaffiliateID: (state, payload) => {
    state.affiliateID = payload;
  },

  mutatedocumentData: (state, payload) => {
    //  state.documentData=payload
    Object.assign(state.documentData, payload);
  },

  mutateapplicantData: (state, payload) => {
    //  state.documentData=payload
    Object.assign(state.applicantData, payload);
  },

  mutateProgress(state, progress) {
    state.actionProgress = progress;
  },
  mutateDocumentsData(state, data) {
    if (data.documentTypeID === 6) {
      if (
        !state.uploadedDocuments.some(
          (_data) => _data.value === data.value && _data.documentTypeID === 6
        )
      ) {
        state.uploadedDocuments.push(data);
      } else {
        let i = state.uploadedDocuments.findIndex(
          (_item) => _item.value === data.value && _item.documentTypeID === 6
        );
        if (i > -1) {
          state.uploadedDocuments[i] = data;
        }
      }
    } else {
      if (
        !state.uploadedDocuments.some(
          (_data) => _data.documentTypeID === data.documentTypeID
        )
      ) {
        state.uploadedDocuments.push(data);
      } else {
        let z = state.uploadedDocuments.findIndex(
          (_item) => _item.documentTypeID === data.documentTypeID
        );
        if (z > -1) {
          state.uploadedDocuments[z] = data;
        }
      }
    }
  },
  mutatePrePopulatedData(state, payload) {
    Object.assign(state.prePopulatedData, payload);
  },
  mutateFundDetail(state, payload) {
    Object.assign(state.fundingDetails, payload);
  },
  mutateApplicationDocumentsData(state, payload) {
    state.documentData.inputs.FilesOthers = [];
    state.documentData.ProgressReportDocument=[]
    state.programDocumentData = [];
    let document = [];
    if (payload.length > 0) {
      state.ApplicationDocumentsData = payload;
      payload.forEach((documentData) => {
        if (documentData.documentTypeID === 6) {
          state.documentData.inputs.FilesOthers.push(documentData);
        } 
          else if(documentData.documentTypeID === state.fundingData.inputs.SPADocumentTypeID){
          state.documentData.ProgressReportDocument.push(documentData);
        }
        else {
          document.push(documentData);
        }
      });
      let programDoc = document;
      programDoc.forEach((documentData) => {
        if (documentData.isActive == true) {
          let docData = {
            documentTypeID: documentData.documentID,
            documentName: documentData.documentName,
            fileName: documentData.fileName,
            documentGUID: documentData.documentGUID,
            physicalFileStorageKey: documentData.physicalFileStorageKey,
            fileSize: "",
            applicationDocumentID: documentData.programDocumentID,
            isRequired: documentData.isRequired,
            isActive: documentData.isActive,
            displayOrder: documentData.displayOrder,
            documentCategoryID: documentData.documentCategoryID,
            programID: documentData.programID,
          };
          state.programDocumentData.push(docData);
        }
      });
    } else state.ApplicationDocumentsData = [];
  },
  mutateclearBusinessProfileData: (state) => {
    state.businessProfileData.businessInputs.businessName = null;
    state.businessProfileData.businessInputs.dba = null;
    state.businessProfileData.businessInputs.address = null;
    state.businessProfileData.businessInputs.emailAddress = null;
    state.businessProfileData.businessInputs.phoneNumber = null;
    state.businessProfileData.businessInputs.url = null;
    state.businessProfileData.businessInputs.zip = null;
    state.businessProfileData.businessInputs.city = null;
    state.businessProfileData.businessInputs.ein = null;
    state.businessProfileData.businessInputs.duns = null;
    state.businessProfileData.businessInputs.Affiliate = null;
    state.businessProfileData.businessInputs.startDate = null;
    state.businessProfileData.businessInputs.numberOfYearsInBusiness = null;
    state.businessProfileData.businessInputs.employeeStrength = null;
    state.businessProfileData.businessInputs.bankAccountNumber = null;
    state.businessProfileData.businessInputs.bankRoutingNumber = null;
    state.businessProfileData.businessInputs.comment = null;
    state.businessProfileData.businessInputs.naicS_ID = null;
    state.businessProfileData.businessInputs.stateID = null;
    state.businessProfileData.businessInputs.industryTypeID = null;
    state.businessProfileData.businessInputs.businessTypeID = null;
    state.businessProfileData.businessInputs.siC_ID = null;
    state.businessProfileData.businessInputs.naicsCode = null;
  },
  mutateclearBusinessProfileOwnerData: (state) => {
    state.businessProfileData.ownersData.forEach((element) => {
      element.businessOwnerName = null;
      element.ownedPercentage = null;
      element.genderID = 0;
      element.raceID = 0;
      element.ethnicityID = 0;
      element.veteranID = 0;
      element.raceID = 0;
      element.demographic = null;
      element.emailAddress = null;
      element.phoneNumber = null;
      element.id = 0;
    });
  },

  mutateclearLoanApplicationBusinessData: (state) => {
    state.applicationID = 0;
    state.applicantData.applicationID = 0;
    state.businessData.inputs.businessName = null;
    state.businessData.inputs.dba = null;
    state.businessData.inputs.address = null;
    state.businessData.inputs.emailAddress = null;
    state.businessData.inputs.phoneNumber = null;
    state.businessData.inputs.url = null;
    state.businessData.inputs.zip = null;
    state.businessData.inputs.city = null;
    state.businessData.inputs.ein = null;
    state.businessData.inputs.duns = null;
    state.businessData.inputs.Affiliate = null;
    state.businessData.inputs.startDate = null;
    state.businessData.inputs.numberOfYearsInBusiness = null;
    state.businessData.inputs.employeeStrength = null;
    state.businessData.inputs.bankAccountNumber = null;
    state.businessData.inputs.bankRoutingNumber = null;
    state.businessData.inputs.comment = null;
    state.businessData.inputs.naicS_ID = null;
    state.businessData.inputs.stateID = null;
    state.businessData.inputs.industryTypeID = null;
    state.businessData.inputs.businessTypeID = null;
    state.businessData.inputs.siC_ID = null;
    state.businessData.inputs.naicsCode = null;
  },
  mutateclearLoanApplicationBusinessOwnerData: (state) => {
    state.ownersData.forEach((element) => {
      element.businessOwnerName = null;
      element.ownedPercentage = null;
      element.genderID = 0;
      element.raceID = 0;
      element.ethnicityID = 0;
      element.veteranID = 0;
      element.raceID = 0;
      element.demographic = null;
      element.emailAddress = null;
      element.phoneNumber = null;
      element.id = 0;
    });
  },
  mutateclearLoanApplicationFundingData: (state) => {
    state.fundingData.inputs.UseOfFunds = null;
    state.fundingData.inputs.purpose = null;
    state.fundingData.inputs.isbankruptcyProtection = null;
    state.fundingData.inputs.isAppliedBankLoan = null;
    state.fundingData.inputs.isAnyOWnerBankruptcy = null;
    state.fundingData.inputs.isAtleast51percentBlackOwned = null;
    state.fundingData.inputs.isApplicantAtleast25percentBlack = null;
    state.fundingData.inputs.isApplicantUScitizen = null;
  },
  mutateclearLoanApplicationDocumentData: (state) => {
    state.documentData.inputs.FormW9.fileName = null;
    state.documentData.inputs.FormW9.documentGUID = null;
    state.documentData.inputs.FormW9.physicalFileStorageKey = null;
    state.documentData.inputs.FormW9.applicationDocumentID = 0;

    state.documentData.inputs.ProofOfOwnership.fileName = null;
    state.documentData.inputs.ProofOfOwnership.documentGUID = null;
    state.documentData.inputs.ProofOfOwnership.physicalFileStorageKey = null;
    state.documentData.inputs.ProofOfOwnership.applicationDocumentID = 0;

    state.documentData.inputs.ACHVendorForm.fileName = null;
    state.documentData.inputs.ACHVendorForm.documentGUID = null;
    state.documentData.inputs.ACHVendorForm.physicalFileStorageKey = null;
    state.documentData.inputs.ACHVendorForm.applicationDocumentID = 0;

    // state.documentData.input.FilesOthers.push({
    //   documentName: "",
    //   Value: 0,
    //   fileName: "",
    //   documentGUID: "",
    //   documentTypeID:6,
    //   physicalFileStorageKey:""
    // });
  },
  mutateclearLoanApplicationfundingDetails: (state) => {
    (state.fundingDetails.FundedAmount = ""),
      (state.fundingDetails.DateOfFunding = ""),
      (state.fundingDetails.AccountNumber = ""),
      (state.fundingDetails.RoutingNumber = ""),
      (state.fundingDetails.OriginatingBankAccount = ""),
      (state.fundingDetails.DestinationBankAccount = ""),
      (state.fundingDetails.BankRoutingNumber = ""),
      (state.fundingDetails.AddNotes = ""),
      (state.fundingDetails.document = {
        documentTypeID: 7,
        fileName: "",
        documentGUID: "00000000-0000-0000-0000-000000000000",
        physicalFileStorageKey: "",
      });
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
