<template>
  <div class="fundingSource">
    <FundListing 
    class="listingTable"
    :items="items"
    :columns= "columns"
    @selectRow="fetchFundingSource"
    @addFundSourceFromChild="addFundSourceToParent()"
    :perPage="itemsPerTable"/>
  </div>
</template>
<script>
import textStore from "@/textStore.js";
import FundListing from "@/components/FundSourceTracking/FundListing.vue"
export default {
  components:{
    FundListing
  },
  setup() {
    // let labels = fundSource.entity
    return {
      //   labels
      textStore,
    };
  },
  data() {
    return {
      labels: {},
      columns:["Program Name","Funding Type","Intial Fund","Available Limit","Utilized Amount","Total Funded Amount"],
      itemsPerTable:5,
    };
  },
    methods: {
      addFundSourceToParent(){
        this.$emit('addFundSource')
      },
    fetchFundingSource(sourceID) {
      if(this.userRoleID !=8){
        if (sourceID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store.dispatch("fetchFundingSource", sourceID)
          .then((res) => {
            this.$emit('edit')
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.reject(err);
          });
      }
      }
      
    },
    createNewEntity() {
      this.$router.push("/funding/form");
    },
  },

  computed: {
    inputs: {
      get() {
        Object.assign(this.labels, textStore.fundSource.source);
        return this.$store.state.fundSource.fundingSource;
      },
    },
    items: {
      get() {
        return this.$store.state.fundSource.fundingEntity.fundingSources;
      },
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.fundingSource {
  height: 100%;
  overflow-y: auto;
  padding-top: .1rem;
  .row {
    padding-right:  2%;
    margin: 0;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>
