<template>
    <div class="questions pr-1">
        <p class=" px-1 mx-1 add-router">
              <router-link  to="/questionsLists">
                Add Questions to list
              </router-link>(Add new, Edit)
            </p>
        <div class="row m-0 d-flex align-items-center questions-header px-0 pb-3">
            <div class="heading col-8">
                <h3>Select Questions</h3>
            </div>
            <div class="text col-4 d-flex justify-content-end px-0">
                <p class="text-data mr-4" v-if="(documentDatas.filter(x=> (x.isActive==true)).length>0)">
                    is Mandatory ?
                </p>
            </div>
        </div>
    <div class=" questions-data px-1 "
      v-for="documentData in documentDatas"
      :key="documentData.questionID">
        <DataWithRadioAndYesOrNO
        :titleData="documentData.questionText"
        :isActive="documentData.isActive"
        :isMandatory="documentData.isMandatory"
        :uniqueId="documentData.questionID"
        @updateData="getUpdateData($event)"
         />
    </div>
        
    </div>
</template>
<script>
import DataWithRadioAndYesOrNO from '@/components/DataWithRadioAndYesOrNO'
export default {
    data(){
        return{
            documentDatas:[],
            programDoc:[]
        }
    },
    components:{
        DataWithRadioAndYesOrNO
    },
    created() {
        let id =this.$store.state.fundSource.fundingSource.fundingSourceID
    this.$store
      .dispatch("GetProgramQuestions",id)
      .then((res) => {
        this.documentDatas=res
      })
      .catch((err) => {
        Promise.reject(err);
      });
  },
  computed:{
      documentData:{
         get(){
          return this.documentDatas
      } 
      }
      
  },
  methods:{
      getUpdateData(data){
         let index = this.documentDatas.findIndex((e) => e.questionID === data.id);
        if (index >-1) {        

         this.documentDatas[index].isActive=data.CheckButtonData;
         this.documentDatas[index].isMandatory=data.RadioButtonData;
        }
      },
      saveQuestion(){
          this.$store.state.fundSource.program.programQuestions=this.documentDatas;
          this.$store.state.fundSource.fundingSource.fundingSourceID
          let selectedDoc= this.documentDatas.filter(x => x.isActive===true);
           
this.programDoc=[];

selectedDoc.forEach(element => {
    this.programDoc.push({
                    "programQuestionID": element.programQuestionID,
                    "questionID": element.questionID ,
                    "isMandatory": element.isMandatory,
                    "displayOrder": 0,
                    "isDeleted": true
                })
});

data
let id =this.$store.state.fundSource.fundingSource.fundingSourceID
          let data={
        "programID": id,
  "programQuestions":this.programDoc
      }
          this.$store.dispatch("updateLoaderFlag", true);
            this.$store
              .dispatch("SaveOrUpdateProgramQuestions", data)
              .then((res)=>{
                  
                    this.$store
                    .dispatch("GetProgramQuestions",id)
                    .then((res) => {
                        this.documentDatas=res
                    })
                    this.toasterMessage('',res.data.message,'success')
                  this.$store.dispatch("updateLoaderFlag", false);
              })
              .catch((err)=>{
                  console.log(err)
                  this.toasterMessage('','Failed to save','danger')
                  this.$store.dispatch("updateLoaderFlag", false);
              })
      }
  }
}
</script>
<style lang="scss" scoped>
.questions{
    color: $primary-color-black;
 .text-data{
    // overflow-x: hidden;
    font-size: 1.6rem;
    padding: 0rem;
    margin: 0rem;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
 }
 .add-router{
     cursor: pointer;
    font-size: 1.6rem;
 }
}
</style>