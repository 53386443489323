<template>
  <div class="table-view">
    <div class="table-view__header d-flex justify-content-between  align-items-center m-0 p-0">
      <div class="table-view__header--left">
        <div class="table-heading">
          <h3>Funding Entities</h3>
        </div>
        <div class="sort">
        </div>
      </div>
      <div class="table-view__header--right p-0 col d-flex justify-content-end">
        <div class="search">
          <div class="d-flex  flex-wrap ">
            <label for="search" class="ml-1 d-flex align-items-center search-by-filter" >Search&nbsp;By:
              <span class="search-by-filter__select">
                <select
            class="search-group search-by-filter__select mx-sm-2 mx-0 px-1 my-1"
            placeholder=" "
            name="search-group"
            id="search-group"
            v-model="selectOptionToFilter"
          >
            <option selected value="all">
              <p>All</p>
            </option>
            <option value="fundingEntityName">
              <p>Funding Entity</p>
            </option>
            <option value="city">
              <p>City</p>
            </option>
            <option value="state">
              <p>State</p>
            </option>
            <option value="ein">
              <p>EIN</p>
            </option>
            <option value="tin">
              <p>TIN</p>
            </option>
          </select>
              </span>
          
          </label>
          </div>
          <span class=" mx-1 d-flex search-by-input ">
          <input
          class="search-by-input__input"
            type="text"
            name="searchByKey"
            id="searchByKey"
            v-model="filter"
            placeholder="Search here"
          />
          <button class="reset-button" @click="ResetSort">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
             </span>
          <div>
               
          </div>
        </div>
      </div>
    </div>
    <div class="table-view__table">
      <table class="table">
        <thead>
          <tr>
            <th @click="Sort('fundingEntityName')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='fundingEntityName'?'show-icon':''">
                <p class="tabel-header__title">Funding Entity</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('city')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='city'?'show-icon':''">
                <p class="tabel-header__title">City</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('state')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='state'?'show-icon':''">
                <p class="tabel-header__title">State</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('ein')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='ein'?'show-icon':''">
                <p class="tabel-header__title">EIN</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('tin')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='tin'?'show-icon':''">
                <p class="tabel-header__title">TIN</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="item in displayedItems()"
            :key="item"
            @click="selectId(item.fundingEntityID)"
          >
            <td>
              <p v-html="highlightMatches(item['fundingEntityName'])"></p>
            </td>
            <td>
              <p v-html="highlightMatches(item['city'])"></p>
            </td>
            <td>
              <p v-html="highlightMatches(item['state'])"></p>
            </td>
             <td>
               <p v-html="highlightMatches(item['ein'])"></p>
            </td>
             <td>
               <p v-html="highlightMatches(item['tin'])"></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Pagination from "@/components/Pagination.vue"
export default {
  components: {
    // Pagination
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    TableHeight: {
      type: Number,
    },
    columns: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      filter: "",
      list: this.items,
      unsort:false,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages: [],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
    };
  },
  beforeUpdate() {
    this.list = this.items;
  },
  methods: {
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;
    },
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter);
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    selectId(entityID) {
      this.$emit("selectRow", entityID);
    },
    Sort(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate(records) {
      if(!records)
        return [];
      let page = this.values.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      this.values.totalPages = Math.ceil(records.length / this.perPage);
      this.values.totalPages = Math.ceil(
        this.filteredRows.length / this.perPage
      );
      return records.slice(from, to);
    },
    totalRecords(){
      if(!this.filteredRows)
        return "";
      return this.filteredRows.length;
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
    displayedItems () {
			return this.paginate(this.filteredRows);
		}
  },
  computed: {
    setTableHeight() {
      return "height: " + this.TableHeight + "vh";
    },
    sortTable() {
      let lists = this.list;
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        const fundingEntityName = row.fundingEntityName.toString().toLowerCase();
        const city = row.city.toString().toLowerCase();
        const state = row.state.toString().toLowerCase();
        const ein = row.ein.toLowerCase();
        const tin = row.tin.toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            fundingEntityName.includes(searchTerm) ||
            city.includes(searchTerm) ||
            state.includes(searchTerm) ||
            ein.includes(searchTerm) ||
            tin.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "fundingEntityName") {
          filtedItems = fundingEntityName.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "city") {
          filtedItems = city.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "state") {
          filtedItems = state.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "ein") {
          filtedItems = ein.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "tin") {
          filtedItems = tin.includes(searchTerm); 
        }
        return filtedItems;
      });
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
  @include table-style;
</style>
