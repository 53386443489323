<template>
  <section class="FundingDetailsTab">
    <div class="FundingDetailsTab__main">
      <div class="row m-0 d-flex justify-content-between">
        <div class="col-sm-3  col-md-3 col-12 form-group " v-if="LoanProgram.length>0">
          <select
            name="LoanNumber"
            id="LoanNumber"
            v-model="LoanNumber"
            @change="ChangeProgram(LoanNumber)"
          >
            <option
              v-for="Loan in LoanProgram"
              :key="Loan.key"
              :value="Loan.key"
            >
              {{ Loan.values }}
            </option>
          </select>
          <label class="form__label" for="LoanNumber"> {{ labels.LoanNumber }}</label>
        </div>
        <div class=" col-sm-3 col-12 form-group">
          <input
            type="text"
            placeholder=""
            id="fundDetail-ProgramNameValue"
            v-model="inputs.programName"
            disabled
          />
          <label for="fundDetail-ProgramNameValue" class="form__label ">
            {{ labels.ProgramName }}
          </label>
        </div>
        <div class="form-file col-sm-6 col-12 row m-0 p-0 pb-5 d-flex justify-content-between align-items-center">
          <label
            class="form__label form-file__label  col-sm-5 col-3 pl-0"
          >
            {{ labels.Agreement }}
          </label>
          <input
            :disabled="isDisableForAgreement"
            :readonly="isDisableForAgreement"
            ref="fileInput"
             class="form__label form-file__label  col-sm-2 col-2 px-1"
            type="file"
            name="fundDetail-document"
            id="fundDetail-document"
            @input="inputs.AgreementDocument"
            v-bind="inputs.AgreementDocument.documentTypeID"
            @change="showPreview($event, inputs.AgreementDocument)"
          />
          <p
            
            :href="inputs.AgreementDocument.fileName"
            class="downloadLink ml-2 p-0 m-0 col-sm-3 col-3"
            @click.prevent="download(inputs.AgreementDocument)"
          >
            {{ AgreementDocumentFileName }}
          </p>
        </div>
      </div>
      <div class="row  m-0">
        <!--<div class=" col-sm-3 col-12 form-group">
          <input
            type="text"
            v-money3="config"
            placeholder=""
            id="fundDetail-fundRequestedAmount"
            v-model="inputs.fundRequestedAmount"
            :disabled="isDisable"
            :readonly="isDisable"
          />
          <label for="fundDetail-fundRequestedAmount" class="form__label ">
            {{ labels.FundRequested }}
          </label>
        </div>-->
        <div class=" col-sm-3 col-12 form-group">
          <input
            required
            type="text"
            v-money3="config"
            placeholder=""
            id="fundDetail-fundAllocatedAmount"
            v-model="inputs.fundAllocatedAmount"
          />
          <label
            for="fundDetail-fundAllocatedAmount"
            class="form__label required"
          >
            {{ labels.FundAllocated }}
          </label>
        </div>
        <div class=" col-sm-3 col-12 form-group">
          <input
            type="text"
            v-money3="config"
            placeholder=""
            id="fundDetail-fundDisbursedAmount"
            v-model="inputs.fundDisbursedAmount"
            :disabled="isDisable"
            :readonly="isDisable"
          />
          <label for="fundDetail-fundDisbursedAmount" class="form__label ">
            {{ labels.FundDisbursed }}
          </label>
        </div>
        <div class=" col-sm-3 col-12 form-group">
          <input
            type="text"
            v-money3="config"
            placeholder=""
            id="fundDetail-fundPendingAmount"
            v-model="inputs.fundPendingAmount"
            :disabled="isDisable"
            :readonly="isDisable"
          />
          <label for="fundDetail-fundPendingAmount" class="form__label ">
            {{ labels.PendingDisbursement }}
          </label>
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 p-0 m-0">
          <div
            class="paymentScheduleHeading py-3 d-flex"
            
          >
            <h3
            class="d-flex"
              @click="addPaymentSchedule()"
              v-if="isAllDisbursed == false"
              v-show=" userRoleID == 1 || userRoleID == 4 || userRoleID == 6 || userRoleID == 7" >
              Payment&nbsp;Schedule&nbsp;&nbsp;+
            </h3>
            <h3 
            v-show=" userRoleID == 1 || userRoleID == 4 || userRoleID == 6 || userRoleID == 7"
            v-else
            class="d-flex" >
              Payment&nbsp;Schedule
            </h3>
          </div>
        </div>
        <div class="col-12 p-0 m-0" v-if="PaymentScheduleDataInput.length > 0">
          <div
            v-for="(PaymentScheduleInput, index) in PaymentScheduleDataInput"
            :key="PaymentScheduleInput"
          >
            <br />
            <PaymentSchedule
            v-if="IsShowPaymentSchedule"
              :id="'PaymentSchedule' + index"
              :ref="'PaymentSchedule' + index"
              :counter="index"
              @deletePaymentSchedule="deletePaymentSchedule"
              :inputData="PaymentScheduleInput"
            />
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="TrackingList__table-section"
          v-if="paymentScheduleTransactionTableData.items.length > 0"
        >
          <fundStatusListing
            class="listingTable"
            :items="paymentScheduleTransactionTableData.items"
            :TableHeight="paymentScheduleTransactionTableData.TableHeight"
            :perPage="paymentScheduleTransactionTableData.itemsPerTable"
            @RemovePaymentScheduleTransaction="RemovePaymentScheduleTransaction"
            @deletePaymentScheduleTransaction="deletePaymentScheduleTransaction"
            @GetPaymentScheduleTransactionById="GetPaymentScheduleTransactionById"
            @TiggerManualNotification = "TiggerManualNotification"
          />
        </div>
      </div>
      <div class="additionalNotesAgreement pr-2">
        <br>
        <br>
    <QuillEditor ref="additionalNotesAgreement" theme="snow" :toolbar="toolbarOptions" :disabled=isDisableForAgreement />
</div>
    </div>

    <div
      class="btn--section row  align-items-center justify-content-end mr-2 flex-wrap "
    >
      <span
        class=" "
        v-if="
          (userRoleID == 1 ||  userRoleID == 4 || userRoleID == 3 ||  userRoleID == 7) "
      >
        <BtnPrimary
        v-show="isAllDisbursed == false" 
          :buttonName="`Save`"
          @click="SaveorUpdatePaymentScheduleAndTransaction()"
        />
      </span>
      <span> </span>
    </div>
   <div class="central-popup" v-if="fundavailableLimitValidation.showCentralPopUp == true">
      <div class="central-popup__main  w-50" >
          <span class="close-btn">
            <button @click="fundavailableLimitValidation.showCentralPopUp = false">
              <img src="@/assets/imgs/cancel.svg" alt="close button" />
            </button>
          </span>
          <div>
            <div class="central-popup__main__heading py-0 px-2 m-0">
              <h3>Notification</h3>
            </div>

            <div class="MainDiv row m-2 p-4">
              <div class="col-10 m-2 p-4 mx-auto">
              <p class="large-fontsize"> 
                {{ fundavailableLimitValidation.CentralPopUpMessage }}  
                  
                </p>          
              </div>
            </div>
          </div>
           <div class=" d-flex justify-content-center pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Cancel`"
                    @click="closePopUp()"
                  />
                </span>
              </div>
            </div>
        </div>      
  </div>
  </section>
</template>
<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import PaymentSchedule from "@/components/BusinessForms/FundingDetails/PaymentSchedule.vue";
import fundStatusListing from "@/components/BusinessForms/FundingDetails/fundStatusListing.vue";
import { Money3Directive } from "v-money3";
import { QuillEditor } from '@vueup/vue-quill'

export default {
  data() {
    return {
      businessId: 0,
      ProgramName:'',
      programId: 0,
      LoanNumber:0,
      applicationId:this.LoanNumber,
      labels: {
        ProgramName: "Program Name",
        Agreement: "Supporting Document :",
        FundRequested: "Fund Requested",
        FundAllocated: "Fund Allocated",
        FundDisbursed: "Fund Disbursed",
        PendingDisbursement: "Pending Disbursement",
        LoanNumber:"Grant Number"
      },
      paymentScheduleTransactionTableData: {
        items: [],
        TableHeight: 20,
        perPage: 10,
      },
      content: "<p> </p>",
      toolbarOptions : [
                        ['bold', 'italic', 'underline', 'strike','link'],        // toggled buttons
                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme                                       
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                       // text direction
                        [{ 'align': [] }],
                        ['clean']                                         // remove formatting button
                      ],
      deletePaymentScheduleData:[],
      PaymentScheduleData: {},
      PaymentScheduleDataInput: [],
      IsShowPaymentSchedule:true,      
      isDisableForAgreement:false,
      fundavailableLimitValidation:{
          CentralPopUpMessage:"",
          IsAvailableLimitExceeds:false,
          showCentralPopUp:false,
      },
    };
  },
  directives: {
    money3: Money3Directive,
  },
  props: {
    entity_ID: {
      type: Number,
      default: 0,
    },
    loanApplID:
    {
      type: Number,
      default: null,

    }
  },
  components: {
    BtnPrimary,
    PaymentSchedule,
    fundStatusListing,
    QuillEditor
  },
  computed: {
    editor() {
        return this.$refs.additionalNotesAgreement
      },
    isDisable() {
      return true;
    },
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        disableNegative: true,
        masked: false /* doesn't work with directive */,
      };
    },
    inputs: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness
          .PaymentScheduleSummary;
      },
    },
    programs: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness.ProgramList;
      },
    },
    LoanProgram: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness.LoanProgram;
      },
    },
    isAllDisbursed: {
      get() {
        let isAllDisbursed = false;
        let AllDisbursedLength = 0;
        if (this.PaymentScheduleTransactionList.length > 0) {
          for (let index = 0;index < this.PaymentScheduleTransactionList.length; index++) {
            if (this.PaymentScheduleTransactionList[index].transactionStatusID ==2 ) {
              AllDisbursedLength = AllDisbursedLength + 1;
            }
          }
          if ( AllDisbursedLength == this.PaymentScheduleTransactionList.length) {
            if(this.inputs.fundPendingAmount ==0){
            isAllDisbursed = true;
            }
            else{
            isAllDisbursed = false;
            }
          }
        }

        if ( this.PaymentScheduleTransactionList.length < 0 || this.PaymentScheduleTransactionList == null ) {
          isAllDisbursed = false;
        }

        return isAllDisbursed;
      },
    },
    PaymentScheduleTransactionList: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness
          .PaymentScheduleTransactionList;
      },
    },
    totalPaymentScheduleTransactionAmount: {
      get() {
        let totalAmount = 0;
        let list = this.PaymentScheduleTransactionList;
        for (let i = 0; i < list.length; i++) {
          totalAmount = totalAmount + list[i].fundedAmount;
        }
        return totalAmount;
      },
    },
    fundingDetailsprogramId: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness.programId;
      },
    },
    AgreementDocumentFileName: {
      get() {
        let characterLimit = 9;
        let filename = this.inputs.AgreementDocument.fileName;
        if (filename.length >= characterLimit) {
          filename =
            filename
              .slice(0, filename.length)
              .substring(0, characterLimit - 1)
              .trim() + "...";
        }
        return filename;
      },
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID;
    },
  },
  created() {
    this.businessId = this.entity_ID;
    if (this.entity_ID > 0) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store.state.business.fundingDetailsForBusiness.PaymentScheduleSummary = {
        fundAllocatedAmount: "",
        fundRequestedAmount: "",
        fundDisbursedAmount: "",
        fundPendingAmount: "",
        AgreementDocument: {
          documentID: 0,
          documentTypeID: 1,
          fileName: "",
          documentGUID: "",
          physicalFileStorageKey: "",
          documentName: "",
          fileSize: 0,
          fileSource: "",
        },
      };
      // GetPaymentScheduleLoanNoList
      this.$store.dispatch("GetPaymentScheduleLoanNoList", this.businessId, {root: true, })
      .then((res)=>{
        this.LoanNumber=res.data.masterOptionDetails[0].key
        if(this.LoanNumber>0){
          if(this.loanApplID > 0)
          {
            this.LoanNumber = this.loanApplID;
          }
          
          this.GetPaymentScheduleSummary();
      this.GetPaymentScheduleTransaction();
      this.PaymentScheduleDataInput = [];
      this.deletePaymentScheduleData = [];
        }
        
      })
      .catch((err)=>{
        console.log(err)
      })
      this.deletePaymentScheduleData=[]
    }
  },
  mounted() {},
  methods: {
    clearPaymentScheduleSummary(){
      this.$store.state.business.fundingDetailsForBusiness.PaymentScheduleSummary={
      contactID:'',
      fundPaymentScheduleID:0,
      fundAllocatedAmount: "",
      fundRequestedAmount: "",
      fundDisbursedAmount: "",
      fundPendingAmount: "",
      additionalNotesAgreement:'',
      AgreementDocument: {
        documentID: 0,
        documentTypeID: 1,
        fileName: "",
        documentGUID: "",
        physicalFileStorageKey: "",
        documentName: "",
        fileSize: 0,
        fileUploadedSourceUrl: "",
      },
    }
    },
    async validatePaymentSchedule() {
      let isAllValidatePaymentSchedule;
      for (
        let index = 0;
        index < this.PaymentScheduleDataInput.length;
        index++
      ) {
        const isvalid = await this.$refs[
          "PaymentSchedule" + index
        ].validatePaymentSchedule();
        if (isvalid == false) {
          isAllValidatePaymentSchedule = false;
          break;
        } else isAllValidatePaymentSchedule = true;
      }
      return isAllValidatePaymentSchedule;
    },
    addPaymentSchedule() {
      this.IsShowPaymentSchedule = true;
      this.PaymentScheduleDataInput.push({
        paymentScheduleID:0,
        loanApplicationID:Number(this.LoanNumber) ,
        businessID: Number(this.businessId),
        programID: Number(this.inputs.programID),
        transactionDate: "",
        fundingTypeID: null,
        fundedAmount: null,
        transactionStatusID: 1,
        contactID: Number(this.inputs.contactID),
        isEdit: false,
      });
    },
    deletePaymentSchedule(counter) {
      this.PaymentScheduleDataInput.splice(counter, 1);
    },
    async SaveorUpdatePaymentScheduleAndTransaction() {
      let isValid;
      if (this.PaymentScheduleDataInput.length > 0) {
        isValid = await this.validatePaymentSchedule();
      } else {
        isValid = true;
      }
      if (isValid == true) {
        if (typeof this.inputs.fundAllocatedAmount == String) {
          this.inputs.fundAllocatedAmount.replace(/\D/g, "");
        }
        if (typeof this.inputs.fundRequestedAmount == String) {
          this.inputs.fundRequestedAmount.replace(/\D/g, "");
        }
        if (typeof this.inputs.fundDisbursedAmount == String) {
          this.inputs.fundDisbursedAmount.replace(/\D/g, "");
        }
        if (typeof this.inputs.fundPendingAmount == String) {
          this.inputs.fundPendingAmount.replace(/\D/g, "");
        }

        this.inputs.fundAllocatedAmount = Number(
          String(this.inputs.fundAllocatedAmount).replace(/[^\d]/g, "")
        );
        if (this.inputs.fundAllocatedAmount <= 0) {
          this.toasterMessage(
            "",
            `Fund Allocated should be more than zero`,
            "danger"
          );
          return;
        }
        /*if (this.inputs.fundAllocatedAmount > this.inputs.fundRequestedAmount) {
          this.toasterMessage(
            "",
            `Allocated fund can not be more than requested amount`,
            "danger"
          );
          return;
        }*/
        let totalAmount = 0;
        /////
        for (
          let Listindex = 0;
          Listindex < this.paymentScheduleTransactionTableData.items.length;
          Listindex++
        ) {
          if (
            this.paymentScheduleTransactionTableData.items[Listindex].isEdit ==
            true
          ) {
            //
            totalAmount = totalAmount + 0;
          } else {
            totalAmount =
              totalAmount +
              this.paymentScheduleTransactionTableData.items[Listindex]
                .fundedAmount;
          }
        }
        /////
        let currentPaymentScheduleTotalAmount = 0;
        this.PaymentScheduleDataInput = this.PaymentScheduleDataInput.map(
          ({ isEdit, ...rest }) => {
            console.log(isEdit);
            return rest;
          }
        );
        for (
          let index = 0;
          index < this.PaymentScheduleDataInput.length;
          index++
        ) {
          if (
            typeof this.PaymentScheduleDataInput[index].fundedAmount == String
          ) {
            this.PaymentScheduleDataInput[index].fundedAmount.replace(
              /\D/g,
              ""
            );
            currentPaymentScheduleTotalAmount =
              currentPaymentScheduleTotalAmount +
              this.PaymentScheduleDataInput[index].fundedAmount;
          } else {
            currentPaymentScheduleTotalAmount =
              currentPaymentScheduleTotalAmount +
              this.PaymentScheduleDataInput[index].fundedAmount;
          }
        }
        if (
          currentPaymentScheduleTotalAmount + totalAmount !=
          this.inputs.fundAllocatedAmount
        ) {
          this.toasterMessage(
            "",
            `Fund Allocated should match with total Payment Schedule`,
            "danger"
          );
          return;
        }
        let AllDisbursedLength = 0,
          AllDisbursed = 0;
        if (this.PaymentScheduleTransactionList.length > 0) {
          for (
            let index = 0;
            index < this.PaymentScheduleTransactionList.length;
            index++
          ) {
            if (
              this.PaymentScheduleTransactionList[index].transactionStatusID ==
              2
            ) {
              AllDisbursedLength = AllDisbursedLength + 1;
              AllDisbursed =
                AllDisbursed +
                this.PaymentScheduleTransactionList[index].fundedAmount;
            }
          }
          if (
            AllDisbursedLength == this.PaymentScheduleTransactionList.length &&
            AllDisbursed == this.inputs.fundAllocatedAmount
          ) {
            this.toasterMessage("", `Already amount disbursed`, "danger");
            return;
          }
        }
        if (
          currentPaymentScheduleTotalAmount + totalAmount !=
          this.inputs.fundAllocatedAmount
        ) {
          this.toasterMessage(
            "",
            `Fund Allocated should match with total Payment Schedule`,
            "danger"
          );
          return;
        }
         this.content =this.editor.getHTML();
        this.$store.dispatch("updateLoaderFlag", true);
        let data = {
          fundPaymentScheduleID: this.inputs.fundPaymentScheduleID,
          loanApplicationID: Number(this.LoanNumber),
          businessID: this.businessId,
          programID: Number(this.inputs.programID),
          fundRequestedAmount: Number(this.inputs.fundRequestedAmount),
          fundAllocatedAmount: Number(this.inputs.fundAllocatedAmount),
          fundDisbursedAmount: Number(this.inputs.fundDisbursedAmount),
          fundPendingAmount: Number(this.inputs.fundPendingAmount),
          contactID: this.inputs.contactID,
          documentID: this.inputs.AgreementDocument.documentID,
          documentGUID: this.inputs.AgreementDocument.documentGUID,
          documentTypeID: this.inputs.AgreementDocument.documentTypeID,
          documentName: this.inputs.AgreementDocument.documentName,
          fileName: this.inputs.AgreementDocument.fileName,
          physicalFileStorageKey: this.inputs.AgreementDocument.physicalFileStorageKey,
          fileSize: this.inputs.AgreementDocument.fileSize,
          fileUploadedSourceUrl: this.inputs.AgreementDocument.fileUploadedSourceUrl,
          isPaymentSchedule: true,
          isLoanApplicationFundDetails: true,
          additionalNotesAgreement: this.content,
          paymentScheduleTransParam: this.PaymentScheduleDataInput,
        };
        if (this.deletePaymentScheduleData.length > 0) {
          this.$store.dispatch("updateLoaderFlag", true);
          for (
            let index = 0;
            index < this.deletePaymentScheduleData.length;
            index++
          ) {
            // const element = array[index];
            let item = this.deletePaymentScheduleData[index];

            this.RemovePaymentScheduleTransaction(item);
          }
          this.$store.dispatch("updateLoaderFlag", false);
          // RemovePaymentScheduleTransaction(item)
        }
        this.$store.dispatch("updateLoaderFlag", true);
        this.IsShowPaymentSchedule = false;
        await this.$store
          .dispatch("SaveorUpdatePaymentScheduleAndTransaction", data, {
            root: true,
          })
          .then((res) => {
            if (res.data.isSuccess == true) {
              this.inputs.fundPendingAmount = res.data.fundPendingAmount;
              this.inputs.fundDisbursedAmount = res.data.fundDisbursedAmount;
              // isPaymentSchedule made true to send in Loan application data.
              this.$store.state.borrower.fundingData.inputs.isPaymentSchedule=true;
              this.toasterMessage("", res.data.message, "success");
              this.GetPaymentScheduleSummary();
              this.GetPaymentScheduleTransaction();
              this.deletePaymentScheduleData = [];
              this.PaymentScheduleDataInput = [];
              this.$store.dispatch("updateLoaderFlag", false);
            } else {
              if(res.data.isAvailableLimitExceeds)
              {
                this.fundavailableLimitValidation.isAvailableLimitExceeds = true;
                  this.fundavailableLimitValidation.CentralPopUpMessage = res.data.message;
                  this.fundavailableLimitValidation.showCentralPopUp = true;
                  this.$store.dispatch("updateLoaderFlag", false);
              }
              else
              {
                  this.toasterMessage("", res.data.message, "danger");
                  this.$store.dispatch("updateLoaderFlag", false);
                  this.fundavailableLimitValidation.showCentralPopUp = false;
              }
              
            }
            return Promise.resolve();
          })
          .catch((err) => {
            Promise.reject(err);
            this.$store.dispatch("updateLoaderFlag", false);

            return Promise.reject();
          });
      }
    },

    ChangeProgram(Lnumber) {
      this.LoanNumber=Lnumber;
      this.GetPaymentScheduleSummary();
      this.GetPaymentScheduleTransaction();
      this.PaymentScheduleDataInput = [];
      this.deletePaymentScheduleData = [];
    },
    async FundingSourceGetProgramList() {
      this.$store
        .dispatch("FundingSourceGetProgramList", this.entity_ID)
        .then((res) => {
          if (this.LoanNumber > 0) {
            this.programId = res.data.masterOptionDetails[0].key;
          }
          this.GetPaymentScheduleTransaction();
          this.GetPaymentScheduleSummary();
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.reject(err);
        });
    },
    async GetPaymentScheduleTransactionById(item) {
      const PaymentScheduleData = this.PaymentScheduleDataInput.find(
        (Payment) => Payment.paymentScheduleID === item.paymentScheduleID
      );
      if (PaymentScheduleData == undefined) {
        this.$store.dispatch("updateLoaderFlag", true);
        let data = {
          paymentScheduleID: item.paymentScheduleID,
        };
        await this.$store
          .dispatch("GetPaymentScheduleTransactionById", data, { root: true })
          .then((res) => {
            if (res.data.isSuccess === true) {
              this.$store.dispatch("updateLoaderFlag", false);

              // pop up for
              this.IsShowPaymentSchedule = true;
              this.paymentScheduleTransactionTableData.items.find((element) => {
                if (
                  element.paymentScheduleID ==
                  res.data.paymentScheduleTransaction.paymentScheduleID
                ) {
                  element.isEdit = true;
                  return true;
                } else {
                  return false;
                }
              });
              this.PaymentScheduleDataInput.push({
                paymentScheduleID:
                  res.data.paymentScheduleTransaction.paymentScheduleID,
                loanApplicationID:
                  res.data.paymentScheduleTransaction.loanApplicationID,
                businessID: res.data.paymentScheduleTransaction.businessID,
                programID: res.data.paymentScheduleTransaction.programID,
                transactionDate:
                  res.data.paymentScheduleTransaction.transactionDate,
                fundingTypeID:
                  res.data.paymentScheduleTransaction.fundingTypeID,
                fundedAmount: res.data.paymentScheduleTransaction.fundedAmount,
                transactionStatusID:
                  res.data.paymentScheduleTransaction.transactionStatusID,
                contactID: res.data.paymentScheduleTransaction.contactID,
                isEdit: true,
              });
              // }
            } else {
              this.$store.dispatch("updateLoaderFlag", false);

              this.toasterMessage("", res.data.message, "danger");
            }
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);

            Promise.reject(err);
            return Promise.reject();
          });
      }
    },
    deletePaymentScheduleTransaction(item) {
      this.deletePaymentScheduleData.push(item);
      let paymentScheduleIndex = this.paymentScheduleTransactionTableData.items.findIndex(
        (paymentSchedule) =>
          paymentSchedule.paymentScheduleID === item.paymentScheduleID
      );
      this.paymentScheduleTransactionTableData.items.splice(
        paymentScheduleIndex,
        1
      );
    },
    async RemovePaymentScheduleTransaction(item) {
      this.$store.dispatch("updateLoaderFlag", true);
      let data = {
        contactID: this.inputs.contactID,
        paymentScheduleID: item.paymentScheduleID,
        loanApplicationID: item.loanApplicationID,
        businessID: item.businessID,
        programID: item.programID,
        transactionDate: item.transactionDate,
        fundingTypeID: item.fundingTypeID,
        fundedAmount: item.fundedAmount,
        transactionStatusID: item.transactionStatusID,
      };
      await this.$store
        .dispatch("RemovePaymentScheduleTransaction", data, { root: true })
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.inputs.fundPendingAmount = res.data.fundPendingAmount;
            this.inputs.fundDisbursedAmount = res.data.fundDisbursedAmount;
            this.GetPaymentScheduleTransaction();
            this.$store.dispatch("updateLoaderFlag", false);

            // this.toasterMessage("", res.data.message, "success");
          } else {
            this.$store.dispatch("updateLoaderFlag", false);

            // this.toasterMessage("", res.data.message, "danger");
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.reject(err);
          return Promise.reject();
        });
    },
    async GetPaymentScheduleSummary() {
      this.$store.dispatch("updateLoaderFlag", true);
      let data = {
        businessID: parseInt(this.businessId),
        applicationId: parseInt(this.LoanNumber),
      };
      await this.$store
        .dispatch("GetPaymentScheduleSummary", data, { root: true })
        .then((res) => {
          if (res.data.isSuccess === false) {
            this.clearPaymentScheduleSummary()
            this.toasterMessage("", res.data.message, "danger");
          }
          this.content=this.inputs.additionalNotesAgreement
          this.editor.setHTML(this.content)
          this.isDisableForAgreement = res.data.applicationStatusId >= 14 ? true : false;
              if(this.isDisableForAgreement)
              {
                  this.$refs.additionalNotesAgreement.getQuill().enable(false);
              }
              else
              {
                  this.$refs.additionalNotesAgreement.getQuill().enable(true);
              }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.clearPaymentScheduleSummary()
          this.content="";
          this.editor.setHTML(this.content)
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.reject(err);
          return Promise.reject();
        });
    },
    async GetPaymentScheduleTransaction() {
      this.$store.dispatch("updateLoaderFlag", true);
      let data = {
        businessID: parseInt(this.businessId),
        applicationId: parseInt(this.LoanNumber),
      };
      await this.$store
        .dispatch("GetPaymentScheduleTransaction", data, { root: true })
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.paymentScheduleTransactionTableData.items =
              res.data.paymentScheduleTransactionList;
            this.$store.dispatch("updateLoaderFlag", false);

            return Promise.resolve();
          } else {
            this.$store.dispatch("updateLoaderFlag", false);

            this.toasterMessage("", res.data.message, "danger");
            return Promise.resolve();
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.reject(err);
          return Promise.reject();
        });
    },
    async TiggerManualNotification(item) {
      this.$store.dispatch("updateLoaderFlag", true);
      let data = {
        applicationID: item.loanApplicationID,
        transactionDate: item.transactionDate,
        businessId: item.businessID,
        programId: item.programID,
        contactID: item.contactID,
      };
      await this.$store
        .dispatch("TiggerManualNotification", data, { root: true })
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.toasterMessage("", res.data.message, "success");
            this.$store.dispatch("updateLoaderFlag", false);

            return Promise.resolve();
          } else {
            this.$store.dispatch("updateLoaderFlag", false);

            this.toasterMessage("", res.data.message, "danger");
            return Promise.resolve();
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.reject(err);
          return Promise.reject();
        });
    },
    showPreview(e, item) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.newFiles = e.target.files;
      var data = {
        file: this.newFiles[0],
        documentTypeID: item.documentTypeID,
      };
      if (this.newFiles.length) {
        //Max file size < 25mb
        if (data.file.size > 26214400) {
          this.toasterMessage("", "Max file size should be < 25MB", "danger");
          return;
        }

        var filename = data.file.name.toLowerCase();
        var fileExt = filename.split(".").pop();
        if (
          fileExt == "pl" ||
          fileExt == "bat" ||
          fileExt == "ps" ||
          fileExt == "php" ||
          fileExt == "aspx" ||
          fileExt == "cshtml" ||
          fileExt == "vbs" ||
          fileExt == "js" ||
          fileExt == "py" ||
          fileExt == "java" ||
          fileExt == "cs" ||
          fileExt == "json" ||
          fileExt == "pyd" ||
          fileExt == "pyc" ||
          fileExt == "pyo" ||
          fileExt == "pyw" ||
          fileExt == "pm" ||
          fileExt == "t" ||
          fileExt == "pod"
        ) {
          this.toasterMessage("", "Unsupported file format", "danger");
          return;
        }

        //upload files
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("UploadFundAgreementDocument", data)
          .then((res) => {
            if (res.status === 200) {
              this.inputs.AgreementDocument.fileName = data.file.name;
              this.inputs.AgreementDocument.documentID = 0;
              this.inputs.AgreementDocument.documentTypeID = 1;
              this.inputs.AgreementDocument.documentGUID = res.data.documentID;
              this.inputs.AgreementDocument.physicalFileStorageKey =
                res.data.storageKey;
              this.inputs.AgreementDocument.fileUploadedSourceUrl =
                res.data.fileSource;
              this.inputs.AgreementDocument.fileSize = data.file.size;
              this.inputs.AgreementDocument.documentName = "Agreement Document";
              const fileSelected = this.$refs.fileInput;
              fileSelected.type = "text";
              fileSelected.type = "file";
              this.$store.dispatch("updateLoaderFlag", false);

              this.toasterMessage("", "File uploaded Successfully", "success");
            }
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);

            this.toasterMessage("", "Unsupported file", "danger");
            Promise.reject(err);
          });
      }
    },
    download(item) {
      this.$store.dispatch("updateLoaderFlag", true);
      var data = {
        physicalFileStorageKey: item.physicalFileStorageKey,
        fileName: item.fileName,
      };
      this.$store
        .dispatch("Download", data)
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);

          Promise.reject(err);
        });
    },
    closePopUp()
    {
      this.fundavailableLimitValidation.showCentralPopUp = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.FundingDetailsTab {
  padding-top: 1.5rem;
  &__main {
    padding-top: 0.5rem;
    height: 52vh;
    overflow-y: auto;
    .paymentScheduleHeading {
      h3 {
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
        cursor: pointer;
      }
    }
  }
  .form-group {
    // padding: 0;
    position: relative;
    @include input-style;
  }

  .btn--section {
    margin: 0rem;
    padding: 0rem;
    .btn--tertiary {
      button {
        width: auto;
        padding: 0rem 2rem;
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          content: "+";
        }
      }
    }
  }
  .form-file {
    label,
    input {
      margin: 0;
      font-size: 1.6rem;
      color: $primary-color-black;
    }
    label,
    input,
    .downloadLink {
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }

    .downloadLink {
      font-size: 1.6rem;
      word-wrap: break-word;
      // max-width:30rem;
      color: #0056b3;
      width: auto;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .popup {
    .popup--body {
      height: 95%;
      margin-top: 0.5rem;
      overflow-y: auto;
    }
  }
}
.central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 20vh;
    min-width: 40%;
    width: auto;
    padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px) {
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      p {
        font-size: 1.6rem;
        text-align: center;
        color: $primary-color-black;
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }

  .LabelNote,
  .input-values {
    padding: 1rem;
    // margin: 1rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $primary-color-black;
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
}
</style>
