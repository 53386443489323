<template>
  <div class="ResetForgotPassword">
    <BasicCenterTemplate>
      <div class="ResetForgotPassword-title">
        <h3>{{ ResetForgotPasswordTitle }}</h3>
      </div>
      <form @submit.prevent class="login-section__form--details">
        <div class="form-inputs">
          <div class="form-input--password form-group">
            <input
              type="password"
              placeholder=" "
              name="login-password"
              id="login-newPassword"
              @keyup="checkPasswordValidation($event)"
              @blur="checkPasswordValidation($event)"
              v-model="inputs.newPassword"
            />
            <label for="login-newPassword" class="form__label required">
              Enter New Password
            </label>
            <button class="password-show-icon" @click="showPassword($event)">
              <img :src="eye ? openEye : closedEye" alt="eye" />
            </button>
            <Error
              v-if="NewPasswordError.isError == true"
              class="error-message"
            >
              <p>
                <small>
                  {{ NewPasswordError.message }}
                </small>
              </p>
            </Error>
          </div>
        </div>
        <div class="form-inputs">
          <div class="form-input--password form-group">
            <input
              type="password"
              placeholder=" "
              name="login-password"
              id="login-newPassword"
              v-model="inputs.confirmPassword"
               v-on:keyup.enter="onEnter"
            />
            <label for="login-newPassword" class="form__label required">
              Confirm New Password
            </label>
            <button class="password-show-icon" @click="showPassword($event)">
              <img :src="eye ? openEye : closedEye" alt="eye" />
            </button>
            <span v-if="inputs.newPassword != inputs.confirmPassword">
              <Error v-if="v$.inputs.confirmPassword.$error">
                  <p>
                    <small
                    >
                      Password should be same as New Password
                    </small>
                  </p>
                </Error>
            </span>
          </div>
        </div>
        <div class="loginbtn">
          <BtnPrimary buttonName="Reset Password" @click="resetPassword()" />
        </div>
      </form>
      <div class="central-popup" v-if="logoutPopUP === true">
        <div class="central-popup__main">
          <div>
            <span class="central-popup__main__heading">
              <h3>Please login with New Password</h3>
            </span>
          </div>
          <div></div>
          <div>
            <div class=" d-flex justify-content-end">
              <div class="p-0">
                <span>
                  <BtnPrimary :buttonName="`Ok`" @click="logOut" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasicCenterTemplate>
  </div>
</template>
<script>
import BasicCenterTemplate from "@/components/BasicCenterTemplate.vue";
import BtnPrimary from "@/components/BtnPrimary.vue";
import axios from "axios";
import Error from "@/components/Error.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: {
    BasicCenterTemplate,
    BtnPrimary,
    Error,
  },
  data() {
    return {
      logoutPopUP: false,
      NewPasswordError: {
        isError: false,
        message: "",
      },
      ResetForgotPasswordTitle: "Reset Password",
      newPassword: "",
      inputs: {
        newPassword: "",
        confirmPassword: "",
      },
      openEye: require("@/assets/imgs/showIcon.svg"),
      closedEye: require("@/assets/imgs/hideShowIcon.svg"),
      eye: "",
    };
  },
  props: {
    t: {
      type: String,
    },
    c: {
      type: String,
    },
  },
  beforeMount() {
    let config = {
      params: {
        t: this.t,
        c: this.c,
      },
    };
    this.$store.dispatch("updateLoaderFlag", true);
    axios
      .get("/contact/ForgotPasswordActivation", config)
      .then((res) => {
        if (res.status === 200) {
          if (
            res.data.isSuccess === false &&
            res.data.message === "Invalid request"
          ) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage(
              "",
              "Forgot Password activation link is expired",
              "warning"
            );
            this.$router.push("/ale");
          }
          this.accountActivationResponse = res.data;
        }
        this.$store.dispatch("updateLoaderFlag", false);
      })
      .catch((err) => {
        this.$store.dispatch("updateLoaderFlag", false);
        this.toasterMessage("", "Error while processing request", "danger");
        Promise.reject(err);
      });
  },
  validations() {
    return {
      inputs: {
        newPassword: { required, minLength: minLength(8) },
        confirmPassword: { required,sameAs: sameAs(this.inputs.newPassword) },
      },
    };
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
  methods: {
    checkPasswordValidation() {
      if (
        this.inputs.newPassword.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-]).{8,20}$/
        )
      ) {
        this.NewPasswordError.isError = false;
        this.NewPasswordError.message = "";
      } else {
        this.NewPasswordError.isError = true;
        this.NewPasswordError.message =
          "The password must have a minimum 8 and maximum 20 characters, 1 number, 1 upper case, 1 lower case and a special character (@#$%&)";
          if(this.inputs.newPassword.length>20){
           let newPassword = this.inputs.newPassword.slice(0,20);
            this.inputs.newPassword = newPassword ;
            this.NewPasswordError.isError = false;
            this.NewPasswordError.message = "";
          }
      }
    },
    onEnter(){
      this.resetPassword();
    },
    async resetPassword() {
      const isFormCorrect =await this.v$.$validate();
      if (
        this.inputs.newPassword.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-]).{8,20}$/
        )
      ) {
        this.NewPasswordError.isError = false;
        this.NewPasswordError.message = "";
        if (isFormCorrect) {
          let passwordData = {
            NewPassword: this.inputs.newPassword,
            TokenID: this.t,
          };
          this.$store.dispatch("updateLoaderFlag", true);
          this.$store
            .dispatch("UpdateResetForgotPassWord", passwordData)
            .then((res) => {
                console.log(res)
              this.$store.dispatch("updateLoaderFlag", false);
              this.toasterMessage("", 'Password reset successfully', "success");
              this.logoutPopUP = true;
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              this.toasterMessage("", err, "danger");
            });
        }
      } else {
        this.NewPasswordError.isError = true;
        this.NewPasswordError.message =
          "The password must have a minimum 8 and maximum 20 characters, 1 number, 1 upper case, 1 lower case and a special character (@#$%&)";
      }
    },
    showPassword(e) {
      let parentBody = e.target.parentNode.parentNode;
      let password = parentBody.childNodes[0];
      if (password.type === "password") {
        password.type = "text";
        parentBody.childNodes[2].childNodes[0].src = this.openEye;
      } else {
        password.type = "password";
        parentBody.childNodes[2].childNodes[0].src = this.closedEye;
      }
    },
    logOut() {
      this.$store
        .dispatch("UpdateLogout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.ResetForgotPassword {
  .ResetForgotPassword-title {
    h3 {
      font-size: 3.2rem; //3rem;
      font-weight: 400; // font-weight: bold;
      color: $primary-color-red;
    }
    padding: 5rem 0rem 0rem 0rem;
    @media screen and (max-width: 1600px) {
      padding: 0.5rem 0rem 0rem 0rem;
      h3 {
        font-size: 2.5rem;
      }
      h6 {
        font-size: 1.2rem;
      }
    }
    @media screen and (max-width: 1400px) {
      h3 {
        font-size: 2rem;
      }
    }
  }
  .form-inputs {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    .form-group {
      position: relative;
      @include input-style;
      padding-right: 0% !important;
      .required{
                &:after {
                content: "*";
                color: $primary-color-red;
              }
            }
      .password-show-icon {
        height: 1.5rem;
        width: 2rem;
        outline: none;
        border: none;
        background-color: transparent;
        position: absolute;
        right: 0.5rem;
        bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center; // img {
        //   width: 100%;
        //   height: 100%;
        // }
      }
    }
    .form-input--infos {
      display: flex;
      justify-content: space-between;
      input {
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        color: $primary-color-black;
      }
      &__radio-btn {
        display: flex;
        input {
          margin-right: 1rem;
        }
      }
      button {
        color: $primary-color-black;
        background-color: transparent;
        border: none;
        outline: none;
        text-decoration: underline;
      }
    }
  }
  .error-message {
    top: 85%;
  }
}
.central-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    z-index: 9999;
    padding: 2rem;
    min-height: 25vh;
    width: auto;
    border: 1px solid gray;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__heading {
      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        // text-transform: capitalize;
        color: $primary-color-red;
      }
    }
  }
}
</style>
