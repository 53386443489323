<template>
  <div class="fundingSource pr-1">
    <div class="">
      <div class="row m-0 justify-content-between">
      <div class=" col-12 p-0">
        <h3 >
         <b>Program Details
          <br>
        </b> 
        </h3>
        <div class="section-border " >
          <b>
            <p></p>
          </b>
        </div>
      </div>
    </div>
    <div class="row m-0 justify-content-between">
      <div class="col-sm-4 col-12 order-sm-1 order-3  form-group">
        <input
          required
          type="text"
          placeholder=" "
          id="fundingSource-programName"
          name="fundingSource-programName"
          v-model="inputs.programName"
          :disabled=isDisable
           :readonly=isDisable
        />
        <label for="fundingSource-programName" class="form__label required">
          {{ labels.programName }}
        </label>
        <Error v-if="v$.inputs.programName.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.programName'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="col-sm-4 col-12  order-sm-2 order-2 form-group">
        <select
          class="select-group"
          placeholder=" "
          id="fundingSource-fundingType"
          name="fundingSource-fundingType"
          v-model="inputs.fundingTypeID"
          :disabled=isDisable
          :readonly=isDisable
        >
          <option
            v-for="fundingType in fundingTypes"
            :key="fundingType.fundingTypeID"
            :value="fundingType.fundingTypeID"
          >
            {{ fundingType.type }}
          </option>
        </select>
        <label for="fundingSource-fundingType" class="form__label required">
          {{ labels.fundingType }}
        </label>
        <Error v-if="v$.inputs.fundingTypeID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.fundingTypeID'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="col-sm-4 col-12 order-sm-3 order-1 form-group">
        <div class="funders-logo">
            <div class="funders-logo__image p-0" v-if="((fundingEntityImg)&&(logoImg === null || logoImg ===undefined || logoImg === '')) ">
              <img :src=fundingEntityImg alt=" funding Entity Logo " />
            </div>
            <div class="funders-logo__image p-0" v-if="logoImg" >
              <img :src=logoImg alt=" funding Entity Logo" />
            </div>
          </div>
      </div>
    </div>
    <div class="row m-0   justify-content-between">
      <div class=" col-sm-4 col-12 form-group">
        <select
          class="select-group"
          placeholder=" "
          id="fundingSource-businessTypesAllowed"
          name="fundingSource-businessTypesAllowed"
          v-model="inputs.businessTypes"
          :disabled=isDisable
          :readonly=isDisable
        >
          <option
            v-for="businessType in businessTypes"
            :key="businessType.businessTypeID"
            :value="businessType.businessTypeID"
          >
            {{ businessType.type }}
          </option>
        </select>
        <label for="fundingSource-businessTypesAllowed" class="form__label select-label">
          {{ labels.businessTypesAllowed }}
        </label>
        <Error v-if="v$.inputs.businessTypes.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.businessTypes'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class=" col-sm-4 col-12 form-group">
        <select
          class="select-group"
          placeholder=" "
          id="fundingSource-state"
          name="fundingSource-state"
          v-model="inputs.states"
          :disabled=isDisable
          :readonly=isDisable
        >
          <option
            v-for="state in states"
            :key="state.stateID"
            :value="state.stateID"
          >
            {{ state.stateName }}
          </option>
        </select>
        <label
          for="fundingSource-state"
          class="form__label select-label"
        >
          {{ labels.state }}
        </label>
        <Error v-if="v$.inputs.states.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.states'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="col-sm-4 col-12 form-group"></div>
    </div>
    </div>
    <div class="row m-0  ">
        <div class="col-sm-8 col-12 p-0">
          <div class=" form-file row m-0 ">
        <label
          for=inputs.uploadProgramLogo
          class="form__label form-file__label required col-sm-4 col-6 mr-4 p-0"
          >
          {{ labels.documentName }}
        </label>
        <input
          required
          ref="fileInput"
          class="form__label form-file__label required col-sm-3 col-4 mr-4 "
          type="file"
          :name=inputs.uploadProgramLogo
          :id=inputs.uploadProgramLogo
          v-bind="inputs.uploadProgramLogo.documentTypeID"
          @input="inputs.uploadProgramLogo"
          @change="showPreview($event, inputs.uploadProgramLogo)"
           accept=".jpg,.jpeg,.png,.gif,.svg"
        />
      </div>
        </div>
      </div>
    <div class="">
      <div class="row m-0   justify-content-between">
      <div class=" col-12 p-0">
        <div class="d-flex justify-content-between pt-4">
          <h3 >
         <b>Funding Information
          <br>
        </b> 
        </h3>
        <span class="hide-show-btn pr-1">
        <button @click="isHide = !isHide">
        <span> </span>
        <img v-if="isHide == false" src="@/assets/imgs/arrow-down.png" alt="down arrow" />
        <img v-if="isHide == true" src="@/assets/imgs/arrow-up.png" alt="up arrow" />
      </button>
        </span>
        
        </div>
        
        <div class="section-border " >
          <b>
            <p></p>
          </b>
        </div>
      </div>
    </div>
      <div class="row m-0 justify-content-between" v-if="isHide == true">
      <div class="  col-sm-4 col-12 form-group">
        <input
          required
         
          type="text"
          placeholder="$"
          maxlength="22"
           min="0"
          id="fundingSource-minimumLoanAmount"
          name="fundingSource-minimumLoanAmount"
          v-money3="config"
          v-model="inputs.minimumLoanAmount"
          :disabled=isDisable
          :readonly=isDisable
        />
        <label
          for="fundingSource-minimumLoanAmount"
          class="form__label required"
        >
          {{ labels.minimumLoanAmount }}
        </label>
        <Error v-if="v$.inputs.minimumLoanAmount.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.minimumLoanAmount'">
              {{ error.$message }}{{ inputs.minimumLoanAmount }}
            </small>
          </p>
        </Error>
      </div>
      <div class="  col-sm-4 col-12 form-group">
        <input
          required
          type="text"
          placeholder=" "
          maxlength="22"
          id="fundingSource-maximumLoanAmount"
          name="fundingSource-maximumLoanAmount"
          v-money3="config"
          v-model="inputs.maximumLoanAmount"
          :disabled=isDisable
          :readonly=isDisable
        />
        <label
          for="fundingSource-maximumLoanAmount"
          class="form__label required"
        >
          {{ labels.maximumLoanAmount }}
        </label>
        <Error v-if="v$.inputs.maximumLoanAmount.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.maximumLoanAmount'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
      <div class="  col-sm-4 col-12 form-group">
        <input
          readonly
          type="text"
          placeholder=" "
          id="fundingSource-initialFundedAmount"
          name="fundingSource-initialFundedAmount"
          v-money3="config"
          v-model="inputs.initialFundedAmount"
          :disabled=isDisable

        />
        <label
          for="fundingSource-initialFundedAmount"
          class="form__label required"
        >
          {{ labels.initialFundedAmount }}
        </label>
      </div>
    </div>
    <div class="row m-0 justify-content-between" v-if="isHide == true">
      <div class="  col-sm-4 col-12 form-group">
        <input
          type="text"
          placeholder=" "
          readonly
          id="fundingSource-totalFundedAmount"
          name="fundingSource-totalFundedAmount"
          v-money3="config"
          v-model="inputs.totalFundedAmount"
          :disabled=isDisable

        />
        <label
          for="fundingSource-totalFundedAmount"
          class="form__label required"
        >
          {{ labels.totalFundedAmount }}
        </label>
        <span class="icons edit-icon" @click="FundedAmountSummary(inputs)">
          <img src="@/assets/imgs/pencil.svg" alt="edit" />
        </span>
      </div>
      <div class="  col-sm-4 col-12 form-group">
        <input
          type="text"
          placeholder=" "
          readonly
          id="fundingSource-utilizedAmount"
          name="fundingSource-utilizedAmount"
          v-money3="config"
          v-model="inputs.utilizedAmount"
          :disabled=isDisable
        />
        <label for="fundingSource-utilizedAmount" class="form__label required">
          {{ labels.utilizedAmount }}
        </label>
        <span class="icons view-icon" @click="UtilizedAmountSummary(inputs)">
          <img src="@/assets/imgs/showIcon.svg" alt="show List" />
        </span>
      </div>
      <div class="  col-sm-4 col-12 form-group">
        <input
          readonly
          type="text"
          placeholder=" "
          id="fundingSource-availableLimit"
          name="fundingSource-availableLimit"
          v-money3="config"
          v-model="inputs.availableLimit"
          :disabled=isDisable
        />
        <label for="fundingSource-availableLimit" class="form__label required">
          {{ labels.availableLimit }}
        </label>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import textStore from "@/textStore.js";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Error from "@/components/Error.vue";
import { Money3Directive } from "v-money3";
export default {
  directives: {
    money3: Money3Directive,
  },
  props: {
    stateList: [],
    fundingTypeList: [],
    businessTypeList: [],
  },
  validations() {
    return {
      inputs: {
        programName: { required },
        fundingTypeID: { required },
        states: { required },
        businessTypes: { required },
        minimumLoanAmount: { required },
        maximumLoanAmount: { required },
      },
    };
  },
  beforemount() {
    this.$emit('onSave',this.$store.state.fundSource.fundingEntity.fundingEntityID)
   },
  setup() {
    return {
      v$: useVuelidate(),
      textStore,
    };
  },
  components: {
    Error,
  },
  data() {
    return {
      logoImg:'',
      isHide:true,
      CurrencySetUp: {
        CurrencyValue: "",
        formattedCurrencyValue: "",
        preventNextIteration: false,
      },
      minmumAmount: "",
      labels: {},
    };
  },
  computed: {
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        disableNegative: true,
        masked: false /* doesn't work with directive */,
      };
    },
    fundingEntityImg:{
      get(){
      if(this.inputs.logSource ==null || this.inputs.logSource ==undefined || this.inputs.logSource == ''){
        return ''
        }
        else{
          return this.inputs.logSource
        }
    }
    },
    inputs: {
      get() {
        Object.assign(this.labels, textStore.fundSource.source);
        return this.$store.state.fundSource.fundingSource;
      },
    },
    states: {
      get() {
        return this.stateList;
      },
    },
    fundingTypes: {
      get() {
        return this.fundingTypeList;
      },
    },
    businessTypes: {
      get() {
        return this.businessTypeList;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    },
  },
  methods: {
    download(item){
        this.$store.dispatch("updateLoaderFlag", true);
      var data ={
        physicalFileStorageKey:item.physicalFileStorageKey,
        fileName:item.fileName
      }
      this.$store.dispatch("Download",data).then((res)=>{
        this.$store.dispatch("updateLoaderFlag", false);
        this.downloadDataImg=res.data
          Promise.resolve(res);
        }).catch(err =>{
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    showPreview(e, item) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.newFiles = e.target.files;
      var data = {
        file: this.newFiles[0],
        documentTypeID: item.documentTypeID,
      };

///////////////////////////////
      if (this.newFiles.length) {

        var filename = data.file.name.toLowerCase();
        var fileExt = filename.split('.').pop();
       
        const fileExtensions = ["jpg", "png","jpeg","svg","gif"];
       
        //Commented this. using only supported logo
        //if(fileExt=="pl" || fileExt=="bat" || fileExt=="ps" || fileExt=="php" || fileExt=="aspx" || fileExt=="cshtml" || fileExt=="vbs" || fileExt=="js" || fileExt=="py" || fileExt=="java" || fileExt=="cs" || fileExt=="json" || fileExt=="pyd" || fileExt=="pyc" || fileExt=="pyo" || fileExt=="pyw" || fileExt=="pm" || fileExt=="t" || fileExt=="pod"  )
        if(fileExtensions.indexOf(fileExt) == -1)
        {
          this.toasterMessage('','Please upload image file','danger');
          return;
        }


        //upload files
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("UploadLogoFile", data)
          .then((res) => {
          if (res.status===200) {
            this.inputs.uploadProgramLogo.fileName = data.file.name;
            this.inputs.uploadProgramLogo.documentTypeID = 0;
            this.inputs.uploadProgramLogo.documentGUID = res.data.documentID;
            this.inputs.uploadProgramLogo.physicalFileStorageKey =
              res.data.storageKey;
            this.inputs.uploadProgramLogo.fileSize = res.data.fileSize;
            this.inputs.uploadProgramLogo.documentName =
              "Fund Transaction Document";
              this.inputs.uploadProgramLogo.fileSource =res.data.fileSource
               this.logoImg=res.data.fileSource
            const fileSelected = this.$refs.fileInput;
            fileSelected.type = "text";
            fileSelected.type = "file";
            this.$store.dispatch("updateLoaderFlag", false);
            // this.fundingEntityImg = this.inputs.uploadProgramLogo.physicalFileStorageKey

  
        //     this.fundingEntityImg = e.target.result;
        //     this.image=e.target.files[0];
        // reader.readAsDataURL(e.target.files[0]);
            this.toasterMessage('','File uploaded Successfully','success')
          }
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage('','Unsupported file','danger')
            Promise.reject(err);
          });
      }
    },
    formateCurrencyToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.CurrencySetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.CurrencySetUp.preventNextIteration = false;
        return;
      }
      let amount = this.inputs.minimumLoanAmount;
      this.inputs.minimumLoanAmount = amount
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    FundedAmountSummary(item) {
      this.$emit("showFundAllocationSummary", item.fundingSourceID);
    },
    UtilizedAmountSummary(item) {
      this.$emit("showutilizedAmountSummary", item.fundingSourceID);
    },
    
     async saveFundSource() {
      var statesId = [];
      var businessTypesId = [];

      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        statesId.push(this.inputs.states);
        businessTypesId.push(this.inputs.businessTypes);
        
        const data = {
          fundingSourceID: this.inputs.fundingSourceID,
          programName: this.inputs.programName,
          fundingEntityName: this.$store.state.fundSource.fundingEntity.fundingEntityName,
          fundingEntityID: this.$store.state.fundSource.fundingEntity.fundingEntityID,
          fundingTypeID: this.inputs.fundingTypeID,
          states: statesId,
          businessTypes: businessTypesId,

          minimumLoanAmount:Number(String(this.inputs.minimumLoanAmount).replace(/[^\d]/g, '')),
          maximumLoanAmount:Number(String(this.inputs.maximumLoanAmount).replace(/[^\d]/g, '')) ,
        };
        
        if (data.minimumLoanAmount <= 0 || data.maximumLoanAmount <= 0) {
          this.toasterMessage("", " Grant Amount should not be $ 0", "danger");
        } else {
          if (data.minimumLoanAmount <= data.maximumLoanAmount) {
      //       if (payload.uploadFundingEntityLogo.documentGUID==='') {
      //   payload.uploadFundingEntityLogo.documentGUID="D04B69C1-5166-4B0E-ACAE-666A9FC9A9A7";
        
      // }
      // data.uploadFundingEntityLogo = payload.uploadFundingEntityLogo;

            if(this.inputs.uploadProgramLogo.documentGUID===''){
          this.inputs.uploadProgramLogo.documentGUID="D04B69C1-5166-4B0E-ACAE-666A9FC9A9A7";
        }
        data.uploadProgramLogo=this.inputs.uploadProgramLogo
            this.$store.dispatch("updateLoaderFlag", true);
            this.$store
              .dispatch("SaveFundingSource", data)
              .then((res) => {
            if (res.data.isSuccess) {
              this.$emit('onSave',this.$store.state.fundSource.fundingEntity.fundingEntityID)
              this.$store.dispatch("updateLoaderFlag",false)
              this.$store.dispatch("fetchFundingSource", res.data.id)
              this.toasterMessage('',res.data.message,'success')
              return Promise.resolve(true);
            } else {
              this.$store.dispatch("updateLoaderFlag",false)
              if((res.data.validationErrors!=null || res.data.validationErrors!=undefined) && res.data.validationErrors.length>0)
              {
                res.data.validationErrors.forEach(element => {
                  this.toasterMessage('',element,'danger')
                });
              }
              else{
                this.toasterMessage('',res.data.message,'danger')
              }
              
            }
              })
              .catch(() => {
                this.$store.dispatch("updateLoaderFlag", false);
                this.toasterMessage('','Error while processing request','danger')
                return Promise.resolve(false);
              });
          } else {
            this.toasterMessage(
              "",
              "Minimum Grant Amount should be less/equal to Maximum Grant Amount",
              "danger"
            );
          }
        }
      } else {
        return Promise.resolve(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.fundingSource {
  height: 100%;
  overflow-y: auto;
  padding-top: 0.5rem;
  .hide-show-btn {
    button {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      padding: 0.01rem;
      border: none;
      img {
        height: 50%;
      }
    }
  }
  .row {
    padding-right: 2%;
    margin: 0;
  }
  .section-border{
    border-bottom: .2rem solid $tertiary-color-white;
    margin-right: 2%;
    margin-bottom: 1.5rem;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
    .icons {
      bottom: 2.5rem;
      position: absolute;
      right: 10%;
      height: 2.5rem;
      padding-right: 5%;
      width: 2rem;
      outline: none;
      border: none;
      background-color: transparent;

      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 100%;
      }
    }
  }
  .btn {
    button {
      width: auto;
    }
  }
  .form-file {
    input,.downloadLink,label{
      color: $primary-color-black;
      font-size: 1.6rem;
      
    }
  }
  .funders-logo {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          &__image{
          height: 6rem;
          img {
            padding: 0 .2rem;
            height: 100%;
            object-fit:contain;
            object-position:50% 50%;
            width:100%;
          }
          }
          margin-bottom: 1rem;
          
  }
}
</style>
