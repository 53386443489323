<template>
  <div class="question">
    <HeaderNav />
    <div class="question--content">
      <div class="question--content__Header d-flex">
        <div class="question--content__Header--breadCrumb align-self-start">
          <BreadCrumb class="breadcrumb" :crumbs="crumbs" />
        </div>
        <span class="btn--primary" v-if="userRoleID !=8">
          <BtnPrimary :buttonName="`New Question`" @click="createQuestion" />
        </span>
    </div>
        <div class="TrackingList__table-section">
            <Questiontable
              class="listingTable"
              :items="items"
              :columns="columns"
              :TableHeight="TableHeight"
              :TableBodyHeight="TableBodyHeight"
              :perPage="itemsPerTable"
              @selectId="selectRow"
            />
        </div>
      
    </div>
    <LoadingMask v-if="isLoading === true" />
    <Footer />
  </div>
</template>
<script>
import Questiontable from "@/components/Question/questiontable";
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import BreadCrumb from "@/components/BreadCrumb";
import BtnPrimary from "@/components/BtnPrimary.vue";
export default {
  data() {
    return {
      crumbs: ["Questions"],
      itemsPerTable: 10,
      TableBodyHeight: 40,
      TableHeight: 52,
      items: [],
    };
  },
  components: {
    HeaderNav,
    Footer,
    BreadCrumb,
    Questiontable,
    BtnPrimary,
  },

  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  created() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.getQuestionList()

  },
  methods: {
    selectRow(data) {
      if (data > 0) {
        this.$router.push("/question/form/" +data);
      }
    },
    async getQuestionList() {
      this.$store.dispatch("updateLoaderFlag", true);
    //   this.popup = true;
      await this.$store
        .dispatch("getQuestionList", { root: true })
        .then((res) => {
          if (res.data.questionsRecords && res.data.questionsRecords.length > 0) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.items = res.data.questionsRecords;
          }
        })
        .catch(() => {
          this.$store.dispatch("updateLoaderFlag", false);
          this.LoginError = true;
        });
    },
    async createQuestion() {
      this.$router.push("/question/form/0");
    },
  },
};
</script>
<style lang="scss" scoped>
.question {
  &--content {

    padding: 0rem 2%;
    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn--primary {
        min-width: 19rem;
        button {
          font-size: 1.6rem;
          padding: 0 1rem;
        }
      }
    }
    &__name {
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
    }
    @media screen and (max-width: 580px){
      min-height: 90vh;
    }
  }
}
</style>
