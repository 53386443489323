<template>
  <div class="fundform">
    <BasicComponent :heading="getPageHeader()"
    @callParentFuntion="isShowProgram"
     :subText="texts.subText">
      <template v-slot:breadCrumb>
        <div class="d-flex justify-content-between">
          <BreadCrumb :crumbs="crumbs" @selected="selected" />
      
        </div>
        
      </template>
      <template v-slot:mainSection>
        <div v-if="fundSourceForm !== true" class="form-section">
          <div class="form-section__header pb-3">
            <nav>
              <ul>
                <li class="nav-item">
                  <span
                    @click.prevent="setActive('fundingEntity')"
                    class="nav-btn"
                    :class="activeItem == 'fundingEntity' ? 'active' : ''"
                  >
                    <a href="#businessEntity">
                      <p>{{ nav.stepName1 }}</p>
                    </a>
                  </span>
                </li>
                <li class="nav-item" v-if="canhide==false">
                  <span
                    @click.prevent="setActive('fundingSource')"
                    class="nav-btn"
                    :class="activeItem == 'fundingSource' ? 'active' : ''"
                  >
                    <a href="#businessEntity">
                      <p>{{ nav.stepName2 }}</p>
                    </a>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
          <div class="row m-0 form-section__multi-form">
            <div class="col-12 form-section__multi-form__forms p-0">
              <div class="forms-container tab-content" id="myTabContent">
                <!-- funding entitiy  -->
                <FundingEntity
                  id="fundingEntity"
                  class="tab-pane fade"
                  :stateList="stateList"
                  :entity_ID = "entity_ID"
                  :class="{ 'active show': isActive('fundingEntity') }"
                  ref="fundingEntity"
                />
                  
                  <FundingSource
                  id="fundingSource"
                  class="tab-pane fade"
                  :class="{ 'active show': isActive('fundingSource') }"
                  :entity_ID = "entity_ID"
                  ref="fundingSource"
                  @edit="editFundSource"
                  @addFundSource="addFundSource()"
                />
              </div>
              <div class="horizontal">
                <div class="forms-btns row">
                  <div class="forms-btns__left col-3">     
                  </div>
                  
                </div>
              </div>
            </div>
            <!-- <div class="col-3 form-section__multi-right">
              
            </div> -->
          </div>
          <LoadingMask v-if="isLoading===true" />
        </div>
        <div v-if="fundSourceForm == true">
          <Program />
        </div>
        <!-- <router-view></router-view> -->
        <PopUp v-if="popup===true" @closePopUp="popup = false" class="popup">
          <div class="my-5"></div>
          <div
            class="fundAllocationSummary popup-body"
            v-if="fundAllocationSummary == true"
          >
            <FundAllocationSummary @showFundForm="showFundForm" />
          </div>
          <div
            class="utilizedAmountSummary popup-body"
            v-if="utilizedAmountSummary == true"
          >
            <UtilizedAmountSummary 
            @showFundForm="showFundForm" />
          </div>
        </PopUp>
      </template>
      
    </BasicComponent>
    <LoadingMask v-if="isLoading===true" />

  </div>
</template>
<script>
import FundingEntity from "@/components/FundSourceTracking/FundingEntity";
import FundingSource from "@/components/FundSourceTracking/FundingSource";
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import Program from '@/views/Fund/Program'
// import BtnTertiary from "@/components/BtnTertiary.vue";
// import BtnSecondary from "@/components/BtnSecondary.vue";
import PopUp from "@/components/PopUp";
// import FundSourceForm from "@/components/FundSourceTracking/FundSource/FundSourceForm";
import FundAllocationSummary from "@/components/FundSourceTracking/FundSource/FundAllocationSummary";
import UtilizedAmountSummary from "@/components/FundSourceTracking/FundSource/UtilizedAmount";
export default {
  components: {
    BasicComponent,
    BreadCrumb,
    Program,
    // BtnSecondary,
    // BtnTertiary,
    FundingEntity,
    FundingSource,
    UtilizedAmountSummary,
    PopUp,
    // FundSourceForm,
    FundAllocationSummary,
  },
  props: {
    entityID: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
    defaultfundingSource: {
    fundingSourceID: 0,
    programName: "",
    fundingEntityName: "",
    fundingEntityID: 0,
    fundingTypeID: null,
    states: '',
    businessTypes: '',
    minimumLoanAmount: null,
    maximumLoanAmount: null,
    initialFundedAmount: 0,
    totalFundedAmount: 0,
    utilizedAmount: 0,
    availableLimit: 0,
    uploadProgramLogo: {
      documentGUID: "",
      documentTypeID: 0,
      documentName: "",
      fileName: "",
      physicalFileStorageKey: "",
      fileSize: 0,
      isActive: true,
      loanApplicationID: 0,
      applicationDocumentID: 0,
    },
  },
      popup: false,
      fundSourceForm: false,
      utilizedAmountSummary: false,
      fundAllocationSummary: false,
      tabItems: ["fundingEntity", "fundingSource"],
      activeItem: "fundingEntity",
      nav: {
        stepName1: "Funding Entity",
        stepName2: "Programs",
      },
      texts: {
        heading: " ",
        subText: " ",
      },
      crumbs: [ "Funding Entities", " Funding Entity"],
      stateList: [],
      fundingTypeList: [],
      businessTypeList: [],
      entity_ID: this.entityID
      // canhide:true
    };
  },   
  created() {
    this.$store
      .dispatch("getmasterData")
      .then((res) => {
        // this.$store.state.fundSource.fundingEntity={};
        this.stateList = res.data.stateList;
        this.fundingTypeList = res.data.fundingTypeList;
        this.businessTypeList = res.data.businessTypeList;
        var fundEntity = this.$store.state.fundSource.fundingEntity;
        if(fundEntity.fundingEntityID===undefined || fundEntity.fundingEntityID===0 ){
          this.canhide = true;
        }
        else{
          this.canhide = false;
        }
      })
      .catch((err) => {
        Promise.reject(err);
      });
  },
  methods: {
    isShowProgram(){
      if(this.fundSourceForm === true){
        this.fundSourceForm=false
      }
    },
    selected(crumb) {
      if(crumb=="Funding Entities"){
        this.$router.push('/funding')
      }
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
     getPageHeader()
    {
      if(this.fundSourceForm === true){
        let programName = this.$store.state.fundSource.fundingSource.programName;
        if(this.$store.state.fundSource.fundingSource.fundingSourceID===0 || this.$store.state.fundSource.fundingSource.fundingSourceID === undefined)   
        this.texts.heading = "<"+" "+" "+"New Program";
        else
        this.texts.heading ="<"+" "+" "+programName;
      }
      else{
        let fundingEntity = this.$store.state.fundSource.fundingEntity.fundingEntityName;
        if(this.$store.state.fundSource.fundingEntity.fundingEntityID===0 || this.$store.state.fundSource.fundingEntity.fundingEntityID === undefined)   
        this.texts.heading = "New Fund Entity";
        else
        this.texts.heading = fundingEntity;
      }
        
        return this.texts.heading;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;

      if (this.activeItem == "fundingEntity") {
        this.crumbs[1] = "Funding Entity";
      }
      if (this.activeItem == "fundingSource") {
        this.crumbs[1] = "Programs";
      }
    },
    addFundSource() {
    this.$store.state.fundSource.fundingSource = {
    fundingSourceID: 0,
    programName: "",
    fundingEntityName: "",
    fundingEntityID: 0,
    fundingTypeID: null,
    states: '',
    businessTypes: '',
    minimumLoanAmount: null,
    maximumLoanAmount: null,
    initialFundedAmount: 0,
    totalFundedAmount: 0,
    utilizedAmount: 0,
    availableLimit: 0,
    uploadProgramLogo: {
      documentGUID: "",
      documentTypeID: 0,
      documentName: "",
      fileName: "",
      physicalFileStorageKey: "",
      fileSize: 0,
      isActive: true,
      loanApplicationID: 0,
      applicationDocumentID: 0,
    },
  };
      (this.utilizedAmountSummary = false);
        (this.fundAllocationSummary = false);
        (this.fundSourceForm = true);
      // this.popup = true;
    },
    editFundSource() {
      // this.$store.state.fundSource.fundingSource = this.defaultfundingSource;
      (this.utilizedAmountSummary = false),
        (this.fundAllocationSummary = false),
        (this.fundSourceForm = true);
      // this.popup = true;
    },
    showFundAllocationSummary() {
      this.fundSourceForm = false;
      (this.utilizedAmountSummary = false),
        (this.fundAllocationSummary = true),
        (this.popup = true);
    },
    showutilizedAmountSummary() {
      this.fundSourceForm = false;
      (this.fundAllocationSummary = false),
        (this.utilizedAmountSummary = true),
        (this.popup = true);
    },
    showFundForm() {
      this.editFundSource();
    },
    getFundTransactions(fundingSourceID) {
      this.$store.dispatch("updateLoaderFlag",true)
      if (fundingSourceID > 0){
        this.$store
          .dispatch("getFundTransactionList", fundingSourceID)
          .then((res) => {
        this.$store.dispatch("updateLoaderFlag", false);
            this.showFundAllocationSummary();
            Promise.resolve(res);
          })
          .catch(()=>{
            this.LoginError = true
            this.$store.dispatch("updateLoaderFlag", false);
          });
      }
      else{
        this.$store.dispatch("updateLoaderFlag", false);
      }
    },
    getFundUtilizations(fundingSourceID) {
      this.$store.dispatch("updateLoaderFlag", true);
      if (fundingSourceID > 0) {
        this.$store
          .dispatch("getFundUtilizationList", fundingSourceID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.showutilizedAmountSummary();
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
          })
          .catch(()=>{
            this.LoginError = true
            this.$store.dispatch("updateLoaderFlag", false);
          });
      }
      else{
        this.$store.dispatch("updateLoaderFlag", false);
      }
    },
    getFundSources(fundingEntityID) {
      if (fundingEntityID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("fetchFundingEntitiy", fundingEntityID)
          .then((res) => {
            (this.utilizedAmountSummary = false),
              (this.fundAllocationSummary = false),
              (this.fundSourceForm = true);
            this.popup = false;
        this.$store.dispatch("updateLoaderFlag", false);
            Promise.resolve(res);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.reject(err);
          });
      }
    },
  },
  computed:{
    isLoading:{
      get(){
        return this.$store.state.showLoader;
      }
  },
    canhide:{
      get(){
        let fundEntity = this.$store.state.fundSource.fundingEntity;
        if(fundEntity.fundingEntityID===undefined || fundEntity.fundingEntityID===0 ){
          return true;
        }
        else{
          return false;
        }
      }
    }
    
  }
};
</script>
<style lang="scss" scoped>
.fundform {
  .form-section {
    &__header {
      nav {
        ul,
        li {
          list-style: none;
          display: flex;
          flex-wrap: nowrap;
          margin: 0;
          a {
            text-decoration: none;
            height: 5rem; //54px;
            min-width: 5rem;
            margin-right: 1rem;
            background-color: transparent;
            outline: none;
            border: 1px solid transparent; //$primary-color-red;
            border-radius: 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
              // border: 1px solid $primary-color-red;
              p {
                font-weight: bold;
                color: $primary-color-black;
              }
            }
            p {
              font-size: 1.6rem;
              color: $primary-color-grey;
              margin: 0 0.5rem;
              padding: 0rem 2.5rem;
            }
          }
          .active {
            a {
              border: 1px solid $primary-color-red;
              p {
                font-weight: bold;
                color: $primary-color-black;
              }
            }
          }
        }
      }
    }
    &__multi-form {
      .forms-container {
        height: calc(100vh - 30rem);
        @media screen and(max-width:580px) {
            min-height: 70vh;
          }
      }
    }
  }
  .btn--tertiary {
      button {
        width: auto;
        padding: 0rem 2rem;
        position: relative;
        margin: 0rem;
        &:before {
          position: absolute;
          left: 0;
          content: "+";
        }
      }
    }
  .popup-body {
    height: 40vh;
    overflow-y: auto;
    margin-top: 4rem;
  }
}
</style>
