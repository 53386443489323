<template>
  <div class="basicTemplate">
    <div class="basicTemplate-bg"></div>
    <div class="basicTemplate-section row justify-content-center">
      <div class="basicTemplate-section__form col ">
        <div class="basicTemplate-section__form--logo">
          <img src="@\assets\imgs\NUL_Logo_Standalone.png" alt="logo" />
          <img
            src="@\assets\imgs\Urban-Empowerment-Fund-2020-cmyk@2x.png"
            alt="logo"
          />
        </div>
        <div class="form-titles">
          <h1>
            {{ FormTitle }}
          </h1>
          <h6>
            {{ formSubTitle }}
          </h6>
        </div>
        <div class="basicTemplate-title px-2 px-sm-0">
          <h3>{{ changePasswordTitle }}</h3>
        </div>
        <slot class="popUp-continer__display">

          </slot>
      </div>
    </div>
    <div class="central-popup" v-if="logoutPopUP===true">
              <div class="central-popup__main">
                <div>
                  <span class="central-popup__main__heading">
                      
                    <h3>Please basicTemplate again with New Password</h3>
                  </span>
                </div>
                <div>
                </div>
                <div>
                  <div class=" d-flex justify-content-end">
                    <div class="p-0">
                      <span>
                        <BtnPrimary :buttonName="`Ok`" @click="logOut" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
    <LoadingMask v-if="isLoading === true" />
    <footer class="row p-0 m-0">
      <div class="row p-0 m-0 col-12 footer">
        <div class="col-sm-3 col-12 px-3 footer-left">
          <p>© 2021 national urban league</p>
        </div>
        <div class="col-sm-3 col-12 px-3 footer-right">
          <a
            href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/NUL_Website_Privacy_Policy(BuildBackBlack).docx"
            >Privacy Policy
          </a>
          <div>&nbsp;</div>
          <a
            href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/Terms_of_Use_(BuildBackBlack).docx"
            >Terms of Use
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import BtnPrimary from "@/components/BtnPrimary.vue";

export default {
  components: {
    BtnPrimary,
  },
  data() {
    return {
        logoutPopUP:false,
      openEye: require("@/assets/imgs/showIcon.svg"),
      closedEye: require("@/assets/imgs/hideShowIcon.svg"),
      eye: "",
      
    };
  },
  props: {
      FormTitle:{
          type:String,
          default:"Build Back Black"
      } ,
      formSubTitle: {
          type:String,
          default:"Supporting minority entrepreneurs with capital resources."
      } ,
  },
  methods: {
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.basicTemplate {
  position: relative;
  overflow: hidden;
  .basicTemplate-bg {
    height: 100vh;
    background: $secondary-color-white 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  .basicTemplate-section {
    max-width: calc(100vw - 10%); 
    width: 100%; 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 20%);
    max-height: 100%;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    border-radius: 5.8rem;
    opacity: 1;
     margin: auto;
    &__form {
      max-width: 100%;
      border-radius: 5.8rem 0 0 5.8rem;
      padding: 5rem 6rem;
      display: grid;
      // flex-direction: column;
      justify-content: space-around;
      @media screen and (max-width: 1600px) {
        padding: 3.5rem;
      }
      @media screen and (max-width: 1400px) {
        padding: 2.5rem;
      }
      @media screen and (max-width: 756px) {
        padding: 2rem .5rem;
        max-width: fit-content;
      }
      &--logo {
        max-height: 5rem; //5.5rem;
        img {
          height: 100%;
          margin-right: 3rem;
        }
        @media screen and (max-width: 1600px) {
          height: 4rem;
        }
        @media screen and (max-width: 1400px) {
          height: 3rem;
          text-align: center;
          vertical-align: middle;
          max-width: 30rem;
        }
      }
      .form-titles {
        color: $primary-color-black;
        h1 {
          font-size: 4rem;
          font-weight: bold;
          line-height: 5rem;
          text-transform: capitalize;
          @media screen and (max-width: 1600px) {
            font-size: 3rem;
            line-height: 3rem;
            margin-top: 1rem;
          }
          @media screen and (max-width: 570px) {
            font-size: 2.5rem;
            line-height: 3rem;
            margin-top: 1rem;
            vertical-align: middle;
            // width: 300px;
          }
        }
        h6 {
          // max-width: 100%;
          font-size: 1.4rem;
          line-height: 2rem;
          @media screen and (max-width: 570px){
            display: none;
          }
        }
      }
      .basicTemplate-title {
        h3 {
          font-size: 3.2rem; //3rem;
          font-weight: 400; // font-weight: bold;
          color: $primary-color-red;
        }
        padding: 5rem 0rem 0rem 0rem;
        @media screen and (max-width: 1600px) {
          padding: 0.5rem 0rem 0rem 0rem;
          h3 {
            font-size: 2.5rem;
          }
          h6 {
            font-size: 1.2rem;
          }
        }
        @media screen and (max-width: 1400px) {
          h3 {
            font-size: 2rem;
          }
        }
      }
      &--details {
        max-width: 80%;
        display: flex;
        flex-direction: column; // @media screen and (max-width: 1600px) {
        //   max-width: 80%;
        // }
        .form-inputs {
          padding: 1rem 0;
          display: flex;
          flex-direction: column;
          .form-group {
            position: relative;
            @include input-style;
            padding-right: 0% !important;
            .password-show-icon {
              height: 1.5rem;
              width: 2rem;
              outline: none;
              border: none;
              background-color: transparent;
              position: absolute;
              right: 0.5rem;
              bottom: 2rem;
              display: flex;
              justify-content: center;
              align-items: center; // img {
              //   width: 100%;
              //   height: 100%;
              // }
            }
          }
          .form-input--infos {
            display: flex;
            justify-content: space-between;
            input {
              display: flex;
              width: 1.5rem;
              height: 1.5rem;
              color: $primary-color-black;
            }
            &__radio-btn {
              display: flex;
              input {
                margin-right: 1rem;
              }
            }
            button {
              color: $primary-color-black;
              background-color: transparent;
              border: none;
              outline: none;
              text-decoration: underline;
            }
          }
          .activatebtn {
            button {
              // width: 45rem;
              margin-top: 4.6rem;
              height: 6.4rem;
              font-size: 2rem;
            }
            @media screen and (max-width: 1600px) {
              button {
                margin-top: 1.5rem;
                height: 4rem;
              }
            }
            @media screen and (max-width: 1400px) {
              button {
                margin-top: 1rem;
                height: 3rem;
                font-size: 1.6rem;
              }
            }
          }
          @media screen and (max-width: 756px){
          padding: .5rem 1rem;
          }
        }
        @media screen and (max-width: 1400px) {
          max-width: 100%;
        }
      }
    }
    &__img {
      border-radius: 0 5.8rem 5.8rem 0;
      height: 100%;
      max-width: 50%;
      background: url("./../assets/imgs/Group8.png"),
        linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)); // rgba(255, 255, 255, 0);
      background-size: cover;
      background-blend-mode: saturation;
      background-repeat: no-repeat;
      background-position: cover;
      color: $primary-color-white;
      padding: 3rem 5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-top,
      &-bottom {
        max-width: 45%;
        color: $primary-color-white;
        h6 {
          font-size: 1.4rem;
          font-weight: lighter;
          a {
            font-size: 1.4rem;
            color: $primary-color-white;
            text-decoration: underline;
          }
        }
        h1 {
          font-size: 4.2rem;
          font-weight: bold;
        }
      }
      @media screen and (max-width: 1600px) {
        padding: 1rem 4rem;
        h1 {
          font-size: 4rem;
        }
        h6,
        a {
          font-size: 1.2rem;
        }
      }
    }
  }
  footer {
    position: absolute; // width: 100%;
    max-width: calc(100vw - 10%); //172rem;
    width: 100%;
    margin: 0;
    bottom: 0%;
    left: 50%; // top: 50%;
    transform: translate(-50%, -50%); // @media screen and (max-width: 1600px) {
    //   width: 80%;
    // }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0rem;
      a,
      p {
        font-size: 1.2rem;
        line-height: 1.9rem;
        color: $primary-color-black;
        text-transform: capitalize;
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div {
          // content: "";
          height: 1.5rem;
          max-width: 0.1rem;
          background-color: $secondary-color-white;
          margin: 0 1rem;
        }
        
      }
      @media screen and (max-width: 756px) {
           top:40px;
          left:40px;
        }
        @media screen and (max-width: 570px) {
           top:20px;
          left:0px;
          &-right,
          &-left{
            display: flex;
            justify-content: center;
          }
        }
    }
  }
}
.central-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    &__main {
      z-index: 9999;
      padding: 2rem;
     min-height: 25vh;
    width: auto;
      border: 1px solid gray;
      background: $primary-color-white 0% 0% no-repeat padding-box;
      box-shadow: 0px -6px 12px #00000010;
      border-radius: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__heading{
        h3{
          font-size: 2.4rem;
        font-weight: bold;
        // text-transform: capitalize;
        color: $primary-color-red;
        }
      }
    }
  }
</style>
