<template>
  <div class="fund-allocation-summary">
    <div class="discription" @click="back()">
      <p class="discription-data">
        <span class="pr-3">&#60;</span>{{ text.discrption.back }}
      </p>
    </div>
    <div>
      <div class="info-button">
        <div class="info-section">
          <div class="info-section__amount">
            <h3 class="info-section__amount--data"><span class="form-group--currency">$ </span>{{utilizedAmount}}</h3>
            <p class="info-section__amount--label">
              {{ text.totalFundingAllocated }}
            </p>
          </div>
        </div>
        <div class="button-section">
          <slot> </slot>
        </div>
      </div>
      <div class="listing">
        <!-- listing table  -->
        <UtilizedAmountList
          :items="items"
          :columns="columns"
          :TableHeight="35"
          :perPage="3"
        />
      </div>
    </div>
  </div>
</template>
<script>
import UtilizedAmountList from "@/components/FundSourceTracking/FundSource/UtilizedAmountList";

export default {
  components: {
    UtilizedAmountList,
  },
  data() {
    return {
      columns: [
        "Transaction date",
        "Business Name",
        "Business Type",
        "Funding Type",
        "Funds Disbursed",
      ],
      summary: true,
      addFundSection: false,
      removeFundSection: false,
      text: {
        discrption: {
          back:"Back"
        },
        totalFundingAllocated: "Total Funding Allocated",
      },
    };
  },
  computed: {
    items: {
      get() {
        return this.$store.state.fundSource.fundUtilizations;
      },
    },
    utilizedAmount: {
      get() {
        var utilizedAmountHandleZero  = this.$store.state.fundSource.fundingSource.utilizedAmount; 
        if(utilizedAmountHandleZero == null)
        {
          utilizedAmountHandleZero= 0;
        }
        return utilizedAmountHandleZero; 
      },
    },
  },
  methods: {
    back(){
      this.$emit('showFundForm')
    },
  },
};
</script>
<style lang="scss" scoped>
.fund-allocation-summary {
  .discription {
    cursor: pointer;
    position: absolute;
    top: 5%;
    font-size: 1.8rem;
    color: $primary-color-black;
    text-transform: capitalize;
  }
  .info-button {
    margin: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info-section {
      &__amount {
        &--data {
          font-size: 2.6rem;
          font-weight: bold;
          text-transform: capitalize;
          color: $primary-color-red;
        }
        &--label {
          font-size: 2.2rem;
          color: $primary-color-black;
          text-transform: capitalize;
        }
        @media screen and (max-width: 1600px) {
          &--data {
            font-size: 2.4rem;
          }
          &--label {
            font-size: 2rem;
          }
        }
      }
    }
    .button-section {
      display: flex;

      &--btn {
        margin: 0rem 1rem;
        button {
          width: auto;
        }
      }
    }
  }
  .listing{
    padding: .2rem;
  }
}
</style>
