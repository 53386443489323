<template>
  <div class="login">
    <div class="login-bg">
      <div class="login-bg-red"></div>
      
    </div>
    <div class="login-section row">
      <div class="login-section__form col">
        <div class="login-section__form--logo">
          <img
            src="@\assets\imgs\NUL_Logo_Standalone.png"
            alt="logo"
          />
          <img
            src="@\assets\imgs\Urban-Empowerment-Fund-2020-cmyk@2x.png"
            alt="logo"
          />       
        </div>
        <div class="form-titles">
          <h1>
            {{FormTitle}}
          </h1>
          <h6>
            Supporting minority entrepreneurs with <br /> capital resources.
          </h6>
        </div>
        <div class="login-title">
          <h3>{{ LoginTitle }}</h3>
        </div>
        <form 
        @submit.prevent
        class="login-section__form--details">
          <div class="form-inputs">
            <div class="form-input--email form-group">
              <input
                required
                type="email"
                placeholder=" "
                name="login-email"
                id="login-email"
                v-model="inputs.email"
              />
              <label for="login-email" class="form__label required">
                {{ emailAddress }}
              </label>
        <Error v-if="v$.inputs.email.$error">
                <p v-for="(error, index) of v$.$errors" :key="index">
                  <small v-if="error.$propertyPath === 'inputs.email'">
                    Enter valid Email
                  </small>
                </p>
              </Error>
            </div>
            <div class="form-input--password form-group">
              <input
                required
                type="password"
                placeholder=" "
                name="login-password"
                id="login-password"
                v-model="inputs.password"
                 v-on:keyup.enter="onEnter"
              />
              <label for="login-password" class="form__lable required">{{
                password
              }}</label>
              <Error v-if="v$.inputs.password.$error">
                <p v-for="(error, index) of v$.$errors" :key="index">
                  <small v-if="error.$propertyPath === 'inputs.password'">
                    Enter the password
                  </small>
                </p>
              </Error>
              <button class="password-show-icon" @click="showPassword">
                <img :src="eye?openEye:closedEye"  alt="eye"/>
              </button>
            </div>
            <div class="form-input--infos">
              <div class="form-input--infos__radio-btn">
                <!-- <input
                  type="checkbox"
                  name="is-loggedin"
                  id="is-loggedin"
                  v-model="isLoggedIn"
                />
                <label for="is-loggedin">{{ KeepMeLoggedIn }}</label> -->
              </div>
              <button
                class="form-input--info__forgot-password"
                @click="forgotpassword()"
              >
                {{ ForgotPassword }}
              </button>
            </div>
            <div class="loginbtn">
              <BtnPrimary buttonName="Login" @click.prevent="login" />
            </div>

          </div>
        </form>
      </div>
      <div class="login-section__img col">
        <div class="login-section__img-top">
          <h1>
            {{ imgTopHeader1 }}
          </h1>
          <h6>
            {{ imgTopHeader6 }}
          </h6>
        </div>
      </div>
    </div>
    <LoadingMask v-if="isLoading === true" />
    <footer class="row p-0 m-0">
        <div class="row p-0 m-0 col-12 footer">
          <div class="col-sm-3 col-12 px-3 footer-left">
            <p>© 2021 national urban league</p>
          </div>
          <div class="col-sm-3 col-12 px-3 footer-right">
            <a href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/NUL_Website_Privacy_Policy(BuildBackBlack).docx">Privacy Policy </a>
            <div> &nbsp; </div>
            <a href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/Terms_of_Use_(BuildBackBlack).docx">Terms of Use </a>
          </div>
        </div>
      </footer>
  </div>
</template>
<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
import { required,email } from "@vuelidate/validators";
import Error from "@/components/Error.vue";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputs: {
        password: { required},
        email: { required,email},
      },
    };
  },
  components: {
    BtnPrimary,
    Error
  },
  data() {
    return {
      openEye:require('@/assets/imgs/showIcon.svg'),
      closedEye:require('@/assets/imgs/hideShowIcon.svg'),
      eye:"",
      FormTitle:"Build Back Black",
      // formSubTitle: "Supporting minority entrepreneurs with capital resources.",
      LoginTitle: "Login Now",
      KeepMeLoggedIn: "Keep me Logged in",
      ForgotPassword: "Forgot password?",
      emailAddress: " Email Address",
      password: "Password",
      imgTopHeader1: "Entrepreneurship.",
      imgTopHeader6:"Fund your passion to profits while creating jobs and wealth across the country.",
      imgBottomHeader6: "Need help? Contact us",
      LoginError: false,
      inputs: {
        password: "",
        email: "",
      },
    };
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
  methods: {
    forgotpassword(){
      this.$router.push('/forgotpassword')
    },
    onEnter(){
      this.login();
      
    }, 
    async login() {
      //await this.$router.push("/");
      const isFormCorrect =await this.v$.$validate();
      if(isFormCorrect){
        this.$store.dispatch("updateLoaderFlag", true);
        let userDetials = this.inputs
      this.$store.
      dispatch("UpdateLogin", userDetials)
      .then((res)=>{  
        if(res.data.unlockBusinessContactResponce !==null){
          this.toasterMessage('',res.data.unlockBusinessContactResponce,'danger')
        }   
        else{
          this.inputs.password= "";
        this.inputs.email= "";
        const roleID = res.data.roleID[0];
        // Redirect user based on its role
        /** For borrower */
        if(roleID === 2) {
          if(res.data.showConcent !== undefined && res.data.showConcent !== null && res.data.showConcent===true ) 
            this.$router.push("/Consent");

          else {
            this.$router.push(this.$route.query.redirect || "/tracks");
          }

        }
        //else if(roleID === 5 ||roleID === 6) {
        else if(roleID === 6) {
            this.$router.push(this.$route.query.redirect || "/tracks")
        }
        // else user is admin
        else
          this.$router.push(this.$route.query.redirect || "/dashBoard");

        this.$store.dispatch("updateLoaderFlag", false);
        }  
        
      })
      .catch(err =>{
         this.toasterMessage('',err.data.unlockBusinessContactResponce,'danger')
        this.$router.push("/login");
        this.LoginError=false
        Promise.reject(err);
        this.$store.dispatch("updateLoaderFlag", false);
      });
     
      }
       
    },
    showPassword(event){
          
     if(event.pointerType != ""){
      let password = document.querySelector('#login-password');
      if(password.type === "password"){
        password.type = "text"
        document.querySelector('.password-show-icon img').src=this.openEye
      }
      else{
        password.type = "password"
        document.querySelector('.password-show-icon img').src=this.closedEye
      }
    }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.login {
  position: relative;
  overflow: hidden;
  .login-bg {
    height: 100vh;
    background: $secondary-color-white 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: column;
    .login-bg-red {
      align-self: flex-end;
      width: 25vw;
      height: 100%;
      background: $primary-color-red 0% 0% no-repeat padding-box;
      opacity: 1;
    }
  }
  .login-section {
    max-width: calc(100vw - 10%);//172rem;
    width: 100%;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    height: calc(100vh - 20%);//88rem;
    max-height: 100%;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    border-radius: 5.8rem;
    opacity: 1;
    margin: auto;
    &__form {
      max-width: 50%;

      border-radius: 5.8rem 0 0 5.8rem;
      padding: 5rem 6rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      @media screen and (max-width: 1600px) {
        padding: 3.5rem;
      }
      @media screen and (max-width: 756px) {
        padding: 2.5rem;
        max-width: fit-content;
      }

      &--logo {
        max-height: 5rem; //5.5rem;
        img {
          height: 100%;
          margin-right: 3rem;
        }
        @media screen and (max-width: 1600px) {
          height: 4rem;
        }
        @media screen and (max-width: 1400px) {
          height: 3.5rem;
           text-align:center;
           vertical-align:middle;
          width:30rem;
        }
      }
      .form-titles{
        color: $primary-color-black;
        h1{
          font-size: 4rem;
          font-weight: bold;
          line-height: 5rem;
          text-transform: capitalize;
          @media screen and (max-width: 1600px) {
            font-size: 3rem;
            line-height: 3rem;
            margin-top: 1rem;
          }
          @media screen and (max-width: 756px) {
            font-size: 2.5rem;
             line-height: 3rem;
            margin-top: 1rem;
             text-align:center;
           vertical-align:middle;
          width:300px;
          }
        }
        h6{
          max-width: 50%;
          font-size: 1.4rem;
          line-height: 2rem;
           @media screen and (max-width: 756px) {
            margin-top: 15px;
            clear: both;
            display: inline-block;
            white-space: nowrap;
          }
        }
      }
      .login-title {
        h3 {
          font-size: 3.2rem; //3rem;
          font-weight: 400;
          // font-weight: bold;
          color: $primary-color-red;
        }
        padding: 5rem 0rem 0rem 0rem;
        @media screen and (max-width: 1600px) {
        padding: .5rem 0rem 0rem 0rem;
          h3 {
            font-size: 2.5rem;
          }
          h6 {
            font-size: 1.2rem;
          }
        }
        @media screen and (max-width: 1400px) {
          h3 {
            font-size: 2rem;
          }
        }
      }
      &--details {
        max-width: 80%;
        display: flex;
        flex-direction: column;
        .form-inputs {
          padding: 1rem 0;
          display: flex;
          flex-direction: column;
          .form-group {
            position: relative;
             @include input-style;
            padding-right: 0% !important;
            .password-show-icon {
                  height: 1.5rem;
                  width: 2rem;
                  outline: none;
                  border: none;
                  background-color: transparent;
                  position: absolute;
                  right: 0.5rem;
                  bottom: 2rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
            }
          }
          .form-input--infos {
            display: flex;
            justify-content: space-between;
            input {
              display: flex;
              width: 1.5rem;
              height: 1.5rem;
              color: $primary-color-black;
            }
            &__radio-btn {
              display: flex;
              input {
                margin-right: 1rem;
              }
            }
            button {
              color: $primary-color-black;
              background-color: transparent;
              border: none;
              outline: none;
              text-decoration: underline;
            }
          }
          .loginbtn {
            button {
              // width: 45rem;
              margin-top: 4.6rem;
              height: 6.4rem;
              font-size: 2rem;
            }
            @media screen and (max-width: 1600px) {
              button {
                margin-top: 1.5rem;
                height: 4rem;
              }
              
            }
            @media screen and (max-width: 1400px) {
              button {
                margin-top: 1rem;
                height: 3rem;
                font-size: 1.6rem;
              }
            }
          }
        }
        @media screen and (max-width: 1400px) {
          max-width: 100%;
        }
      }
    }
    &__img {
      border-radius: 0 5.8rem 5.8rem 0;
      height: 100%;
      max-width: 50%;
       @media screen and (max-width: 756px) {
          display: none;
        }
      background:url('./../assets/imgs/Group8.png'),linear-gradient(rgba(255,255,255,0.1), rgba(255, 255, 255, 0.1));// rgba(255, 255, 255, 0);
      background-size:cover;
      background-blend-mode: saturation;

      background-repeat: no-repeat;
      background-position: cover;
      color: $primary-color-white;
      padding:3rem 5rem;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-top,
      &-bottom {
        max-width: 45%;
        color: $primary-color-white;
        h6 {
          font-size: 1.4rem;
          font-weight: lighter;
          a {
            font-size: 1.4rem;
            color: $primary-color-white;
            text-decoration: underline;
          }
        }

        h1 {
          font-size: 4.2rem;
          font-weight: bold;
        }
      }
      @media screen and (max-width: 1600px) {
        padding:1rem 4rem;
        h1 {
          font-size: 4rem;
        }
        h6,
        a {
          font-size: 1.2rem;
          
        }
      }
    }
  }
  footer{
    position: absolute;
    // width: 100%;
    max-width: calc(100vw - 10%);//172rem;
    width: 100%;
    margin: 0;
    bottom: 0%;
    left: 50%;
    // top: 50%;
    transform: translate(-50%, -50%);
    .footer{
      display: flex;
      justify-content: space-between;
      padding: 0rem;
       
        
      a,p{
          font-size: 1.2rem;
          line-height: 1.4rem;
          color: $primary-color-black;
          text-transform: capitalize;
        }
      &-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div{
          // content: "";
          height: 1.5rem;
          max-width: .1rem;
          background-color: $secondary-color-white;
          margin: 0 1rem;
        }
        a{
          color: $secondary-color-white;
        }
         @media screen and (max-width: 756px) {
           a{
            color: $secondary-color-black;
            // font-size:10px;
            //   clear: both;
            // display: inline-block;
            // white-space: nowrap;
           }
        }
      }
      @media screen and (max-width: 756px) {
           top:40px;
          left:40px;
        }
        @media screen and (max-width: 570px) {
           top:20px;
          left:0px;
          &-right,
          &-left{
            display: flex;
            justify-content: center;
          }
        }
    }
  }
}
</style>
