<template class="CiviCRMListingGrid" >
  <div class="table-view">
      <div class="table-view__header d-flex m-0 p-0">
      <div class="table-view__header--left p-0">
        <div class=" p-0 m-0 form-group search d-flex flex-wrap align-items-center ">
            
            <label for="search" class="mr-1 d-flex  align-items-center justify-content-center search-by-filter  px-0 pr-sm-0 pr-3">Select&nbsp;File&nbsp;Type&nbsp;:
            <span class="search-by-filter__select">
              <select
                class="search-group search-by-filter__select mx-sm-2 mx-0 px-1 my-1"
                placeholder=" "
                name="search-group"
                id="search-group"
                v-model="selectFileType"
              >
              <option selected value="1">
                  <p>Organization</p>
                </option>
                <option value="2">
                  <p>Contact</p>
                </option>
              
              </select>
            </span>
            </label>
             <span class="btn--primary mx-0 p-0">
            <BtnPrimary :buttonName="`Download`" @click="Download()" />
          </span>
          </div>
      </div>
      <div class="table-view__header--right p-0 d-flex justify-content-end ">
        <div class="search">
          <div class="d-flex flex-wrap " >
          <label for="search" class="ml-1  px-0 pr-sm-0 pr-4 d-flex align-items-center search-by-filter">Search&nbsp;By:
          <span class="search-by-filter__select" >
            <select
            class="search-group search-by-filter__select mx-sm-2 mx-0 px-1 my-1"
            placeholder=" "
            name="search-group"
            id="search-group"
            @change="clearFilter()"
            v-model="selectOptionToFilter"
          >
            <option selected value="all">
              <p>All</p>
            </option>
            <option value="exportedDate">
              <p>Exported Date</p>
            </option>
            <option value="exportedBy">
              <p>Exported By</p>
            </option>
            <option value="exportedType">
              <p>Exported File Type</p>
            </option>
            <option value="recordCount">
              <p>Record Count</p>
            </option>
          </select>
          </span>
          </label>
          </div>
          <span class=" mx-1 d-flex search-by-input " >
          <input
            type="text"
            class="search-by-input__input"
            name="searchByKey"
            id="searchByKey"
            v-model="filter"
            placeholder="Search here"
          />
          <button class="reset-button" @click="ResetSort()">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
          </span>  
             
          <div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="table-view__table">

      <table class="table">
        <thead>
         <tr class="">
        <th @click="sortCol('exportedDate')" class="tabel-header  " >
              <span class="tabel-header__data" :class="values.currentSort=='exportedDate'?'show-icon':''" >
                <p class="tabel-header__title">Exported Date</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('exportedBy')" class="tabel-header " >
              <span class="tabel-header__data" :class="values.currentSort=='exportedBy'?'show-icon':''">
                <p class="tabel-header__title">Exported By</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            
               <th @click="sortCol('exportedType')" class="tabel-header ">
              <span class="tabel-header__data" :class="values.currentSort=='exportedType'?'show-icon':''">
                <p class="tabel-header__title">Exported File Type </p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
           <th @click="sortCol('recordCount')" class="tabel-header ">
              <span class="tabel-header__data" :class="values.currentSort=='recordCount'?'show-icon':''">
                <p class="tabel-header__title">Record Count</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr v-for="item in displayedPosts" :key="item" 
          class=" " >
            <td class="tabel-header" >
              <p v-html="highlightMatches(item['exportedDate'])"></p>
            </td>
              <td class="tabel-header">
                 <p v-html="highlightMatches(item['exportedBy'])"></p>
            </td>
            <td class="tabel-header">
                <p v-html="highlightMatches(item['exportedType'])"></p>
            </td>
            <td class="tabel-header">
                <p v-html="highlightMatches(item['recordCount'])"></p>
            </td> 
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import axios from 'axios'

export default {
  components: {
    BtnPrimary
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type:Number,
      default:10
      },
      TableHeight:{
        type:Number,
      },
    columns: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      selectFileType: 1,
      filter: "",
      list: this.items,
      unsort:false,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages:[],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
    };
  },
  beforeUpdate() {
    this.list = this.items;
  },
  methods: {

    clearFilter(){
      this.filter='';
    },
  Download() {
       this.$store.dispatch("updateLoaderFlag", true);
      let token = localStorage.getItem("token");
        let config = {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
          params: {
            type: this.selectFileType             
          },
        }; 

        var dateObj = new Date();
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();
        
        var newdate =  month.toString().padStart(2, "0")+ "_" + day.toString().padStart(2, "0")+ "_" +year ;
      


        let filename="NUL_Org_"+newdate;
        if(this.selectFileType==2)
        { filename="NUL_Contact_"+newdate; }
          
        
         axios.get('admin/ExportCiviCRMData', config).then(res => {
              if (res.status === 200) {
                let blob = new Blob([res.data], { type: res.headers["content-type"] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
                  this.$store.dispatch("updateLoaderFlag", false);
              }
            })
              .catch(err => {
                 console.log(err);
                  this.$store.dispatch("updateLoaderFlag", false);
              })
          }, 
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;
    },
       highlightMatches(text) {
      let matchExists 
      
      if (!matchExists || this.filter==null) return text;
else{
          matchExists = text.toLowerCase().includes(this.filter);
      }
      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
      sortCol(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.values.currentPage;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.values.totalPages =Math.ceil(posts.length / this.perPage);
      if(this.values.totalPages===0){
        this.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.filteredRows)
        return "";
      return this.filteredRows.length;
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
      
    setTableHeight(){
      return 'height: ' + this.TableHeight +'vh';
    },
     sortTable() {
      let lists = this.list;
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
     
      return data;
       

    },
    filteredRows() {
        return this.sortTable.filter((row) => {
          let exportedDate 
        if(row.exportedDate ==null){
            exportedDate =''
        }
        else{
            exportedDate = row.exportedDate.toString().toLowerCase();
        }
        let exportedBy 
        if(row.exportedBy==null){
            exportedBy=''
        }
        else{
            exportedBy= row.exportedBy.toString().toLowerCase();
        }
        let exportedType 
        if(row.exportedType==null){
            exportedType=''
        }
        else{
            exportedType= row.exportedType.toString().toLowerCase();
        }
        let recordCount 
        if(row.recordCount==null){
            recordCount=''
        }
        else{
            recordCount=  row.recordCount.toString().toLowerCase();
        }
     
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
          exportedDate.includes(searchTerm) ||
            exportedBy.includes(searchTerm) ||
            exportedType.includes(searchTerm) ||
            recordCount.includes(searchTerm) ;
        }
        if (this.selectOptionToFilter == "exportedDate") {
          filtedItems = exportedDate.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "exportedBy") {
          filtedItems = exportedBy.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "exportedType") {
          filtedItems = exportedType.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "recordCount") {
          filtedItems = recordCount.includes(searchTerm);
        }
       
        return filtedItems;
      });
    },
    displayedPosts() {
			return this.paginate(this.filteredRows);
		}
  ,
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    }
  },
  watch: {
		posts () {
			this.setPages();
		}
	},
};
</script>
<style lang="scss" scoped>

@import "@/assets/styles/_mixins.scss";
  @include table-style;
.CiviCRMListingGrid{
  .form-group {
    padding: 0;
    margin-top: 0.5rem;
    position: relative;
    @include input-style;
  }
  .btn--primary {
        min-width: 19rem;
        button {
          font-size: 1.6rem;
          padding: 0 1rem;
        }
      }
      
}
.table-view__table .table {
    width: 100%;
    min-width: 50rem;
}
</style>
