<template>
  <div class="graph">
    <div class="graph--body ">
      <div class="graph--body__header row m-0 p-0">
        <div class="col-10 d-flex px-1">
          <h3 class="heading--text ">
            {{ heading }}
          </h3>
        </div>
        <div class="col-2  graph-change-btns d-flex justify-content-center align-items-center p-0 pr-1"  v-if="chartData.type === 'bar'">
          <button
          v-show="IsPreviousPage"
            type="button"
            title="Previous"
            class="graph-change-btn back-arrow"
            @click="backPage"
          >
            <img src="@/assets/imgs/arrow-down.png" alt="back arrow" />
          </button>
          <button
          v-if="totalSize>10"
           class="graph--currentPageNumber">
            {{currentPageNumber}}
          </button>
          <button
          v-show="IsNextPage"
            type="button"
            title="Next"
            class="graph-change-btn next-arrow"
            @click="nextPage"
          >
            <img src="@/assets/imgs/arrow-down.png" alt="next arrow" />
          </button>
        </div>
        
      </div>
      <div class="graph--body__main d-flex align-items-center justify-content-center" v-if="showChart1 || showChart===false" :class="chartType" >
          <p class="graph--body__main__para">No data available </p>
      </div>
      <div class="graph--body__main" v-if="showChart1  || showChart" :class="chartType" @click="dashboardPopup(name,programid)" >
        <vue3-chart-js v-bind="{ ...chartData }" class="chart" />
      </div>
    </div>
  </div>
    <Transition name="modal">
    <div v-if="showCentralPopUp == true" class="modal-mask">
      <div class="modal-wrapper" style="margin: auto;
  width: 70%;
  padding: 10px;">
        <div class="modal-container">
         <DashboardPopup 
              :title="chartModal.title"
              :name="chartModal.name"
              :items="chartModal.items"
         />
          <div class="modal-footer justify-content-center">
          <slot name="footer">
            <div class=" d-flex  pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Close`"
                    @click="closePopUp()"
                  />
                </span>
              </div>
            </div>
           </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import DashboardPopup from "@/components/DashBoardPopup.vue";
import axios from "axios";
import BtnPrimary from "@/components/BtnPrimary.vue";
export default {
  components: {
    Vue3ChartJs,
    DashboardPopup,
    BtnPrimary
  },
  props: {
    showChart:{
      required: true,
      type:Boolean
    },
    heading: {
      type: String,
      required: true,
    },
    graphData: {
      required: true,
    },
    chartType:{
      required: true,
    },
    programid:{
      type:Number
    },
    TotalCount:{
      type:Number
    },
    currentPageNumber:{
      type:Number
    },
    totalSize:{
      type:Number
    },
    pageEnd:{
      type:Number
    },
    IsPreviousPage:{
      type:Boolean
    },
    IsNextPage:{
      type:Boolean
    },
    name:{
      type: String,
    }
  },
  data() {
    return {
      showChart1:false,
      posts: [""],
      postDuplicate: [],
      page: 1,
      currentPage: this.currentPageNumber,
      perPage: 5,
      pages: [],
      numberOfPages: "",
      xObject: {},
      currentPagenationNumber:1,
      countData:0,
      showCentralPopUp:false,
       chartModal:
        {
                items:[],
                perPage : 10,
                filter: "",
                
                unsort:false,
                selectOptionToFilter: "all",
                isClearSort: false,
                labels: {
                  ShowingResults: "Showing Results",
                  ShowFilters: "Show Filters",
                  Results: "Results",
                  Result: "Result",
                  Rowsperpage: "Rows per page:",
                  pages: "Pages",
                  of: "of",
                  },
                  values: {
                    pages: [],
                    totalPages: 1,
                    currentPage: 1,
                    currentSort: "",
                    currentSortDir: "asc",
                    isSearchByAll: true,
                  },
              }
    };
  },
  
  mounted() {
    this.renderChart();
    this.showChart1 = this.$store.showChart;    
  },
  created() {
    
    this.posts = this.chartData;
    for (const [key, value] of Object.entries(this.graphData)) {
      this.xObject[key] = value;
    }
  },
  computed: {
    chartData: function() {
      return this.graphData;
    },
  },
  methods: {
     closePopUp()
    {
      this.showCentralPopUp = false;
      this.$store.dispatch("updateLoaderFlag", false);
    } ,  
   async dashboardPopup(name,programid)
    {
        switch (name) {
              case "FundAllocationDetail":
                  this.chartModal.title = 'Fund Allocation Details';
                  this.chartModal.name = "FundAllocationDetail";
                  this.fetchDashboardFundAllocationDetail(programid)
                break;
                case "ProgramWiseFundAllocation":
                  this.chartModal.title = 'Fund Allocation Details';
                  this.chartModal.name = "FundAllocationDetail";
                  this.fetchDashboardFundAllocationDetail(programid)
                break;
                case "ApplicationStats":
                  this.chartModal.title = 'Application Stats Details';
                  this.chartModal.name = "ApplicationStats";
                  this.fetchDashboardApplicationStatsDetail(programid);
                break;
                case "ProgramWiseApplicationStats":
                   this.chartModal.title = 'Application Stats Details';
                   this.chartModal.name = "ApplicationStats";
                   this.fetchDashboardApplicationStatsDetail(programid);
                break;
                case "AffiliatewiseFundUtilized":
                  this.chartModal.title = 'Affiliate Wise Fund Utilized';
                   this.chartModal.name = "AffiliatewiseFundUtilized";
                   this.fetchDashboardAffiliateWiseFundUtilizedDetailByProgram(programid)
                break;
                case "AffiliateWiseApplicationStats":
                  this.chartModal.title = 'Affiliate Wise Application Stats';
                   this.chartModal.name = "AffiliateWiseApplicationStats";
                  this.fetchDashboardAffiliateWiseApplicationStatsDetailByProgram(programid)
                break;
        }
    },
     async fetchDashboardFundAllocationDetail(programId) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramID: parseInt(programId),
        },
      };
      /*let params = {
          reportType:reportType,
          
          label:label
        }*/
      await axios
        .get("/Dashboard/FetchDashboardFundAllocationDetailByProgram", config)
        .then((res1) => {
           this.chartModal.items = res1.data.fundAllocationDetail;
          this.showCentralPopUp = true;
          //this.$store.dispatch("updateLoaderFlag", true);
           //this.showChartB = true;
          //this.showChart = 'showChartB';
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
     async fetchDashboardApplicationStatsDetail(programId)
    {
      
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramID: parseInt(programId),
        },
      };
     /* let params = {
          reportType:reportType,
          programId: parseInt(programId),
          label:label
        }*/
        
      await axios
        .get("/Dashboard/FetchDashboardApplicationStatsDetailByProgram", config)
        .then((res1) => {
           this.chartModal.items = res1.data.applicationStatsDetail;
           this.showCentralPopUp = true;
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
     async fetchDashboardAffiliateWiseFundUtilizedDetailByProgram(programId)
    {
      
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramID: parseInt(programId),
        },
      };
     /* let params = {
          reportType:reportType,
          programId: parseInt(programId),
          label:label
        }*/
        
      await axios
        .get("/Dashboard/FetchDashboardAffiliateWiseFundUtilizedDetailByProgram", config)
        .then((res1) => {
           this.chartModal.items = res1.data.fundDetailAffiliateWise;
           this.showCentralPopUp = true;
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
     async fetchDashboardAffiliateWiseApplicationStatsDetailByProgram(programId)
    {
      
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
         params: {
          ProgramID: parseInt(programId),
        },
      };
      console.log(config);
     /* let params = {
          reportType:reportType,
          ProgramID: programId,
          label:label
        }*/
        
      await axios
        .get("/Dashboard/FetchDashboardAffiliateWiseApplicationStatsDetailByProgram", config)
        .then((res1) => {
           this.chartModal.items = res1.data.applicationStatsDetailAffiliateWise;
           this.showCentralPopUp = true;
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
    renderChart() {
      this.graphData;
    },
    setPages() {
      if (this.chartData.type === "bar") {
        this.numberOfPages = Math.ceil(
          this.chartData.data.labels.length / this.perPage
        );
        for (let index = 1; index <= this.numberOfPages; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate(posts) {
      if (this.chartData.type === "bar") {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return posts.data.labels.slice(from, to); //postData;//.data.labels.slice(from, to);
        
      } else {
        return this.chartData;
      }
    },
    backPage() {
      let BackIndex=this.currentPagenationNumber-10;
      this.currentPagenationNumber= BackIndex;
      this.currentPage= this.currentPage-1
      this.$emit('GetBarPagination',this.programid,this.currentPageNumber-1)
      this.countData=this.graphData.data.labels.length//this.countData-10
      
    },
    nextPage() {
      let NextIndex=this.currentPagenationNumber+10;
      this.currentPagenationNumber= NextIndex;
      this.currentPage= this.currentPage+1
      this.$emit('GetBarPagination',this.programid,this.currentPageNumber+1)
      
      this.countData= this.graphData.data.labels.length//this.countData+10
      
    },
  },
  
  watch: {
    graphData: function() {
      this.renderChart();
    }
  }
};
</script>
<style lang="scss" scoped>
.graph {
  height: 100%;
  &--body {
    margin: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem $primary-color-grey;
    height: 100%;
    &__header {
      min-height: 6rem;
      padding: 1rem;
      border-bottom: 0.2rem solid $tertiary-color-white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .heading--text {
        color:$tertiary-color-grey;
        font-weight: bold;
        padding-left: 4rem;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin: 0rem;
        
      }
      .graph-change-btn {
          font-size: 2rem;
          font-weight: bold;
          padding: 0.5rem;
          margin: 0rem;
          background: none;
          outline: none;
          border: none;
        }
        .graph--currentPageNumber{
          border: 1px solid $primary-color-red;
          border-radius: .5rem;
          height: 2rem;
          color: $tertiary-color-grey;
        }
        .graph--currentPageNumber,
        .graph-change-btn{
          padding: 0.5rem;
          background-color: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          
        }
        .back-arrow,
        .next-arrow{
          height: 3rem;
          img{
          height: 100%;
          }
        }
        .back-arrow{
           transform: rotate(90deg); 
        }
        .next-arrow{
           transform: rotate(270deg); 
        }
    }
    &__main {
      max-width: 90%;
      min-height: 80%;
      margin: auto;
      padding: 1rem;
      &__para{
        font-size: 1.4rem;
        color: $primary-color-grey;
      }
    }
  }
}
 .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
      justify-content: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
  
     table, tr td {
   
}
tbody {
    display: block;
    height: 50px;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
    width: 400px;
} 
</style>
