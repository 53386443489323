<template>
  <div class="report">
    <BasicComponent :heading="'Cumulative Report'">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>
      <br />
      <template v-slot:mainSection>
      <div class="report--header-section row m-0 p-0 mt-5 pl-3">
       <div class="  col-12 m-0 p-0">
             <!-- <select
                      name="programid"
                      id="programid"
                      v-model="inputs.programid"
                    >
                      <option v-if="isAllSelectForProgramInvitation==true" value="0"> <p>All</p></option>
                      <option
                        v-for="programInvitation in programs"
                        :key="programInvitation.programId"
                        :value="programInvitation.programId"
                      >
                        {{ programInvitation.programName }}
                      </option>
                    </select>
                    <label class="form__label"> Program:</label>-->
                    <div class="multiselect ">
                                <div
                                  class="multiselect__selectBox "
                                  @click="showCheckboxes()"
                                >
                                  <label class="multiselect__selectBox__label" for=""
                                    >Program Name</label>
                                  <span
                                    class="multiselect__selectBox__show-selectedBox "
                                  >
                                    <span
                                      class="multiselect__selectBox__show-selectedBox__display"
                                      v-for="CheckedData in showCheckedData"
                                      v-bind:key="CheckedData.programId"
                                    >
                                      <span class="CheckedData">{{
                                        CheckedData.programName
                                      }}</span>
                                    </span>
                                  </span>
                                  <button class="arrow-buttons" >
                                    <img
                                    class="arrow-buttons__img"
                                      src="@/assets/imgs/arrow-up.png"
                                      alt="up arrow"
                                    />
                                    <img
                                    class="arrow-buttons__img"
                                      src="@/assets/imgs/arrow-down.png"
                                      alt="down arrow"
                                    />
                                    
                                  </button>
                                </div>
                                <!-- {{testData}} -->
                                <div class="checkboxes" v-if="isShowCheckboxesOption" >
                                  <div
                                    v-for="program in testData"
                                    :key="program.programId"
                                    class="checkbox-div"
                                    :class="{
                                      'checkbox-checked': showCheckedData.includes(
                                        program
                                      ),
                                    }"
                                  >
                                    <input
                                      class="checkboxes__input p-0 m-0"
                                      type="checkbox"
                                      :id="program.programId"
                                      :value="program"
                                      @change="onChanceOfProgramData($event, program)"
                                      v-model="showCheckedData"
                                    />
                                    <label
                                      class="checkboxes__label p-0 m-0 pl-2"
                                      :for="program.programId"
                                    >
                                      {{ program.programName }}
                                    </label>
                                  </div>
                                </div>
                              </div>
      </div>
      </div>
        <div class="report--header-section row m-0 p-0 mt-5 pl-3">
          
          <div class="date-picker-section col-12 row m-0 p-2">
            <div class="col-sm-2 col-6 form-group ">
              <flat-pickr
                v-model="inputs.fromDate"
                :config="config"
                required
                name="FromDate"
                id="FromDate"
                class="datepicker"
              >
              </flat-pickr>
              <label for="FromDate" class="form__label required">
                From Date:</label
              >
            </div>
            <div class="col-sm-2 col-6 form-group ">
              <flat-pickr
                v-model="inputs.toDate"
                :config="config"
                name="ToDate"
                required
                id="ToDate"
                class="datepicker"
              >
              </flat-pickr>
              <label for="ToDate" class="form__label required"> To Date:</label>
            </div>
             <div class="col-sm-5 col-6 form-group ">
              <select
            class="search-by-program__filter__select"
              placeholder=" "
              name="FundingEntity"
              id="FundingEntity"
              v-model="inputs.fundingEntityId"
             @change="getFundingEntityId(fundingEntityId)"
            >
              <option :value="all" selected   > <p>All</p></option>
              <option
                v-for="fundingEntity in fundingEntities"
                :key="fundingEntity.fundingEntityId"
                :value="fundingEntity.fundingEntityId"
              >
                {{ fundingEntity.fundingEntityName }}
              </option>
            </select>
            <label for="FundingEntity" class="form__label required">
                Funding Entity:</label
              >
             </div>
             <div class="d-flex justify-content-sm-end justify-content-center p-2 mb-6">
          <span class="mx-sm-4 mx-2">
              <BtnPrimary :buttonName="`Search`" @click="searchBasedOnDate()" />
            </span>
            <span class="btn--primary">
              <BtnSecondary
                :buttonName="`Export to Excel`"
                @click="exportConsolidatedReport"
              />
            </span>
        </div>
          </div>
       
          <div class="button-section col row d-flex justify-content-end"></div>
        </div>
        
        
        <div class="report--body">
          <div
            class="report--body-section"
            v-for="(data, heading) in cumulativeReportData"
            :key="heading"
          >
            <div class="report--body-section__cards" @click="fetchReportDetailByReportType(heading)">
              <SimpleCard :heading="heading">
                <template v-slot:cardData>
                  <div class="card--data">
                    <p
                      class="card--data--text"
                      :class="heading == 'Fund Released' ? 'prefix-dollar' : ''"
                    >
                      {{ data }}
                    </p>
                  </div>
                </template>
              </SimpleCard>
            </div>
          </div>
        </div>
      </template>
    </BasicComponent>
    <LoadingMask v-if="isLoading === true" />
  </div>
  
      <Transition name="modal">
    <div v-if="showCentralPopUp == true" class="modal-mask">
      <div class="modal-wrapper" style="margin: auto;
  width: 70%;
  padding: 10px;">
        <div class="modal-container">
        <CumilitiveReportPopup 
              :title="cumulativeReportDataPopup.heading"
              :name="cumulativeReportDataPopup.reportType"
              :items="cumulativeReportDataPopup.items"
         />
          <div class="modal-footer justify-content-center">
          <slot name="footer">
            <span class="pd-4">
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Close`"
                    @click="closePopUp()"
                  />
                </span>
           </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import SimpleCard from "@/components/SimpleCard";
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnSecondary from "@/components/BtnSecondary.vue";
import CumilitiveReportPopup from "@/components/CumilitiveReportPopup.vue";
export default {
  data() {
    return {
       testData: [],
       showCheckedData: [],
       isShowCheckboxesOption: false,
      crumbs: ["Reports"],
      cumulativeReportData: {},
      cumulativeReportDataPopup:{
        heading:"",
        reportType:"",
        items:[]
      },
      showCentralPopUp : false,
      inputs: {
        toDate: "",
        fromDate: "",
        programid: 0,
      },
      config: {
        dateFormat: "m/d/Y",
        disableMobile: "true",
      },
    };
  },
  components: {
    BreadCrumb,
    SimpleCard,
    BtnPrimary,
    BtnSecondary,
    BasicComponent,
    flatPickr,
    CumilitiveReportPopup
  },
  beforeMount() {
    this.$store.dispatch("updateLoaderFlag", true);
    
  },
  mounted() {
    this.$store.dispatch("updateLoaderFlag", true);
    //this.inputs.fromDate = "";
    //this.inputs.todate = "";
    this.getPrograms()
    .then(()=>{
    this.searchBasedOnDate();
    })

    //let programid = 0;
    //this.GetConsolidatedReportData(this.inputs.fromDate,this.inputs.todate,this.inputs.programid);
    //    this.exportConsolidatedReport(this.inputs.fromDate,this.inputs.todate,this.inputs.programid);
  },
  methods: {
    closePopUp()
    {
      this.showCentralPopUp = false;
    } ,
    //  reportProgram(programid) {
    //   this.getProgramName();
    //   if (this.programid == "0") {
    //     this.GetConsolidatedReportData(programid);
    //     this.exportConsolidatedReport(programid);

    //   } else {
    //     this.GetConsolidatedReportData(programid);
    //     this.exportConsolidatedReport(programid);
    //   }
    // },
    getProgramName() {
      if (this.inputs.programid !== "0") {
        return this.programs.find((x) => x.programid == this.programid)
          .programName;
      } else {
        return "";
      }
    },
    getFundingEntityId(fundingEntityId)
    {
      this.fundingEntityId = fundingEntityId;
      this.$emit('getFundingEntityId',fundingEntityId)
    },
onChanceOfProgramData(e, program) {
      //  if    current checkbox is all remove other from showCheckedData
      if (program.programId == 0 && e.target.checked == true) {
        this.showCheckedData = [{ programId: 0, programName: "All" }];
      }
      //else
      // check isthere programId === 0 , then remove it .
      else {
        let AllValueindex = this.showCheckedData.findIndex(
          (x) => x.programId == "0"
        );
        if (AllValueindex >= 0) {
          this.showCheckedData.splice(AllValueindex, 1);
        }
      }
    },
    showCheckboxes() {
      this.isShowCheckboxesOption = !this.isShowCheckboxesOption;
    },
    // to get the program names
    async getPrograms() {
      await this.$store
        .dispatch("createProgramInvitation")
        .then((res) => {
          
            const data = this.programs;
            console.log(this.programs)
            this.testData = data;
            this.testData.unshift({ programId: 0, programName: "All" });
            this.inputs.programid=this.programs[0].programId
          
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
        });
    },    
    async searchBasedOnDate() {
       let programInvitations = [];
       let fundingenititesdata = [];
       console.log(this.inputs.fundingEntityId);
       fundingenititesdata.push(this.inputs.fundingEntityId == undefined ? 0 :this.inputs.fundingEntityId );
      programInvitations = this.showCheckedData.map((item) => {
        return item.programId;
      });
      if(programInvitations.length<=0){
         programInvitations= [0]
        }
      let param = {
        fromdate: this.inputs.fromDate,
        todate: this.inputs.toDate,
        programID: programInvitations,
        fundingEntityID: fundingenititesdata,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      await this.$store
        .dispatch("GetConsolidatedReportData", param, {
          root: true,
        })
        .then((res) => {
          this.cumulativeReportData = res.data.cumulativeReportData;
          this.$store.dispatch("updateLoaderFlag", false);
          this.inputs.fromDate = res.data.fromdate;
          this.inputs.toDate = res.data.todate;
          this.inputs.programID = res.data.programid;
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("updateLoaderFlag", false);
        });
    },
    exportConsolidatedReport() {
      let programInvitations = [];
       let fundingenititesdata = [];
       console.log(this.inputs.fundingEntityId);
       fundingenititesdata.push(this.inputs.fundingEntityId == undefined ? 0 :this.inputs.fundingEntityId );
      programInvitations = this.showCheckedData.map((item) => {
        return item.programId;
      });
      if(programInvitations.length<=0){
         programInvitations= [0]
        }
      let param = {
        fromdate: this.inputs.fromDate,
        todate: this.inputs.toDate,
        programID: programInvitations,
        fundingEntityID: fundingenititesdata,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("exportConsolidatedReport", param, { root: true })
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
          this.toasterMessage(
            "",
            "The download isn't completed. Please try again later.",
            "danger"
          );
        });
    },
    async fetchReportDetailByReportType(heading)
    {
      let programInvitations = [];
       let fundingenititesdata = [];
       console.log(this.inputs.fundingEntityId);
       fundingenititesdata.push(this.inputs.fundingEntityId == undefined ? 0 :this.inputs.fundingEntityId );
      programInvitations = this.showCheckedData.map((item) => {
        return item.programId;
      });
      if(programInvitations.length<=0){
         programInvitations= [0]
        }
      this.cumulativeReportDataPopup.heading =heading;
      if(heading === 'Invitation Sent')
      {
        this.cumulativeReportDataPopup.reportType ='INVITED';
      }
      else  if(heading === 'Active Account')
      {
        this.cumulativeReportDataPopup.reportType = 'ACTIVATEDACCOUNT';
      }
      else  if(heading === 'Application Started')
      {
         this.cumulativeReportDataPopup.reportType = 'STARTED'
      }
      else  if(heading === 'Application Submitted')
      {
         this.cumulativeReportDataPopup.reportType = 'SUBMITTED'
      }
      else  if(heading === 'Application Funded')
      {
         this.cumulativeReportDataPopup.reportType = 'FUNDED'
      }
      else  if(heading === 'Fund Released')
      {
         this.cumulativeReportDataPopup.reportType = 'FUNDRELEASED'
      }
       let param = {
        fromdate: this.inputs.fromDate,
        todate: this.inputs.toDate,
        programID: programInvitations,
        fundingEntityID: fundingenititesdata,
        reportType:this.cumulativeReportDataPopup.reportType
      };
      this.$store.dispatch("updateLoaderFlag", true);
          await this.$store
        .dispatch("fetchReportDetailByReportType", param, {root: true,})
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          
          if(heading === 'Invitation Sent')
      {
       this.cumulativeReportDataPopup.items  = res.data.invitationDetail;
      }
      else  if(heading === 'Active Account')
      {
        this.cumulativeReportDataPopup.items  = res.data.activeAccountDetail;
      }
      else  if(heading === 'Application Started')
      {
         this.cumulativeReportDataPopup.items  = res.data.applicationStartedDetail;
      }
      else  if(heading === 'Application Submitted')
      {
         this.cumulativeReportDataPopup.items  = res.data.applicationSubmittedDetail;
      }
      else  if(heading === 'Application Funded')
      {
         this.cumulativeReportDataPopup.items  = res.data.applicationFundedDetail;
      }
      else  if(heading === 'Fund Released')
      {
        this.cumulativeReportDataPopup.items  = res.data.fundReleasedDetail;
      }
          console.log("Test"+this.cumulativeReportDataPopup.items + "  name:" + this.cumulativeReportDataPopup.reportType);
          this.showCentralPopUp = true;
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("updateLoaderFlag", false);
          this.showCentralPopUp = false;
        });
    }
  },
  computed: {
    isAllSelectForProgramInvitation:{
      get(){
        return this.$store.state.business.temporary.isAllSelectForProgramInvitation
      }
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      },
    },
    fundingEntities()
    {
          return this.$store.state.business.temporary.fundingEntity;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.report {
  .report--body-section {
    &__cards {
      display: flex;
      flex-wrap: wrap;
      .card {
        &--data--text {
          min-width: 24rem;
          color: $primary-color-grey;
          font-size: 3.2rem;
          opacity: 0.75;
          padding: 0rem 1rem;
          text-align: center;
        }
        .prefix-dollar {
          ::before {
            content: "$";
            color: $primary-color-grey;
            font-size: 3.2rem;
          }
        }
      }
    }
  }
  .report--body {
    display: flex;
    flex-wrap: wrap;
    height: 50vh;
    overflow-y: auto;
  }
  .report--header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date-picker-section {
      .form-group {
        padding: 0;
        position: relative;
        @include input-style;
      }
    }
  }


}
  .multiselect {
  padding-right: 2%;
  &__selectBox {
    border-bottom: 1px solid $primary-color-black;
    position: relative;
    &__label {
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    &__show-selectedBox {
      width: 95%;
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &__display {
        padding: 0.5rem;
        border: 0.2rem solid $primary-color-red;
;
        align-items: center;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 1rem;
        // background-color: $secondary-color-pink;
        white-space: nowrap;
        font-size: 1.4rem;
      }
    }
    .arrow-buttons{
      position: absolute;
      right: 1%;
          bottom: 25%;
      height: 2rem;
    display: flex;
    flex-direction: column;
    background: transparent;
    border: none;
    outline: none;
    &__img{
      height: 50%;
    }
    }
    
  }
  .CheckedData {
    padding: 0.5rem;
  }
  .show-selectedBox {
    display: flex;
    padding: 1rem;
    overflow-x: hidden;
    &__display {
      padding: 0.5rem;
      border: 1px solid;
      align-items: center;
      margin: 1rem;
      border-radius: 1rem;
      background-color: $secondary-color-pink;
      white-space: nowrap;
    }
    .CheckedData {
    }
  }

  .checkboxes {
    max-height: 15rem;
    overflow-y: auto;
    .checkbox-div {
      display: flex;
      align-items: center;
      margin: 0.2rem 0rem;
    }
    &__label,
    &__input {
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
    &__label {
      width: auto;
      color: $primary-color-grey;
    }
    .checkbox-checked {
      background-color: $tertiary-color-white;
    }
  }
}
  .selectBox select {
  width: 100%;
}
 .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
      justify-content: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
  
     table, tr td {
   
}
tbody {
    display: block;
    height: 50px;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
    width: 400px;
} 
</style>
