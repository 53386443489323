<template>
  <div class="document">
    <HeaderNav />
    <div class="document--content">
      <div class="document--content__Header d-flex">
        <div class="document--content__Header--breadCrumb align-self-start">
          <BreadCrumb class="breadcrumb" :crumbs="crumbs" />
        </div>
        <span class="btn--primary" v-if="userRoleID !=8">
          <BtnPrimary :buttonName="`New Document`" @click="createDocument" />
        </span>
    </div>
        <div class="TrackingList__table-section">
          <div class="mt-3 listing-content ">
            <DocumentTable
              class="listingTable"
              :items="items"
              :columns="columns"
              :TableHeight="TableHeight"
              :TableBodyHeight="TableBodyHeight"
              :perPage="itemsPerTable"
              @selectId="selectRow"
            />
          </div>
        </div>
      
    </div>
    <LoadingMask v-if="isLoading === true" />
    <Footer />
  </div>
</template>
<script>
import DocumentTable from "@/components/Document/documentTable";
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import BreadCrumb from "@/components/BreadCrumb";
import BtnPrimary from "@/components/BtnPrimary.vue";
export default {
  data() {
    return {
      crumbs: ["Documents"],
      itemsPerTable: 10,
      TableBodyHeight: 40,
      TableHeight: 52,
      items: [],
    };
  },
  components: {
    HeaderNav,
    Footer,
    BreadCrumb,
    DocumentTable,
    BtnPrimary,
  },

  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  created() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.getDocumentList()

  },
  methods: {
    selected(crumb) {
      if (crumb == "Business Entities") {
        this.$router.push("/documents");
      }
    },
    selectRow(data) {
      if (data > 0) {
        this.$router.push("/document/form/" + data);
      }
    },
    async getDocumentList() {
      this.$store.dispatch("updateLoaderFlag", true);
    //   this.popup = true;
      await this.$store
        .dispatch("getDocumentList", { root: true })
        .then((res) => {
          if (res.data.documentRecords && res.data.documentRecords.length > 0) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.items = res.data.documentRecords;
          }
        })
        .catch(() => {
          this.$store.dispatch("updateLoaderFlag", false);
          this.LoginError = true;
        });
    },
    async createDocument() {
      this.$router.push("/document/form/0");
    },
  },
};
</script>
<style lang="scss" scoped>
.document {
  &--content {
    padding: 0rem 2%;
    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn--primary {
        min-width: 19rem;
        button {
          font-size: 1.6rem;
          margin: 1.5rem;
          padding: 0 1rem;
        }
      }
    }
    &__name {
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
    }
    @media screen and (max-width: 580px){
      min-height: 90vh;
    }
  }
}
</style>
