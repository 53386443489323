<template class="error">
    <span class="error-msg">
          <span class="error-msg__icon"> </span>
          <slot>
          </slot>
          <!-- <p v-for="(error, index) of errors" :key="index">
            <small
              v-if="error.$propertyPath === matchingInput"
            >
              {{ error.$message }}
            </small> 
          </p>-->
        </span>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.error{
@include input-style
}
</style>