<template>
    <div class="landing-page ">
        <HeaderNav />
        <div class="welcome__name">
                <h3>
                    {{welcomeName}} {{userName}}
                </h3>
            </div>
        <Welcome  class="welcome-component"/>
        <Footer />
    </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav"
import Welcome from "@/components/Welcome"
import Footer from "@/components/Footer"
export default {
    components:{
        HeaderNav,
        Footer,
        Welcome
    },
    data(){
        return{
             welcomeName:'Welcome, ',
          subTitle:"Three Steps to Secure Your Funding",

        }
    },
    computed:{
        userName(){
      return this.$store.getters.getUser
    }
    },
     created() {     
        if (localStorage.getItem("userRoleID") != 2) { 
            this.$router.push("/tracks");
        }
     }
}
</script>
<style lang="scss" scoped>
.landing-page{
    // max-height: 100vh !important;
    // max-width: 100vw !important;
    // overflow: hidden;
    .welcome__name{
        padding-top: 8rem;
        padding-left: 5%;
        @media screen and(max-width:1600px) {
            padding-top: 3rem;
        }
        h3{
            font-size: 2.6rem;
            font-weight: bold;
            text-transform: capitalize;
            color: $primary-color-red;
        }
    }
    position: relative;
    .welcome-component{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-45%);
    }
    height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
}

</style>