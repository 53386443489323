<template>
  <div class="Notification">
    <div class="central-popup">
      <div class="central-popup__main">
        <span class="close-btn">
          <button @click="HideNotificationOriginal()">
            <img src="@/assets/imgs/cancel.svg" alt="close button" />
          </button>
        </span>
        <div>
          <div class="central-popup__main__heading py-0 px-2 m-0">
            <h3>{{ LabelHeading }}</h3>
          </div>

          <div class="MainDiv">
            <p class="LabelNote m-0">{{ LabelNote }}</p>
            <div class="input-values d-flex justify-content-center"  v-if="inputData.length > 0" >
              <div class=" form-check d-flex justify-content-center align-items-center mx-3">
                <input
                    class="mx-1 my-0"
                    type="radio"
                    :id="inputData[0].notificationID"
                    v-model="notificationID"
                    :value="inputData[0].notificationID"
                  />
                <label
                class="my-0"
                  :for="inputData[0].notificationID"  
                >
                  {{ inputData[0].notificationTypeName }}
                </label>
              </div>
              <div class=" form-check d-flex justify-content-center align-items-center mx-3">
                
                <input
                    class="mx-1 my-0"
                    type="radio"
                    :id="inputData[1].notificationID"
                    v-model="notificationID"
                    :value="inputData[1].notificationID"
                  /><label
                  :for="inputData[1].notificationID"
                  class="my-0"
                >
                  {{ inputData[1].notificationTypeName }}
                </label>
              </div>
              <div class=" form-check d-flex justify-content-center align-items-center mx-3">
                <input
                    class="mx-1 my-0"
                    type="radio"
                    :id="inputData[2].notificationID"
                    v-model="notificationID"
                    :value="inputData[2].notificationID"
                  />
                <label
                  :for="inputData[2].notificationID"
                  class="my-0"
                >
                  {{ inputData[2].notificationTypeName }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class=" d-flex justify-content-center pb-4 px-2">
            <div class="mr-5">
              <span>
                <BtnPrimary
                  :buttonName="`SAVE`"
                  @click="SaveNotificationMode()"
                />
              </span>
            </div>
            <div class="p-0">
              <span>
                <BtnSecondary
                  class="btn-secondary"
                  :buttonName="`Cancel`"
                  @click="HideNotificationOriginal()"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <LoadingMask v-if="isLoading === true" />
</template>
<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  data() {
    return {
      notificationID: 0,
      notificationModeTypeID: 0,
      userId: "",
      LabelHeading: "Notification Preference ",
      LabelNote: "Application status notification",
      inputData: [],
    };
  },
  components: {
    BtnPrimary,
    BtnSecondary,
  },
  created() {
    this.userId = localStorage.getItem("contactID");
    this.GetNotificationModeByUser();
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
  methods: {
    HideNotificationOriginal() {
      this.$emit("HideNotificationOriginal");
    },
    GetNotificationModeByUser() {
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("GetNotificationModeByUser", this.userId)
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.inputData = res.data.notificationTypeEntity;
            this.notificationModeTypeID = res.data.notificationModeTypeID;
            this.notificationID = res.data.notificationID;
          }
          Promise.resolve(res);
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
          this.$store.dispatch("updateLoaderFlag", false);
        });
    },
    SaveNotificationMode() {
      let param = {
        notificationModeTypeID: this.notificationModeTypeID,
        notificationMode: this.notificationID,
        userID: this.userId,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("SaveNotificationMode", param)
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.toasterMessage("", res.data.message, "success");
            Promise.resolve(res);
          } else {
            this.toasterMessage("", res.data.message, "danger");
            Promise.reject(res);
          }
          this.$store.dispatch("updateLoaderFlag", false);
          this.$emit("HideNotificationOriginal");
        })
        
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
          this.LoginError = true;
          this.$emit("HideNotificationOriginal");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 20vh;
    min-width: 40%;
    width: auto;
  padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px){
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      input[type=checkbox], input[type=radio],
      .form-check-label{
        font-size: 1.6rem;
        color: $primary-color-black;
      }
      @media screen and(max-width:570px) {
        display: flex;
        flex-direction: column;
        p{
          text-align: center;
        }
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }

  .LabelNote,
  .input-values {
    padding: 1rem;
    // margin: 1rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $primary-color-black;
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
}
</style>
