import axios from 'axios';
const state = {
  civiCRMDataExportData: {
    exportedDate: '',
    exportedBy: '',
    exportedUserID:0,
    exportedType: '',
    recordCount: 0,
  },

};
const getters = {};
const actions = {
  updateBusinessUsersDetails: (vueContext, payload) => {
    vueContext.commit("mutateciviCRMDataExportData", payload);
  },
  
 

  fetchAllCiviCRMDataExportLogs: (vueContext) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
  };
    return new Promise((resolve, reject) => {
      axios.get("/Admin/GetCiviCRMDataExportLog", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess===true) {
            vueContext.commit('mutateciviCRMDataExportData', res.data.exportLogs);
            resolve(res);
          }
          else{
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

};
const mutations = {
  mutateciviCRMDataExportData(state, data) {
    state.civiCRMDataExportData = data;
  },
 
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};