<template>
  <div class="fund-allocation-summary">
    <div class="discription" @click="summary == true ? back() : summaryPage()">
      <p class="discription--data" v-if="summary == true">
        <span class="pr-3">&#60;</span>{{ text.discrption.back }}
      </p>
      <p class="discription--data" v-if="summary == false">
        <span class="pr-3">&#60;</span>{{ text.discrption.summary }}
      </p>
    </div>
    <div v-if="summary == true">
      <div class="info-button">
        <div class="info-section">
          <div class="info-section__amount">
            <h3 class="info-section__amount--data"><span class="form-group--currency">$ </span>{{ totalFundedAmount }}</h3>
            <p class="info-section__amount--label">
              {{ text.totalfundedAmount }}
            </p>
          </div>
        </div>
        <div class="button-section" v-if="userRoleID !=8">
          <div class="add button-section--btn">
            <BtnSecondary buttonName="Add Fund" @click="addFund" />
          </div>
          <div class="remove button-section--btn">
            <BtnSecondary buttonName="Remove Fund" @click="removeFund" />
          </div>
        </div>
      </div>
      <div class="listing">
        <!-- listing table  -->
        <TransactionList
          class="listingTable"
          :items="items"
          :columns="columns"
          :TableHeight="35"
          :perPage="3"
        />
      </div>
    </div>
    <div v-if="addFundSection == true">
      <AddFund @onSave="getTransactionList" @onCancel="summaryPage" />
    </div>
    <div v-if="removeFundSection == true">
      <RemoveFund @onSave="getTransactionList" @onCancel="summaryPage" />
    </div>
  </div>
</template>
<script>
import AddFund from "@/components/FundSourceTracking/FundSource/AddFund";
import RemoveFund from "@/components/FundSourceTracking/FundSource/DeleteFund";
import BtnSecondary from "@/components/BtnSecondary";
import TransactionList from "@/components/FundSourceTracking/FundSource/TransactionList";
export default {
  components: {
    BtnSecondary,
    AddFund,
    RemoveFund,
    TransactionList,
  },
  data() {
    return {
      columns: [
        "Transaction Date",
        "Transaction Type",
        "Transaction amount",
        "Comments",
        //"Attachments",
      ],
      summary: true,
      addFundSection: false,
      removeFundSection: false,
      text: {
        discrption: {
          summary: "Summary",
          back: "Back",
          addFund: "Add Funds",
          removeFund: "Remove Funds",
        },
        totalfundedAmount: "Total funded Amount ",
      },
    };
  },
  computed: {
    items: {
      get() {
        return this.$store.state.fundSource.fundTransactions;
      },
    },
    totalFundedAmount: {
      get() {
           var totalFundedAmountHandleZero = this.$store.state.fundSource.fundingSource.totalFundedAmount;
           if(totalFundedAmountHandleZero == null)
           {
            totalFundedAmountHandleZero = 0;
           }

        return totalFundedAmountHandleZero;
      },

    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  methods: {
    summaryPage() {
      this.summary = true;
      this.addFundSection = false;
      this.removeFundSection = false;
    },
    back() {
      this.$emit("showFundForm");
    },
    addFund() {
      this.$store.state.fundSource.addFund.transactionDocument = {};
      this.$store.state.fundSource.addFund.transactionAmount = 0;
      this.$store.state.fundSource.addFund.comment = "";
      this.$store.state.fundSource.addFund.originatingBankAccount = "";
      this.$store.state.fundSource.addFund.dateOfFunding = "";

      this.summary = false;
      this.addFundSection = true;
    },
    removeFund() {
      this.$store.state.fundSource.removeFund.transactionDocument = {};
      this.$store.state.fundSource.removeFund.transactionAmount = 0;
      this.$store.state.fundSource.removeFund.comment = "";
      this.$store.state.fundSource.removeFund.originatingBankAccount = "";
      this.$store.state.fundSource.removeFund.dateOfFunding = "";

      this.summary = false;
      this.removeFundSection = true;
    },
    getTransactionList(fundingSourceID) {
      this.$store.dispatch("updateLoaderFlag",true)
      if (fundingSourceID > 0)
        this.$store
          .dispatch("getFundTransactionList", fundingSourceID)
          .then((res) => {
            this.fetchFundingSource(fundingSourceID);
            this.summaryPage();
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
          })
          .catch((this.LoginError = true));
    },
    fetchFundingSource(sourceID) {
      if (sourceID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("fetchFundingSource", sourceID)
          .then((res) => {
            var entityID = this.$store.state.fundSource.fundingEntity.fundingEntityID;
            this.getFundSources(entityID);
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.reject(err);
          });
      }
    },
    getFundSources(fundingEntityID) {
      if (fundingEntityID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("fetchFundingEntitiy", fundingEntityID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.reject(err);
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.fund-allocation-summary {
  // height: 30vh;
  // overflow-y: auto;
  .discription {
    cursor: pointer;
    position: absolute;
    top: 5%;
    color: $primary-color-black;
    text-transform: capitalize;
    &--data {
      font-size: 1.8rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.6rem;
      }
    }
  }
  .info-button {
    margin: 1rem 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .info-section {
      &__amount {
        &--data {
          font-size: 2.6rem;
          font-weight: bold;
          text-transform: capitalize;
          color: $primary-color-red;
        }
        &--label {
          font-size: 2.2rem;
          color: $primary-color-black;
          text-transform: capitalize;
        }
        @media screen and (max-width: 1600px) {
          &--data {
            font-size: 2.4rem;
          }
          &--label {
            font-size: 2rem;
          }
        }
      }
    }
    .button-section {
      display: flex;

      &--btn {
        margin: 0rem 1rem;
        button {
          width: auto;
        }
      }
    }
  }
}
</style>
