<template>
  <div class="fundingEntity">
    <div class="fundingEntity__body">
      <div class="row m-0">
        <div class="col-sm-8 col-12 form-group order-sm-0 order-2 ">
          <input
            required
            type="text"
            placeholder=" "
            id="fundingEntity-entityName"
            v-model="inputs.fundingEntityName"
            :disabled=isDisable
            :readonly=isDisable
          />
           <label for="fundingEntity-entityName" class="form__label select-label">
            {{ labels.entityName }}
          </label>
          <Error v-if="v$.inputs.fundingEntityName.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.fundingEntityName'">
                {{ error.$message }}
              </small>
            </p>
          </Error> 
        </div>
        <div class="col-sm-4  col-12 order-sm-1 order-0">
          <div class="funders-logo">
            <div class="funders-logo__image p-0" v-if="((fundingEntityImg)&&(logoImg === null || logoImg ===undefined || logoImg === '')) ">
              <img :src=fundingEntityImg alt=" funding Entity Logo " />
            </div>
            <div class="funders-logo__image p-0" v-if="logoImg" >
              <img :src=logoImg alt=" funding Entity Logo" />
            </div>
          </div>  
        </div>
      </div>
      <div class="row m-0">
        <div class="col-sm-8 col-12 form-group">
          <input
            required
            type="text"
            placeholder=" "
            id="fundingEntity-entityAddress"
            v-model="inputs.address"
            :disabled=isDisable
            :readonly=isDisable
          />
          <label for="fundingEntity-entityAddress" class="form__label select-label">
            {{ labels.entityAddress }}
          </label>
          <Error v-if="v$.inputs.address.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.address'">
                {{ error.$message }}
              </small>
            </p>
          </Error>  
        </div>
      </div>
      <div class="row m-0 justify-content-between">
        <div class="col-sm-4 col-12 form-group">
          <input
            required
            type="text"
            placeholder=" "
            max="5"
            oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
            id="fundingEntity-zipCode"
            v-model="inputs.zipCode"
             @keyup="formateZip($event,5)"
             @blur="formateZip($event,5)"
            :disabled=isDisable
            :readonly=isDisable
          />
          <label for="fundingEntity-zipCode" class="form__label required">
            {{ labels.zipCode }}
          </label>
          <Error v-if="v$.inputs.zipCode.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.zipCode'">
                Enter valid Zip Code
              </small>
            </p>
          </Error>  
        </div>
        <div class="col-sm-4 col-12 form-group">
          <input
            required
            type="text"
            placeholder=" "
            id="fundingEntity-City"
            v-model="inputs.city"
            :disabled=isDisable
            :readonly=isDisable
          />
          <label for="fundingEntity-City" class="form__label required">
            {{ labels.city }}
          </label>
          <Error v-if="v$.inputs.city.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.city'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>
        <div class="col-sm-4 col-12 form-group">
          <select
            class="select-group"
            placeholder=" "
            required
            id="fundingEntity-State"
            name="fundingEntity-State"
            v-model="inputs.stateID"
            :disabled=isDisable
            :readonly=isDisable
          >
            <option v-for="state in states" :key="state.stateID" :value="state.stateID">
              {{ state.stateName }}
            </option>
          </select>
          <label for="fundingEntity-State" class="form__label required">
            {{ labels.state }}
          </label>
          <Error v-if="v$.inputs.stateID.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.stateID'">
                {{ error.$message }}
              </small>
            </p>
          </Error>      
        </div>
      </div>
      <div class="row m-0 justify-content-between">
        <div class="col-sm-4 col-12 form-group">
          <input
            required
            type="text"
          max="10"
          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
            placeholder="XX-XXXXXXX"
            id="fundingEntity-ein"
            v-model="inputs.ein"
            @keyup="formateEIN"
          @blur="formateEIN"
            :disabled=isDisable
            :readonly=isDisable
          />
          <label for="fundingEntity-ein" class="form__label required">
            {{ labels.ein }}
          </label>
          <Error v-if="v$.inputs.ein.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.ein'">
                Enter valid EIN
              </small>
            </p>
          </Error>
        </div>
        <div class="col-sm-4 col-12 form-group">
          <input
            required
            type="text"
            placeholder="XXX-XX-XXXX"
            id="fundingEntity-tin"
            v-model="inputs.tin"
            @input="formateTin($event,9)"
            @keyup="formateTin($event,9)"
            @blur="formateTin($event,9)"
            :disabled=isDisable
            :readonly=isDisable
          />
          <label for="fundingEntity-tin" class="form__label required">
            {{ labels.tin }}
          </label>
          <Error v-if="v$.inputs.tin.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.tin'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>
        <div class="col-sm-4 col-12 form-group"></div>
      </div>
      <div class="row m-0">
        <div class="col-sm-8 col-12 p-0">
          <div class=" form-file row">
        <label
          for=inputs.uploadFundingEntityLogo
          class="form__label form-file__label required col-5 mr-4 p-0"
          >
          {{ labels.documentName }}
        </label>
        <input
        v-if="userRoleID !=8"
          required
          ref="fileInput"
          class="form__label form-file__label required col-3 px-0 "
          type="file"
          :name=inputs.uploadFundingEntityLogo
          :id=inputs.uploadFundingEntityLogo
          v-bind="inputs.uploadFundingEntityLogo.documentTypeID"
          @input="inputs.uploadFundingEntityLogo"
          @change="showPreview($event, inputs.uploadFundingEntityLogo)"
          accept=".jpg,.jpeg,.png,.gif,.svg"
        />
      </div>
        </div>
      </div>
    </div>
    <div class="forms-btns__right col d-flex justify-content-end">
      <span class="btn--primary ml-5" v-if="userRoleID !=8">
        <BtnPrimary :buttonName="`Save`" @click="saveEntity()" />
      </span>
    </div>
  </div>
</template>
<script>
import textStore from "@/textStore.js";
import Error from "@/components/Error.vue"
import { required, minLength } from "@vuelidate/validators";
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
export default {
  components: {
    BtnPrimary,
    Error
  },
  
  props: {
    stateList: [],
    entity_ID: {
      type: Number,
      default: 0,
    }
  },
  validations() {
    return {
      inputs: {
        fundingEntityName: { required },
        address: { required },
        zipCode: { required , minLength: minLength(5)},
        city: { required },
        stateID: { required },
        ein: { required ,
        minLength: minLength(10)},
        tin: { required },
      },
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
      textStore,
    };
  },
  data() {
    return {
      downloadDataImg:'',
      logoImg:'',
      labels: {},
      einSetUp:{
        einValue: '',
      formattedeinValue: "",
      preventNextIteration: false,
      },
    };
  },
  computed: {
    fundingEntityImg:{
      get(){
      if(this.inputs.logSource ==null || this.inputs.logSource ==undefined || this.inputs.logSource == ''){
        return ''
        }
        else{
          return this.inputs.logSource
        }
    }
    },
    inputs: {
      get() {
        Object.assign(this.labels, textStore.fundSource.entity);
        
        return this.$store.state.fundSource.fundingEntity;
      },
    },
    states: {
      get() {
        return this.stateList;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
     isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    },

  },
  mounted() {
      if (this.entity_ID > 0) {
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store.dispatch("fetchFundingEntitiy", this.entity_ID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.resolve(res);
            
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.reject(err);
          });
      }
  },
  methods: {
    download(item){
        this.$store.dispatch("updateLoaderFlag", true);
      var data ={
        physicalFileStorageKey:item.physicalFileStorageKey,
        fileName:item.fileName
      }
      this.$store.dispatch("Download",data).then((res)=>{
        this.$store.dispatch("updateLoaderFlag", false);
        this.downloadDataImg=res.data
          Promise.resolve(res);
        }).catch(err =>{
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    showPreview(e, item) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.newFiles = e.target.files;
      var data = {
        file: this.newFiles[0],
        documentTypeID: item.documentTypeID,
      };
      if (this.newFiles.length) {

        var filename = data.file.name.toLowerCase();
        var fileExt = filename.split('.').pop();

        const fileExtensions = ["jpg", "png","jpeg","svg","gif"];
       
        //Commented this. using only supported logo
        //if(fileExt=="pl" || fileExt=="bat" || fileExt=="ps" || fileExt=="php" || fileExt=="aspx" || fileExt=="cshtml" || fileExt=="vbs" || fileExt=="js" || fileExt=="py" || fileExt=="java" || fileExt=="cs" || fileExt=="json" || fileExt=="pyd" || fileExt=="pyc" || fileExt=="pyo" || fileExt=="pyw" || fileExt=="pm" || fileExt=="t" || fileExt=="pod"  )
        if(fileExtensions.indexOf(fileExt) == -1)
        {
          this.toasterMessage('','Please upload image file','danger');
          return;
        }

        //upload files
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("UploadLogoFile", data)
          .then((res) => {
          if (res.status===200) {
            this.inputs.uploadFundingEntityLogo.fileName = data.file.name;
            this.inputs.uploadFundingEntityLogo.documentTypeID = 0;
            this.inputs.uploadFundingEntityLogo.documentGUID = res.data.documentID;
            this.inputs.uploadFundingEntityLogo.physicalFileStorageKey =
              res.data.storageKey;
            this.inputs.uploadFundingEntityLogo.fileSize = res.data.fileSize;
            this.inputs.uploadFundingEntityLogo.documentName =
              "Fund Transaction Document";
              this.inputs.uploadFundingEntityLogo.fileSource =res.data.fileSource
               this.logoImg=res.data.fileSource
            const fileSelected = this.$refs.fileInput;
            fileSelected.type = "text";
            fileSelected.type = "file";
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage('','File uploaded Successfully','success')
          }
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage('','Unsupported file','danger')
            Promise.reject(err);
          });
      }
    },
    formateTin(event,maxNumber){
      let tempFormattingNumber,value;
           tempFormattingNumber = this.inputs.tin.replace(/[^\d,]/g,'').slice(0,maxNumber)

           value = tempFormattingNumber.replace(/(\d{1,3})(\d{1,2})(\d{1,4})/g,
        "$1-$2-$3")     
           this.inputs.tin=value
           return this.inputs.tin
    },
    formateZip(event,maxNumber){
      let tempFormattingNumber,value;
           tempFormattingNumber = this.inputs.zipCode.replace(/[^\d,]/g,'')

           value = tempFormattingNumber.slice(0,maxNumber).replace(/[^\d]/g,'')
           this.inputs.zipCode=value
           return this.inputs.zipCode;
    },
    formateEIN(){
       if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.einSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.einSetUp.preventNextIteration = false;
        return;
      }
      let einNumber = this.inputs.ein
      .slice(0,10)
        .replace(/-/g, "")
        .replace(/[^\d,]/g,'')
      // Format display value based on calculated 
      this.inputs.ein = einNumber.replace(
        /(\d{1,2})(\d{1,7})/g,
        "$1-$2"
      );
     },
    async saveEntity() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("SaveEntity", this.inputs)
          .then((res) => {
            if (res.data.isSuccess === true) {
              this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage('',res.data.message,'success')
              this.$router.push('/funding');
              return Promise.resolve(true);
            }
            else {
              this.$store.dispatch("updateLoaderFlag",false)
              if((res.data.validationErrors!=null || res.data.validationErrors!=undefined) && res.data.validationErrors.length>0)
              {
                res.data.validationErrors.forEach(element => {
                  this.toasterMessage('',element,'danger')
                });
              }
              else{
                this.$store.dispatch("updateLoaderFlag",false)
                 this.toasterMessage('',res.data.message,'danger')
              }
              
            }
            
          })
          .catch((err) => {
            Promise.reject(err);
            this.$store.dispatch("updateLoaderFlag",false)
            this.toasterMessage('','Error while processing request','danger')
            return Promise.resolve(false);
          });
      } else {
        return Promise.resolve(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.fundingEntity {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  .row {
    padding-right: 2%;
    margin: 0;
  }
  .form-group {
    // padding: 0;
    // position: relative;
    @include input-style;
  }
  .forms-btns__right {
    padding-right: 8%;
  }
    input,.downloadLink,label{
      color: $primary-color-black;
      font-size: 1.6rem;
    }
  .funders-logo {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          &__image{
          height: 6rem;
          img {
            padding: 0 .2rem;
            height: 100%;
            object-fit:contain;
            object-position:50% 50%;
            width:100%;
          }
          }
          margin-bottom: 1rem;
          
  }
}
</style>
