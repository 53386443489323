<template>
    <span class="nav-btn">
        <a class="btn-step default" :class="style">
            <span class="btn-step__icons"> 
                <p>{{stepNumber}}</p>
            </span>
              <span class="btn-step__texts">
                <p>{{stepSubText}}</p>
                <h5>
                  {{stepName}}
                </h5>
              </span>
        </a>
    </span>
</template>
<script>
export default {
    props:{
        stepNumber:{
            type:Number
        },
        stepSubText:{
            type:String
        },
        stepName:{
            type:String
        },
        style:{
            type:String
        }
    }
}
</script>
<style lang="scss" scoped>
.nav-btn{
    display: inline-block;
    a{
        // border: 1px solid red;
        text-decoration: none;
        cursor: pointer;

        }
        ///////////default state of button ////////////
    .btn-step,.default{
        
          // max-width: 26rem;//218px;
          // min-width: 20rem;
          height: 5rem; //54px;
          margin-right: 1rem;
          background-color: transparent;
          outline: none;
          border: 1px solid transparent;
          border-radius: 3.6rem;
          display: flex;
          align-items: center;
          .btn-step__icons {
            margin: auto 1rem;
            border: 1px solid $primary-color-grey;
            border-radius: 50%;
            height: 3rem;
            width: 3rem;
            position: relative;
        background-color: transparent;

    font-size: 1.6rem;
    p{
        padding: 0;
        margin: 0;
        color: $primary-color-grey;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
          }

          .btn-step__texts {
            margin-right: 3.5rem;
            margin-left: 1rem;
            margin-top: 1rem;
            margin-bottom: 1rem;
            p {
              font-size: 1.2rem;
              margin-bottom: 0.3rem;
            }
            h5 {
                // word-wrap: break-word;
                overflow-wrap: normal;
                word-break: keep-all;
                display: inline;
                white-space: nowrap ;
                font-weight: thin;
              font-size: 1.6rem;
              margin-bottom: 0rem;
            }
            p,
            h5 {
              text-align: start;
              color: $primary-color-grey;
            }
          }
        }
        ///////////////active or current but state ////////////
        .active{
            border: 1px solid $primary-color-red;
            border-radius: 3.6rem;
            .btn-step__icons{
                background-color: $primary-color-red;
                border: 1px solid transparent;
                p{
        color: $primary-color-white;
                }
            }
            .btn-step__texts {
                p{
                    color: $primary-color-red;
                }
                h5{
                    width: 100%;
                    font-weight: bold;
                    color: $tertiary-color-black;
                }
            }
        }
        ////////////////////// when state is input values are filled ///////////////////
        .allValid{
            border: 1px solid transparent;
            .btn-step__icons{
                background-color: transparent;
                border: 1px solid $color-green;
                p{
                    color: transparent;
                    // display:none ;
                    &::after{
                        
                        position: absolute;
              left: 0%;
              top: 15%;
              content: "";
              display: block;
              width: 0.8rem;
              height: 1.6rem;
              border: solid $color-green;
              border-width: 0 0.25rem 0.25rem 0;
              transform: rotate(45deg);
                    }
        // color: $color-green;
                }
            }
            .btn-step__texts {
                p{
                    color: $color-green;
                }
                h5{
                    font-weight: lighter;
                    color: $tertiary-color-black;
                }
            }
        }
}
</style>