<template>
  <div class="TrackingList">
    <HeaderNav />
    <div class="d-flex justify-content-between header-section">
      <BreadCrumb
        class="breadcrumb align-self-start"
        :crumbs="crumbs"
        @selected="selected"
      />
      <span
        class="ml-3 btn--primary"
        v-if="userRoleID == 2 && hasOwnerPermission == 'true'"
      >
        <BtnPrimary
          :buttonName="`New Application`"
          @click="createApplication"
        />
      </span>
    </div>
    <div class="TrackingList__table-section">
      <Table
        class="listingTable"
        :userRoleID="userRoleID"
        :items="loanApplicationsList"
        :columns="columns"
        :TableHeight="TableHeight"
        :perPage="itemsPerTable"
        @getProgramId="getProgramId"
        @selectRow="fetchLoanApplicationDetails"
        @exportLoanApplications="exportLoanApplications"
      />
    </div>
    <LoadingMask v-if="isLoading === true" />
    <Footer />
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import Table from "@/components/Table";
import BtnPrimary from "@/components/BtnPrimary.vue";
import BreadCrumb from "@/components/BreadCrumb";
// import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  components: {
    HeaderNav,
    Footer,
    Table,
    BtnPrimary,
    BreadCrumb,
    // BtnSecondary,
  },

  /**
   * After instance created, fetch master data
   * Store in vuex store for convenient while getting master data in component
   */
  created() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.$store.state.borrower.ownersData = [{}];
    // this.fetchLoanApplications(0);
  },

  data() {
    return {
      welcomeName: "Welcome, ",
      LastModifiedDate: "Last Visited on" + " April 10, 2021",
      crumbs: ["Grant Application"],
      itemsPerTable: 10,
      TableHeight: 52,
      columns: [
        "Grant number",
        "Date applied",
        "Business name",
        "Affiliate Name",
        "Grant program",
        "Grant Amount",
        "Status",
      ],
      loanApplicationsList: [],
    };
  },
  computed: {
    programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    userName() {
      return this.$store.getters.getUser; //Cookies.get("firstname")
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID; //Cookies.get("firstname")
    },
    hasOwnerPermission() {
      return localStorage.getItem("hasOwnerPermission");
    },
  },
  methods: {
    fetchLoanApplications(programid) {
      this.$store.dispatch("updateLoaderFlag", true);
      let filterParameters=[];
      let filterParameterArray = [];
      if (programid.length > 0) {
        
        for (let index = 0; index < programid.length; index++) {
          let value = {
            key: "ProgramID",
            value: programid[index],
          }
          filterParameterArray.push(value);
        }
        filterParameters=filterParameterArray
      }
      else{
        filterParameters = [
        {
          key: "ProgramID",
          value: programid,
        },
      ];
      }
      
      this.$store
        .dispatch("fetchLoanApplications", filterParameters, { root: true })
        .then((loanApplicationList) => {
          if (localStorage.getItem("userRoleID") === 2) {
            if (
              loanApplicationList.data &&
              loanApplicationList.data.length > 0
            ) {
              this.loanApplicationsList = loanApplicationList.data.data;
            } else {
              this.loanApplicationsList = [];
              this.$router.push("/");
            }
          } else {
            if (loanApplicationList.data.isSuccess === true) {
              this.loanApplicationsList = loanApplicationList.data.data;
            } else {
              this.toasterMessage(
                "",
                loanApplicationList.data.message,
                "danger"
              );
              this.loanApplicationsList = [];
            }
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    getProgramId(programid) {
      this.fetchLoanApplications(programid);
      // return programid
    },
    createApplication() {
      this.$store.state.borrower.applicationID = 0;
      this.$store.dispatch("clearStore");
      this.$store.state.borrower.ownersData = [{}];
      this.$router.push("/");
    },
    exportLoanApplications() {
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("exportLoanApplications")
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
        });
      this.$store.state.borrower.ownersData = [{}];
      this.$router.push("/tracks");
    },
    fetchLoanApplicationDetails(loanApplicationID) {
      if (loanApplicationID > 0)
        this.$router.push("/form/" + 0 + "/" + loanApplicationID);
    },
  },
};
</script>
<style lang="scss" scoped>
.TrackingList {
  .header-section {
    padding: 0% 2%;
  }
  &__head {
    padding: 0 2%;
    &__name {
      padding: 1rem 0rem;
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
    }
  }

  &__table-section {
    padding: 0rem 2%;
  }
}
</style>
