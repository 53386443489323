import axios from 'axios'
import Cookies from 'js-cookie'
import { createStore } from 'vuex'
import borrower from './modules/borrower/borrower'
import business from './modules/BusinessEntity/businessEntity'
import fundSource from "./modules/FundSource/fundSource"
import report from "./modules/report/report"
import affiliate from './Affiliate/affiliate'
import document from './modules/Document/document'
import question from './modules/Question/question'
import civicrm from "./modules/CiviCRMDataExport/civiCRMDataEntity"
// import Cookie from 'js-cookie' 


////// main index.js of store is for authentication 
export default createStore({
  modules:{
    affiliate:affiliate,
    borrower:borrower,
    fundSource:fundSource,
    business:business,
    report:report,
    document:document,
    question:question,
    civicrm:civicrm,
  },
  state: {
    idToken:{
      type:String
    },
    user:'',
    userRoleID: {
      type: Number
    },
    userRoleName: {
      type: String
    },
    userType:{
      type:String
    },
    contactID:{
      type:String
    },
  showLoader:false,

  },
  mutations: {
    mutateLoaderFlag: (state, payload) => {
      state.showLoader = payload;
    },
    mutateLogin:(state,payload)=>{
      state.idToken = payload.token
      state.user = payload.firstname
      state.userRoleID = payload.userRoleID;
      state.userRoleName = payload.userRoleName;
      state.contactID= payload.contactID
    },
    mutateclearUser:(state)=>{
      state.idToken=null,
      state.user=null
    },
    mutateUserType:(state,payload)=>{
      state.userType=payload
    }
  },
  actions: {
    GetNotificationModeByUser:(vueContext, payload)=>{
      let token = localStorage.getItem("token");
      let config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          userId: parseInt(payload),
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/Contact/GetNotificationModeByUser", config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess==true) {
              resolve(res);
            }
            else{
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    SaveNotificationMode:(vueContext, payload)=>{
      let token = localStorage.getItem("token");
      let config = {
        headers: { Authorization: `Bearer ${token}` }
      };
      let param ={
        "notificationModeTypeID": payload.notificationModeTypeID,
        "notificationMode": payload.notificationMode,
        "userID": payload.userID
      }
      return new Promise((resolve, reject)=>{
        axios.post('/Contact/SaveNotificationMode', param, config)
        .then(res => {
          if((res.status === 200) && (res.data.isSuccess===true)){
            resolve(res);
          }
          else{
            reject(res);             
          }
        })
        .catch(err=>{
          console.log(err)
          reject(err)
        })
      })
    },
    updateLoaderFlag:(vueContext, payload)=>{
      vueContext.commit("mutateLoaderFlag", payload);
    },
    UpdateLogin:(vueContext, payload)=>{
      if(payload){
        return new Promise((resolve, reject)=>{
          axios.post('account/login',
          {username: Buffer.from(payload.email).toString('base64'),
            password:Buffer.from(payload.password).toString('base64')}
          )
          .then(res => {
            if((res.status === 200 )&& (res.data.unlockBusinessContactResponce===null)){
              let token = res.data.jwtToken
              let firstname = res.data.firstName
              let userEmail = res.data.userName
              let userRoleID = res.data.roleID[0];
              let userRoleName = res.data.roleName[0];
              let contactID = res.data.contactID;
              let hasOwnerPermission = res.data.hasOwnerPermission;
              Cookies.set('token',token)
              Cookies.set('firstname',firstname)
              Cookies.set('userEmail',userEmail)
              Cookies.set("userRoleID",userRoleID);
              localStorage.setItem('contactID',contactID)
              localStorage.setItem('token',token)
              localStorage.setItem('firstname',firstname)
              localStorage.setItem('userEmail',userEmail)
              localStorage.setItem('userRoleID',userRoleID)
              localStorage.setItem('hasOwnerPermission',hasOwnerPermission)
              

              vueContext.commit('mutateLogin',{token:token,firstname:firstname,userRoleID:userRoleID,userRoleName:userRoleName,contactID:contactID})
              
              resolve(res);
            }
            else{
              reject(res)
            }
          })
          .catch(err=>{
            reject(err)
          })
        })
      }
    },
    UpdateLogout(vueContext){
        Cookies.remove('token')
        Cookies.remove('firstname')
        Cookies.remove('userEmail')
  
        localStorage.removeItem('token')
        localStorage.removeItem('firstname')
        localStorage.removeItem('userEmail')
  
        vueContext.commit('mutateclearUser')
        
    },
    UpdateChangeDPassword:(vueContext, payload)=>{
      if(payload){
        let contactId = localStorage.getItem('contactID')
        return new Promise((resolve, reject)=>{
          axios.post('contact/ChangePassword',
          {
            ContactID:contactId,
            ExistingPassword: payload.ExistingPassword,
            NewPassword: payload.NewPassword
          }
          )
          .then(res => {
            if((res.status === 200) && (res.data.isSuccess===true)){
              resolve(res.data.message);
            }
            else{
              reject(res.data.message);             
            }
          })
          .catch(err=>{
            console.log(err)
            reject(err)
          })
        })
      }
    },
    UpdateForgotPassWord:(vueContext, payload)=>{
      if(payload){
        
        return new Promise((resolve, reject)=>{
          axios.post('contact/ForgotPassword',
          {
            EmailId:payload
          }
          )
          .then(res => {
            if((res.status === 200) && (res.data.isSuccess===true)){
              resolve(res.data.message);
            }
            else{
              reject(res.data.message);             
            }
          })
          .catch(err=>{
            console.log(err)
            reject(err)
          })
        })
      }
    },
    UpdateResetForgotPassWord:(vueContext, payload)=>{
      if(payload){
        return new Promise((resolve, reject)=>{
          axios.post('/contact/UpdatePasswordFromForgotPassword',
          {
            NewPassword: payload.NewPassword,
          TokenID:payload.TokenID
          }
          )
          .then(res => {
            if((res.status === 200) && (res.data.isSuccess===true)){
              resolve(res.data.message);
            }
            else{
              reject(res.data.message);             
            }
          })
          .catch(err=>{
            console.log(err)
            reject(err)
          })
        })
      }
    },
    UpdatePasswordResetContact:(vueContext, payload)=>{
      if(payload){
        return new Promise((resolve, reject)=>{
          axios.post('/contact/UpdatePasswordFromForgotPassword',
          {
            newPassword: payload.NewPassword,
            tokenID:payload.TokenID
          }
          )
          .then(res => {
            if((res.status === 200)){
              resolve(res);
            }
            else{
              reject(res);             
            }
          })
          .catch(err=>{
            console.log(err)
            reject(err)
          })
        })
      }
    },
  },
  getters:{
    isAuthenticated: state =>{
      return state.idToken != null
    },
    getUser: () =>{
      let name = Cookies.get("firstname")
      return name
    },
    idToken :() =>{
      let idToken=Cookies.get('token')
      return idToken
    },
    userType :state =>{
      return state.userType
    },
    getUserRoleID :() => {
      return Cookies.get("userRoleID");
    }
  },
})
