<template>
  <div class="form-page">
    <HeaderNav />
    <div class="form-section">
      <BreadCrumb class="breadcrumb" :crumbs="crumbs" @selected="selected" />
      <div class="form-section__header">
        <div
          v-if="loanApplicationID != undefined && loanApplicationID > 0"
          class="d-flex"
        >
          <div class="d-flex form-section__header--main-data">
          <h3>{{loanAppliactionDetials.programName}}</h3>
           <!-- <h3 class=" p-0 ">{{ loanAppliactionDetials.loanNumber }}</h3>-->
            <h4 class="align-self-center mb-4  ml-sm-4">
              <strong> Grant Number : </strong
              >{{ loanAppliactionDetials.loanNumber }}
            </h4>
            <h4 class="align-self-center mb-4  ml-sm-4">
              <strong> Status : </strong
              >{{ loanAppliactionDetials.applicationStatus }}
            </h4>
            
          </div>
          
          <img v-show="logoInfo.programLogoSource != null" style="max-width:10% ; align:right;" class=" align-self-center ml-auto" :src="logoInfo.programLogoSource" alt=" program Logo" height="50"/>
      
          <button
            class="col-4 helpful-guide-Faqs--small_screen helpful-guide-Faqs p-0"
            @click="showHelpFaqs = true"
          >
            &lt; &nbsp; Help&nbsp; &#38;&nbsp; FAQs
          </button>
        </div>

        <div v-else class="d-flex justify-content-between">
          <h3>
            {{ texts.formSectionHeader.h3 }}
          </h3>
          <button
            class="col-4 helpful-guide-Faqs--small_screen helpful-guide-Faqs p-0"
            @click="showHelpFaqs = true"
          >
            &lt; &nbsp; Help&nbsp; &#38;&nbsp; FAQs
          </button>
        </div>
        <p class="form-section__header--para">
          {{ texts.formSectionHeader.p }}
        </p>
      </div>
      <div class="form-section__step-tabs row p-0 m-0">
        <ul class="nav-btn-steps nav nav-tabs col-sm ">
          <div class="form-section__step-tabs__arrow-buttons">
            <span
              class="arrow-button"
              @click="showOnlyActiveTab()"
              v-if="isShowOnlyActiveTab == false"
            >
              <button class="arrow-button__btn arrow--button__up">
                <img src="@/assets/imgs/Arrow.svg" alt="up arrow" />
              </button>
            </span>
            <span
              class="arrow-button"
              @click="showAllTab()"
              v-if="isShowOnlyActiveTab == true"
            >
              <button class="arrow-button__btn arrow--button__down">
                <img src="@/assets/imgs/Arrow.svg" alt="down arrow" />
              </button>
            </span>
          </div>
          <li class="nav-item" v-if="showActiveTab == true">
            <NavBtn
              v-if="activeItem == 'businessprofile'"
              href="#businessprofile"
              :stepNumber="nav.stepNumber1"
              :stepSubText="nav.stepSubtext1"
              :stepName="nav.stepName1"
              @click.prevent="setActive('businessprofile')"
              :style="{ active: isActive('businessprofile') }"
            />
            <NavBtn
              v-if="activeItem == 'fundingApplication'"
              href="#fundingApplication"
              :stepNumber="nav.stepNumber2"
              :stepSubText="nav.stepSubtext2"
              :stepName="nav.stepName2"
              @click.prevent="
                getUserRoleID == 2 &&
                (loanAppliactionDetials.applicationStatus == 'Drafted' ||
                  createdApplicationID == 0)
                  ? ''
                  : setActive('fundingApplication')
              "
              :style="{ active: isActive('fundingApplication') }"
            />
            <NavBtn
              v-if="activeItem == 'documents'"
              href="#documents"
              :stepNumber="nav.stepNumber3"
              :stepSubText="nav.stepSubtext3"
              :stepName="nav.stepName3"
              @click.prevent="
                getUserRoleID == 2 &&
                (loanAppliactionDetials.applicationStatus == 'Drafted' ||
                  createdApplicationID == 0)
                  ? ''
                  : setActive('documents')
              "
              :style="{ active: isActive('documents') }"
            />
            <NavBtn
              v-if="activeItem == 'review'"
              href="#review"
              :stepNumber="nav.stepNumber4"
              :stepSubText="nav.stepSubtext4"
              :stepName="nav.stepName4"
              @click.prevent="
                getUserRoleID == 2 &&
                (loanAppliactionDetials.applicationStatus == 'Drafted' ||
                  createdApplicationID == 0)
                  ? ''
                  : setActive('review')
              "
              :style="{ active: isActive('review') }"
            />
          </li>
          <li class="nav-item" v-if="showActiveTab == false">
            <NavBtn
              href="#businessprofile"
              :stepNumber="nav.stepNumber1"
              :stepSubText="nav.stepSubtext1"
              :stepName="nav.stepName1"
              @click.prevent="setActive('businessprofile')"
              :style="{ active: isActive('businessprofile') }"
            />
          </li>
          <li class="nav-item" v-if="showActiveTab == false">
            <NavBtn
              href="#fundingApplication"
              :stepNumber="nav.stepNumber2"
              :stepSubText="nav.stepSubtext2"
              :stepName="nav.stepName2"
              @click.prevent="
                getUserRoleID == 2 &&
                (loanAppliactionDetials.applicationStatus == 'Drafted' ||
                  createdApplicationID == 0)
                  ? ''
                  : setActive('fundingApplication')
              "
              :style="{ active: isActive('fundingApplication') }"
            />
          </li>
          <li class="nav-item" v-if="showActiveTab == false">
            <NavBtn
              href="#documents"
              :stepNumber="nav.stepNumber3"
              :stepSubText="nav.stepSubtext3"
              :stepName="nav.stepName3"
              @click.prevent="
                getUserRoleID == 2 &&
                (loanAppliactionDetials.applicationStatus == 'Drafted' ||
                  createdApplicationID == 0)
                  ? ''
                  : setActive('documents')
              "
              :style="{ active: isActive('documents') }"
            />
          </li>
          <li class="nav-item" v-if="showActiveTab == false">
            <NavBtn
              href="#review"
              :stepNumber="nav.stepNumber4"
              :stepSubText="nav.stepSubtext4"
              :stepName="nav.stepName4"
              @click.prevent="
                getUserRoleID == 2 &&
                (loanAppliactionDetials.applicationStatus == 'Drafted' ||
                  createdApplicationID == 0)
                  ? ''
                  : setActive('review')
              "
              :style="{ active: isActive('review') }"
            />
          </li>
        </ul>
        <button
          class="col-sm-2 helpful-guide-Faqs"
          @click="showHelpFaqs = true"
        >
          &#60; Helpful Guide and FAQs
        </button>
      </div>

      <div class="row form-section__multi-form">
        <div class="col-12 form-section__multi-form__forms">
          <div class="forms-container tab-content" id="myTabContent">
            <BusinessProfile
              id="businessprofile"
              :prePopulatedData="prePopulatedData"
              :shouldFieldReadOnly="shouldFieldReadOnly"
              class="tab-pane fade"
              :class="{ 'active show': isActive('businessprofile') }"
              ref="BusinessProfile"
            />

            <FundingApplication
              id="fundingApplication"
              :prePopulatedData="prePopulatedData"
              :shouldFieldReadOnly="shouldFieldReadOnly"
              class="tab-pane fade"
              :class="{ 'active show': isActive('fundingApplication') }"
              ref="fundingApplication"
            />
            <Documents
              id="documents"
              :shouldFieldReadOnly="shouldFieldReadOnly"
              class="tab-pane fade"
              :class="{ 'active show': isActive('documents') }"
              ref="documents"
            />

            <Review
              id="review"
              class="tab-pane fade"
              :class="{ 'active show': isActive('review') }"
            />
          </div>
          <!-- <div class="horizontal">&nbsp;</div> -->
          <div class="forms-btns row m-0 p-0 py-2 d-flex ">
            <div class="forms-btns__left col-sm-2 col-6 order-2 order-sm-1">
              <!-- <span class="btn--tertiary" v-show="!isActive('consent')"> -->
              <span class="btn--tertiary" v-show="!isActive('businessprofile')">
                <BtnTertiary buttonName="Previous" @click="previous" />
              </span>
            </div>

            <div
              class="forms-btns__right col-sm-8 col-12 d-flex justify-content-end order-1 order-sm-2"
            >
              <!-- <span class="btn--secondary mx-1" @click="nextTab()">
                <BtnSecondary buttonName="Save as Draft" v-if="isActive('documents')" />
              </span> -->

              <!--***** If tab is other than review then show next button ******-->
              <!-- Else show button from commandflow and call the api based on that -->
              
             
              <span
             
                class="btn--primary--body flex-wrap d-flex justify-content-center"
              >
             
                  <BtnPrimary
                  v-if="getUserRoleID == 2 && IsPaymentRequested == true"
                  class=" btn--primary ml-3 mx-5"
                  buttonName="Request Payment"
                  @click="RequestedAddFundAllocation()">
                </BtnPrimary>
                
              <BtnPrimary
                  class="btn--primary ml-3 mx-5"
                  buttonName="Payment Schedule"
                  :className="ml - 3"
                  @click="ShowPaymentSchedule()"
                  v-show="loanAppliactionDetials.applicationStatusID == 7 && getUserRoleID == 3 ">
                </BtnPrimary>
                <BtnPrimary
                  class="btn--primary ml-3 mx-5"
                  v-for="(workFlowCommand,
                  index) in workFlowCommandList.workFlowCommands"
                  :key="index"
                  :buttonName="workFlowCommand.name"
                  :className="ml - 3"                  
                  @click="submit(workFlowCommand)"
                  v-show="
                    (workFlowCommand.name != 'Submit' &&
                      workFlowCommand.name != 'Disagree')
                  "
                >
                </BtnPrimary>
              </span>

              <span
                v-show="!shouldFieldReadOnly && isActive('review')"
                class="btn--primary  justify-content-end "
              >
                <BtnPrimary
                  class="btn--primary ml-3 "
                  v-for="(workFlowCommand,
                  index) in workFlowCommandList.workFlowCommands"
                  :key="index"
                  :buttonName="workFlowCommand.name"
                  :className="ml - 3"
                  @click="submit(workFlowCommand)"
                  v-show="workFlowCommand.name == 'Submit'"
                >
                </BtnPrimary>
              </span>
            </div>

            <div class="forms-btns__right col-sm-2 col-6 order-3 order-sm-3">
              <span class="btn--tertiary" v-show="!isActive('review')">
                <BtnTertiary buttonName="Next" @click="next()" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="help-faqs--main" v-if="showHelpFaqs == true">
        <div class="help-faqs">
          <span class="close-btn" @click="showHelpFaqs = false">
            <button>
              <img src="@/assets/imgs/cancel.svg" alt="close img" />
            </button>
          </span>
          <div
            class="funders-logo row m-0 mt-5"
            v-show="isActive('fundingApplication') || isActive('documents')"
          >
            <div
              class="funders-logo__image col-6 p-0"
              v-if="logoInfo.showFundingEntityLogo"
            >
              <img
                :src="logoInfo.fundingEntityLogoSource"
                alt=" funding Entity Logo"
              />
            </div>
            <div
              class="funders-logo__image col-6 p-0"
              v-if="logoInfo.showProgramLogo"
            >
              <img :src="logoInfo.programLogoSource" alt=" program Logo" />
            </div>
          </div>
          <h4
            v-show="
              isActive('fundingApplication') ||
                isActive('documents') ||
                isActive('businessprofile') ||
                isActive('review')
            "
          >
            {{ texts.formSectionFaqs.h4 }}
          </h4>
          <div class="description" v-show="isActive('businessprofile')">
            <div v-html="bussinessProfileHelpfulGuideTemplate"></div>
          </div>
          <div class="description" v-show="isActive('fundingApplication')">
            <div v-html="fundingApplicationHelpfulGuideTemplate"></div>
          </div>
          <div class="description" v-show="isActive('documents')">
            <div v-html="documentTabHelpfulGuideTemplate"></div>
          </div>
          <div class="description" v-show="isActive('review')">
            <div v-html="reviewTabHelpfulGuideTemplate"></div>
          </div>
        </div>
      </div>

      <div class="central-popup" v-if="showCentralPopUp == true">
        <div class="central-popup__main  w-50" v-if="PaymentScheduleApproveReject.ApproveReject == 'Approve'">
          <span class="close-btn">
            <button @click="showCentralPopUp = false">
              <img src="@/assets/imgs/cancel.svg" alt="close button" />
            </button>
          </span>
          <div>
            <div class="central-popup__main__heading py-0 px-2 m-0">
              <h3>Notification</h3>
            </div>

            <div class="MainDiv row m-0 p-0">
              <div class="col-10 m-0 p-2 mx-auto">
              <p class="large-fontsize"> 
                {{ CentralPopUpMessage }}  
                  
                </p>          
              </div>
            </div>
          </div>
          <div>
            <div class=" d-flex justify-content-center pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Ok`"
                    @click="CentralPopUpFunction(true,PaymentScheduleApproveReject.ApproveReject)"
                  />
                </span>
              </div>
              <!--<div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`No`"
                    @click="CentralPopUpFunction(false,'No')"
                  />
                </span>
              </div>-->
              <!-- <div class="p-0">
                <span>
                  <BtnSecondary
                    class="btn-secondary"
                    :buttonName="`Cancel`"
                    @click="HideNotificationOriginal()"
                  />
                </span>
              </div> -->
            </div>
          </div>
        </div>
         <div class="central-popup__main  w-50" v-if="PaymentScheduleApproveReject.ApproveReject == 'FinalApprove'">
          <span class="close-btn">
            <button @click="showCentralPopUp = false">
              <img src="@/assets/imgs/cancel.svg" alt="close button" />
            </button>
          </span>
          <div>
            <div class="central-popup__main__heading py-0 px-2 m-0">
              <h3>Notification</h3>
            </div>

            <div class="MainDiv row m-0 p-0">
              <div class="col-10 m-0 p-2 mx-auto">
              <p class="large-fontsize"> 
                {{ CentralPopUpMessage }}   
                  
                </p>          
              </div>
            </div>
          </div>
          <div>
            <div class=" d-flex justify-content-center pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Yes`"
                    @click="CentralPopUpFunction(true,PaymentScheduleApproveReject.ApproveReject)"
                  />
                </span>
              </div>
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`No`"
                    @click="CentralPopUpFunction(false,'NotApprove')"
                  />
                </span>
              </div>
              <!-- <div class="p-0">
                <span>
                  <BtnSecondary
                    class="btn-secondary"
                    :buttonName="`Cancel`"
                    @click="HideNotificationOriginal()"
                  />
                </span>
              </div> -->
            </div>
          </div>
        </div>
         <div class="central-popup__main w-50" v-if="PaymentScheduleApproveReject.ApproveReject == 'Reject'">
          <span class="close-btn">
            <button @click="showCentralPopUp = false">
              <img src="@/assets/imgs/cancel.svg" alt="close button" />
            </button>
          </span>
          <div>
            <div class="central-popup__main__heading py-0 px-2 m-0">
              <h3>Notification</h3>
            </div>

            <div class="MainDiv row m-0 p-0">
              <div class="col-10 m-0 p-0">
              <p class="large-fontsize">               
                  {{ CentralPopUpMessage }}
                 
                </p>            
              </div>
            </div>
          </div>
          <div>
            <div class=" d-flex justify-content-center pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Yes`"
                    @click="CentralPopUpFunction(true,PaymentScheduleApproveReject.ApproveReject)"
                  />
                </span>
              </div>
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`No`"
                    @click="CentralPopUpFunction(false,PaymentScheduleApproveReject.ApproveReject)"
                  />
                </span>
              </div>
              <!-- <div class="p-0">
                <span>
                  <BtnSecondary
                    class="btn-secondary"
                    :buttonName="`Cancel`"
                    @click="HideNotificationOriginal()"
                  />
                </span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <LoadingMask v-if="isLoading === true" />
    </div>
    <PopUp v-if="popup == true" @closePopUp="popup = false" class="popup">
      <FundDetail v-if="showFundUtilizedPopUp == true" />
      <TextArea v-if="showCommentPopUp == true" />
      <Agreement v-if="showAgreementPopUp == true" />
      <LoanPaymentSchedule
        :entity_ID="businessEntityId"
        :applicationId="loanApplicationID"
        :ProgramID="programId"
        :ProgramName='loanAppliactionDetials.programName'
        :LoanNumber='loanAppliactionDetials.loanNumber'
        :from=1
        v-if="showPaymentSchedule == true"
      />
    </PopUp>
    <LoadingMask v-if="isLoading === true" />
    <Footer />
  </div>
</template>
<script>
import FundDetail from "@/components/BorrowerForms/FundDetail";
import TextArea from "@/components/TextArea";
import Agreement from "@/components/Agreement";
import PopUp from "@/components/PopUp";
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import BreadCrumb from "@/components/BreadCrumb";
import BusinessProfile from "@/components/BorrowerForms/BusinessProfile";
import Review from "@/components/BorrowerForms/Review";
import FundingApplication from "@/components/BorrowerForms/FundingApplication";
import Documents from "@/components/BorrowerForms/Documents";
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnTertiary from "@/components/BtnTertiary.vue";
import NavBtn from "@/components/NavBtn.vue";
import LoanPaymentSchedule from "@/components/BorrowerForms/LoanPaymentSchedule.vue";

const state = {
  commandName: "",
  applicationID: 0,
  affiliateID: 0,
  loadAppliacationNumber: "",
  ein: "",
  businessName: "",
};
const getters = {
  getapplicationComments: (state) => {
    return state.commandName;
  },
  getapplicationID: (state) => {
    return state.applicationID;
  },
  getprogramInvitationID: (state) => {
    return state.programInvitationID;
  },
  getaffiliateID: (state) => {
    return state.affiliateID;
  },
  getein: (state) => {
    return state.ein;
  },
  getbusinessName: (state) => {
    return state.businessName;
  },
};

export default {
  state,
  getters,
  components: {
    HeaderNav,
    Footer,
    BreadCrumb,
    PopUp,
    TextArea,
    Agreement,
    FundDetail,
    BusinessProfile,
    FundingApplication,
    Documents,
    BtnPrimary,
    BtnTertiary,
    Review,
    NavBtn,
    LoanPaymentSchedule,
  },

  /**
   * After instance created, fetch master data
   * Store in vuex store for convenient while getting master data in component
   */
  created() {
    /** Get workflow command and button for submit loan application */
    this.$store.state.borrower.documentData.inputs.FilesOthers = [];
    this.$store.state.borrower.documentData.ProgressReportDocument = [];
    this.$store
      .dispatch("getWorkFlowCommand", this.loanApplicationID, {
        root: true,
      })
      .then((workflowCommand) => {
        this.workFlowCommandList = workflowCommand.data;
        // this.workflowCommandForReviewtab.buttonName = workflowCommand.data.workFlowCommands[0].name;
      })
      .catch((err) => {
        Promise.reject(err);
      });

    if (this.loanApplicationID > 0) {
      let param = {
        applicationID: this.loanApplicationID,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store.state.borrower.ownersData = [{}];
      this.$store.state.borrower.documentData.FilesOthers;
      this.$store.state.borrower.documentData.ProgressReportDocument;
      this.$store
        .dispatch("fetchLoanApplicationDetails", param, { root: true })
        .then((res) => {
          if (res.data.isSuccess == true) {
            const loanApplicationData = res.data.loanApplication;
            this.selected_ProgramStatus = loanApplicationData.applicationStatus;
            this.selected_ProgramInvitationID =
              loanApplicationData.programInvitationID;
            this.selected_LoanApplicationID = loanApplicationData.applicationID;
            this.businessEntityId = this.$store.state.borrower.businessEntityId;
            this.IsPaymentRequested=this.$store.state.borrower.IsPaymentRequested;
            this.programId= this.$store.state.borrower.programId;
            this.prePopulated(loanApplicationData.programInvitationID);
            this.shouldFieldReadOnly =
              this.selected_ProgramStatus == "Invited" ||
              (this.selected_ProgramStatus == "Requested More Information" &&
                (this.$store.getters.getUserRoleID == 2 ||
                  this.$store.getters.getUserRoleID == 7)) ||
              this.selected_ProgramStatus == 5 ||
              this.selected_ProgramStatus == "Drafted" ||
              this.selected_ProgramStatus == "Drafted" ||
              ((this.loanAppliactionDetials.applicationStatusID == 25 ||
                this.loanAppliactionDetials.applicationStatusID == 26 ||
                this.loanAppliactionDetials.applicationStatusID == 16 ||
                this.loanAppliactionDetials.applicationStatusID == 21) &&
                (this.$store.getters.getUserRoleID == 2 ||
                  this.$store.getters.getUserRoleID == 4 ||
                  this.$store.getters.getUserRoleID == 6))
                ? false
                : true;
            this.toasterMessage("", res.data.message, "success");
            this.$store.dispatch("updateLoaderFlag", false);
          } else {
            if (res.data.isSuccess == false) {
              this.toasterMessage("", res.data.message, "danger");
              this.$router.push("/tracks");
              this.$store.dispatch("updateLoaderFlag", false);
            }
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    } else if (this.programInvitationID > 0) {
      this.selected_ProgramInvitationID = this.programInvitationID;
      this.prePopulated(this.selected_ProgramInvitationID);
      this.shouldFieldReadOnly = false;
    }
  },

  props: {
    commands: {
      type: String,
      default: null,
    },
    selectedProgramInvitationID: {
      type: Number,
      default: null,
    },
    loanApplicationID: {
      type: Number,
      default: 0,
    },
    programInvitationID: {
      type: Number,
      default: 0,
    },
  },
  // props: ['selectedProgramInvitationID'],

  data() {
    return {
      IsPaymentRequested:false,
      PaymentScheduleQuestion: {
        Question: "Do you want to update Payment Schedule? ",
        isShow: true,
        value: null,
      },
      PaymentScheduleApproveReject:{
        ApproveReject:null,
        Reject:{
          Question: " Do you really want to reject this application?",
          isRejectPopUp:false,
          isReject:true,
        },
        Approve:{
          Question: "Please add Payment Schedule before approval?",
          isApprovePopUp:false,
          isApprove:true,
        }
      },
      businessEntityId: null,
      programId:null,
      isShowOnlyActiveTab: false,
      showActiveTab: false,
      showNavTab: true,
      showHelpFaqs: false,
      popup: false,
      showCentralPopUp: false,
      CentralPopUpMessage: "",
      showLoanStatus: false,
      showFundUtilizedPopUp: false,
      showCommentPopUp: false,
      showPaymentSchedule: false,
      showAgreementPopUp: false,
      commandname: "",
      isCfoORControllerAcceptByRMI: false,
      isBusinessProfile: false,
      isFundingApp: false,
      isApplicationDocuments: false,
      isBusinessOwnerName: true,
      bussinessProfileHelpfulGuideTemplate: "",
      fundingApplicationHelpfulGuideTemplate: "",
      documentTabHelpfulGuideTemplate: "",
      reviewTabHelpfulGuideTemplate: "",

      statusID: 0,
      statusName: "",
      crumbs: ["Grant Application", "Business Profile"],
      tabItems: [
        "businessprofile",
        "fundingApplication",
        "documents",
        "review",
      ],
      activeItem: "businessprofile",
      prePopulatedData: null,
      shouldFieldReadOnly: true,
      businessName: "",
      affiliateID: 0,
      createdApplicationID:
        this.loanApplicationID == null ? 0 : this.loanApplicationID,
      ein: "",
      workflowCommandForReviewtab: {
        buttonName: "",
        apiToBeCalled: "",
      },
      workFlowCommandList: {},
      inputValid: false,
      selected_ProgramInvitationID: 0,
      selected_ProgramStatus: "",
      selected_LoanApplicationID: 0,
      nav: {
        stepNumber1: "1",
        stepSubtext1: "Step One",
        stepName1: `Business Profile`,
        stepNumber2: "2",
        stepSubtext2: "Step Two",
        stepName2: "Funding Application",
        stepNumber3: "3",
        stepSubtext3: "Step Three",
        stepName3: "Documents",
        stepNumber4: "4",
        stepSubtext4: "Step Four",
        stepName4: "Review",
      },
      texts: {
        formSectionHeader: {
          h3: "Grant Application",
          p:
            "  Fast, Simple, and Convenient. Our funding options are determined by your business history, so please provide information accurately.",
        },
        HelpFAQ: "< Help & FAQs",
        formSectionStepBtn: {},
        formSectionFaqs: {
          fundingApplicationFaqs: {},
          documentsFaqs: {
            note4:
              "Use the Add button to upload any additional documents as requested.",
          },
          reviewFaqs: {},
        },
        formSectionFaqsFileUpload: {
          h6: "How to Upload Files",
          step1: {
            title: "Step 1:",
            detail: "Click on Choose file to upload file at a time",
          },
          step2: {
            title: "Step 2:",
            detail:
              "Select the respective document name from the list of documents",
          },
          step3: {
            title: "Step 3:",
            detail: " You can upload additional documents",
          },
        },
      },
      isDisabled()    {
        return this.isPaymentSchedule == false && this.loanAppliactionDetials.applicationStatusID == 7 && this.getUserRoleID == 3;
    }
    };
  },
  methods: {
    CentralPopUpFunction(res,ApproveReject){
      if(res==true)
      {
        this.showCentralPopUp = false;
        if(ApproveReject=='Approve')
        {
          
          this.PaymentScheduleApproveReject.Approve.isApprovePopUp=true
          this.PaymentScheduleSubmit();
          this.PaymentScheduleApproveReject.Approve.isApprove=true               
        }
        else if(ApproveReject=='FinalApprove')
        {
          
          this.PaymentScheduleApproveReject.Approve.isApprovePopUp=false
          //this.PaymentScheduleSubmit();
          this.PaymentScheduleApproveReject.Approve.isApprove=false 
          let workFlowCommand = this.workFlowCommandList.workFlowCommands.find(workFlowCommand => workFlowCommand.name=='Approve');
          this.submit(workFlowCommand);              
        }
        else if(ApproveReject == 'Reject')
        {
          this.PaymentScheduleApproveReject.Reject.isReject=false
          this.PaymentScheduleApproveReject.Reject.isRejectPopUp=false
          let workFlowCommand = this.workFlowCommandList.workFlowCommands.find(workFlowCommand => workFlowCommand.name==ApproveReject);
          this.submit(workFlowCommand);
        }
      }
      else if(res==false && ApproveReject=='No')
      {
       this.showCentralPopUp = false;
      }
      else if(res==false && ApproveReject=='NotApprove')
      {
        this.showCentralPopUp = false;
       // let workFlowCommand = this.workFlowCommandList.workFlowCommands.find(workFlowCommand => workFlowCommand.name=='Approve');
        //this.submit(workFlowCommand);
      }       
      else{
          this.showCentralPopUp = false;
          this.PaymentScheduleApproveReject.ApproveReject= null
          this.PaymentScheduleApproveReject.Approve.isApprovePopUp=false
      }
      
    },
     async RequestedAddFundAllocation() {
      var that = this;
      let param = {
        selectedProgramInvitationID: this.selected_ProgramInvitationID,
        businessName: this.businessName,
        affiliateID: this.affiliateID,
        ein: this.ein,
        storeData: this.$store.state.borrower,
        applicationID: this.createdApplicationID,
        triggerdCommandName: "",
        isBusinessProfile: that.isBusinessProfile,
        isApplicationDocuments: that.isApplicationDocuments,
        isFundingApp: that.isFundingApp,
        isCfoORControllerAcceptByRMI: this.isCfoORControllerAcceptByRMI,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      await this.$store.dispatch("RequestedAddFundAllocation", param, { root: true })
        .then((res)=>{
        if (res.status == 200) {
          this.toasterMessage("", res.data.statusMessage, "success");
        }
        else{
          this.toasterMessage("", res.data.statusMessage, "danger");
        }
          this.$router.push("/tracks")

        this.$store.dispatch("updateLoaderFlag", false);
      })
      .catch(()=>{
        this.$router.push("/tracks")
        this.$store.dispatch("updateLoaderFlag", false);
      })
    },
    ShowPaymentSchedule(){
 this.showFundUtilizedPopUp = false;
              this.showAgreementPopUp = false;
              this.showCommentPopUp = false;
              this.popup = true;
              this.showPaymentSchedule = true;
    },
    PaymentScheduleSubmit(){
      this.PaymentScheduleQuestion.isShow=false
      /*if(this.PaymentScheduleQuestion.value=='true'){
       this.ShowPaymentSchedule();
      }*/
      this.ShowPaymentSchedule();
    },
    showOnlyActiveTab() {
      this.showActiveTab = true;
      this.isShowOnlyActiveTab = true;
    },
    showAllTab() {
      this.showActiveTab = false;
      this.isShowOnlyActiveTab = false;
    },
    openLinkImAnotherTab: function() {
      window.open("https://www.naics.com/search/", "_blank");
    },
    prePopulated(pid) {
      /** Get Pre-populated fields data */
      this.$store
        .dispatch("getPrePopulatedApplicationData", pid, {
          root: true,
        })
        .then((masterData) => {
          if (masterData.data.isSuccess === true) {
            this.prePopulatedData = masterData.data;
            this.businessName = masterData.data.businessName;
            this.affiliateID = masterData.data.affiliateID;
            this.ein = masterData.data.ein;
            this.bussinessProfileHelpfulGuideTemplate =
              masterData.data.bussinessProfileHelpfulGuideTemplate;
            this.fundingApplicationHelpfulGuideTemplate =
              masterData.data.fundingApplicationHelpfulGuideTemplate;
            this.documentTabHelpfulGuideTemplate =
              masterData.data.documentTabHelpfulGuideTemplate;
            this.reviewTabHelpfulGuideTemplate =
              masterData.data.reviewTabHelpfulGuideTemplate;
          } else {
            this.toasterMessage("", masterData.data.message, "danger");
            this.$router.push("/tracks");
          }
        })
        .catch((err) => {
          Promise.reject(err);
          this.$router.push("/tracks");
        });
    },
    showLoader() {
      this.$store.dispatch("updateLoaderFlag", true);
    },
    hideLoader() {
      this.$store.dispatch("updateLoaderFlag", false);
    },
    selected(crumb) {
      if (crumb == "Grant Application") {
        this.$router.push("/tracks");
      }
    },
    isValidInputs(menuItem) {
      console.log("Testing menuItem", menuItem);
      return (menuItem = this.inputValid);
      // return true
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    getButtonName() {
      if (this.isActive("review")) {
        return this.workflowCommandForReviewtab.buttonName;
      } else return "Next";
    },

    async setActive(menuItem) {
      this.activeItem = menuItem;
      if (this.activeItem == "businessprofile") {
        this.crumbs[1] = "business profile";
      }
      if (this.activeItem == "fundingApplication") {
        this.crumbs[1] = "funding Application";
      }
      if (this.activeItem == "documents") {
        this.crumbs[1] = "documents";
      }
      if (this.activeItem == "review") {
        this.crumbs[1] = "review";
      }
    },
    async next() {
      let activeTab = this.activeItem;
      let tabItems = this.tabItems;
      if (activeTab === tabItems[0]) {
        await this.$refs.BusinessProfile.validationsStore()
          .then((res) => {
            //this.ownerValidation();
            if (res == true && this.isBusinessOwnerName) {
              this.setActive(tabItems[1]);

              this.inputValid = true;
              this.isBusinessProfile = true;
              // save the form if apllication id is zero
              // if(aplication>==0){
              //   submit('save')
              // }
            } else {
              this.setActive(tabItems[0]);
            }
          })
          .catch(() => {
            this.setActive(tabItems[0]);
          });
      }

      if (activeTab === tabItems[1]) {
        await this.$refs.fundingApplication
          .validationsStore()
          .then((res) => {
            if (res == true) {
              this.setActive(tabItems[2]);

              this.inputValid = true;
              this.isFundingApp = true;
            } else {
              this.setActive(tabItems[1]);
            }
          })
          .catch(() => {
            this.setActive(tabItems[1]);
          });
      }
      if (activeTab === tabItems[2]) {
        await this.$refs.documents
          .validationsStore()
          .then((res) => {
            if (res === true) {
              this.setActive(tabItems[3]);

              this.inputValid = true;
              this.isApplicationDocuments = true;
            } else {
              this.setActive(tabItems[2]);
            }
          })
          .catch(() => {
            this.setActive(tabItems[2]);
          });
      }
      if (activeTab === tabItems[3]) {
        this.setActive(tabItems[4]);
      }
    },
    async saveValidation() {
      var that = this;
      let activeTab = this.activeItem;
      let tabItems = this.tabItems;
      if (activeTab === tabItems[0]) {
        await this.$refs.BusinessProfile.validationsStore()
          .then((res) => {
            if (res == true) {
              this.inputValid = true;
              that.res = true;
              that.isBusinessProfile = true;
            } else {
              this.inputValid = false;
              that.res = false;
              this.setActive(tabItems[0]);
            }
          })
          .catch(() => {
            this.setActive(tabItems[0]);
          });
      }

      if (activeTab === tabItems[1]) {
        await this.$refs.fundingApplication
          .validationsStore()
          .then((res) => {
            if (res == true) {
              this.inputValid = true;
              that.res = true;
              that.isFundingApp = true;
            } else {
              this.inputValid = false;
              that.res = false;
              this.setActive(tabItems[1]);
            }
          })
          .catch(() => {
            this.setActive(tabItems[1]);
          });
      }
      if (activeTab === tabItems[2]) {
        await this.$refs.documents
          .validationsStore()
          .then((res) => {
            if (res === true) {
              this.inputValid = true;
              that.isApplicationDocuments = true;
              that.res = true;
            } else {
              this.inputValid = false;
              that.res = false;
              this.setActive(tabItems[2]);
            }
          })
          .catch(() => {
            this.setActive(tabItems[2]);
          });
      }
      if (activeTab === tabItems[3]) {
        let BusinessProfileValidataion = await this.$refs.BusinessProfile.validationsStore()
          .then((res) => {
            if (res == true) {
              this.inputValid = true;
              that.res = true;
              that.isBusinessProfile = true;
              return true;
            } else {
              this.inputValid = false;
              that.res = false;
              this.setActive(tabItems[0]);
              return false;
            }
          })
          .catch(() => {
            return false;
          });
        let fundingApplicationValidataion = await this.$refs.fundingApplication
          .validationsStore()
          .then((res) => {
            if (res == true) {
              this.inputValid = true;
              that.res = true;
              that.isFundingApp = true;
              return true;
            } else {
              this.inputValid = false;
              that.res = false;
              this.setActive(tabItems[1]);
              return false;
            }
          })
          .catch(() => {
            return false;
          });
        let documentsValidataion = await this.$refs.documents
          .validationsStore()
          .then((res) => {
            if (res === true) {
              this.inputValid = true;
              that.isApplicationDocuments = true;
              that.res = true;
              return true;
            } else {
              this.inputValid = false;
              that.res = false;
              this.setActive(tabItems[2]);
              return false;
            }
          })
          .catch(() => {
            return false;
          });

        if (BusinessProfileValidataion == false) {
          this.setActive(this.tabItems[0]);
          this.toasterMessage(
            "",
            "Please fill required fields in Business Profile",
            "danger"
          );
        } else if (fundingApplicationValidataion == false) {
          this.setActive(this.tabItems[1]);
          this.toasterMessage(
            "",
            "Please fill required fields in Funding Application.",
            "danger"
          );
        } else if (documentsValidataion == false) {
          this.setActive(this.tabItems[2]);
          this.toasterMessage(
            "",
            "Please fill required fields in documents",
            "danger"
          );
        } else {
          that.res = true;
          this.setActive(tabItems[3]);
        }
      }
    },
    async submit(workFlowCommand) {
      var that = this;
      let param = {
        selectedProgramInvitationID: this.selected_ProgramInvitationID,
        businessName: this.businessName,
        affiliateID: this.affiliateID,
        ein: this.ein,
        storeData: this.$store.state.borrower,
        applicationID: this.createdApplicationID,
        triggerdCommandName: workFlowCommand.name,
        isBusinessProfile: that.isBusinessProfile,
        isApplicationDocuments: that.isApplicationDocuments,
        isFundingApp: that.isFundingApp,
        isCfoORControllerAcceptByRMI: this.isCfoORControllerAcceptByRMI,
      };
      if (workFlowCommand.name == "Save") {
        await this.saveValidation()
          .then((res) => {
            if (this.res == true) {
              param.isBusinessProfile = that.isBusinessProfile;
              param.isFundingApp = that.isFundingApp;
              param.isApplicationDocuments = that.isApplicationDocuments;
              this.commandHandler(param);
              Promise.resolve(res);
            }
          })
          .catch((this.LoginError = true));
      } else {
        if (
          workFlowCommand.workFlowCommandValidationEntity
            .fundUtilizationValidation
        ) {
          this.$store.getters.commandname = workFlowCommand.name;
          this.$store.getters.applicationID = param.applicationID;
          this.$store.getters.programInvitationID =
            param.selectedProgramInvitationID;
          this.showCommentPopUp = false;
          this.showAgreementPopUp = false;
          this.popup = true;
          this.showFundUtilizedPopUp = true;
        } else if (
          workFlowCommand.workFlowCommandValidationEntity
            .commentRequiredValidation
        ) {
          if (this.getUserRoleID == 2 || this.getUserRoleID == 7) 
          {
            let BusinessProfileValidataion = await this.$refs.BusinessProfile.validationsStore()
              .then((res) => {
                if (res == true) {
                  return true;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return false;
              });
            let fundingApplicationValidataion = await this.$refs.fundingApplication
              .validationsStore()
              .then((res) => {
                if (res == true) {
                  return true;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return false;
              });
            let documentsValidataion = await this.$refs.documents
              .validationsStore()
              .then((res) => {
                if (res === true) {
                  return true;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return false;
              });

            if (BusinessProfileValidataion == false) {
              this.setActive(this.tabItems[0]);
              this.toasterMessage(
                "",
                "Please fill required fields in Business Profile",
                "danger"
              );
            } else if (fundingApplicationValidataion == false) {
              this.setActive(this.tabItems[1]);
              this.toasterMessage(
                "",
                "Please fill required fields in Funding Application.",
                "danger"
              );
            } else if (documentsValidataion == false) {
              this.setActive(this.tabItems[2]);
              this.toasterMessage(
                "",
                "Please fill required fields in documents",
                "danger"
              );
            } else {
              this.$store.getters.commandname = workFlowCommand.name;
              this.$store.getters.applicationID = param.applicationID;
              this.$store.getters.programInvitationID =
                param.selectedProgramInvitationID;
              this.$store.getters.ein = param.ein;
              this.$store.getters.affiliateID = param.affiliateID;
              this.$store.getters.businessName = param.businessName;
              this.showFundUtilizedPopUp = false;
              this.showAgreementPopUp = false;
              this.popup = true;
              this.showCommentPopUp = true;
            }
          } else {
            this.$store.getters.commandname = workFlowCommand.name;
            this.$store.getters.applicationID = param.applicationID;
            this.$store.getters.programInvitationID =
              param.selectedProgramInvitationID;
            this.$store.getters.ein = param.ein;
            this.$store.getters.affiliateID = param.affiliateID;
            this.$store.getters.businessName = param.businessName;
            this.showFundUtilizedPopUp = false;
            this.showAgreementPopUp = false;
            this.popup = true;
            this.showCommentPopUp = true;
          }
        } else if (
          workFlowCommand.workFlowCommandValidationEntity.agreementValidation
        ) {
          this.$store.getters.commandname = workFlowCommand.name;
          this.$store.getters.applicationID = param.applicationID;
          this.$store.getters.programInvitationID =
            param.selectedProgramInvitationID;
          this.showFundUtilizedPopUp = false;
          this.showCommentPopUp = false;
          this.popup = true;
          this.showAgreementPopUp = true;
        } else 
        {
          if ((workFlowCommand.name == "Approve" && this.loanAppliactionDetials.applicationStatusID == 7) || workFlowCommand.name == "Reject" && this.loanAppliactionDetials.applicationStatusID == 7) 
          { 
            if (workFlowCommand.name == "Reject" && this.PaymentScheduleApproveReject.Reject.isReject==true) {
                  this.CentralPopUpMessage=this.PaymentScheduleApproveReject.Reject.Question
                this.showCentralPopUp = true;
                this.PaymentScheduleApproveReject.ApproveReject= "Reject"
                this.PaymentScheduleApproveReject.Reject.isRejectPopUp=true
              return;
            }
            if (workFlowCommand.name == "Approve" && this.PaymentScheduleApproveReject.Approve.isApprove == true && (this.$store.state.borrower.fundingData.inputs.isPaymentSchedule == false)) {
                  this.CentralPopUpMessage=this.PaymentScheduleApproveReject.Approve.Question
                this.showCentralPopUp = true;
                this.PaymentScheduleApproveReject.ApproveReject= "Approve"
                this.PaymentScheduleApproveReject.Approve.isApprovePopUp=true
              return;
            }
            if (workFlowCommand.name == "Approve" && this.PaymentScheduleApproveReject.Approve.isApprove == true && (this.$store.state.borrower.fundingData.inputs.isPaymentSchedule == true)) {
                  this.CentralPopUpMessage="Are you sure you want to approve and send Payment Schedule & Agreement to applicant?";//this.PaymentScheduleApproveReject.Approve.Question
                this.showCentralPopUp = true;
                this.PaymentScheduleApproveReject.ApproveReject= "FinalApprove"
                this.PaymentScheduleApproveReject.Approve.isApprovePopUp=true
              return;
            }
          }

          if (
            workFlowCommand.name == "Submit" ||
            ((workFlowCommand.name === "Accept" ||
              workFlowCommand.name === "Approve") &&
              (this.loanAppliactionDetials.applicationStatusID == 25 ||
                this.loanAppliactionDetials.applicationStatusID == 26 ||
                this.loanAppliactionDetials.applicationStatusID == 16 ||
                this.loanAppliactionDetials.applicationStatusID == 21))
          ) {
            if (workFlowCommand.name === "Accept") {
              this.isCfoORControllerAcceptByRMI = true;
              param.isCfoORControllerAcceptByRMI = this.isCfoORControllerAcceptByRMI;
            }
            let BusinessProfileValidataion = await this.$refs.BusinessProfile.validationsStore()
              .then((res) => {
                if (res == true) {
                  return true;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return false;
              });
            let fundingApplicationValidataion = await this.$refs.fundingApplication
              .validationsStore()
              .then((res) => {
                if (res == true) {
                  return true;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return false;
              });
            let documentsValidataion = await this.$refs.documents
              .validationsStore()
              .then((res) => {
                if (res === true) {
                  return true;
                } else {
                  return false;
                }
              })
              .catch(() => {
                return false;
              });

            if (BusinessProfileValidataion == false) {
              this.setActive(this.tabItems[0]);
              this.toasterMessage(
                "",
                "Please fill required fields in Business Profile",
                "danger"
              );
            } else if (fundingApplicationValidataion == false) {
              this.setActive(this.tabItems[1]);
              this.toasterMessage(
                "",
                "Please fill required fields in Funding Application.",
                "danger"
              );
            } else if (documentsValidataion == false) {
              this.setActive(this.tabItems[2]);
              this.toasterMessage(
                "",
                "Please fill required fields in documents",
                "danger"
              );
            } else {
              this.commandHandler(param);
            }
          } else {
            this.commandHandler(param);
          }
        }
      }
    },
    // ownerValidation() {
    //   this.$store.getters.getownersData.forEach((element) => {
    //     if (
    //       element.businessOwnerName == null ||
    //       element.businessOwnerName == "" ||
    //       element.ownedPercentage == null ||
    //       element.ownedPercentage == "" ||
    //       element.genderID == 0 ||
    //       element.genderID == null ||
    //       element.raceID == 0 ||
    //       element.raceID == null ||
    //       element.veteranID == 0 ||
    //       element.veteranID == null ||
    //       element.ethnicityID == 0 ||
    //       element.ethnicityID == null ||
    //       element.emailAddress == null ||
    //       element.emailAddress == "" ||
    //       element.phoneNumber == null ||
    //       element.phoneNumber == ""
    //     ) {
    //       this.isBusinessOwnerName = false;
    //       this.toasterMessage("", "Please enter all the required fields", "danger");
    //     } else this.isBusinessOwnerName = true;
    //   });
    // },
    commandHandler(param) {
      //if (param.triggerdCommandName == "Save" || param.triggerdCommandName == "Submit")
      //this.ownerValidation();
      if (this.isBusinessOwnerName) {
        this.showLoader();
        this.$store
          .dispatch("SaveApplication", param, { root: true })
          .then((res) => {
            if (param.triggerdCommandName == "Save") {
              if (res.data.isSuccess == true) {
                if (
                  this.loanApplicationID == 0 &&
                  this.programInvitationID != 0
                )
                  this.$router.push(
                    "/form/" + this.programInvitationID + "/" + res.data.id
                  );
                this.toasterMessage(
                  "",
                  "Successfully saved Grant Application.",
                  "success"
                );
              } else {
                this.toasterMessage(
                  "",
                  "Failed to save Grant Application.",
                  "danger"
                );
              }
            } else if (param.triggerdCommandName == "Submit") {
              if (res.data.isSuccess === true) {
                this.toasterMessage("", res.data.message, "success");
                this.$router.push("/tracks");
              } else if (
                res.data.isSuccess === false &&
                res.data.invalidInput === true
              ) {
                this.toasterMessage("", res.data.message, "danger");
                if (res.data.validationErrors.length > 0) {
                  res.data.validationErrors.forEach((validationError) => {
                    this.toasterMessage("", validationError, "danger");
                  });
                  this.$refs.BusinessProfile.validationsStore();
                  this.$refs.fundingApplication.validationsStore();
                  this.$refs.documents.validationsStore();
                }
              } else {
                this.toasterMessage("", res.data.message, "danger");
              }
            } else {
              this.toasterMessage(
                "",
                res.data.message,
                "success"
              );

              if (param.triggerdCommandName != "Save")
                this.$router.push("/tracks");
            }
            /** Get workflow command and button for submit loan application */
            this.$store
              .dispatch("getWorkFlowCommand", this.selected_LoanApplicationID, {
                root: true,
              })
              .then((workflowCommand) => {
                this.hideLoader();
                this.workFlowCommandList = workflowCommand.data;
                // this.workflowCommandForReviewtab.buttonName = workflowCommand.data.workFlowCommands[0].name;
              })
              .catch((err) => {
                this.$store.dispatch("updateLoaderFlag", false);
                Promise.reject(err);
              });
          })
          .catch((err) => {
            Promise.reject(err);
            this.hideLoader();
            this.toasterMessage("", "Error while processing request", "danger");
            this.$store.dispatch("updateLoaderFlag", false);
          });
      }
    },
    previous() {
      let activeTab = this.activeItem;
      let tabItems = this.tabItems;
      if (activeTab === tabItems[1]) {
        this.setActive(tabItems[0]);
      }
      if (activeTab === tabItems[2]) {
        this.setActive(tabItems[1]);
      }
      if (activeTab === tabItems[3]) {
        this.setActive(tabItems[2]);
      }
      if (activeTab === tabItems[4]) {
        this.setActive(tabItems[3]);
      }
    },
    async SendUpdateFundDetailEmailNotifiaction() {
      this.$store.dispatch("updateLoaderFlag", true);
      await this.$store
        .dispatch(
          "SendUpdateFundDetailEmailNotifiaction",
          this.loanApplicationID
        )
        .then((res) => {
          if (res.data.isSuccess == true) {
            this.showCentralPopUp = false;
            this.toasterMessage("", res.data.message, "success");
            this.$store.dispatch("updateLoaderFlag", false);
            this.$router.push("/tracks");
          } else {
            this.showCentralPopUp = false;
            this.toasterMessage("", res.data.message, "danger");
            this.$store.dispatch("updateLoaderFlag", false);
            this.$router.push("/tracks");
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
        });
    },
  },
  computed: {
    
    isPaymentSchedule() {
      return this.$store.state.borrower.fundingData.inputs.isPaymentSchedule !== undefined ? this.$store.state.borrower.fundingData.inputs.isPaymentSchedule : false ;
    },
    getUserRoleID: {
      get() {
        return this.$store.getters.getUserRoleID;
      },
    },
    logoInfo: {
      get() {
        let logoInformation = {
          showFundingEntityLogo: this.$store.state.borrower
            .showFundingEntityLogo,
          showProgramLogo: this.$store.state.borrower.showProgramLogo,
          programLogoSource: this.$store.state.borrower.programLogoSource,
          fundingEntityLogoSource: this.$store.state.borrower
            .fundingEntityLogoSource,
        };
        return logoInformation;
      },
    },
    loanAppliactionDetials: {
      get() {
        return this.$store.state.borrower.applicantData;
      },
    },

    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    }
  },
};
</script>
<style lang="scss" scoped>
.form-page {
  .form-section {
    width: calc(100vw - 10rem);
    margin: 0 auto;
    // height: calc(100vh - 20rem);
    &__header {
      margin: 1rem 0;
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font-size: 1.2rem;
      }
      &--para {
        @media screen and(max-width:580px) {
          display: none;
        }
      }
      &--main-data {
        @media screen and (max-width: 576px) {
          h4 {
            margin: 0%;
            padding: 0%;
            font-size: 1.6rem;
          }
          display: flex;
          align-content: flex-start;
          flex-direction: column;
        }
      }
    }
    &__step-tabs {
      position: relative;
      .nav-tabs {
        padding: 0.5rem;
        // display: flex;
        // justify-content: flex-start;
        // flex-wrap: nowrap;
        // overflow-x: auto;
        //  overflow-y:scroll;

        // &::-webkit-scrollbar {
        //   height: 0.2rem;
        //   background: $tertiary-color-white;
        // }

        // &::-webkit-scrollbar-thumb {
        //   background: #bdbdbd;
        //   height: 0.1rem;
        //   -webkit-border-radius: 1rem;
        // }

        // &::-webkit-scrollbar-corner {
        //   background: #000;
        // }

        @media screen and(max-width:576px) {
          border: none;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 5px 6px #00000029;
          border-radius: 4px;
          opacity: 1;
        }
      }
      &__arrow-buttons {
        display: none;
        @media screen and(max-width:576px) {
          display: flex;
          position: absolute;
          right: 2.5%;
          .arrow-button {
            &__btn {
              height: 2.5rem;
              width: 2.5rem;
              border: none;
              outline: none;
              background: transparent;
              img {
                height: 100%;
              }
            }
            .arrow--button__down {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    &__multi-form {
      border: 0rem;
      border-top: 0.1px;
      border-top: 1px;
      border-color: $secondary-color-white;
      border-style: solid;
      margin: 0rem;
      padding: 0rem;
      &__forms {
        padding: 0rem;
        border: 0rem;
        .forms-container {
          margin: 0rem;
          padding: 0;
          padding-top: 0rem;
          height: 55vh;
          @media screen and(max-height:700px) {
            height: 45vh;
          }
          @media screen and(max-width:580px) {
            height: 60vh;
          }
        }
        .horizontal {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $secondary-color-white;
        }
        .row {
          margin: 0rem;
          padding: 0rem;
        }
        .forms-btns {
          padding-right: 5rem;
          margin: 2.5rem 0rem;
          button {
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0rem;
            margin-top: 1.5rem;
            height: 4rem;
          }
          .btn--secondary {
            width: 16.5rem;
          }
          .btn--primary--body {
            width: 100%;
            .btn--primary {
              width: auto;
            }
          }
          &__left {
            padding: 0rem;
            .btn--tertiary {
              button {
                position: relative;
                &:before {
                  position: absolute;
                  left: 0;
                  content: "<";
                }
              }
            }
          }
          &__right {
            padding: 0rem;
            .btn--tertiary {
              button {
                position: relative;
                &:before {
                  position: absolute;
                  right: 0;
                  content: ">";
                }
              }
            }
          }

          @media screen and (max-width: 1600px) {
            margin: 1rem 0rem;
          }
        }
      }
    }

    @media screen and (max-width: 576px) {
      width: calc(100vw - 3rem);

      &__header {
        h3 {
          font-size: 1.8rem;
        }
      }
    }
    .helpful-guide-Faqs {
      border: none;
      background: transparent;
      color: $primary-color-red;
      font-size: 1.6rem;
      font-weight: bold;
      @media screen and(max-width:576px) {
        display: none;
      }
    }
    .help-faqs--main {
      background-color: rgba(0, 0, 0, 0.3);
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .help-faqs {
        box-shadow: 0px 0.6rem 1.2rem #0000000f;
        border-radius: 3rem 3rem 0rem 0rem;
        width: 40rem;
        height: 70%;
        background: #fff;
        position: absolute;
        top: 30%;
        right: 0%;
        overflow-y: auto;
        padding: 2rem 4rem;
        color: $primary-color-black;

        .loan-detials {
          h5,
          p {
            font-size: 1.6rem;
            color: $tertiary-color-grey;
          }
        }
        .funders-logo {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          &__image {
            height: 10rem;
            img {
              padding: 0 0.2rem;
              height: 100%;
              object-fit: contain;
              object-position: 50% 50%;
              width: 100%;
            }
          }
          margin-bottom: 1rem;
        }

        h4 {
          font-size: 2.4rem;
          font-weight: normal;
          margin-bottom: 1.5rem;
        }
        h6 {
          margin: 2rem 0;
          font-size: 1.6rem;
          font-weight: bold;
          text-transform: capitalize;
        }
        p {
          font-size: 1.4rem;
        }
        .contactus,
        .naics-code {
          cursor: pointer;
          font-size: 1.6rem;
        }
        .description {
          height: 95%;
          overflow-y: auto;
          font-size: 1.6rem;
          p,
          h5,
          a {
            font-size: 1.6rem;
          }
          h1 {
            font-size: 2.4rem;
          }
          h2 {
            font-size: 2.2rem;
          }
          h3 {
            font-size: 2rem;
          }
          h4 {
            font-size: 1.8rem;
          }
          h6 {
            font-size: 1.4rem;
          }
          @media screen and(max-width:1600px) {
            p,
            h5,
            a {
              font-size: 1.4rem;
            }
            h1 {
              font-size: 2.2rem;
              // font-weight: normal;
              // margin-bottom: 1.5rem; //4rem;
            }
            h2 {
              font-size: 2rem;
            }
            h3 {
              font-size: 1.8rem;
            }
            h4 {
              font-size: 1.6rem;
            }
            h6 {
              font-size: 1.4rem;
            }
          }
          // h6 {
          //   margin: 2rem 0; //3rem 0;
          //   font-size: 1.6rem;
          //   font-weight: bold;
          //   text-transform: capitalize;
          // }
          a {
            cursor: pointer;
          }
        }
        .close-btn {
          position: fixed;
          top: 31%;
          right: 1.5%;
          button {
            background: #f7f7f7 0% 0% no-repeat padding-box;
            height: 3.5rem;
            width: 3.5rem;
            border: 1px solid transparent;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1rem;
            img {
              height: 100%;
            }
          }
        }
        @media screen and(max-width:1600px) {
          padding: 1rem;
        }
        @media screen and(max-width:580px) {
          top: 3%;
          left: 0;
          height: 100vh;
          width: 100%;

          .close-btn {
            top: 4%;
            right: 2.5%;
          }
        }
      }
    }
    .helpful-guide-Faqs--small_screen {
      display: none;
      font-size: 1.4rem;

      @media screen and(max-width:580px) {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
.central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 20vh;
    min-width: 40%;
    width: auto;
    padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px) {
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      p {
        font-size: 1.6rem;
        text-align: center;
        color: $primary-color-black;
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }

  .LabelNote,
  .input-values {
    padding: 1rem;
    // margin: 1rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $primary-color-black;
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
}
.PaymentScheduleQuestion{
  font-size: 1.6rem;
  width: 100%;
  font-weight: bold;
  // border: 2px solid red;
}
.confirms-btn {
  min-width: 75px;
}
.large-fontsize{
font-size: 25px !important;
font-weight: bold;
}  
</style>
