<template>
  <nav>
    <ol class="breadcrumb">
      <li
        v-for="(crumb, ci) in crumbs"
        :key="ci"
        class="breadcrumb-item align-items-center"
      >
        <button
          class="btn "
          :class="{ disabled: isLast(ci) }"
          @click="selected(crumb)"
        >
          {{ crumb }}
        </button>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit("selected", crumb);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  cursor:text;
   margin: .5rem 0 .5rem 0;
  // margin: 0;
  padding: 0;
  background-color: transparent;
  .breadcrumb {
    margin: .5rem 0;
    padding: 0rem;
    background-color: transparent;
    font-size: 1.2rem;
    color: $primary-color-black;
    .breadcrumb-item + .breadcrumb-item::before {
      padding: 0.1rem;
      float: left;
      content: "/";
    }
    .btn {
      cursor:text;
      border: none;
      outline: 0rem;
      box-shadow: none;
      color: inherit;
      margin: 0rem;
      padding: 0.2rem;
      text-transform: capitalize;
    }
    
  }
  li:first-child {
      .btn{
      text-decoration: underline;
      cursor: pointer;
      }
    }
}
</style>
