<template>
  <div class="popUp">
    <div class="popUp--main">
      <div class="popUp--main--bg-popup">
        <div class="popUp-continer">
          <span class="close-btn">
            <button @click="$emit('closePopUp')">
              <img src="@/assets/imgs/cancel.svg" alt="" />
            </button>
          </span>
          <slot class="popUp-continer__display">

          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.popUp {
  transition: opacity .3s ease;
  display: block;
  z-index: 999;
  position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  

  &--main {
    background-color: #00000049;
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: flex-end;
    align-items: flex-end;
    &--bg-popup {
        z-index: 9999;
        border: transparent;
      background:$primary-color-white 0% 0% no-repeat padding-box;
      box-shadow: 0px -6px 12px #00000010;
      border-radius: 3rem 3rem 0px 0px;
      opacity: 1;
      max-height: 70vh;
      width: 100%;
      margin-bottom: 3rem;
      @media screen and (max-width: 580px){
        margin-bottom: 0rem;
      }
      .popUp-continer{

      width: 100%;
      height: 100%;
      position: relative;
          padding: 3% 5% 1% 5%;
.close-btn{
          position: absolute;
          top: 5%;
          right: 5%;
         button{
         background: #F7F7F7 0% 0% no-repeat padding-box; 
             height: 3.5rem;
            width: 3.5rem;
            border: 1px solid transparent;
            border-radius: 50%;
            outline: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
             padding: 1rem;
           img{
            height: 100%;
           }
         }
      }
      }
      
      
    }
    // top: 0%;
  }
}
</style>
