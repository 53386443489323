<template>
  <div class="DataWithRadioAndYesOrNO">
    <div class="" :id="id">
      <div
        class="row m-0 px-2 radio-groups col form-group m-0 p-0 my-1 d-flex align-items-center justify-content-between"
      >
        <span
          class="col-8 m-0 form-group__DataWithRadioAndYesOrNO-label d-flex align-items-start justify-content-start"
        >
          <div class="form-check px-2 mr-2">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="CheckButtonData"
              @change="updateData()"
              :id="id"
            />
            <label class="form-check-label" :for="id"> </label>
          </div>
          <p class="text">
            {{ textData }}
            <!-- {{questionData.questionText}} -->
          </p>
        </span>
        <div
          class="col-4 d-flex align-items-center justify-content-end pl-2 pr-0"
          v-if="CheckButtonData == true"
        >
         <div class="form-radio px-1">
           <input
              class="mx-2 form-radio__input"
              type="radio"
              :id="id + 'data-true'+textData"
              v-model="RadioButtonData"
              @change="updateData()"
              value="true"
            />
            <label
            :for="id + 'data-true'+textData"
            class=" form-radio__label d-flex align-items-center mx-1 py-1"
          >Yes
          </label>
          
         </div>
         <div class="form-radio px-1">
           <input
              class="mx-2 form-radio__input"
              type="radio"
              :id="id + 'data-false'+textData"
              value="false"
              @change="updateData()"
              v-model="RadioButtonData"
            />
            <label
            :for="id + 'data-false'+textData"
            class=" form-radio__label d-flex align-items-center mx-1  py-1"
          >No
          </label>
           </div> 
            
            
        </div>
        <div>
        </div>
      </div>
      <hr class="px-5" />
    </div>
    <div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      RadioButtonData: this.isMandatory,
      CheckButtonData: this.isActive,
    };
  },
  props: {
    titleData: {},
    isActive: {},
    isMandatory: {},
    uniqueId: {},
  },
  computed: {
    textData: {
      get() {
        return this.titleData;
      },
    },
    isChecked: {
      get() {
        return this.isActive;
      },
    },
    isRequired: {
      get() {
        return this.isMandatory;
      },
    },
    id: {
      get() {
        return this.uniqueId;
      },
    },
  },
  methods: {
    updateData() {
      let data = {
        id: this.id,
        RadioButtonData: this.RadioButtonData,
        CheckButtonData: this.CheckButtonData,
      };
      this.$emit("updateData", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.DataWithRadioAndYesOrNO {
  .form-check-input,
  .form-check-label,
  .form-radio__label,
  // .form-radio__input,
  .text {
    font-size: 1.6rem;
    color: $primary-color-black;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .form-radio{
    display: flex;
    align-items: center;
    &__label{
          padding: 0rem;
          margin: 0rem;
        }
    &__input{
      height: 1.8rem;
      width: 1.8rem;
    }
  }
  .text {
    margin: 0;
  }
}
</style>
