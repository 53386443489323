<template>
  <button class="primary-btn btn-next-submit" :class="className">
    <!-- Login -->
    {{ buttonName }}
  </button>
</template>
<script>
export default {
  props: {
    buttonName: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      required: false
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-next-submit {
  min-width: 135px;
}
.primary-btn {
  margin: 0.5rem 0rem;
  height: 4.5rem;//5rem;
  width: 100%;

  white-space: nowrap ;
  overflow-wrap: normal;
  word-break: keep-all;
  

  padding:0 1rem;
  background: $primary-color-red;
  border: 1px solid transparent;
  border-radius: 3px;
  color: $primary-color-white;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    background-color: $primary-color-red-light;
    box-shadow: 0px 0.6rem 1.2rem $primary-color-red-darkest;
  }
  &:active {
    background-color: $primary-color-red-darkest;
  }
}
</style>
