<template>
  <div class="programInvitations">
    <div
      class="row justify-content-between"
      v-if="inputs.id == 0 ? isHide == true : isHide == false"
    >
      <div class="col-4 px-1">
        <p class="para-info">
          {{ inputs.programName }}
        </p>
      </div>
      <div class="col-3 px-1">
        <p class="para-info">{{ inputs.firstName }} {{ inputs.lastName }}</p>
      </div>
      
      <div class="col-3 px-1">
        <p class="para-info ">
          {{ inputs.notifiedOn }}
        </p>
      </div>
      <div class="status--continer col-sm-2 col-12 px-1">
        <p class="para-info">
         {{ inputs.programStatus }}
        </p>
      </div>
    </div>
    <div
      class="row justify-content-between"
      v-if="inputs.id == 0 ? isHide == false : isHide == true"
    >
      <div class="col-sm-6 col-12 form-group">
        <select
          class="select-group"
          placeholder=" "
          id="invitation-programName"
          name="invitation-programName"
          v-model="inputs.programID"
        >
          <option
            v-for="programInvitation in invite.programInvitation"
            :key="programInvitation.programId"
            :value="programInvitation.programId"
          >
            {{ programInvitation.programName }}
          </option>
        </select>
     
        <label for="invitation-programName" class="form__label required">
          {{ label.programName }}
        </label>
           <Error v-if="v$.inputs.programID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.programID'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>

      <div class="col-3 form-group">
      </div>
      <div class="col-3 col-3-internal"></div>
    </div>
    <div class="row" v-if="inputs.id == 0 ? isHide == false : isHide == true">
      <div class="col form-group">
        <select
          class="select-group"
          placeholder=" "
          id="invitation-user"
          name="invitation-user"
          v-model="inputs.contactID"
        >
          <!-- @change="selectOption($event)" -->
          <option
            v-for="user in businessusers"
            :key="user.contactID"
            :value="user.contactID"
          >
            {{ user.fullName }}
          </option>
        </select>
        <label for="invitation-user" class="form__label required">
          {{ label.user }}
        </label>
             <Error v-if="v$.inputs.contactID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.contactID'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
    </div>
    <div
      class="row justify-content-end d-flex"
      v-if="inputs.id == 0 ? isHide == false : isHide == true"
    >
      <div class=" d-flex justify-content-end">
        <span class=" mr-3 p-0" v-if="inputs.id == 0">
          <BtnSecondary :buttonName="`Cancel`" @click="cancelInvitation()" />
        </span>
        <span class=" btn--primary p-0">
          <BtnPrimary :buttonName="`Notify`" @click="SaveNotify" />
        </span>
      </div>
    </div>
    <hr />
  </div>
</template>
<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
//import axios from "axios";
import useVuelidate from "@vuelidate/core";
import Error from "@/components/Error.vue";
import { required } from "@vuelidate/validators";
import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  props: {
    programInvite: {},
    businessID: Number,
  },
  components: {
    BtnPrimary,
    BtnSecondary,
    Error,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputs: {
        programID: { required },
        contactID: { required },
      },
    };
  },
  data() {
    return {
      isHide: false,
      label: {
        businessEntity: "Business Entity",
        programName: "Program Name",
        user: "User",
      },

      //businessusers: {},
    };
  },

  methods: {
    hideShow() {
      this.isHide = !this.isHide;
    },
    cancelInvitation() {
      this.$store.dispatch("updateLoaderFlag",true)
      this.$store.state.business.programs.programInvitation = [{}];
      this.$store.dispatch(
        "getBusinessProgramInvitations",this.$store.state.business.businessEntityData.id, {root: true,}
      );
      this.$store.dispatch("updateLoaderFlag",false)
    },
    async SaveNotify() {
      const data = {
        businessID: this.businessID,
        programID: this.inputs.programID,
        contactID: this.inputs.contactID,
        programStatusId: 1,
      };
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("sendProgramInvitation", data)
          .then((res) => {
            Promise.resolve(res);
            this.$store.dispatch("updateLoaderFlag",false)
            if(!res.data.isSuccess)
            {
              this.toasterMessage('',res.data.message,'danger')
            }
            else{
                  this.toasterMessage('',res.data.message,'success')
                this.$store.dispatch("getBusinessProgramInvitations",this.$store.state.business.businessEntityData.id,{root: true,}
                );
                this.$store.dispatch("FundingSourceGetProgramList", this.$store.state.business.businessEntityData.id, {root: true,})
                this.$store.dispatch("fetchBusinessUsersDetails", this.$store.state.business.businessEntityData.id, {root: true,});
                this.$store.dispatch("GetBusinessProfileData", this.$store.state.business.businessEntityData.id,{root: true,})
            }
            
             
            this.isHide = false;
            //this.$router.push("/bel");
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            this.toasterMessage('','Error while processing request','danger')
            Promise.reject(err);
            this.LoginError = true;
          });
      }
    },
  },

  computed: {
    inputs: {
      get() {
        return this.programInvite;
      },
    },
    invite: {
      get() {
        return this.$store.state.business.temporary;
      },
    },
    businessusers: {
      get() {
        return this.$store.state.business.businessusers;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.programInvitations {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  position: relative;
  .hide-show-btn {
    position: absolute;
    right: 3%;
    button {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      padding: 0.01rem;
      border: none;
      img {
        height: 50%;
      }
    }
  }
  .para-info {
    font-size: 1.6rem;
    padding: 0rem;
    margin: 0rem;
    overflow-wrap: break-word;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .row {
    padding-right: 2%;
    margin: 0;
  }
  .status--continer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 0.2rem;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>
