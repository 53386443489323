<template>
<div>
  <div class="user">
    <span class="hide-show-btn" v-if="hideShowBar==true">
      <button class="mr-2" v-if="inputs.showActivationLink == true" title="Send activation link" >
        <span>
         <a href="#"><img src="@/assets/imgs/sendMailIcon.svg" alt="send Mail Icon" @click="sendActivation(inputs.emailAddress)" ></a> 
        </span>
      </button>
      <button @click="hideShow()">
        <span> </span>
        <img v-if="isHide == false" src="@/assets/imgs/arrow-down.png" alt="down arrow" />
        <img v-if="isHide == true" src="@/assets/imgs/arrow-up.png" alt="up arrow" />
        <!-- arrow-up -->
      </button>
    </span>
    <div class="user-info">
      <div class="row justify-content-between " v-if="inputs.contactID==0?isHide == true:isHide == false">
          <div class="col-2 px-1">
            <p class="para-info">
            {{ inputs.businessRoleName }}
          </p>   
        </div>
        
          <div class=" col-3 px-1">
            <p class="para-info">
             {{inputs.firstName}} {{inputs.lastName}}
          </p>
          </div>
          <div class=" col-3 px-1">
            <p class="para-info">
              {{inputs.emailAddress}}
          </p>
          </div>
          <div class=" col-2 px-1">
            <p class="para-info ">
              {{inputs.phoneNo}}
          </p>
          </div>

          <div class="status--continer col-sm-2 col-12 px-1 ">
            <p class="para-info">
              {{ inputs.accountStatus }}
            </p>
          </div>
        
      </div>
      <div class="row justify-content-between" v-if="inputs.contactID==0?isHide == false:isHide == true">
        <div class=" col-sm-4 col-12  form-group">
          <select
            class="select-group"
            placeholder=" "
            v-model="inputs.businessRoleID"
            id = "user-roleID"
            name="user-roleID"
            :disabled=isDisable
            :readonly=isDisable
          >
            <option
              v-for="role in roleList"
              :key="role.businessRoleID"
              :value="role.businessRoleID"
            >
              {{ role.businessRoleName }}
            </option>
          </select>
       
          <label for="user-roleID" class="form__label select-label">
            {{ labels.role }}
          </label>
             <Error v-if="v$.inputs.businessRoleID.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.businessRoleID'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>
        <div class="col-sm-4 col-12 pb-4 custom-control custom-switch">
          <span>
            <p class="para-info">{{ labels.status }}</p>
          </span>
          <input
            type="checkbox"
            class="custom-control-input statusSwitch"
            :id="index"
            v-model="inputs.isActive"
            true-value="true"
            false-value="false"
          :disabled=isDisable
          :readonly=isDisable
          />
          <label class="custom-control-label" :for="index"></label>
          <span>
            <p class="para-info" v-if="inputs.isActive == 'true'">
              {{ labels.active }}
            </p>
            <p class="para-info" v-if="inputs.isActive == 'false'">
              {{ labels.inactive }}
            </p>
          </span>
        </div>

        <div class="col-sm-4 col-12"></div>
      </div>
      <div class="row justify-content-between" v-if="inputs.contactID==0?isHide == false:isHide == true">
        <div class=" col-sm-4 col-12  form-group">
          <select
            class="select-group"
            required
            placeholder=" "
            v-model="inputs.salutationID"
            id="user-salutationID"
            name="user-salutationID"
            :disabled=isDisable
            :readonly=isDisable

          >
            <!-- @change="selectOption($event)" -->
            <option
              v-for="salutation in salutationList"
              :key="salutation.salutationID"
              :value="salutation.salutationID"
            >
              {{ salutation.salutationName }}
            </option>
          </select>
      

          <label for="user-salutationID" class="form__label required">
            {{ labels.title }}
          </label>
              <Error v-if="v$.inputs.salutationID.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.salutationID'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>

        <div class=" col-sm-4 col-12  form-group">
          <input
            type="text"
            required
            v-model="inputs.firstName"
            placeholder=" "
            id="user-firstName"
            name="user-firstName"
            :disabled=isDisable
            :readonly=isDisable
          />
      
          <label for="user-firstName" class="form__label required">
            {{ labels.firstName }}
          </label>
              <Error v-if="v$.inputs.firstName.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.firstName'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>
        <div class="col-sm-4 col-12 form-group">
          <input
            type="text"
            v-model="inputs.middleName"
            placeholder=" "
            id="user-middleName"
            name="user-middleName"
            :disabled=isDisable
            :readonly=isDisable
          />
          <label for="user-middleName" class="form__label required">
            {{ labels.middleName }}
          </label>
        </div>
      </div>
      <div class="row justify-content-between" v-if="inputs.contactID==0?isHide == false:isHide == true">
        <div class=" col-sm-4 col-12  form-group">
          <input
            type="text"
            required
            v-model="inputs.lastName"
            placeholder=" "
            id="user-lastName"
            name="user-lastName"
            :disabled=isDisable
            :readonly=isDisable

          />
  

          <label for="user-lastName" class="form__label required">
            {{ labels.lastName }}
          </label>
                  <Error v-if="v$.inputs.lastName.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.lastName'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>
        <div class=" col-sm-4 col-12  form-group">
          <input
            type="email"
            v-model="inputs.emailAddress"
            required
            placeholder=" "
            id="user-emailAddress"
            name="user-emailAddress"
            :disabled=isDisable
            :readonly=isDisable

          />
              <label for="user-emailAddress" class="form__label required">
            {{ labels.emailAddress }}
          </label>
          <Error v-if="v$.inputs.emailAddress.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.emailAddress'">
                {{ error.$message }}
              </small>
            </p>
          </Error>

      
        </div>
        <div class=" col-sm-4 col-12   form-group">
          <input
            required
            max="12"
          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
            v-model="inputs.phoneNo"
            placeholder="XXX-XXX-XXXX"
            type="text" 
          @keyup="formatePhoneNumberToUSA"
          @blur="formatePhoneNumberToUSA"
            id="user-phoneNo"
            name="user-phoneNo"
            :disabled=isDisable
            :readonly=isDisable
        

          />
          
          <label for="user-phoneNo" class="form__label required">
            {{ labels.phoneNo }}
          </label>
          <Error v-if="v$.inputs.phoneNo.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.phoneNo'">
                <!-- {{ error.$message }} -->Enter valid Phone Number
              </small>
            </p>
          </Error>

        </div>
      </div>
      <div class="row d-flex justify-content-end" v-if="inputs.contactID==0?isHide == false:isHide == true">
        <span class=" mr-2" v-if="inputs.contactID==0 && (userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)">
          <BtnSecondary :buttonName="`Cancel`" @click="cancel()" />
        </span>
        <span class=" " v-if="(userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)">
          <BtnPrimary :buttonName="`Save`" @click="saveUserEntity()" />
        </span>
        
      </div>
      <div class="row"></div>
      <hr />
    </div>
  </div>
</div>

</template>
<script>
import textStore from "@/textStore";
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnSecondary from "@/components/BtnSecondary.vue";
import useVuelidate from "@vuelidate/core";
import Error from "@/components/Error.vue";
import { required,email,minLength } from "@vuelidate/validators";

export default {
  beforemount() {
    
  },
  components: {
    BtnPrimary,
    BtnSecondary,
    Error,
  },
  setup() {
    return {
      textStore,
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputs: {
        businessRoleID: { required },
        salutationID: { required },
        firstName: { required },
        lastName: { required },
        emailAddress: { required,email },
        phoneNo: { required,minLength: minLength(12) },
      },
    };
  },
  props: {
    roleList: [],
    salutationList: [],
    userData: {},
    index: {},
    hideShowBar:{
      type:Boolean
    }
  },
  data() {
    return {
      phoneNumberSetUp:{
        phoneValue: '',
      formattedPhoneValue: "",
      preventNextIteration: false,
      },
      isHide:false,
      toggleValue: "true"//this.inputs.isActive,
    };
  },
  methods: {
    formatePhoneNumberToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      let phoneNumber = this.inputs.phoneNo
        .replace(/[^\d,]/g,'').slice(0,10)

      // Format display value based on calculated 
      this.inputs.phoneNo = phoneNumber.replace(
        /(\d{1,3})(\d{1,3})(\d{1,4})/g,
        "$1-$2-$3"
      );
    },
    cancel(){
      this.$emit('cancle')

    },
    hideShow() {
      this.isHide = !this.isHide;
    },

    isActive() {
      return (this.inputs.isActive = this.toggleValue);
    },

    async saveUserEntity() {
      const isFormCorrect = await this.v$.$validate();

      let param = {
        contactID: Number(this.inputs.contactID),
        businessRoleID: (this.inputs.businessRoleID),
        isActive: this.inputs.isActive,
        salutationID: (this.inputs.salutationID),
        firstName: this.inputs.firstName,
        middleName: this.inputs.middleName,
        lastName: this.inputs.lastName,
        emailAddress: this.inputs.emailAddress,
        phoneNo: this.inputs.phoneNo,
        businessID: this.businessID,
        accountStatusID: 1,
        userRoles: [2],
      };
      
      this.$store;
      if (isFormCorrect) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("SaveUserEntity", param)
          .then(async(res) => {
            if (res.data.isSuccess) {
              this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage('',res.data.message,'success')
              this.$store.dispatch("fetchBusinessUsersDetails", this.$store.state.business.businessEntityData.id, {
                root: true,
              });
              this.$store.dispatch("GetBusinessProfileData", this.$store.state.business.businessEntityData.id)
             Promise.resolve(true);
            this.isHide=false
            this.$emit('cancle')
            } else {
              this.$store.dispatch("updateLoaderFlag",false)
              if((res.data.validationErrors!=null || res.data.validationErrors!=undefined) && res.data.validationErrors.length>0)
              {
                res.data.validationErrors.forEach(element => {
                  this.toasterMessage('',element,'danger')
                });
              }
              else{
                this.toasterMessage('','Failed to save contact','danger')
              }
              
            }
            return Promise
          })
          .catch(() => {
            this.$store.dispatch("updateLoaderFlag",false)
            this.toasterMessage('','Error while processing request','danger')
            return Promise.resolve(false);
          });
      } else {
        return Promise.resolve(false);
      }
    },

    sendActivation(emailAddress)
    {
      if(this.userRoleID !=8){
      this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("resetPasswordLink", emailAddress)
          .then((res) => {
            Promise.resolve(res);
            this.$store.dispatch("updateLoaderFlag",false)
            this.$store.dispatch("fetchBusinessUsersDetails", this.$store.state.business.businessEntityData.id, {
                root: true,
              });
            this.toasterMessage('','Activation link sent Successfully','success')
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            this.toasterMessage('','Error while processing request','danger')
            Promise.reject(err);
            this.LoginError = true;
          });
      }
    },
  },
  computed: {  
    inputs: {
      get() {
        return this.userData;
      },
    },
    labels: {
      get() {
        return textStore.businessEntityLabel.users;
      },
    },
    
    businessID: {
      get() {
        return this.$store.state.business.businessEntityData.id;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.user {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  position: relative;
  .status--continer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 0.2rem 0.5rem;
    .user-active {
      &::before {
        content: "\00a0 ";
        height: 0.8rem;
        width: 0.8rem;
        background-color: $color-green;
        margin: 0rem 0.5rem;
        border-radius: 50% 50%;
        display: inline-block;
      }
    }
    .user-inactive {
      &::before {
        content: "\00a0 ";
        height: 0.8rem;
        width: 0.8rem;
        background-color: $primary-color-red;
        margin: 0rem 0.5rem;
        border-radius: 50% 50%;
        display: inline-block;
      }
    }
    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem;
      margin: 0rem;
      font: normal normal normal 0.8rem;
      letter-spacing: 0px;
      color: $primary-color-black;
      text-transform: capitalize;
    }
  }
  .hide-show-btn {
    display: flex;
    position: absolute;
    right: 1%;
    button {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      padding: 0.01rem;
      border: none;
      img {
        height: 50%;
      }
    }
    @media screen and(max-width:580px){
      display: flex;
      flex-direction: column;
    }
  }
  .row {
    padding-right: 8%;

    margin: 0;
  }
  .custom-switch {
    input {
      position: relative;
      display: none;
    }
    p {
      display: inline-block;
      padding: 0.2rem;
      margin: 0;
    }
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .custom-control-label {
      padding: 0rem 0rem 0rem 5rem;
    }
    .custom-control-label::before {
      position: relative;
      // left: 2rem;
      width: 3.4rem;
      height: 1.6rem;
      pointer-events: all;
      border-radius: 1.5rem;
    }
    .custom-control-label::after {
      position: relative;
      left: calc(-3.25rem + 2px);
      height: 2rem;
      width: 2rem;
      background-color: #adb5bd;
      margin-top: -19px;
      border-radius: 3.5rem;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      position: relative;
      color: #fff;
      border-color: #71a50c;
      background-color: #71a50c;
      opacity: 0.5;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      position: relative;
      background-color: #71a50c;
      margin-top: -19px;
      height: 2rem;
      width: 2rem;
      // top: 0.5px ;
      border-radius: 1rem;
      transform: translateX(2.5rem);
    }
  }
  .para-info {
    font-size: 1.6rem;
    padding: 0rem;
    margin: 0rem;
    overflow-wrap: break-word;
    word-wrap: break-word;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>
