<template> 
<div class="agreement pr-2">
    <QuillEditor ref="agreement" theme="snow" :toolbar="toolbarOptions" />
</div>  
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
 
export default { 
   data(){
        return {
            data : 'Hello',
             content: "<p> </p>",
             toolbarOptions : [
                        ['bold', 'italic', 'underline', 'strike','link'],        // toggled buttons
                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme                                       
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                       // text direction
                        [{ 'align': [] }],
                        ['clean']                                         // remove formatting button
                      ]
        };
    },
    computed: {
      editor() {
        return this.$refs.agreement
      },
    },
    components: {
   QuillEditor
  },
  created(){
  },
  mounted() {
    this.GetProgramWiseAgreement()
    // show the predefined data
    //  this.editor.setHTML(this.content)
  },

  methods: {
    async GetProgramWiseAgreement(){
      await this.$store
          .dispatch("GetProgramWiseAgreement", this.$store.state.fundSource.fundingSource.fundingSourceID)
          .then((res)=>{
            this.content = res.agreementBody
            this.editor.setHTML(this.content)
          })
          .catch(()=>{
            this.content = " "
            this.editor.setHTML(this.content)
          })
    },
    async saveAgreement() {   
      this.$store.dispatch("updateLoaderFlag", true);
      //save the data
    this.content =this.editor.getHTML();
     await this.$store
     .dispatch("SaveOrUpdateAgreementData", this.content)
          .then((res)=>{
            this.toasterMessage('',res.data.message,'success')
            this.$store.dispatch("updateLoaderFlag", false);
          })
          .catch((err)=>{
            console.log(err)
            this.toasterMessage('','Failed to save','danger')
            this.$store.dispatch("updateLoaderFlag", false);
          })
     
      
    },
  }  
};
</script>

<style lang="scss" scoped>
.agreement{
  .ql-container{
    min-height:25rem;
  }
}

</style>
