<template>
  <div class="affiliate">
    <BasicComponent :heading="getPageHeader()">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>
      <template v-slot:mainSection>
        <div class="form-section">
              <div class="horizontal">
                <div>
                  <div class="row m-0">
                    <div class="col  form-group">
                      <input
                        required
                        placeholder=" "
                        type="text"
                        id="affiliate-affiliateName"
                        name="affiliate-affiliateName"
                        v-model="inputs.affiliateName"
                        @focus="checkAffiliateName()"
                        @blur="checkAffiliateName()"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                         <label for="affiliate-affiliateName" class="form__label required">
                        {{ labels.affiliateName }}
                      </label>
                      <Error v-if="affiliatesError.isError==true">
                            <p>
                            <small>
                                {{affiliatesError.message}}
                            </small>
                            </p>
                        </Error>
                    </div>
                  </div>
                  <div class="row justify-content-between m-0">
                      <div class="col-sm-6 col-12 form-group">
                      <input
                        placeholder=" "
                        type="text"
                        id="affiliate-firstName"
                        name="affiliate-firstName"
                        v-model="inputs.firstName"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                      <label for="affiliate-firstName" class="form__label">
                        {{ labels.firstName }}
                      </label>
                    </div>
                    <div class="col-sm-6 col-12  form-group">
                      <input
                        placeholder=" "
                        type="text"
                        id="affiliate-lastName"
                        name="affiliate-lastName"
                        v-model="inputs.lastName"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                      <label for="affiliate-lastName" class="form__label">
                        {{ labels.lastName }}
                      </label>
                    </div>
                  </div>
                  <div class="row justify-content-between m-0">
                      <div class="col-sm-6 col-12  form-group">
                      <input
                        placeholder=" "
                        type="email"
                        id="affiliate-emailAddress"
                        name="affiliate-emailAddress"
                        v-model="inputs.emailAddress"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                      <label for="affiliate-emailAddress" class="form__label">
                        {{ labels.emailAddress }}
                      </label>
                    </div>
                    <div class="col-sm-6 col-12  form-group">
                      <input
                        placeholder=" XXX-XXX-XXXX"
                        type="text"
                        id="affiliate-phoneNumber"
                        name="affiliate-phoneNumber"
                        v-model="inputs.phoneNumber"
                        @keyup="formatePhoneNumberToUSA"
                        @blur="formatePhoneNumberToUSA"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                      <label for="affiliate-phoneNumber" class="form__label">
                        {{ labels.phoneNumber }}
                      </label>
                    </div>
                  </div>
                  <div class="row m-0">
                    <div class="col  form-group">
                      <input                  
                        placeholder=" "
                        type="text"
                        id="affiliate-affiliateAddress"
                        name="affiliate-affiliateAddress"
                        v-model="inputs.affiliateAddress"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                      <label for="affiliate-affiliateAddress" class="form__label">
                        {{ labels.affiliateAddress }}
                      </label>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="forms-btns row m-0 justify-content-end d-flex">
                  <span v-if="userRoleID !=8">
                    <BtnPrimary :buttonName="`Save`" @click="saveAffiliate()" />
                  </span>
                </div>
              </div>
        </div>
        <LoadingMask v-if="isLoading === true" />
      </template>
    </BasicComponent>
  </div>
</template>
<script>
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import BtnPrimary from "@/components/BtnPrimary.vue";
import Label from "@/textStore.js";
import Error from "@/components/Error.vue";
export default {
  components: {
    BasicComponent,
    BreadCrumb,
    BtnPrimary,
    Error,
  },
  data() {
    return {
      labels: {},
      heading:'',
      affiliatesError:{
          message:"Enter Affiliate Name",
          isError:false
      },
      crumbs: ["Affiliates", "Affiliate"],
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
    };
  },
props: {
    affiliateID: {
      type: Number,
      default: 0,
    }
  },
mounted() {
      if (this.affiliateID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store.dispatch("getAffiliate", this.affiliateID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);           
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage("", err, "danger");
            Promise.reject(err);
          });
      }
      else{
         this.$store.state.affiliate.affiliateData= {
             affiliateName: "",
            affiliateAddress: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            emailAddress: "",
            active: "",
            affiliateID: 0,
         } 
      }
  },
  computed: {
    inputs: {
      get() {
        Object.assign(this.labels, Label.affiliateLabel);
        return this.$store.state.affiliate.affiliateData;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
      userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    },
  },
  methods:{
      getPageHeader()
    {
        let affiliateName = this.$store.state.affiliate.affiliateData.affiliateName;
       if(affiliateName === undefined || affiliateName === "")   
        this.heading = "New Affiliate";
        else
        this.heading = affiliateName;
        return this.heading;
    },
      selected(crumb) {
      if (crumb == "Affiliates") {
        this.$router.push("/affiliates");
      }
    },
      formatePhoneNumberToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      let phoneNumber = this.inputs.phoneNumber
        .replace(/[^\d,]/g, "")
        .slice(0, 10);

      // Format display value based on calculated currencyValue
      this.inputs.phoneNumber = phoneNumber.replace(
        /(\d{1,3})(\d{1,3})(\d{1,4})/g,
        "$1-$2-$3"
      );
    },
    checkAffiliateName(){
        if(this.inputs.affiliateName.trim()==" "){
            this.affiliatesError.isError=true
        }
        else{
            this.affiliatesError.isError=false
        }
    },
    saveAffiliate(){
        if(this.inputs.affiliateName.trim()==""){
            this.affiliatesError.isError=true
        }
        else{
           let param= this.inputs
      this.$store.dispatch("updateLoaderFlag",true)
        this.$store.dispatch("saveOrUpdateAffiliate", param, { root: true })
        .then((res)=>{
            this.$store.dispatch("updateLoaderFlag",false)
            // affiliateID
              this.toasterMessage("", res.message, "success");
              this.$router.push("/affiliate/form/"+res.affiliateID);
        })
        .catch((err)=>{
            this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage("", err.message, "danger");

        }) 
        }
        
        
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.affiliate {
  .form-section{
    @media screen and(max-width:580px) {
            min-height: 82vh;
          }
  }

  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>
