<template>
  <div class="paymentSchedule p-0">
    <span class="delete-btn" v-if="inputs.paymentScheduleID<=0" >
        <button
          @click="deletePaymentSchedule(counter)">
          <img src="@/assets/imgs/cancel.svg" alt="Remove icon" />
        </button>
      </span>
    <div class="row justify-content-start m-0 p-0">
      <div class="col-12 col-sm-3  form-group">
        <flat-pickr
          :config="Dateconfig"
          required
          placeholder=" "
          name="paymentSchedule-transactionDate"
          id="paymentSchedule-transactionDate"
          :disabled="shouldReadOnly"
          type="text"
          v-model="inputs.transactionDate"
          class="datepicker">
        </flat-pickr>
        <label for="paymentSchedule-transactionDate" class="form__label required">
          {{ labels.transactionDate }}
        </label>
        <Error v-if="v$.inputs.transactionDate.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.transactionDate'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
      <div class=" col-sm-3 col-12  form-group">
        <select
          class="select-group"
          required
          placeholder=" "
          id="paymentSchedule-fundingTypeID"
          name="paymentSchedule-fundingTypeID"
          :disabled="isDisable"
          :readonly="isDisable"
          v-model="inputs.fundingTypeID"
        >
          <option
            v-for="fundingType in fundingTypeList"
            :key="fundingType.fundingTypeID"
            :value="fundingType.fundingTypeID"
          >
            {{ fundingType.type }}
          </option>
        </select>

        <label for="paymentSchedule-fundingTypeID" class="form__label select-label required">
          {{ labels.fundingType }}
        </label>
        <Error v-if="v$.inputs.fundingTypeID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.fundingTypeID'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
      <div class="  col-sm-3 col-12 form-group">
        <input
          v-model="inputs.fundedAmount"
          required
          type="text"
          placeholder="$"
          maxlength="22"
          min="0"
          id="paymentSchedule-fundedAmount"
          name="paymentSchedule-fundedAmount"
          v-money3="config"
          :disabled="isDisable"
          :readonly="isDisable"
        />
        <label
          for="paymentSchedule-fundedAmount"
          class="form__label required"
        >
          {{ labels.fundingAmount }}
        </label>
        <Error v-if="v$.inputs.fundedAmount.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.fundedAmount'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import { required} from "@vuelidate/validators";
import Error from "@/components/Error.vue";
import useVuelidate from "@vuelidate/core";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Money3Directive } from "v-money3";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputs: {
        transactionDate: { required },
        fundingTypeID: { required },
        fundedAmount: { required },
      },
    };
  },
  data() {
    return {
      Dateconfig: {
        dateFormat: "m/d/Y",
        disableMobile: "true",
        minDate: "today"
      },
      labels: {
        transactionDate: "Transaction Date",
        fundingType: "Funding Type",
        fundingAmount: "Funding Amount",
        status: "Status",
      },
      paymentStateOption: {},
      fundingTypeList: {},
      totalFundedAmount:this.totalAmount,
    };
  },
  props: {
    entity_ID: {
      type: Number,
      default: 0,
    },
    counter:{
      type:Number
    },
    paymentScheduleID:{
      type:Number,
    },
    inputData:{
      type:Object
    }
  },
  components: {
    Error,
    flatPickr,
  },
  directives: {
    money3: Money3Directive,
  },
  computed: {
    userRoleID() {
      return this.$store.getters.getUserRoleID;
    },
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        disableNegative: true,
        masked: false /* doesn't work with directive */,
      };
    },
    inputs: {
      get() {
        return this.inputData;
      },
    },
    totalAmount:{
      get(){
        
        let totalAmount=0
        let list=this.PaymentScheduleTransactionList;
        for(let i=0;i<list.length;i++){
          totalAmount = totalAmount + list[i].fundedAmount
        }
        return totalAmount
      }
    },
    PaymentScheduleTransactionList: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness.PaymentScheduleTransactionList;
      },
    },
    PaymentScheduleSummary: {
      get() {
        return this.$store.state.business.fundingDetailsForBusiness.PaymentScheduleSummary;
      },
    },
  },
  created() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.$store
      .dispatch("getmasterData")
      .then((res) => {
        this.fundingTypeList = res.data.fundingTypeList;
        this.$store.dispatch("updateLoaderFlag", false);
      })
      .catch((err) => {
        this.$store.dispatch("updateLoaderFlag", false);
        Promise.reject(err);
      });
  },
  methods: {
    deletePaymentSchedule(counter){
      this.$emit('deletePaymentSchedule', counter)
    },
    async validatePaymentSchedule(){
      const isFormCorrect = await this.v$.$validate();
        this.inputs.fundedAmount = Number(String(this.inputs.fundedAmount).replace(/[^\d]/g, ""))
        if(this.inputs.fundedAmount==0){
        this.toasterMessage("", `Fund amount can not be zero`, "danger");
        return false
      }
      if(isFormCorrect==true){
        return true
      }
      else{
        return false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.paymentSchedule {
  padding: .1rem 2rem;
  position: relative;
  .delete-btn {
      position: absolute;
      right: 2%;
      top: 2%;
      button {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.5rem;
        border: none;
        img {
          height: 90%;
        }
      }
      @media screen and (max-width:580px) {
        top: -10%;
      }
    }
  .form-group {
    padding: 0;
    margin-top: 0.5rem;
    position: relative;
    @include input-style;
  }
  .heading {
    font-size: 2.6rem;
    font-weight: bold;
    text-transform: capitalize;
    color: $primary-color-red;
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
}
</style>
