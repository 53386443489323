<template>
  <button class="secondary-btn">
    <!-- Login -->
    {{ buttonName }}
  </button>
</template>
<script>
export default {
  props: {
    buttonName: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.secondary-btn {
  margin: 0.5rem 0rem;
   white-space: nowrap ;
  overflow-wrap: normal;
  word-break: keep-all;
  padding:0 1rem;
  height: 4.5rem;//5rem;
  width: 100%;
  background: transparent;//$primary-color-red;
  border: 1px solid $primary-color-red;
  border-radius: 3px;
  color: $primary-color-red;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    border:1px solid $primary-color-red-light;
    color: $primary-color-red-light;
    box-shadow: 0px 0.6rem 1.2rem $primary-color-red-darkest;
  }
  &:active {
    color: $primary-color-red-dark;
    background-color:#FFE6EA;// $primary-color-red-darkest;
  }
}
</style>
