<template>
  <section class="welcome">
    <h5>{{ title }}</h5>
    <div class="welcome__brief">
      <div class="steps">
        <div class="step-1 step">
          <div class="step__left">
            <h6>
              {{ brief.step1H6 }}
            </h6>
            <p>
              {{ brief.step1P }}
            </p>
          </div>
          <div class="step__right">
            <img src="@/assets/imgs/resume.svg" alt="resume img" />
          </div>
        </div>
        <div class="step-2 step">
          <div class="step__left">
            <h6>
              {{ brief.step2H6 }}
            </h6>
            <p>
              {{ brief.step2P }}
            </p>
          </div>
          <div class="step__right">
            <img src="@/assets/imgs/handshake.svg" alt="handshake img" />
          </div>
        </div>
        <div class="step-3 step">
          <div class="step__left">
            <h6>
              {{ brief.step3H6 }}
            </h6>
            <p>
              {{ brief.step3P }}
            </p>
          </div>
          <div class="step__right">
            <img src="@/assets/imgs/funding.svg" alt="funding img" />
          </div>
        </div>
      </div>
    </div>
    <div class="welcome__btn">
      <h5>{{ preBtnText }}</h5>
      <div class="btn">
        <BtnPrimary buttonName="YES, APPLY NOW" @click="createLoan" />
      </div>
    </div>
  </section>
  <PopUp v-if="popup" @closePopUp="popup = false" class="popup">
    <div class="my-5"></div>

    <h4 class=" popup__header">{{ popUpTexts.header }}</h4>

    <div class="listingTable">
      <ListTable
        class="listingTable--continer"
        :items="items"
        :columns="columns"
        :perPage="itemsPerTable"
        @selectRow="selectInvite"
      />
    </div>
  </PopUp>
  <LoadingMask v-if="isLoading === true" />
</template>
<script>
import ListTable from "@/components/ListTable";
import BtnPrimary from "@/components/BtnPrimary.vue";
import PopUp from "@/components/PopUp";
export default {
  components: {
    BtnPrimary,
    PopUp,
    ListTable,
  },
  beforeCreate() {
    const roleID = this.$store.getters.getUserRoleID;
    if (roleID == 1) {
      this.$router.push("/tracks");
    } else if (
      roleID == 2 &&
      localStorage.getItem("hasOwnerPermission") == "false"
    ) {
      this.$router.push("/tracks");
    }
  },
  created() {
    if (this.isFromProgramInvitationTOProfile == true) {
      this.createLoan();
    }
  },
  data() {
    return {
      columns: ["Business Name", "Program Name", "Status"],
      itemsPerTable: 3,
      TableHeight: 35,
      items: [],
      popup: false,
      popUpTexts: {
        header: "Please choose an invitation from the list below",
      },
      /*preBtnText: "Ready to grow your business?",*/
      title: "One Stop Shop to Secure Your Funding",
      brief: {
        step1H6: "Apply Online",
        step1P:
          "Usually requires information and documents you already have on hand.",
        step2H6: "Online Review and Approval",
        step2P:
          "Once we receive your application, our experts will review and make a decision.",
        step3H6: "Receive Funding Electronically",
        step3P:
          "Once approved, funds are disbursed directly into your bank account.",
      },
    };
  },
  methods: {
    async createLoan() {
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store.state.borrower.applicationID = 0;
      this.$store.dispatch("clearStore");
      this.$store.state.borrower.ownersData = [{}];
      this.$store.state.borrower.applicantData = {};
      this.popup = true;
      let param = {
          ProgramID: [0],
        };
      await this.$store
        .dispatch("getProgramInvitationList", param, { root: true })
        .then((res) => {
          const invitation = res.data.data;
          this.$store.dispatch("updateLoaderFlag", false);
          this.items = invitation.filter((x) => x.programStatus == "Invited");
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
          this.LoginError = true;
        });
    },
    async selectInvite(selectedProgramInvitationID, programStatus) {
      console.log(programStatus);
      this.$store.state.borrower.ownersData = [{}];
      this.$store.state.borrower.documentData.inputs.FilesOthers = [];
      await this.$store.dispatch(
        "UpdateInvitation",
        selectedProgramInvitationID,
        { root: true }
      );

      this.$router.push("/form/" + selectedProgramInvitationID + "/" + 0);
    },
  },
  computed: {
    isFromProgramInvitationTOProfile: {
      get() {
        return this.$store.state.borrower.isFromProgramInvitationTOProfile;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.welcome {
  height: calc(100vh - 20rem);
  color: $primary-color-black;
  padding: 9rem 13rem;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    @media screen and(max-width:570px) {
      display: none;
    }
  }
  .popup {
    height: 100%;
    border: 1px solid transparent;
    overflow-y: auto;
  }
  @media screen and(max-width:1920px) {
    padding: 0rem;
  }
  &__name {
    padding-top: 3rem;
    padding-left: 5%;
    align-self: flex-start;
    h3 {
      font-size: 2.6rem;
      font-weight: bold;
      text-transform: capitalize;
      color: $primary-color-red;
    }
  }
  &__brief {
    width: 52.7rem;
    height: 52.7rem;
    border-radius: 50%;
    background: $secondary-color-pink;
    .steps {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .step {
        width: 55.3rem;
        height: 11rem;
        padding: 3.5rem;
        background: $primary-color-white 0% 0% no-repeat padding-box;
        box-shadow: 0px 1.2rem 2.4rem #0000000f;
        border-radius: 3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__left {
          max-width: 78%;
          color: $primary-color-black;
          h6 {
            font-weight: bold;
            font-size: 1.6rem;
            text-transform: capitalize;
          }
          p {
            font-size: 1.6rem;
            font-weight: 300;
            margin: 0;
          }
        }
      }
      .step-1,
      .step-3 {
        margin-left: -5rem;
      }
      .step-2 {
        margin-left: 6rem;
      }
    }
    @media screen and(max-width:1600px) {
      width: 35rem;
      height: 35rem;
      .steps {
        .step {
          max-height: 7rem;
          width: 40rem;
          padding: 1.5rem;

          h6 {
            font-size: 1.2rem;
          }
          p {
            font-size: 1.2rem;
          }
        }
      }
    }
    @media screen and(max-width:570px) {
      width: 25rem;
      height: 25rem;
      .steps {

        .step {
          width: auto;
          min-height: 8rem;
          padding: 1rem;
          margin-top: 1rem;
          
          &__left{
            width: 100%;
            h6{
              margin: 0%;
            }
          }
          
        }
        .step-1,
          .step-3 {
            margin-left: -2.5rem;
            margin-right: 1rem;
          }
          .step-2 {
            margin-right: -2.5rem;
            margin-left: 1.5rem;
          }
      }
    }
  }
  &__btn {
    max-width: 33.9rem;
    width: 100%;
    margin-top: 3rem;
    padding: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    h5 {
      font-weight: normal;
      font-size: 1.8rem;
      margin: 1.6rem 0;
      color: $primary-color-black;
    }
  }
  .btn {
    padding: 0rem;
    width: 100%;
    margin: 0;
    margin-bottom: 1rem;
    button {
      margin: 1rem 0rem;
      height: 6.4rem;
      font-size: 2rem;
    }
    @media screen and(max-width:1600px) {
      button {
        margin: 0.5rem 0rem;
        height: 5rem;
        font-size: 1.8rem;
      }
    }
  }

  @media screen and(max-width:570px){
  height: calc(100vh - 17.5rem);
}
}

.popup__header {
  color: $primary-color-red !important;
  font-size: 2.4rem;
}

.listingTable {
  &--continer {
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 58vh);
    overflow-y: auto;
    overflow-x: auto;
    justify-content: space-between;
  }
}
</style>
