<template>
  <div class="documents">
    <div class="documents__instrction-para">
      <h6>{{ label.instrctionHeading }}</h6>
      <p>{{ instrctionPara }}</p>
    </div>
    <div class="documents__file-section">
      <div v-if="programDocuments.length > 0">
        <div
          v-for="programDocument in programDocuments"
          :key="programDocument.documentID"
          class="doument-file"
        >
          <div class="mr-5 form-group form-file row">
            <label
              for="programDocument.documentTypeID"
              class="form__label form-file__label  col-4 mr-4"
              :class="programDocument.isRequired == true ? 'required' : ''"
            >
              {{ programDocument.documentName }}
            </label>
            <input
              required
              class="form__label form-file__label  col-2 mr-4"
              type="file"
              :hidden="shouldReadOnly"
              :disabled="shouldReadOnly"
              :name="programDocument.documentTypeID"
              :id="programDocument.documentTypeID"
              v-bind="programDocument.documentTypeID"
              @input="programDocument"
              @change="
                showPreview(
                  $event,
                  programDocument,
                  programDocument.documentName
                )
              "
            />

            <a
              :href="programDocument.fileName"
              class="downloadLink px-2"
              @click.prevent="download(programDocument)"
            >
              {{ nameLengthmax(programDocument.fileName) }}
            </a>
          </div>
          <div v-if="programDocument.isRequired==true">
            <div
              v-if="programDocument.fileName === '' || programDocument.fileName === null || programDocument.fileName === undefined ">
              <div v-if="isErrorsData.isError == true" class="error-msg--div">
                <span class="error-msg pl-3">
                  <span class="error-msg__icon"> </span>
                  <p>
                    Please upload Document
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mr-5  row" style="max-width: 100px;">
        <button
          :hidden="shouldReadOnly"
          class="secondary-btn"
          @click="oninputAdditionalDocuments()"
        >
          Add
        </button>
      </div>
      <div
        class="mr-5 additional-docs form-file row"
        v-for="(document, count) in input.FilesOthers"
        :key="count + 1"
      >
        <div class="col-4 col-4-internal document-name">
          <input
            type="text"
            placeholder="Please enter document name"
            name="document"
            :id="document"
            class="col-4"
            v-model="document.documentName"
            :disabled="shouldReadOnly"
            ref="focusDoc"
          />
        </div>
        <input
          class="form__label form-file__label required col-2 mr-4"
          type="file"
          id="documents-extraDocs"
          :disabled="shouldReadOnly"
          style="padding: 0;"
          v-bind="document"
          @input="document"
          :hidden="shouldReadOnly"
          @change="showPreview($event, document, document.documentName, count)"
        />

        <a
          :href="document.fileName"
          class="downloadLink px-2"
          @click.prevent="download(document)"
        >
          {{ nameLengthmax(document.fileName) }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import textStore from "@/textStore.js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  props: {
    shouldFieldReadOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      userNamed: "",
      labelName: true,
      // changeLabel:true,
      instrctionHeading: `Upload by selecting one the following:`,
      instrctionPara: ` Max file size is 25MB`,
      label: {},
      fileName: "",
      // SPADocumentTypeID : this.$store.state.borrower.fundingData.inputs.SPADocumentTypeID,
      isErrorsData: {
        isError: false,
        errorsData: [],
      },
    };
  },
  components: {},
  setup() {
    return {
      v$: useVuelidate(),
      textStore,
      newFiles: [],
    };
  },
  validations() {
    return {
      FormW9: { required },
      ProofOfOwnership: { required },
      ACHVendorForm: { required },
      InvoiceLetterhead: { required },
    };
  },
  created() {},
  methods: {
    nameLengthmax(name) {
      let characterLimit = 9;
      let filename = name;
      if(filename==null|| filename==undefined || filename==null){
        filename=''
      }
      if (filename.length >= characterLimit) {
        filename =
          filename
            .slice(0, filename.length)
            .substring(0, characterLimit - 1)
            .trim() + "...";
      }
      return filename;
    },
    handleFileChange(e) {
      this.$emit("input", e.target.files[0]);
    },
    focusInput() {
      var that = this;
      setTimeout(function() {
        that.$refs.focusDoc.focus();
      }, 500);
    },
    showPreview(e, item, documentName, count) {
      var files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;
      this.newFiles = e.target.files;
      var data = {
        file: this.newFiles[0],
        documentTypeID: item.documentTypeID,
        documentName: documentName,
        count: count,
        applicationDocumentID: item.applicationDocumentID,
      };

      if (this.newFiles.length) {
        //Max file size < 25mb
        if (data.file.size > 26214400) {
          this.toasterMessage("", "Max file size should be < 25MB", "danger");
          return;
        }

        var filename = data.file.name.toLowerCase();
        var fileExt = filename.split(".").pop();
        if (
          fileExt == "pl" ||
          fileExt == "bat" ||
          fileExt == "ps" ||
          fileExt == "php" ||
          fileExt == "aspx" ||
          fileExt == "cshtml" ||
          fileExt == "vbs" ||
          fileExt == "js" ||
          fileExt == "py" ||
          fileExt == "java" ||
          fileExt == "cs" ||
          fileExt == "json" ||
          fileExt == "pyd" ||
          fileExt == "pyc" ||
          fileExt == "pyo" ||
          fileExt == "pyw" ||
          fileExt == "pm" ||
          fileExt == "t" ||
          fileExt == "pod"
        ) {
          this.toasterMessage("", "Unsupported file format", "danger");
          return;
        }

        const fileSelected = e.target; //this.$refs.fileInput
        fileSelected.type = "text";
        fileSelected.type = "file";

        //upload files
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("UploadFile", data)
          .then((res) => {
            if (res.status === 200) {
              this.toasterMessage("", "File uploaded Successfully", "success");
              //if (data.documentTypeID !== 6 && data.documentTypeID !== 11) {
                if (data.documentTypeID !== 6 && data.documentTypeID !== this.SPADocumentTypeID) {
                let programDocumentsIndex = this.programDocuments.findIndex(
                  (obj) => obj.documentTypeID == data.documentTypeID
                );
                this.programDocuments[programDocumentsIndex].fileName = data.file.name;
                this.programDocuments[programDocumentsIndex].documentGUID =res.data.documentID;
                this.programDocuments[programDocumentsIndex].physicalFileStorageKey = res.data.storageKey;
                this.programDocuments[programDocumentsIndex].fileSize = data.file.size;
              }
              if (data.documentTypeID === 6) {
                // this.input.FilesOthers[count].applicationDocumentID=0
                this.input.FilesOthers[count].fileName = data.file.name;
                this.input.FilesOthers[count].documentGUID =
                  res.data.documentID;
                this.input.FilesOthers[count].physicalFileStorageKey =
                  res.data.storageKey;
                this.input.FilesOthers[count].fileSize = data.file.size;
              }
             // if (data.documentTypeID === 11) {
                if (data.documentTypeID === this.SPADocumentTypeID) {
                this.ProgressReportDocument[count].fileName = data.file.name;
                this.ProgressReportDocument[count].documentGUID =
                  res.data.documentID;
                this.ProgressReportDocument[count].physicalFileStorageKey =
                  res.data.storageKey;
                this.ProgressReportDocument[count].fileSize = data.file.size;
              }
            }

            this.$store.state.borrower.programDocumentData = this.programDocuments;
            this.$store.dispatch("updateLoaderFlag", false);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.reject(err);
          });
      }
    },

    download(item) {
      var data = {
        physicalFileStorageKey: item.physicalFileStorageKey,
        fileName: item.fileName,
      };
      this.$store
        .dispatch("Download", data)
        .then((res) => {
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
        });
    },

    async validationsStore() {
      this.isErrorsData;
      let applicantData = this.$store.state.borrower.applicantData;
      let applicationStatusID = applicantData.applicationStatusID;
      let getUserRoleID = this.$store.getters.getUserRoleID;
      if (typeof applicationStatusID == "undefined") {
        applicationStatusID = 1;
      }
      if (
        ((applicationStatusID === 1 ||
          applicationStatusID === 2 ||
          applicationStatusID === 3 ||
          applicationStatusID === 5) &&
          (getUserRoleID == 2 || getUserRoleID == 7)) ||
        ((applicationStatusID === 26 ||
          applicationStatusID === 25 ||
          applicationStatusID === 16 ||
          applicationStatusID === 21) &&
          (getUserRoleID == 2 || getUserRoleID == 4 || getUserRoleID == 6))
      ) {
        if (this.programDocuments.length > 0) {
          this.programDocuments.forEach((programDocument) => {
            if (programDocument.isRequired === true) {
              if (
                programDocument.fileName === "" ||
                programDocument.fileName === null ||
                programDocument.fileName === undefined
              ) {
                let docId = {
                  documentID: programDocument.documentTypeID,
                };
                let index = this.isErrorsData.errorsData.findIndex(function(o) {
                  return o.documentID === programDocument.documentTypeID;
                });
                if (index > -1) {
                  this.isErrorsData.errorsData[index] = docId;
                  this.isErrorsData.isError = true;
                } else {
                  this.isErrorsData.errorsData.push(docId);
                  this.isErrorsData.isError = true;
                }
              } else {
                var index = this.isErrorsData.errorsData.findIndex(function(o) {
                  return o.documentID === programDocument.documentTypeID;
                });
                if (index !== -1) {
                  this.isErrorsData.errorsData.splice(index, 1);
                  this.isErrorsData.isError = false;
                }
              }
            }
          });
        }
      }
      return !this.isErrorsData.isError;
    },
    changeLabel(e) {
      console.log(e);
    },
    // adding Additional Document on clicking input of files
    oninputAdditionalDocuments() {
      this.input.FilesOthers.push({
        documentName: "",
        Value: 0,
        fileName: "",
        documentGUID: "00000000-0000-0000-0000-000000000000",
        documentTypeID: 6,
        physicalFileStorageKey: "",
        applicationDocumentID: 0,
      });
      this.focusInput();
    },
    AddProgressReport() {
      if (this.ProgressReportDocument.length < 6)
        this.ProgressReportDocument.push({
          documentName: "ProgressReport",
          Value: 0,
          fileName: "",
          documentGUID: "00000000-0000-0000-0000-000000000000",
          documentTypeID: this.SPADocumentTypeID,
          physicalFileStorageKey: "",
          applicationDocumentID: 0,
        });
    },
  },

  computed: {
    SPADocumentTypeID :{ 
      get() {
        return this.$store.state.borrower.fundingData.inputs.SPADocumentTypeID
        }
        },
    isShowProgressReport: {
      get() {
        if (
          this.shouldFieldReadOnly == true &&
          this.ProgressReportDocument.length < 1
        ) {
          return false;
        } else {
          return true;
        }
      },
    },
    requestedFundAmount() {
      let requestedAmount = this.$store.state.borrower.fundingData.inputs
        .requestedFundAmount;
      if (typeof requestedAmount == String) {
        String(requestedAmount)
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      Number(requestedAmount);
      return requestedAmount;
    },
    ProgressReportDocument: {
      get() {
        return this.$store.state.borrower.documentData.ProgressReportDocument;
      },
    },
    programDocuments: {
      get() {
        return this.$store.state.borrower.programDocumentData;
      },
    },
    input: {
      get() {
        Object.assign(this.label, textStore.documents);
        return this.$store.state.borrower.documentData.inputs;
      },
    },
    shouldReadOnly: {
      get() {
        return this.shouldFieldReadOnly;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.documents {
  padding: 4.4rem 0rem;
  height: 100%;
  overflow-y: auto;
  h6,
  p {
    font-size: 1.6rem;
    color: $primary-color-black;
    text-align: left;
  }
  h6 {
    // font-weight: 500;
    font-weight: bold;
  }
  p {
    font-weight: 300;
  }
  .row {
    margin: 0rem;
  }
  &__file-section {
    margin-top: 2rem;
    .form-file {
      padding: 1rem 0rem;
      // border: 1px solid green;
      border-top: 1px solid $secondary-color-white;
      border-bottom: 1px solid $secondary-color-white;
      // width: 30%;
      display: flex;
      align-items: center;
    }
    .error-msg--div{
      position: relative;
      .error-msg {
      margin: 0.1rem 0rem;
      position: absolute;
      display: flex;
      // top: 6rem;
      // top: 5%;
      // bottom: -12%;
      // left: 0rem;
      font-size: 1.2rem;
      &__icon {
        width: 1.2rem;
        height: 1.2rem;
        margin: 0rem;
        margin-right: 0.5rem;
        border: solid $primary-color-red 0.1rem;
        border-radius: 50%;
        background-color: $primary-color-red;
        position: relative;
        &::before {
          content: "i";
          color: $primary-color-white;
          font-size: 1rem;
          font-weight: bold;
          position: absolute;
          top: 65%;
          left: 45%;
          transform: translate(-50%, -50%);
        }
      }
      p {
        color: $primary-color-red;
        margin: 0rem;
      }
      @media screen and (max-width: 1600px) {
        // top: 3.2rem;
        p {
          font-size: 1rem;
          top: 3.2rem;
        }
      }
    }
    }
  }
  .progress-report__files,
  .progress-report__label-button,
  .form-file {
    &--input,
    input {
      outline: none;
      border: none;
      padding: 0rem;
      margin: 0rem;
      max-width: 100%;
      width: 100%;
      font-size: 1.6rem;
    }
    label {
      margin: 0;
      font-size: 1.6rem;
      color: $primary-color-black;
    }
  }
  .document-name {
    padding: 0;
    position: relative;
    border-bottom: 1px solid #bbb7b7;
  }
  .secondary-btn {
    margin: 2.5rem 0rem;
    height: 4.5rem; //5rem;
    width: 100%;
    background: transparent; //$primary-color-red;
    border: 1px solid $primary-color-red;
    border-radius: 3px;
    color: $primary-color-red;
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: 0.3s ease-out;
    &:hover {
      border: 1px solid $primary-color-red-light;
      color: $primary-color-red-light;
      box-shadow: 0px 0.6rem 1.2rem $primary-color-red-darkest;
    }
    &:active {
      color: $primary-color-red-dark;
      background-color: #ffe6ea; // $primary-color-red-darkest;
    }
  }
  .downloadLink {
    font-size: 1.6rem;
    word-wrap: break-word;
    max-width: 25rem;
    width: auto;
  }
  .doument-file {
    .required {
      &:after {
        content: "*";
        color: $primary-color-red;
      }
    }
  }
  .progress-report {
    .AddProgressReport-btn {
      border: 0.1rem solid $primary-color-red;
      border-radius: 3px;
      color: $primary-color-red;
      padding: 0.5rem;
      margin: 0.2rem;
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;
      transition: 0.3s ease-out;
      background: transparent;
      outline: none;
      &:hover {
        border: 1px solid $primary-color-red-light;
        color: $primary-color-red-light;
        box-shadow: 0px 0.6rem 1.2rem $primary-color-red-darkest;
      }
      &:active {
        color: $primary-color-red-dark;
        background-color: #ffe6ea; // $primary-color-red-darkest;
      }
    }
    border-top: 1px solid $secondary-color-white;
    border-bottom: 1px solid $secondary-color-white;
  }
}
</style>
