import axios from "axios";

const state = {
  fundingEntity: {
    fundingEntityID: 0,
    fundingEntityName: "",
    address: "",
    zipCode: "",
    city: "",
    stateID: null,
    ein: "",
    tin: "",
    logSource: "",
    logoFileName: "",
    logoID: "",
    logoName: "",
    logoPhysicalFileStorageKey: "",
    uploadFundingEntityLogo: {
      documentGUID: "",
      documentName: "",
      documentTypeID: 0,
      fileName: "",
      fileSource: "",
      physicalFileStorageKey: "",
      fileSize: 0,
      isActive: true,
      loanApplicationID: 0,
      applicationDocumentID: 0,
    },
  },
  fundingSource: {
    fundingSourceID: 0,
    programName: "",
    fundingEntityName: "",
    fundingEntityID: 0,
    fundingTypeID: null,
    states: '',
    businessTypes: '',
    minimumLoanAmount: null,
    maximumLoanAmount: null,
    initialFundedAmount: 0,
    totalFundedAmount: 0,
    utilizedAmount: 0,
    availableLimit: 0,
    logSource: "",
    logoFileName: "",
    logoID: "",
    logoName: "",
    logoPhysicalFileStorageKey: "",
    uploadProgramLogo: {
      documentGUID: "",
      documentName: "",
      documentTypeID: 0,
      fileName: "",
      fileSource: "",
      physicalFileStorageKey: "",
      fileSize: 0,
      isActive: true,
    },
  },
  addFund: {
    fundingSourceID: 0,
    transactionTypeID: 0,
    transactionAmount: 0,
    comment: "",
    originatingBankAccount: "",
    transactionDocument: {
      documentTypeID: 8,
      fileName: "",
      documentGUID: "",
      physicalFileStorageKey: "",
      documentName: "",
      fileSize: 0,
    },
  },
  removeFund: {
    fundingSourceID: 0,
    transactionTypeID: 0,
    transactionAmount: 0,
    comment: "",
    originatingBankAccount: "",
    transactionDocument: {
      documentTypeID: 8,
      fileName: "",
      documentGUID: "",
      physicalFileStorageKey: "",
      documentName: "",
      fileSize: 0,
    },
  },
  fundTransactions: [],
  fundUtilizations: [],
  uploadedDocuments: [],
  program: {
    InvitationEmail:'',
    programQuestions: [],
    documents: [],
    agreement: {
      agreementBody: "",
      agreementID: 0,
      agreementName: "",
    },
    helpfullGuide: [],
  },
  showLoader: false,
};
const getters = {};
const actions = {
  updateLoaderFlag: (vueContext, payload) => {
    vueContext.commit("mutateLoaderFlag", payload);
  },
  fetchFundingEntities: () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let param = {
      length: 100,
      start: 0,
      draw: 100,
      sortBy: "string",
      sortDesc: true,
      search: {
        searchByAttribute: "string",
        value: "string",
        inActive: true,
      },
      order: [
        {
          column: 0,
          name: "string",
          dir: "string",
        },
      ],
      filterParameters: [
        {
          key: "string",
          value: "string",
        },
      ],
      isColumnFilter: true,
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/FundingSource/FetchFundingEntities", param, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getmasterData: () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("Master/GetAllMasterEntity", config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchFundingEntitiy: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        fundingEntityID: parseInt(payload),
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("FundingSource/GetFundingEntityDetails", config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit("mutateFundingEntity", res.data.fundingEntity);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //GetHelpfulGuideTemplate
  GetHelpfulGuideTemplate: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        programID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetHelpfulGuideTemplate", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            vueContext.commit(
              "mutateHelpfulGuideData",
              res.data.helpfulGuideTextViewResponse
            );
            resolve(res.data);
          } else {
            reject(res);
            vueContext.commit("mutateHelpfulGuideData", []);
          }
        })
        .catch((err) => {
          reject(err);
          vueContext.commit("mutateHelpfulGuideData", []);
        });
    });
  },

  //SaveOrUpdateHelpfulGuideTemplate
  SaveOrUpdateHelpfulGuideTemplateData:(vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
   
    if (payload) {
      let data={
  "programID": state.fundingSource.fundingSourceID,
    "businessProfileHelpfulGuideText": payload.businessProfileHelpfulGuideText,
    "fundingApplicationHelpfulGuideText": payload.fundingApplicationHelpfulGuideText,
    "documentsHelpfulGuideText": payload.documentsHelpfulGuideText,
    "reviewHelpfulGuideText": payload.reviewHelpfulGuideText
      }
      return new Promise((resolve, reject) => {
        axios.post("/FundingSource/SaveOrUpdateHelpfulGuideTemplate", data, config).then((res) => {
            if (res.status === 200 && res.data.isSuccess===true) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //GetProgramWiseAgreement
  GetProgramWiseAgreement: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        programID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/Admin/GetProgramWiseAgreement", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            let data = {
              agreementBody: res.data.agreementBody,
              agreementID: res.data.agreementID,
              agreementName: res.data.agreementName,
            };
            vueContext.commit("mutateAgreementData", data);
            resolve(res.data);
          }
          else
          {
            let data = {
              agreementBody: null,
              agreementID: 0,
              agreementName: null,
            };
            vueContext.commit("mutateAgreementData", data);
            resolve(res.data);
          }
          // else{
          //   reject(res);
          // }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //SaveOrUpdateAgreementData
  SaveOrUpdateAgreementData: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
   
    if (payload) {
      if (
        parseInt(state.program.agreement.agreementID) === null ||
        parseInt(state.program.agreement.agreementID) === undefined ||
        parseInt(state.program.agreement.agreementID) === ""
      ) {
        state.program.agreement.agreementID = 0;
      }
      let data = {
        agreementID: parseInt(state.program.agreement.agreementID),
        agreementName: state.fundingSource.fundingEntityName,
        programID: parseInt(state.fundingSource.fundingSourceID),
        agreementBody: payload,
      };
      return new Promise((resolve, reject) => {
        axios
          .post("/Admin/SaveOrUpdateAgreementName", data, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              //vueContext.commit();
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //GetProgramDocuments
  GetProgramDocuments: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        programID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetProgramDocuments", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            // vueContext.commit("mutateFundingSource", res.data.fundingSource);
            resolve(res.data.programDocumentsResponse);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  //SaveOrUpdateProgramDocument
  SaveOrUpdateProgramDocument: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/FundingSource/SaveOrUpdateProgramDocument", payload, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              //vueContext.commit();
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  //Get ProgramInvitationEmail
  GetEmailInvitation: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        programID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetProgramInvitationEmail", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            let data = {
              programInvitationEmailBody: res.data.programInvitationEmail
            };
            vueContext.commit("mutateProgramInvitationEmail", data);
            resolve(data);
          }
          // else{
          //   reject(res);
          // }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //SaveOrUpdate ProgramInvitationEmail
  SaveOrUpdateProgramInvitationEmail: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
   
    if (payload) {
      let data = {
        programID: parseInt(state.fundingSource.fundingSourceID),
        messageBody: payload,
      };
      return new Promise((resolve, reject) => {
        axios
          .post("/FundingSource/SaveOrUpdateProgramInvitationEmail", data, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              //vueContext.commit();
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },


  //GetProgramQuestions
  GetProgramQuestions: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        programID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetProgramQuestions", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            // vueContext.commit("mutateFundingSource", res.data.programQuestionResponse);
            resolve(res.data.programQuestionResponse);
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //SaveOrUpdateProgramQuestions
  SaveOrUpdateProgramQuestions: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/FundingSource/SaveOrUpdateProgramQuestions", payload, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              //vueContext.commit();
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  fetchFundingSource: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        fundingSourceID: parseInt(payload),
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("FundingSource/GetFundingSourceDetails", config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit("mutateFundingSource", res.data.fundingSource);
            resolve(res);
            ////////
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  SaveEntity: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      let data = {
        fundingEntityID: payload.fundingEntityID,
        fundingEntityName: payload.fundingEntityName,
        address: payload.address,
        ein: payload.ein,
        tin: payload.tin,
        city: payload.city,
        zipCode: payload.zipCode,
        stateID: payload.stateID,
      };
      if (payload.uploadFundingEntityLogo.documentGUID==='') {
        payload.uploadFundingEntityLogo.documentGUID="D04B69C1-5166-4B0E-ACAE-666A9FC9A9A7";
        
      }
      data.uploadFundingEntityLogo = payload.uploadFundingEntityLogo;
      return new Promise((resolve, reject) => {
        axios
          .post("FundingSource/AddOrUpdateFundingEntity", data, config)
          .then((res) => {
            if (res.status === 200) {
              //
              resolve(res);
            }
            else{
              reject(res)
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  SaveFundingSource: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("FundingSource/AddFundingSource", payload, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess===true) {
              resolve(res);
              //
            }
            else{
              reject(res)
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  getFundTransactionList: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        FundingSourceID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetFundTransaction/", config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit(
              "mutateFundTransactions",
              res.data.fundTransactionList
            );
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getFundUtilizationList: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        FundingSourceID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetAllUtilizedAmountDetails/", config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit(
              "mutateFundUtilizations",
              res.data.fundUtilization
            );
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  AddFunds: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("FundingSource/AddFundTransaction", payload, config)
          .then((res) => {
            if (res.status === 200) {
              //vueContext.commit();
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  RemoveFunds: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("FundingSource/RemoveFund", payload, config)
          .then((res) => {
            if (res.status === 200) {
              //vueContext.commit();
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  UploadLogoFile: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    const data = new FormData();
    // add file or files
    data.append("file", payload.file);

    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Upload/UploadLogo", data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  UploadFundingFile: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    const data = new FormData();
    // add file or files
    data.append("file", payload.file);

    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Upload/UploadApplicationDocument", data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  getConsolidatedFundReportData:(vueContext,payload)=>{
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
     
    };
    let params = {
      length: 10,
      start: 0,
      draw: 10,
      sortBy: "string",
      sortDesc: true,
      search: {
        searchByAttribute: "string",
        value: "string",
        inActive: true,
      },
      order: [
        {
          column: 0,
          name: "string",
          dir: "string",
        },
      ],
      filterParameters: payload,
      //  [
      //   {
      //     key: "string",
      //     value: "string",
      //   },
      // ],
      isColumnFilter: true,
    };
      return new Promise((resolve, reject) => {
        axios.post("Admin/ConsolidatedFundReportData", params,config)
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
  },
};
const mutations = {
  mutateLoaderFlag: (state, payload) => {
    state.showLoader = payload;
  },
  mutateFundingEntity: (state, payload) => {
    // state.fundingEntity= payload;
    state.fundingEntity.fundingEntityID = payload.fundingEntityID;
    state.fundingEntity.fundingEntityName = payload.fundingEntityName;
    state.fundingEntity.address = payload.address;
    state.fundingEntity.zipCode = payload.zipCode;
    state.fundingEntity.city = payload.city;
    state.fundingEntity.stateID = payload.stateID;
    state.fundingEntity.ein = payload.ein;
    state.fundingEntity.tin = payload.tin;
    state.fundingEntity.fundingSources = payload.fundingSources;
    state.fundingEntity.logSource = payload.logSource;
    state.fundingEntity.logoFileName = payload.logoFileName;
    state.fundingEntity.logoID = payload.logoID;
    state.fundingEntity.logoName = payload.logoName;
    state.fundingEntity.logoPhysicalFileStorageKey =payload.logoPhysicalFileStorageKey;
    state.fundingEntity.uploadFundingEntityLogo.physicalFileStorageKey=payload.logoPhysicalFileStorageKey;
    state.fundingEntity.uploadFundingEntityLogo.fileName=payload.logoFileName
    state.fundingEntity.uploadFundingEntityLogo.documentName=payload.logoName
    state.fundingEntity.uploadFundingEntityLogo.fileSource=payload.logSource
  },
  mutateFundingSource: (state, payload) => {
    // state.fundingSource = payload;
    state.fundingSource.fundingSourceID=payload.fundingSourceID,
    state.fundingSource.programName=payload.programName
    state.fundingSource.fundingEntityName=payload.fundingEntityName
    state.fundingSource.fundingEntityID=payload.fundingEntityID
    state.fundingSource.fundingTypeID=payload.fundingTypeID
    state.fundingSource.states=payload.states
    state.fundingSource.businessTypes=payload.businessTypes
    state.fundingSource.minimumLoanAmount=payload.minimumLoanAmount
    state.fundingSource.maximumLoanAmount=payload.maximumLoanAmount
    state.fundingSource.initialFundedAmount=payload.initialFundedAmount
    state.fundingSource.totalFundedAmount=payload.totalFundedAmount
    state.fundingSource.utilizedAmount=payload.utilizedAmount
    state.fundingSource.availableLimit=payload.availableLimit
    state.fundingSource.logSource=payload.logSource
    state.fundingSource.logoFileName=payload.logoFileName
    state.fundingSource.logoID=payload.logoID
    state.fundingSource.logoName=payload.logoName
    state.fundingSource.logoPhysicalFileStorageKey=payload.logoPhysicalFileStorageKey
    state.fundingSource.uploadProgramLogo.physicalFileStorageKey=payload.logoPhysicalFileStorageKey
    state.fundingSource.uploadProgramLogo.fileName=payload.logoFileName
    state.fundingSource.uploadProgramLogo.documentName=payload.logoName
    state.fundingSource.uploadProgramLogo.documentTypeID=payload.logoID
    state.fundingSource.uploadProgramLogo.fileSource=payload.logSource
    if (payload.states.length > 0)
      state.fundingSource.states = payload.states[0];
    if (payload.businessTypes.length > 0)
      state.fundingSource.businessTypes = payload.businessTypes[0];
  },
  mutateFundTransactions: (state, payload) => {
    state.fundTransactions = payload;
  },
  mutateFundUtilizations: (state, payload) => {
    state.fundUtilizations = payload;
  },
  mutateProgramQuestions: (state, payload) => {
    state.program.documents = payload;
  },
  mutateAgreementData: (state, payload) => {
    state.program.agreement = payload;
  },
  mutateProgramInvitationEmail: (state, payload) => {
    state.program.InvitationEmail = payload;
  },
  mutateHelpfulGuideData: (state, payload) => {
    state.program.helpfullGuide = payload;
  },
  mutateDocumentsData: (state, data) => {
    if (
      !state.uploadedDocuments.some(
        (_data) => _data.documentTypeID === data.documentTypeID
      )
    ) {
      state.uploadedDocuments.push(data);
    } else {
      const i = state.uploadedDocuments.findIndex(
        (_item) => _item.documentTypeID === data.documentTypeID
      );
      if (i > -1) state.uploadedDocuments[i].documentID = data.documentID;
    }
  },
};
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
