<template>
  <div class="review">
    <h4 class="my-3 d-flex justify-content-end">
      <a
        v-if="
          inputs.loggedInRole != 2 &&
            inputs.applicantData.applicationStatusID > 3
        "
        href="#"
        class="downloadLink px-2"
        @click.prevent="DownloadSummary()"
      >
        Download application summary</a
      >
    </h4>
    <h2 class="my-3">Business Profile</h2>
    <hr />
    <div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.status"
            :Value="inputs.applicantData.applicationStatus"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessName"
            :Value="inputs.businessData.businessName"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelDoingBusiness"
            :Value="inputs.businessData.dba"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessAddress"
            :Value="inputs.businessData.address"
          />
        </div>
        
      </div>
      
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelDUNS"
            :Value="inputs.businessData.duns"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelAffiliate"
            :Value="inputs.businessData.Affiliate"
          />
        </div>
        
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessEmail"
            :Value="inputs.businessData.emailAddress"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelSICCode"
            :Value="getSICName(inputs.businessData.siC_ID)"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessPhoneNo"
            :Value="inputs.businessData.phoneNumber"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelNAICSCode"
            :Value="inputs.businessData.naicsCode"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessUrl"
            :Value="inputs.businessData.url"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessType"
            :Value="getBusinessTypeName(inputs.businessData.businessTypeID)"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelZipCode"
            :Value="inputs.businessData.zip"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelIndustry"
            :Value="getIndustryTypeName(inputs.businessData.industryTypeID)"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelCity"
            :Value="inputs.businessData.city"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelBusinessStartDate"
            :Value="inputs.businessData.startDate"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelState"
            :Value="getStateName(inputs.businessData.stateID)"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelNoOfYearsBusiness"
            :Value="inputs.businessData.numberOfYearsInBusiness"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelEIN"
            :Value="inputs.businessData.ein"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelNoOfEmployess"
            :Value="inputs.businessData.employeeStrength"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelAccountNo"
            :Value="inputs.businessData.bankAccountNumber"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelRoutingNo"
            :Value="inputs.businessData.bankRoutingNumber"
          />
        </div>
      </div>
      <div class="row p-0 m-0 ">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.BusinessLabel.LabelNote"
            :Value="inputs.businessData.comment"
          />
        </div>
            <div class="col-12 col-sm-6 m-0 p-0">
            </div>
        </div>
    </div>
    <h2 class="mt-4">Owner Profile</h2>
    <div v-for="owner in inputs.ownersData.slice(0, 20)" :key="owner">
      <hr />
      <div >
        <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelOwnerName"
            :Value="owner.businessOwnerName"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelOwnership"
            :Value="owner.ownedPercentage"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelGender"
            :Value="getGenderName(owner.genderID)"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelRace"
            :Value="getRaceName(owner.raceID)"
          />
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelEthnicity"
            :Value="getEthnicityName(owner.ethnicityID)"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelVeteran"
            :Value="getVeteranName(owner.veteranID)"
          />
        </div>
      </div>
      <div class="row m-0 p-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelOwnerEmail"
            :Value="owner.emailAddress"
          />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
            :label="label.ownerLabel.LabelOwnerPhoneNo"
            :Value="owner.phoneNumber"
          />
        </div>
      </div>
      </div>
      
    </div>
    <h2 class="mt-4">Funding Application</h2>

    <hr />
    <div>
      <div class="row m-0 p-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
          :label="label.fundingApplication.LabelBusinessName"
          :Value="inputs.fundingData.BusinessName"
        />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
        :label="label.fundingApplication.LabelLoanProgram"
        :Value="inputs.fundingData.FundingProgram"
      />
        </div>
        
      </div>

      <div class="row m-0 p-0">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
        :label="label.fundingApplication.LabelFundingSource"
        :Value="inputs.fundingData.FundingSource"
      />
        </div>
       <div class="col-12 col-sm-6 m-0 p-0" >
          <LabelValue
        :label="label.fundingApplication.LabelLoanAmount"
        :Value="
          String(inputs.paymentScheduleSummary.fundAllocatedAmount)
            .replace(/\D/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        ">
        <template v-slot:prefix><strong class="">$ </strong></template>
      </LabelValue>
        </div>
      </div>
      <div class="row p-0 m-0 ">
        <div class="col-12 col-sm-6 m-0 p-0">
          <LabelValue
        :label="label.fundingApplication.LabelPurposeofFunds"
        :Value="inputs.fundingData.purpose"
      />
        </div>
        <div class="col-12 col-sm-6 m-0 p-0">
        </div>
        </div>
        <div v-if="JSON.stringify(inputs.prePopulatedData.programQuestions) != '{}'"  class="row m-0 p-0"  >
        <div v-for="question in inputs.prePopulatedData.programQuestions" :key="question" class="col-12 col-sm-6 m-0 p-0"  >
          <div v-if="question.response == 'true'">
            <LabelValue :label="question.questionText" Value="Yes" />
          </div>
          <div  v-else-if="question.response == 'false'">
            <LabelValue :label="question.questionText" Value="No" />
          </div>
          <div  v-else>
            <LabelValue :label="question.questionText" Value="" />
          </div>
        </div>
      </div>
      <div v-else>
        <span> <i> No Questions available</i></span>
      </div>
      
      
    </div>
    <h2 class="mt-4" v-if="inputs.programDocumentData.length > 0" >Documents</h2>
    <hr />
    <div  class="row m-0 p-0" >
      <div v-for="(programDocument, index) in inputs.programDocumentData" :key="index"  class="col-12 col-sm-6 m-0 p-0" >
        <div>
          <LabelValue
          :label="programDocument.documentName"
          :Value="programDocument.fileName"
        />
        </div>
        
      </div>
      <div class="col-12 col-sm-6 m-0 p-0"
        v-for="(doc, count) in inputs.documentData.FilesOthers"
        :key="count + 1"
      >
        <div v-if="doc.fileName !== ''">
          <LabelValue :label="doc.documentName" :Value="doc.fileName" />
        </div>
      </div>
      
      <div class="col-12 col-sm-6 m-0 p-0"
        v-for="(doc, count) in inputs.ProgressReportDocument"
        :key="count + 1"
      >
        <div v-if="doc.fileName !== ''">
          <LabelValue :label="`Progress Report`" :Value="doc.fileName" />
        </div>
      </div>
      <!-- ProgressReportDocument -->
      
    </div>

    <div v-if="inputs.accountDisbursedInformation.length > 0 && inputs.applicantData.applicationStatusID > 7" >
        <h2 class="mt-4">Account Disbursed Details</h2>
        <hr />
        <div v-for="acc in inputs.accountDisbursedInformation" :key="acc"> 
        <div class="row p-0 m-0">
            <div class="col-12 col-sm-6 m-0 p-0">
                <LabelValue
                  :label="acc.disbursementName"
                  :Value="acc.amount"
                />
              </div>
              <div class="col-12 col-sm-6 m-0 p-0">
                <LabelValue
                  label="Disbursed Date"
                  :Value="acc.disbursedDate"
                />
              </div>
          </div>     
    </div>    
      </div>
      <div v-if="inputs.applicantData.showAccountDisbursedInfo == true && inputs.applicantData.showAccountDisbursedInfoIfSPA==false && inputs.applicantData.applicationStatusID > 7">
        <h2 class="mt-4" >Account Disbursed Details</h2>
        <hr />
        <div class="row m-0 p-0">
          <div class="col-12 col-sm-6 m-0 p-0">
            <LabelValue
            label="Disbursed Amount"
            :Value="
              String(inputs.applicantData.fundedAmount)
                .replace('$', '')
                .replace(',', '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
            "
          >
            <template v-slot:prefix><strong class="">$ </strong></template>
          </LabelValue>
          </div>
          <div class="col-12 col-sm-6 m-0 p-0">
            <LabelValue
            label="Transaction Date"
            :Value="inputs.applicantData.fundedDate"
          />
          </div>
        </div>
        <div>
        </div>
        
      </div>
      <div v-if="inputs.pendingTransactionDetails.length > 0 " >
        <h2 class="mt-4"> Pending Payment Details</h2>
        <hr />
        <div v-for="acc in inputs.pendingTransactionDetails" :key="acc"> 
        <div class="row p-0 m-0">
            <div class="col-12 col-sm-6 m-0 p-0">
                <LabelValue
                  :label="acc.disbursementName"
                  :Value="acc.amount"
                />
              </div>
              <div class="col-12 col-sm-6 m-0 p-0">
                <LabelValue
                  label="Transaction Date"
                  :Value="acc.transactionDate"
                />
              </div>
          </div>     
    </div>    
      </div>
      <div v-if="inputs.applicantData.showUserAgreementdetailsInfo == true || inputs.applicantData.showUserSPAdetailsInfo === true">
        <h2 class="mt-4" >User Agreement</h2>
        <hr />
        <div v-if="inputs.applicantData.showUserAgreementdetailsInfo === true">
          <div class="row m-0 p-0" >
            <div class="col-12 col-sm-6 m-0 p-0">
              <LabelValue
              label="Agreement Name"
              :Value="inputs.applicantData.grantAgreementName"
            />
            </div>
            <div class="col-12 col-sm-6 m-0 p-0">
              <LabelValue
              label="Accepted by"
              :Value="inputs.applicantData.agreementAcceptedBy"
            />
            </div>
              </div>
          </div> 
          <div class="row m-0 p-0">
          <div class="col-12 col-sm-6 m-0 p-0">
                <div class="row  downloadlink">
              <div class="col-4 downloadlink--label">
                <p>
                  Agreement
                </p>
              </div>
              <div class="downloadlink--value">
                <p class=" pl-5">
                  <span class="downloadlink--value__span pl-md-3">
                    :
                    <a
                      href="#"
                      class="downloadLink--data"
                      @click.prevent="DownloadAgreement()"
                      >Download
                    </a>
                  </span>
                </p>
              </div>
            </div> 
          </div>  
          <div class="col-12 col-sm-6 m-0 p-0">
              <LabelValue
              label="Acceptance Date"
              :Value="inputs.applicantData.agreementAcceptanceDateTime"
            />
            </div>     
        </div>

        <div v-if="inputs.applicantData.showUserSPAdetailsInfo === true">
        <div class="row m-0 p-0" >
                <div class="col-12 col-sm-6 m-0 p-0">
                    <LabelValue
                    label="Agreement Name"
                    :Value="inputs.applicantData.spaName"
                  />
                  </div>
                  <div class="col-12 col-sm-6 m-0 p-0">
                    <LabelValue
                    label="Accepted by"
                    :Value="inputs.applicantData.spaAcceptedBy"
                  />
                  </div>
              </div>
              <div class="row m-0 p-0">
                <div class="col-12 col-sm-6 m-0 p-0">
                      <div class="row  downloadlink">
                    <div class="col-4 downloadlink--label">
                      <p>
                        Agreement
                      </p>
                    </div>
                    <div class="downloadlink--value">
                      <p class=" pl-5">
                        <span class="downloadlink--value__span pl-md-3">
                          :
                          <a
                            href="#"
                            class="downloadLink--data"
                            @click.prevent="DownloadSAPAgreement()"
                            >Download
                          </a>
                        </span>
                      </p>
                    </div>
                  </div> 
                </div>  
                <div class="col-12 col-sm-6 m-0 p-0">
                    <LabelValue
                    label="Acceptance Date"
                    :Value="inputs.applicantData.spaAcceptanceDateTime"
                  />
                  </div>     
              </div>
        </div>
      </div>

        
       

  </div>
</template>
<script>
import LabelValue from "@/components/LabelValue";
import textStore from "@/textStore.js";
import axios from "axios";
export default {
  components: {
    LabelValue,
  },
  data() {
    return {
      label: textStore,
      naicsCodes: [
        "722511:Full-Service Restaurants",
        "722513:Limited-Service Restaurant",
        "722330:Mobile Food Services",
        "722320:Caterers",
      ],
      sicCodes: [
        "A:44205:Agriculture, Forestry, And Fishing",
        "B:44483:Mining",
        "C:15-17:Construction",
        "D:20-39:Manufacturing",
        "E:40-49:Transportation, Communications, Electric, Gas, And Sanitary Services",
        "F:50-51:Wholesale Trade",
        "G:52-59:Retail Trade",
        "H:60-67:Finance, Insurance, And Real Estate",
        "I:70-89:Services",
        "J:90-99:Public Administration",
      ],
      defaultAmount: " 10,000",
    };
  },
  setup() {
    return {
      textStore,
    };
  },
  created(){
  },
  computed: {
    inputs: {
      get() {
        let documentData = this.$store.state.borrower.documentData.inputs;
        let ownersData = this.$store.state.borrower.ownersData;
        let fundingData = this.$store.state.borrower.fundingData.inputs;
        let businessData = this.$store.state.borrower.businessData.inputs;
        let UploadedDocuments = this.$store.state.borrower.uploadedDocuments;
        let prePopulatedData = this.$store.state.borrower.prePopulatedData;
        let applicantData = this.$store.state.borrower.applicantData;
        let accountDisbursedInformation = this.$store.state.borrower.AccountDisbursedInformation
        let pendingTransactionDetails =this.$store.state.borrower.pendingTransactionDetails
        let ProgressReportDocument=this.$store.state.borrower.documentData.ProgressReportDocument
        let loggedInRole = localStorage.getItem("userRoleID");
        let programDocumentData = this.$store.state.borrower.programDocumentData;
        let paymentScheduleSummary = this.$store.state.borrower.fundingDetails.paymentScheduleSummary;
       console.log(paymentScheduleSummary);
        return {
          documentData,
          ownersData,
          fundingData,
          businessData,
          UploadedDocuments,
          prePopulatedData,
          applicantData,
          accountDisbursedInformation,
          pendingTransactionDetails,
          loggedInRole,
          programDocumentData,
          ProgressReportDocument,
          paymentScheduleSummary
        };
      },
    },
  },
  methods: {
    download(item) {
      this.$store.dispatch("updateLoaderFlag", true);
      var data = {
        physicalFileStorageKey: item.physicalFileStorageKey,
        fileName: item.fileName,
      };
      this.$store
        .dispatch("Download", data)
        .then((res) => {
          
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    DownloadAgreement() {
      this.$store.dispatch("updateLoaderFlag", true);
      let config = {
        params: {
          applicationID: this.$store.getters.getapplicationID,
        },
        responseType: "blob",
      };
      if (config) {
        return new Promise((resolve, reject) => {
          axios
            .get("PDFGenerator/GetAgreement", config)
            .then((res) => {
              if (res.status === 200) {
                let blob = new Blob([res.data], {
                  type: res.headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "Agreement";
                link.click();
                this.$store.dispatch("updateLoaderFlag", false);
                resolve(res);
              }
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              reject(err);
            });
        });
      }
    },
    DownloadSAPAgreement()
    {
      let applicationId = this.$store.getters.getapplicationID;
      let data = this.$store.state.borrower;
      let isSPAExist = data.fundingData.inputs.isSPAExist;
      let SPADocumentData={}
      if(isSPAExist==true){
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("GetSPADocument", applicationId, { root: true })
          .then((res) => {
            SPADocumentData = res.data;
            this.$store.dispatch("updateLoaderFlag", false);
          })
          .then(()=>{
            this.download(SPADocumentData)
          })
          .catch((err) => {
            Promise.reject(err);
            this.$store.dispatch("updateLoaderFlag", false);
          });
      }
      else{
        this.$store.dispatch("updateLoaderFlag", true);
      let config = {
        responseType: "blob",
      };
      
      if (config) {
        return new Promise((resolve, reject) => {
          axios
            .get("/PDFGenerator/GetPaymentScheduleTransactionAgreement?applicationId="+applicationId, config)
            .then((res) => {
              if (res.status === 200) {
                let blob = new Blob([res.data], {
                  type: res.headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download ="PaymentScheduleTransactionAgreement";
                link.click();
          
                this.$store.dispatch("updateLoaderFlag", false);
                resolve(res);
              }
            })
            .catch((err) => {
          
              this.$store.dispatch("updateLoaderFlag", false);
              reject(err);
            });
        });
      }
      }
      
    },
    DownloadSummary() {
      this.$store.dispatch("updateLoaderFlag", true);
      let config = {
        params: {
          applicationID: this.$store.getters.getapplicationID,
        },
        responseType: "blob",
      };
      if (config) {
        return new Promise((resolve, reject) => {
          axios
            .get("PDFGenerator/ApplicationSummary", config)
            .then((res) => {
              if (res.status === 200) {
                let blob = new Blob([res.data], {
                  type: res.headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download =
                  "ApplicationSummary" + this.$store.getters.getapplicationID;
                link.click();
                this.$store.dispatch("updateLoaderFlag", false);
                resolve(res);
              }
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              reject(err);
            });
        });
      }
    },

    getStateName(stateId) {
      var filtered = this.inputs.prePopulatedData.stateList.filter(
        (m) => m.stateID === stateId
      );
      if (filtered.length > 0) return filtered[0].stateName;
      else return "";
    },
    getNaicsName(id) {
      if (id > 0) {
        if (this.naicsCodes.length > 0) return this.naicsCodes[id - 1];
      }
      return "";
    },
    getSICName(id) {
      if (id > 0) {
        if (this.sicCodes.length > 0) return this.sicCodes[id - 1];
      }
      return "";
    },
    getBusinessTypeName(id) {
      var filtered = this.inputs.prePopulatedData.businessTypeList.filter(
        (m) => m.businessTypeID === id
      );
      if (filtered.length > 0) return filtered[0].type;
      else return "";
    },
    getIndustryTypeName(id) {
      var filtered = this.inputs.prePopulatedData.industryTypeList.filter(
        (m) => m.industryTypeID === id
      );
      if (filtered.length > 0) return filtered[0].type;
      else return "";
    },
    getEthnicityName(id) {
      var filtered = this.inputs.prePopulatedData.ethnicityList.filter(
        (m) => m.ethnicityID === id
      );
      if (filtered.length > 0) return filtered[0].ethnicityName;
      else return "";
    },
    getGenderName(id) {
      var filtered = this.inputs.prePopulatedData.genderList.filter(
        (m) => m.genderID === id
      );
      if (filtered.length > 0) return filtered[0].genderName;
      else return "";
    },
    getRaceName(id) {
      var filtered = this.inputs.prePopulatedData.raceList.filter(
        (m) => m.raceID === id
      );
      if (filtered.length > 0) return filtered[0].raceName;
      else return "";
    },
    getVeteranName(id) {
      var filtered = this.inputs.prePopulatedData.veteranList.filter(
        (m) => m.veteranID === id
      );
      if (filtered.length > 0) return filtered[0].veteranType;
      else return "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.review {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  h2 {
    font-size: 2.4rem;
    color: $primary-color-black;
  }
  .row {
    // padding-right: 8%;
    margin: 0;
    p {
      color: $primary-color-black;
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
    .col-4 {
      p {
        color: $tertiary-color-black;
      }
    }
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
  .downloadlink {
    padding-right: 10%;
    margin: 0;
    display: flex;
    align-items: center;
    &--label {
      padding: 0rem;
      padding-right: 1rem;
      p {
        color: $tertiary-color-black;
      }
    }
    &--value{
      &__span{
        // margin-left: 3.7rem;
      }
    }
  }
}
</style>
