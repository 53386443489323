<template>
  <div class="TrackingList">
    <HeaderNav />
    <div class="TrackingList--content">
      <div class="TrackingList--content__Header d-flex">
        <div class="TrackingList--content__Header--breadCrumb align-self-start">
          <BreadCrumb class="breadcrumb" :crumbs="crumbs" @selected="selected" />
        </div>
        <span class="btn--primary" v-if="userRoleID !=8">
          <BtnPrimary :buttonName="`New fund entity`" @click="createNewEntity" />
        </span>
      </div>
      <LoadingMask v-if="isLoading===true" />
      <div class="TrackingList--content__table-section">
        <FundingEntityTable
          class="listingTable"
          :items="items"
          :TableHeight="TableHeight"
          :perPage="itemsPerTable"
          :columns="columns"
          @selectRow="fetchFundingEntitiy"
        />
      </div>
    </div>
    <LoadingMask v-if="isLoading===true" />
    <Footer />
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import BtnPrimary from "@/components/BtnPrimary.vue";
import FundingEntityTable from "@/components/FundingEntityTable";
export default {
  created() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.$store
      .dispatch("fetchFundingEntities", { root: true })
      .then((fundingEntitiesList) => {
        this.items = fundingEntitiesList.data.data;
        this.$store.dispatch("updateLoaderFlag", false);
      })
      .catch((err) => {
        this.$store.dispatch("updateLoaderFlag", false);
        Promise.reject(err);
      });
  },
  data() {
    return {
      crumbs: ["Funding Entities"],
      welcomeName: "Welcome, ",
      LastModifiedDate: "Last Visited on" + " April 10, 2021",
      itemsPerTable: 10,
      TableHeight: 52,
      items: [],
      columns: ["Funding Entity", "City", "State", "EIN", "TIN"],
    };
  },
  components: {
    HeaderNav,
    Footer,
    FundingEntityTable,
    BtnPrimary,
    BreadCrumb,
  },
  computed: {
    isLoading:{
      get(){
        return this.$store.state.showLoader;
      }
    },
    userName() {
      return this.$store.getters.getUser; 
    }, 
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  methods: {
    fetchFundingEntitiy(entityID) {
      if (entityID > 0) {
        this.$router.push("/funding/form/"+entityID);
      }
    },
    createNewEntity() {
      let defaultValue={
   "fundingEntityID":0,
   "fundingEntityName":"",
   "address":"",
   "zipCode":"",
   "city":"",
   "stateID":'',
   "ein":"",
   "tin":"",
   "logSource":"",
   "logoFileName":"",
   "logoID":0,
   "logoName":"",
   "logoPhysicalFileStorageKey":"",
   "uploadFundingEntityLogo":{
      "documentGUID":"",
      "documentName":"",
      "documentTypeID":0,
      "fileName":"",
      "physicalFileStorageKey":"",
      "fileSize":0,
      "fileSource":'',
      "isActive":true,
      "loanApplicationID":0,
      "applicationDocumentID":0
   },
   "fundingSources":[]
}
      this.$store.state.fundSource.fundingEntity=defaultValue;
      this.$router.push("/funding/form/"+0);
    },
  },
};
</script>
<style lang="scss" scoped>
.TrackingList {
  &--content {
    padding: 0rem 2%;
    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn--primary {
        width: 19rem;
        button {
          font-size: 1.6rem;
        }
      }
    }
    &__name {
      padding: 0rem; 
      padding-bottom:1rem;
      
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
    }
  }
}
</style>
