<template>
  <div class="FundingApplication">
    <div class="row">
      <div class=" col form-group">
        <input
          disabled
          type="text"
          name="fundingApplication-BusinessName"
          id="fundingApplication-BusinessName"
          v-model="inputs.BusinessName"
          placeholder=" ex- LOCUS INTERNATIONAL CENTRE FOR ENTREPRENEURSHIP DEVELOPMENT AND INCUBATION SERVICES LIMITED"
        />
        <label
          for="fundingApplication-BusinessName"
          class="form__label required"
        >
          {{ label.fundingApplication.LabelBusinessName }}
        </label>
        <Error v-if="v$.inputs.BusinessName.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.BusinessName'">
              {{ error.$message }}
            </small>
          </p>
        </Error>      
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="  col-12 col-sm-4 form-group">
        <input
          disabled
          type="text"
          placeholder=" "
          name="fundingApplication-FundingProgram"
          id="fundingApplication-FundingProgram"
          v-model="inputs.FundingProgram"
        />
        <label
          for="fundingApplication-FundingProgram"
          class="form__label required"
        >
          {{ label.fundingApplication.LabelLoanProgram }}
        </label>
        <Error v-if="v$.inputs.FundingProgram.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.FundingProgram'">
              {{ error.$message }}
            </small>
          </p>
        </Error>       
      </div>
      <div class="  col-12 col-sm-4 form-group">
        <input
          disabled
          type="text"
          placeholder=" "
          name="fundingApplication-FundingSource"
          id="fundingApplication-FundingSource"
          v-model="inputs.FundingSource"
        />
        <label
          for="fundingApplication-FundingSource"
          class="form__label required"
        >
          {{ label.fundingApplication.LabelFundingSource }}
        </label>
        <Error v-if="v$.inputs.FundingSource.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.FundingSource'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
 <div class="col-12 col-sm-4 form-group">
        <input
          :disabled="
            true
              ? inputs.hasDefaultFundAmount == true || shouldReadOnly == true
              : false
          "
          :readonly="
            true
              ? inputs.hasDefaultFundAmount == true || shouldReadOnly == true
              : false
          "
          type="text"
          placeholder="$"
          class="input-fundingApplication-LoanAmount"
          id="fundingApplication-LoanAmount"
          name="fundingApplication-LoanAmount"
          
          v-money3="config"
          v-model="inputs.requestedFundAmount"
        />
        <label for="fundingApplication-LoanAmount" class="form__label required">
          {{ label.fundingApplication.LabelFundRequestedAmount }}
        </label>
        <div
         
        >
        <!-- <label for="fundingApplication-LoanAmount" class="form__label required">
          {{ label.fundingApplication.LabelLoanAmount }}
        </label> -->
          <Error
            v-if="
              inputs.hasDefaultFundAmount == false &&
                requestedFundAmountErrorShow.isErrors == true
            "
          >
            <p>
              <small>
                {{ requestedFundAmountErrorShow.errorMessage }}
              </small>
            </p>
          </Error>
        </div>

        
      </div>
      
    </div>
    <div class="row">
      <div class="col form-group">
        <input
          required
          type="text"
          placeholder=" "
          :disabled="shouldReadOnly"
          name="fundingApplication-PurposeofFunds"
          id="fundingApplication-PurposeofFunds"
          v-model="inputs.purpose"
        />
        <label
          for="fundingApplication-PurposeofFunds"
          class="form__label required"
        >
          {{ label.fundingApplication.LabelPurposeofFunds }}
        </label>
        <Error v-if="v$.inputs.purpose.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.purpose'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
      </div>
    </div>
    <div
      class=""
      v-for="programQuestion in getProgramQuestions"
      :key="programQuestion.questionID"
    >
      <div :id="programQuestion.questionID">
        <RadioButtonQuestioner
          :programQuestion="programQuestion"
          :shouldReadOnly="shouldReadOnly"
          :ErrorShow="ErrorShow"
          :id="programQuestion.questionID"
        />
      </div>     
    </div>
  </div>
</template>
<script>
import Error from "@/components/Error.vue";
import useVuelidate from "@vuelidate/core";
import fundingApplication from "@/textStore.js";
import { required } from "@vuelidate/validators";
import { Money3Directive } from "v-money3";
import RadioButtonQuestioner from "@/components/RadioButtonQuestioner";
export default {
  directives: {
    money3: Money3Directive,
  },
  components: {
    Error,
    RadioButtonQuestioner,
  },
  setup() {
    return {
      v$: useVuelidate(),
      fundingApplication,
    };
  },
  props: {
    prePopulatedData: {
      required: true,
      type: Object,
    },
    shouldFieldReadOnly: {
      type: Boolean,
    },
  },
  watch: {
    "items.requestedFundAmount": function(newValue) {
      const result = newValue
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (this.inputs.requestedFundAmount = result));
    },
  },
  validations() {
    return {
      inputs: {
        BusinessName: { required },
        FundingProgram: { required },
        FundingSource: { required },
        // UseOfFunds: { required },
        // LoanPeriod: { required },
        purpose: { required },
      },
    };
  },
  data() {
    return {
      ErrorShow: {
        isErrors: false,
        errorData: [],
      },
      requestedFundAmountErrorShow: {
        isErrors: false,
        errorMessage: "",
      },
      label: {},
      businessName: this.prePopulatedData?.businessName,
      loanProgramName: this.prePopulatedData?.programName,
      fundingSource: this.prePopulatedData?.fundingSource,
      programQuestions: this.prePopulatedData?.programQuestions,
    };
  },
  computed: {
    getProgramQuestions: {
      get() {
        return this.$store.state.borrower.prePopulatedData.programQuestions;
      },
    },
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        disableNegative: true,
        masked: false,
      };
    },
    inputs: {
      get() {
        Object.assign(this.label, fundingApplication);
        return this.$store.state.borrower.fundingData.inputs;
      },
    },
    shouldReadOnly: {
      get() {
        return this.shouldFieldReadOnly;
      },
    },
    applicantData: {
      get() {
        return this.$store.state.borrower.applicantData;
      },
    },
  },
  methods: {
    async validationsStore() {
      
        let applicationStatusID = this.applicantData.applicationStatusID;
        let getUserRoleID=this.$store.getters.getUserRoleID
        if (typeof applicationStatusID == "undefined") {
          applicationStatusID = 1;
        }
        if (
          (
            (applicationStatusID === 1 || applicationStatusID === 2 ||applicationStatusID === 3||applicationStatusID ===5) && ((getUserRoleID==2 || getUserRoleID==7))
            )||
          ((applicationStatusID ==26 || applicationStatusID ===25 ||applicationStatusID ===21 || applicationStatusID ===16) && (getUserRoleID==2 ||getUserRoleID==4 ||getUserRoleID==6))
        ) {
          if (this.requestedFundAmountValidation() == true){
            let isQuestionerValid = this.validationQuestionerData();
          const isFormCorrect = await this.v$.$validate();
          if (!isFormCorrect) {
            return false;
          } else {
            if (isQuestionerValid == false) {
              return false;
            } else {
              this.inputs.programQuestions = this.getProgramQuestions;
              return await this.$store
                .dispatch("UpdatefundingData", this.fundingData, { root: true })
                .then(() => {
                  return true;
                })
                .catch((err) => {
                  Promise.reject(err);
                  return false;
                });
            }
          }
          }
          
        } else {
          return true;
        }
    },
    requestedFundAmountValidation() {
      /*let FundAmount = Number(
        String(this.inputs.requestedFundAmount).replace(/[^\d]/g, "")
      );
      if (this.inputs.hasDefaultFundAmount === false) {
        if (
          this.inputs.minimumFundAmount <= FundAmount &&
          this.inputs.maximumFundAmount >= FundAmount
        ) {
          this.requestedFundAmountErrorShow.isErrors = false;
          return true;
        } else {
          this.requestedFundAmountErrorShow.isErrors = true;
          if (this.inputs.minimumFundAmount==this.inputs.maximumFundAmount) {
            this.requestedFundAmountErrorShow.errorMessage = `Amount should be $ ${String(this.inputs.minimumFundAmount).replace('$', '').replace(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
            return false;
          } else {
            this.requestedFundAmountErrorShow.errorMessage = `Amount should be between $ ${String(this.inputs.minimumFundAmount).replace('$', '').replace(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')} and $ ${String(this.inputs.maximumFundAmount).replace('$', '').replace(',', '').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
            return false;
          }
        }
      } else {
        this.requestedFundAmountErrorShow.isErrors = false;
        return true;
      }*/
      return true;
    },
    // dynamic Questions and answers validation
    validationQuestionerData() {
      let ProgramQuestions = this.getProgramQuestions;
      for (let ProgramQuestion of ProgramQuestions) {
        if (
          ProgramQuestion.isRequired == true &&
          (ProgramQuestion.response === "" ||
            ProgramQuestion.response === null ||
            ProgramQuestion.response === undefined)
        ) {
              let index = this.ErrorShow.errorData.findIndex(function(o) {
                return o.dataId=== ProgramQuestion.questionID;
              });
              if (index > -1) {
                this.ErrorShow.errorData[index] = {isError: true,
            dataId: ProgramQuestion.questionID,}
                this.ErrorShow.isErrors = true;
                
              } else {
                this.ErrorShow.errorData.push(
                  {isError: true,
            dataId: ProgramQuestion.questionID,}
                  );
                this.ErrorShow.isErrors = true;
              }
        }
         else{
           console.log(this.ErrorShow.errorData)
              let index = this.ErrorShow.errorData.findIndex(function(o) {
                return o.dataId === ProgramQuestion.questionID;
              });
              if (index !== -1){
                this.ErrorShow.errorData.splice(index, 1);
                if(this.ErrorShow.errorData.length==0){
                this.ErrorShow.isErrors = false;
                }
              } 
            }  
        
        // else {
        //   this.ErrorShow.isErrors = false;
        // }
      }
      if (this.ErrorShow.isErrors == true) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.FundingApplication {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  .row {
    // padding-right: 8%;
    margin: 0;
    .col-3-internal {
      width: 36rem;
      max-width: 30%;
      flex-basis: 50%;
      input {
        width: 100%;
      }
      .input-fundingApplication-LoanAmount ~ label {
        top: -0.1rem;
      }
    }
  }
  .form-group {
    margin-top: 1rem;
    padding: 0;
    position: relative;
    @include input-style;
    &__question-label {
      font-size: 1.6rem;
      color: $primary-color-black;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
  }
  .radio-groups {
    span {
      padding: 0rem;
    }
    display: flex;
    align-items: center;
    label {
      input {
        height: 1.8rem;
        width: 1.8rem;
      }
      //   bootstrap col-1 design change
      flex: 0 0 10.333333%;
      max-width: 10.333333%;

      position: unset;
      top: unset;
      left: unset;
      bottom: unset;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }
  }
}
</style>
