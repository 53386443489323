<template>
  <div class="table-view">
      <div class="table-view__header d-flex row m-0 p-0">
      <div class="table-view__header--left">
        <div class="table-heading">
          <h3>Business Contacts</h3>
        </div>
        <div class="sort">
    
        </div>
      </div>
      <div class="table-view__header--right  p-0 col d-flex justify-content-end">
        <div class="search">
          <div class="d-flex  flex-wrap ">
          <label for="search" class="ml-1 d-flex align-items-center search-by-filter" >Search&nbsp;By:
          <span class="search-by-filter__select" >
            <select
            class="search-by-program__filter__select"
            placeholder=" "
            name="search-group"
            id="search-group"
            @change="clearFilter()"
            v-model="selectOptionToFilter"
          >
            <option selected value="all">
              <p>All</p>
            </option>
            <option value="firstName">
              <p>Business Name</p>
            </option>            
            <option value="firstName">
              <p>First Name</p>
            </option>
            <option value="lastName">
              <p>Last Name</p>
            </option>
            <option value="emailAddress">
              <p>Email address</p>
            </option>
            <option value="accountStatus">
              <p>Account Status</p>
            </option>
            <option value="phoneNumber">
              <p>Phone Number</p>
            </option>
          </select>
          </span>
          </label>
          </div>

          <span class=" mx-1 d-flex search-by-input ">
            <select
            v-if="selectOptionToFilter==='accountStatus'"
            class="search-group search-by-input__select my-1"
            placeholder=" "
            name="search-group-filter"
            id="search-group-filter"
            v-model="filter"
          >
          <option value="">Show All</option>
            <option v-for="status in accountStatus()" :key='status' :value="status">
              <p>{{status}}</p>
            </option>
          </select>
          <input
            v-else
            class="search-by-input__input"
            type="text"
            name="searchByKey"
            id="searchByKey"
            v-model="filter"
            placeholder="Search here"
          />
          <button class="reset-button" @click="ResetSort()">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
          </span> 

          <span class="btn--secondary m-2"  v-if="userRoleID !=8">
            <BtnSecondary
              :buttonName="`Export to Excel`"
              @click="exportBusinessContacts"
            />
          </span>        
          <div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="table-view__table">

      <table class="table">
        <thead>
          <tr class="  m-0 mr-1">
          <th @click="sortCol('businessName')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='businessName'?'show-icon':''" >
                <p class="tabel-header__title">Business Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('firstName')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='firstName'?'show-icon':''" >
                <p class="tabel-header__title">First Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('lastName')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='lastName'?'show-icon':''">
                <p class="tabel-header__title">Last Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('emailAddress')" class="tabel-header col col-sm-3">
              <span class="tabel-header__data" :class="values.currentSort=='emailAddress'?'show-icon':''">
                <p class="tabel-header__title">Email address</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('phoneNumber')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='phoneNumber'?'show-icon':''">
                <p class="tabel-header__title">Phone Number</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('accountStatus')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='accountStatus'?'show-icon':''">
                <p class="tabel-header__title">Account Status</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th class="tabel-header col col-sm-1" >
              <p class="tabel-header__title">Action</p>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr v-for="item in displayedPosts" :key="item" class="row  m-0">
            <td @click="selectId(item.contactID)" class="col col-sm-2" >
              <p v-html="highlightMatches(item['businessName'])"></p>
            </td>
            <td @click="selectId(item.contactID)" class="col col-sm-2">
              <p v-html="highlightMatches(item['firstName'])"></p> 
            </td>
            <td @click="selectId(item.contactID)" class="col col-sm-2" >
              <p v-html="highlightMatches(item['lastName'])"></p>
            </td>
            <td @click="selectId(item.contactID)" class="col col-sm-3">
              <p v-html="highlightMatches(item['emailAddress'])"></p> 
            </td>
            <td @click="selectId(item.contactID)" class="col col-sm-2" >
              <p v-html="highlightMatches(item['phoneNumber'])"></p>
            </td>
            <td @click="selectId(item.contactID)" class="col col-sm-2">
              <p v-html="highlightMatches(item['accountStatus'])"></p>
            </td>
            <td class=" col col-sm-1">
              <div>
                <span class="d-flex justify-content-between align-items-center ">             
              <span class="d-flex justify-content-between align-items-center ">
                <button class="icons-button p-sm-1 p-0" v-if="item['accountStatus']==='Deactivated'" @click="activateContact(item.contactID)"> 
                  <img src="@/assets/imgs/active.svg" alt="active Icon" title="Activate Account">
                  </button>
                <button class="icons-button p-sm-1 p-0" v-if="item['accountStatus']==='Active'" @click="deActivateContact(item.contactID)">
                  <img src="@/assets/imgs/deactive.svg" alt="deactive Icon" title="Deactivate Account">
                   </button>
                   <button class="icons-button p-2 mx-sm-2 mx-0" v-if="item['accountStatus']==='Active'" @click="reSetContact(item.contactID,item.emailAddress)">
                  <img src="@/assets/imgs/spinner.svg" alt="Reset Icon" title="Reset Account">
                   </button>
                   <button class="icons-button p-sm-1 p-0"  v-if="item['accountStatus']==='Locked'" @click="unLockContact(item.contactID,item.emailAddress)"> 
                  <img src="@/assets/imgs/lock.svg" alt="Lock Icon" title="Unlock Account">
                  </button>
              </span>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  components: {
    BtnSecondary
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type:Number,
      default:10
      },
      TableHeight:{
        type:Number,
      },
    columns: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      filter: "",
      list: this.items,
      unsort:false,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages:[],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
    };
  },
  beforeUpdate() {
    this.list = this.items;
  },
  methods: {
    activateContact(contactID){
      if(this.userRoleID !=8){
         this.$store.dispatch("updateLoaderFlag",true)
      this.$store
      .dispatch("ActivateContact",contactID,{ root: true })
      .then((res) => {
         this.$store.dispatch("updateLoaderFlag",false)
          this.toasterMessage('',res.data.message,'success')
          this.$emit('fetchAllBusinessContacts')
      })
      .catch(err =>{
         this.$store.dispatch("updateLoaderFlag",false)
           this.toasterMessage('',err.data.message,'danger')
        Promise.reject(err);
      });
      }
      
    },
    deActivateContact(contactID){
      if(this.userRoleID !=8){
      this.$store.dispatch("updateLoaderFlag",true)
      this.$store
      .dispatch("DeActivateContact",contactID,{ root: true })
      .then((res) => {
         this.$store.dispatch("updateLoaderFlag",false)
          this.toasterMessage('',res.data.message,'success')
          this.$emit('fetchAllBusinessContacts')
      })
      .catch(err =>{
         this.$store.dispatch("updateLoaderFlag",false)
           this.toasterMessage('',err.data.message,'danger')
        Promise.reject(err);
      });
      }
    },
    reSetContact(contactID,emailAddress){
      if(this.userRoleID !=8){
         this.$store.dispatch("updateLoaderFlag",true)
      this.$store
      .dispatch("ResetContact",{contactID,emailAddress},{ root: true })
      .then((res) => {
         this.$store.dispatch("updateLoaderFlag",false)
          this.toasterMessage('',res.data.message,'success')
          this.$emit('fetchAllBusinessContacts')
      })
      .catch(err =>{
         this.$store.dispatch("updateLoaderFlag",false)
           this.toasterMessage('',err.data.message,'danger')
        Promise.reject(err);
      });
      }
      
    },
    
    unLockContact(contactID,emailAddress){
      if(this.userRoleID !=8){
         this.$store.dispatch("updateLoaderFlag",true)
      this.$store
      .dispatch("Unlockaccount",{contactID,emailAddress},{ root: true })
      .then((res) => {
         this.$store.dispatch("updateLoaderFlag",false)
          this.toasterMessage('',res.data.message,'success')
          this.$emit('fetchAllBusinessContacts')
      })
      .catch(err =>{
         this.$store.dispatch("updateLoaderFlag",false)
           this.toasterMessage('',err.data.message,'danger')
        Promise.reject(err);
      });
      }
    },
    clearFilter(){
      this.filter='';
    },
    accountStatus(){
        let accountStatus =[...new Set(this.list.map(item => item.accountStatus))]
        return accountStatus.sort()
      },
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;
    },
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter);
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    selectId(contactID){
      this.$emit('selectRow', contactID)
    },
    sortCol(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.values.currentPage;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.values.totalPages =Math.ceil(posts.length / this.perPage);
      if(this.values.totalPages===0){
        this.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.filteredRows)
        return "";
      return this.filteredRows.length;
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
    exportBusinessContacts()
    {
      this.$store.dispatch("updateLoaderFlag", true);
      let filterParameters=[];
     filterParameters = [
        {
          key: this.selectOptionToFilter,
          value: this.filter,
        },
      ];
      this.$store
        .dispatch("exportBusinessContactsReport",filterParameters, { root: true })
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
        });
      this.$store.state.borrower.ownersData = [{}];
      this.$router.push("/businessContacts");
     // this.$emit('exportLoanApplications')
    }
  },
  computed: {
      
    setTableHeight(){
      return 'height: ' + this.TableHeight +'vh';
    },
    sortTable() {
      let lists = this.list;
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        const firstName = row.firstName.toString().toLowerCase();
        const lastName = row.lastName.toString().toLowerCase();
        const emailAddress = row.emailAddress.toString().toLowerCase();
        const phoneNumber = row.phoneNumber.toString().toLowerCase();
        const accountStatus = row.accountStatus.toString().toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            firstName.includes(searchTerm) ||
            lastName.includes(searchTerm) ||
            emailAddress.includes(searchTerm) ||
            phoneNumber.includes(searchTerm) ||
            accountStatus.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "firstName") {
          filtedItems = firstName.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "lastName") {
          filtedItems = lastName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "emailAddress") {
          filtedItems = emailAddress.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "phoneNumber") {
          filtedItems = phoneNumber.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "accountStatus") {
          
          if(searchTerm==''){
            filtedItems=true
          }
          else{
          filtedItems = accountStatus===searchTerm ;
          }
        }
        return filtedItems;
      });
    },
    displayedPosts() {
			return this.paginate(this.filteredRows);
		},
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    }
  },
  watch: {
		posts () {
			this.setPages();
		}
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
  @include table-style;
  .table-view{
    .icons-button{
      height: 3rem;
      width: 3rem;
      background-color: transparent;
      border: none;
      img {
        height: 100%;
      }
      @media screen and(max-width:580px) {
          width: 2rem;
          img {
          width: 100%;
      }
        }
    }
  }

</style>