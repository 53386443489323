<template>
  <div class="document">
    <BasicComponent :heading="getPageHeader()">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>
      <template v-slot:mainSection>
        <div class="form-section">
          <div class="row form-section__multi-form">
            <div class="col-12 form-section__multi-form__forms p-0">
              <div class="horizontal">
                <div>
                  <div class="row">
                    <div class="col form-group">
                      <input
                        required
                        placeholder=" "
                        type="text"
                        id="document-documentName"
                        name="document-documentName"
                        v-model="inputs.documentName"
                        @focus="checkDocumentName()"
                        @blur="checkDocumentName()"
                        :disabled=isDisable
                        :readonly=isDisable
                      />
                      <Error v-if="documentsError.isError==true">
                            <p>
                            <small>
                                {{documentsError.message}}
                            </small>
                            </p>
                        </Error>

                      <label for="document-documentName" class="form__label required">
                      Document Name</label>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="forms-btns row justify-content-end d-flex">
                  <span v-if="userRoleID !=8">
                    <BtnPrimary :buttonName="`Save`" @click="saveDocument()" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingMask v-if="isLoading === true" />
      </template>
    </BasicComponent>
  </div>
</template>
<script>
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import BtnPrimary from "@/components/BtnPrimary.vue";
import Label from "@/textStore.js";
import Error from "@/components/Error.vue";
export default {
  components: {
    BasicComponent,
    BreadCrumb,
    BtnPrimary,
    Error,
  },
  data() {
    return {
      labels: {},
      heading:'',
      documentsError:{
          message:"Enter Document Name",
          isError:false
      },
      crumbs: ["Documents", "Document"],
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
    };
  },
props: {
    documentID: {
      type: Number,
      default: 0,
    }
  },
mounted() {
      if (this.documentID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store.dispatch("getDocument", this.documentID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);           
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage("", err, "danger");
            Promise.reject(err);
          });
      }
      else{
         this.$store.state.document.documentData= {
             documentName: "",
            active: "",
            documentID: 0,
         } 
      }
  },
  computed: {
    inputs: {
      get() {
        Object.assign(this.labels, Label.documentLabel);
        return this.$store.state.document.documentData;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
      userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    },
  },
  methods:{
      getPageHeader()
    {
        let documentName = this.$store.state.document.documentData.documentName;
       if(documentName === undefined || documentName === "")   
        this.heading = "New Document";
        else
        this.heading = documentName;
        return this.heading;
    },
      selected(crumb) {
      if (crumb == "Documents") {
        this.$router.push("/documentsLists");
      }
    },
     
    checkDocumentName(){
        if(this.inputs.documentName.trim()==" "){
            this.documentsError.isError=true
        }
        else{
            this.documentsError.isError=false
        }
    },
    saveDocument(){
        if(this.inputs.documentName.trim()==""){
            this.documentsError.isError=true
        }
        else{
           let param= this.inputs
      this.$store.dispatch("updateLoaderFlag",true)
        this.$store.dispatch("SaveOrUpdateDocuments", param, { root: true })
        .then((res)=>{
            this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage("", res.message, "success");
        })
        .catch((err)=>{
            this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage("", err.message, "danger");

        }) 
        }
        
        
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.document {
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
  .row,col-3,col-6{
      margin: auto 0rem;
      padding: 0rem;
  }
  .form-section__multi-form{
         
          @media screen and(max-width:580px) {
            min-height: 78vh;
          }
        }
}
</style>
