import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import config from "./config"
 
import axios from 'axios'
import VueAxios from 'vue-axios'
import LoadingMask from '@/components/LoadingMask'
import moshaToast from 'mosha-vue-toastify'
import { createToast } from "mosha-vue-toastify";
import 'mosha-vue-toastify/dist/style.css'


createApp(App)
.mixin({
    methods: {
      toasterMessage: function (title='',description='',type='') {
        if(description===null || description===undefined){
          return
        }
        setTimeout(()=>{
          createToast(
            {
              title: title,
              description: description,
            },
            {
              showIcon: "true",
              hideProgressBar: "true",
              timeout: 6000,
              type: type,// types to be used info,warning,success,danger
              // toastBackgroundColor: '',
              position: "top-right",
              transition: "slide",
            }
          );
          }, 500)
        
      },
    },
  })
.use(store)
.use(moshaToast)
.use(router)
.use(VueAxios, axios)
.component('LoadingMask', LoadingMask)
.mount('#app')
axios.defaults.baseURL=process.env.VUE_APP_ROOTAPI;
axios.defaults.headers=config.optionAxios