<template>
  <div class="businessEntitySection">
    <BasicComponent>
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
        <div class="row justify-content-between align-items-center">
          <div class="col-3">
            <div class="heading">
              <h3>
                {{ texts.heading }}
              </h3>
            </div>
          </div>
          <div
            class="col-sm-3 col-6  form-group"
            v-if="AllBusinessEntity.length > 1"
          >
            <select
              class="select-group"
              placeholder=" "
              name="BusinessEntity"
              id="BusinessEntity"
              @change="ChangeBusinessEntity(businessId)"
              v-model="businessId"
            >
              <option
                v-for="BusinessEntity in AllBusinessEntity"
                :key="BusinessEntity.businessId"
                :value="BusinessEntity.businessId"
              >
                {{ BusinessEntity.businessName }}
              </option>
            </select>
            <label for="BusinessEntity" class="form__label">
              {{ labels.BusinessEntity }}
            </label>
          </div>
        </div>
      </template>
      <template v-slot:mainSection>
        <div class="BusinessProfile-inputs">
          <BusinessProfile
          ref="BusinessProfile" />
        </div>
        <div class="row m-0  d-flex justify-content-center" >
          <span class="col-1">
            <BtnPrimary :buttonName="`Save`" @click="saveBusinessProfile()" />
          </span>
        </div>
        <LoadingMask v-if="isLoading === true" />
      </template>
    </BasicComponent>
  </div>
</template>

<script>
import BusinessProfile from "@/components/BusinessProfile.vue";
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import BtnPrimary from "@/components/BtnPrimary.vue";

export default {
  data() {
    return {
      labels: {
        BusinessEntity: "Business Entity",
      },
      businessId: "",
      programInvitationId: "",
      AllBusinessEntity: [],
      texts: {
        heading: "Profile",
      },
      crumbs: ["Profile"],
    };
  },
  components: {
    BtnPrimary,
    BusinessProfile,
    BasicComponent,
    BreadCrumb,
  },
  created() {
    let userRoleId = localStorage.getItem("userRoleID");
    if (userRoleId == 2) {
      this.businessId=this.$store.state.borrower.businessId
      this.GetAllBusinessEntityByUser();
    } else {
      this.$router.push("/");
    }
  },

  mounted() {
    
  },
  methods: {
    ChangeBusinessEntity(businessId){
      this.businessId=businessId;
      this.GetBusinessProfileData(this.businessId);
    },
    GetAllBusinessEntityByUser() {
      this.$store.dispatch("updateLoaderFlag", true);
      let userName = localStorage.getItem("userEmail");
      this.$store
        .dispatch("GetAllBusinessEntityByUser", userName)
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          if (res.isSuccess == true) {
            this.AllBusinessEntity = res.businessEntity;
            if((this.businessId<0) || (this.businessId =='')){
              this.businessId = this.AllBusinessEntity[0].businessId;
              this.programInvitationId = this.AllBusinessEntity[0].programInvitationId;
            }
            this.$store.state.borrower.businessProfileData.ownersData = [{}];

            this.GetBusinessProfileData(this.businessId);
          }
          else {
            this.toasterMessage("", res.data.message, "danger");
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
        });
    },
    GetBusinessProfileData(ParambusinessId ) {
      
      // Clear the store value before Updating Data
      this.$store.state.borrower.businessProfileData.ownersData = [{}];
      this.$store.state.borrower.businessProfileData.businessInputs = {};
      this.$store.dispatch("clearBusinessProfileStore");

      this.$store.dispatch("updateLoaderFlag", true);
      let businessID = ParambusinessId;
      this.$store
        .dispatch("GetBusinessProfileData", businessID)
        .then((res) => {
          if (res.data.isSuccess == true) {
            console.log(res.data.message);
            
          } else {
            this.toasterMessage("", res.data.message, "danger");
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
        });
    },
    selected(crumb) {
      if (crumb == "Profile") {
        this.$router.push("/profile");
      }
    },
    async saveBusinessProfile(){
      let BusinessProfileValidation = await this.$refs.BusinessProfile.validationsStore();
      if(BusinessProfileValidation==true){
      this.$store.dispatch("updateLoaderFlag", true);
      let param={}
      let businessInputs=this.$store.state.borrower.businessProfileData.businessInputs
      let ownersData= this.$store.state.borrower.businessProfileData.ownersData
      param={
        "businessOwnerMasterParam":ownersData
        ,
      "loanBusinessDetailMasterParam":businessInputs 
      }
      this.$store
        .dispatch("SaveBusinessProfileData", param, {
          root: true,
        })
        .then((res)=>{
          if(res.status === 200 && res.data.isSuccess===true){
            if(this.$store.state.borrower.isFromProgramInvitationTOProfile==true){
              this.$router.push('/')
            }
            this.toasterMessage('',res.data.message,'success')
          }else {
            this.toasterMessage("", res.data.message, "danger");
          }
           this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
        });
      }
      else{
        return Promise(false)
      }
    },
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.businessEntitySection {
  .heading {
    h3 {
      font-size: 2.6rem;
      font-weight: bold;
      text-transform: capitalize;
      color: $primary-color-red;
      cursor: pointer;
    }
  }
  .BusinessProfile-inputs {
    height: 65vh;
    // border: 1px solid red;
  }
  .form-group {
    position: relative;
    @include input-style;
    .form__label {
      &::after {
        display: none;
      }
    }
  }
}
</style>
