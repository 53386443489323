<template>
  <div class="businessEntitySection">
    <BasicComponent :heading="getPageHeader()" :subText="texts.subText">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>

      <template v-slot:mainSection>
        <div class="form-section">
          <div class="form-section__header">
            <nav>
              <ul class="nav-btn-steps nav nav-tabs col-sm " >
                <div class="form-section__step-tabs__arrow-buttons">
                  <span
                    class="arrow-button"
                    @click="showOnlyActiveTab()"
                    v-if="isShowOnlyActiveTab == false"
                  >
                    <button class="arrow-button__btn arrow--button__up">
                      <img src="@/assets/imgs/Arrow.svg" alt="up arrow" />
                    </button>
                  </span>
                  <span
                    class="arrow-button"
                    @click="showAllTab()"
                    v-if="isShowOnlyActiveTab == true"
                  >
                    <button class="arrow-button__btn arrow--button__down">
                      <img src="@/assets/imgs/Arrow.svg" alt="down arrow" />
                    </button>
                  </span>
                </div>
                <li class="nav-item" v-if="showActiveTab==true" >
                  <span class="nav-btn"
                  v-if="activeItem=='business'"
                    @click.prevent="setActive('business')"
                    :class="activeItem == 'business' ? 'active' : ''">
                    <a href="#business">
                      <p>{{ nav.stepName1 }}</p>
                    </a>
                  </span>
                  <span
                    class="nav-btn"
                    v-if="activeItem=='businessContact'"
                    @click.prevent="setActive('businessContact')"
                    :class="activeItem == 'businessContact' ? 'active' : ''"
                  >
                    <a href="#businessContact">
                      <p>{{ nav.stepName2 }}</p>
                    </a>
                  </span>
                  <span
                    class="nav-btn"
                    v-if="activeItem=='programInvitation'"
                    @click.prevent="setActiveProgramInvitation()"
                    :class="activeItem == 'programInvitation' ? 'active' : ''"
                  >
                    <a href="#programInvitation">
                      <p>{{ nav.stepName3 }}</p>
                    </a>
                  </span>
                  <span
                    class="nav-btn"
                    v-if="activeItem=='businessProfile'"
                    @click.prevent="setActive('businessProfile')"
                    :class="activeItem == 'businessProfile' ? 'active' : ''"
                  >
                    <a href="#businessProfile">
                      <p>{{ nav.stepName4 }}</p>
                    </a>
                  </span>
                  <span
                    class="nav-btn"
                    v-if="activeItem=='fundingDetails'"
                    @click.prevent="setActive('fundingDetails')"
                    :class="activeItem == 'fundingDetails' ? 'active' : ''"
                  >
                    <a href="#fundingDetails">
                      <p>{{ nav.stepName5 }}</p>
                    </a>
                  </span>
                </li>
                
                <li class="nav-item" 
                v-show="showActiveTab==false" >
                  <span
                    class="nav-btn"
                    @click.prevent="setActive('business')"
                    :class="activeItem == 'business' ? 'active' : ''"
                  >
                    <a href="#business">
                      <p>{{ nav.stepName1 }}</p>
                    </a>
                  </span>
                </li>
                <li class="nav-item" v-show="showActiveTab==false" v-if="(canhide == true)"  >
                  <span
                    class="nav-btn"
                    @click.prevent="setActive('businessContact')"
                    :class="activeItem == 'businessContact' ? 'active' : ''"
                  >
                    <a href="#businessContact">
                      <p>{{ nav.stepName2 }}</p>
                    </a>
                  </span>
                </li>
                <li class="nav-item " v-show="showActiveTab==false" v-if="(canhide == true)"   >
                  <span
                    class="nav-btn"
                    @click.prevent="setActiveProgramInvitation()"
                    :class="activeItem == 'programInvitation' ? 'active' : ''"
                  >
                    <a href="#programInvitation">
                      <p>{{ nav.stepName3 }}</p>
                    </a>
                  </span>
                </li>
                <li class="nav-item " v-show="showActiveTab==false" v-if="(canhide == true) "  >
                  <span
                    class="nav-btn"
                    @click.prevent="setActive('businessProfile')"
                    :class="activeItem == 'businessProfile' ? 'active' : ''"
                  >
                    <a href="#businessProfile">
                      <p>{{ nav.stepName4 }}</p>
                    </a>
                  </span>
                </li>
                <li class="nav-item " v-show="showActiveTab==false" v-if="(canhide == true) && isPaymentSchedule==true" >
                  <span
                    class="nav-btn"
                    @click.prevent="setActive('fundingDetails')"
                    :class="activeItem == 'fundingDetails' ? 'active' : ''"
                  >
                    <a href="#fundingDetails">
                      <p>{{ nav.stepName5 }}</p>
                    </a>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
          <div class="row form-section__multi-form">
            <div class="col-12 form-section__multi-form__forms">
              <div class="forms-container tab-content" id="myTabContent">
                <BusinessEntity
                  id="business"
                  class="tab-pane fade"
                  :entity_ID="entity_ID"
                  :stateList="stateList"
                  :affiliateList="affiliateList"
                  :class="{ 'active show': isActive('business') }"
                  ref="business"
                />
                <UserList
                  id="businessContact"
                  class="tab-pane fade"
                  :entity_ID="entity_ID"
                  :roleList="roleList"
                  :salutationList="salutationList"
                  :class="{ 'active show': isActive('businessContact') }"
                  @addContact="addContact"
                  ref="businessContact"
                />
                <ProgramInvitationsList 
                  id="programInvitation"
                  class="tab-pane fade"
                  :entity_ID="entity_ID"
                  :stateList="stateList"
                  :class="{ 'active show': isActive('programInvitation') }"
                  ref="programInvitation"
                />
                <BusinessProfile
                  id="businessProfile"
                  class="tab-pane fade"
                  :entity_ID="entity_ID"
                  :stateList="stateList"
                  @saveBusinessProfileData="saveBusinessProfileData"
                  :class="{ 'active show': isActive('businessProfile') }"
                  ref="businessProfile"
                />
                <FundingDetails
                  id="fundingDetails"
                  class="tab-pane fade"
                  :entity_ID="entity_ID"
                  :loanApplID="loanApplication_ID"
                  :stateList="stateList"
                  @saveFundingDetailsData="saveFundingDetailsData"
                  :class="{ 'active show': isActive('fundingDetails') }"
                  ref="fundingDetails"
                />
              </div>
              <!-- <div class="horizontal">
                <div class="forms-btns row justify-content-end d-flex"></div>
              </div> -->
            </div>
          </div>
        </div>
        <PopUp v-if="popup === true" @closePopUp="popup = false" class="popup">
          <div class="popup--body">
            <div class="discription pb-2" @click="back">
              <span class="discription-data d-flex ">
                <span class="pr-3">&#60;</span>
                <p v-if="isNewContactForm">Back to Contacts</p>
                <p v-if="isContactList">Back to Add New Contact</p>
              </span>
            </div>
            <div class="new-contact-form" v-if="isNewContactForm">
              <div
                class=" popup__header-section mt-1 d-flex align-items-center justify-content-between flex-sm-row flex-column pr-5"
              >
                <span class="popup__header-section__header">
                  <h3>Add&nbsp;New&nbsp;Contact</h3>
                </span>
                <span>
                  <BtnSecondary
                    :buttonName="`Add Existing Contact`"
                    @click="addExistingContact()"
                  />
                </span>
              </div>
              <div>
                <User
                  :hideShowBar="false"
                  :userData="input"
                  :roleList="roleList"
                  :index="index"
                  @cancle="popup = false"
                  :salutationList="salutationList"
                />
              </div>
            </div>
            <div class="contact-list" v-if="isContactList">
              <ContactList
                class="listingTable"
                :items="contactList"
                :columns="columns"
                :TableHeight="TableHeight"
                :perPage="itemsPerTable"
                @selectRow="selectUserID"
              />
            </div>
            <div class="central-popup" v-if="isContactList && isUserRole">
              <div class="central-popup__main">
                <div>
                  <span class="central-popup__main__heading">
                    <h3>
                      Select the role for {{ userDetail.firstName }}
                      {{ userDetail.lastName }}
                    </h3>
                  </span>
                </div>
                <div>
                  <div class=" col-3 col-3-internal  form-group">
                    <select
                      class="select-group"
                      placeholder=" "
                      id="user-role"
                      v-model="userDetail.businessRoleID"
                      name="user-role"
                    >
                      <option value="" disabled>Select a Role</option>
                      <!-- @change="selectOption($event)" -->
                      <option
                        v-for="role in roleList"
                        :key="role.businessRoleID"
                        :value="role.businessRoleID"
                      >
                        {{ role.businessRoleName }}
                      </option>
                    </select>
                    <label for="user-role" class="form__label select-label">
                      Role</label
                    >
                    <!-- {{ labels.role }} -->
                  </div>
                </div>
                <div>
                  <div class=" d-flex justify-content-end">
                    <div class="mr-5">
                      <span>
                        <BtnSecondary
                          :buttonName="`Cancel`"
                          @click="isUserRole = false"
                        />
                      </span>
                    </div>
                    <div class="p-0">
                      <span>
                        <BtnPrimary
                          :buttonName="`Save`"
                          @click="saveUserRole"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopUp>
        <LoadingMask v-if="isLoading === true" />
      </template>
    </BasicComponent>
  </div>
</template>

<script>
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import BusinessEntity from "@/components/BusinessForms/BusinessEntity";
import ProgramInvitationsList from "@/components/BusinessForms/ProgramInvitationsList";
import ContactList from "@/components/BusinessForms/ContactList";
import UserList from "@/components/BusinessForms/UserList";
import PopUp from "@/components/PopUp";
import User from "@/components/BusinessForms/User.vue";
import BtnSecondary from "@/components/BtnSecondary.vue";
import BusinessProfile from "@/components/BusinessForms/BusinessProfileTab.vue";
import FundingDetails from "@/components/BusinessForms/FundingDetailsTab.vue";
// import BtnTertiary from "@/components/BtnTertiary.vue";
import BtnPrimary from "@/components/BtnPrimary.vue";
//import axios from "axios";
export default {
  components: {
    BasicComponent,
    BreadCrumb,
    BtnPrimary,
    // BtnTertiary,
    BtnSecondary,
    ContactList,
    PopUp,
    User,
    BusinessEntity,
    ProgramInvitationsList,
    BusinessProfile,
    UserList,
    FundingDetails
  },
  props: {
    entityID: {
      type: Number,
      default: 0,
    },
    loanApplicationID:
    {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      isShowOnlyActiveTab:false,
      showActiveTab:false,
      showNavTab: true,
      popup: false,
      itemsPerTable: 3,
      TableHeight: 15,
      contactList: {},
      columns: ["Business Name", "EIN", "Affiliate Name"],
      items: this.inputs,
      isNewContactForm: true,
      isContactList: false,
      isUserRole: false,
      userDetail: {
        contactID: "",
        businessRoleID: "",
        businessID: "",
      },
      input: {
        contactID: 0,
        businessRoleID: "",
        isActive: true,
        salutationID: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        phoneNo: "",
        businessID: this.businessID,
        accountStatusID: 0,
        userRoles: [2],
      },
      label: {
        businessEntity: "Business Entity",
        programInvitation: " Program",
        user: "Contact",
      },
      tabItems: [
        "business",
        "businessContact",
        "programInvitation",
        "businessProfile",
        "fundingDetails",
      ],
      activeItem: "business",
      nav: {
        stepName1: "Business Entity ",
        stepName2: "Contacts ",
        stepName3: "Program Invitations",
        stepName4: "Business Profile",
        stepName5: "Payment Schedule",
      },
      texts: {
        heading: " ",
        // subText: "Not sure about the description - need more information",
      },
      crumbs: ["Business Entities", "business Entity"],
      //businessID: 0,
      invitationID: 0,
      userID: 0,
      programStatusId: 0,
      businessusers: {},
      affiliateList: [],
      roleList: [],
      salutationList: [],
      entity_ID: this.entityID,
      loanApplication_ID:this.loanApplicationID === undefined ? 0 : this.loanApplicationID
      // canhide: true,
    };
  },
  beforeMount() {
    if (this.$store.state.business.programInvitationActiveTab =="programInvitation") {
      this.setActive("programInvitation");
    }
    else if(this.$store.state.business.programInvitationActiveTab =="businessContact")
    {
      this.setActive("businessContact");
    }
    else if(this.$store.state.business.programInvitationActiveTab =="fundingDetails")
    {
      this.setActive("fundingDetails");
    }
    /*if(this.userRoleID==4 || this.userRoleID==6){
      this.setActive("fundingDetails");
    } */
    else 
    {
    this.setActive("business");
    }
  },
  created() {
    this.getPrograms();
    this.getMasterData();
  },
  computed: {
    userRoleID() {
      return this.$store.getters.getUserRoleID;
    },
    isPaymentSchedule: {
      get() {
        return this.$store.state.business.isPaymentSchedule;
      },
    },
    inputs: {
      get() {
        return this.$store.state.business.users.roles;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    invite: {
      get() {
        return this.$store.state.business.temporary;
      },
    },
    businessData: {
      get() {
        return this.$store.state.business.businessEntityData;
      },
    },
    canhide: {
      get() {
        if (this.businessData.id === undefined || this.businessData.id === 0) {
          return false;
        } else {
          return true;
        }
      },
    },
  },
  methods: {
    showOnlyActiveTab() {
      this.showActiveTab=true;
      this.isShowOnlyActiveTab=true;
    },
    showAllTab() {
      this.showActiveTab=false;
      this.isShowOnlyActiveTab=false;
    },
    async saveBusinessProfileData() {
      if (this.activeItem == "businessProfile") {
        let BusinessProfileValidation = await this.$refs.businessProfile.saveBusinessProfile();
        if (BusinessProfileValidation === true) {
          this.$store.dispatch("updateLoaderFlag", true);
          let param = {};
          let businessInputs = this.$store.state.borrower.businessProfileData
            .businessInputs;
          let ownersData = this.$store.state.borrower.businessProfileData
            .ownersData;
          param = {
            businessOwnerMasterParam: ownersData,
            loanBusinessDetailMasterParam: businessInputs,
          };
          this.$store
            .dispatch("SaveBusinessProfileData", param, {
              root: true,
            })
            .then((res) => {
              if (res.status === 200 && res.data.isSuccess === true) {
                this.toasterMessage("", res.data.message, "success");
                this.$store.dispatch(
                  "GetBusinessProfileData",
                  businessInputs.businessID
                );
                // this.GetBusinessProfileData();
              } else {
                this.toasterMessage("", res.data.message, "danger");
              }
              this.$store.dispatch("updateLoaderFlag", false);
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              console.log(err);
            });
        }
      }
    },
    featchContactlist() {
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("fetchBusinessContacts", { root: true })
        .then((contacts) => {
          if (contacts.data.data && contacts.data.data.length > 0) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.contactList = contacts.data.data;
          }
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    async saveUserRole() {
      let businessID = await this.$store.state.business.businessEntityData.id;
      let param = {
        contactID: Number(this.userDetail.contactID),
        businessRoleID: this.userDetail.businessRoleID,
        businessID: businessID,
      };

      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("AddRoleForContact", param)
        .then(async (res) => {
          if (res.data.isSuccess) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", "Contact saved successfully", "success");
            this.$store.dispatch(
              "fetchBusinessUsersDetails",
              this.$store.state.business.businessEntityData.id,
              {
                root: true,
              }
            );
            Promise.resolve(true);
            this.isHide = false;
            this.popup = false;
            this.isUserRole = false;
            this.isContactList = false;
          } else {
            this.$store.dispatch("updateLoaderFlag", false);
            if (
              (res.data.validationErrors != null ||
                res.data.validationErrors != undefined) &&
              res.data.validationErrors.length > 0
            ) {
              res.data.validationErrors.forEach((element) => {
                this.toasterMessage("", element, "danger");
              });
              this.isUserRole = false;
            } else {
              this.toasterMessage("", "Failed to save contact", "danger");
            }
          }
          return Promise;
        })
        .catch(() => {
          this.$store.dispatch("updateLoaderFlag", false);
          this.toasterMessage("", "Error while processing request", "danger");
          return Promise.resolve(false);
        });
    },
    selectUserID(value) {
      this.userDetail = value;
      this.isUserRole = true;
    },
    back() {
      if (this.isNewContactForm == true) {
        this.popup = false;
      }
      if (this.isContactList == true) {
        this.isContactList = false;
        this.isNewContactForm = true;
      }
    },
    addExistingContact() {
      this.isUserRole = false;
      this.isContactList = true;
      this.isNewContactForm = false;
    },
    selected(crumb) {
      if (crumb == "Business Entities") {
        this.$router.push("/bel");
      }
    },
    addContact() {
      this.featchContactlist();
      this.popup = true;
      this.isNewContactForm = true;
      this.isContactList = false;
      let isDirty = false;
      if (isDirty === false) {
        this.input = {
          contactID: 0,
          businessRoleID: "",
          isActive: true,
          salutationID: "",
          firstName: "",
          middleName: "",
          lastName: "",
          emailAddress: "",
          phoneNo: "",
          businessID: this.businessID,
          accountStatusID: 0,
          userRoles: [2],
        };
      }
      this.$store.dispatch("updateBusinessUsersDetails", this.inputs, {
        root: true,
      });
    },
    setActiveProgramInvitation() {
      this.setActive("programInvitation");
      this.$store.dispatch("getbusinessuserList", this.businessData.id, {
        root: true,
      });
    },
    getPageHeader() {
      let businessname=''
      businessname = this.$store.state.business.businessEntityData.businessName;
      if (
        this.$store.state.business.businessEntityData.id === 0 ||
        this.$store.state.business.businessEntityData.id === undefined
      )
        this.texts.heading = "New Business";
      else this.texts.heading = businessname;
      return this.texts.heading;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
      if (this.activeItem == "business") {
        this.crumbs[1] = "Business Entity";
      }
      if (this.activeItem == "businessContact") {
        this.crumbs[1] = " Contact";
      }
      if (this.activeItem == "programInvitation") {
        this.crumbs[1] = "Program Invitation";
      }
      if (this.activeItem == "businessProfile") {
        this.crumbs[1] = "Business Profile";
      }
      if (this.activeItem == "fundingDetails") {
        this.crumbs[1] = "Payment Schedule";
      }

    },

    getPrograms() {
      this.$store
        .dispatch("createProgramInvitation")
        .then((res) => {
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
        });
    },

    getMasterData() {
      this.$store
        .dispatch("getmasterData")
        .then((res) => {
          this.affiliateList = res.data.affiliateList;
          this.roleList = res.data.businessRoleList;
          this.salutationList = res.data.salutationList;
          // var businessEntity = this.$store.state.business.businessEntityData;
          // if (businessEntity.id === undefined || businessEntity.id === 0) {
          //   this.canhide = true;
          // } else {
          //   this.canhide = false;
          // }
        })
        .catch((err) => {
          Promise.reject(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.businessEntitySection {
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
  nav {
    ul,
    li {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      a {
        text-decoration: none;
        height: 5rem; //54px;
        min-width: 5rem;
        margin-right: 1rem;
        background-color: transparent;
        outline: none;
        border: 1px solid transparent; //$primary-color-red;
        border-radius: 3.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          // border: 1px solid $primary-color-red;
          p {
            font-weight: bold;
            color: $primary-color-black;
          }
        }
        p {
          font-size: 1.6rem;
          color: $primary-color-grey;
          margin: 0 0.5rem;
          padding: 0rem 4rem;
        }
      }
      .active {
        a {
          border: 1px solid $primary-color-red;
          p {
            font-weight: bold;
            color: $primary-color-black;
          }
        }
      }
    }
  }
  .temp {
    button {
      padding: 0rem 1rem;
    }
    padding-top: 2rem;
    .row {
      padding-right: 10rem;
      margin: 0;
    }
  }
  .form-section {
    &__multi-form {
      height: 70vh;
      border: 0rem;
      border-top: 0.1px;
      
      border-top: 1px;
      border-color: $secondary-color-white;
      border-style: solid;
      margin: 0rem;
      padding: 0rem;
      &__forms {
        padding: 0rem;
        border: 0rem;
        .horizontal {
          content: "";
          width: 100%;
          height: 1px;
          background-color: $secondary-color-white;
        }
        .row {
          margin: 0rem;
          padding: 0rem;
        }
        .forms-btns {
          padding-right: 10rem;
          margin: 2.5rem 0rem;
          @media screen and (max-width: 1600px) {
            margin: 1rem 0rem;
          }
          button {
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0rem;
            margin-top: 1.5rem;
            height: 4rem;
          }
          .btn--secondary {
            width: 16.5rem;
          }
          .btn--primary {
            width: 12.7rem;
          }
          &__left {
            padding: 0rem;
            .btn--tertiary {
              button {
                position: relative;
                &:before {
                  position: absolute;
                  left: 0;
                  content: "<";
                }
              }
            }
          }
        }
        .forms-container {
        height: calc(100vh - 30rem);
        @media screen and(max-width:580px) {
            min-height: 70vh;
          }
      }
      }
    }
    &__step-tabs__arrow-buttons {
      display: none;
      @media screen and(max-width:576px) {
        display: flex;
        position: absolute;
        right: 2.5%;
        .arrow-button {
          &__btn {
            height: 2.5rem;
            width: 2.5rem;
            border: none;
            outline: none;
            background: transparent;
            img {
              height: 100%;
            }
          }
          .arrow--button__down {
            transform: rotate(180deg);
          }
        }
      }
    }
    @media screen and (max-width: 580px){
        min-height: 80vh;
      }
  }
  .btn--primary {
    button {
      width: auto;
    }
  }
  .discription {
    cursor: pointer;
    position: absolute;
    top: 1.5rem;
    font-size: 1.6rem;
    color: $primary-color-black;
    text-transform: capitalize;
  }
  .popup {
    .popup--body {
      height: 95%;
      margin-top: 4rem;
      overflow-y: auto;
    }
    
  }
  .popup__header-section {
    &__header {
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
    }
  }
  .central-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0%;
    left: 0%;
    height: 100%;
    width: 100%;
    &__main {
      z-index: 9999;
      padding: 2rem;
      min-height: 40vh;
      min-width: 60%;
      border: 1px solid gray;
      background: $primary-color-white 0% 0% no-repeat padding-box;
      box-shadow: 0px -6px 12px #00000010;
      border-radius: 3rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__heading {
        h3 {
          font-size: 2.4rem;
          font-weight: bold;
          // text-transform: capitalize;
          color: $primary-color-red;
        }
      }
    }
  }
}
</style>
