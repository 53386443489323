<template>
<div>
  <div class="menu">
    <nav  class="primary-navigation">
  <ul>
    <li v-for="menu in menus" :key="menu">
          <a v-if="menu.isSubMenu"  :to="menu.linkTo"    class="subMenurouter-tab">
            {{menu.name}}
          </a>
          <router-link v-else  :to="menu.linkTo" active-link="active" class="router-tab">
            {{menu.name}}	
          </router-link>
          <!--Sub Menu Section -->
           <ul v-if="menu.isSubMenu" class="dropdown" style="width:300px;left:320px;margin-top: -60px;  box-shadow: 0px 0.5rem 1.2rem #00000029;
              border-radius: 0.5rem 0.5rem 0.3rem 0.5rem;">
              <li style="height: 35px;" v-for="submenu in menu.subMenuList" :key="submenu">
                  <router-link  :to="submenu.linkTo" active-link="active" class="router-tab">
                  {{submenu.name}}	
                </router-link>
              </li>
          </ul>
    </li>
  </ul>
</nav>
  </div>
</div>
  
</template>
<script>
export default {
  props: {
    menus: [
    ],
  },
};
</script>
<style lang="scss" scoped>
.menu {
    box-shadow: 0px .3rem 1.2rem #00000029;
    border-radius: 0px .3rem .3rem 0px;
  // height: 89vh;
  height: calc((100vh) - (10rem));
  overflow-y: auto;
  width: 35rem;
  z-index: 999999;
  background-color: $primary-color-white;
   &::-webkit-scrollbar {
      width: 5px;
      background: $tertiary-color-white;
    }

    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      height: 0.1rem;
      -webkit-border-radius: 2rem;
    }

    &::-webkit-scrollbar-corner {
      background: #000;
    }
  ul {
    list-style: none;
    li {
      height: 6rem;
      width: 100%;
      .router-tab {
        display: flex;
        border: none;
        align-items: center;
        border-left: 5px solid transparent;
        font-size: 1.8rem;
        color: $primary-color-black;
        text-transform: capitalize;
        text-decoration: none;
        border-left: 5px solid transparent;
        padding-left: 2.5rem;
        text-transform: capitalize;
        transition: 0.3s ease-out;

        height: 100%;
        &:hover {
          background-color: rgba($primary-color-grey, 0.19);
        }
      }
      .router-link-exact-active,
      .router-tab:active {
        border-left: 5px solid $primary-color-red;
        background-color: rgba($primary-color-red, 0.08);
        color: $primary-color-red;
        font-weight: bold;
      } 
      .subMenurouter-tab {
        display: flex;
        border: none;
        align-items: center;
        border-left: 5px solid transparent;
        font-size: 1.8rem;
        color: $primary-color-black;
        text-transform: capitalize;
        text-decoration: none;
        border-left: 5px solid transparent;
        padding-left: 2.5rem;
        text-transform: capitalize;
        transition: 0.3s ease-out;

        height: 100%;
        &:hover {
          background-color: rgba($primary-color-grey, 0.19);
        }
      }
      .subMenurouter-tab a { border-bottom: 8px solid #e1263a; }
       
    }
  }
  @media screen and (max-width:580px) {
    width: 25rem;
      // position: relative;
      min-height: 100vh;//calc((100vh) - (10rem));
      height: max-content;
      // max-height: 100%;
            // height: 100%;
      }
}
nav {
  &.primary-navigation {
    display: block;

    ul li {
      
      margin: 0 auto;
      border-left: 2px solid ;
      
      
      
      text-decoration: none;
      text-align: center;
    }

    li a {
      color: black;
    }

    li:hover {
      cursor: pointer;
    }

    ul li ul {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      padding-left: 0;
      left: 0;
      display: none;
      background: white;
    }

    ul li:hover > ul,
    ul li ul:hover {
      visibility: visible;
      opacity: 1;
      display: block;
      min-width: 250px;
      
      padding-top: 20px;
      box-shadow: 0px 3px 5px -1px #ccc;
    }

    ul li ul li {
      clear: both;
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      border-style: none;
    }

    ul li ul li a:hover {
      
      
      
    }
  }
}



 ul li ul li a { }


</style>
