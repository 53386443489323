<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="login-section row">
      <div class="login-section__form col ">
        <div class="login-section__form--logo">
          <img src="@\assets\imgs\NUL_Logo_Standalone.png" alt="logo" />
          <img
            src="@\assets\imgs\Urban-Empowerment-Fund-2020-cmyk@2x.png"
            alt="logo"
          />
        </div>
        <div class="form-titles">
          <h1>
            {{ FormTitle }}
          </h1>
          <h6>
            {{ formSubTitle }}
          </h6>
        </div>
        <div class="login-title">
          <h3>{{ activateAccountTitle }}</h3>
        </div>
        <form @submit.prevent class="login-section__form--details">
          <div class="form-inputs">
            <div class="form-input--email form-group mb-5">
              <input
                required
                type="password"
                placeholder=" "
                name="login-email"
                id="login-email"
                @keyup="checkPasswordValidation($event)"
                @blur="checkPasswordValidation($event)"
                v-model="inputs.newPassword"
              />
              <label for="login-email" class="form__label required">
                {{ NewPassword }}
              </label>
              <button class="password-show-icon" @click="showPassword($event)">
                <img :src="eye ? openEye : closedEye" alt="eye" />
              </button>
              <Error
                v-if="NewPasswordError.isError == true"
                class="error-message"
              >
                <p>
                  <small>
                    {{ NewPasswordError.message }}
                  </small>
                </p>
              </Error>
            </div>
            <div class="form-input--password form-group">
              <input
                required
                type="password"
                placeholder=" "
                name="login-password"
                id="login-password"
                v-model="inputs.confirmPassword"
                 v-on:keyup.enter="onEnter"
              />
              <label for="login-password" class="form__lable required">{{
                ConfirmPassword
              }}</label>
              <button class="password-show-icon" @click="showPassword($event)">
                <img :src="eye ? openEye : closedEye" alt="eye" />
              </button>
              <Error v-if="v$.inputs.confirmPassword.$error">
                <p>
                  <small>
                    Password should be same as New Password
                  </small>
                </p>
              </Error>
            </div>
            <div class="loginbtn">
              <BtnPrimary buttonName="Activate Now" @click="ActivateAccount" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <LoadingMask v-if="isLoading === true" />
    <footer class="row">
      <div class="row col footer">
        <div class="col-3 footer-left">
          <p>© 2021 national urban league</p>
        </div>
        <div class="col-3 footer-right">
          <a
            href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/NUL_Website_Privacy_Policy(BuildBackBlack).docx"
            >Privacy Policy
          </a>
          <div>&nbsp;</div>
          <a
            href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/Terms_of_Use_(BuildBackBlack).docx"
            >Terms of Use
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Error from "@/components/Error.vue";
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import axios from "axios";
export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  components: {
    BtnPrimary,
    Error,
  },
  data() {
    return {
      openEye: require("@/assets/imgs/showIcon.svg"),
      closedEye: require("@/assets/imgs/hideShowIcon.svg"),
      eye: "",
      FormTitle: "Build Back Black",
      formSubTitle: "Supporting minority entrepreneurs with capital resources.",
      activateAccountTitle: "Activate Account",
      NewPasswordError: {
        isError: false,
        message: "",
      },
      NewPassword: "New Password",
      ConfirmPassword: "Confirm New Password",
      passwordError: false,
      inputs: {
        newPassword: "",
        confirmPassword: "",
      },
      accountActivationResponse: {},
    };
  },
  validations() {
    return {
      inputs: {
        newPassword: { required, minLength: minLength(8) },
        confirmPassword: { required, sameAs: sameAs(this.inputs.newPassword) },
      },
    };
  },
  props: {
    t: {
      type: String,
    },
    c: {
      type: String,
    },
  },
  beforeMount() {
      let config = {
          params: {
              t: this.t,
              c: this.c
          },
      };
      this.$store.dispatch("updateLoaderFlag",true)
      axios.get('/Contact/ActivateAccount', config)
          .then((res) => {
              if (res.status === 200) {
                  if(res.data.isSuccess===false && res.data.message==='Invalid request'){
                  this.$store.dispatch("updateLoaderFlag",false)
                      this.toasterMessage('','Activation link is expired','warning')
                      this.$router.push('/ale')
                  }
                  this.accountActivationResponse = res.data;
              }
                  this.$store.dispatch("updateLoaderFlag",false)
          })
          .catch((err) => {
              this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage('','Error while processing request','danger')
              Promise.reject(err);
          });
  },
  methods: {
    onEnter(){
    this.ActivateAccount()         
    },
    async ActivateAccount() {
      const isFormCorrect = await this.v$.$validate();
      if (
        this.inputs.newPassword.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-]).{8,20}$/
        )
      ) {
        this.NewPasswordError.isError = false;
        this.NewPasswordError.message = "";
        if (isFormCorrect) {
          this.$store.dispatch("updateLoaderFlag", true);
          let param = {
            ContactID: this.accountActivationResponse.contactId,
            UserID: this.accountActivationResponse.userId,
            TokenID: this.accountActivationResponse.tokenID,
            Password: this.inputs.newPassword,
            ConfirmPassword: this.inputs.confirmPassword,
          };
          return new Promise((resolve, reject) => {
            axios
              .post("Contact/ActivateUserAccount", param)
              .then((res) => {
                this.$store.dispatch("updateLoaderFlag", false);
                if (res.status === 200) {
                  if (res.data.isSuccess === true) {
                    this.toasterMessage(
                      "",
                      "Your account activated successfully.",
                      "success"
                    );
                    this.$router.push("/success");
                  }
                  resolve(res);
                }
              })
              .catch((err) => {
                this.$store.dispatch("updateLoaderFlag", false);
                this.toasterMessage(
                  "",
                  "Error while processing request",
                  "danger"
                );
                reject(err);
              });
          });
        }
      }
      else {
        this.NewPasswordError.isError = true;
        this.NewPasswordError.message =
          "The password must have a minimum 8 and maximum 20 characters, 1 number, 1 upper case, 1 lower case and a special character (@#$%&)";
      }
    },
    showPassword(e) {
      let parentBody = e.target.parentNode.parentNode;
      let password = parentBody.childNodes[0];
      if (password.type === "password") {
        password.type = "text";
        parentBody.childNodes[2].childNodes[0].src = this.openEye;
      } else {
        password.type = "password";
        parentBody.childNodes[2].childNodes[0].src = this.closedEye;
      }
    },
    checkPasswordValidation() {
      if (
        this.inputs.newPassword.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[#?!@$%^&*-]).{8,20}$/
        )
      ) {
        this.NewPasswordError.isError = false;
        this.NewPasswordError.message = "";
      } else {
        this.NewPasswordError.isError = true;
        this.NewPasswordError.message =
          "The password must have a minimum 8 and maximum 20 characters, 1 number, 1 upper case, 1 lower case and a special character (@#$%&)";
          if(this.inputs.newPassword.length>20){
           let newPassword = this.inputs.newPassword.slice(0,20);
            this.inputs.newPassword = newPassword ;
            this.NewPasswordError.isError = false;
            this.NewPasswordError.message = "";
          }
      }
    },
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.login {
  position: relative;
  overflow: hidden;
  .login-bg {
    height: 100vh;
    background: $secondary-color-white 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: column;
  }
  .login-section {
    max-width: calc(100vw - 10%); //172rem;
    width: 100%; 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: calc(100vh - 20%); //88rem;
    max-height: 100%;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    border-radius: 5.8rem;
    opacity: 1;
    &__form {
      max-width: 100%;
      border-radius: 5.8rem 0 0 5.8rem;
      padding: 5rem 6rem;
      display: grid;
      // flex-direction: column;
      justify-content: space-around;
      @media screen and (max-width: 1600px) {
        padding: 3.5rem;
      }
      @media screen and (max-width: 1400px) {
        padding: 2.5rem;
      }
      &--logo {
        max-height: 5rem; //5.5rem;
        img {
          height: 100%;
          margin-right: 3rem;
        }
        @media screen and (max-width: 1600px) {
          height: 4rem;
          align-content: center;
        }
        @media screen and (max-width: 1400px) {
          height: 3.5rem;
          align-content: center;
        }
      }
      .form-titles {
        color: $primary-color-black;
        h1 {
          font-size: 4rem;
          font-weight: bold;
          line-height: 5rem;
          text-transform: capitalize;
          @media screen and (max-width: 1600px) {
            font-size: 3rem;
          }
          @media screen and (max-width: 1400px) {
            font-size: 2.5rem;
          }
        }
        h6 {
          max-width: 100%;
          font-size: 1.4rem;
          line-height: 2rem;
        }
      }
      .login-title {
        h3 {
          font-size: 3.2rem; //3rem;
          font-weight: 400; // font-weight: bold;
          color: $primary-color-red;
        }
        padding: 5rem 0rem 0rem 0rem;
        @media screen and (max-width: 1600px) {
          padding: 0.5rem 0rem 0rem 0rem;
          h3 {
            font-size: 2.5rem;
          }
          h6 {
            font-size: 1.2rem;
          }
        }
        @media screen and (max-width: 1400px) {
          h3 {
            font-size: 2rem;
          }
        }
      }
      &--details {
        max-width: 80%;
        display: flex;
        flex-direction: column; // @media screen and (max-width: 1600px) {
        //   max-width: 80%;
        // }
        .form-inputs {
          padding: 1rem 0;
          display: flex;
          flex-direction: column;
          .form-group {
            position: relative;
            @include input-style;
            padding-right: 0% !important;
            .password-show-icon {
              height: 1.5rem;
              width: 2rem;
              outline: none;
              border: none;
              background-color: transparent;
              position: absolute;
              right: 0.5rem;
              bottom: 2rem;
              display: flex;
              justify-content: center;
              align-items: center; // img {
              //   width: 100%;
              //   height: 100%;
              // }
            }
          }
          .form-input--infos {
            display: flex;
            justify-content: space-between;
            input {
              display: flex;
              width: 1.5rem;
              height: 1.5rem;
              color: $primary-color-black;
            }
            &__radio-btn {
              display: flex;
              input {
                margin-right: 1rem;
              }
            }
            button {
              color: $primary-color-black;
              background-color: transparent;
              border: none;
              outline: none;
              text-decoration: underline;
            }
          }
          .activatebtn {
            button {
              // width: 45rem;
              margin-top: 4.6rem;
              height: 6.4rem;
              font-size: 2rem;
            }
            @media screen and (max-width: 1600px) {
              button {
                margin-top: 1.5rem;
                height: 4rem;
              }
            }
            @media screen and (max-width: 1400px) {
              button {
                margin-top: 1rem;
                height: 3rem;
                font-size: 1.6rem;
              }
            }
          }
          .error-message {
            top: 85%;
          }
        }
        @media screen and (max-width: 1400px) {
          max-width: 100%;
        }
      }
    }
  }
  footer {
    position: absolute; // width: 100%;
    max-width: calc(100vw - 10%); //172rem;
    width: 100%;
    margin: 0;
    bottom: 0%;
    left: 50%; // top: 50%;
    transform: translate(-50%, -50%); // @media screen and (max-width: 1600px) {
    //   width: 80%;
    // }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0rem;
      a,
      p {
        font-size: 1.2rem;
        line-height: 1.9rem;
        color: $primary-color-black;
        text-transform: capitalize;
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div {
          // content: "";
          height: 1.5rem;
          max-width: 0.1rem;
          background-color: $secondary-color-white;
          margin: 0 1rem;
        }
        a {
          color: $secondary-color-white;
        }
      }
    }
  }
}
</style>
