import axios from "axios";

const state = {
  questionData: {
    questionText:"",
    active:"",
    questionID:0
  },
};
const getters = {};
const actions = {
  getQuestionList: () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/Admin/GetAllQuestions", config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clearStore:(vueContext)=>{
    vueContext.commit("mutateClearQuestionData", null);
  },
  getQuestion:(vueContext,payload)=>{
    let token = localStorage.getItem("token");
    actions.clearStore(vueContext);
    let config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          QuestionID: parseInt(payload),
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/Admin/GetQuestion", config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess==true) {
                vueContext.commit("mutateQuestionData", res.data.questionsRecords[0]);  
                state.questionData=res.data.questionsRecords[0]  
              resolve(res.data.message);
            }
            else{
                reject(res.data.message);
            }
          })
          .catch((err) => {
            reject(err.data.message);
          });
      });
  },
  saveOrUpdateQuestion:(vueContext, payload)=>{
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
        return new Promise((resolve, reject) => {
          axios.post('/Admin/SaveOrUpdateQuestions',payload, config)
            .then(res => {
              if (res.status === 200 && res.data.isSuccess==true) {
                vueContext.commit("mutateQuestionData", res.data);
                resolve(res.data);
              }
              else{
                  reject(res.data)
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
  }
};
const mutations = {
    mutateClearQuestionData:(state) => {
        state.questionData.questionText=""
       state.questionData.active=""
        state.questionData.questionID=0
    },
    mutateQuestionData:(state, payload)=> {
    Object.assign(state.questionData, payload);

    },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
