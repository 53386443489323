<template>
    <div class="row">
            <div class="col-4">
                <p>
                    {{label }}
                </p>
            </div>
            <div class="col-8">
                <p >
                    <span class="">:<span class="pl-2"><slot name="prefix"> </slot>{{Value}}</span></span> 
                </p>
            </div>
        </div>
</template>
<script>
export default {
    props:{
        label:{
            type:String
        },
        Value:{
            type:String
        }
    }
}
</script>
<style lang="scss" scoped>
.row {
    // padding-right: 10%;
    margin: 0;
    display: flex;
    align-items: center;
    p{
    color: $primary-color-black;
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }

    }
    .col-4{
        padding: 0rem;
        padding-right: 1rem;
        p{
        color: $tertiary-color-black;
        }
        
    }
    .col-8{
        p{
            overflow-wrap: break-word;
        }
    }
    }
</style>