<template>
  <div class="ExpiredLink">
    <div class="ExpiredLink--main">
      <div class="ExpiredLink--main__content">
        <div class="ExpiredLink--main__content__logo">
          <div class="logos">
            <img class="logo" src="@\assets\imgs\NUL_Logo_Standalone.png" alt="logo" />
            <div  class="vertical-line"> &nbsp; </div>
            <img
            class="logo"
              src="@\assets\imgs\Urban-Empowerment-Fund-2020-cmyk@2x.png"
              alt="logo"
            />
          </div>
        </div>
        <div class="ExpiredLink--main__content__img">
            <div class="main-img">
                <img
                class="computer-img"
              src="@\assets\imgs\deactiveImg.png"
              alt="computer"
            />
            </div>
        </div>
        <div class="ExpiredLink--main__content__text">
            <div class="title">
                <h1 class="title__main">
                  {{mainTitle}}
                </h1>
            </div>            
        </div>
      </div>
    </div>
    <div class="ExpiredLink--footer">
      <footer class="row p-0 m-0">
        <div class="row p-0 m-0 col-12 footer">
          <div class="col-sm-3 col-12 px-3 footer-left">
            <p>© 2021 national urban league</p>
          </div>
          <div class="col-sm-3 col-12 px-3 footer-right">
            <a href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/NUL_Website_Privacy_Policy(BuildBackBlack).docx">Privacy Policy </a>
            <div> &nbsp; </div>
            <a href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/Terms_of_Use_(BuildBackBlack).docx">Terms of Use </a>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>

export default {
  data(){
    return{
      mainTitle:"Activation link is expired",
    }
  },
    props:{
        mainMessage:{
            type:String,
            default:"Congratulations"
        },
        instructionMessage:{
            type:String,
            default:"Congratulations"
        }
    }
};
</script>
<style lang="scss" scoped>
.ExpiredLink{
  padding: 0;
  margin: 0;
  width: 100%;
  border: 1px solid transparent;
  height: 100vh;
  background-color: $secondary-color-white;
  &--main{
      background-color: $primary-color-white;
      border-radius: 5.8rem;
      max-width: calc(100vw - 10%);
       height: calc(100vh - 20%);
    padding: 5rem 0rem;
    margin:5% auto;
    display: flex;
    justify-content: center;
    &__content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      &__logo{
        .logos{
        height: 5rem;
        display: flex;
        .vertical-line{
          height: 100%;
          width: .1rem;
          background-color: $tertiary-color-white;
          display: inline-block;
        }
        .logo{
          height: 100%;
          padding: 0rem 3rem;
        }
        @media screen and (max-width: 570px){
          height: 4rem;
          .logo{
            padding: 0rem 1rem;
          }
        }
        }
        
      }
      &__img{
        .main-img{
          // height: calc(100vh - 50vh);
          max-width: 50rem;
          .computer-img{
            // height: 100%;
            width: 100%;
          }
          @media screen and (max-width: 570px){
            max-width: 25rem;
          }
        }
      }
      &__text{
        .title{
          text-align: center;
          &__main{
            font: normal normal normal 3.2rem;
            font-weight: bold;
            color: $primary-color-red;
          }
          &__subtitle{
            padding: 0;
            margin: 0;
            font: normal normal 300 1.4rem;
            color: $primary-color-black;
          }
        }
      }
      &__btn{
        width: 90%;
      }
    }
  }
  &--footer{
    width: 100%;
    footer{
    position: absolute;
    // width: 100%;
    max-width: calc(100vw - 10%);//172rem;
    width: 100%;
    margin: 0;
    bottom: 0%;

    left: 50%;
    // top: 50%;
    transform: translate(-50%, -50%);
    .footer{
      display: flex;
      justify-content: space-between;
      padding: 0rem;
      a,p{
          font-size: 1.2rem;
          line-height: 1.9rem;
          color: $primary-color-black;
          text-transform: capitalize;
        }
      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div {
          // content: "";
          height: 1.5rem;
          max-width: 0.1rem;
          background-color: $secondary-color-white;
          margin: 0 1rem;
        }
        
      }
      @media screen and (max-width: 756px) {
           top:40px;
          left:40px;
        }
        @media screen and (max-width: 570px) {
           top:20px;
          left:0px;
          &-right,
          &-left{
            display: flex;
            justify-content: center;
          }
        }
    }
  }
  }
}
</style>
