<template class="funding-list" >
  <div class="table-view">
    <div class="table-view__header d-flex row m-0 p-0">
      <div class="table-view__header--left">
        <div class="sort">
        </div>
      </div>
      <div class="table-view__header--right p-0 col d-flex justify-content-end">
        <div class="search">
          <div class="d-flex  flex-wrap">
          <label for="search" class="ml-1 d-flex align-items-center search-by-filter" >Search&nbsp;By:
            <span class="search-by-filter__select">
              <select
            class="search-group search-by-filter__select mx-sm-2 mx-0 px-1 my-1"
            placeholder=" "
            name="search-group"
            id="search-group"
            v-model="selectOptionToFilter"
          >
            <option selected value="all">
              <p>All</p>
            </option>
            <option value="programName">
              <p>Program Name</p>
            </option>
            <option value="fundType">
              <p>Funding Type</p>
            </option>
            <option value="initialFundedAmount">
              <p>Intial Fund</p>
            </option>
            <option value="availableLimit">
              <p>Available Limit</p>
            </option>
            <option value="utilizedAmount">
              <p>Utilized Amount</p>
            </option>
            <option value="totalFundedAmount">
              <p>Total Funded Amount</p>
            </option>
          </select>
            </span>
          </label>
          <span class=" mx-1 d-flex search-by-input " >
            
          <input
          class="search-by-input__input"
            type="text"
            name="searchByKey"
            id="searchByKey"
            v-model="filter"
            placeholder="Search here"
          />
          <button class="reset-button" @click="ResetSort">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
          </span>
          </div>
        </div>
      </div>
    </div>
    <div class="table-view__table">
      <table class="table">
        <thead>
          <tr>
            <th @click="Sort('programName')" class="tabel-header">
              <span class="tabel-header__data"  :class="values.currentSort=='programName'?'show-icon':''">
                <p class="tabel-header__title">Program Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('fundType')" class="tabel-header">
              <span class="tabel-header__data"  :class="values.currentSort=='fundType'?'show-icon':''">
                <p class="tabel-header__title">Funding Type</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('initialFundedAmount')" class="tabel-header">
              <span class="tabel-header__data"  :class="values.currentSort=='initialFundedAmount'?'show-icon':''">
                <p class="tabel-header__title">Initial  Fund</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('availableLimit')" class="tabel-header">
              <span class="tabel-header__data"  :class="values.currentSort=='availableLimit'?'show-icon':''">
                <p class="tabel-header__title">Available Limit</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('utilizedAmount')" class="tabel-header">
              <span class="tabel-header__data"  :class="values.currentSort=='utilizedAmount'?'show-icon':''">
                <p class="tabel-header__title">Utilized Amount</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('totalFundedAmount')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='totalFundedAmount'?'show-icon':''">
                <p class="tabel-header__title">Total Funded Amount</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr v-for="item in displayedPosts" :key="item" @click="selectId(item)">
            <td>
              <p v-html="highlightMatches(item['programName'])"></p>
            </td>
            <td>
              <p v-html="highlightMatches(item['fundType'])"></p>
            </td>
            <td>
              <span>$</span> <span v-html="highlightMatches(item['initialFundedAmount'])"></span>
            </td>
            <td>
              <span>$</span> <span v-html="highlightMatches(item['availableLimit'])"></span>
            </td>
            <td>
              <span>$</span> <span v-html="highlightMatches(item['utilizedAmount'])"></span>
            </td>
            <td>
              <span>$</span> <span v-html="highlightMatches(item['totalFundedAmount'])"></span> 
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination d-flex justify-content-between align-items-center flex-wrap">
        <span class="btn--tertiary">
          <BtnTertiary
           v-if="userRoleID !=8"
           :buttonName="`Add New Program`" @click="callAddFundSource()" />
        </span>
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ totalRecords() }} 
              {{ totalRecords() > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnTertiary from "@/components/BtnTertiary.vue";
export default {
  components:{
    BtnTertiary
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type:Number,
      default:10
      },
      TableHeight:{
        type:Number,
      },
      columns: {
      // required: true,
      type: Array,
    }
  },
  data() {
    return {
      filter: "",
      list: this.items,
      unsort:false,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages:[],
        totalPages: 1,
        currentPage:1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
    };
  },
  beforeUpdate() {
    this.list = this.items;
  },
  methods: {
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;this.unsort=true
    },
    highlightMatches(text) {
      const textStr = text.toString();
      const matchExists = textStr.toLowerCase().includes(this.filter);
      if (!matchExists) return textStr;

      const re = new RegExp(this.filter, "ig");
      return textStr.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    callAddFundSource(){
      this.$emit('addFundSourceFromChild')
    },
    selectId(item){
      this.$emit('selectRow', item.fundingSourceID)
    },
    Sort(s) {
      this.unsort = false;
      this.isClearSort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
  setPages () {
			let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.values.pages.push(index);
			}
		},
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.values.currentPage;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.values.totalPages = Math.ceil(posts.length / this.perPage);
      if(this.values.totalPages===0){
        this.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.filteredRows)
        return "";
      return this.filteredRows.length;
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
    setTableHeight(){
      return 'height: ' + this.TableHeight +'vh';
    },
    sortTable() {
      if(!this.list)
        return
      let lists = this.list;
      if(!this.list){
        return 
      }
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      if(!this.sortTable){
        return 
      }
      return this.sortTable.filter((row) => {
        const programName = row.programName.toLowerCase();
        const fundType = row.fundType.toLowerCase();
        const initialFundedAmount = row.initialFundedAmount.toString().toLowerCase();
        const availableLimit = row.availableLimit.toString().toLowerCase();
        const utilizedAmount = row.utilizedAmount.toString().toLowerCase();
        const totalFundedAmount = row.totalFundedAmount.toString().toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            programName.includes(searchTerm) ||
            fundType.includes(searchTerm) ||
            initialFundedAmount.includes(searchTerm) ||
            availableLimit.includes(searchTerm) ||
            utilizedAmount.includes(searchTerm) ||
            totalFundedAmount.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "programName") {
          filtedItems = programName.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "fundType") {
          filtedItems = fundType.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "initialFundedAmount") {
          filtedItems = initialFundedAmount.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "availableLimit") {
          filtedItems = availableLimit.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "utilizedAmount") {
          filtedItems = utilizedAmount.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "totalFundedAmount") {
          filtedItems = totalFundedAmount.includes(searchTerm); 
        }
        return filtedItems;
      });
    },
    displayedPosts () {
			return this.paginate(this.filteredRows);
		},
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  watch: {
		posts () {
			this.setPages();
		}
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
  @include table-style;

  .table-view{
  .table-view__table .table .tbody {
        padding: 0.5rem;
        // height: 32vh;
        height: calc(100vh - 70vh);
        display: block;
        width: 100%;
        overflow: auto;
      }
      
  .btn--tertiary {
      button {
        width: auto;
        padding: 0rem 2rem;
        position: relative;
        margin: 0rem;
        &:before {
          position: absolute;
          left: 0;
          content: "+";
        }
      }
    }
 .search{
    .search-by-filter,.search-by-input{
      @media screen and (max-width: 580px){
      width: 90vw;
      }
    }
  }  
}


</style>
