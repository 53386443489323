<template>
    <div class="questioner ">
        <div class="row m-0 p-0" :id="questionData.questionID">
      <div class="row justify-content-between radio-groups col form-group m-0 p-0 pr-md-5">
        <span class="col-7 p-0 m-0 form-group__question-label">
          <p class="question-text"
          :disabled = "shouldReadOnly"
          :class="questionData.isRequired==true?'required-aster-mark':''"
          >{{questionData.questionText}}</p>
        </span>
        <div v-if="((questionData.response==='')||(questionData.response===null)||(questionData.response===undefined))">
           <Error v-if="((ErrorShow.errorData.find(err=>err.dataId==questionData.questionID)))" >
          <p >
            <small >
              Choose an option
            </small>
          </p>
        </Error> 
        </div>
        <div class="col p-0 m-0 d-flex justify-content-end">
          <div class="d-flex justify-content-center align-items-center form-check" >
          <input
          class="mx-2"
            type="radio"
            :disabled = "shouldReadOnly"
            :name="questionData.questionID+'true'"
            v-model="questionData.response"
            :id="questionData.questionID+'true'"
            :value="'true'"
          />
          <label class=" form-check-label mx-1"
         :for="questionData.questionID+'true'" >
          Yes
        </label>
        </div>
        
        <div class=" form-check">
          <input
          class="mx-2"
            type="radio"
            :disabled = "shouldReadOnly"
            :name="questionData.questionID+'false'"
            v-model="questionData.response"
            :id="questionData.questionID+'false'"
            :value="'false'"
          />
          <label class=" form-check-label mx-1"
        :for="questionData.questionID+'false'">
        No
        </label>

        </div>
        </div>
        
        
      </div>
    </div>
    </div>
</template>
<script>
import Error from "@/components/Error.vue"
export default {
    components:{
    Error,
  },
    data(){
        return{
        }
    },
    props:{
        programQuestion:{},
        shouldReadOnly:{},
        ErrorShow:{}
    },
    computed:{
        questionData: {
      get() {
        return this.programQuestion;
      },
    },
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.questioner{
    .form-group{
    // @include input-style;
    }
    .radio-groups{
        span{
          padding: 0rem;

        }
      display: flex;
      align-items: center;
      .form-check{
        // border: 1px solid red;
        display: flex;
        align-items: center;
        &-label{
          padding: 0rem;
        }
        input[type=checkbox], input[type=radio]{
              height: 1.8rem;
              width: 1.8rem;
          }
      }
      label,.question-text,
      input[type=checkbox], input[type=radio],
      .form-check-label{
        font-size: 1.6rem;
    color: $primary-color-black;
    @media screen and (max-width: 1600px){
        font-size: 1.4rem;
    }
    }
    
    .required-aster-mark{
        &:after {
      content: "*";
      color: $primary-color-red;
    }
    }
  }
}
</style>