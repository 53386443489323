<template> 
<div class="invitation pr-2">
  <div class="invitation--header d-flex justify-content-between flex-wrap align-items-center">
    <span class="note-data">
      <p>
       Please don’t delete <strong>@RecipientFullName</strong>  and <strong>@BusinessName</strong>  while editing the invitation
      </p>
    </span>
    <span class="checkbox-section pr-3 d-flex justify-content-end">
            <label class="checkbox-section__label" for="checkBusinessProfile">
              Default Invitation:
            </label>
            <input
              class="checkbox-section__input"
              type="checkbox"
              v-model="isDefaultInvitation"
              @change="checkBoxChecked()"
              id="checkBusinessProfile"
            />
    </span>
  </div>
  
          
    <QuillEditor class="invitation--body" ref="invitation" 
    theme="snow" 
    :toolbar="toolbarOptions" />
</div>  
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
 
export default { 
   data(){
        return {
          isDefaultInvitation:false,
          DefaultInvitation:``,
            data : 'Hello',
             content: "<p> </p>",
             toolbarOptions : [
                        ['bold', 'italic', 'underline', 'strike','link'],        // toggled buttons
                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme                                       
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                        [{ 'direction': 'rtl' }],                       // text direction
                        [{ 'align': [] }],
                        ['clean']                                         // remove formatting button
                      ]
        };
    },
    computed: {
      editor() {
        return this.$refs.invitation
      },
    },
    components: {
   QuillEditor
  },
  created(){
  },
  mounted() {
    if(this.$store.state.fundSource.fundingSource.fundingSourceID !== undefined){
        this.GetEmailInvitation()
    }    
  },

  methods: {
    async GetEmailInvitation(){
      await this.$store
          .dispatch("GetEmailInvitation", this.$store.state.fundSource.fundingSource.fundingSourceID)
          .then((res)=>{
           // alert(this.$store.state.fundSource.fundingSource.uploadProgramLogo.fileSource);
            this.content = res.programInvitationEmailBody.body;
            this.DefaultInvitation=res.programInvitationEmailBody.defaultTemplate;
            this.editor.setHTML(this.content)
          })
          .catch(()=>{
            this.content = " "
            this.editor.setHTML(this.content)
          })
    },
    async saveInvitationEmail() {   
      this.$store.dispatch("updateLoaderFlag", true);
      //save the data
    this.content =this.editor.getHTML();
     await this.$store
     .dispatch("SaveOrUpdateProgramInvitationEmail", this.content)
          .then((res)=>{
            this.toasterMessage('',res.data.message,'success')
            this.$store.dispatch("updateLoaderFlag", false);
          })
          .catch((err)=>{
            console.log(err)
            this.toasterMessage('','Failed to save','danger')
            this.$store.dispatch("updateLoaderFlag", false);
          })
     
      
    },
    checkBoxChecked() {
      if(this.isDefaultInvitation===true){
      this.editor.setHTML(this.DefaultInvitation)
      }
      else{
        this.editor.setHTML(this.content)
      }
    },
  }  
};
</script>

<style lang="scss" scoped>
.invitation{
  position: relative;
  .note-data{
    p{
      font-size: 1.2rem;
      color: $primary-color-red;
      margin: 1rem 0rem;
    }
  }
  .ql-container{
    min-height:25rem;
  }
  .checkbox-section {

      display: flex;
      align-items: center;
      &__label,
      &__input {
        font-size: 1.6rem;
        margin: 0 0.2rem;
      }
    }
}

</style>
