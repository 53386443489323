<template>
  <div class="businessEntitySection">
    <BasicComponent :heading="getPageHeader()" :subText="texts.subText">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>
      <template v-slot:mainSection>
        <div style="color: rgb(224, 11, 11);">
          <h5 v-if="inputs.canDelete == false && inputs.contactID != 0">
            <b
              >! This contact cannot be deleted as it is actively associated
              with one or more businesses</b
            >
          </h5>
        </div>
        <div class="row m-0 justify-content-between">
          <div class="col-sm-8 px-0 col-12 form-section">
            <div class="form-section__header">
                  <div class="user">
                    <div class="user-info">
                      <div class="row justify-content-between">
                        <div class=" col-12  col-sm-6 form-group">
                          <select
                            class="select-group"
                            id="contact-salutationID"
                            name="contact-salutationID"
                            required
                            placeholder=" "
                            v-model="inputs.salutationID"
                            :disabled="isDisable"
                            :readonly="isDisable"
                          >
                            <option
                              v-for="salutation in salutationList"
                              :key="salutation.salutationID"
                              :value="salutation.salutationID"
                            >
                              {{ salutation.salutationName }}
                            </option>
                          </select>
                          <label
                            class="form__label required"
                            for="contact-salutationID"
                          >
                            {{ labels.title }}
                          </label>
                          <Error v-if="v$.inputs.salutationID.$error">
                            <p
                              v-for="(error, index) of v$.$errors"
                              :key="index"
                            >
                              <small
                                v-if="
                                  error.$propertyPath === 'inputs.salutationID'
                                "
                              >
                                {{ error.$message }}
                              </small>
                            </p>
                          </Error>
                        </div>
                        <div class=" col-12 col-sm-6   form-group">
                          <input
                            type="text"
                            id="contact-firstName"
                            name="contact-firstName"
                            required
                            placeholder=" "
                            v-model="inputs.firstName"
                            :disabled="isDisable"
                            :readonly="isDisable"
                          />
                          <label
                            for="contact-firstName"
                            class="form__label required"
                          >
                            {{ labels.firstName }}
                          </label>
                          <Error v-if="v$.inputs.firstName.$error">
                            <p
                              v-for="(error, index) of v$.$errors"
                              :key="index"
                            >
                              <small
                                v-if="
                                  error.$propertyPath === 'inputs.firstName'
                                "
                              >
                                {{ error.$message }}
                              </small>
                            </p>
                          </Error>
                        </div>
                      </div>
                      <div class="row justify-content-between">
                        <div class="col-12 col-sm-6  form-group">
                          <input
                            type="text"
                            id="contact-middleName"
                            name="contact-middleName"
                            placeholder=" "
                            v-model="inputs.middleName"
                            :disabled="isDisable"
                            :readonly="isDisable"
                          />
                          <label
                            for="contact-middleName"
                            class="form__label required"
                          >
                            {{ labels.middleName }}
                          </label>
                        </div>
                        <div class=" col-12 col-sm-6    form-group">
                          <input
                            type="text"
                            id="contact-lastName"
                            name="contact-lastName"
                            required
                            placeholder=" "
                            v-model="inputs.lastName"
                            :disabled="isDisable"
                            :readonly="isDisable"
                          />
                          <label
                            for="contact-lastName"
                            class="form__label required"
                          >
                            {{ labels.lastName }}
                          </label>
                          <Error v-if="v$.inputs.lastName.$error">
                            <p
                              v-for="(error, index) of v$.$errors"
                              :key="index"
                            >
                              <small
                                v-if="error.$propertyPath === 'inputs.lastName'"
                              >
                                {{ error.$message }}
                              </small>
                            </p>
                          </Error>
                        </div>
                      </div>
                      <div class="row justify-content-between">
                        <div class=" col-12 col-sm-6   form-group">
                          <input
                            type="email"
                            id="contact-emailAddress"
                            name="contact-emailAddress"
                            required
                            placeholder=" "
                            v-model="inputs.emailAddress"
                            :disabled="isDisable"
                            :readonly="isDisable"
                          />
                          <label
                            for="contact-emailAddress"
                            class="form__label required"
                          >
                            {{ labels.emailAddress }}
                          </label>
                          <Error v-if="v$.inputs.emailAddress.$error">
                            <p
                              v-for="(error, index) of v$.$errors"
                              :key="index"
                            >
                              <small
                                v-if="
                                  error.$propertyPath === 'inputs.emailAddress'
                                "
                              >
                                {{ error.$message }}
                              </small>
                            </p>
                          </Error>
                        </div>
                        <div class=" col-12 col-sm-6   form-group">
                          <input
                            required
                            max="12"
                            oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
                            v-model="inputs.phoneNo"
                            placeholder="XXX-XXX-XXXX"
                            type="text"
                            id="contact-phoneNo"
                            name="contact-phoneNo"
                            @keyup="formatePhoneNumberToUSA"
                            @blur="formatePhoneNumberToUSA"
                            :disabled="isDisable"
                            :readonly="isDisable"
                          />
                          <label
                            for="contact-phoneNo"
                            class="form__label required"
                          >
                            {{ labels.phoneNo }}
                          </label>
                          <Error v-if="v$.inputs.phoneNo.$error">
                            <p
                              v-for="(error, index) of v$.$errors"
                              :key="index"
                            >
                              <small
                                v-if="error.$propertyPath === 'inputs.phoneNo'"
                              >
                                Enter Valid Phone Number
                              </small>
                            </p>
                          </Error>
                        </div>
                      </div>
                      <div class="row m-0  px-sm-5 px-2 d-flex justify-content-sm-end justify-content-center">
                        <span class=" mx-2" v-if="userRoleID != 8">
                          <BtnPrimary
                            :buttonName="`Save`"
                            @click="saveUserEntity()"
                          />
                        </span>
                        <span
                          class=" mx-2"
                          v-if="
                            inputs.contactID != 0 &&
                              inputs.canDelete == true &&
                              userRoleID != 8
                          "
                        >
                          <BtnPrimary
                            :buttonName="`Delete`"
                            @click="conformation = true"
                          />
                        </span>
                      </div>
                      <hr />
                    </div>
                  </div>
            </div>
          </div>
          <div class="col-sm-4 col-12 " v-if="businessLength() == 0 && inputs.contactID != 0">
            <h4><b>Associated Business List</b></h4>
            <br />
            <h5>There are no businesses associated with this contact.</h5>
          </div>
          <div class="col-sm-4 col-12 " v-if="businessLength() > 0">
            <h4><b>Associated Business List</b></h4>
            <br />
            <div class="table-view__table" >
            <table class="table">
              <thead>
                <tr>
                  <th>Business Name</th>
                  <th>Role</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody class="tbody">
                <tr
                  v-for="item in inputs.associatedBusinesses"
                  :key="item"
                  @click="selectId(item.businessID)"
                  class="tabel-row"
                >
                  <td>{{ item["businessName"] }}</td>
                  <td>{{ item["businessRole"] }}</td>
                  <td>{{ item["status"] }}</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        </div>
        <div class="central-popup" v-if="conformation == true">
          <div class="central-popup__main p-5">
            <div>
              <span class="central-popup__main__heading">
                <h3 class="mb-5 mt-2">{{ conformationHeader }}</h3>
              </span>
            </div>
            <div></div>
            <div>
              <div class=" d-flex justify-content-end">
                <div class="mr-5">
                  <span class="pop-btn">
                    <BtnSecondary
                      :buttonName="`Cancel`"
                      @click="conformation = false"
                    />
                  </span>
                </div>
                <div class="p-0">
                  <span class="pop-btn">
                    <BtnPrimary :buttonName="`Ok`" @click="deleteContact()" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingMask v-if="isLoading === true" />
      </template>
    </BasicComponent>
  </div>
</template>
<script>
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import textStore from "@/textStore";
import BtnPrimary from "@/components/BtnPrimary.vue";
import BtnSecondary from "@/components/BtnSecondary.vue";
import useVuelidate from "@vuelidate/core";
import Error from "@/components/Error.vue";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  components: {
    BtnPrimary,
    BtnSecondary,
    Error,
    BasicComponent,
    BreadCrumb,
  },
  setup() {
    return {
      textStore,
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputs: {
        salutationID: { required },
        firstName: { required },
        lastName: { required },
        emailAddress: { required, email },
        phoneNo: { required, minLength: minLength(12) },
      },
    };
  },
  props: {
    index: {},
    userData: {},
    contactID: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.getMasterData();
  },
  data() {
    return {
      conformation: false,
      conformationHeader: "Are you sure, you want to delete ?",
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
      salutationList: [],
      texts: {
        heading: " ",
      },
      crumbs: ["Business Contacts", "Contact"],
    };
  },
  mounted() {
    if (this.contactID > 0) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("getContacts", this.contactID)
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    }
  },
  methods: {
    businessLength() {
      if (!this.inputs.associatedBusinesses) return;
      let abl = this.inputs.associatedBusinesses.length;
      return abl;
    },
    selected(crumb) {
      if (crumb == "Business Contacts") {
        this.$router.push("/businessContacts");
      }
    },

    selectId(businessID) {
      if (businessID > 0) {
        this.$router.push("/programinvitation/form/" + businessID+"/"+0);
      }
    },
    getPageHeader() {
      let contactFirstName = this.$store.state.business.users.roles.firstName;
      if (contactFirstName === undefined || contactFirstName === "")
        this.texts.heading = "New Business Contact";
      else this.texts.heading = contactFirstName;
      return this.texts.heading;
    },
    formatePhoneNumberToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      let phoneNumber = this.inputs.phoneNo.replace(/[^\d,]/g, "").slice(0, 10);

      this.inputs.phoneNo = phoneNumber.replace(
        /(\d{1,3})(\d{1,3})(\d{1,4})/g,
        "$1-$2-$3"
      );
    },

    getMasterData() {
      this.$store
        .dispatch("getmasterData")
        .then((res) => {
          this.affiliateList = res.data.affiliateList;
          this.salutationList = res.data.salutationList;
          this.roleList = res.data.roleList.filter(
            (a) => a.roleID != 2 && a.roleID != 7
          );
        })
        .catch((err) => {
          Promise.reject(err);
        });
    },
    async saveUserEntity() {
      const isFormCorrect = await this.v$.$validate();
      let param = {
        contactID:
          isNaN(Number(this.inputs.contactID)) == true
            ? 0
            : Number(this.inputs.contactID),
        businessRoleID: 0,
        isActive: true,
        salutationID: Number(this.inputs.salutationID),
        firstName: this.inputs.firstName,
        middleName: this.inputs.middleName,
        lastName: this.inputs.lastName,
        emailAddress: this.inputs.emailAddress,
        phoneNo: this.inputs.phoneNo,
        businessID: 0,
        accountStatusID: 1,
        userRoles: [2],
        associatedBusinesses: [],
      };

      this.$store;
      if (isFormCorrect) {
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("SaveBusinessContact", param)
          .then((res) => {
            if (res.data.isSuccess) {
              this.toasterMessage("", res.data.message, "success");
              this.$router.push("/businessContacts");
              this.$store.dispatch("updateLoaderFlag", false);
            } else {
              this.$store.dispatch("updateLoaderFlag", false);
              if (
                (res.data.validationErrors != null ||
                  res.data.validationErrors != undefined) &&
                res.data.validationErrors.length > 0
              ) {
                res.data.validationErrors.forEach((element) => {
                  this.toasterMessage("", element, "danger");
                });
              } else {
                this.toasterMessage("", "Failed to save contact", "danger");
              }
            }
            return Promise.resolve(true);
          })
          .catch(() => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", "Error while processing request", "danger");
            return Promise.resolve(false);
          });
      } else {
        return Promise.resolve(false);
      }
    },

    async deleteContact() {
      const contactId = this.inputs.contactID;
      this.$store;
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("DeleteBusinessAndNulContact", contactId)
        .then((res) => {
          if (res.data.isSuccess) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", res.data.message, "success");

            this.$router.push("/businessContacts");
          } else {
            this.$store.dispatch("updateLoaderFlag", false);
            if (
              (res.data.validationErrors != null ||
                res.data.validationErrors != undefined) &&
              res.data.validationErrors.length > 0
            ) {
              res.data.validationErrors.forEach((element) => {
                this.toasterMessage("", element, "danger");
              });
            } else {
              this.toasterMessage("", "Failed to delete contact", "danger");
            }
          }
          return Promise.resolve(true);
        })
        .catch(() => {
          this.$store.dispatch("updateLoaderFlag", false);
          this.toasterMessage("", "Error while processing request", "danger");
          return Promise.resolve(false);
        });
    },
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID;
    },
    isDisable: {
      get() {
        if (this.userRoleID == 8) {
          return true;
        } else {
          return false;
        }
      },
    },

    inputs: {
      get() {
        if (this.$store.state.business.businessContactData.contactID === null)
          return this.$store.state.business.businessContactData;
        else return this.$store.state.business.users.roles;
      },
    },
    labels: {
      get() {
        return textStore.businessEntityLabel.users;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.central-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    z-index: 9999;
    padding: 2rem;
    // min-height: 40vh;
    width: auto;
    // max-width: 50%;
    border: 1px solid gray;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pop-btn {
      button {
        padding: 0 2rem;
        min-width: auto;
      }
    }
    &__heading {
      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        // text-transform: capitalize;
        color: $primary-color-red;
      }
    }
  }
}
.table{
    .tbody{
      height: 60vh;
        display: block;
        width: 100%;
        overflow: auto;
    }
    thead,tbody tr {
    border: none;
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .tabel-row {
  cursor: pointer;
  &:hover,
  &:active {
    box-shadow: 0.1rem 0.1rem 0.5rem $primary-color-grey;
    border-radius: 0.3rem 0.3rem 0rem 0rem;
  }
  
}
}

.user {
  height: 100%;
  // width: 800px;
  overflow-y: auto;
  padding-top: 2rem;
  position: relative;
  .status--continer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 0.2rem 0.5rem;
    .user-active {
      &::before {
        content: "\00a0 ";
        height: 0.8rem;
        width: 0.8rem;
        background-color: $color-green;
        margin: 0rem 0.5rem;
        border-radius: 50% 50%;
        display: inline-block;
      }
    }

    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem;
      margin: 0rem;
      font: normal normal normal 0.8rem;
      letter-spacing: 0px;
      color: $primary-color-black;
      text-transform: capitalize;
    }
  }
  .row {
    padding-right: 2%;

    margin: 0;
  }
  .custom-switch {
    input {
      position: relative;
      display: none;
    }

    p {
      display: inline-block;
      padding: 0.2rem;
      margin: 0;
    }
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .custom-control-label {
      padding: 0rem 0rem 0rem 5rem;
    }
    .custom-control-label::before {
      position: relative;
      width: 3.4rem;
      height: 1.6rem;
      pointer-events: all;
      border-radius: 1.5rem;
    }
    .custom-control-label::after {
      position: relative;
      left: calc(-3.25rem + 2px);
      height: 2rem;
      width: 2rem;
      background-color: #adb5bd;
      margin-top: -19px;
      border-radius: 3.5rem;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      position: relative;
      color: #fff;
      border-color: #71a50c;
      background-color: #71a50c;
      opacity: 0.5;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      position: relative;
      background-color: #71a50c;
      margin-top: -19px;
      height: 2rem;
      width: 2rem;
      border-radius: 1rem;
      transform: translateX(2.5rem);
    }
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>
