<template>
  <div class="remove-fund">
    <div class="remove-fund__form">
      <div class="row m-0 justify-content-between">
        <div class="col-sm-4 col-12 form-group">
          <input
            required
            type="text"
            placeholder=" "
            v-money3="config"
            maxlength="22"
            v-model.lazy="inputs.transactionAmount"
            id="addFund-transactionAmount"
            name="addFund-transactionAmount"
          />
          <label
            for="addFund-transactionAmount"
            class="form__label required"
          >
            {{ labels.Amount }}
          </label>
          <Error v-if="v$.inputs.transactionAmount.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.transactionAmount'">
                {{ error.$message }}
              </small>
            </p>
          </Error>
        </div>
        <div class="col-sm-4 col-12 form-group">
          <flat-pickr 
          :config="Dateconfig"
          required
          v-model="inputs.dateOfFunding"
          placeholder=" "
            id="addFund-dateOfFunding"
            name="addFund-dateOfFunding"
          class="datepicker" >
          </flat-pickr>
          <label
            for="addFund-dateOfFunding"
            class="form__label required"
          >
            {{ labels.dateOfFunding }}
          </label>
          <Error v-if="v$.inputs.dateOfFunding.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.dateOfFunding'">
                {{ error.$message }}
              </small>
            </p>
          </Error> 
        </div>
        <div class="  col-sm-4 col-12 form-group">
          <input
            type="text"
            placeholder=" "
            @input="numericValue($event)"
            @keyup="numericValue($event)"
            @blur="numericValue($event)"
            v-model="inputs.originatingBankAccount"
            id="addFund-originatingBankAccount"
            name="addFund-originatingBankAccount"
          />
          <label
            for="addFund-originatingBankAccount"
            class="form__label"
          >
            {{ labels.originatingBankAccount }}
          </label>
        </div>
        <div class="col-sm-4 col-12"></div>
      </div>
      <div class="row m-0">
        <div class="col form-group">
          <input
            type="text"
            required
            placeholder=" "
            v-model="inputs.comment"
            id="addFund-comment"
            name="addFund-comment"
          />
          <label
            for="addFund-comment"
            class="form__label required"
          >
            {{ labels.notes }}
          </label>
          <Error v-if="v$.inputs.comment.$error">
            <p v-for="(error, index) of v$.$errors" :key="index">
              <small v-if="error.$propertyPath === 'inputs.comment'">
                {{ error.$message }}
              </small>
            </p>
          </Error>          
        </div>
      </div>
      <div class="form-file row m-0 justify-content-between">
        <label
          
          class="form__label form-file__label required col-4 p-0"
          >{{ labels.uploremoveocument }}
        </label>
        <input
          required
          ref="fileInput"
          class="form__label form-file__label required col-3 "
          type="file"
          name="documents-uploadRemoveDocument"
          id="documents-uploadRemoveDocument"
          v-bind="inputs.transactionDocument.documentTypeID"
          @input="inputs.transactionDocument"
          @change="showPreview($event, inputs.transactionDocument)"
        />
        <a
          :href="inputs.transactionDocument.fileName"
          v-text="inputs.transactionDocument.fileName"
          class="downloadLink px-1 col"
          @click.prevent="download(inputs.transactionDocument)"
        />
      </div>
       <div
        class="documents__instrction-para"
        style="
               font-size: 1.6rem;"
      >
        <label
          for="documents-uploadRemoveDocument"
          class="form__label form-file__label required col-4 p-0"
          >{{ labels.uploadRemoveInstrctionPara }}</label
        >
      </div>
    </div>
    <hr />
    <div class="remove-fund__footer d-flex justify-content-end">
      <div class="btn">
        <span class="mr-3">
          <BtnSecondary buttonName="Cancel" @click="cancel()" />
        </span>
        <span>
          <BtnPrimary buttonName="save" @click="saveRemoveFund()" />
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import textStore from "@/textStore.js";
import BtnSecondary from "@/components/BtnSecondary.vue";
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
import Error from "@/components/Error.vue";
import { required } from "@vuelidate/validators";
import { Money3Directive } from "v-money3";
export default {
  directives: {
    money3: Money3Directive,
  },
  setup() {
    return {
      v$: useVuelidate(),
      textStore,
      BtnPrimary,
    };
  },
  data() {
    return {
      labels: {
        Amount: "Amount",
        dateOfFunding: "Date Of Transaction",
        originatingBankAccount: "Originating Bank Account",
        notes: "Notes",
        uploremoveocument: "Upload Document",
         uploadRemoveInstrctionPara: "Max file size is 25MB"
      },
      Dateconfig: {
        dateFormat: "m/d/Y",
        disableMobile: "true"
      },
    };
  },
  validations() {
    return {
      inputs: {
        transactionAmount: { required },
        comment: { required },
        dateOfFunding: { required },
      },
    };
  },
  computed: {
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        disableNegative: true,
        masked: false /* doesn't work with directive */,
      };
    },
    inputs: {
      get() {
        return this.$store.state.fundSource.removeFund;
      },
    },
  },
  components: {
    BtnPrimary,
    BtnSecondary,
    Error,
    flatPickr,
  },

  methods: {
    numericValue(event) {
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.replace(/[^0-9]/g, "");
      this.inputs.originatingBankAccount = value;
      return event.target.value;
    },
    async saveRemoveFund() {
      const isFormCorrect = await this.v$.$validate();
      this.inputs.fundingSourceID = this.$store.state.fundSource.fundingSource.fundingSourceID;
      this.inputs.transactionAmount = Number(
        String(this.inputs.transactionAmount).replace(/[^\d]/g, "")
      );
      if (isFormCorrect) {
        if (this.inputs.transactionAmount <= 0) {
          this.toasterMessage("", " Grant Amount should not be $0", "danger");
        } else {
          this.$store.dispatch("updateLoaderFlag", true);
          this.$store
            .dispatch("RemoveFunds", this.inputs)
            .then((res) => {
              if (res.data.isSuccess) {
                this.$store.dispatch("updateLoaderFlag", false);
                this.$emit("onSave", this.inputs.fundingSourceID);
                this.toasterMessage("", res.data.message, "success");
              } else {
                if (
                  (res.data.validationErrors != null ||
                    res.data.validationErrors != undefined) &&
                  res.data.validationErrors.length > 0
                ) {
                  this.$store.dispatch("updateLoaderFlag", false);
                  res.data.validationErrors.forEach((element) => {
                    this.toasterMessage("", element, "danger");
                  });
                } else {
                  this.$store.dispatch("updateLoaderFlag", false);
                  this.toasterMessage("", res.data.message, "danger");
                }
              }
              return Promise.resolve(true);
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              this.toasterMessage(
                "",
                "Error while processing request",
                "danger"
              );
              Promise.reject(err);
              return Promise.resolve(false);
            });
        }
      } else {
        return Promise.resolve(false);
      }
    },
    showPreview(e, item) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.newFiles = e.target.files;
      var data = {
        file: this.newFiles[0],
        documentTypeID: item.documentTypeID,
      };
      if (this.newFiles.length) {

        //Max file size < 25mb
        if(data.file.size > 26214400)
         {
            this.toasterMessage('','Max file size should be < 25MB','danger');
            return;
         }
         
        var filename = data.file.name.toLowerCase();
        var fileExt = filename.split('.').pop();
        if(fileExt=="pl" || fileExt=="bat" || fileExt=="ps" || fileExt=="php" || fileExt=="aspx" || fileExt=="cshtml" || fileExt=="vbs" || fileExt=="js" || fileExt=="py" || fileExt=="java" || fileExt=="cs" || fileExt=="json" || fileExt=="pyd" || fileExt=="pyc" || fileExt=="pyo" || fileExt=="pyw" || fileExt=="pm" || fileExt=="t" || fileExt=="pod"  )
        {
          this.toasterMessage('','Unsupported file format','danger');
          return;
        }

        //upload files
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("UploadFundingFile", data)
          .then((res) => {
          if (res.status===200) {
            this.inputs.transactionDocument.fileName = data.file.name;
            this.inputs.transactionDocument.documentTypeID = 8;
            this.inputs.transactionDocument.documentGUID = res.data.documentID;
            this.inputs.transactionDocument.physicalFileStorageKey =
              res.data.storageKey;
            this.inputs.transactionDocument.fileSize = res.data.fileSize;
            this.inputs.transactionDocument.documentName =
              "Fund Transaction Document";
            const fileSelected = this.$refs.fileInput;
            fileSelected.type = "text";
            fileSelected.type = "file";
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage('','File uploaded Successfully','success')
          }
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage('','Unsupported file','danger')
            Promise.reject(err);
          });
      }
    },
    download(item) {
      this.$store.dispatch("updateLoaderFlag", true);
      var data = {
        physicalFileStorageKey: item.physicalFileStorageKey,
        fileName: item.fileName,
      };
      this.$store
        .dispatch("Download", data)
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    cancel() {
      this.$emit("onCancel");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.remove-fund {
  height: 50vh;
  overflow-y: auto;
  padding-top: 2rem;
  padding-right: 8%;

  .row {
    // padding-right:  8%;
    margin: 0;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
  .form-file {
   
    label,input  {
      margin: 0;
      font-size: 1.6rem;
      color: $primary-color-black;
    }
  }
  .btn {
    button {
      width: auto;
    }
  }
  .downloadLink{
  font-size: 1.6rem;
  word-wrap: break-word;
  max-width: 25rem;
  width: auto;
}
}
</style>
