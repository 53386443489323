let rootApi=process.env.VUE_APP_ROOTAPI

let optionAxios = {
    headers: {
        Accept:"appliction/json",
        'Content-Type': 'application/x-www-form-urlencoded/json',
        'Access-Control-Allow-Origin': '*',
        Authorization:localStorage.getItem('firstname') && localStorage.getItem('token')
    }
}
export default{
    rootApi,
    optionAxios
}