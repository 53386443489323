<template>
  <div class="businessEntitySection">
    <BasicComponent :heading="getPageHeader()" :subText="texts.subText">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>
      <template v-slot:mainSection>
        <div class="form-section">
          <div class="form-section__header">
            <nav>
              <ul>
                <div class="user">
                  <div class="user-info">
                    <div class="row m-0 justify-content-between">
                      <div class=" col-sm-4 col-12 form-group">
                        <select
                          class="select-group"
                          id="contact-roleID"
                          name="contact-roleID"
                          placeholder=" "
                          required
                          v-model="inputs.roleID"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        >
                          <!-- @change="selectOption($event)" -->
                          <option
                            v-for="role in roleList"
                            :key="role.roleID"
                            :value="role.roleID"
                          >
                            {{ role.roleDescription }}
                          </option>
                        </select>
                        <label
                          class="form__label required"
                          for="contact-roleID"
                        >
                          {{ labels.role }}
                        </label>
                        <Error v-if="v$.inputs.roleID.$error">
                          <p v-for="(error, index) of v$.$errors" :key="index">
                            <small
                              v-if="error.$propertyPath === 'inputs.roleID'"
                            >
                              {{ error.$message }}
                            </small>
                          </p>
                        </Error>
                      </div>
                      <div
                        class="col-sm-3 col-12 pr-0 py-0 mb-3 custom-control custom-switch"
                      >
                        <span>
                          <p class="para-info">{{ labels.status }}</p>
                        </span>
                        <input
                          type="checkbox"
                          class="custom-control-input statusSwitch"
                          id="isActiveValue"
                          v-model="inputs.isActive"
                          true-value="true"
                          false-value="false"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        />
                        <label
                          class="custom-control-label"
                          for="isActiveValue"
                        ></label>
                        <span>
                          <p class="para-info" v-if="inputs.isActive == 'true'">
                            {{ labels.active }}
                          </p>
                          <p
                            class="para-info"
                            v-if="inputs.isActive == 'false'"
                          >
                            {{ labels.inactive }}
                          </p>
                        </span>
                      </div>
                      <div class="col-sm-4 "></div>
                    </div>
                    <div class="row justify-content-between">
                      <div class=" col-sm-4   form-group">
                        <select
                          required
                          class="select-group"
                          id="contact-salutationID"
                          name="contact-salutationID"
                          placeholder=" "
                          v-model="inputs.salutationID"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        >
                          <option
                            v-for="salutation in salutationList"
                            :key="salutation.salutationID"
                            :value="salutation.salutationID"
                          >
                            {{ salutation.salutationName }}
                          </option>
                        </select>
                        <label
                          class="form__label required"
                          for="contact-salutationID"
                        >
                          {{ labels.title }}
                        </label>
                        <Error v-if="v$.inputs.salutationID.$error">
                          <p v-for="(error, index) of v$.$errors" :key="index">
                            <small
                              v-if="
                                error.$propertyPath === 'inputs.salutationID'
                              "
                            >
                              {{ error.$message }}
                            </small>
                          </p>
                        </Error>
                      </div>

                      <div class=" col-sm-4   form-group">
                        <input
                          type="text"
                          id="contact-firstName"
                          name="contact-firstName"
                          required
                          placeholder=" "
                          v-model="inputs.firstName"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        />
                        <label
                          for="contact-firstName"
                          class="form__label required"
                        >
                          {{ labels.firstName }}
                        </label>
                        <Error v-if="v$.inputs.firstName.$error">
                          <p v-for="(error, index) of v$.$errors" :key="index">
                            <small
                              v-if="error.$propertyPath === 'inputs.firstName'"
                            >
                              {{ error.$message }}
                            </small>
                          </p>
                        </Error>
                      </div>
                      <div class="col-sm-4  form-group">
                        <input
                          type="text"
                          id="contact-middleName"
                          name="contact-middleName"
                          placeholder=" "
                          v-model="inputs.middleName"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        />
                        <label
                          for="contact-middleName"
                          class="form__label required"
                        >
                          {{ labels.middleName }}
                        </label>
                      </div>
                    </div>
                    <div class="row justify-content-between">
                      <div class=" col-sm-4   form-group">
                        <input
                          type="text"
                          id="contact-lastName"
                          name="contact-lastName"
                          required
                          placeholder=" "
                          v-model="inputs.lastName"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        />
                        <label
                          for="contact-lastName"
                          class="form__label required"
                        >
                          {{ labels.lastName }}
                        </label>
                        <Error v-if="v$.inputs.lastName.$error">
                          <p v-for="(error, index) of v$.$errors" :key="index">
                            <small
                              v-if="error.$propertyPath === 'inputs.lastName'"
                            >
                              {{ error.$message }}
                            </small>
                          </p>
                        </Error>
                      </div>
                      <div class=" col-sm-4   form-group">
                        <input
                          type="email"
                          id="contact-emailAddress"
                          name="contact-emailAddress"
                          required
                          placeholder=" "
                          v-model="inputs.emailAddress"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        />
                        <label
                          for="contact-emailAddress"
                          class="form__label required"
                        >
                          {{ labels.emailAddress }}
                        </label>
                        <Error v-if="v$.inputs.emailAddress.$error">
                          <p v-for="(error, index) of v$.$errors" :key="index">
                            <small
                              v-if="
                                error.$propertyPath === 'inputs.emailAddress'
                              "
                            >
                              {{ error.$message }}
                            </small>
                          </p>
                        </Error>
                      </div>
                      <div class=" col-sm-4   form-group">
                        <input
                          required
                          max="12"
                          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
                          v-model="inputs.phoneNo"
                          placeholder="XXX-XXX-XXXX"
                          type="text"
                          id="contact-phoneNo"
                          name="contact-phoneNo"
                          @keyup="formatePhoneNumberToUSA"
                          @blur="formatePhoneNumberToUSA"
                          :disabled="isDisable"
                          :readonly="isDisable"
                        />
                        <label
                          for="contact-phoneNo"
                          class="form__label required"
                        >
                          {{ labels.phoneNo }}
                        </label>
                        <Error v-if="v$.inputs.phoneNo.$error">
                          <p v-for="(error, index) of v$.$errors" :key="index">
                            <small
                              v-if="error.$propertyPath === 'inputs.phoneNo'"
                            >
                              Enter Valid Phone Number
                            </small>
                          </p>
                        </Error>
                      </div>
                    </div>
                    <div
                      class="row  m-0 justify-content-between "
                      v-if="userRoleID == 1 || userRoleID == 7"
                    >
                      <div class="col p-0 ">
                        <div class="multiselect ">
                          <div
                            class="multiselect__selectBox "
                            @click="showCheckboxes()"
                          >
                            <label class="multiselect__selectBox__label" for=""
                              >Program Name</label>
                            <span
                              class="multiselect__selectBox__show-selectedBox "
                            >
                              <span
                                class="multiselect__selectBox__show-selectedBox__display"
                                v-for="CheckedData in showCheckedData"
                                v-bind:key="CheckedData.programId"
                              >
                                <span class="CheckedData">{{
                                  CheckedData.programName
                                }}</span>
                              </span>
                            </span>
                            <button class="arrow-buttons" >
                              <img
                              class="arrow-buttons__img"
                                src="@/assets/imgs/arrow-up.png"
                                alt="up arrow"
                              />
                              <img
                              class="arrow-buttons__img"
                                src="@/assets/imgs/arrow-down.png"
                                alt="down arrow"
                              />
                              
                            </button>
                          </div>
                          <!-- {{testData}} -->
                          <div class="checkboxes" v-if="isShowCheckboxesOption">
                            <div
                              v-for="program in testData"
                              :key="program.programId"
                              class="checkbox-div"
                              :class="{
                                'checkbox-checked': showCheckedData.includes(
                                  program
                                ),
                              }"
                            >
                              <input
                                class="checkboxes__input p-0 m-0"
                                type="checkbox"
                                :id="program.programId"
                                :value="program"
                                @change="onChanceOfProgramData($event, program)"
                                v-model="showCheckedData"
                              />
                              <label
                                class="checkboxes__label p-0 m-0 pl-2"
                                :for="program.programId"
                              >
                                {{ program.programName }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row d-flex justify-content-sm-end justify-content-center"
                    >
                      <span class=" mx-2" v-if="userRoleID != 8">
                        <BtnPrimary
                          :buttonName="`Save`"
                          @click="saveUserEntity()"
                        />
                      </span>
                      <span
                        class=" ml-sm-5"
                        v-if="
                          inputs.contactID != 0 &&
                            inputs.canDelete == true &&
                            userRoleID != 8
                        "
                      >
                        <BtnPrimary
                          :buttonName="`Delete`"
                          @click="conformation = true"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </ul>
            </nav>
          </div>
        </div>

        <div class="central-popup" v-if="conformation == true">
          <div class="central-popup__main p-5">
            <div>
              <span class="central-popup__main__heading">
                <h3 class="mb-5 mt-2">{{ conformationHeader }}</h3>
              </span>
            </div>
            <div>
              <div class=" d-flex justify-content-end">
                <div class="mr-5">
                  <span class="pop-btn">
                    <BtnSecondary
                      :buttonName="`Cancel`"
                      @click="conformation = false"
                    />
                  </span>
                </div>
                <div class="p-0">
                  <span class="pop-btn">
                    <BtnPrimary :buttonName="`Ok`" @click="deleteContact()" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoadingMask v-if="isLoading === true" />
      </template>
    </BasicComponent>
  </div>
</template>
<script>
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import textStore from "@/textStore";
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
import Error from "@/components/Error.vue";
import { required, email, minLength } from "@vuelidate/validators";
import BtnSecondary from "@/components/BtnSecondary.vue";

export default {
  components: {
    BtnPrimary,
    Error,
    BasicComponent,
    BreadCrumb,
    BtnSecondary,
  },
  setup() {
    return {
      textStore,
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputs: {
        roleID: { required },
        salutationID: { required },
        firstName: { required },
        lastName: { required },
        emailAddress: { required, email },
        phoneNo: { required, minLength: minLength(12) },
      },
    };
  },
  props: {
    index: {},
    userData: {},
    contactID: {
      type: Number,
      default: 0,
    },
  },
  created() {
    this.getMasterData()
    if (this.userRoleID == 1 || this.userRoleID == 7) {
      this.GetAllProgramsFromFundingSource();
    }
    this.GetContactData();
  },
  data() {
    return {
      testData: [],
      showCheckedData: [],
      isShowCheckboxesOption: false,
      expanded: false,
      AllProgramsFromFundingSource: [],
      conformation: false,
      programList: [0],
      conformationHeader: "Are you sure, you want to delete ?",
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
      salutationList: [],
      texts: {
        heading: " ",
        // subText: "Not sure about the description - need more information",
      },
      crumbs: ["NUL Contacts", "Contact"],
    };
  },
  methods: {
    onChanceOfProgramData(e, program) {
      //  if    current checkbox is all remove other from showCheckedData
      if (program.programId == 0 && e.target.checked == true) {
        this.showCheckedData = [{ programId: 0, programName: "All" }];
      }
      //else
      // check isthere programId === 0 , then remove it .
      else {
        let AllValueindex = this.showCheckedData.findIndex(
          (x) => x.programId == "0"
        );
        if (AllValueindex >= 0) {
          this.showCheckedData.splice(AllValueindex, 1);
        }
      }
    },
    showCheckboxes() {
      this.isShowCheckboxesOption = !this.isShowCheckboxesOption;
    },

    businessLength() {
      if (!this.inputs.associatedBusinesses) return;
      let abl = this.inputs.associatedBusinesses.length;
      return abl;
    },
    selected(crumb) {
      if (crumb == "NUL Contacts") {
        this.$router.push("/contacts");
      }
    },
    getPageHeader() {
      let contactFirstName = this.$store.state.business.users.roles.firstName;
      if (contactFirstName === undefined || contactFirstName === "")
        this.texts.heading = "New Contact";
      else this.texts.heading = contactFirstName;
      return this.texts.heading;
    },
    formatePhoneNumberToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      let phoneNumber = this.inputs.phoneNo.replace(/[^\d,]/g, "").slice(0, 10);

      // Format display value based on calculated currencyValue
      this.inputs.phoneNo = phoneNumber.replace(
        /(\d{1,3})(\d{1,3})(\d{1,4})/g,
        "$1-$2-$3"
      );
    },
    isActive() {
      return (this.inputs.isActive = this.toggleValue);
    },
    GetAllProgramsFromFundingSource() {
      this.$store
        .dispatch("GetAllProgramsFromFundingSource")
        .then((res) => {
          const data = res;
          this.testData = data;
          this.testData.unshift({ programId: 0, programName: "All" });
        })
        .catch((err) => {
          Promise.reject(err);
        });
    },
    updateSelectedDAta(programInvitations){
      this.$store.dispatch("updateLoaderFlag", true);

       this.programList = [0];
      let programID = [];
      if (programInvitations == null) {
            this.programList = [0];
            this.showCheckedData = [{ programId: 0, programName: "All" }];
          } 
          else {
            this.AllProgramsFromFundingSource = programInvitations;
            for (let i = 0; i < this.AllProgramsFromFundingSource.length; i++) {
              programID.push(programInvitations[i].programID);
            }
            let result1 = this.testData;
            let result2 = programInvitations;

            let result = result1.filter(function(o1) {
              return result2.some(function(o2) {
                return o1.programId === o2.programID; // return the ones with equal id
              });
            });
            this.showCheckedData = result;
            this.programList = programID;
          }
          this.$store.dispatch("updateLoaderFlag", false);
    },
    async GetContactData(){
      this.$store.dispatch("updateLoaderFlag", true);
      await this.$store
        .dispatch("getContacts", this.contactID)
        .then((res) => {
          setTimeout(() => {
            this.updateSelectedDAta(res.data.programInvitations)
          }, 100);

          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    getMasterData() {
      this.$store
        .dispatch("getmasterData")
        .then((res) => {
          this.affiliateList = res.data.affiliateList;
          this.salutationList = res.data.salutationList;
          this.roleList = res.data.roleList.filter(
            (a) => a.roleID != 2 && a.roleID != 7
          );
        })
        .catch((err) => {
          Promise.reject(err);
        });
    },
    async saveUserEntity() {
      let programInvitations = [];
      // let data = this.showCheckedData
      
      programInvitations = this.showCheckedData.map((item) => {
        return item.programId;
      });
      
      if(this.userRoleID == 1 || this.userRoleID == 7){
        if(programInvitations.length<=0){
         programInvitations= [0]
        }
      }
      const isFormCorrect = await this.v$.$validate();
      let param = {
        contactID:
          isNaN(Number(this.inputs.contactID)) == true
            ? 0
            : Number(this.inputs.contactID),
        businessRoleID: 0,
        isActive: this.inputs.isActive,
        salutationID: Number(this.inputs.salutationID),
        firstName: this.inputs.firstName,
        middleName: this.inputs.middleName,
        lastName: this.inputs.lastName,
        emailAddress: this.inputs.emailAddress,
        phoneNo: this.inputs.phoneNo,
        businessID: 0,
        accountStatusID: 1,
        userRoles: [this.inputs.roleID],
        associatedBusinesses: [],
        programInvitations: programInvitations,
      };
      this.$store;
      if (isFormCorrect) {
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("SaveUserEntity", param)
          .then((res) => {
            if (res.data.isSuccess) {
              this.$store.dispatch("updateLoaderFlag", false);
              this.toasterMessage("", res.data.message, "success");

              this.$router.push("/contacts");
            } else {
              this.$store.dispatch("updateLoaderFlag", false);
              if (
                (res.data.validationErrors != null ||
                  res.data.validationErrors != undefined) &&
                res.data.validationErrors.length > 0
              ) {
                res.data.validationErrors.forEach((element) => {
                  this.toasterMessage("", element, "danger");
                });
              } else {
                this.toasterMessage("", "Failed to save contact", "danger");
              }
            }
            return Promise.resolve(true);
          })
          .catch(() => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", "Error while processing request", "danger");
            return Promise.resolve(false);
          });
      } else {
        return Promise.resolve(false);
      }
    },
    async deleteContact() {
      const contactId = this.inputs.contactID;
      this.$store;
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("DeleteBusinessAndNulContact", contactId)
        .then((res) => {
          if (res.data.isSuccess) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", res.data.message, "success");

            this.$router.push("/contacts");
          } else {
            this.$store.dispatch("updateLoaderFlag", false);
            if (
              (res.data.validationErrors != null ||
                res.data.validationErrors != undefined) &&
              res.data.validationErrors.length > 0
            ) {
              res.data.validationErrors.forEach((element) => {
                this.toasterMessage("", element, "danger");
              });
            } else {
              this.toasterMessage("", "Failed to delete contact", "danger");
            }
          }
          return Promise.resolve(true);
        })
        .catch(() => {
          this.$store.dispatch("updateLoaderFlag", false);
          this.toasterMessage("", "Error while processing request", "danger");
          return Promise.resolve(false);
        });
    },
  },
  mounted() {},
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    toggleValue: {
      get() {
        return this.$store.state.business.users.roles.isActive;
      },
    },
    inputs: {
      get() {
        return this.$store.state.business.users.roles;
      },
    },
    labels: {
      get() {
        return textStore.businessEntityLabel.users;
      },
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID;
    },
    isDisable: {
      get() {
        if (this.userRoleID == 8) {
          return true;
        } else {
          return false;
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_mixins.scss";

.central-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    z-index: 9999;
    padding: 2rem;
    // min-height: 40vh;
    width: auto;
    // max-width: 50%;
    border: 1px solid gray;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pop-btn {
      button {
        padding: 0 2rem;
        min-width: auto;
      }
    }
    &__heading {
      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        // text-transform: capitalize;
        color: $primary-color-red;
      }
    }
  }
}
.user {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  position: relative;
  .status--continer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: auto;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 0.2rem 0.5rem;
    .user-active {
      &::before {
        content: "\00a0 ";
        height: 0.8rem;
        width: 0.8rem;
        background-color: $color-green;
        margin: 0rem 0.5rem;
        border-radius: 50% 50%;
        display: inline-block;
      }
    }
    .user-inactive {
      &::before {
        content: "\00a0 ";
        height: 0.8rem;
        width: 0.8rem;
        background-color: $primary-color-red;
        margin: 0rem 0.5rem;
        border-radius: 50% 50%;
        display: inline-block;
      }
    }
    &__body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0rem;
      margin: 0rem;
      font: normal normal normal 0.8rem;
      letter-spacing: 0px;
      color: $primary-color-black;
      text-transform: capitalize;
    }
  }
  .row {
    padding-right: 2%;

    margin: 0;
  }
  .custom-switch {
    input {
      position: relative;
      display: none;
    }
    // border: 1px solid red;
    p {
      display: inline-block;
      padding: 0.2rem;
      margin: 0;
    }
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .custom-control-label {
      padding: 0rem 0rem 0rem 5rem;
    }
    .custom-control-label::before {
      position: relative;
      // left: 2rem;
      width: 3.4rem;
      height: 1.6rem;
      pointer-events: all;
      border-radius: 1.5rem;
    }
    .custom-control-label::after {
      position: relative;
      //  top: 0.5px ;
      // left: calc(1.75rem + 2px);
      // left: calc(-2.5rem + 2px);
      left: calc(-3.25rem + 2px);
      height: 2rem;
      width: 2rem;
      background-color: #adb5bd;
      margin-top: -19px;
      border-radius: 3.5rem;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      position: relative;
      color: #fff;
      border-color: #71a50c;
      background-color: #71a50c;
      opacity: 0.5;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
      position: relative;
      background-color: #71a50c;
      margin-top: -19px;
      height: 2rem;
      width: 2rem;
      // top: 0.5px ;
      border-radius: 1rem;
      transform: translateX(2.5rem);
    }
  }
  .para-info {
    font-size: 1.6rem;
    padding: 0rem;
    margin: 0rem;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .form-group {
    // padding: 0;
    position: relative;
    @include input-style;
  }
}

.multiselect {
  padding-right: 2%;
  &__selectBox {
    border-bottom: 1px solid $primary-color-black;
    position: relative;
    &__label {
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    &__show-selectedBox {
      width: 95%;
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &__display {
        padding: 0.5rem;
        border: 0.2rem solid $primary-color-red;
;
        align-items: center;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 1rem;
        // background-color: $secondary-color-pink;
        white-space: nowrap;
        font-size: 1.4rem;
      }
    }
    .arrow-buttons{
      position: absolute;
      right: 1%;
          bottom: 25%;
      height: 2rem;
    display: flex;
    flex-direction: column;
    background: transparent;
    border: none;
    outline: none;
    &__img{
      height: 50%;
    }
    }
    
  }
  .CheckedData {
    padding: 0.5rem;
  }
  .show-selectedBox {
    display: flex;
    padding: 1rem;
    overflow-x: hidden;
    &__display {
      padding: 0.5rem;
      border: 1px solid;
      align-items: center;
      margin: 1rem;
      border-radius: 1rem;
      background-color: $secondary-color-pink;
      white-space: nowrap;
    }
    .CheckedData {
    }
  }

  .checkboxes {
    max-height: 15rem;
    overflow-y: auto;
    .checkbox-div {
      display: flex;
      align-items: center;
      margin: 0.2rem 0rem;
    }
    &__label,
    &__input {
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
    &__label {
      width: auto;
      color: $primary-color-grey;
    }
    .checkbox-checked {
      background-color: $tertiary-color-white;
    }
  }
}

.selectBox select {
  width: 100%;
}

</style>
