<template>
     <div class="modal loading-component" id="exampleModalCenter" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered justify-content-center" role="document">
    <div class="modal-content" style="width:auto;">
    <div class="loading-icon-content">
      <img src="@\assets\imgs\loading-icon-transparent-background.jpg" alt="loading-icon">

    </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.loading-component{
  
  background-color: rgba($color: #000000, $alpha: .5);
  display: block !important;
  .modal-content{
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    .loading-icon-content{
      height: 20vh;
      img{
        height: 100%;
      }
    }

    
  }
  
}
</style>