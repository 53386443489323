<template>
  <div class="Owners">
    <div class="owners-info" v-for="(Owner, counter) in Owners" v-bind:key="counter">
      <hr class="mb-5" />
      <span class="delete-owner-btn">
        <button
          v-show="((counter >= 1) && (isDisable==false))"
          @click="deleteOwners(counter)"
          
        >
          <img src="@/assets/imgs/Delete.svg" alt="" />
        </button>
      </span>
      <div class="row justify-content-between">
        <div class="col-12 col-sm-3 form-group">
          
          <input
            type="text"
            :disabled=isDisable
            placeholder=" "
            name="businessProfile-businessOwnerName"
            :id="`businessProfile-businessOwnerName${counter}`"
            v-model="Owner.businessOwnerName"
          />

          <label
            :for="`businessProfile-businessOwnerName${counter}`"
            class="form__label required"
          >
            {{ Label.ownerLabel.LabelOwnerName }}
          </label>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <input
            type="text"
             :disabled=isDisable
            min="1"
            max="100"
            placeholder=" "
            name="businessProfile-Ownership"
            :id="`businessProfile-Ownership${counter}`"
            @keyup="ownerShipPercentage($event, 3, counter)"
            @blur="ownerShipPercentage($event, 3, counter)"
            v-model="Owner.ownedPercentage"
          />
          <label
            :for="`businessProfile-Ownership${counter}`"
            class="form__label required"
          >
            {{ Label.ownerLabel.LabelOwnership }}
          </label>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <input
            type="email"
             :disabled=isDisable
            placeholder=" "
            name="businessProfile-LabelOwnerEmail"
            :id="`businessProfile-LabelOwnerEmail${counter}`"
            v-model="Owner.emailAddress"
          />
          <label
            :for="`businessProfile-LabelOwnerEmail${counter}`"
            class="form__label required"
          >
            {{ Label.ownerLabel.LabelOwnerEmail }}
          </label>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <select
            class="select-group"
             :disabled=isDisable
            placeholder=" "
            name="businessProfile-Veteran"
            :id="`businessProfile-Veteran${counter}`"
            v-model="Owner.veteranID"
          >
            <option
              v-for="veteran in prePopulatedData?.veteranList"
              :key="veteran.veteranID"
              :value="veteran.veteranID"
            >
              {{ veteran.veteranType }}
            </option>
          </select>
          <label :for="`businessProfile-Veteran${counter}`" class="form__label required">
            {{ Label.ownerLabel.LabelVeteran }}
          </label>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-12 col-sm-3 form-group">
          <select
            class="select-group"
            placeholder=" "
             :disabled=isDisable
            name="businessProfile-Race"
            :id="`businessProfile-Race${counter}`"
            v-model="Owner.raceID"
          >
            <option
              v-for="race in prePopulatedData?.raceList"
              :key="race.raceID"
              :value="race.raceID"
            >
              {{ race.raceName }}
            </option>
          </select>
          <label :for="`businessProfile-Race${counter}`" class="form__label required">
            {{ Label.ownerLabel.LabelRace }}
          </label>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <select
            class="select-group"
            placeholder=" "
            name="businessProfile-Ethnicity"
             :disabled=isDisable
            :id="`businessProfile-Ethnicity${counter}`"
            v-model="Owner.ethnicityID"
          >
            <option
              v-for="ethnicity in prePopulatedData?.ethnicityList"
              :key="ethnicity.ethnicityID"
              :value="ethnicity.ethnicityID"
            >
              {{ ethnicity.ethnicityName }}
            </option>
          </select>
          <label
            :for="`businessProfile-Ethnicity${counter}`"
            class="form__label required"
          >
            {{ Label.ownerLabel.LabelEthnicity }}
          </label>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <input
            type="text"
             :disabled=isDisable
            placeholder="XXX-XXX-XXXX"
            max="12"
            name="businessProfile-LabelOwnerPhoneNo"
            :id="`businessProfile-LabelOwnerPhoneNo${counter}`"
            v-model="Owner.phoneNumber"
            @keyup="formatePhoneNumberToUSA($event, Owner.phoneNumber, counter)"
            @blur="formatePhoneNumberToUSA($event, Owner.phoneNumber, counter)"
          />
          <label
            :for="`businessProfile-LabelOwnerPhoneNo${counter}`"
            class="form__label required"
          >
            {{ Label.ownerLabel.LabelOwnerPhoneNo }}
          </label>
        </div>
        <div class="col-12 col-sm-3 form-group">
          <select
            class="select-group"
             :disabled=isDisable
            placeholder=" "
            name="businessProfile-Gender"
            :id="`businessProfile-Gender${counter}`"
            v-model="Owner.genderID"
          >
            <option
              v-for="gender in prePopulatedData?.genderList"
              :key="gender.genderID"
              :value="gender.genderID"
            >
              {{ gender.genderName }}
            </option>
          </select>
          <label :for="`businessProfile-Gender${counter}`" class="form__label required">
            {{ Label.ownerLabel.LabelGender }}
          </label>
        </div>
      </div>
      <div class="row justify-content-between">
        

        
      </div>
    </div>
  </div>
</template>
<script>
import ownerLabel from "@/textStore";
export default {
  setup() {
    return {
      ownerLabel,
    };
  },
  props: {
    // prePopulatedData: {
    //   required: true,
    //   type: Object,
    // },
    shouldFieldReadOnly: {
      type: Boolean,
    },
  },
  components: {
  },
  data() {
    return {
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
      hideDelete: true,
      Label: {},
    };
  },
  computed: {
    Owners: {
      get() {
        Object.assign(this.Label, ownerLabel);
        return this.$store.state.borrower.businessProfileData.ownersData;
      },
    },
    prePopulatedData: {
      get() {
        return this.$store.state.borrower.businessProfileData.prePopulatedData;
      },
    },
    shouldReadOnly: {
      get() {
        return this.shouldFieldReadOnly;
      },
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if((this.userRoleID ==1)||(this.userRoleID ==2)||(this.userRoleID ==3)||(this.userRoleID ==5)||(this.userRoleID ==7)){
          return false
        }
        else{
          return true
        }
      },
    },
  },
  created() {
  },
  methods: {
    ownerShipPercentage(event, maxNumber, counter) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.Owners[counter].ownedPercentage
        .replace(/[^\d,]/g, "")
        .slice(0, maxNumber);

      value = tempFormattingNumber.replace(/[^\d]/g, "");
      if (Number(value) > 100) {
        value = "100";
        this.Owners[counter].ownedPercentage = value;
        return this.Owners[counter].ownedPercentage;
      }
      this.Owners[counter].ownedPercentage = value;
    },
    async formatePhoneNumberToUSA(event, value, counter) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      // let value =122222
      let phoneNumber = value.replace(/[^\d,]/g, "").slice(0, 10);
      // Format display value based on calculated currencyValue
      value = phoneNumber.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, "$1-$2-$3");
      this.Owners[counter].phoneNumber = value;
    },
    async storeOwner() {
      await this.$store
        .dispatch("UpdateownersData", this.Owners, { root: true })
        .then(() => {
          // return true
        })
        .catch((err) => {
          Promise.reject(err);
        });
    },
    async addOwners() {
      this.Owners.push({
        "id": 0,
          "businessID": "",
          "businessOwnerName": "",
          "emailAddress": "",
          "phoneNumber": "",
          "ownedPercentage": "",
          "veteranID": "",
          "veteranName": "",
          "genderID": "",
          "genderName": "",
          "raceID": "",
          "raceName": "",
          "ethnicityID": "",
          "ethnicityName": "",
          "demographic": ""
      });
    },
    deleteOwners(counter) {
      this.Owners.splice(counter, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.Owners {
  padding-top: 1rem;
  .owners-info {
    position: relative;
    .delete-owner-btn {
      position: absolute;
      right: 2%;
      top: 2%;
      button {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.01rem;
        border: none;
        img {
          height: 100%;
        }
      }
    }
  }

  .row {
    margin: 0;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;

    select:not([value=""]):valid ~ label {
      &:after {
        content: "" !important ;
        color: transparent !important ;
      }
    }
  }
}
</style>
