<template>
  <div class="card">
    <div class="card--heading">
     <h2 class="card--heading__h2-header">{{heading}}</h2> 
    </div>
    <div class="card--body">
       <slot name="cardData" class="card--data">
       </slot>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    heading:{
      type:String
    },
  }
}
</script>
<style lang="scss" scoped>
.card{
  box-shadow: 0 0 1rem -0.5rem $primary-color-grey;
  min-width: 28rem;
  min-height: 12rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0rem;
  margin: 1rem;
  &--heading{
    &__h2-header{
      font-size: 1.8rem;
      font-weight: bold;
      color: $tertiary-color-grey;
    }
  }
  // &--body{
  //   .card--data{
  //   }
  // }
}
</style>