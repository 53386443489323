<template>
  <div class="TrackingList">
    <HeaderNav />

    <div class="TrackingList--content">
      <div class="TrackingList--content__Header d-flex">
        <div class="TrackingList--content__Header--breadCrumb align-self-start">
          <BreadCrumb
            class="breadcrumb"
            :crumbs="crumbs"
            @selected="selected"
          />
        </div>
        <span class="btn--primary" v-if="(userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)">
          <BtnPrimary
            :buttonName="`New Business Entity`"
            @click="createProgramInvitation"
          />
        </span>
      </div>
      
    </div>
    <div class="TrackingList__table-section ">
        <BusinessEntityListTable
          class="listingTable"
          :items="items"
          :columns="columns"
          :TableHeight="TableHeight"
          :perPage="itemsPerTable"
          @selectRow="getBusinessEntitiyDetails"
        />
      </div>
    <LoadingMask v-if="isLoading===true" />
    <Footer />
  </div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import BusinessEntityListTable from "@/components/BusinessForms/BusinessEntityListTable";
import BtnPrimary from "@/components/BtnPrimary.vue";
export default {
  created() {
    this.getBusinessEntityList();
  },

  data() {
    return {
      crumbs: ["Business Entities"],
      welcomeName: "Welcome, ",
      LastModifiedDate: "Last Visited on" + " April 10, 2021",
      itemsPerTable: 10,
      TableHeight: 30,
      columns: ["Business Name", "EIN", "Affiliate Name"],
      items: [],
    };
  },
  components: {
    HeaderNav,
    Footer,
    BreadCrumb,
    BusinessEntityListTable,
    BtnPrimary,
  },
  computed: {
    userName() {
      return this.$store.getters.getUser;
    },
    isLoading:{
      get(){
        return this.$store.state.showLoader;
      }
    },
      userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  methods: {
    async getBusinessEntityList() {
      this.$store.dispatch("updateLoaderFlag",true)
      await this.$store
        .dispatch("getBusinessEntityList", { root: true })
        .then((res) => {
          this.items = res.data.data;
          this.$store.dispatch("updateLoaderFlag",false)
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag",false)
          Promise.reject(err);
        });
    },
    getBusinessEntitiyDetails(entityID) {
      if (entityID > 0) {
        this.$store.state.business.programInvitationActiveTab='business'
        this.$router.push("/programinvitation/form/"+entityID+"/"+0);
      }
    },
    // create a new program 
    async createProgramInvitation() {
      this.$store.state.business.businessEntityData={};
      this.$store.state.business.programs.programInvitation=[{}];
      this.$store.state.business.users.roles=[{}];
      this.$store.state.business.canBeDeleted=false;
      this.$store.state.business.programInvitationActiveTab=''
      this.$router.push("/programinvitation/form/"+0+"/"+0);
    },
  },
};
</script>
<style lang="scss" scoped>
.TrackingList {
  &--content {
    padding: 0rem 2%;
    &__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn--primary {
        // width: 19rem;
        button {
          width: auto;
          font-size: 1.6rem;
          margin: 1.5rem 0rem;
        }
      }
    }
    &__name {
      padding:1rem 0rem;
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
    }
  }
  .TrackingList__table-section{
    padding: 0rem 2%;
  }
}
</style>
