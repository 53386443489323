<template>
  <div class="userList">
    <div class=" row justify-content-between " v-if="isUserListEmpty === false && inputs[0].contactID!=0">
          <div class="status--continer col-2 px-1">
            <p class="para-info d-flex ">
            <b>Role</b>
          </p>
          
        </div>
        
          <div class=" col-3 px-1">
            <p class="para-info d-flex ">
             <b>Contact</b>
          </p>
          </div>
          <div class=" col-3 px-1">
            <p class="para-info d-flex ">
             <b>Email</b>
          </p>
          </div>
          <div class=" col-2 px-1">
            <p class="para-info d-flex ">
             <b>Phone Number</b>
          </p>
          </div>
          <div class="status--continer col-sm-2 col-0 px-1 ">
            
          </div>
        
      </div>
    <div class="userList--content">
      <div
        v-if="isUserListEmpty === true"
        class="d-flex justify-content-center"
      >
        <h3>No user availiable</h3>
      </div>
      <div v-if="isUserListEmpty === false">
        <div class="user-section" v-for="(input, index) in inputs" :key="index">
          <User
            :hideShowBar="true"
            :userData="input"
            :roleList="roleList"
            :index="index"
            :salutationList="salutationList"
          />
        </div>
      </div>
    </div>
    <div class="btn--section row justify-content-start">
      <span class="btn--tertiary col-3" v-if="(userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)">
        <BtnTertiary buttonName="Add Contact" @click="addContact" />
      </span>
    </div>
  </div>
</template>
<script>
import User from "@/components/BusinessForms/User.vue";
import BtnTertiary from "@/components/BtnTertiary.vue";
export default {
  components: {
    User,
    BtnTertiary,
  },
  props: {
    roleList: [],
    salutationList: [],
  },
  data() {
    return {
      isListEmpty: this.isUserListEmpty,
    };
  },
  methods: {
    addContact() {
      this.$emit('addContact');

    },
  },
  computed: {
    isUserListEmpty: {
      get() {
        return this.$store.state.business.isBusinessUserListEmpty;
      },
    },
    inputs: {
      get() {
        return this.$store.state.business.users.roles;
      },
    },
    businessID: {
      get() {
        return this.$store.state.business.businessEntityData.id;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.userList {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  &--content {
    height: 80%;
    overflow-y: auto;
   
  }
  .btn--section {
    margin: 0rem;
    padding: 0rem;
    .btn--tertiary {
      button {
        width: auto;
        padding: 0rem 2rem;
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          content: "+";
        }
      }
    }
  }
  .para-info {
    font-size: 1.6rem;
    padding: 0rem;
    margin: 0rem;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .row {
    padding-right: 8%;

    margin: 0;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>