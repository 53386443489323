<template>
  <div class="dashboard">
    <HeaderNav />
    <div class="dashboard--section">
      <div class="dashboard--header">
        <div class="dashboard--header__breadcrumbs">
          <BreadCrumb class="breadcrumb" :crumbs="crumbs" />
        </div>
        <div class="dashboard--header__heading-fillter mb-3">
          <div class="dashboard--heading">
            <h3 class="heading">{{ heading }}</h3>
          </div>
          <div class="dashboard--fillter fillter">
            <label for="group-filter" class="pr-2"> <p>Program:</p> </label>
            <select
              class="group-filter"
              placeholder=" "
              name="group-filter"
              id="group-filter"
              v-model="programid"
              @change="dashboardGraphs(programid)"
            >
              <option v-if="isAllSelectForProgramInvitation==true" value="0"> <p>All</p></option>
              <option
                v-for="programInvitation in programs"
                :key="programInvitation.programId"
                :value="programInvitation.programId"
              >
                {{ programInvitation.programName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="dashboard--main">
        <div class="charts ">
          <div class="row">
            <div class="col-md-6 chart">
              <Graph
                :heading="
                  programid == 0
                    ? FundAllocationHeadingPieChart
                    : FundAllocationHeadingPieChart + ' - ' + getProgramName()
                "
                :graphData="pieChart"
                :showChart="showChartB"
                :chartType="'doughnut'"
                :programid="programid"
                :name="'FundAllocationDetail'"
              />
            </div>
            <div class="col-md-6 chart">
              <Graph
                :heading="
                  programid == 0
                    ? ApplicationStatHeadingPieChart
                    : ApplicationStatHeadingPieChart + ' - ' + getProgramName()
                "
                :graphData="ApplicationStatPieChart"
                :showChart="showChartC"
                :chartType="'doughnut'"
                :programid="programid"
                :name="'ApplicationStats'"
              />
            </div>
          </div>
          <div class="row" v-if="programid == 0">
            <div class="col-md-6 chart">
              <Graph
                :heading="
                  programid == 0
                    ? 'Program wise' + FundAllocationHeadingBarChart
                    : getProgramName() + ' ' + FundAllocationHeadingBarChart
                "
                :graphData="stackedBarChart"
                :showChart="showChartA"
                :chartType="'bar'"
                :programid="programid"
                :TotalCount="ChartDTotalCount"
                :currentPageNumber="FundAllocationProgram.pageNumber"
                :totalSize="FundAllocationProgram.totalSize"
                :pageEnd="FundAllocationProgram.pageEnd"
                :IsPreviousPage="FundAllocationProgram.previousPage"
                :IsNextPage="FundAllocationProgram.nextPage"
                @GetBarPagination="dashboardGetFundAllocationProgram"
                :name="'ProgramWiseFundAllocation'"
              />
            </div>
            <div class="col-md-6 chart">
              <Graph
                :heading="
                  programid == 0
                    ? 'Program wise ' + ApplicationStatHeadingBarChart
                    : getProgramName() + ' ' + ApplicationStatHeadingBarChart
                "
                :graphData="ApplicationstackedBarChart"
                :showChart="showChartD"
                :chartType="'bar'"
                :programid="programid"
                :TotalCount="ChartDTotalCount"
                :currentPageNumber="ProgramApplicationStatus.pageNumber"
                :totalSize="ProgramApplicationStatus.totalSize"
                :pageEnd="ProgramApplicationStatus.pageEnd"
                :IsPreviousPage="ProgramApplicationStatus.previousPage"
                :IsNextPage="ProgramApplicationStatus.nextPage"
                @GetBarPagination="dashboardGetProgramApplicationStatus"
                :name="'ProgramWiseApplicationStats'"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 chart">
              <Graph
                :heading="AffiliateFundAllocationHeadingPieChart"
                :graphData="AffiliateFundAllocationStatBarChart"
                :showChart="showChartF"
                :chartType="'bar'"
                :programid="programid"
                :TotalCount="ChartDTotalCount"
                :currentPageNumber="AffiliateFundAllocation.pageNumber"
                :totalSize="AffiliateFundAllocation.totalSize"
                :pageEnd="AffiliateFundAllocation.pageEnd"
                :IsPreviousPage="AffiliateFundAllocation.previousPage"
                :IsNextPage="AffiliateFundAllocation.nextPage"
                @GetBarPagination="dashboardGetAffiliateFundAllocation"
                :name="'AffiliatewiseFundUtilized'"
              />
            </div>
            <div class="col-md-6 chart">
              <Graph
                :heading="AffiliateFundAllocationHeadingBarChart"
                :graphData="AffiliateFundAllocationstackedBarChart"
                :showChart="showChartE"
                :chartType="'bar'"
                :programid="programid"
                :TotalCount="ChartDTotalCount"
                :currentPageNumber="AffiliateApplicationStatus.pageNumber"
                :totalSize="AffiliateApplicationStatus.totalSize"
                :pageEnd="AffiliateApplicationStatus.pageEnd"
                :IsPreviousPage="AffiliateApplicationStatus.previousPage"
                :IsNextPage="AffiliateApplicationStatus.nextPage"
                @GetBarPagination="dashboardGetAffiliateApplicationStatus"
                :name="'AffiliateWiseApplicationStats'"
              />
            </div>
          </div>
        </div>
        <div class="quick-links" v-if="roleID != 4">
          <hr />
          <div class="quick-links--heading">
            <h3 class="heading">Quick Links</h3>
          </div>
          <div class="quick-links--main">
            <p class="links">
              <router-link to="/funding"
                >Funding Entities and Program</router-link
              >
              <small v-if="roleID != 8">
                (Add, Edit, Manage Funding Entities, Fund Limit and Program)
              </small>
            </p>
            <p class="links">
              <router-link to="/affiliates">Affiliate</router-link>
              <small v-if="roleID != 8">
                (Add and Edit Affiliate Contacts)
              </small>
            </p>
            <p class="links">
              <router-link to="/bel"
                >Business Entities and Contact
              </router-link>
              <small v-if="roleID != 8">
                (Add, Edit, Delete Business Entities and Contact)
              </small>
            </p>
            <p class="links">
              <router-link to="/contacts">NUL Contacts </router-link>
              <small v-if="roleID != 8">
                (Add, Edit, Delete NUL Contacts)
              </small>
            </p>
          </div>
        </div>
      </div>
      <div class="dashboard--footer"></div>
    </div>
    <Footer />
    <LoadingMask v-if="isLoading === true" />
  </div>


  <Transition name="modal">
    <div v-if="showCentralPopUp == true" class="modal-mask">
      <div class="modal-wrapper" style="margin: auto;
  width: 70%;
  padding: 10px;">
        <div class="modal-container">
          <div class="modal-header justify-content-center">
           <slot name="header">
            <h3 style="font-weight: bold;text-transform: capitalize;color: #C11439;margin: 0;font-size: x-large;">
              {{chartModal.title}}
            </h3>
          </slot>
           
          </div>

          <div class="modal-body">
            <slot name="body">
            <table class="table m-4" style="width:100%" v-if="chartModal.name == 'ApplicationStats'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title"> {{dashboardApplicationStatsDetail.fundingEntityName}}</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.programName}}</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.noAction}}</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                   <p class="tabel-header__title">{{dashboardApplicationStatsDetail.inprogress}}</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.started}}</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.funded}}</p>
                    </th>
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['fundingEntityName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td class="text-center" >
                       <p> <span v-html="item['noAction']"></span></p>
                    </td>
                    <td class="text-center">
                       <p ><span v-html="item['inprogress']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['started']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['funded']"></span></p>
                    </td>
                  </tr>
                </tbody>
              </table>
            <table class="table m-4" style="width:100%" v-if="chartModal.name == 'FundAllocationDetail'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title"> Funding Entity Name</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">Program Name</p>
                    </th>
                    <th class="tabel-header col col-sm-3 ">
                    <p class="tabel-header__title">Available Limit</p>
                    </th>
                    <th class="tabel-header col col-sm-3 ">
                   <p class="tabel-header__title">Utilized Amount</p>
                    </th>
                     
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['fundingEntityName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td  >
                       <p> <span v-html="item['availableLimit']"></span></p>
                    </td>
                    <td >
                       <p ><span v-html="item['utilizedAmount']"></span></p>
                    </td>
                  </tr>
                </tbody>
            </table>
              <table class="table m-4" style="width:100%" v-if="chartModal.name == 'AffiliatewiseFundUtilized'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title">Affiliate Name</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">Program Name</p>
                    </th>
                    <th class="tabel-header col col-sm-3 ">
                    <p class="tabel-header__title">Available Limit</p>
                    </th>
                    <th class="tabel-header col col-sm-3 ">
                   <p class="tabel-header__title">Utilized Amount</p>
                    </th>
                     
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['affiliateName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td  >
                       <p> <span v-html="item['availableLimit']"></span></p>
                    </td>
                    <td >
                       <p ><span v-html="item['utilizedAmount']"></span></p>
                    </td>
                  </tr>
                </tbody>
            </table>
            <table class="table m-4" style="width:100%" v-if="chartModal.name == 'AffiliatewiseApplicationStats'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title"> Affiliate Name</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">Program Name</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title"> NoAction</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                   <p class="tabel-header__title">Inprogress</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">Started</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">Funded</p>
                    </th>
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['affiliateName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td class="text-center" >
                       <p> <span v-html="item['noAction']"></span></p>
                    </td>
                    <td class="text-center">
                       <p ><span v-html="item['inprogress']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['started']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['funded']"></span></p>
                    </td>
                  </tr>
                </tbody>
            </table>
             <div class ="table-view__footer" >
              <div class="pagination">
                <div class="pagination__container">
                  <div class="pagination__container__total-pages">
                    <p>
                    {{ filteredRows.length }}
                      {{ filteredRows.length > 1 ? chartModal.labels.Results : chartModal.labels.Result }}
                    </p>
                  </div>
                  <div class="pagination__container__row-per-page">
                    <span>
                      <label>
                      {{ chartModal.labels.Rowsperpage }}
                      </label>
                      <input
                        type="chartModal.Number"
                        :max=chartModal.perPage
                        min="1"
                        readonly
                        :value=chartModal.perPage
                      />
                    </span>
                  </div>
                  <div class="pagination__container__brief">
                    <span>
                      <p>{{ chartModal.values.currentPage }}</p>
                      <p>{{ chartModal.labels.of }}</p>
                      <p>{{ chartModal.values.totalPages }}</p>
                      <p>{{ chartModal.labels.pages }}</p>
                    </span>
                  </div>
                  <div class="pagination__container__inc-dec">
                    <button @click="backPage()">
                      &#60;
                    </button>
                    <p>{{ chartModal.values.currentPage }}</p>
                    <button @click="nextPage()">
                      &#62;
                    </button>
                  </div>
                </div>
              </div>
          </div>
            </slot>
           
          </div>
          <div class="modal-footer justify-content-center">
          <slot name="footer">
            <div class=" d-flex  pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Close`"
                    @click="closePopUp()"
                  />
                </span>
              </div>
            </div>
           </slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import Graph from "@/components/Graph.vue";
import BreadCrumb from "@/components/BreadCrumb";
import axios from "axios";
import ChartJSPluginDatalabels from "chartjs-plugin-datalabels";
import BtnPrimary from "@/components/BtnPrimary.vue";

export default {
  components: {
    BreadCrumb,
    HeaderNav,
    Footer,
    Graph,
    BtnPrimary
  },
  data() {
    return {
      showCentralPopUp:false,
      showChartA: false,
      showChartB: false,
      showChartC: false,
      showChartD: false,
      showChartE: false,
      showChartF: false,
      crumbs: ["Dashboard"],
      heading: "Dashboard",
      programid: 0,
      FundAllocationHeadingPieChart: "Fund Allocation",
      FundAllocationHeadingBarChart: " Fund Allocation",
      ApplicationStatHeadingBarChart: " Application Stats",
      ApplicationStatHeadingPieChart: "Application Stats",
      AffiliateFundAllocationHeadingPieChart: "Affiliate wise Fund Utilized",
      AffiliateFundAllocationHeadingBarChart:
        "Affiliate wise Application Stats",
      affiliateApplicationStatusArray: [],
      ProgramApplicationStatus: {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      },
      FundAllocationProgram: {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      },
      AffiliateFundAllocation: {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      },
      AffiliateApplicationStatus: {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      },
      dashboardOverLay:{
        title:"",
        rslt:null,
      },
      dashboardFundAllocationColumn:
      {
        fundingEntityName: "Funding Entity",
          programName:"Program Name",
          availableLimit: "Available Limit",
          utilizedAmount:"Utilized Amount"
      },
      dashboardApplicationStatsDetail:
      {
        title:"",
          rslt:null,
          fundingEntityName:"Funding Entity",
          programName:"Program Name",
          noAction:"No Action",
          inprogress:"Inprogress",
          started:"Started",
         funded:"Funded"
      },
      basicPicChart: {
        type: "doughnut",
        data: {
          labels: [],
          datasets: [
            {
              backgroundColor: [""],
              data: [""],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "right",
            },
          },
        },
      },
      ApplicationStatPieChart :{
          type: "doughnut",
          data: {
            labels: ["No Action", "Started", "In-progress", "Funded"],
            datalabels: {
              color: "#FFCE56",
            },
            datasets: [
              {
                backgroundColor: [],
                data: [""],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "right",
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  footer: (ttItem) => {
                    let sum = 0;
                    let dataArr = ttItem[0].dataset.data;
                    dataArr.map((data) => {
                      sum += Number(data);
                    });

                    let percentage =
                      ((ttItem[0].parsed * 100) / sum).toFixed(2) + "%";
                    return `Percentage: ${percentage}`;
                  },
                },
              },
              datalabels: {
                display: true,
                anchor: "center",
                align: "center",
                offset: 20,
                borderWidth: 0,
                formatter: (value, dnct1) => {
                  let sum = 0;
                  let dataArr = dnct1.chart.data.datasets[0].data;
                  dataArr.map((data) => {
                    sum += Number(data);
                  });

                  let percentage = ((value * 100) / sum).toFixed(2); // + "%";
                  if (percentage < 1) {
                    percentage = "";
                  } else {
                    percentage = percentage + "%";
                  }
                  return percentage;
                },
                color: "#FFFFFF",
              },
              
             
            },
            /* onClick: (e, activeEls) =>{//Application Stats
                //let datasetIndex = activeEls[0].datasetIndex;
                let dataIndex = activeEls[0].index;
                //let datasetLabel = e.chart.data.datasets[datasetIndex].label;
                //let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                let label = e.chart.data.labels[dataIndex];
                //var chartTitleText = e.chart.titleBlock.options.text;
                
                //alert(label);
                
                this.fetchDashboardApplicationStatsDetail("FUNDALLOCATION",label,this.programid);
                this.showChartA = true;
                this.showChart = 'showChartA';
                //console.log(rslt);
//this.showCentralPopUp = true;

                },*/
          },
          plugins: [ChartJSPluginDatalabels],
        },
         chartModal:
        {
                name:"",
                title:"",
                items:[],
                perPage : 10,
                filter: "",
                list: [],
                unsort:false,
                selectOptionToFilter: "all",
                isClearSort: false,
                labels: {
                  ShowingResults: "Showing Results",
                  ShowFilters: "Show Filters",
                  Results: "Results",
                  Result: "Result",
                  Rowsperpage: "Rows per page:",
                  pages: "Pages",
                  of: "of",
                  },
                  values: {
                    pages: [],
                    totalPages: 1,
                    currentPage: 1,
                    currentSort: "",
                    currentSortDir: "asc",
                    isSearchByAll: true,
                  },
              },
      basicStackedBarChart: {
        type: "bar",
        options: {
          min: 0,
          max: 10,
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            x: {
              Stacked: true,
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                callback: function(val) {
                  let characterLimit = 11;
                  let xAxisLabel = val;
                  if (this.getLabelForValue(val).length >= characterLimit) {
                    xAxisLabel =
                      this.getLabelForValue(val)
                        .slice(0, this.getLabelForValue(val).length)
                        .substring(0, characterLimit - 1)
                        .trim() + "...";
                  } else {
                    xAxisLabel = this.getLabelForValue(val)
                  }
                  return xAxisLabel;
                },
              },
            },
            y: {
              min: 0,
              Stacked: true,
              ticks: {
                precision: 0,
              },
            },
          },
        },
        data: {
          labels: [""],
          datasets: [
            {
              label: "",
              backgroundColor: [""],
              data: [],
              stack: "Stack 0",
            },
          ],
        },
      },
      stackedBarChart: {
        type: "bar",
        options: {
          min: 0,
          max: 10,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            x: {
              Stacked: true,
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                callback: function(val) {
                  let characterLimit = 11;
                  let xAxisLabel = val;
                  if (this.getLabelForValue(val).length >= characterLimit) {
                    xAxisLabel =
                      this.getLabelForValue(val)
                        .slice(0, this.getLabelForValue(val).length)
                        .substring(0, characterLimit - 1)
                        .trim() + "...";
                  } else {
                    xAxisLabel = this.getLabelForValue(val)
                  }
                  return xAxisLabel;
                },
              },
            },
            y: {
              min: 0,
              Stacked: true,
              ticks: {
                precision: 0,
              },
            },
          },
         
        },
        data: {
          labels: [
            "Restaurant Accelerator",
            "Round It Up Africa",
            "Elevate Together",
          ],
          datasets: [],
        },
      },

      ApplicationstackedBarChart: {
        type: "bar",
        options: {
          min: 0,
          max: 10,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            x: {
              Stacked: true,
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                callback: function(val) {
                  let characterLimit = 11;
                  let xAxisLabel = val;
                  if (this.getLabelForValue(val).length >= characterLimit) {
                    xAxisLabel =
                      this.getLabelForValue(val)
                        .slice(0, this.getLabelForValue(val).length)
                        .substring(0, characterLimit - 1)
                        .trim() + "...";
                  } else {
                    xAxisLabel = this.getLabelForValue(val);
                  }

                  // this.getLabelForValue(val)=xAxisLabel
                  return xAxisLabel;
                },
              },
            },
            y: {
              min: 0,
              Stacked: true,
              ticks: {
                precision: 0,
              },
            },
          },
           /*onClick: (e, activeEls) =>{//Program wise Application Stats
                let datasetIndex = activeEls[0].datasetIndex;
                let dataIndex = activeEls[0].index;
                let datasetLabel = e.chart.data.datasets[datasetIndex].label;
                let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                let label = e.chart.data.labels[dataIndex];
                var chartTitleText = e.chart.titleBlock.options.text;
                this.dashboardOverLay.title = "Fund Allocation Details";
                this.dashboardOverLay.UtilizedAmount = "Utilized Amount"
                alert('Program wise Application Stats'+this.programid+'chartTitleText is ',chartTitleText,'Clicked:', datasetLabel, 'Value:', value,'label',label);
               // this.fetchDashboardDetailsByReportType("FUNDALLOCATION",label,this.programid);    
                            
                this.showChartE = true;
                this.showChart = 'showChartE';
//this.showCentralPopUp = true;

                },*/
        },
        
        data: {
          labels: [],
          datasets: [],
        },
      },
      AffiliateFundAllocationStatPieChart: {
        type: "doughnut",
        data: {},
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        },
      },
      AffiliateFundAllocationStatBarChart: {
        type: "bar",
        options: {
          min: 0,
          max: 10,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            x: {
              Stacked: true,
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                callback: function(val) {
                  let characterLimit = 11;
                  let xAxisLabel = val;
                  if (this.getLabelForValue(val).length >= characterLimit) {
                    xAxisLabel =
                      this.getLabelForValue(val)
                        .slice(0, this.getLabelForValue(val).length)
                        .substring(0, characterLimit - 1)
                        .trim() + "...";
                  } else {
                    xAxisLabel = this.getLabelForValue(val);
                  }
                  return xAxisLabel;
                },
              },
            },
            y: {
              Stacked: true,
              ticks: {
                precision: 0,
              },
            },
          },
          /*onClick: (e, activeEls) =>{//Program wise Application Stats
                let datasetIndex = activeEls[0].datasetIndex;
                let dataIndex = activeEls[0].index;
                let datasetLabel = e.chart.data.datasets[datasetIndex].label;
                let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                let label = e.chart.data.labels[dataIndex];
                var chartTitleText = e.chart.titleBlock.options.text;
                this.dashboardOverLay.title = "Fund Allocation Details";
                this.dashboardOverLay.UtilizedAmount = "Utilized Amount"
                alert('Affiliate FundAllocation Stat Bar Chart'+this.programid+'chartTitleText is ',chartTitleText,'Clicked:', datasetLabel, 'Value:', value,'label',label);
               // this.fetchDashboardDetailsByReportType("FUNDALLOCATION",label,this.programid);    
                            
                this.showChartE = true;
                this.showChart = 'showChartE';
//this.showCentralPopUp = true;

                },*/
        },
        data: {
          labels: [],
          datasets: [],
        },
      },
      AffiliateFundAllocationstackedBarChart: {
        type: "bar",
        options: {
          min: 0,
          max: 10,
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
          scales: {
            x: {
              Stacked: true,
              ticks: {
                // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                callback: function(val) {
                  let characterLimit = 11;
                  let xAxisLabel = val;
                  if (this.getLabelForValue(val).length >= characterLimit) {
                    xAxisLabel =
                      this.getLabelForValue(val)
                        .slice(0, this.getLabelForValue(val).length)
                        .substring(0, characterLimit - 1)
                        .trim() + "...";
                  } else {
                    xAxisLabel = this.getLabelForValue(val);
                  }
                  return xAxisLabel;
                },
              },
            },
            y: {
              Stacked: true,
              ticks: {
                precision: 0,
              },
            },
          },
          /*onClick: (e, activeEls) =>{//Program wise Application Stats
                let datasetIndex = activeEls[0].datasetIndex;
                let dataIndex = activeEls[0].index;
                let datasetLabel = e.chart.data.datasets[datasetIndex].label;
                let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                let label = e.chart.data.labels[dataIndex];
                var chartTitleText = e.chart.titleBlock.options.text;
                this.dashboardOverLay.title = "Fund Allocation Details";
                this.dashboardOverLay.UtilizedAmount = "Utilized Amount"
                alert('Affiliate FundAllocationstackedBarChart'+this.programid+'chartTitleText is ',chartTitleText,'Clicked:', datasetLabel, 'Value:', value,'label',label);
               // this.fetchDashboardDetailsByReportType("FUNDALLOCATION",label,this.programid);    
                            
                this.showChartE = true;
                this.showChart = 'showChartE';
//this.showCentralPopUp = true;

                },*/
        },
        data: {
          labels: [],
          datasets: [],
        },
      },
    };
  },
  mounted() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.getPrograms()
    .then(()=>{
      let programid=this.programid
      let pageNumber = 1;
    this.dashboardGetFundAllocationProgram(programid, pageNumber);
    this.dashboardGetFoundAllocation(programid);
    this.dashboardGetApplicationStatus(programid);
    this.dashboardGetProgramApplicationStatus(programid, pageNumber);
    this.dashboardGetAffiliateFundAllocation(programid, pageNumber);
    this.dashboardGetAffiliateApplicationStatus(programid, pageNumber);
    })
    
    
  },
  methods: {
    hide(){
      this.showCentralPopUp = false;
    }, 
    closePopUp()
    {
      this.showCentralPopUp = false;
    } ,  
    getRandomColor() {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + randomColor;
    },
    resetAllExternalData() {
      this.ProgramApplicationStatus = {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      };
      this.FundAllocationProgram = {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      };
      this.AffiliateFundAllocation = {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      };
      this.AffiliateApplicationStatus = {
        totalSize: 0,
        pageNumber: 1,
        pageEnd: 0,
        previousPage: false,
        nextPage: false,
      };
    },
    dashboardGraphs(programid) {
      this.resetAllExternalData();
      this.getProgramName();
      if (this.programid == "0") {
        let pageNumber = 0;
        this.dashboardGetFundAllocationProgram(programid, pageNumber);
        this.dashboardGetFoundAllocation(programid);
        this.dashboardGetApplicationStatus(programid);
        this.dashboardGetProgramApplicationStatus(programid, pageNumber);
        this.dashboardGetAffiliateFundAllocation(programid, pageNumber);
        this.dashboardGetAffiliateApplicationStatus(programid, pageNumber);
      } else {
        // this.AffiliateFundAllocation.pageNumber=0
        // this.AffiliateApplicationStatus.pageNumber=0
        // this.FundAllocationProgram.pageNumber=0
        // this.ProgramApplicationStatus.pageNumber=0
        this.dashboardGetFoundAllocation(programid);
        this.dashboardGetApplicationStatus(programid);
        this.dashboardGetAffiliateFundAllocation(
          programid,
          this.AffiliateFundAllocation.pageNumber
        );
        this.dashboardGetAffiliateApplicationStatus(
          programid,
          this.AffiliateApplicationStatus.pageNumber
        );
      }
    },
    getProgramName() {
      if (this.programid !== "0") {
        return this.programs.find((x) => x.programId == this.programid)
          .programName;
      } else {
        return "";
      }
    },
    // to get the program names
    async getPrograms() {
      await this.$store
        .dispatch("createProgramInvitation")
        .then((res) => {
          if(res.data.isAllSelect==false)
          {
            this.programid=this.programs[0].programId
          }
          else{
             this.programid=0
          }
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
        });
    },
    // to get tha stacked Bar Chart data of FundAllocation Program
    async dashboardGetFundAllocationProgram(programId, pageNumber) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartA = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramId: parseInt(programId),
          pageNumber: parseInt(pageNumber),
        },
      };
      await axios
        .get("/Dashboard/GetFundAllocationProgram", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            this.FundAllocationProgram.totalSize = res.data.totalSize;
            this.FundAllocationProgram.pageEnd = res.data.pageEnd;
            this.FundAllocationProgram.pageNumber = res.data.pageNumber;
            this.FundAllocationProgram.previousPage = res.data.previousPage;
            this.FundAllocationProgram.nextPage = res.data.nextPage;

            this.showChartA = true;
            this.stackedBarChart.data.labels = {};
            this.stackedBarChart.data.datasets = {};

            let labels = [];
            let datasets = [
              {
                label: "Utilized Amount",
                backgroundColor: ["#4092DF"],
                data: [],
                stack: "Stack 0",
              },
              {
                label: "Available Limit",
                data: [],
                backgroundColor: ["#71A50C"],

                stack: "Stack 0",
              },
            ];
            for (let i = 0; i < res.data.fundAllocationResponse.length; i++) {
              let programName = res.data.fundAllocationResponse[i].programName;
              labels.push(programName);
              if (res.data.fundAllocationResponse[i].availableLimit >0) {
                datasets[1].data.push(
                  res.data.fundAllocationResponse[i].availableLimit
                );
              }
             if (res.data.fundAllocationResponse[i].availableLimit <= 0){
                datasets[1].data.push(0);
              }
              if (res.data.fundAllocationResponse[i].utilizedAmount > 0) {
                datasets[0].data.push(
                  res.data.fundAllocationResponse[i].utilizedAmount
                );
              }
              if (res.data.fundAllocationResponse[i].utilizedAmount <= 0){
                datasets[0].data.push(0)
              }
            }

            this.stackedBarChart.data.labels = labels;
            this.stackedBarChart.data.datasets = datasets;
          } else {
            this.showChartA = false;
            this.stackedBarChart = {
              type: "bar",
              options: {
                min: 0,
                max: 10,
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                },
                scales: {
                  x: {
                    Stacked: true,
                    ticks: {
                      // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                      callback: function(val) {
                        let characterLimit = 11;
                        let xAxisLabel = val;
                        if (
                          this.getLabelForValue(val).length >= characterLimit
                        ) {
                          xAxisLabel =
                            this.getLabelForValue(val)
                              .slice(0, this.getLabelForValue(val).length)
                              .substring(0, characterLimit - 1)
                              .trim() + "...";
                        } else {
                          xAxisLabel = this.getLabelForValue(val);
                        }
                        return xAxisLabel;
                      },
                    },
                  },
                  y: {
                    min: 0,
                    Stacked: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                },
              },
              data: {
                labels: [""],
                datasets: [
                  {
                    label: "",
                    backgroundColor: [""],
                    data: [],
                    stack: "Stack 0",
                  },
                ],
              },
            };
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          this.showChartA = false;
          this.stackedBarChart = {
            type: "bar",
            options: {
              min: 0,
              max: 10,
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
              scales: {
                x: {
                  Stacked: true,
                  ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                    callback: function(val) {
                      let characterLimit = 11;
                      let xAxisLabel = val;
                      if (this.getLabelForValue(val).length >= characterLimit) {
                        xAxisLabel =
                          this.getLabelForValue(val)
                            .slice(0, this.getLabelForValue(val).length)
                            .substring(0, characterLimit - 1)
                            .trim() + "...";
                      } else {
                        xAxisLabel = this.getLabelForValue(val);
                      }
                      return xAxisLabel;
                    },
                  },
                },
                y: {
                  min: 0,
                  Stacked: true,
                  ticks: {
                    precision: 0,
                  },
                },
              },
            },
            data: {
              labels: [""],
              datasets: [
                {
                  label: "",
                  backgroundColor: [""],
                  data: [],
                  stack: "Stack 0",
                },
              ],
            },
          };
        });
    },
    // to get tha doughnut Chart data of Fund Allocation
    async dashboardGetFoundAllocation(programId) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramId: parseInt(programId),
        },
      };
      await axios
        .get("/Dashboard/GetFoundAllocation", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            if((res.data.utilizedAmount==0) && (res.data.availableLimit==0)){
              this.showChartB = false;
            }
            else{
              this.showChartB = true;
            let utilizedAmount = Number(
              String(res.data.utilizedAmount).replace(/[^\d]/g, "")
            );
            let availableLimit = Number(
              String(res.data.availableLimit).replace(/[^\d]/g, "")
            );
            this.pieChart.data.labels = ["Utilized Amount", "Available Limit"];
            this.pieChart.data.datasets = [
              {
                backgroundColor: ["#4092DF", "#71A50C"],
                data: [utilizedAmount, availableLimit],
              },
            ];
            }
            
          } else {
            this.showChartB = false;
            this.pieChart = {
              type: "doughnut",
              data: {
                labels: ["Utilized Amount", "Available Limit"],
                datasets: [
                  {
                    backgroundColor: ["#4092DF", "#71A50C"],
                    data: [""],
                  },
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: "right",
                  },
                },
              },
            };
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          this.showChartB = false;
          this.pieChart = {
            type: "doughnut",
            data: {
              labels: ["Utilized Amount", "Available Limit"],
              datasets: [
                {
                  backgroundColor: ["#4092DF", "#71A50C"],
                  data: [""],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "right",
                },
              },
            },
          };
        });
    },
    // to get tha doughnut Chart data of Application
    async dashboardGetApplicationStatus(programId) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartC = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramId: parseInt(programId),
        },
      };
      await axios
        .get("/Dashboard/GetApplicationStatus", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            this.showChartC = true;
            this.ApplicationStatPieChart.data.labels = [
              "No Action",
              "Started",
              "In-progress",
              "Funded",
            ];
            this.ApplicationStatPieChart.data.datasets = [
              {
                backgroundColor: ["#A81132", "#ffb74d", "#4092DF", "#71A50C"],
                data: [
                  res.data.invitedNoActionTaken,
                  res.data.activatedAccount,
                  res.data.submitted,
                  res.data.amountdisbursed,
                ],
              },
            ];
          } else {
            this.showChartC = false;
            this.ApplicationStatPieChart = {
              type: "doughnut",
              data: {
                labels: ["No Action", "Started", "In-progress", "Funded"],
                datasets: [
                  {
                    backgroundColor: [
                      "#A81132",
                      "#ffb74d",
                      "#4092DF",
                      "#71A50C",
                    ],
                    data: [""],
                  },
                ],
              },
              options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "right",
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  footer: (ttItem) => {
                    let sum = 0;
                    let dataArr = ttItem[0].dataset.data;
                    dataArr.map((data) => {
                      sum += Number(data);
                    });

                    let percentage =
                      ((ttItem[0].parsed * 100) / sum).toFixed(2) + "%";
                    return `Percentage: ${percentage}`;
                  },
                },
              },
              datalabels: {
                display: true,
                anchor: "center",
                align: "center",
                offset: 20,
                borderWidth: 0,
                formatter: (value, dnct1) => {
                  let sum = 0;
                  let dataArr = dnct1.chart.data.datasets[0].data;
                  dataArr.map((data) => {
                    sum += Number(data);
                  });

                  let percentage = ((value * 100) / sum).toFixed(2); // + "%";
                  if (percentage < 1) {
                    percentage = "";
                  } else {
                    percentage = percentage + "%";
                  }
                  return percentage;
                },
                color: "#FFFFFF",
              },
            },
          },
          plugins: [ChartJSPluginDatalabels],
            };
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          this.showChartC = false;
          this.ApplicationStatPieChart = {
            type: "doughnut",
            data: {
              labels: ["No Action", "Started", "In-progress", "Funded"],
              datasets: [
                {
                  backgroundColor: [],
                  data: [""],
                },
              ],
            },
            options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "right",
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  footer: (ttItem) => {
                    let sum = 0;
                    let dataArr = ttItem[0].dataset.data;
                    dataArr.map((data) => {
                      sum += Number(data);
                    });

                    let percentage =
                      ((ttItem[0].parsed * 100) / sum).toFixed(2) + "%";
                    return `Percentage: ${percentage}`;
                  },
                },
              },
              datalabels: {
                display: true,
                anchor: "center",
                align: "center",
                offset: 20,
                borderWidth: 0,
                formatter: (value, dnct1) => {
                  let sum = 0;
                  let dataArr = dnct1.chart.data.datasets[0].data;
                  dataArr.map((data) => {
                    sum += Number(data);
                  });

                  let percentage = ((value * 100) / sum).toFixed(2); // + "%";
                  if (percentage < 1) {
                    percentage = "";
                  } else {
                    percentage = percentage + "%";
                  }
                  return percentage;
                },
                color: "#FFFFFF",
              },
            },
          },
          plugins: [ChartJSPluginDatalabels],
          };
        });
    },
    // to get tha stacked Bar Chart data of Program Application Stat
    async dashboardGetProgramApplicationStatus(programId, pageNumber) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartD = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramId: parseInt(programId),
          pageNumber: parseInt(pageNumber),
        },
      };
      await axios
        .get("/Dashboard/GetProgramApplicationStatus", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            this.ProgramApplicationStatus.totalSize = res.data.totalSize;
            this.ProgramApplicationStatus.pageEnd = res.data.pageEnd;
            this.ProgramApplicationStatus.pageNumber = res.data.pageNumber;
            this.ProgramApplicationStatus.previousPage = res.data.previousPage;
            this.ProgramApplicationStatus.nextPage = res.data.nextPage;
            this.showChartD = true;
            let chartData = {
              labels: [],
              datasets: [],
            };
            let datasets = [
              {
                label: "No Action",
                backgroundColor: ["#A81132"],
                data: [],
                stack: "Stack 0",
              },
              {
                label: "Started",
                backgroundColor: ["#ffb74d"],
                data: [],
                stack: "Stack 0",
              },
              {
                label: "In-progress",
                data: [],
                backgroundColor: ["#4092DF"],
                stack: "Stack 0",
              },
              {
                label: "Funded",
                data: [],
                backgroundColor: ["#71A50C"],
                stack: "Stack 0",
              },
            ];
            for (const data of Object.keys(res.data.programApplicationStatus)) {
              let programName = data;
              let programData = res.data.programApplicationStatus[data];
              chartData.labels.push(programName);
              if (programData.amountdisbursed >= 0) {
                datasets[3].data.push(programData.amountdisbursed);
              }
              if (programData.submitted >= 0) {
                datasets[2].data.push(programData.submitted);
              }
              if (programData.activatedAccount >= 0) {
                datasets[1].data.push(programData.activatedAccount);
              }
              if (programData.noActionTaken >= 0) {
                datasets[0].data.push(programData.noActionTaken);
              }
            }
            chartData.datasets = datasets;
            this.ApplicationstackedBarChart.data.labels = chartData.labels;
            this.ApplicationstackedBarChart.data.datasets = chartData.datasets;
          } else {
            this.showChartD = false;
            this.ApplicationstackedBarChart = {
              type: "bar",
              options: {
                min: 0,
                max: 10,
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                },
                scales: {
                  x: {
                    Stacked: true,
                  },
                  y: {
                    min: 0,
                    Stacked: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                },
              },
              data: {
                labels: [""],
                datasets: [
                  {
                    label: "",
                    backgroundColor: [""],
                    data: [],
                    stack: "Stack 0",
                  },
                ],
              },
            };
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          this.showChartD = false;
          this.ApplicationstackedBarChart = {
            type: "bar",
            options: {
              min: 0,
              max: 10,
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
              scales: {
                x: {
                  Stacked: true,
                },
                y: {
                  min: 0,
                  Stacked: true,
                  ticks: {
                    precision: 0,
                  },
                },
              },
            },
            data: {
              labels: [""],
              datasets: [
                {
                  label: "",
                  backgroundColor: [""],
                  data: [],
                  stack: "Stack 0",
                },
              ],
            },
          };
        });
    },
    async dashboardGetAffiliateFundAllocation(programId, pageNumber) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartF = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramId: parseInt(programId),
          pageNumber: parseInt(pageNumber),
        },
      };
      await axios
        .get("/Dashboard/GetAffiliateFundAllocation", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            this.AffiliateFundAllocation.totalSize = res.data.totalSize;
            this.AffiliateFundAllocation.pageEnd = res.data.pageEnd;
            this.AffiliateFundAllocation.pageNumber = res.data.pageNumber;
            this.AffiliateFundAllocation.previousPage = res.data.previousPage;
            this.AffiliateFundAllocation.nextPage = res.data.nextPage;
            this.showChartF = true;
            let chartData = {
              labels: [],
              datasets: [],
            };
            let datasets = [
              {
                label: "Utilized Amount",
                backgroundColor: ["#4092DF"],
                data: [],
                stack: "Stack 0",
              },
            ];
            for (const key in res.data.affiliateFundAllocationStatus) {
              chartData.labels.push(key);
              datasets[0].data.push(
                res.data.affiliateFundAllocationStatus[key].utilizedAmount
              );
            }

            chartData.datasets = datasets;
            this.AffiliateFundAllocationStatBarChart.data.labels =
              chartData.labels;
            this.AffiliateFundAllocationStatBarChart.data.datasets =
              chartData.datasets;
          } else {
            this.showChartF = false;
            this.AffiliateFundAllocationStatBarChart = {
              type: "bar",
              options: {
                min: 0,
                max: 10,
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                },
                scales: {
                  x: {
                    Stacked: true,
                    ticks: {
                      // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                      callback: function(val) {
                        let characterLimit = 11;
                        let xAxisLabel = val;
                        if (
                          this.getLabelForValue(val).length >= characterLimit
                        ) {
                          xAxisLabel =
                            this.getLabelForValue(val)
                              .slice(0, this.getLabelForValue(val).length)
                              .substring(0, characterLimit - 1)
                              .trim() + "...";
                        } else {
                          xAxisLabel = this.getLabelForValue(val);
                        }
                        return xAxisLabel;
                      },
                    },
                  },
                  y: {
                    Stacked: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                },
                
             
              },
              data: {
                labels: [""],
                datasets: [
                  {
                    label: "",
                    backgroundColor: [""],
                    data: [],
                    stack: "Stack 0",
                  },
                ],
              },
            };
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          this.showChartF = false;
          this.AffiliateFundAllocationStatBarChart = {
            type: "bar",
            data: {
              labels: [],
              datasets: [
                {
                  backgroundColor: [""],
                  data: [""],
                },
              ],
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
            },
          };
        });
    },
    // to get tha stacked Bar Chart data of Affiliate Application Stat
    async dashboardGetAffiliateApplicationStatus(programId, pageNumber) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartE = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          ProgramId: parseInt(programId),
          pageNumber: parseInt(pageNumber),
        },
      };
      await axios
        .get("/Dashboard/GetAffiliateApplicationStatus", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            this.AffiliateApplicationStatus.totalSize = res.data.totalSize;
            this.AffiliateApplicationStatus.pageEnd = res.data.pageEnd;
            this.AffiliateApplicationStatus.pageNumber = res.data.pageNumber;
            this.AffiliateApplicationStatus.previousPage =res.data.previousPage;
            this.AffiliateApplicationStatus.nextPage = res.data.nextPage;
            this.showChartE = true;
            let chartData = {
              labels: [],
              datasets: [],
            };
            let datasets = [
              {
                label: "No Action",
                backgroundColor: ["#A81132"],
                data: [],
                stack: "Stack 0",
              },
              {
                label: "Started",
                backgroundColor: ["#ffb74d"],
                data: [],
                stack: "Stack 0",
              },
              {
                label: "In-progress",
                data: [],
                backgroundColor: ["#4092DF"],
                stack: "Stack 0",
              },
              {
                label: "Funded",
                data: [],
                backgroundColor: ["#71A50C"],
                stack: "Stack 0",
              },
            ];
            for (const data of Object.keys(
              res.data.affiliateApplicationStatus
            )) {
              let programName = data;
              let programData = res.data.affiliateApplicationStatus[data];
              chartData.labels.push(programName);
              if (programData.amountdisbursed >= 0) {
                datasets[3].data.push(programData.amountdisbursed);
              }
              if (programData.submitted >= 0) {
                datasets[2].data.push(programData.submitted);
              }
              if (programData.activatedAccount >= 0) {
                datasets[1].data.push(programData.activatedAccount);
              }
              if (programData.noActionTaken >= 0) {
                datasets[0].data.push(programData.noActionTaken);
              }
            }
            chartData.datasets = datasets;
            this.AffiliateFundAllocationstackedBarChart.data.labels =
              chartData.labels;
            this.AffiliateFundAllocationstackedBarChart.data.datasets =
              chartData.datasets;
          } else {
            this.showChartE = false;
            this.AffiliateFundAllocationstackedBarChart = {
              type: "bar",
              options: {
                min: 0,
                max: 10,
                responsive: true,
                plugins: {
                  legend: {
                    position: "bottom",
                  },
                },
                scales: {
                  x: {
                    Stacked: true,
                    ticks: {
                      // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                      callback: function(val) {
                        let characterLimit = 11;
                        let xAxisLabel = val;
                        if (
                          this.getLabelForValue(val).length >= characterLimit
                        ) {
                          xAxisLabel =
                            this.getLabelForValue(val)
                              .slice(0, this.getLabelForValue(val).length)
                              .substring(0, characterLimit - 1)
                              .trim() + "...";
                        } else {
                          xAxisLabel = this.getLabelForValue(val);
                        }
                        return xAxisLabel;
                      },
                    },
                  },
                  y: {
                    min: 0,
                    Stacked: true,
                    ticks: {
                      precision: 0,
                    },
                  },
                },
              },
              data: {
                labels: [""],
                datasets: [
                  {
                    label: "",
                    backgroundColor: [""],
                    data: [],
                    stack: "Stack 0",
                  },
                ],
              },
            };
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          this.showChartE = false;
          this.AffiliateFundAllocationstackedBarChart = {
            type: "bar",
            options: {
              min: 0,
              max: 10,
              responsive: true,
              plugins: {
                legend: {
                  position: "bottom",
                },
              },
              scales: {
                x: {
                  Stacked: true,
                  ticks: {
                    // For a category axis, the val is the index so the lookup via getLabelForValue is needed to trim the labels.
                    callback: function(val) {
                      let characterLimit = 11;
                      let xAxisLabel = val;
                      if (this.getLabelForValue(val).length >= characterLimit) {
                        xAxisLabel =
                          this.getLabelForValue(val)
                            .slice(0, this.getLabelForValue(val).length)
                            .substring(0, characterLimit - 1)
                            .trim() + "...";
                      } else {
                        xAxisLabel = this.getLabelForValue(val);
                      }
                      return xAxisLabel;
                    },
                  },
                },
                y: {
                  min: 0,
                  Stacked: true,
                  ticks: {
                    precision: 0,
                  },
                },
              },
            },
            data: {
              labels: [""],
              datasets: [
                {
                  label: "",
                  backgroundColor: [""],
                  data: [],
                  stack: "Stack 0",
                },
              ],
            },
          };
        });
    },
     async fetchDashboardFundAllocationDetail(programId) {
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        programId: parseInt(programId)
      };
      /*let params = {
          reportType:reportType,
          
          label:label
        }*/
      await axios
        .get("/Dashboard/FetchDashboardFundAllocationDetailByProgram", config)
        .then((res1) => {
          this.dashboardOverLay.rslt = "FundAllocation Detail";
          this.dashboardApplicationStatsDetail.title = "";
          this.dashboardOverLay.rslt = res1.data.fundAllocationDetail;
          this.chartModal.title = 'Fund Allocation Details';
           this.chartModal.name = "FundAllocationDetail";
           this.chartModal.items = res1.data.fundAllocationDetail;
          this.showCentralPopUp = true;
          console.log(this.dashboardOverLay.rslt);
          this.$store.dispatch("updateLoaderFlag", false);
           this.showChartB = true;
          this.showChart = 'showChartB';
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
    async fetchDashboardApplicationStatsDetail(programId)
    {
      
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        programId: parseInt(programId),
      };
     /* let params = {
          reportType:reportType,
          programId: parseInt(programId),
          label:label
        }*/
        
      await axios
        .get("/Dashboard/FetchDashboardApplicationStatsDetailByProgram", config)
        .then((res1) => {
              this.dashboardApplicationStatsDetail.title = 'Application Stats Details',
           this.dashboardApplicationStatsDetail.rslt = res1.data.applicationStatsDetail;
           this.chartModal.title = 'Application Stats Details';
           this.chartModal.name = "ApplicationStats";
           this.chartModal.items = res1.data.applicationStatsDetail;
           console.log(this.dashboardApplicationStatsDetail.rslt);
           this.showCentralPopUp = true;
       this.$store.dispatch("updateLoaderFlag", false);
          
         
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
    async fetchDashboardAffiliateWiseFundUtilizedDetailByProgram(programId)
    {
      
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        programId: parseInt(programId),
      };
     /* let params = {
          reportType:reportType,
          programId: parseInt(programId),
          label:label
        }*/
        
      await axios
        .get("/Dashboard/FetchDashboardAffiliateWiseFundUtilizedDetailByProgram", config)
        .then((res1) => {
              this.dashboardApplicationStatsDetail.title = 'Affiliate wise Fund Utilized',
           this.dashboardApplicationStatsDetail.rslt = res1.data.applicationStatsDetail;
           this.chartModal.title = 'Affiliate wise Fund Utilized';
           this.chartModal.name = "AffiliatewiseFundUtilized";
           this.chartModal.items = res1.data.fundDetailAffiliateWise;
           this.showCentralPopUp = true;
       this.$store.dispatch("updateLoaderFlag", false);
          
         
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
     async fetchDashboardAffiliateWiseApplicationStatsDetailByProgram(programId)
    {
      
      this.$store.dispatch("updateLoaderFlag", true);
      this.showChartB = false;
      let token = localStorage.getItem("token");
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        programId: parseInt(programId),
      };
     /* let params = {
          reportType:reportType,
          programId: parseInt(programId),
          label:label
        }*/
        
      await axios
        .get("/Dashboard/FetchDashboardAffiliateWiseApplicationStatsDetailByProgram", config)
        .then((res1) => {
              this.dashboardApplicationStatsDetail.title = 'Affiliate wise Fund Utilized',
           this.dashboardApplicationStatsDetail.rslt = res1.data.applicationStatsDetail;
           this.chartModal.title = 'Affiliate wise Application Stats';
           this.chartModal.name = "AffiliatewiseApplicationStats";
           this.chartModal.items = res1.data.applicationStatsDetailAffiliateWise;
           console.log(this.dashboardApplicationStatsDetail.rslt);
           this.showCentralPopUp = true;
       this.$store.dispatch("updateLoaderFlag", false);
          
         
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
          
        });
    },
    //ChartModel Paginations
    clearFilter(){
      this.chartModal.filter='';
    },
    ResetSort() {
      this.chartModal.filter='';
      this.chartModal.values.currentSort=''
      this.selectOptionToFilter='all';
      this.chartModal.unsort=true;
    },
    sortColumn(s) {
      this.chartModal.unsort = false;
      if (s === this.chartModal.values.currentSort) {
        this.chartModal.values.currentSortDir =
          this.chartModal.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.chartModal.values.currentSort = s;
    },
  setPages () {
			let numberOfPages = Math.ceil(this.filteredRows.length / this.chartModal.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.chartModal.values.pages.push(index);
			}
      
		},
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.chartModal.values.currentPage;
			let perPage = this.chartModal.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.chartModal.values.totalPages =Math.ceil(this.filteredRows.length / this.chartModal.perPage);
      if(this.chartModal.values.totalPages===0){
        this.chartModal.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.chartModal.items.data)
        return "";
      return this.chartModal.items.data.length;
    },
    backPage() {
      if (this.chartModal.values.currentPage > 1) {
        this.chartModal.values.currentPage--;
      }
    },
    nextPage() {
      if (this.chartModal.values.currentPage < this.chartModal.values.totalPages) {
        this.chartModal.values.currentPage++;
      }
    }
  },
  computed: {
    roleID: {
      get() {
        const roleID = this.$store.getters.getUserRoleID;
        return roleID;
      },
    },
    programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      },
    },
    isAllSelectForProgramInvitation:{
      get(){
        return this.$store.state.business.temporary.isAllSelectForProgramInvitation
      }
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    pieChart: {
      get() {
        let pieChart = {
          type: "doughnut",
          data: {
            labels: ["Utilized Amount", "Available Limit"],
            datalabels: {
              color: "#FFCE56",
            },
            datasets: [
              {
                backgroundColor: ["#4092DF", "#71A50C"],
                data: [""],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "right",
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  footer: (ttItem) => {
                    let sum = 0;
                    let dataArr = ttItem[0].dataset.data;
                    dataArr.map((data) => {
                      sum += Number(data);
                    });

                    let percentage =
                      ((ttItem[0].parsed * 100) / sum).toFixed(2) + "%";
                    return `Percentage: ${percentage}`;
                  },
                },
              },
              datalabels: {
                display: true,
                anchor: "center",
                align: "center",
                offset: 20,
                borderWidth: 0,
                formatter: (value, dnct1) => {
                  let sum = 0;
                  let dataArr = dnct1.chart.data.datasets[0].data;
                  dataArr.map((data) => {
                    sum += Number(data);
                  });

                  let percentage = ((value * 100) / sum).toFixed(2); // + "%";
                  percentage = percentage + "%";

                  return percentage;
                },
                color: "#FFFFFF",
              },
            },
             /*onClick: (e, activeEls) =>{//Fund Allocation
                let datasetIndex = activeEls[0].datasetIndex;
                let dataIndex = activeEls[0].index;
                let datasetLabel = e.chart.data.datasets[datasetIndex].label;
                let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
                let label = e.chart.data.labels[dataIndex];
                var chartTitleText = e.chart.titleBlock.options.text;
                this.dashboardOverLay.title = "Fund Allocation Details";
                this.dashboardOverLay.UtilizedAmount = "Utilized Amount"
                alert('chartTitleText is ',chartTitleText,'Clicked:', datasetLabel, 'Value:', value,'label',label);
                this.fetchDashboardDetailsByReportType("FUNDALLOCATION",label,this.programid);    
                            
                this.showChartB = true;
                this.showChart = 'showChartB';
//this.showCentralPopUp = true;

                },*/
          },
          plugins: [ChartJSPluginDatalabels],
        };
        return pieChart;
      },
    },
    displayedPosts() {
			return this.paginate(this.filteredRows);
		},
    sortTable() {
      let lists = this.chartModal.items;
      let data;
      if(this.chartModal.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.chartModal.values.currentSortDir === "desc") modifier = -1;
        if (a[this.chartModal.values.currentSort] < b[this.chartModal.values.currentSort])
          return -1 * modifier;
        if (a[this.chartModal.values.currentSort] > b[this.chartModal.values.currentSort])
          return 1 * modifier;
        return 0;
      });

      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        /*const loanNumber = row.loanNumber.toString().toLowerCase();
        const dateApplied = row.dateApplied.toLowerCase();
        const businessName = row.businessName.toLowerCase();
         const affiliateName = row.affiliateName.toString().toLowerCase();
          // const loanProgramName = row.loanProgramName.toLowerCase();
        const loanAmount = row.loanAmount.toString().toLowerCase();//.toLowerCase();
        const applicationStatus = row.applicationStatus.toLowerCase();
        const searchTerm = this.chartModal.filter.toLowerCase();
        let filtedItems;
        if (this.chartModal.selectOptionToFilter == "all") {
          filtedItems =
            businessName.includes(searchTerm) ||
           loanNumber.includes(searchTerm) ||
           dateApplied.includes(searchTerm)||
            affiliateName.includes(searchTerm)||
                        // loanProgramName.includes(searchTerm) ||
            loanAmount.includes(searchTerm) ||
            applicationStatus.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "businessName") {
          filtedItems = businessName.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        if (this.selectOptionToFilter == "loanNumber") {
          filtedItems = loanNumber.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "affiliateName") {
          filtedItems = affiliateName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "dateApplied") {
          filtedItems = dateApplied.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        //     if (this.selectOptionToFilter == "loanProgramName") {
        //   filtedItems = loanProgramName.includes(searchTerm);
        // }
        if (this.selectOptionToFilter == "loanAmount") {
          filtedItems = loanAmount.includes(searchTerm);
        }
        if (this.chartModal.selectOptionToFilter == "applicationStatus") {
          if(searchTerm==''){
            filtedItems=true
          }
          else{
          filtedItems = searchTerm === "show all" ? true: applicationStatus===searchTerm  ;
          }
        }
        // this.items=filtedItems*/
        return row;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &--section {
    .dashboard--header {
      padding: 0rem 6%;
      &__heading-fillter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fillter {
          display: flex;
          label,
          input,
          select,
          option {
            font-size: 1.6rem;
            color: $primary-color-black;
            margin: 0;
            outline: none;
          }
          p {
            margin: 0;
            padding: 0;
          }
          select,
          input {
            border: none;
            outline: none;
            border-bottom: 0.2rem solid $tertiary-color-white;
            height: 3rem;
            width: 100%;
          }
        }
      }
    }
    .dashboard--main {
      height: calc(100vh - 20rem);
      padding: 0rem 6%;
      padding-bottom: 5rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background: $tertiary-color-white;
      }

      &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        height: 0.1rem;
        -webkit-border-radius: 2rem;
      }

      &::-webkit-scrollbar-corner {
        background: #000;
      }
      .charts {
        .chart {
          padding: 1rem 0rem;
        }
      }
      .quick-links {
        padding: 2rem 0rem;
        &--main {
          margin-top: 1rem;
          .links {
            cursor: pointer;
            font-size: 1.6rem;
            // text-decoration: underline;
          }
        }
      }
    }
    .heading {
      font-size: 2.6rem;
      font-weight: bold;
      text-transform: capitalize;
      color: $primary-color-red;
      margin: 0;
      @media screen and (max-width: 1600px) {
        font-size: 2.4rem;
      }
    }
  }
}

.central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 70%;
    min-width: 70%;
    width: auto;
    padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px) {
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      p {
        font-size: 1.6rem;
        text-align: center;
        color: $primary-color-black;
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }
}

.table-heading {
  h3 {
    font-size: 2.6rem;
    font-weight: bold;
    text-transform: capitalize;
  }
}
.reset-button {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  height: 3.5rem;
  width: 3.5rem;
  border: 1px solid transparent;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  img{
    height: 100%;
  }
}
.search {
  display: flex;
  align-items: flex-end;
  label,
  input,
  select,
  option {
    outline: none;
    font-size: 1.6rem;
    color: $primary-color-black;
    margin: 0;
  }
  select,
  input {
    min-height: 3.8rem;
    width: auto;
  }
  input {
    border: none;
    outline: none;
    border-bottom: 1px solid $primary-color-black;
    border-radius: 0rem;
  }
}
.sort {
  display: flex;
  align-items: center;
  p,
  input {
    margin-right: 1rem;
  }
  input {
    border: 0rem;
    outline: none;
    border-bottom: 1px solid $secondary-color-white;
    font: normal normal medium;
  }
}
.filter {
  width: 14.6rem;
  height: 3.6rem;
  background: $tertiary-color-white 0% 0% no-repeat padding-box;
  border-radius: 0.3rem;
  button {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    outline: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.tabel-header {
  &__data {
    display: flex;
    align-items: center;
    // &__title {
    // }
    .functional-icons {
      opacity: 0;
    }
  }
  .show-icon,
  &:hover,
  &:active {
    .functional-icons {
      opacity: 1;
    }
  }
}
.table-view {
  p {
    padding: 0;
    margin: 0;
    font: normal normal 300 1.4rem;
    color: $primary-color-black;
    text-transform: capitalize;
  }
  &__header {
    min-height: 6rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .sort {
      display: flex;
      align-items: center;
      p,
      input {
        margin-right: 1rem;
      }
      input {
        border: 0rem;
        outline: none;
        border-bottom: 1px solid $secondary-color-white;
        font: normal normal medium;
      }
    }
    .filter {
      width: 14.6rem;
      height: 3.6rem;
      background: $tertiary-color-white 0% 0% no-repeat padding-box;
      border-radius: 0.3rem;
      button {
        height: 100%;
        width: 100%;
        border: 1px solid transparent;
        outline: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    &--right{
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      @media screen and(max-width:580px) {
        
      }
      
      .search{
        display: flex;
            align-items: center;
        justify-content: end;
      flex-wrap: wrap;
      }
    }
    @media screen and (max-width:580px) {
      justify-content: start;
      display: flex;
      flex-direction: column;
      .search-by-program,
      .search-by-filter,
      .search-by-input{
        width: 95vw;
      }
      .search-by-program__filter,
      .search-by-filter,
      .search-by-input{
        &__select
        ,&__input{
          width: 100%;

        }
      }
      

    }
  }
  &__table {
     overflow-x: hidden;
    overflow-y: auto;
    border-top: 2px solid $secondary-color-white;
    border-bottom: 2px solid $secondary-color-white;
    padding: 0.5rem 0rem 0.5rem 0rem;//2rem 0rem;
    overflow-x: auto;
    .table {
      width: 100%;
      min-width: 65rem;
      thead {
        border-bottom: 2px solid $secondary-color-white;
        tr {
          height: 3.5rem;
          th {
            font-size: 1.4rem;
            vertical-align: top;
            border: none;
          }
        }
      }
      .tbody {
        padding:.5rem;
        height: 45vh;
        display: block;
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
                width: .3rem;
                background: $tertiary-color-white;
            }

            &::-webkit-scrollbar-thumb {
                background:#bdbdbd;
                height: .1rem;
                -webkit-border-radius: 2rem;
            }

            &::-webkit-scrollbar-corner {
                background: #000;
            }
        tr {
          border: 1px solid transparent;
          height: 4.5rem;
          cursor: pointer;
          td {
            font-size: 1.4rem;
            color: #4d4d4f;
            padding-top: 1.5rem;
            line-height: 2rem;
            a {
              color: $color-blue;
            }
            p{
              overflow-wrap: break-word;
            }
            
          }
          .status {
            display: inline-block;
            padding: 0.5rem 1rem;
            background: #eeeeee 0% 0% no-repeat padding-box;
            border-radius: 3rem;
            &::before {
              display: inline-block;
              content: "";
              border-radius: 50% 50%;
              height: 0.5rem;
              width: 0.5rem;
              // font-size: 1.4rem;
              border: 1px solid transparent;
              background-color: $color-green;
              padding: 0.1rem;
              margin-right: 0.5rem;
            }
          }
          &:hover {
            box-shadow: 0.1rem 0.1rem 0.5rem $primary-color-grey;
            border-radius: 0.3rem 0.3rem 0rem 0rem;
          }
        }
      }
    }
  }
  td {
    a{
    text-decoration: none;
  }
    text-align: left;
    font: normal normal normal 1.4rem/2rem;
    color: $tertiary-color-grey;
    border-top: 0rem;
  }
  thead, tbody tr {
    border: none;
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  &__footer{
    .pagination{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &__container,span{
        display: flex;
        align-items: center;
        div{
            padding:.5rem 1rem;
        }
        p,label,input{
            font: normal normal normal 1.2rem/2rem;
            color: $tertiary-color-grey;
            padding: .2rem;
            margin: 0;
        }
        &__row-per-page{
            span{
                display: flex;
                
                input{
                    width: 4rem;
                    border: none;
                    outline: none;
                   
                }
            }
        }
        &__inc-dec{
            display: flex;
            align-items: center;
            button,p{
                height: 3rem;
                width: 3rem;
                font-weight: bold;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.5rem;
            }
            p{
                border: 1px solid $primary-color-red;
                border-radius: .5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color-red;
                cursor: pointer;
            }
        }
        
    }
}
  }
  
}
      
  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
      justify-content: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
  
     table, tr td {
   
}
tbody {
    display: block;
    height: 50px;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
    width: 400px;
}  
</style>
