import axios from "axios";

const state={

}
const getters = {};
const actions = {
    GetConsolidatedReportData:(vueContext,payload)=>{
        let token = localStorage.getItem("token");
        let config = {
          headers: { Authorization: `Bearer ${token}` },
         
        };
          return new Promise((resolve, reject) => {
            axios.post("Admin/GetConsolidatedReportData", payload,config)
              .then((res) => {
                if (res.status === 200) {
                  resolve(res);
                }
              })
              .catch((err) => {
                reject(err);
              });
          });
      },
       exportConsolidatedReport:(vueContext,payload)=>{
        let token = localStorage.getItem("token");
        let config = {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        };
        if (config) {
          return new Promise((resolve, reject) => {
            axios.post('admin/ExportConsolidatedReport', payload, config).then(res => {
              if (res.status === 200) {
                let blob = new Blob([res.data], { type: res.headers["content-type"] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "CumulativeReport";
                link.click();
                resolve(res)
              }
            })
              .catch(err => {
                reject(err)
              })
          })
        }
      },
      fetchReportDetailByReportType:(vueContext,payload)=>{
        let token = localStorage.getItem("token");
        let config = {
          headers: { Authorization: `Bearer ${token}` },
         
        };
          return new Promise((resolve, reject) => {
            axios.post("Admin/FetchReportDetailByReportType", payload,config)
              .then((res) => {
                if (res.status === 200) {
                  resolve(res);
                }
              })
              .catch((err) => {
                reject(err);
              });
          });
      },
}
const mutations ={

}
export default {
    state,
    getters,
    actions,
    mutations,
  };