<template>
  <div class="fundDetail">
    <div class="fundDetail__container">
      <div class="fundDetail__body">
        <div class="row justify-content-between"  
        v-if="isPaymentSchedule==true">
          <!--<div class="  col-sm-3 col-12 form-group">
            <input
              disabled
              type="text"
              placeholder=" "
              name="fundDetail-fundRequestedAmount"
              id="fundDetail-fundRequestedAmount"
              v-money3="config"
              maxlength="22"
              v-model="fundDetail.paymentScheduleSummary.fundRequestedAmount"
            />
            <label for="fundDetail-fundRequestedAmount" class="form__label ">
              {{ labels.paymentScheduleSummary.fundRequestedAmount }}
            </label>
          </div>-->
          <div class="  col-sm-3 col-12 form-group">
            <input
              disabled
              type="text"
              placeholder=" "
              name="fundDetail-fundAllocatedAmount"
              id="fundDetail-fundAllocatedAmount"
              v-money3="config"
              maxlength="22"
              v-model="
                fundDetail.paymentScheduleSummary.fundAllocatedAmount
              "
            />
            <label for="fundDetail-fundAllocatedAmount" class="form__label ">
              {{ labels.paymentScheduleSummary.fundAllocatedAmount }}
            </label>
          </div>
          <div class="  col-sm-3 col-12 form-group">
            <input
              disabled
              type="text"
              placeholder=" "
              name="fundDetail-fundDisbursedAmount"
              id="fundDetail-fundDisbursedAmount"
              v-money3="config"
              maxlength="22"
              v-model="
                fundDetail.paymentScheduleSummary.fundDisbursedAmount
              "
            />
            <label for="fundDetail-fundDisbursedAmount" class="form__label ">
              {{ labels.paymentScheduleSummary.fundDisbursedAmount }}
            </label>
          </div>
          <div class="  col-sm-3 col-12 form-group">
            <input
              disabled
              type="text"
              placeholder=" "
              name="fundDetail-fundPendingAmount"
              id="fundDetail-fundPendingAmount"
              v-money3="config"
              maxlength="22"
              v-model="fundDetail.paymentScheduleSummary.fundPendingAmount"
            />
            <label for="fundDetail-fundPendingAmount" class="form__label ">
              {{ labels.paymentScheduleSummary.fundPendingAmount }}
            </label>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="  col-sm-3 col-12 form-group">
            <input
            :disabled="isPaymentSchedule==true"
              required
              type="text"
              placeholder=" "
              name="fundDetail-FundedAmount"
              id="fundDetail-FundedAmount"
              v-money3="config"
              maxlength="22"
              v-model="fundDetail.FundedAmount"
            />
            <label for="fundDetail-FundedAmount" class="form__label required">
              {{ labels.FundedAmount }}
            </label>
            <Error v-if="v$.fundDetail.FundedAmount.$error">
              <p v-for="(error, index) of v$.$errors" :key="index">
                <small v-if="error.$propertyPath === 'fundDetail.FundedAmount'">
                  {{ error.$message }}
                </small>
              </p>
            </Error>
          </div>
          <div class="   col-sm-3 col-12 form-group">
            <input
              type="number"
              placeholder=" "
              name="fundDetail-OriginatingBankAccount"
              id="fundDetail-OriginatingBankAccount"
              v-model="fundDetail.OriginatingBankAccount"
            />
            <label
              for="fundDetail-OriginatingBankAccount"
              class="form__label required"
            >
              {{ labels.OriginatingBankAccount }}
            </label>
          </div>
          <div class="   col-sm-3 col-12 form-group">
            <input
              required
              type="number"
              placeholder=" "
              name="fundDetail-AccountNumber"
              id="fundDetail-AccountNumber"
              v-model="fundDetail.DestinationBankAccount"
            />
            <label for="fundDetail-AccountNumber" class="form__label required">
              {{ labels.destinationBankAccount }}
            </label>
            <Error v-if="v$.fundDetail.DestinationBankAccount.$error">
              <p v-for="(error, index) of v$.$errors" :key="index">
                <small
                  v-if="
                    error.$propertyPath === 'fundDetail.DestinationBankAccount'
                  "
                >
                  {{ error.$message }}
                </small>
              </p>
            </Error>
          </div>
          <div class="   col-sm-3 col-12 form-group">
            <input
              required
              type="number"
              placeholder=" "
              name="fundDetail-RoutingNumber"
              id="fundDetail-RoutingNumber"
              v-model="fundDetail.BankRoutingNumber"
            />
            <label for="fundDetail-RoutingNumber" class="form__label required">
              {{ labels.bankRoutingNumber }}
            </label>
            <Error v-if="v$.fundDetail.BankRoutingNumber.$error">
              <p v-for="(error, index) of v$.$errors" :key="index">
                <small
                  v-if="error.$propertyPath === 'fundDetail.BankRoutingNumber'"
                >
                  {{ error.$message }}
                </small>
              </p>
            </Error>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="  col-sm-3 col-12 form-group">
            <flat-pickr
              :config="Dateconfig"
              required
              :disabled="isPaymentSchedule==true"
              name="fundDetail-DateOfFunding"
              id="fundDetail-DateOfFunding"
              v-model="fundDetail.DateOfFunding"
              class="datepicker"
            >
            </flat-pickr>
            <label for="fundDetail-DateOfFunding" class="form__label required">
              {{ labels.DateOfFunding }}
            </label>
            <Error v-if="v$.fundDetail.DateOfFunding.$error">
              <p v-for="(error, index) of v$.$errors" :key="index">
                <small
                  v-if="error.$propertyPath === 'fundDetail.DateOfFunding'"
                >
                  {{ error.$message }}
                </small>
              </p>
            </Error>
          </div>
          <div class="col-sm-9 col-12 form-group">
            <input
              required
              type="text"
              name="fundDetail-AddNotes"
              id="fundDetail-AddNotes"
              placeholder=" "
              v-model="fundDetail.AddNotes"
            />
            <label for="fundDetail-AddNotes" class="form__label select-label">
              {{ labels.AddNotes }}
            </label>
            <Error v-if="v$.fundDetail.AddNotes.$error">
              <p v-for="(error, index) of v$.$errors" :key="index">
                <small v-if="error.$propertyPath === 'fundDetail.AddNotes'">
                  {{ error.$message }}
                </small>
              </p>
            </Error>
          </div>
        </div>
        <div
          class="row fundDetail__body__listingTable mt-4"
          v-if="isPaymentSchedule==true"
        >
          <div class="table-view__table col-12">
            <table class="table">
              <thead>
                <tr class="row  m-0 ">
                  <th class="tabel-header col col-sm-1">Select</th>
                  <th class="tabel-header col col-sm-3">Transaction Date</th>
                  <th class="tabel-header col col-sm-3">Funding Amount</th>
                  <th class="tabel-header col col-sm-3">Status</th>
                  <th class="tabel-header col col-sm-1">Action</th>
                </tr>
              </thead>
              <tbody class="tbody">
                <tr
                  class="row  m-0"
                  v-for="paymentScheduleTransaction in fundDetail.paymentScheduleTransaction"
                  :key="paymentScheduleTransaction.paymentScheduleID"
                >
                  <td class="col col-sm-1">
                    <span>
                      <label :for="'paymentScheduleTransaction-check'+paymentScheduleTransaction.paymentScheduleID">
                        <input
                          type="radio"
                          :disabled="!paymentScheduleTransaction.isEnabled"
                          name="paymentScheduleTransaction-check"
                          @change="updateAmount(paymentScheduleTransaction)"
                          :id="'paymentScheduleTransaction-check'+paymentScheduleTransaction.paymentScheduleID"
                          :value="paymentScheduleTransaction.paymentScheduleID"
                          v-model="paymentScheduleTransactionId"
                        />
                      </label>
                    </span>
                  </td>
                  <td class="col col-sm-3">
                    <span>
                      <p>{{ paymentScheduleTransaction.transactionDate }}</p>
                    </span>
                  </td>
                  <td class="col col-sm-3">
                    <span>
                      <p>
                        {{ paymentScheduleTransaction.fundedAmountFormat }}
                      </p>
                    </span>
                  </td>
                  <td class="col col-sm-3">
                    <span>
                      <p>
                        {{ paymentScheduleTransaction.transactionStatusID==1?'Pending':'' }}
                      </p>
                    </span>
                  </td>
                  <td class="col col-sm-1">
                    <span @click="routeToBusiness(paymentScheduleTransaction.businessID)">
                      <img
                        src="@/assets/imgs/pencil.svg"
                        alt="edit Icon"
                        title="Edit the payment transaction"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class=" form-file row justify-content-between mt-4">
          <label class="form__label form-file__label  col-sm-2 col-3 pl-0"
            >{{ labels.document }}
          </label>
          <input
            ref="fileInput"
            class="form__label form-file__label  col-sm-2 col-2 px-1"
            type="file"
            name="fundDetail-document"
            id="fundDetail-document"
            v-bind="fundDetail.document.documentTypeID"
            @input="fundDetail.document"
            @change="showPreview($event, fundDetail.document, labels.document)"
          />
          <a
            :href="fundDetail.document.fileName"
            class="downloadLink px-3 col-sm-8 col-7 "
            v-text="fundDetail.document.fileName"
            @click.prevent="download(fundDetail.document)"
          />
        </div>
      </div>
      <hr />
      <div class="fundDetail__footer px-2 py-0">
        <div class="forms-btns row m-0 p-0">
          <div class="forms-btns__left col-2"></div>

          <div class=" forms-btns__right col d-flex justify-content-end p-0">
            <!--***** If tab is other than review then show next button ******-->
            <!-- Else show button from commandflow and call the api based on that -->
            <span class="btn--primary ml-5">
              <BtnPrimary :buttonName="`Submit`"  class="m-0" @click="save()" />
            </span>
          </div>
        </div>
      </div>
      <div class="central-popup" v-if="showCentralPopUp == true">
        <div class="central-popup__main">
          <span class="close-btn">
            <button @click="showCentralPopUp = false">
              <img src="@/assets/imgs/cancel.svg" alt="close button" />
            </button>
          </span>
          <div>
            <div class="central-popup__main__heading py-0 px-2 m-0">
              <h3>Notification</h3>
            </div>

            <div class="MainDiv row m-0 p-0">
              <div class="col-11 py-3 m-0 p-0">
                <p>
              {{CentralPopUpMessage}}
                </p>
              </div>
            </div>
          </div>
          <div>
            <div class=" d-flex justify-content-center pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary
                    :buttonName="`ok`"
                    @click="routeTrack()"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BtnPrimary from "@/components/BtnPrimary.vue";
import textStore from "@/textStore.js";
import Error from "@/components/Error.vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import { Money3Directive } from "v-money3";
export default {
  directives: {
    money3: Money3Directive,
  },
  components: {
    Error,
    BtnPrimary,
    flatPickr,
  },
  data() {
    return {
      paymentScheduleTransactionId:0,
      showCentralPopUp:false,
      CentralPopUpMessage:'',
      labels: {},
      Dateconfig: {
        dateFormat: "m/d/Y",
        disableMobile: "true",
      },
      selectedPaymentScheduleTransaction:{},
      isSaveButtonEnabled:false
    };
  },
  setup() {
    return {
      v$: useVuelidate(),
      textStore,
    };
  },
  props: {
    selectedProgramInvitationID: {
      type: Number,
      default: null,
    },
    selectedProgramStatus: {
      type: String,
      default: "",
    },
    selectedLoanApplicationID: {
      type: Number,
      default: null,
    },
  },
  validations() {
    return {
      fundDetail: {
        FundedAmount: { required },
        DateOfFunding: { required },
        BankRoutingNumber: { required, numeric },
        DestinationBankAccount: { required, numeric },
        AddNotes: { required, $lazy: true, $autoDirty: true },
        document: { required, $lazy: true, $autoDirty: true },
      },
    };
  },
  computed: {
    isPaymentSchedule(){
        return this.$store.state.borrower.fundingData.inputs.isPaymentSchedule;
    },
    config() {
      return {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 0,
        disableNegative: true,
        masked: false /* doesn't work with directive */,
      };
    },
    fundDetail: {
      get() {
        Object.assign(this.labels, textStore.FundingDataInBorrower);
        return this.$store.state.borrower.fundingDetails;
      },
    },
  },
  created() {
    
    this.$store.state.borrower.fundingDetails.FundedAmount= ""
    this.$store.state.borrower.fundingDetails.DateOfFunding= ""
    this.$store.state.borrower.fundingDetails.AccountNumber= ""
    this.$store.state.borrower.fundingDetails.RoutingNumber= ""
    this.$store.state.borrower.fundingDetails.OriginatingBankAccount= ""
    this.$store.state.borrower.fundingDetails.DestinationBankAccount= ""
    this.$store.state.borrower.fundingDetails.BankRoutingNumber= ""
    this.$store.state.borrower.fundingDetails.AddNotes= ""    
    this.paymentScheduleTransactionId=0;
    if((this.fundDetail.paymentScheduleTransaction.length > 0) &&(this.isPaymentSchedule==true)){
    this.updateAmount(this.fundDetail.paymentScheduleTransaction[0])
    this.paymentScheduleTransactionId=this.fundDetail.paymentScheduleTransaction[0].paymentScheduleID;
     }
    
  },
  methods: {
    routeTrack(){
              this.$router.push("/tracks");
    },
    routeToBusiness(businessId){
      this.$store.state.business.programInvitationActiveTab="fundingDetails";
    this.$router.push('/programinvitation/form/'+businessId+'/'+this.$store.getters.applicationID)
    },
    updateAmount(paymentScheduleTransactionData) {
      this.fundDetail.FundedAmount =paymentScheduleTransactionData.fundedAmountFormat;
      this.fundDetail.DateOfFunding =paymentScheduleTransactionData.transactionDate;
      this.selectedPaymentScheduleTransaction=paymentScheduleTransactionData
      this.isSaveButtonEnabled = paymentScheduleTransactionData.isEnabled;
    },
    numericValue(event) {
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.slice(0, 12).replace(/[^0-9]/g, "");
      this.fundDetail.OriginatingBankAccount = value;
      return event.target.value;
    },
    async validationsStore() {
      const isFormCorrect = await this.v$.$validate();

      if (isFormCorrect == true) {
        return await this.$store
          .dispatch("UpdateFundDetail", this.fundDetail, { root: true })
          .then(() => {
            return true;
          })
          .catch((err) => {
            Promise.reject(err);
            return false;
          });
      }
    },
    async save() {
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.fundDetail.FundedAmount = Number(
          String(this.fundDetail.FundedAmount).replace(/[^\d]/g, "")
        );

        if (this.fundDetail.FundedAmount <= 0) {
          this.toasterMessage("", "Grant Amount should not be $ 0", "danger");
        } else {
          let param = {
            storeData: this.$store.state.borrower,
            triggerdCommandName: this.$store.getters.commandname,
            applicationID: this.$store.getters.applicationID,
            selectedProgramInvitationID: this.$store.getters
              .programInvitationID,
          };
          this.fundDetail.paymentScheduleTransaction=[this.selectedPaymentScheduleTransaction]
          this.$store.dispatch("updateLoaderFlag", true);
          this.$store
            .dispatch("SaveApplication", param, { root: true })
            .then((res) => {
              
                if (res.data.isSuccess == true) {
                  
              this.toasterMessage("",res.data.message,"success");
              this.$router.push("/tracks");
              this.$store.dispatch("updateLoaderFlag", false);
                }
                
                else if(res.data.isAvailableLimitExceeds==true){
                  this.showCentralPopUp=true
              this.$store.dispatch("updateLoaderFlag", false);
                this.CentralPopUpMessage=`${res.data.message}`
                }
                else{
              this.$store.dispatch("updateLoaderFlag", false);                 
                this.toasterMessage("", res.data.message, "danger");
                }



              /** Get workflow command and button for submit loan application */
              this.$store
                .dispatch("getWorkFlowCommand",this.selectedLoanApplicationID,{ root: true })
                .then((workflowCommand) => {
                  this.workFlowCommandList = workflowCommand.data;
                  // this.workflowCommandForReviewtab.buttonName = workflowCommand.data.workFlowCommands[0].name;
                })
                .catch((err) => {
                  this.$store.dispatch("updateLoaderFlag", false);
                  Promise.reject(err);
                });
            })
            .catch((err) => {
              Promise.reject(err);
              this.$store.dispatch("updateLoaderFlag", false);
              this.toasterMessage( "","Error while processing request","danger"
              );
            });
        }
      }
    },
    showPreview(e, item, documentName, count) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.newFiles = e.target.files;
      var data = {
        file: this.newFiles[0],
        documentTypeID: item.documentTypeID,
        documentName: documentName,
        count: count,
      };
      if (this.newFiles.length) {
        const fileSelected = this.$refs.fileInput;
        fileSelected.type = "text";
        fileSelected.type = "file";
        //upload files
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("UploadFile", data)
          .then((res) => {
            if (res.status === 200) {
              this.fundDetail.document.fileName = data.file.name;
              this.fundDetail.document.documentTypeID = 7;
              this.fundDetail.document.documentGUID = res.data.documentID;
              this.fundDetail.document.physicalFileStorageKey =
                res.data.storageKey;
              this.fundDetail.document.fileSize = res.data.fileSize;
              this.fundDetail.document.documentName = "Funding Detail Document";
              const fileSelected = this.$refs.fileInput;
              fileSelected.type = "text";
              fileSelected.type = "file";
              this.$store.dispatch("updateLoaderFlag", false);
              this.toasterMessage("", "File uploaded Successfully", "success");
            }
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", "Unsupported file", "danger");
            Promise.reject(err);
          });
      }
    },
    download(item) {
      var data = {
        physicalFileStorageKey: item.physicalFileStorageKey,
        fileName: item.fileName,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("Download", data)
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.fundDetail {
  &__container {
    height: 60vh;
    overflow-y: auto;
    padding: 0.5rem;
    .row {
      margin: 0;
    }
    .fundDetail__body {
      padding: 1rem 0rem;
      padding-right: 2rem;
      max-height: 85%;
      overflow-y: auto;
      &__listingTable {
        @include table-style;
        .table td {
          padding: 0.5rem;
          vertical-align: middle;
          border: none;
        }
        .table-view__table .table .tbody{
          height: auto;
        }
      }
    }

    .form-group {
      padding: 0;
      position: relative;
      @include input-style;
    }
    .form-file {
      label,
      input {
        font-size: 1.6rem;
        @media screen and (max-width: 1600px) {
          font-size: 1.4rem;
        }
      }
    }
    .forms-btns {
      margin: 0.5rem 0rem;
      @media screen and (max-width: 1600px) {
        margin: 1rem 0rem;
      }
      button {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0rem;
        margin-top: 1.5rem;
        height: 4rem;
      }
      .btn--secondary {
        width: 16.5rem;
      }
      .btn--primary {
        width: 12.7rem;
      }
      &__left {
        padding: 0rem;
        .btn--tertiary {
          button {
            position: relative;
            &:before {
              position: absolute;
              left: 0;
              content: "<";
            }
          }
        }
      }
    }
    .central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 20vh;
    min-width: 40%;
    width: auto;
    padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px) {
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      p {
        font-size: 1.6rem;
        text-align: center;
        color: $primary-color-black;
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }

  .LabelNote,
  .input-values {
    padding: 1rem;
    // margin: 1rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $primary-color-black;
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
}
  }
  .downloadLink {
    font-size: 1.6rem;
    word-wrap: break-word;
    max-width: 25rem;
    width: auto;
  }
}
.m-0:disabled {
  background: grey;
}
</style>
