<template>
   <div class="errorPage">
    <div class="errorPage-bg">
      
    </div>
    <div class="errorPage-section row justify-content-center">
      <div class="errorPage-section__form col ">
        <div class="img-section">
        <img
            src="@\assets\imgs\GraphicsAuthorization.svg"
            alt="logo"
          />  
        </div>
        <div class="form-titles ">
         
          <h1>
            {{ activateAccountTitle }}
          </h1>
          
        </div>
        <div class="errorPage-title px-2 px-sm-0">
          <h3> Error 401. We are sorry, the page you are <br/>trying to access has restricted access.</h3>
        </div>
        <div >
              <BtnPrimary buttonName="Go to Home" @click="gotoHome" />
        </div>

      </div>
      
    </div>
    <footer class="row p-0 m-0">
      <div class="row p-0 m-0 col-12 footer">
        <div class="col-sm-3 col-12 px-3 footer-left">
          <p>© 2021 national urban league</p>
        </div>
        <div class="col-sm-3 col-12 px-3 footer-right">
          <a
            href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/NUL_Website_Privacy_Policy(BuildBackBlack).docx"
            >Privacy Policy
          </a>
          <div>&nbsp;</div>
          <a
            href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/Terms_of_Use_(BuildBackBlack).docx"
            >Terms of Use
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import BtnPrimary from "@/components/BtnPrimary.vue";
//import axios from "axios";
export default {
    components: {
        BtnPrimary,
    },
    data() {
        return {
            activateAccountTitle: "Oops! No Authorization Found",
        };
    },
    methods: {
        gotoHome() {
            
                        this.$router.push('/tracks')
            
        },

    },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.errorPage {
    position: relative;
    overflow: hidden;
    .errorPage-bg {
        height: 100vh;
        background: $secondary-color-white 0% 0% no-repeat padding-box;
        opacity: 1;
        display: flex;
        flex-direction: column;
    }
    .errorPage-section {
        max-width: calc(100vw - 10%); 
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: calc(100vh - 20%); //88rem;
        max-height: 100%;
        background: $primary-color-white 0% 0% no-repeat padding-box;
        border-radius: 5.8rem;
        opacity: 1;
        margin: auto;
        &__form {
            background-color: $secondary-color-pink;
            // max-width: 50%;
            border-radius: 5.8rem;
            padding: 5rem 6rem;
             display: grid;
            justify-content: space-around;
            .img-section{
                max-width: 60rem;
                    min-width: 25rem;
                img{
                    width: 100%;
                }
                
            }
            @media screen and (max-width: 570px){
                padding: 1rem 2rem;
            }
            .form-titles {
                color: $primary-color-black;
                h1 {
                    font-size: 4rem;
                    font-weight: bold;
                    line-height: 5rem;
                    text-transform: capitalize;
                    @media screen and (max-width: 1600px) {
                        font-size: 3rem;
                    }
                   @media screen and (max-width: 570px) {
                            font-size: 2rem;
                            line-height: 3rem;
                            margin-top: 1rem;
                            vertical-align: middle;
                            // width: 300px;
                        }
                    
                }
            }
            .errorPage-title {
                h3 {
                    font-size: 3.2rem; //3rem;
                    font-weight: 400; // font-weight: bold;
                    color: $primary-color-red;
                }
                padding: 5rem 0rem 0rem 0rem;
                @media screen and (max-width: 1600px) {
                    padding: .5rem 0rem 0rem 0rem;
                    h3 {
                        font-size: 2.5rem;
                    }
                    h6 {
                        font-size: 1.2rem;
                    }
                }
                @media screen and (max-width: 1400px) {
                    h3 {
                        font-size: 2rem;
                    }
                }
                @media screen and (max-width: 570px){
                    h3 {
                        font-size: 1.8rem;
                    }
                }
            }

        }
        &__img {
            border-radius: 0 5.8rem 5.8rem 0;
            height: 100%;
            max-width: 50%;
            background: url('./../assets/imgs/Group8.png'), linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)); // rgba(255, 255, 255, 0);
            background-size: cover;
            background-blend-mode: saturation;
            background-repeat: no-repeat;
            background-position: cover;
            color: $primary-color-white;
            padding: 3rem 5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-top,
            &-bottom {
                max-width: 45%;
                color: $primary-color-white;
                h6 {
                    font-size: 1.4rem;
                    font-weight: lighter;
                    a {
                        font-size: 1.4rem;
                        color: $primary-color-white;
                        text-decoration: underline;
                    }
                }
                h1 {
                    font-size: 4.2rem;
                    font-weight: bold;
                }
            }
            @media screen and (max-width: 1600px) {
                padding: 1rem 4rem;
                h1 {
                    font-size: 4rem;
                }
                h6,
                a {
                    font-size: 1.2rem;
                }
            }
        }
    }
    footer {
    position: absolute; // width: 100%;
    max-width: calc(100vw - 10%); //172rem;
    width: 100%;
    margin: 0;
    bottom: 0%;
    left: 50%; // top: 50%;
    transform: translate(-50%, -50%); // @media screen and (max-width: 1600px) {
    //   width: 80%;
    // }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0rem;
      a,
      p {
        font-size: 1.2rem;
        line-height: 1.9rem;
        color: $primary-color-black;
        text-transform: capitalize;
      }
      &-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        div {
          // content: "";
          height: 1.5rem;
          max-width: 0.1rem;
          background-color: $secondary-color-white;
          margin: 0 1rem;
        }
        
      }
      @media screen and (max-width: 756px) {
           top:40px;
          left:40px;
        }
        @media screen and (max-width: 570px) {
           top:20px;
          left:0px;
          &-right,
          &-left{
            display: flex;
            justify-content: center;
          }
        }
    }
  }
}
</style>