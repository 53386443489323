<template>
  <div class="table-view">
      
    <div class="table-view__table">

      <table class="table">
        <thead>
          <tr class="row  m-0 ">
            <th @click="sortCol('transactionDate')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='transactionDate'?'show-icon':''" >
                <p class="tabel-header__title">Transaction Date</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('fundingTypeName')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='fundingTypeName'?'show-icon':''">
                <p class="tabel-header__title">Funding Type</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('fundedAmountFormat')" class="tabel-header col col-sm-3">
              <span class="tabel-header__data" :class="values.currentSort=='fundedAmountFormat'?'show-icon':''">
                <p class="tabel-header__title">Amount</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortCol('transactionStatusName')" class="tabel-header col col-sm-2" >
              <span class="tabel-header__data" :class="values.currentSort=='transactionStatusName'?'show-icon':''">
                <p class="tabel-header__title">Status</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th class="tabel-header col-3" v-if="(userRoleID == 1|| userRoleID == 4 || userRoleID == 3|| userRoleID == 7)">
              <p class="tabel-header__title">Action</p>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr v-for="item in displayedPosts" :key="item" class="row  m-0">
            <td class="col col-sm-2">
              <p v-html="highlightMatches(item['transactionDate'])"></p> 
            </td>
            <td class="col col-sm-2" >
              <p v-html="highlightMatches(item['fundingTypeName'])"></p>
            </td>
            <td class="col col-sm-3">
              <p v-html="highlightMatches(item['fundedAmountFormat'])"></p> 
            </td>
            <td class="col col-sm-2" >
              <p v-html="highlightMatches(item['transactionStatusName'])"></p>
            </td>
            <td class=" col-3" >
              <div>
                <span class="d-flex justify-content-between align-items-start " v-if="(userRoleID == 1|| userRoleID == 4 || userRoleID == 3|| userRoleID == 7) && item.transactionStatusID==1">             
              <span class="d-flex justify-content-between align-items-center ">
                <button class="icons-button p-0"  @click="GetPaymentScheduleTransactionById(item)"> 
                  <img src="@/assets/imgs/pencil.svg" alt="edit Icon" title="Edit the payment transaction">
                  </button>
                <!-- <button class="icons-button p-0" @click="RemovePaymentScheduleTransaction(item)"> -->
                  <button class="icons-button p-0" @click="deletePaymentScheduleTransaction(item)">
                  <img src="@/assets/imgs/Delete.svg" alt="delete Icon" title="Delete the payment transaction">
                   </button>
                    <button v-show="false" class="icons-button p-2" @click="TiggerManualNotification(item)">
                      <img src="@/assets/imgs/send-email.png" alt="Trigger Manual Notification" title="Trigger Manual Notification">
                   </button>
              </span>
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  components: {
    
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type:Number,
      default:10
      },
      TableHeight:{
        type:Number,
      },
    columns: {
      type: Array,
    }
  },
  data() {
    return {
      filter: "",
      list: this.items,
      unsort:false,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages:[],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
    };
  },
  beforeUpdate() {
    this.list = this.items;
  },
  methods: {
    clearFilter(){
      this.filter='';
    },
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;
    },
    highlightMatches(text) {
      let matchExists 
      
      if (!matchExists || this.filter==null) return text;
else{
          matchExists = text.toLowerCase().includes(this.filter);
      }
      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    RemovePaymentScheduleTransaction(item){
      this.$emit('RemovePaymentScheduleTransaction', item)
    },
    deletePaymentScheduleTransaction( item){
      this.$emit('deletePaymentScheduleTransaction', item)
      // deletePaymentScheduleTransaction
    },
    GetPaymentScheduleTransactionById(item){
      this.$emit('GetPaymentScheduleTransactionById', item)
    },
    TiggerManualNotification(item){
      this.$emit('TiggerManualNotification', item)
    }
    ,
    sortCol(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.values.currentPage;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.values.totalPages =Math.ceil(posts.length / this.perPage);
      if(this.values.totalPages===0){
        this.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.filteredRows)
        return "";
      return this.filteredRows.length;
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
      
    setTableHeight(){
      return 'height: ' + this.TableHeight +'vh';
    },
    sortTable() {
      let lists = this.list;
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        const transactionDate = row.transactionDate.toString().toLowerCase();
        const fundingTypeName = row.fundingTypeName.toString().toLowerCase();
        const fundedAmountFormat = row.fundedAmountFormat.toString().toLowerCase();
        const transactionStatusName = row.transactionStatusName.toString().toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            transactionDate.includes(searchTerm) ||
            fundingTypeName.includes(searchTerm) ||
            fundedAmountFormat.includes(searchTerm) ||
            transactionStatusName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "transactionDate") {
          filtedItems = transactionDate.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "fundingTypeName") {
          filtedItems = fundingTypeName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "fundedAmountFormat") {
          filtedItems = fundedAmountFormat.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "transactionStatusName") {
          filtedItems = transactionStatusName.includes(searchTerm); 
        }
        return filtedItems;
      });
    },
    displayedPosts() {
			return this.paginate(this.filteredRows);
		},
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    }
  },
  watch: {
		posts () {
			this.setPages();
		}
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
  @include table-style;
  .table-view{
    .icons-button{
      height: 3rem;
      width: 3rem;
      background-color: transparent;
      border: none;
      img {
        height: 100%;
      }
      .icons-trigger-button{
        height: .5rem;
      width: .5rem;
      background-color: transparent;
      border: none;
      img {
        height: 100%;
      }
        }
      @media screen and(max-width:580px) {
          width: 2rem;
          img {
          width: 100%;
      }
        }
    }
    .table-view__header{
      min-height: 1rem;
    }
    .table-view__table .table .tbody{
      height: auto;
    }
    .table-view__table .table .tbody tr{
      height: 2rem;
      td{
      padding: .5rem;
      vertical-align:middle;
    }
    }
     
    .table-view__footer .pagination{
      padding: .1rem;
    }
  }

</style>
