<template>
  <div class="table-view">
    <div class="table-view__table" :style="setTableHeight">
      <table class="table">
        <thead>
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index"
              on:click="sortTable(column)"
              scope="col"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="item in displayedPosts"
            :key="item"
            @click="openpopUP(item)"
          >
            <td>
              <p>{{ item["businessName"] }}</p>
            </td>
            <td>
              <p>{{ item["programName"] }}</p>
            </td>
            <td>
              <p>{{ item["programStatus"] }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ items.length }}
              {{ values.totalPages > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="central-popup" v-if="showCentralPopUP === true">
    <div class="central-popup__main">
      <span class="close-btn">
        <button @click="showCentralPopUP = false">
          <img src="@/assets/imgs/cancel.svg" alt="" />
        </button>
      </span>
      <div>
        <span class="central-popup__main__heading">
          <h3 class="px-5">{{ CentralPopUPLabel }}</h3>
        </span>
      </div>
      <div>
        <div class=" d-flex justify-content-center">
          <div class="mr-5">
            <span>
              <BtnPrimary :buttonName="`Yes`" @click="routeToProfile()" />
            </span>
          </div>
          <div class="p-0">
            <span>
              <BtnSecondary
                class="btn-secondary"
                :buttonName="`No`"
                @click="routeToLoanApplication()"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>
<script>
// import BtnPrimary from "@/components/BtnPrimary.vue";
// import BtnSecondary from "@/components/BtnSecondary.vue";

// import Pagination from "@/components/Pagination.vue"
export default {
  components: {
    // BtnSecondary,
    // BtnPrimary,
    // Pagination
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    TableHeight: {
      type: Number,
    },
    columns: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      selectDataitem: {},
      showCentralPopUP: false,
      CentralPopUPLabel:
        "Do you want to update Business Profile before proceeding to Grant Application ?",
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages: [],
        totalPages: 1,
        currentPage: 1,
        currentSort: [this.items == 1][this.columns == 1],
        currentSortDir: "asc",
      },
      //columns: ["Id", "Business Name", "Program Name", "Status"]
    };
  },
  methods: {
    routeToProfile() {
      this.$store.state.borrower.businessId = this.selectDataitem.businessID;
      this.$store.state.borrower.isFromProgramInvitationTOProfile = true;
      this.$router.push("/profile");
    },
    openpopUP(item) {
      this.selectDataitem = item;
      this.routeToLoanApplication()
      // this.showCentralPopUP = true;
    },
    routeToLoanApplication() {
      this.$store.state.borrower.isFromProgramInvitationTOProfile = false;
      this.selectId(this.selectDataitem);
    },
    selectId(item) {
      this.$emit("selectRow", item.id, item.programStatus);
    },
    sort: function(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.items.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.values.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      this.values.totalPages = Math.ceil(this.items.length / this.perPage);
      return posts.slice(from, to);
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
    setTableHeight() {
      return "height: " + this.TableHeight + "vh";
    },
    displayedPosts() {
      return this.paginate(this.items);
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
};
</script>
<style lang="scss" scoped>
.table-view {
  p {
    padding: 0;
    margin: 0;
    font: normal normal 300 1.4rem;
    color: $primary-color-black;
    text-transform: capitalize;
  }
  &__header {
    height: 6rem; //7rem;// height: 7.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sort {
      display: flex;
      align-items: center;
      p,
      input {
        margin-right: 1rem;
      }
      input {
        border: 0rem;
        outline: none;
        border-bottom: 1px solid $secondary-color-white;
        font: normal normal medium;
      }
    }
    .filter {
      width: 14.6rem;
      height: 3.6rem;
      background: $tertiary-color-white 0% 0% no-repeat padding-box;
      border-radius: 0.3rem;
      button {
        height: 100%;
        width: 100%;
        border: 1px solid transparent;
        outline: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
  }
  &__table {
    // overflow-x: hidden;
    // overflow-y: auto;
    border-top: 2px solid $secondary-color-white;
    border-bottom: 2px solid $secondary-color-white;
    padding: 1.5rem 0rem 3rem 0rem; //2rem 0rem;
    .table {
      width: 100%;
      thead {
        border-bottom: 2px solid $secondary-color-white;
        tr {
          height: 3.5rem;
          th {
            font-size: 1.4rem;
            vertical-align: top;
            border: none;
          }
        }
      }
      .tbody {
        padding: 0.5rem;
        min-height: 10vh;
        display: block;
        width: 100%;
        overflow: auto;
        tr {
          border: 1px solid transparent;
          height: 4.5rem;
          cursor: pointer;
          td {
            font-size: 1.4rem;
            color: #4d4d4f;
            padding-top: 1.5rem;
            line-height: 2rem;
            a {
              color: $color-blue;
            }
            p{
              overflow-wrap: break-word;
            }
          }
          .status {
            display: inline-block;
            padding: 0.5rem 1rem;
            background: #eeeeee 0% 0% no-repeat padding-box;
            border-radius: 3rem;
            &::before {
              display: inline-block;
              content: "";
              border-radius: 50% 50%;
              height: 0.5rem;
              width: 0.5rem;
              // font-size: 1.4rem;
              border: 1px solid transparent;
              background-color: $color-green;
              padding: 0.1rem;
              margin-right: 0.5rem;
            }
          }
          &:hover {
            box-shadow: 0.1rem 0.1rem 0.5rem $primary-color-grey;
            border-radius: 0.3rem 0.3rem 0rem 0rem;
          }
        }
      }
    }
  }
  td {
    a {
      text-decoration: none;
    }
    text-align: left;
    font: normal normal normal 1.4rem/2rem;
    color: $tertiary-color-grey;
    border-top: 0rem;
  }
  thead,
  tbody tr {
    border: none;
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  &__footer{
    .pagination{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &__container,span{
        display: flex;
        align-items: center;
        div{
            padding:.5rem 1rem;
             @media screen and(max-width:570px){
               padding:.5rem .2rem;
             }
        }
        p,label,input{
            font: normal normal normal 1.2rem/2rem;
            color: $tertiary-color-grey;
            padding: .2rem;
            margin: 0;
        }
        &__row-per-page{
            span{
                display: flex;
                
                input{
                    width: 4rem;
                    border: none;
                    outline: none;
                    background-color: transparent;
                }
            }
        }
        &__inc-dec{
            display: flex;
            align-items: center;
            button,p{
                height: 3rem;
                width: 3rem;
                font-weight: bold;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.5rem;
            }
            p{
                border: 1px solid $primary-color-red;
                border-radius: .5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color-red;
                cursor: pointer;
            }
        }
        
        
    }
    @media screen and(max-width:570px){
               padding:1rem .2rem;
             }
}
  }
}
.central-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    z-index: 999;
    padding: 2rem;
    min-height: 20vh;
    // min-width: 60%;
    position: relative;
    // max-width: 55rem;
    width: auto;
    border: 1px solid gray;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__heading {
      h3 {
        margin-right: 2rem;
        font-size: 2.2rem;
        font-weight: bold;
        // text-transform: capitalize;
        color: $primary-color-red;
      }
    }
    .close-btn {
    position: absolute;
    top: 5%;
    right: 2%;
    button {
      background: #f7f7f7 0% 0% no-repeat padding-box;
      height: 3.5rem;
      width: 3.5rem;
      border: 1px solid transparent;
      border-radius: 50%;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      //  img{
      //   //  max-height: 2.2rem;
      //    width: auto;
      //  }
    }
  }
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
  
}
</style>
