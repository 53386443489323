<template>
  <div class="helpfulGuide pr-2">
    <div class="helpfulGuide__section BusinessProfile">
      <div class="heading d-flex justify-content-between pb-1">
        <h3>
          <b>{{ Heading.BusinessProfile }}</b>
        </h3>
        <span class="hide-show-btn pr-2">
          <span class="checkbox-section pr-3">
            <label class="checkbox-section__label" for="checkBusinessProfile">
              Default Guide:
            </label>
            <input
              class="checkbox-section__input"
              v-model="isDefaultData.BusinessProfile"
              type="checkbox"
              @change="checkBoxCheckd('checkBusinessProfile')"
              id="checkBusinessProfile"
            />
          </span>

          <button @click="isHide.BusinessProfile = !isHide.BusinessProfile">
            <img
              v-if="isHide.BusinessProfile == false"
              src="@/assets/imgs/arrow-down.png"
              alt="down arrow"
            />
            <img
              v-if="isHide.BusinessProfile == true"
              src="@/assets/imgs/arrow-up.png"
              alt="up arrow"
            />
          </button>
        </span>
      </div>
      <div v-show="isHide.BusinessProfile == true">
        <QuillEditor
          ref="helpfulGuideBusinessProfile"
          theme="snow"
          :toolbar="toolbarOptions"
        />
      </div>
      <hr />
    </div>
    <div class="helpfulGuide__section FundingApplication">
      <div class="heading d-flex justify-content-between pb-1">
        <h3>
          <b>{{ Heading.FundingApplication }}</b>
        </h3>
        <span class="hide-show-btn pr-2">
          <span class="checkbox-section pr-3">
            <label class="checkbox-section__label" for="checkFundingApplication">
              Default Guide:
            </label>
            <input
              v-model="isDefaultData.FundingApplication"
              type="checkbox"
              @change="checkBoxCheckd('checkFundingApplication')"
              id="checkFundingApplication"
              class="checkbox-section__input"
            />
          </span>
          <button
            @click="isHide.FundingApplication = !isHide.FundingApplication"
          >
            <img
              v-if="isHide.FundingApplication == false"
              src="@/assets/imgs/arrow-down.png"
              alt="down arrow"
            />
            <img
              v-if="isHide.FundingApplication == true"
              src="@/assets/imgs/arrow-up.png"
              alt="up arrow"
            />
          </button>
        </span>
      </div>
      <div v-show="isHide.FundingApplication == true">
        <QuillEditor
          ref="helpfulGuideFundingApplication"
          theme="snow"
          :toolbar="toolbarOptions"
        />
      </div>
      <hr />
    </div>
    <div class="helpfulGuide__section Documents">
      <div class="heading d-flex justify-content-between pb-1">
        <h3>
          <b>{{ Heading.Documents }}</b>
        </h3>
        <span class="hide-show-btn pr-2">
          <span class="checkbox-section pr-3">
            <label class="checkbox-section__label" for="checkDocuments">
              Default Guide:
            </label>
            <input
              v-model="isDefaultData.Documents"
              type="checkbox"
              @change="checkBoxCheckd('checkDocuments')"
              id="checkDocuments"
              class="checkbox-section__input"
            />
          </span>
          <button @click="isHide.Documents = !isHide.Documents">
            <img
              v-if="isHide.Documents == false"
              src="@/assets/imgs/arrow-down.png"
              alt="down arrow"
            />
            <img
              v-if="isHide.Documents == true"
              src="@/assets/imgs/arrow-up.png"
              alt="up arrow"
            />
          </button>
        </span>
      </div>
      <div v-show="isHide.Documents == true">
        <QuillEditor
          ref="helpfulGuideDocuments"
          theme="snow"
          :toolbar="toolbarOptions"
        />
      </div>
      <hr />
    </div>
    <div class="helpfulGuide__section Review">
      <div class="heading d-flex justify-content-between pb-1">
        <h3>
          <b>{{ Heading.Review }}</b>
        </h3>
        <span class="hide-show-btn pr-2">
          <span class="checkbox-section pr-3">
            <label class="checkbox-section__label" for="checkReview">
              Default Guide: </label
            ><input
              v-model="isDefaultData.Review"
              type="checkbox"
              @change="checkBoxCheckd('checkReview')"
              id="checkReview"
              class="checkbox-section__input"
            />
          </span>
          <button @click="isHide.Review = !isHide.Review">
            <img
              v-if="isHide.Review == false"
              src="@/assets/imgs/arrow-down.png"
              alt="down arrow"
            />
            <img
              v-if="isHide.Review == true"
              src="@/assets/imgs/arrow-up.png"
              alt="up arrow"
            />
          </button>
        </span>
      </div>
      <div v-show="isHide.Review == true">
        <QuillEditor
          ref="helpfulGuideReview"
          theme="snow"
          :toolbar="toolbarOptions"
        />
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export default {
  data() {
    return {
      data: "<p> Hello </p>",
      helpfullGuide: [],
      Heading: {
        BusinessProfile: "Business Profile",
        FundingApplication: "Funding Application",
        Documents: "Documents",
        Review: "Review",
      },
      isHide: {
        BusinessProfile: false,
        FundingApplication: false,
        Documents: false,
        Review: false,
      },
      isDefaultData: {
        BusinessProfile: false,
        FundingApplication: false,
        Documents: false,
        Review: false,
      },
      content: {
        BusinessProfile: "<p> </p>",
        FundingApplication: "<p> </p>",
        Documents: "<p> </p>",
        Review: "<p> </p>",
      },
      toolbarOptions: [
        ["bold", "italic", "underline", "strike", "link"], // toggled buttons
        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ direction: "rtl" }], // text direction
        [{ align: [] }],
        ["clean"], // remove formatting button
      ],
    };
  },
  computed: {
    editorHelpfulGuideBusinessProfile() {
      return this.$refs.helpfulGuideBusinessProfile;
    },
    editorHelpfulGuideFundingApplication() {
      return this.$refs.helpfulGuideFundingApplication;
    },
    editorHelpfulGuideDocuments() {
      return this.$refs.helpfulGuideDocuments;
    },
    editorHelpfulGuideReview() {
      return this.$refs.helpfulGuideReview;
    },
  },
  components: {
    QuillEditor,
  },
  created() {},
  mounted() {
    this.GetHelpfulGuideTemplate();
    this.editorHelpfulGuideBusinessProfile.setHTML(
      this.content.BusinessProfile
    );
    this.editorHelpfulGuideFundingApplication.setHTML(
      this.content.FundingApplication
    );
    this.editorHelpfulGuideDocuments.setHTML(this.content.Documents);
    this.editorHelpfulGuideReview.setHTML(this.content.Review);
  },

  methods: {
    checkBoxCheckd(checkBoxName) {
      if(checkBoxName==='checkBusinessProfile'){
      if (this.isDefaultData.BusinessProfile === true) {
        this.editorHelpfulGuideBusinessProfile.setHTML(
          "<p><h4 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-weight: 400; line-height: 1.2; font-size: 2.4rem; text-align: left; text-indent: 0px;'>Helpful Guide and FAQs</h4></p><p><h6 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-size: 1.5rem;line-height: 1.2; text-align: left;'><b>Fill Requirements</b></h6></p><p>The information provided should be for the business not the business owner.</p><p>Please complete your business profile to start your application and register your business into our system.</p><p>To know more information on NAICS code, Please click on the below link:</p><p style='font-size: 1.6rem;'><a href ='https://www.naics.com/search' target = '_blank' > NAICS Code </a ></p ><p><a style='font-size: 1.6rem;' href='mailto:contactus@buildbackblack.org' >Contact us</a></p>"
        );
      } else {
        if (this.helpfullGuide.length) {
          this.editorHelpfulGuideBusinessProfile.setHTML(
            this.helpfullGuide[0].body
          );
        } else
          this.editorHelpfulGuideBusinessProfile.setHTML(
            this.content.BusinessProfile
          );
      }
      }
      if(checkBoxName==='checkFundingApplication'){
      if (this.isDefaultData.FundingApplication === true) {
        this.editorHelpfulGuideFundingApplication.setHTML(
          "<p><h4 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-weight: 400; line-height: 1.2; font-size: 2.4rem; text-align: left; text-indent: 0px;'>Helpful Guide and FAQs</h4></p><p><h6 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-size: 1.5rem;line-height: 1.2; text-align: left;'><b>Fill Requirements</b></h6></p><p style='box-sizing: border-box; margin: 0px 0px 1rem; padding: 0px; font-size: 1.4rem;'>Funding amount must be $10,000.</p><p style='box-sizing: border-box; margin: 0px 0px 1rem; padding: 0px; font-size: 1.4rem;'><b style='box-sizing: border-box; margin: 0px; padding: 0px; font-weight: bolder;'>Purpose of Funds: </b>Provide brief description of need such as online ordering system, food delivery software, replacement oven, hire web designer.</p> </div> <p><br></p><p>This data is being collected for informational purposes.</p><p><a style='font-size: 1.6rem;' href='mailto:contactus@buildbackblack.org' >Contact us</a></p>"
        );
      } else {
        if (this.helpfullGuide.length) {
          this.editorHelpfulGuideFundingApplication.setHTML(
            this.helpfullGuide[1].body
          );
        } else
          this.editorHelpfulGuideFundingApplication.setHTML(
            this.content.FundingApplication
          );
      }
      }
      if(checkBoxName==='checkDocuments'){
      if (this.isDefaultData.Documents === true) {
        this.editorHelpfulGuideDocuments.setHTML(
          "<p><h4 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-weight: 400; line-height: 1.2; font-size: 2.4rem; text-align: left; text-indent: 0px;'>Helpful Guide and FAQs</h4></p><p>Proof of Ownership can include tax return with IRS Schedule C; copy of DBA Certificate; articles of incorporation; or articles of organization.</p><p>Please click the link to download Vendor ACH form.</p><p><a style='font-size: 1.6rem;' href ='https://uefdocumentsdev.blob.core.windows.net/applicationassets/NULVENDORACHFORM-I-Updated(11-2-17).xlsx'> Download </a ></p ><p><a style='font-size: 1.6rem;' href='mailto:contactus@buildbackblack.org' >Contact us</a></p>"
        );
      } else {
        if (this.helpfullGuide.length) {
          this.editorHelpfulGuideDocuments.setHTML(this.helpfullGuide[2].body);
        } else this.editorHelpfulGuideDocuments.setHTML(this.content.Documents);
      }
      }
      if(checkBoxName==='checkReview'){
      if (this.isDefaultData.Review === true) {
        this.editorHelpfulGuideReview.setHTML(
          "<p><h4 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-weight: 400; line-height: 1.2; font-size: 2.4rem; text-align: left; text-indent: 0px;'>Helpful Guide and FAQs</h4></p><p><h6 style='box-sizing: border-box; margin: 0px 0px 1.5rem; padding: 0px; font-size: 1.5rem;line-height: 1.2; text-align: left;'><u><b>Next Steps:</b></u></h6></p><p>1. Review your application for completeness.</p><p>2. Submit application to initiate the review process.</p><p>3. Your application will be reviewed to ensure all required information is provided. It will either be accepted as complete or additional information will be requested.</p><p>4. Once your application is accepted as complete, then it will be reviewed for approval.</p><p>5. You will be informed if application has been approved or rejected.</p><p>6. If your application is approved, you will get an email with a link to this review page to accepted our agreement terms.</p><p>7. Once you have accepted the terms of the agreement, the funding process will begin.</p><p>8. You will be notified by email that funds have been distributed to your account on the ACH form.</p><br/><p><a style='font-size: 1.6rem;' href='mailto:contactus@buildbackblack.org' >Contact us</a></p>"
        );
      } else {
        if (this.helpfullGuide.length) {
          this.editorHelpfulGuideReview.setHTML(this.helpfullGuide[3].body);
        } else this.editorHelpfulGuideReview.setHTML(this.content.Review);
      }
      }
    },
    async GetHelpfulGuideTemplate() {
      this.$store
        .dispatch(
          "GetHelpfulGuideTemplate",
          this.$store.state.fundSource.fundingSource.fundingSourceID
        )
        .then((res) => {
          let helpfullGuide = res.helpfulGuideTextViewResponse;
          if (helpfullGuide.length > 0) {
            this.editorHelpfulGuideBusinessProfile.setHTML(
              helpfullGuide[0].body
            );
            this.editorHelpfulGuideFundingApplication.setHTML(
              helpfullGuide[1].body
            );
            this.editorHelpfulGuideDocuments.setHTML(helpfullGuide[2].body);
            this.editorHelpfulGuideReview.setHTML(helpfullGuide[3].body);
            this.helpfullGuide = helpfullGuide;
          } else {
            this.editorHelpfulGuideBusinessProfile.setHTML(
              this.content.BusinessProfile
            );
            this.editorHelpfulGuideFundingApplication.setHTML(
              this.content.FundingApplication
            );
            this.editorHelpfulGuideDocuments.setHTML(this.content.Documents);
            this.editorHelpfulGuideReview.setHTML(this.content.Review);
          }
        })
        .catch(() => {});
    },
    async savehelpFulGuide() {
      this.$store.dispatch("updateLoaderFlag", true);

      let data = {
        businessProfileHelpfulGuideText: this.editorHelpfulGuideBusinessProfile.getHTML(),
        fundingApplicationHelpfulGuideText: this.editorHelpfulGuideFundingApplication.getHTML(),
        documentsHelpfulGuideText: this.editorHelpfulGuideDocuments.getHTML(),
        reviewHelpfulGuideText: this.editorHelpfulGuideReview.getHTML(),
      };

      await this.$store
        .dispatch("SaveOrUpdateHelpfulGuideTemplateData", data)
        .then((res) => {
          this.toasterMessage("", res.data.message, "success");
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          console.log(err);
          this.toasterMessage("", "Failed to save", "danger");
          this.$store.dispatch("updateLoaderFlag", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.helpfulGuide {
  .ql-container {
    min-height: 15rem;
  }
  .hide-show-btn {
    align-items: center;
    padding-right: 0.5rem !important;
    display: flex;
    justify-content: space-between;
    .checkbox-section {
      display: flex;
      align-items: center;
      &__label,
      &__input {
        font-size: 1.6rem;
        margin: 0 0.2rem;
      }
    }
    button {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      padding: 0.01rem;
      border: none;
      img {
        height: 50%;
      }
    }
  }
}
</style>
