<template>
  <div class="TrackingList">
    <HeaderNav />
    <div class="TrackingList--content">
      <div class="TrackingList--content__Header d-flex">
        <div class="TrackingList--content__Header--breadCrumb align-self-start">
          <BreadCrumb class="breadcrumb" :crumbs="crumbs" />
        </div>
        <span class="btn--primary"  v-if="userRoleID !=8">
          <BtnPrimary
            :buttonName="`New Contact`"
            @click="createContact"
          />
        </span>
    </div>
    <div class="TrackingList__table-section">
      <BusinessContactTable
        class="listingTable"
        :items="contactList"
        :columns="columns"
        :TableHeight="TableHeight"
        :perPage="itemsPerTable"
        @selectRow="getContactData"
        @fetchAllBusinessContacts="fetchAllBusinessContacts"
      />
    </div>
    </div>
    <LoadingMask v-if="isLoading===true" />
    <Footer />
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import BusinessContactTable from "@/components/BusinessContactsTable";
import BtnPrimary from "@/components/BtnPrimary.vue";
import BreadCrumb from "@/components/BreadCrumb";
export default {
  components: {
    HeaderNav,
    Footer,
    BusinessContactTable,
    BtnPrimary,
    BreadCrumb,
  },

  /**
   * After instance created, fetch master data
   * Store in vuex store for convenient while getting master data in component
   */
  created() {
     this.$store.dispatch("updateLoaderFlag",true)
    this.fetchAllBusinessContacts();
  },

  data() {
    return {
      welcomeName: "Welcome, ",
      LastModifiedDate: "Last Visited on" + " April 10, 2021",
      crumbs: ["Business Contacts"],
      itemsPerTable: 10,
      TableHeight: 52,
      columns: [
        "First Name",
        "Last Name",
        "Email address",
        "Account Status",
        "Status",
      ],
      contactList: [],
    };
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    userName() {
      return this.$store.getters.getUser; 
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
  methods: {
    fetchAllBusinessContacts(){
      this.$store
      .dispatch("fetchAllBusinessContacts", { root: true })
      .then((contacts) => {
          if (contacts.data.data && contacts.data.data.length > 0) {
            this.$store.dispatch("updateLoaderFlag",false)
            this.contactList = contacts.data.data;
          } 
      })
      .catch(err =>{
         this.$store.dispatch("updateLoaderFlag",false)
        Promise.reject(err);
      });
    },
    async createContact() {
        this.clearcache();
        this.$store.state.business.users.roles.associatedBusinesses=[]
        this.$router.push("/businessContact/"+0);
    },
    getContactData(contactID) {
      if (contactID > 0) {
        this.$router.push("/businessContact/"+contactID);
      }
  },
  clearcache(){
       this.$store.state.business.users.roles.isActive = true;
       this.$store.state.business.users.roles.contactID = 0;
       this.$store.state.business.users.roles.accountStatus = "";
       this.$store.state.business.users.roles.salutationID = "";
       this.$store.state.business.users.roles.firstName = "";
       this.$store.state.business.users.roles.middleName = "";
       this.$store.state.business.users.roles.lastName = "";
       this.$store.state.business.users.roles.emailAddress = "";
       this.$store.state.business.users.roles.phoneNo = "";
       this.$store.state.business.users.roles.businessRoleName = "";
       this.$store.state.business.users.roles.roleID = "";
      },
  }
};
</script>
<style lang="scss" scoped>
.TrackingList {
  &--content {
    padding: 0rem 2%;
    &__Header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn--primary {
        min-width: 19rem;
        button {
          font-size: 1.6rem;
          padding: 0 1rem;
        }
      }
    }
    &__name {
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
      
    }
    
  }
}
</style>