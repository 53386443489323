 <template>
     <div class="modal-header justify-content-center">
           <slot name="header">
            <h3 style="font-weight: bold;text-transform: capitalize;color: #C11439;margin: 0;font-size: x-large;">
            {{title}}
            </h3>
          </slot>
           
          </div>

          <div class="modal-body">
            <slot name="body">
              
              <table class="table m-4" style="width:100%" v-if="name == 'FundAllocationDetail'">
                  <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                    <tr>
                      <th class="tabel-header  col col-sm-3 ">
                      <p class="tabel-header__title"> Funding Entity Name</p>
                      </th>
                      <th class="tabel-header  col col-sm-3 ">
                      <p class="tabel-header__title">Program Name</p>
                      </th>
                      <th class="tabel-header col col-sm-3 ">
                      <p class="tabel-header__title">Available Limit</p>
                      </th>
                      <th class="tabel-header col col-sm-3 ">
                    <p class="tabel-header__title">Utilized Amount</p>
                      </th>
                      
                    </tr>
                  </thead>
                <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                    <tr   v-for="(item) in displayedPosts" :key="item">
                      <td >
                      <p ><span v-html="item['fundingEntityName']"></span></p>
                      </td>
                      <td >
                        <p > <span v-html="item['programName']"></span></p>
                      </td>
                      <td  >
                        <p> <span v-html="item['availableLimit']"></span></p>
                      </td>
                      <td >
                        <p ><span v-html="item['utilizedAmount']"></span></p>
                      </td>
                    </tr>
                  </tbody>
              </table>
              <table class="table m-4" style="width:100%" v-if="name == 'ApplicationStats'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title"> {{dashboardApplicationStatsDetail.fundingEntityName}}</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.programName}}</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.noAction}}</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                   <p class="tabel-header__title">{{dashboardApplicationStatsDetail.inprogress}}</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.started}}</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">{{dashboardApplicationStatsDetail.funded}}</p>
                    </th>
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['fundingEntityName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td class="text-center" >
                       <p> <span v-html="item['noAction']"></span></p>
                    </td>
                    <td class="text-center">
                       <p ><span v-html="item['inprogress']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['started']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['funded']"></span></p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table m-4" style="width:100%" v-if="name == 'AffiliatewiseFundUtilized'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title">Affiliate Name</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">Program Name</p>
                    </th>
                    <th class="tabel-header col col-sm-3 ">
                    <p class="tabel-header__title">Available Limit</p>
                    </th>
                    <th class="tabel-header col col-sm-3 ">
                   <p class="tabel-header__title">Utilized Amount</p>
                    </th>
                     
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['affiliateName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td  >
                       <p> <span v-html="item['availableLimit']"></span></p>
                    </td>
                    <td >
                       <p ><span v-html="item['utilizedAmount']"></span></p>
                    </td>
                  </tr>
                </tbody>
            </table>
            <table class="table m-4" style="width:100%" v-if="name == 'AffiliateWiseApplicationStats'">
                <thead style="font-size:1.4rem;background: #fff;position: sticky;top: 0;z-index: 1;">
                  <tr>
                    <th class="tabel-header  col col-sm-3 ">
                     <p class="tabel-header__title"> Affiliate Name</p>
                    </th>
                    <th class="tabel-header  col col-sm-3 ">
                    <p class="tabel-header__title">Program Name</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title"> NoAction</p>
                    </th>
                    <th class="tabel-header col col-sm-3 text-center">
                   <p class="tabel-header__title">Inprogress</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">Started</p>
                    </th>
                     <th class="tabel-header col col-sm-3 text-center">
                    <p class="tabel-header__title">Funded</p>
                    </th>
                  </tr>
                </thead>
               <tbody class="" style="font-size:1.4rem;overflow-y: scroll; height:300px ">
                  <tr   v-for="(item) in displayedPosts" :key="item">
                    <td >
                     <p ><span v-html="item['affiliateName']"></span></p>
                    </td>
                     <td >
                       <p > <span v-html="item['programName']"></span></p>
                    </td>
                    <td class="text-center" >
                       <p> <span v-html="item['noAction']"></span></p>
                    </td>
                    <td class="text-center">
                       <p ><span v-html="item['inprogress']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['started']"></span></p>
                    </td>
                     <td class="text-center">
                       <p ><span v-html="item['funded']"></span></p>
                    </td>
                  </tr>
                </tbody>
            </table>
              <div class ="table-view__footer" >
              <div class="pagination">
                <div class="pagination__container">
                  <div class="pagination__container__total-pages">
                    <p>
                    {{ filteredRows.length }}
                      {{ filteredRows.length > 1 ? chartModal.labels.Results : chartModal.labels.Result }}
                    </p>
                  </div>
                  <div class="pagination__container__row-per-page">
                    <span>
                      <label>
                      {{ chartModal.labels.Rowsperpage }}
                      </label>
                      <input
                        type="chartModal.Number"
                        :max=chartModal.perPage
                        min="1"
                        readonly
                        :value=chartModal.perPage
                      />
                    </span>
                  </div>
                  <div class="pagination__container__brief">
                    <span>
                      <p>{{ chartModal.values.currentPage }}</p>
                      <p>{{ chartModal.labels.of }}</p>
                      <p>{{ chartModal.values.totalPages }}</p>
                      <p>{{ chartModal.labels.pages }}</p>
                    </span>
                  </div>
                  <div class="pagination__container__inc-dec">
                    <button @click="backPage()">
                      &#60;
                    </button>
                    <p>{{ chartModal.values.currentPage }}</p>
                    <button @click="nextPage()">
                      &#62;
                    </button>
                  </div>
                </div>
              </div>
              </div>
            </slot>
           
          </div>
  </template>
  <script>
 //import BtnPrimary from "@/components/BtnPrimary.vue";
export default {
    props: {
    items: {
      required: true,
      type: Array,
    },
    title:{
        required : true,
        type:String
    },
      name:{
        required : true,
        type:String
    }
  },
  components: {
   // BtnPrimary
  },
data()
{
    return{
        list: this.items,
         dashboardFundAllocationColumn:
        {
            fundingEntityName: "Funding Entity",
            programName:"Program Name",
            availableLimit: "Available Limit",
            utilizedAmount:"Utilized Amount"
        },
        dashboardApplicationStatsDetail:
        {
            title:"",
            rslt:null,
            fundingEntityName:"Funding Entity",
            programName:"Program Name",
            noAction:"No Action",
            inprogress:"Inprogress",
            started:"Started",
          funded:"Funded"
        },
           
   chartModal:
        {
                
                perPage : 10,
                filter: "",
                
                unsort:false,
                selectOptionToFilter: "all",
                isClearSort: false,
                labels: {
                  ShowingResults: "Showing Results",
                  ShowFilters: "Show Filters",
                  Results: "Results",
                  Result: "Result",
                  Rowsperpage: "Rows per page:",
                  pages: "Pages",
                  of: "of",
                  },
                  values: {
                    pages: [],
                    totalPages: 1,
                    currentPage: 1,
                    currentSort: "",
                    currentSortDir: "asc",
                    isSearchByAll: true,
                  },
              }
    }

},
beforeUpdate() {
    this.list = this.items;
  },
    methods:{
    closePopUp()
    {
      this.showCentralPopUp = false;
    } ,
        //ChartModel Paginations
    clearFilter(){
      this.chartModal.filter='';
    },
    ResetSort() {
      this.chartModal.filter='';
      this.chartModal.values.currentSort=''
      this.selectOptionToFilter='all';
      this.chartModal.unsort=true;
    },
    sortColumn(s) {
      this.chartModal.unsort = false;
      if (s === this.chartModal.values.currentSort) {
        this.chartModal.values.currentSortDir =
          this.chartModal.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.chartModal.values.currentSort = s;
    },
  setPages () {
			let numberOfPages = Math.ceil(this.filteredRows.length / this.chartModal.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.chartModal.values.pages.push(index);
			}
      
		},
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.chartModal.values.currentPage;
			let perPage = this.chartModal.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.chartModal.values.totalPages =Math.ceil(this.filteredRows.length / this.chartModal.perPage);
      if(this.chartModal.values.totalPages===0){
        this.chartModal.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.chartModal.items.data)
        return "";
      return this.chartModal.items.data.length;
    },
    backPage() {
      if (this.chartModal.values.currentPage > 1) {
        this.chartModal.values.currentPage--;
      }
    },
    nextPage() {
      if (this.chartModal.values.currentPage < this.chartModal.values.totalPages) {
        this.chartModal.values.currentPage++;
      }
    }
    },
    computed: {
        displayedPosts() {
			return this.paginate(this.filteredRows);
	},
    sortTable() {
      let lists = this.list;
      let data;
      if(this.chartModal.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.chartModal.values.currentSortDir === "desc") modifier = -1;
        if (a[this.chartModal.values.currentSort] < b[this.chartModal.values.currentSort])
          return -1 * modifier;
        if (a[this.chartModal.values.currentSort] > b[this.chartModal.values.currentSort])
          return 1 * modifier;
        return 0;
      });

      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        /*const loanNumber = row.loanNumber.toString().toLowerCase();
        const dateApplied = row.dateApplied.toLowerCase();
        const businessName = row.businessName.toLowerCase();
         const affiliateName = row.affiliateName.toString().toLowerCase();
          // const loanProgramName = row.loanProgramName.toLowerCase();
        const loanAmount = row.loanAmount.toString().toLowerCase();//.toLowerCase();
        const applicationStatus = row.applicationStatus.toLowerCase();
        const searchTerm = this.chartModal.filter.toLowerCase();
        let filtedItems;
        if (this.chartModal.selectOptionToFilter == "all") {
          filtedItems =
            businessName.includes(searchTerm) ||
           loanNumber.includes(searchTerm) ||
           dateApplied.includes(searchTerm)||
            affiliateName.includes(searchTerm)||
                        // loanProgramName.includes(searchTerm) ||
            loanAmount.includes(searchTerm) ||
            applicationStatus.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "businessName") {
          filtedItems = businessName.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        if (this.selectOptionToFilter == "loanNumber") {
          filtedItems = loanNumber.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "affiliateName") {
          filtedItems = affiliateName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "dateApplied") {
          filtedItems = dateApplied.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        //     if (this.selectOptionToFilter == "loanProgramName") {
        //   filtedItems = loanProgramName.includes(searchTerm);
        // }
        if (this.selectOptionToFilter == "loanAmount") {
          filtedItems = loanAmount.includes(searchTerm);
        }
        if (this.chartModal.selectOptionToFilter == "applicationStatus") {
          if(searchTerm==''){
            filtedItems=true
          }
          else{
          filtedItems = searchTerm === "show all" ? true: applicationStatus===searchTerm  ;
          }
        }
        // this.items=filtedItems*/
        return row;
      });
    }
    },
     watch: {
		posts () {
			this.setPages();
		}
	},     
};
  </script>

  <style lang="scss" scoped>
.dashboard {
  &--section {
    .dashboard--header {
      padding: 0rem 6%;
      &__heading-fillter {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .fillter {
          display: flex;
          label,
          input,
          select,
          option {
            font-size: 1.6rem;
            color: $primary-color-black;
            margin: 0;
            outline: none;
          }
          p {
            margin: 0;
            padding: 0;
          }
          select,
          input {
            border: none;
            outline: none;
            border-bottom: 0.2rem solid $tertiary-color-white;
            height: 3rem;
            width: 100%;
          }
        }
      }
    }
    .dashboard--main {
      height: calc(100vh - 20rem);
      padding: 0rem 6%;
      padding-bottom: 5rem;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        background: $tertiary-color-white;
      }

      &::-webkit-scrollbar-thumb {
        background: #bdbdbd;
        height: 0.1rem;
        -webkit-border-radius: 2rem;
      }

      &::-webkit-scrollbar-corner {
        background: #000;
      }
      .charts {
        .chart {
          padding: 1rem 0rem;
        }
      }
      .quick-links {
        padding: 2rem 0rem;
        &--main {
          margin-top: 1rem;
          .links {
            cursor: pointer;
            font-size: 1.6rem;
            // text-decoration: underline;
          }
        }
      }
    }
    .heading {
      font-size: 2.6rem;
      font-weight: bold;
      text-transform: capitalize;
      color: $primary-color-red;
      margin: 0;
      @media screen and (max-width: 1600px) {
        font-size: 2.4rem;
      }
    }
  }
}

.central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 70%;
    min-width: 70%;
    width: auto;
    padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px) {
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      p {
        font-size: 1.6rem;
        text-align: center;
        color: $primary-color-black;
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }
}

.table-heading {
  h3 {
    font-size: 2.6rem;
    font-weight: bold;
    text-transform: capitalize;
  }
}
.reset-button {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  height: 3.5rem;
  width: 3.5rem;
  border: 1px solid transparent;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  img{
    height: 100%;
  }
}
.search {
  display: flex;
  align-items: flex-end;
  label,
  input,
  select,
  option {
    outline: none;
    font-size: 1.6rem;
    color: $primary-color-black;
    margin: 0;
  }
  select,
  input {
    min-height: 3.8rem;
    width: auto;
  }
  input {
    border: none;
    outline: none;
    border-bottom: 1px solid $primary-color-black;
    border-radius: 0rem;
  }
}
.sort {
  display: flex;
  align-items: center;
  p,
  input {
    margin-right: 1rem;
  }
  input {
    border: 0rem;
    outline: none;
    border-bottom: 1px solid $secondary-color-white;
    font: normal normal medium;
  }
}
.filter {
  width: 14.6rem;
  height: 3.6rem;
  background: $tertiary-color-white 0% 0% no-repeat padding-box;
  border-radius: 0.3rem;
  button {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    outline: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.tabel-header {
  &__data {
    display: flex;
    align-items: center;
    // &__title {
    // }
    .functional-icons {
      opacity: 0;
    }
  }
  .show-icon,
  &:hover,
  &:active {
    .functional-icons {
      opacity: 1;
    }
  }
}
.table-view {
  p {
    padding: 0;
    margin: 0;
    font: normal normal 300 1.4rem;
    color: $primary-color-black;
    text-transform: capitalize;
  }
  &__header {
    min-height: 6rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .sort {
      display: flex;
      align-items: center;
      p,
      input {
        margin-right: 1rem;
      }
      input {
        border: 0rem;
        outline: none;
        border-bottom: 1px solid $secondary-color-white;
        font: normal normal medium;
      }
    }
    .filter {
      width: 14.6rem;
      height: 3.6rem;
      background: $tertiary-color-white 0% 0% no-repeat padding-box;
      border-radius: 0.3rem;
      button {
        height: 100%;
        width: 100%;
        border: 1px solid transparent;
        outline: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    &--right{
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      @media screen and(max-width:580px) {
        
      }
      
      .search{
        display: flex;
            align-items: center;
        justify-content: end;
      flex-wrap: wrap;
      }
    }
    @media screen and (max-width:580px) {
      justify-content: start;
      display: flex;
      flex-direction: column;
      .search-by-program,
      .search-by-filter,
      .search-by-input{
        width: 95vw;
      }
      .search-by-program__filter,
      .search-by-filter,
      .search-by-input{
        &__select
        ,&__input{
          width: 100%;

        }
      }
      

    }
  }
  &__table {
     overflow-x: hidden;
    overflow-y: auto;
    border-top: 2px solid $secondary-color-white;
    border-bottom: 2px solid $secondary-color-white;
    padding: 0.5rem 0rem 0.5rem 0rem;//2rem 0rem;
    overflow-x: auto;
    .table {
      width: 100%;
      min-width: 65rem;
      thead {
        border-bottom: 2px solid $secondary-color-white;
        tr {
          height: 3.5rem;
          th {
            font-size: 1.4rem;
            vertical-align: top;
            border: none;
          }
        }
      }
      .tbody {
        padding:.5rem;
        height: 45vh;
        display: block;
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
                width: .3rem;
                background: $tertiary-color-white;
            }

            &::-webkit-scrollbar-thumb {
                background:#bdbdbd;
                height: .1rem;
                -webkit-border-radius: 2rem;
            }

            &::-webkit-scrollbar-corner {
                background: #000;
            }
        tr {
          border: 1px solid transparent;
          height: 4.5rem;
          cursor: pointer;
          td {
            font-size: 1.4rem;
            color: #4d4d4f;
            padding-top: 1.5rem;
            line-height: 2rem;
            a {
              color: $color-blue;
            }
            p{
              overflow-wrap: break-word;
            }
            
          }
          .status {
            display: inline-block;
            padding: 0.5rem 1rem;
            background: #eeeeee 0% 0% no-repeat padding-box;
            border-radius: 3rem;
            &::before {
              display: inline-block;
              content: "";
              border-radius: 50% 50%;
              height: 0.5rem;
              width: 0.5rem;
              // font-size: 1.4rem;
              border: 1px solid transparent;
              background-color: $color-green;
              padding: 0.1rem;
              margin-right: 0.5rem;
            }
          }
          &:hover {
            box-shadow: 0.1rem 0.1rem 0.5rem $primary-color-grey;
            border-radius: 0.3rem 0.3rem 0rem 0rem;
          }
        }
      }
    }
  }
  td {
    a{
    text-decoration: none;
  }
    text-align: left;
    font: normal normal normal 1.4rem/2rem;
    color: $tertiary-color-grey;
    border-top: 0rem;
  }
  thead, tbody tr {
    border: none;
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  &__footer{
    .pagination{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &__container,span{
        display: flex;
        align-items: center;
        div{
            padding:.5rem 1rem;
        }
        p,label,input{
            font: normal normal normal 1.2rem/2rem;
            color: $tertiary-color-grey;
            padding: .2rem;
            margin: 0;
        }
        &__row-per-page{
            span{
                display: flex;
                
                input{
                    width: 4rem;
                    border: none;
                    outline: none;
                   
                }
            }
        }
        &__inc-dec{
            display: flex;
            align-items: center;
            button,p{
                height: 3rem;
                width: 3rem;
                font-weight: bold;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.5rem;
            }
            p{
                border: 1px solid $primary-color-red;
                border-radius: .5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color-red;
                cursor: pointer;
            }
        }
        
    }
}
  }
  
}
      
  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 70%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
      justify-content: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
  
     table, tr td {
   
}
tbody {
    display: block;
    height: 50px;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
thead {
    width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
}
table {
    width: 400px;
}  
</style>
