<template>
  <div class="">
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      vh: window.innerHeight * 0.01,
    };
  },
  setup() {},
  created: function () {    
    const vm = this;
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {
       
        console.log("Error 401 -- >", err.response);
        if (err.response.status === 401 && err.response.data.message === "Token Expired") {
           vm.$store.dispatch("UpdateLogout");          
          console.log(vm.$route.href);

          if(vm.$route.href.includes('redirect')){
            vm.$router.push({ name: 'login', query: { redirect: vm.$route.query.redirect } });             
          }
          else
          {
           // vm.$router.push("/login"); 
           vm.$router.push({ name: 'login', query: { redirect: vm.$route.href } });
          }

             

           

        }
        else if (err.response.status === 401 && err.response.data.message === "Unauthorized") {
           vm.$router.push("/errorpage");        
        }
        throw err;
      });
    });
  },
  mounted() {
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  beforeUpdate()
  {
    if(this.$route.href=="/errorpage" || this.$route.href.startsWith("/login")  || this.$route.href=="/ale"  || this.$route.href=="/success"  || this.$route.href.startsWith("/ActivateAccount") || this.$route.href.startsWith("/summarypage") || this.$route.href.startsWith("/forgotpassword") || this.$route.href.startsWith("/ForgotPasswordActivation")||this.$route.href.startsWith("/ResetContactPasswordActivation") )
    {
      return;
    }
    else{
      let token = localStorage.getItem("token");
      let config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.get("Account/CheckAuthentication", config)
      .then(() =>{
        Promise.resolve(true);
      })
      .catch((err)=>{
        Promise.reject(err)
      })
    }
  }
};
</script>

<style lang="scss">
html {
  font-size: 16px;
  box-sizing: border-box;
  font-size: 62.5%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-spacing: 1px;
  letter-spacing: 0rem;
}

body {
  @font-face {
    font-family: Motiva-Sans;
    src: url(./assets/font-family/MotivaSansRegular/font.woff);
    src: url(./assets/font-family/MotivaSansRegular/font.woff2);
  }

  font-family: Motiva-Sans, sans-serif !important;
  letter-spacing: 0rem;
  width: 100vw;
  height: auto;
}
*,
// p,span,h1,h2,h3,h4,h5,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0rem;
  padding: 0rem;
}

   input[type='file']::-webkit-file-upload-button {
  visibility: hidden;
}
    input[type='file']::before,
    input[type=file]::file-selector-button,
    x::-webkit-file-upload-button {
       content: 'Choose File';
       @media screen and(max-width:580px) {
          content: url("./assets/imgs/upload.svg");
        }
       
  display: inline-block;
  // background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: .5rem 1rem;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  text-align: center;
      max-width: 100%;
      min-width: 90%;
      width: 100%;
    }
    @media screen and (max-width: 570px){
  .mosha__toast,
  .danger{
    top: 10% !important;
  } 
}


</style>
