<template>
  <div class="table-view" >
    <div class="table-view__header d-flex justify-content-between  align-items-center m-0 p-0">
      <div class="table-view__header--left">
        <div class="table-heading">
          <h3>Grant Applications</h3>
        </div>
      </div>
      <div class="table-view__header--right p-0 col d-flex " >
        <div class="search" v-on:mouseleave="mouseleave">
          <div  class=" search-by-program my-1">
             <div class="table-view__header--right p-0 col d-flex justify-content-end">
            <div class="  " style="width:500px">
              <div class="multiselect frontlayer p-4" data-bs-toggle="dropdown"  >
                <div class="multiselect__selectBox " @click="showCheckboxes()">
                  <label class="multiselect__selectBox__label" for="">{{selectedPrograms}}</label>
                  <span class="multiselect__selectBox__show-selectedBox "></span>
                  <button class="arrow-buttons">
                    <img class="arrow-buttons__img" src="@/assets/imgs/arrow-up.png" alt="up arrow" />
                    <img class="arrow-buttons__img" src="@/assets/imgs/arrow-down.png" alt="down arrow" />
                  </button>
                </div>
                <!-- {{testData}} -->
                <div class="checkboxes border border-dark" v-if="isShowCheckboxesOption" >
                  <div v-for="program in programList" :key="program.programId" class="checkbox-div ml-2" style="height: 2rem;" :class="{
                                    'checkbox-checked': showCheckedData.includes(
                                    program
                                    ),
                                    }">
                    <input class="checkboxes__input p-0 m-0" type="checkbox" :id="program.programId" :value="program" @change="onChanceOfProgramData($event, program)" v-model="showCheckedData" />
                    <label class="checkboxes__label p-0 m-0 pl-2" :for="program.programId" :title="program.programName" v-if="program.programName.length > 55">
                      {{ program.programName
                        .substring(0, 55)
                        .trim() + '...'  }}
                    </label>
                      <label class="checkboxes__label p-0 m-0 pl-2" :for="program.programId" :title="program.programName" v-else>
                      {{ program.programName }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
           
          </div>
          </div>
          <div class="d-flex  flex-wrap ">
            <label for="search" class="ml-1 d-flex align-items-center search-by-filter">Search&nbsp;By:
          <span class=" search-by-filter__select">
            <select
            class="search-group search-by-filter__select mx-2 px-1 my-1"
            placeholder=" "
            name="search-group"
            id="search-group"
            @change="clearFilter()"
            v-model="selectOptionToFilter"
          >
            <option selected value="all">
              <p>All</p>
            </option>
            <option value="loanNumber">
              <p>Grant Number</p>
            </option>
            <option value="dateApplied">
              <p>Date Applied</p>
            </option>
            <option value="businessName">
              <p>Business Name</p>
            </option>
            <option selected value="affiliateName">
              <p>Affiliate Name</p>
            </option>
            <option value="loanAmount">
              <p>Disbursed Amount</p>
            </option>
            <option value="applicationStatus">
              <p>Application Status</p>
            </option>
          </select>
          </span>
          </label>
          <span class="mx-1 d-flex search-by-input ">
            <select
            v-if="selectOptionToFilter==='applicationStatus'"
            class="search-group search-by-input__select my-1"
            placeholder=" "
            name="search-group-filter"
            id="search-group-filter"
            v-model="filter"
          >
          <option  selected >Show All</option>
            <option v-for="status in applicationStatusList()" :key='status' :value="status">
              <p>{{status}}</p>
            </option>
          </select>
          <input
           v-else
           class="search-by-input__input"
            type="text"
            name="searchByKey"
            id="searchByKey"
            v-model="filter"
            placeholder="Search here"
          />
          <button class="reset-button" @click="ResetSort">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
          </span>
          </div>
          
          
          <div>
            
          </div>
        </div>
        
        
      </div>
      <div>
            <span class="btn--primary" >
        <BtnSecondary
        v-if="userRoleID == 1 || userRoleID == 3 || userRoleID === 5 || userRoleID == 7"
          :buttonName="`Export to Excel`"
          @click="exportLoanApplications"
        />
      </span>
        </div>
    </div>
    <div class="table-view__table" >
      
      <table class="table" >
        <thead>
          <tr>
            
          <th @click="sortColumn('loanNumber')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='loanNumber'?'show-icon':''">
                <p class="tabel-header__title">Grant Number</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          <th @click="sortColumn('dateApplied')" class="tabel-header " style="width: 120px">
              <span class="tabel-header__data" :class="values.currentSort=='dateApplied'?'show-icon':''">
                <p class="tabel-header__title">Date Applied</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          <th @click="sortColumn('businessName')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='businessName'?'show-icon':''">
                <p class="tabel-header__title">Business Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          <th @click="sortColumn('affiliateName')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='affiliateName'?'show-icon':''">
                <p class="tabel-header__title">Affiliate Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
           <th @click="sortColumn('loanProgramName')" class="tabel-header " style="width: 200px">
              <span class="tabel-header__data" :class="values.currentSort=='loanProgramName'?'show-icon':''">
                <p class="tabel-header__title">Grant Program Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          <th @click="sortColumn('fundAllocatedAmount')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='fundAllocatedAmount'?'show-icon':''">
                <p class="tabel-header__title">Funds Allocated</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          <th @click="sortColumn('loanAmount')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='loanAmount'?'show-icon':''">
                <p class="tabel-header__title">Disbursed Amount</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          
          <th @click="sortColumn('applicationStatus')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='applicationStatus'?'show-icon':''">
                <p class="tabel-header__title">Application Status</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          <th @click="sortColumn('contactInfo')" class="tabel-header" v-if="userRoleID != 2">
              <span class="tabel-header__data" :class="values.currentSort=='contactInfo'?'show-icon':''">
                <p class="tabel-header__title">Contact</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-icon__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
          </th>
          </tr>
        </thead>
        <tbody class="tbody">
          <tr v-for="item in displayedPosts" :key="item" >
            <td @click="selectId(item.loanApplicationID)" >
              <p v-html="highlightMatches(item['loanNumber'])"></p> 
            </td>
            <td @click="selectId(item.loanApplicationID)" style="width: 120px">
              <p v-html="highlightMatches(item['dateApplied'])"></p> 
            </td>
            <td @click="selectId(item.loanApplicationID)">
              <p v-html="highlightMatches(item['businessName'])"></p> 
            </td>
            <td @click="selectId(item.loanApplicationID)">
              <p v-html="highlightMatches(item['affiliateName'])"></p> 
            </td>
            <td @click="selectId(item.loanApplicationID)" style="width: 200px">
              <p v-html="highlightMatches(item['loanProgramName'])"></p> 
            </td>
            <td @click="selectId(item.loanApplicationID)">
             <p ><span v-show="item['fundAllocatedAmount']">$</span> <span v-html="highlightMatches(item['fundAllocatedAmount'])"></span></p>  
            </td>
            <td @click="selectId(item.loanApplicationID)"> 
             <p ><span v-show="item['loanAmount']">$</span> <span v-html="highlightMatches(item['loanAmount'])"></span></p>  
            </td>
            <td @click="selectId(item.loanApplicationID)">
              <p v-html="highlightMatches(item['applicationStatus'])"></p> 
            </td>
            <td v-if="userRoleID != 2">
            <p v-html="highlightMatches(item['contactInfo'])" @click="routeToBusiness(item['businessId'],item.loanApplicationID)" class="hover"></p> 
              <!--<a href="mailto:'item['contactInfo']'" class="tooltiptext" v-html="item['contactInfo'].slice(0, 10)
                        .substring(0, 20)
                        .trim() + '...'">
                      
                        </a> -->
                        
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class ="table-view__footer" >
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max=perPage
                min="1"
                readonly
                :value=perPage
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  components: {
    BtnSecondary
  },
  props: {
    userRoleID:{
      required: true,
    },
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type:Number,
      default:10
      },
      TableHeight:{
        type:Number,
      },
    columns: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      allProgramIdForFilleter:[],
      programid: 0,
      fundingEntityId:0,
      filter: "",
      list: this.items,
      unsort:false,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
         pages: [],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
      programList: [],
        inputs: {
          businessId: 0,
          programId: 0
        },
      isShowCheckboxesOption: false,
      isHideCheckboxesOption:false,
      showCheckedData: [],
      selectedPrograms:"Program Name",
    };
  },
   beforeUpdate() {
    this.list = this.items;
  },
    mounted() {
    this.$store.dispatch("updateLoaderFlag", true);
    this.getPrograms()
    .then(()=>{
      this.getProgramId(this.programid)
      if(this.isAllSelectForProgramInvitation==true){
        this.allProgramIdForFilleter=0
      }
    // this.programid=0;
    })
    //this.getPrograms()
    
     //let programid = 0;
    //this.GetConsolidatedReportData(this.inputs.fromDate,this.inputs.todate,this.inputs.programid);
    //    this.exportConsolidatedReport(this.inputs.fromDate,this.inputs.todate,this.inputs.programid);
 
  },
  methods: {
    mouseleave()
    {
this.isShowCheckboxesOption = false;
    },
    clearFilter(){
      this.filter='';
    },


    routeToBusiness(businessId,loanApplicationID){
      this.$store.state.business.programInvitationActiveTab="businessContact";
    this.$router.push('/programinvitation/form/'+businessId+'/'+ loanApplicationID)
    
    },
getProgramId(programid) { 
  this.programid = programid;
      this.$emit('getProgramId',programid)
    },
    getProgramName() {
      if (this.programid !== "0") {
        return this.programs.find((x) => x.programId == this.programid)
          .programName;
      } else {
        return "";
      }
    },
    // to get the program names
      async getPrograms() {
        await this.$store.dispatch("createProgramInvitation").then((res) => {
          //const data = this.programs;
          this.programList = this.programs;
          this.programList.unshift({
            programId: 0,
            programName: "All"
          });
          // this.inputs.programid=this.programs[0].programId*/
          Promise.resolve(res);
        }).catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
        });
      },
      showCheckboxes() {
        this.isShowCheckboxesOption = !this.isShowCheckboxesOption;
      },
      onChanceOfProgramData(e, program) {
        //  if    current checkbox is all remove other from showCheckedData
        if (program.programId == 0 && e.target.checked == true) {
          this.showCheckedData = [{
            programId: 0,
            programName: "All"
          }];
        }
        //else
        // check isthere programId === 0 , then remove it .
        else {
          let AllValueindex = this.showCheckedData.findIndex(
            (x) => x.programId == "0");
          if (AllValueindex >= 0) {
            this.showCheckedData.splice(AllValueindex, 1);
          }
        }
        if(this.showCheckedData.length > 0)
        {
            this.selectedPrograms = this.showCheckedData.length > 1 ? 'Multiple Programs Selected' : this.showCheckedData[0].programName ;
            let programIds = [];
            for(let i in this.showCheckedData)
            {
                programIds.push(this.showCheckedData[i].programId)
            }
            this.$emit('getProgramId',programIds)
        }
        else
        {
          this.selectedPrograms = 'Program Name';
          this.$emit('getProgramId',0)
        }
      },

    applicationStatusList(){
        let applicationStatus =[...new Set(this.list.map(item => item.applicationStatus))]
        return applicationStatus.sort()
      },
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;
    },
    exportLoanApplications(){
      this.$store.dispatch("updateLoaderFlag", true);
      let programIds = []
      for(let i in this.showCheckedData)
      {
          programIds.push(this.showCheckedData[i].programId)
      }
      let filterByPrograms=[];
      let filterParameterArray = [];
      if (programIds.length > 0) {
        
        for (let index = 0; index < programIds.length; index++) {
          let value = {
            key: "ProgramID",
            value: programIds[index].toString(),
          }
          filterParameterArray.push(value);
        }
        filterByPrograms=filterParameterArray
      }
      else{
        filterByPrograms = [
        {
          key: "ProgramID",
          value: "0",
        },
      ];
      }
      let filterParameters = {
        filterParameters : filterByPrograms,
        searchBy : this.selectOptionToFilter,
        searchValue:this.filter === 'Show All' ? '' : this.filter
      }
      this.$store
        .dispatch("exportLoanApplications",filterParameters, { root: true })
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
        });
      this.$store.state.borrower.ownersData = [{}];
      this.$router.push("/tracks");
     // this.$emit('exportLoanApplications')
    },
    highlightMatches(text) {
      const textStr = text.toString();
      const matchExists = textStr.toLowerCase().includes(this.filter);
      if (!matchExists) return textStr;

      const re = new RegExp(this.filter, "ig");
      return textStr.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    selectId(loanApplicationID){
      this.$emit('selectRow', loanApplicationID)
    },
    sortColumn(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
  setPages () {
			let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.values.pages.push(index);
			}
      
		},
    paginate (posts) {
      if(!posts)
        return [];
			let page = this.values.currentPage;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.values.totalPages =Math.ceil(this.filteredRows.length / this.perPage);
      if(this.values.totalPages===0){
        this.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    totalRecords(){
      if(!this.items.data)
        return "";
      return this.items.data.length;
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
    isAllSelectForProgramInvitation:{
      get(){
        return this.$store.state.business.temporary.isAllSelectForProgramInvitation
      }
    },
  programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      },
    },
    setTableHeight(){
      return 'height: ' + this.TableHeight +'vh';    
    },
    
    displayedPosts() {
			return this.paginate(this.filteredRows);
		},
    sortTable() {
      let lists = this.list;
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });

      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        const loanNumber = row.loanNumber.toString().toLowerCase();
        const dateApplied = row.dateApplied.toLowerCase();
        const businessName = row.businessName.toLowerCase();
         const affiliateName = row.affiliateName.toString().toLowerCase();
          // const loanProgramName = row.loanProgramName.toLowerCase();
        const loanAmount = row.loanAmount.toString().toLowerCase();//.toLowerCase();
        const applicationStatus = row.applicationStatus.toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            businessName.includes(searchTerm) ||
           loanNumber.includes(searchTerm) ||
           dateApplied.includes(searchTerm)||
            affiliateName.includes(searchTerm)||
                        // loanProgramName.includes(searchTerm) ||
            loanAmount.includes(searchTerm) ||
            applicationStatus.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "businessName") {
          filtedItems = businessName.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        if (this.selectOptionToFilter == "loanNumber") {
          filtedItems = loanNumber.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "affiliateName") {
          filtedItems = affiliateName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "dateApplied") {
          filtedItems = dateApplied.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        //     if (this.selectOptionToFilter == "loanProgramName") {
        //   filtedItems = loanProgramName.includes(searchTerm);
        // }
        if (this.selectOptionToFilter == "loanAmount") {
          filtedItems = loanAmount.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "applicationStatus") {
          if(searchTerm==''){
            filtedItems=true
          }
          else{
          filtedItems = searchTerm === "show all" ? true: applicationStatus===searchTerm  ;
          }
        }
        // this.items=filtedItems
        return filtedItems;
      });
    },
  },
  watch: {
		posts () {
			this.setPages();
		}
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.table-heading {
  h3 {
    font-size: 2.6rem;
    font-weight: bold;
    text-transform: capitalize;
    color: $primary-color-red;
  }
}
.reset-button {
  background: #f7f7f7 0% 0% no-repeat padding-box;
  height: 3.5rem;
  width: 3.5rem;
  border: 1px solid transparent;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  img{
    height: 100%;
  }
}
.search {
  display: flex;
  align-items: flex-end;
  label,
  input,
  select,
  option {
    outline: none;
    font-size: 1.6rem;
    color: $primary-color-black;
    margin: 0;
  }
  select,
  input {
    min-height: 3.8rem;
    width: auto;
  }
  input {
    border: none;
    outline: none;
    border-bottom: 1px solid $primary-color-black;
    border-radius: 0rem;
  }
}
.sort {
  display: flex;
  align-items: center;
  p,
  input {
    margin-right: 1rem;
  }
  input {
    border: 0rem;
    outline: none;
    border-bottom: 1px solid $secondary-color-white;
    font: normal normal medium;
  }
}
.filter {
  width: 14.6rem;
  height: 3.6rem;
  background: $tertiary-color-white 0% 0% no-repeat padding-box;
  border-radius: 0.3rem;
  button {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
    outline: none;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.tabel-header {
  &__data {
    display: flex;
    align-items: center;
    // &__title {
    // }
    .functional-icons {
      opacity: 0;
    }
  }
  .show-icon,
  &:hover,
  &:active {
    .functional-icons {
      opacity: 1;
    }
  }
}
.table-view {
  p {
    padding: 0;
    margin: 0;
    font: normal normal 300 1.4rem;
    color: $primary-color-black;
    text-transform: capitalize;
  }
  &__header {
    min-height: 6rem; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .sort {
      display: flex;
      align-items: center;
      p,
      input {
        margin-right: 1rem;
      }
      input {
        border: 0rem;
        outline: none;
        border-bottom: 1px solid $secondary-color-white;
        font: normal normal medium;
      }
    }
    .filter {
      width: 14.6rem;
      height: 3.6rem;
      background: $tertiary-color-white 0% 0% no-repeat padding-box;
      border-radius: 0.3rem;
      button {
        height: 100%;
        width: 100%;
        border: 1px solid transparent;
        outline: none;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    }
    &--right{
      display: flex;
      flex-wrap: wrap;
      justify-content: end;
      @media screen and(max-width:580px) {
        
      }
      
      .search{
        display: flex;
            align-items: center;
        justify-content: end;
      flex-wrap: wrap;
      }
    }
    @media screen and (max-width:580px) {
      justify-content: start;
      display: flex;
      flex-direction: column;
      .search-by-program,
      .search-by-filter,
      .search-by-input{
        width: 95vw;
      }
      .search-by-program__filter,
      .search-by-filter,
      .search-by-input{
        &__select
        ,&__input{
          width: 100%;

        }
      }
      

    }
  }
  &__table {
    // overflow-x: hidden;
    // overflow-y: auto;
    border-top: 2px solid $secondary-color-white;
    border-bottom: 2px solid $secondary-color-white;
    padding: 0.5rem 0rem 0.5rem 0rem;//2rem 0rem;
    overflow-x: auto;
    .table {
      width: 100%;
      min-width: 65rem;
      thead {
        border-bottom: 2px solid $secondary-color-white;
        tr {
          height: 3.5rem;
          th {
            font-size: 1.4rem;
            vertical-align: top;
            border: none;
          }
        }
      }
      .tbody {
        padding:.5rem;
        height: 45vh;
        display: block;
        width: 100%;
        overflow: auto;
        &::-webkit-scrollbar {
                width: .3rem;
                background: $tertiary-color-white;
            }

            &::-webkit-scrollbar-thumb {
                background:#bdbdbd;
                height: .1rem;
                -webkit-border-radius: 2rem;
            }

            &::-webkit-scrollbar-corner {
                background: #000;
            }
        tr {
          border: 1px solid transparent;
          height: 4.5rem;
          cursor: pointer;
          td {
            font-size: 1.4rem;
            color: #4d4d4f;
            padding-top: 1.5rem;
            line-height: 2rem;
            a {
              color: $color-blue;
            }
            p{
              overflow-wrap: break-word;
            }
            
          }
          .status {
            display: inline-block;
            padding: 0.5rem 1rem;
            background: #eeeeee 0% 0% no-repeat padding-box;
            border-radius: 3rem;
            &::before {
              display: inline-block;
              content: "";
              border-radius: 50% 50%;
              height: 0.5rem;
              width: 0.5rem;
              // font-size: 1.4rem;
              border: 1px solid transparent;
              background-color: $color-green;
              padding: 0.1rem;
              margin-right: 0.5rem;
            }
          }
          &:hover {
            box-shadow: 0.1rem 0.1rem 0.5rem $primary-color-grey;
            border-radius: 0.3rem 0.3rem 0rem 0rem;
          }
        }
      }
    }
  }
  td {
    a{
    text-decoration: none;
  }
    text-align: left;
    font: normal normal normal 1.4rem/2rem;
    color: $tertiary-color-grey;
    border-top: 0rem;
  }
  thead, tbody tr {
    border: none;
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  .hover:hover {
    color: #C11439;
    text-decoration:underline;
    font-weight:bold;
}
  &__footer{
    .pagination{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    &__container,span{
        display: flex;
        align-items: center;
        div{
            padding:.5rem 1rem;
        }
        p,label,input{
            font: normal normal normal 1.2rem/2rem;
            color: $tertiary-color-grey;
            padding: .2rem;
            margin: 0;
        }
        &__row-per-page{
            span{
                display: flex;
                
                input{
                    width: 4rem;
                    border: none;
                    outline: none;
                   
                }
            }
        }
        &__inc-dec{
            display: flex;
            align-items: center;
            button,p{
                height: 3rem;
                width: 3rem;
                font-weight: bold;
            }
            button{
                background-color: transparent;
                border: none;
                outline: none;
                font-size: 1.5rem;
            }
            p{
                border: 1px solid $primary-color-red;
                border-radius: .5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $primary-color-red;
                cursor: pointer;
            }
        }
        
    }
}
  }
  
}
.multiselect {
    padding-right: 2%;

    &__selectBox {
      border-bottom: 1px solid $primary-color-black;
      position: relative;

      &__label {
        font-size: 1.6rem;

        @media screen and (max-width: 1600px) {
          font-size: 1.4rem;
        }

        display: inline-block;
        margin-bottom: 0.5rem;
      }

      &__show-selectedBox {
        width: 84%;
        display: flex;
        overflow-x: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;

        /* Firefox */
        &__display {
          padding: 0.5rem;
          border: 0.2rem solid $primary-color-red;
          ;
          align-items: center;
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
          border-radius: 1rem;
          // background-color: $secondary-color-pink;
          white-space: nowrap;
          font-size: 1.4rem;
        }
      }

      .arrow-buttons {
        position: absolute;
        right: 1%;
        bottom: 25%;
        height: 2rem;
        display: flex;
        flex-direction: column;
        background: transparent;
        border: none;
        outline: none;

        &__img {
          height: 50%;
        }
      }
    }

    .CheckedData {
      padding: 0.5rem;
    }

    .show-selectedBox {
      display: flex;
      padding: 1rem;
      overflow-x: hidden;

      &__display {
        padding: 0.5rem;
        border: 1px solid;
        align-items: center;
        margin: 1rem;
        border-radius: 1rem;
        background-color: $secondary-color-pink;
        white-space: nowrap;
      }

      .CheckedData {}
    }

    .checkboxes {
      max-height: 15rem;
      overflow-y: auto;
      position: absolute;
      background-color: white;
      width: 90%;
      z-index: 1;

      .checkbox-div {
        display: flex;
        align-items: center;
        margin: 0.2rem 0rem 0.2rem 0rem;
      }

      &__label,
      &__input {
        font-size: 1.6rem;

        @media screen and (max-width: 1600px) {
          font-size: 1.4rem;
        }
      }

      &__label {
        width: auto;
        color: $primary-color-black;
      }

      .checkbox-checked {
        background-color: $tertiary-color-white;
      }
    }
  }

  .selectBox select {
    width: 100%;
  }
</style>
