<template>
  <div class="CiviCRMData">
    <BasicComponent :heading="'CiviCRM Data Export'">
      <template v-slot:breadCrumb>
        <BreadCrumb :crumbs="crumbs" @selected="selected" />
      </template>
      <br />
      <template v-slot:mainSection>
        <div class="CiviCRMData--body" >
          <div class="TrackingList__table-section">
          <CiviCRMDataExportTable
            class="listingTable"
            :items="civiCRMDataList"
            :columns="columns"
            :TableHeight="TableHeight"
            :perPage="itemsPerTable"
            @selectRow="getContactData"
            @Download="Download()"
            @fetchAllCiviCRMDataExportLogs="fetchAllCiviCRMDataExportLogs"
          />
          </div>
       </div>
      </template>
    </BasicComponent>
    <LoadingMask v-if="isLoading === true" />
  </div>
</template>
<script>
import BasicComponent from "@/components/BasicComponent.vue";
import BreadCrumb from "@/components/BreadCrumb";
import CiviCRMDataExportTable from "@/components/CiviCRMDataExportTable";
export default {
  data() {
    return {
      crumbs: ["CiviCRMData"],
      itemsPerTable: 10,
      TableHeight: 52,
      columns: [
        "Exported Date",
        "Exported By",
        "Exported Type",
        "Record Count",
  
      ],
      civiCRMDataList: [],
      selectFileType: 1,
      cumulativeReportData: {},
      inputs: {
        toDate: "",
        fromDate: "",
      },
    };
  },
    created() {
    this.fetchAllCiviCRMDataExportLogs();
 
  },
  components: {
    BreadCrumb,    
    CiviCRMDataExportTable,
    // BtnPrimary,
    BasicComponent,
  },
  beforeMount() {
    // this.$store.dispatch("updateLoaderFlag", true);
  },
  methods: {
    fetchAllCiviCRMDataExportLogs(){
      this.$store.dispatch("updateLoaderFlag",true)
      this.$store
      .dispatch("fetchAllCiviCRMDataExportLogs", { root: true })
      .then((contacts) => {
        if(contacts.data.isSuccess==true){
          if (contacts.data.exportLogs && contacts.data.exportLogs.length > 0) {
          
            this.$store.dispatch("updateLoaderFlag",false)
            this.civiCRMDataList = contacts.data.exportLogs;
          } 
        }    
          else{
            this.civiCRMDataList=[]
            this.$store.dispatch("updateLoaderFlag",false)
          }
      })
      .catch(err =>{
         this.$store.dispatch("updateLoaderFlag",false)
        Promise.reject(err);
      });
    },
    
    
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.CiviCRMData {
  .CiviCRMData--body-section {
    &__cards {
      display: flex;
      flex-wrap: wrap;
      .card {
        &--data--text {
          min-width: 24rem;
          color: $primary-color-grey;
          font-size: 3.2rem;
          opacity: 0.75;
          padding: 0rem 1rem;
          text-align: center;
        }
        .prefix-dollar {
          ::before {
            content: "$";
            color: $primary-color-grey;
            font-size: 3.2rem;
          }
        }
      }
    }
  }
  .CiviCRMData--body {
    padding-top: .1rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    .TrackingList__table-section{
      width: 100%;
      .listingTable{
        padding: 1rem;
      display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: space-between;
      }
    }
  }
  .CiviCRMData--header-section {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    .search {
      display: flex;
      align-items: center;
      label,
      input,
      select,
      option {
        outline: none;
        font-size: 1.6rem;
        color: $primary-color-black;
        margin: 0;
      }
      select,
      input {
        height: 3rem;
        width: 100%;
        min-width: 17rem;
        border: none;
        border-bottom: 1px solid $primary-color-black;
      }
      input {
        border: none;
        outline: none;
        border-bottom: 1px solid $primary-color-black;
        border-radius: 0rem;
      }
    }
    
  }
}
</style>
