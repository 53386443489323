import axios from "axios";
//import { boolean } from 'yup';
const state = {
  businessEntityData: {
    businessName: "",
    affiliate: "",
    ein: "",
    businessEntityID: 0,
    id: 0,
  },
  isPaymentSchedule:false,
  canBeDeleted: false,
  users: {
    roles: [
      {
        businessUserID: 0,
        businessID: "",
        businessRoleID: "",
        contactID: "",
        accountStatus: "",
        isActive: "",
        salutationID: "",
        firstName: "",
        middleName: "",
        lastName: "",
        emailAddress: "",
        phoneNo: "",
        businessRoleName: "",
        roleID: 0,
        // associatedBusinesses: []
      },
    ],
    programInvitations: [0],
  },
  businessContactData: {
    contactID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    salutationID: "",
    accountStatusID: 0,
    phoneNo: "",
    emailAddress: "",
    businessID: "",
    businessRoleID: "",
    isActive: "",
    businessRoleName: "",
    accountStatus: "",
    userRoles: [],
    associatedBusinesses: [],
  },
  programs: {
    programInvitation: [
      {
        id: "",
        programID: "",
        businessID: "",
        businessName: "",
        programStatus: "",
        programName: "",
        contactID: "",
        firstName: "",
        lastName: "",
        notifiedOn: "",
      },
    ],
  },
  fundingEntities:{
    fundingEntity:[{
      fundingEntitiyID: "",
      fundingEntitiName:""
    }]
  },
  temporary: {
    businessEntity: {
      id: Number,
      value: String,
    },
    user: {
      id: Number,
      value: String,
    },
    programInvitation: {
      id: Number,
      value: String,
    },
    fundingEntity:{
      id: Number,
      value: String,
    },
    isAllSelectForProgramInvitation: true,
  },
  fundingDetailsForBusiness: {
    programId: "",
    ProgramList: [],
    PaymentScheduleSummary: {
      contactID:'',
      fundPaymentScheduleID:0,
      fundAllocatedAmount: "",
      fundRequestedAmount: "",
      fundDisbursedAmount: "",
      fundPendingAmount: "",
      additionalNotesAgreement:'',
      programName:'',
      programID:0,
      AgreementDocument: {
        documentID: 0,
        documentTypeID: 1,
        fileName: "",
        documentGUID: "",
        physicalFileStorageKey: "",
        documentName: "",
        fileSize: 0,
        fileUploadedSourceUrl: "",
      },
    },
    PaymentScheduleTransactionList: {},
    PaymentScheduleTransactionData: {
      createdDateTime: "",
      createdByUserID: 0,
      lastModifiedDateTime: "",
      lastModifiedByUserID: 0,
      isActive: true,
      paymentScheduleID: 0,
      loanApplicationID: 0,
      businessID: 0,
      programID: 0,
      contactID: 0,
      fundingTypeID: 0,
      fundedAmount: 0,
      transactionStatusID: 0,
      transactionDate: "",
      transactionStatusName: "",
      fundingTypeName: "",
      fundedAmountFormat: "",
    },
    LoanProgram:[]
  },
  ProgramsFromFundingSource: [],
  businessusers: {},
  programInvitationActiveTab: "",
  isBusinessUserListEmpty: false,
  isProgramInvitationListEmpty: false,
};
const getters = {};
const actions = {
  updateTemporary: (state, payload) => {
    console.log(state, payload);
  },
  SaveBusinessEntity: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Admin/AddBusinessEntity", payload, config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit("mutateBusinessContactData", null);
              vueContext.commit("mutateProgramInvitations", null);
              //vueContext.commit();
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  GetBusinessEntity: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessEntityID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("Admin/GetBusinessEntityDetails", config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit( "mutatebusinessEntity",res.data.businessViewEntity);
              vueContext.commit("mutatebusinessEntityDeleteFlag",res.data.canBeDeleted);
              state.isPaymentSchedule=res.data.isPaymentSchedule;
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  getbusinessuserList: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("Admin/FetchBusinessUsers", config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit("mutateBusinessusers", res.data.contacts);
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  SaveUserEntity: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Contact/InviteContact", payload, config)
          .then((res) => {
            if (res.status === 200) {
              // this.$store.dispatch("fetchBusinessUsersDetails", param.businessID, {
              //   root: true,
              // });
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  updateBusinessUsersDetails: (vueContext, payload) => {
    vueContext.commit("mutateBusinessContactData", payload);
  },
  fetchBusinessUsersDetails: (vueContext, payload) => {
    let token = localStorage.getItem("token");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessID: parseInt(payload),
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/Contact/GetBusinessContacts", config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit("mutateBusinessContactData", res.data.data);

            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getContacts: (vueContext, payload) => {
    let token = localStorage.getItem("token");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        contactID: parseInt(payload),
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/Contact/GetContact", config)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.programInvitations === null) {
              state.users.programInvitations = [0];
            } else {
              state.users.programInvitations = res.data.programInvitations;
            }

            vueContext.commit("mutateBusinessContactData", res.data.contact);

            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  GetAllProgramsFromFundingSource: (vueContext) => {
    let token = localStorage.getItem("token");

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/FundingSource/GetAllPrograms", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            vueContext.commit(
              "mutateProgramsFromFundingSource",
              res.data.programs
            );
            resolve(res.data.programs);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  createProgramInvitation: (vueContext) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .get("/Admin/FetchProgramInvitationPerRequiredData", config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit(
              "mutatebusinessEntityData",
              res.data.businessEntities
            );
            vueContext.commit("mutateProgramInvitationData", [
              res.data.programs,
              res.data.isAllSelect,
            ]);
            vueContext.commit("mutateUserData", res.data.contacts);
            vueContext.commit("mutateFundingEntitiesData", res.data.fundingEntities);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  sendProgramInvitation: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/Admin/SaveProgramInvitation", payload, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getBusinessEntityList: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .post("/Admin/FetchBusinessEntity", payload, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateProgramInvitations: (vueContext, payload) => {
    vueContext.commit("mutateProgramInvitations", payload);
  },

  getBusinessProgramInvitations: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessId: parseInt(payload),
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("Admin/FetchBusinessProgramInvitations", payload, config)
        .then((res) => {
          if (res.status === 200) {
            vueContext.commit(
              "mutateProgramInvitations",
              res.data.programInvitations
            );
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //Fetch contacts
  fetchContacts: () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let param = {
      length: 10,
      start: 0,
      draw: 10,
      sortBy: "string",
      sortDesc: true,
      search: {
        searchByAttribute: "string",
        value: "string",
        inActive: true,
      },
      order: [
        {
          column: 0,
          name: "string",
          dir: "string",
        },
      ],
      filterParameters: [
        {
          key: "string",
          value: "string",
        },
      ],
      isColumnFilter: true,
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/Contact/GetAllInternalContacts", param, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetPasswordLink: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        userName: payload,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("Contact/ResetPassword", payload, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchBusinessContacts: () => {
    let token = localStorage.getItem("token");
    let param = state.businessEntityData.id;
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        businessID: param,
      },
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/Contact/GetAllBusinessContacts", param, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  //active Contact
  ActivateContact: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        contactID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/Contact/ActivateContact", payload, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess == true) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //deactivate Contact
  DeActivateContact: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        contactID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/Contact/DeactivateContact", payload, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess == true) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //reset Contact
  ResetContact: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      let data = {
        emailId: payload.emailAddress,
        contactID: parseInt(payload.contactID),
      };
      return new Promise((resolve, reject) => {
        axios
          .post("/Contact/ResetContact", data, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess == true) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  //Unblock Business Contact
  Unlockaccount: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      // {
      //   "emailId": "string",
      //   "contactID": 0
      // }
      let data = {
        emailId: payload.emailAddress,
        contactID: parseInt(payload.contactID),
      };
      return new Promise((resolve, reject) => {
        axios
          .post("/Contact/Unlockaccount", data, config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess == true) {
              resolve(res);
            } else {
              reject(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //Fetch business contacts
  fetchAllBusinessContacts: () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let param = {
      length: 10,
      start: 0,
      draw: 10,
      sortBy: "string",
      sortDesc: true,
      search: {
        searchByAttribute: "string",
        value: "string",
        inActive: true,
      },
      order: [
        {
          column: 0,
          name: "string",
          dir: "string",
        },
      ],
      filterParameters: [
        {
          key: "string",
          value: "string",
        },
      ],
      isColumnFilter: true,
    };
    return new Promise((resolve, reject) => {
      axios
        .post("/Contact/GetAllBusinessContacts", param, config)
        .then((res) => {
          if (res.status === 200) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  AddRoleForContact: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Contact/AddRoleForExistingContact", payload, config)
          .then((res) => {
            if (res.status === 200) {
              // this.$store.dispatch("fetchBusinessUsersDetails", param.businessID, {
              //   root: true,
              // });
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  SaveBusinessContact: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Contact/SaveorUpdateBusinessContact", payload, config)
          .then((res) => {
            if (res.status === 200) {
              // this.$store.dispatch("fetchBusinessUsersDetails", param.businessID, {
              //   root: true,
              // });
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },

  DeleteBusinessAndNulContact: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        contactID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("Contact/DeleteContact", payload, config)
          .then((res) => {
            if (res.status === 200) {
              // this.$store.dispatch("fetchBusinessUsersDetails", param.businessID, {
              //   root: true,
              // });
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  // funding details tracking

  // /Master/GetMasterOption
  GetMasterOption: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        category: payload,
      },
    };
    //'PaymentSchedule'
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/Master/GetMasterOption", config)
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  // get the ProgramList for FundingSource
  FundingSourceGetProgramList: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        businessID: parseInt(payload),
      },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("FundingSource/GetProgramList", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess === true) {
            vueContext.commit(
              "mutateFundingSourceProgramList",
              res.data.masterOptionDetails
            );
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // Upload Fund Agreement Document
  UploadFundAgreementDocument: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    const data = new FormData();
    // add file or files
    data.append("file", payload.file);

    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("FundingSource/UploadFundAgreementDocument", data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  ///FundingSource/GetPaymentScheduleLoanNoList
  GetPaymentScheduleLoanNoList: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessID: parseInt(payload),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("FundingSource/GetPaymentScheduleLoanNoList", config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              vueContext.commit( "mutateFundingSourceLoanProgram",res.data.masterOptionDetails); 
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //Payment Schedule Summary
  GetPaymentScheduleSummary: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessID: parseInt(payload.businessID),
        applicationId: parseInt(payload.applicationId),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("FundingSource/GetPaymentScheduleSummary", config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              vueContext.commit(
                "mutatePaymentScheduleSummary",
                res.data
              );
              
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //save SaveOrUpdate Payment Schedule and Payment Schedule Transaction
  SaveorUpdatePaymentScheduleAndTransaction: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("FundingSource/SaveorUpdatePaymentScheduleAndTransaction",payload,config)
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //Get Payment Schedule Transaction list
  GetPaymentScheduleTransaction: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        businessID: parseInt(payload.businessID),
        applicationID: parseInt(payload.applicationId),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("FundingSource/GetPaymentScheduleTransaction", config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess === true) {
              vueContext.commit(
                "mutatePaymentScheduleTransactionList",
                res.data.paymentScheduleTransactionList
              );
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  //Get Payment Schedule Transaction
  GetPaymentScheduleTransactionById: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        paymentScheduleID: parseInt(payload.paymentScheduleID),
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("FundingSource/GetPaymentScheduleTransactionById", config)
          .then((res) => {
            if (res.status === 200) {
              vueContext.commit(
                "mutatePaymentScheduleTransactionData",
                res.data.paymentScheduleTransaction
              );
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  // Delete Payment Schedule from list
  RemovePaymentScheduleTransaction: (vueContext, payload) => {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "FundingSource/RemovePaymentScheduleTransaction",
            payload,
            config
          )
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  exportBusinessContactsReport(vueContext,payload){
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'blob',
    };
    let params = { 
      filterParameters: payload, 
      
      
    }
    if (config) {
      return new Promise((resolve, reject) => {
        axios.post('/Contact/ExportBusinessContacts', params, config).then(res => {
          if (res.status === 200) {
            let blob = new Blob([res.data], { type: res.headers["content-type"] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "BusinessContactReport";
            link.click();
            resolve(res)
          }
        })
          .catch(err => {
            reject(err)
          })
      })
    }
},
  TiggerManualNotification:(vueContext, payload)=>
  {
    let token = localStorage.getItem("token");
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            "FundingSource/NotifyPaymentSummaryTransaction",
            payload,
            config
          )
          .then((res) => {
            if (res.status === 200) {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
    
  }
};
const mutations = {
  mutatePaymentScheduleTransactionData(state, data) {
    state.fundingDetailsForBusiness.PaymentScheduleTransactionData = data;
  },
  mutatePaymentScheduleTransactionList(state, data) {
    state.fundingDetailsForBusiness.PaymentScheduleTransactionList = data;
  },
  mutatePaymentScheduleSummary(state, data) {
    state.fundingDetailsForBusiness.PaymentScheduleSummary.fundPaymentScheduleID=data.paymentScheduleSummary.fundPaymentScheduleID
    state.fundingDetailsForBusiness.PaymentScheduleSummary.contactID=data.paymentScheduleSummary.contactID
    state.fundingDetailsForBusiness.PaymentScheduleSummary.programName=data.paymentScheduleSummary.programName
    state.fundingDetailsForBusiness.PaymentScheduleSummary.programID=data.paymentScheduleSummary.programID
    //programID
    state.fundingDetailsForBusiness.PaymentScheduleSummary.fundAllocatedAmount =
      data.paymentScheduleSummary.fundAllocatedAmount;
    state.fundingDetailsForBusiness.PaymentScheduleSummary.fundRequestedAmount =
      data.paymentScheduleSummary.fundRequestedAmount;
    state.fundingDetailsForBusiness.PaymentScheduleSummary.fundDisbursedAmount =
      data.paymentScheduleSummary.fundDisbursedAmount;
    state.fundingDetailsForBusiness.PaymentScheduleSummary.fundPendingAmount =
      data.paymentScheduleSummary.fundPendingAmount;
      state.fundingDetailsForBusiness.PaymentScheduleSummary.additionalNotesAgreement =
      data.paymentScheduleSummary.additionalNotesAgreement
      if(data.paymentScheduleDocument!=null || data.paymentScheduleDocument!=undefined){
      state.fundingDetailsForBusiness.PaymentScheduleSummary.AgreementDocument=data.paymentScheduleDocument
      }
      else{
        state.fundingDetailsForBusiness.PaymentScheduleSummary.AgreementDocument={
          documentID: 0,
          documentTypeID: 1,
          fileName: "",
          documentGUID: "",
          physicalFileStorageKey: "",
          documentName: "",
          fileSize: 0,
          fileUploadedSourceUrl: "",
        }
      }

  },
  mutateFundingSourceProgramList(state, data) {
    state.fundingDetailsForBusiness.ProgramList = data;
  },

  mutateFundingSourceLoanProgram(state, data) {
    //GetPaymentScheduleLoanNoList
    state.fundingDetailsForBusiness.LoanProgram = data;
  },

  mutatebusinessEntityData(state, data) {
    state.temporary.businessEntity = data;
  },
  mutateProgramInvitationData(state, data) {
    state.temporary.programInvitation = data[0];
    state.temporary.isAllSelectForProgramInvitation = data[1];
  },
  mutateFundingEntitiesData(state, data) {
    state.temporary.fundingEntity = data;
  },  
  mutateUserData(state, data) {
    state.temporary.user = data;
  },
  mutatebusinessEntity(state, data) {
    state.businessEntityData = data;
  },
  mutatebusinessEntityDeleteFlag(state, data) {
    state.canBeDeleted = data;
  },
  mutateProgramsFromFundingSource(state, data) {
    state.ProgramsFromFundingSource = data;
  },

  mutateBusinessContactData: (state, payload) => {
    state.isBusinessUserListEmpty = false;
    if (payload === undefined || payload === null)
      state.isBusinessUserListEmpty = true;
    else Object.assign(state.users.roles, payload);
  },
  mutateProgramInvitations(state, data) {
    state.isProgramInvitationListEmpty = false;
    if (data === undefined || data === null)
      state.isProgramInvitationListEmpty = true;
    else Object.assign(state.programs.programInvitation, data);
  },
  mutateBusinessusers(state, data) {
    state.businessusers = {};
    Object.assign(state.businessusers, data);
  },
};
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
