<template>
  <div class="table-view">
    <div class="table-view__header d-flex">
      <div class="table-view__header--left">
        <div class="sort">
        </div>
      </div>
      <div class="table-view__header--right p-0 col d-flex justify-content-end">
        <div class="search">
          <div class="d-flex  flex-wrap">
          </div>
          <label for="search" class="ml-1 d-flex align-items-center search-by-filter" >Search&nbsp;By:
           <span class="search-by-filter__select">
            <select
            class="search-group search-by-filter__select mx-sm-2 mx-0 px-1 my-1"
            placeholder=" "
            name="search-group"
            id="search-group"
            v-model="selectOptionToFilter"
          >
            <option selected value="all">
              <p>All</p>
            </option>
            <option value="createdDateTime">
              <p>Transaction Date</p>
            </option>
            <option value="transactionType">
              <p>Transaction Type</p>
            </option>
            <option value="transactionAmount">
              <p>Transaction amount</p>
            </option>
            <option value="comment">
              <p>Comments</p>
            </option>
          </select>
          </span>
          </label>
          <span class=" mx-1 d-flex search-by-input " >
            <input
          class="search-by-input__input"
            type="text"
            name="searchByKey"
            id="searchByKey"
            v-model="filter"
            placeholder="Search here"
          />
          <button class="reset-button" @click="ResetSort">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
          </span>
          
          <div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="table-view__table">
      
      <table class="table">
        <thead>
          <tr>
            <th @click="Sort('createdDateTime')" class="tabel-header">
              <span class="tabel-header__data"  :class="values.currentSort=='createdDateTime'?'show-icon':''">
                <p class="tabel-header__title">Transaction Date</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('transactionType')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='transactionType'?'show-icon':''">
                <p class="tabel-header__title">Transaction Type</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('transactionAmount')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='transactionAmount'?'show-icon':''">
                <p class="tabel-header__title">Transaction amount</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="Sort('comment')" class="tabel-header">
              <span class="tabel-header__data" :class="values.currentSort=='comment'?'show-icon':''">
                <p class="tabel-header__title">Comments</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
             <th class="tabel-header">
              <span class="tabel-header__data">
                <p class="tabel-header__title">Document</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody class="tbody">
          
            
          <tr v-for="(item) in displayedPosts" :key="item" >
            <td>
              <p v-html="highlightMatches(item['createdDateTime'])"></p>
            </td>
            <td>
              <p v-html="highlightMatches(item['transactionType'])"></p>
            </td>
            <td>
               <span v-html="highlightMatches(item['transactionAmount'])"></span>
            </td>
            <td>
              <p v-html="highlightMatches(item['comment'])"></p>
            </td>
             <td>
               <a v-if="(item['fundTransactionDocumentID']) !==0" href="#" class="downloadLink col-4" @click="download(item)">
               Download</a>
     
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class ="table-view__footer" >
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Pagination from "@/components/Pagination.vue"
export default {
  components: {
    // Pagination
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type:Number,
      default:10
      },
      TableHeight:{
        type:Number,
      },
    columns: {
      required: true,
      type: Array,
    }
  },
  data() {
    return {
      filter: "",
       unsort:false,
      list: this.items,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages:[],
        totalPages: 1,
        currentPage: 1,
        currentSort:[this.items==1][this.columns==1],
        currentSortDir:'asc',
      },
    
    };
  },
  methods: {
    ResetSort() {
      this.filter='';
      this.values.currentSort=''
      this.selectOptionToFilter='all';
      this.unsort=true;
    },
      download(item){

        this.$store.dispatch("updateLoaderFlag", true);
      var data ={
        physicalFileStorageKey:item.physicalFileStorageKey,
        fileName:item.fileName
      }
      this.$store.dispatch("Download",data).then((res)=>{
        this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        }).catch(err =>{
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    highlightMatches(text) {
      const textStr = text.toString();
      const matchExists = textStr.toLowerCase().includes(this.filter);
      if (!matchExists) return textStr;

      const re = new RegExp(this.filter, "ig");
      return textStr.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    Sort(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages () {
			let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
			for (let index = 1; index <= numberOfPages; index++) {
				this.values.pages.push(index);
			}
      
		},
    paginate (posts) {
			let page = this.values.currentPage;
			let perPage = this.perPage;
			let from = (page * perPage) - perPage;
			let to = (page * perPage);
      this.values.totalPages =Math.ceil(this.filteredRows.length / this.perPage);
      if(this.values.totalPages===0){
        this.values.totalPages=1
      }
			return  posts.slice(from, to);
		},
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
    setTableHeight(){
      return 'height: ' + this.TableHeight +'vh';
    },
    sortTable() {
      let lists = this.list;
      let data;
      if(this.unsort===true){
        return lists
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        const createdDateTime = row.createdDateTime.toLowerCase();
        const transactionType = row.transactionType.toLowerCase();
        const transactionAmount = row.transactionAmount.toString().toLowerCase();
        const comment = row.comment.toString().toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            createdDateTime.includes(searchTerm) ||
            transactionType.includes(searchTerm) ||
            transactionAmount.includes(searchTerm) ||
            comment.includes(searchTerm) ;
        }
        if (this.selectOptionToFilter == "createdDateTime") {
          filtedItems = createdDateTime.includes(searchTerm); 
        }
        if (this.selectOptionToFilter == "transactionType") {
          filtedItems = transactionType.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "transactionAmount") {
          filtedItems = transactionAmount.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "comment") {
          filtedItems = comment.includes(searchTerm); 
        }
        return filtedItems;
      });
    },
    displayedPosts () {
			return this.paginate(this.filteredRows);
		}
    

  },
  watch: {
		posts () {
			this.setPages();
		}
	},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
  @include table-style;
  .table-view__table .table .tbody {
        padding: 0.5rem;
        height: 20vh;
        display: block;
        width: 100%;
        overflow: auto;
      }
  .search{
    .search-by-filter,.search-by-input{
      @media screen and (max-width: 580px){
      width: 85vw;
      }
    }
  }
</style>
