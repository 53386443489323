<template>
  <div class="text-area">
    <div class="text-area__container row">
      <div class="row pr-5">
        <div
          class="col-12 p-0 m-0 textarea-group"
          v-show="isAgreement1 == true"
        >
          <div
            class="textarea-group--textarea"
            style="overflow-x: auto;"
            v-html="data"
          ></div>
          <span class="d-flex align-items-center">
            <h3 class="p-0 m-0">
              Grant Agreement :
            </h3>
            <p class="downloadLink m-2 p-0 " @click="DownloadAgreement()">
              Download
            </p>
          </span>
        </div>
        <div
          class="col-12 p-0 m-0 secondaryAgreement"
          v-if="isPaymentSchedule == true"
          v-show="isAgreement2 == true"
        >
          <h1 class="PaymentAgreement-header">
            {{ ScheduleofPaymentAgreement }}
          </h1>
          <div class="PaymentScheduleTransactionAgreement">
            <iframe
              :src="PaymentScheduleTransactionAgreementAPI + '#toolbar=0'"
              frameborder="0"
              class="PaymentScheduleTransactionAgreement__viewer"
              id="PaymentScheduleTransactionAgreement__viewer"
            ></iframe>
            <div class="row m-0 p-0">
              <span class="col-6 p-0 d-flex align-items-center">
                <h3 class="p-0 m-0">
                  Payment Schedule :
                </h3>
                <p
                  class="downloadLink m-2 p-0 "
                  @click="
                    DownloadPaymentScheduleTransactionAgreement(applicationID)
                  "
                >
                  Download
                </p>
              </span>
              <span class="col-6 p-0 d-flex align-items-center"
              v-if="isSPAExist == true"
              > 
              <h3 class="p-0 m-0">
                Supporting Document :
              </h3>
              <p
                :href="SPADocumentData.fileName"
                class="downloadLink m-2 p-0 "
                @click.prevent="download(SPADocumentData)"
              >
                {{ SPADocumentFileName }} 
              </p>
            </span>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <div class="text-area__button-section">
      <div
        class="row m-0 p-2 d-flex justify-content-sm-between justify-content-center"
      >
        <div class="Agreement-btns__left p-0 col-sm-2 col-5 order-2 order-sm-1">
          <span class="btn--tertiary d-flex justify-content-end ">
            <BtnTertiary
              v-show="isPaymentSchedule == true && isAgreement2 == true"
              class=" ml-3 p-0"
              :buttonName="`<  Previous`"
              @click="previous()"
            />
          </span>
        </div>

        <div class="col-sm-2 col-5 d-flex mx-sm-0 mx-4 p-0 order-1 order-sm-2">
          <span class="">
            <BtnPrimary
              v-show="isPaymentSchedule == true ? isAgreement2 == true : true"
              class="btn--primary  ml-3 p-0"
              :buttonName="`Agree`"
              @click="submit('Read Agreement')"
            />
          </span>
        </div>

        <div
          class="Agreement-btns__right  p-0 col-sm-2 col-5 order-3 order-sm-3"
        >
          <span class="btn--tertiary d-flex justify-content-end ">
            <BtnTertiary
              class=" ml-3 p-0"
              v-show="isPaymentSchedule == true && isAgreement1 == true"
              buttonName="Next  >"
              @click="next()"
            />
          </span>
        </div>
        <!-- <div class="col-2">
                <BtnPrimary class="btn--primary ml-3 p-0" 
                 :buttonName="`Disagree`" @click="isDisagree=true" 
                  />
            </div> -->
      </div>
      
    </div>
  <div class="central-popup" v-if="confirmAgreement == true">
        <div class="central-popup__main" v-if="confirmAgreement === true">
          <span class="close-btn">
            <button @click="confirmAgreement = false">
              <img src="@/assets/imgs/cancel.svg" alt="close button" />
            </button>
          </span>
          <div>
            <div class="central-popup__main__heading py-0 px-2 m-0">
              <h3>Notification</h3>
            </div>

            <div class="MainDiv row m-0 p-0">
              <div class="col-12 m-0 p-4">
                <p>               
                  By submitting this agreement, I am confirming that I read and agree to all terms in the General Agreement,
               </p>
               <p>
                  Payment Schedule and Supporting Documents Attachments.
                 </p>    
              </div>
            </div>
          </div>
          <div>
            <div class=" d-flex justify-content-center pb-4 px-2">
              <div class="mr-5">
                <span>
                  <BtnPrimary class="confirms-btn"
                    :buttonName="`Submit`"
                    @click="submit()"
                  />
                </span>
              </div>
           
            </div>
          </div>
        </div>
        
      </div>
    
  </div>
</template>
<script>
import axios from "axios";
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import BtnTertiary from "@/components/BtnTertiary.vue";

export default {
  components: {
    BtnPrimary,
    
    BtnTertiary,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      data: { required },
    };
  },
  created() {
    this.$store.dispatch("updateLoaderFlag", true);
    /** Get workflow command and button for submit loan application */
    let data = this.$store.state.borrower;
    // let requestedFundAmount = data.fundingData.inputs.requestedFundAmount;
    let applicationID = data.applicantData.applicationID;
    this.applicationID = applicationID;
    this.isAgreement1 = true;
    this.isAgreement2 = false;
    this.$store
      .dispatch("getAgreementData", applicationID, {
        root: true,
      })
      .then((res) => {
        this.data = res.data;
        this.$store.dispatch("updateLoaderFlag", false);
      })
      .catch((err) => {
        Promise.reject(err);
        this.$store.dispatch("updateLoaderFlag", false);
      });
    // GetSPADocument
    // if (requestedFundAmount > 250000) {
    this.isSPAExist = data.fundingData.inputs.isSPAExist;
    if (this.isPaymentSchedule == true) {
      this.PaymentScheduleTransactionAgreementAPI =
        axios.defaults.baseURL +
        "/PDFGenerator/GetPaymentScheduleTransactionAgreement?applicationId=" +
        applicationID;
    }

    if (this.isSPAExist == true) {
      this.$store.dispatch("updateLoaderFlag", false);
      this.SPADataAPI =
        axios.defaults.baseURL +
        "/Master/GetSPAData?ApplicationId=" +
        applicationID;
      this.$store
        .dispatch("GetSPADocument", applicationID, { root: true })
        .then((res) => {
          this.SPADocumentData = res.data;
          let filename= this.SPADocumentData.fileName;
          let characterLimit=9;
        if (filename.length >= characterLimit) {
          filename =
            filename
              .slice(0, filename.length)
              .substring(0, characterLimit - 1)
              .trim() + "...";
              
        }
        this.SPADocumentFileName = filename;
        //this.SPADocumentData.fileName=filename
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          Promise.reject(err);
          this.$store.dispatch("updateLoaderFlag", false);
        });
    }
    // }
  },
  data() {
    return {
      ScheduleofPaymentAgreement: "Schedule of Payment Agreement",
      isAgreement1: true,
      isAgreement2: false,
      isSPAExist: false,
      SPADataAPI: "",
      PaymentScheduleTransactionAgreementAPI: "",
      applicationID: 0,
      SPAData: "",
      SPADocumentData: {},
      SPADocumentFileName :'',
      PaymentScheduleTransactionAgreement: "",
      DisagreementLabel: "Do you really want to reject the agreement?",
      isDisagree: false,
      data: "",
      label: "Terms & Conditions of Black Restaurant Accelerator Program ",
      /*command: "Disagree",*/
      confirmAgreementLabel: "By submitting this agreement, I am confirming that I read and agree to all terms in the General Agreement, </ br> Payment Schedule and Supporting Documents Attachments.",
      confirmAgreement : false,
    };
  },
  methods: {
    DownloadPaymentScheduleTransactionAgreement(applicationId) {
      this.$store.dispatch("updateLoaderFlag", true);
      let config = {
        responseType: "blob",
      };
      if (config) {
        return new Promise((resolve, reject) => {
          axios
            .get(
              "/PDFGenerator/GetPaymentScheduleTransactionAgreement?applicationId=" +
                applicationId,
              config
            )
            .then((res) => {
              if (res.status === 200) {
                let blob = new Blob([res.data], {
                  type: res.headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download =
                  "PaymentScheduleTransactionAgreement" + applicationId;
                link.click();
                this.$store.dispatch("updateLoaderFlag", false);
                resolve(res);
              }
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              reject(err);
            });
        });
      }
    },
    DownloadAgreement() {
      this.$store.dispatch("updateLoaderFlag", true);
      let config = {
        params: {
          applicationID: this.$store.getters.getapplicationID,
        },
        responseType: "blob",
      };
      if (config) {
        return new Promise((resolve, reject) => {
          axios
            .get("PDFGenerator/GetAgreement", config)
            .then((res) => {
              if (res.status === 200) {
                let blob = new Blob([res.data], {
                  type: res.headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = "Agreement";
                link.click();
                this.$store.dispatch("updateLoaderFlag", false);
                resolve(res);
              }
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              reject(err);
            });
        });
      }
    },
    next() {
      this.isAgreement1 = false;
      this.isAgreement2 = true;
    },
    previous() {
      this.isAgreement1 = true;
      this.isAgreement2 = false;
    },
    download(item) {
      this.$store.dispatch("updateLoaderFlag", true);
      var data = {
        physicalFileStorageKey: item.physicalFileStorageKey,
        fileName: item.fileName,
      };
      this.$store
        .dispatch("Download", data)
        .then((res) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.resolve(res);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
        });
    },
    submit(command) {
      if(command != undefined)
      {
        if(command == 'Read Agreement' && this.isPaymentSchedule == true)
        {
          this.confirmAgreement = true;
          return;
        }
      }
      let param = {
        storeData: this.$store.state.borrower,
        triggerdCommandName:
          command == undefined ? this.$store.getters.commandname : command,
        applicationID: this.$store.getters.applicationID,
        selectedProgramInvitationID: this.$store.getters.programInvitationID,
      };
      this.$store.dispatch("updateLoaderFlag", true);
      this.$store
        .dispatch("SaveApplication", param, { root: true })
        .then((res) => {
          if (res.data.isSuccess === true) {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage(
              "",
              res.data.message,
              "success"
            );
          } else {
            this.$store.dispatch("updateLoaderFlag", false);
            this.toasterMessage("", res.data.message, "danger");
          }

          this.$router.push("/tracks");

          /** Get workflow command and button for submit loan application */
          this.$store
            .dispatch("getWorkFlowCommand", this.selectedLoanApplicationID, {
              root: true,
            })
            .then((workflowCommand) => {
              this.workFlowCommandList = workflowCommand.data;
              // this.workflowCommandForReviewtab.buttonName = workflowCommand.data.workFlowCommands[0].name;
            })
            .catch((err) => {
              this.$store.dispatch("updateLoaderFlag", false);
              Promise.reject(err);
            });
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          Promise.reject(err);
          this.$store.dispatch("updateLoaderFlag", false);
          this.toasterMessage("", "Error while processing request", "danger");
        });
    },
  },
  computed: {
    isPaymentSchedule() {
      return this.$store.state.borrower.fundingData.inputs.isPaymentSchedule;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";

.text-area {
  //padding-right: 42.7rem;
  max-width: 95%;
  &__button-section {
    padding-right: 2.8rem;
    .Agreement-btns {
      // &__left {
      //       padding: 0rem;
      //       .btn--tertiary {
      //         button {
      //           position: relative;
      //           &:before {
      //             position: absolute;
      //             left: 0;
      //             content: "<";
      //           }
      //         }
      //       }
      //     }
      //     &__right {
      //       padding: 0rem;
      //       .btn--tertiary {
      //         button {
      //           position: relative;
      //           &:before {
      //             position: absolute;
      //             right: 0;
      //             content: ">";
      //           }
      //         }
      //       }
      //     }
    }
  }
  &__container {
    height: 50vh;
    overflow-y: auto;
    .row {
      margin: 0;
      width: 100%;
    }
    .textlabel {
      font-size: 1.6rem;
      margin-left: 2rem;
    }
    .textarea-group {
      &--label,
      &--textarea {
        font-size: 1.6rem;
        color: $primary-color-black;
        line-height: 2.2rem;
        padding: 1rem;
        width: 100%;
        outline: none;
      }
      &--textarea {
        height: 45vh;
        // width: 1250px;
        // border: $primary-color-black;
        // box-shadow: 0px .01rem .3rem ;
        border-radius: 0.3rem;
      }
      &--label {
        font-weight: bold;
        padding: 1rem 0rem;
        color: $primary-color-black;
      }
    }
    .PaymentAgreement-header {
      color: $primary-color-red;
      text-align: center;
    }
  }
  .central-popup {
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    position: relative;
    min-height: 20vh;
    min-width: 40%;
    width: auto;
    padding: 0;
    border: 1px solid $tertiary-color-white;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;

    &__heading {
      border-radius: 3rem 3rem 0rem 0rem;
      background-color: $tertiary-color-white;
      h3 {
        padding: 2rem 0.5rem;

        text-align: center;
        font-size: 2.4rem;

        font-weight: bold;
        color: $primary-color-red;
        @media screen and(max-width:570px) {
          padding: 2rem 4.5rem;
        }
      }
    }
    .MainDiv {
      display: flex;
      justify-content: center;
      align-content: center;
      p {
        font-size: 1.6rem;
        text-align: center;
        color: $primary-color-black;
      }
    }
    .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
    }
  }

  .LabelNote,
  .input-values {
    padding: 1rem;
    // margin: 1rem 0;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $primary-color-black;
  }
  .btn-secondary {
    min-width: 13.5rem;
  }
}
  .downloadLink {
    cursor: pointer;
    font-size: 1.6rem;
    word-wrap: break-word;
    color: #0056b3;
    width: auto;
    &:hover {
      text-decoration: underline;
    }
  }
  .close-btn {
      position: absolute;
      top: 3%;
      right: 3%;
      button {
        background: #f7f7f7 0% 0% no-repeat padding-box;
        height: 3.5rem;
        width: 3.5rem;
        border: 1px solid transparent;
        border-radius: 50%;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        img {
          height: 100%;
        }
      }
      }
  .secondaryAgreement {
    // border: 2px solid green;
    .SPAData,
    .PaymentScheduleTransactionAgreement {
      // border: 1px solid blue;
      padding: 1rem;
      &__viewer {
        // border: 1px solid red;
        width: 100%;
        height: 36vh;
      }
    }
  }
  
}
</style>
