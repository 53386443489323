<template>
  <div class="table-view">
    <div class="table-view__header d-flex justify-content-between  align-items-center m-0 p-0">
      <div class="table-view__header--left">
        <div class="table-heading">
          <h3>Business Entities</h3>
        </div>
        <div class="sort"></div>
      </div>
      <div class="table-view__header--right p-0 col d-flex ">
        <div class="search">
          <div class="d-flex  flex-wrap ">
            <label for="search" class="ml-1 d-flex align-items-center search-by-filter">Search&nbsp;By:
              <span class=" search-by-filter__select" >
                <select
                  class="search-group search-by-filter__select mx-2 px-1 my-1"
                  placeholder=" "
                  name="search-group"
                  id="search-group"
                  @change="clearFilter()"
                  v-model="selectOptionToFilter"
                >
                  <option selected value="all">
                    <p>All</p>
                  </option>
                  <option value="businessName">
                    <p>Business Name</p>
                  </option>
                  <option value="ein">
                    <p>EIN</p>
                  </option>
                  <option value="affiliateName">
                    <p>Affiliate Name</p>
                  </option>
                </select>
              </span>
            </label>
          </div>

          <span class="mx-1 d-flex search-by-input " >
            <select
              v-if="selectOptionToFilter === 'affiliateName'"
              class="search-group search-by-input__select my-1"
              placeholder=" "
              name="search-group-filter"
              id="search-group-filter"
              v-model="filter"
            >
              <option value="">Show All</option>
              <option
                v-for="status in affiliateNameList()"
                :key="status"
                :value="status"
              >
                <p>{{ status }}</p>
              </option>
            </select>
            <input
              v-else
              class="search-by-input__input"
              type="text"
              name="searchByKey"
              id="searchByKey"
              v-model="filter"
              placeholder="Search here"
            />

            <button class="reset-button" @click="ResetSort">
              <img
                src="@/assets/imgs/spinner.svg"
                alt="Reset button"
                title="Reset"
              />
            </button>
          </span>
          
          <div>
            
          </div>
        </div>
      </div>
    </div>
    <div class="table-view__table">
      <table class="table">
        <thead>
          <tr>
            <th @click="sortColumn('businessName')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="values.currentSort == 'businessName' ? 'show-icon' : ''"
              >
                <p class="tabel-header__title">Business Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortColumn('ein')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="values.currentSort == 'ein' ? 'show-icon' : ''"
              >
                <p class="tabel-header__title">EIN</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortColumn('affiliateName')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="
                  values.currentSort == 'affiliateName' ? 'show-icon' : ''
                "
              >
                <p class="tabel-header__title">Affiliate Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <!-- <th @click="sort('ein')">
              <span>
              <p>Ein</p> <p>↑↓</p> 
              </span> 
            </th>
            <th @click="sort('affiliateName')">
              <span>
              <p>Affiliate Name</p> <p>↑↓</p> 
              </span></th> -->
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="item in displayedPosts"
            :key="item"
            @click="selectId(item.id)"
          >
            <td>
              <p v-html="highlightMatches(item['businessName'])"></p>
            </td>
            <td>
              <p v-html="highlightMatches(item['ein'])"></p>
            </td>
            <td>
              <p v-html="highlightMatches(item['affiliateName'])"></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      required: true,
      type: Array,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    TableHeight: {
      type: Number,
    },
    columns: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      filter: "",
      unsort: false,
      list: this.items,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages: [],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
    };
  },
  beforeUpdate() {
    this.list = this.items;
  },
  methods: {
    clearFilter() {
      this.filter = "";
    },
    affiliateNameList() {
      let affiliateName = [
        ...new Set(this.list.map((item) => item.affiliateName)),
      ];
      return affiliateName.sort();
    },
    ResetSort() {
      this.filter = "";
      this.values.currentSort = "";
      this.selectOptionToFilter = "all";
      this.unsort = true;
    },
    highlightMatches(text) {
      const matchExists = text.toLowerCase().includes(this.filter);
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    selectId(entityID) {
      this.$emit("selectRow", entityID);
    },
    sortColumn(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.values.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      this.values.totalPages = Math.ceil(
        this.filteredRows.length / this.perPage
      );
      if (this.values.totalPages === 0) {
        this.values.totalPages = 1;
      }
      return posts.slice(from, to);
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
    setTableHeight() {
      return "height: " + this.TableHeight + "vh";
    },
    sortTable() {
      let lists = this.list;
      let data;
      if (this.unsort === true) {
        return lists;
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        const businessName = row.businessName.toString().toLowerCase();
        const ein = row.ein.toLowerCase();
        const affiliateName = row.affiliateName.toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            businessName.includes(searchTerm) ||
            ein.includes(searchTerm) ||
            affiliateName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "businessName") {
          filtedItems = businessName.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        if (this.selectOptionToFilter == "ein") {
          filtedItems = ein.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "affiliateName") {
          filtedItems = affiliateName.includes(searchTerm);
        }
        return filtedItems;
      });
    },
    displayedPosts() {
      return this.paginate(this.filteredRows);
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
@include table-style;
</style>
