import axios from "axios";

const state = {
  documentData: {
    documentName: "",
    active: "",
    documentID: 0,
    documentTypeID:0,
  },
};
const getters = {};
const actions = {
  getDocumentList: () => {
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return new Promise((resolve, reject) => {
      axios
        .get("/Admin/GetAllDocumentTypes", config)
        .then((res) => {
          if (res.status === 200 && res.data.isSuccess===true) {
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clearStore:(vueContext)=>{
    vueContext.commit("mutateClearDocumentData", null);
  },
  getDocument:(vueContext,payload)=>{
    let token = localStorage.getItem("token");
    actions.clearStore(vueContext);
    let config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          DocumentTypeID: parseInt(payload),
        },
      };
      return new Promise((resolve, reject) => {
        axios
          .get("/Admin/GetDocument", config)
          .then((res) => {
            if (res.status === 200 && res.data.isSuccess==true) {
                vueContext.commit("mutateDocumentData", res.data.documentRecords[0]);  
                state.documentData=res.data.documentRecords[0] 
              resolve(res.data.documentRecords);
            }
            else{
                reject(res.data.documentRecords);
            }
          })
          .catch((err) => {
            reject(err.data.message);
          });
      });
  },
  SaveOrUpdateDocuments:(vueContext, payload)=>{
    let token = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (payload) {
        return new Promise((resolve, reject) => {
          axios.post('/Admin/SaveOrUpdateDocuments',payload, config)
            .then(res => {
              if (res.status === 200 && res.data.isSuccess==true) {
                vueContext.commit("mutateDocumentData", res.data);
                resolve(res.data);
              }
              else{
                  reject(res.data)
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }
  }
};
const mutations = {
    mutateClearDocumentData:(state) => {
        state.documentData.documentTypeID=""
        state.documentData.documentName=""
       state.documentData.active=""
        state.documentData.documentID=0
    },
    mutateDocumentData:(state, payload)=> {
    Object.assign(state.documentData, payload);

    },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
