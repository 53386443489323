<template>
  <div class="businessProfileTab">
    <div class="BusinessProfile--inputs">
      <BusinessProfile
      ref="BusinessProfile" />
    </div>
    <div v-if="(userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)"
    class="row d-flex justify-content-sm-end justify-content-center m-0 p-0">
                <span class="">
                  <BtnPrimary
                    :buttonName="`Save`"
                    @click="saveBusinessProfileData()"
                  />
                </span>
              </div>
  </div>
</template>

<script>
import BusinessProfile from "@/components/BusinessProfile.vue";
// import BasicComponent from "@/components/BasicComponent.vue";
// import BreadCrumb from "@/components/BreadCrumb";
import BtnPrimary from "@/components/BtnPrimary.vue";

export default {
  data() {
    return {
      labels: {
        BusinessEntity: "Business Entity",
      },
      businessId: "",
      programInvitationId: "",
      AllBusinessEntity: [],
      texts: {
        heading: "Profile",
      },
      crumbs: ["Profile"],
    };
  },
  components: {
    BtnPrimary,
    BusinessProfile,
    // BasicComponent,
    // BreadCrumb,
  },
  props: {
    entity_ID: {
      type: Number,
      default: 0,
    }
  },
  created() {
    this.businessId = this.entity_ID;
  },

  mounted() {
    this.businessId = this.entity_ID;
  },
  methods: {

    GetBusinessProfileData() {
       // Clear the store value before Updating Data
      this.$store.state.borrower.businessProfileData.ownersData = [{}];
      this.$store.state.borrower.businessProfileData.businessInputs = {};
      this.$store.dispatch("clearBusinessProfileStore");

      this.$store.dispatch("updateLoaderFlag", true);
      let businessID = this.businessId;
      this.$store
        .dispatch("GetBusinessProfileData", businessID)
        .then((res) => {
          if (res.data.isSuccess == true) {
            console.log(res.data.message)
          } else {
            this.toasterMessage("", res.data.message, "danger");
          }
          this.$store.dispatch("updateLoaderFlag", false);
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag", false);
          console.log(err);
        });
    },
    saveBusinessProfileData(){
      this.$emit('saveBusinessProfileData');
    },
    async saveBusinessProfile(){
      let BusinessProfileValidation = await this.$refs.BusinessProfile.validationsStore();
      if(BusinessProfileValidation==true){
        return true
      }
      else{
        return false
      }
    },
  },
  computed: {
    userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.businessProfileTab {
  .BusinessProfile--inputs{
      height: 55vh;
  }
  .form-group {
    position: relative;
    @include input-style;
    .form__label {
      &::after {
        display: none;
      }
    }
  }
}
</style>
