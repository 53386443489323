<template>
  <div class="Business-profile">
    <div class="row">
      <div class=" col form-group">
        <input
           
          type="text"
          name="businessProfile-businessName"
          id="businessProfile-businessName"
          placeholder=""
          v-model="businessInputs.businessName"
          disabled
        />
        <label
          for="businessProfile-businessName"
          class="form__label "
        >
          {{ labels.BusinessLabel.LabelBusinessName }}
        </label>    
      </div>
    </div>
      <div class="row justify-content-between">
      <div class="col-sm-7 col-12 form-group">
        <input
           
          type="text"
          placeholder=" "
          name="businessProfile-dba"
           :disabled=isDisable
          id="businessProfile-dba"
          v-model="businessInputs.dba"
        />
        <label for="businessProfile-dba" class="form__label  ">
          {{ labels.BusinessLabel.LabelDoingBusiness }}
        </label>     
      </div>
      <div class="col-sm-5 col-12 form-group">
        <input
          type="text"
          v-model="businessInputs.affiliateName"
          placeholder=" "
          name="businessProfile-Affiliate"
          id="businessProfile-Affiliate"
          disabled
        />
        <label for="businessProfile-Affiliate" class="form__label  ">
          {{ labels.BusinessLabel.LabelAffiliate }}
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col  form-group">
        <input
           
          type="text"
          placeholder=" "
          name="businessProfile-address"
           :disabled=isDisable
          id="businessProfile-address"
          v-model="businessInputs.address"
        />
        <label for="businessProfile-address" class="form__label  ">
          {{ labels.BusinessLabel.LabelBusinessAddress }}
        </label>    
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="  col-12 col-sm-3 form-group">
        <input
        :disabled=isDisable
          type="text"
          placeholder=" "
          max="5"
          @keyup="formateZip($event, 5)"
          @blur="formateZip($event, 5)"
          name="businessProfile-zip"
          id="businessProfile-zip"
          v-model="businessInputs.zip"
        />
        <label for="businessProfile-zip" class="form__label  ">
          {{ labels.BusinessLabel.LabelZipCode }}
        </label>
      </div>
      <div class="  col-12 col-sm-3 form-group">
        <input
        :disabled=isDisable
          type="text"
          placeholder=" "
          name="businessProfile-city"
          id="businessProfile-city"
          v-model="businessInputs.city"
        />
        <label for="businessProfile-city" class="form__label  ">
          {{ labels.BusinessLabel.LabelCity }}
        </label>     
      </div>
      <div class="  col-12 col-sm-3 form-group">
        <select
          class="select-group select-group--required"
           :disabled=isDisable
          placeholder=" "
          name="businessProfile-state"
          id="businessProfile-state"
          v-model="businessInputs.stateID"
        >
          <option
            v-for="StatesOption in prePopulatedData?.stateList"
            :key="StatesOption.stateID"
            :value="StatesOption.stateID"
          >
            {{ StatesOption.stateName }}
          </option>
        </select>
        <label for="businessProfile-state" class="form__label  ">
          {{ labels.BusinessLabel.LabelState }}
        </label>
        <Error v-if="v$.businessInputs.stateID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.stateID'">
              {{ error.$message }}
            </small>
          </p>
        </Error> 
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <input
          type="text"
          max="10"
          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
          placeholder="XX-XXXXXXX"
          name="businessProfile-ein"
          id="businessProfile-ein"
          v-model="businessInputs.ein"
          @keyup="formateEIN"
          @blur="formateEIN"
          disabled
        />
        <label for="businessProfile-ein" class="form__label  ">
          {{ labels.BusinessLabel.LabelEIN }}
        </label>
        
      </div>
      
    </div>
    <div class="row justify-content-between">
      <div class="  col-12 col-sm-3 form-group">
        <input
          type="email"
          placeholder=" "
           :disabled=isDisable
          name="businessProfile-emailAddress"
          id="businessProfile-emailAddress"
          v-model="businessInputs.emailAddress"
        />
        <label for="businessProfile-emailAddress" class="form__label  ">
          {{ labels.BusinessLabel.LabelBusinessEmail }}
        </label>
      </div>
      <div class="  col-12 col-sm-3 form-group">
        <input
          placeholder="XXX-XXX-XXXX"
          max="12"
          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
           :disabled=isDisable
          name="businessProfile-phoneNumber"
          id="businessProfile-phoneNumber"
          v-model="businessInputs.phoneNumber"
          type="text"
          @keyup="formatePhoneNumberToUSA"
          @blur="formatePhoneNumberToUSA"
        />
        <label for="businessProfile-phoneNumber" class="form__label  ">
          {{ labels.BusinessLabel.LabelBusinessPhoneNo }}
        </label>
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <input
          type="text"
          placeholder=" "
          name="businessProfile-duns"
          id="businessProfile-duns"
           :disabled=isDisable
          @keyup="formateDuns($event)"
          @blur="formateDuns($event)"
          v-model="businessInputs.duns"
        />
        <label for="businessProfile-duns" class="form__label  ">
          {{ labels.BusinessLabel.LabelDUNS }}
        </label>
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <input
         
          type="text"
          placeholder=" "
          name="businessProfile-naicS_ID"
          id="businessProfile-naicS_ID"
           :disabled=isDisable
          @keyup="naicsCode($event)"
          @blur="naicsCode($event)"
          v-model="businessInputs.naicsCode"
    
        />
        <label for="businessProfile-naicS_ID" class="form__label  ">
          {{ labels.BusinessLabel.LabelNAICSCode }}
        </label>
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="  col-12 col-sm-3 form-group">
        <select
          class="select-group select-group--required"
          name="businessProfile-businessTypeID"
          id="businessProfile-businessTypeID"
          placeholder=""
          :disabled=isDisable
          v-model="businessInputs.businessTypeID"
        >
          <option
            v-for="businessType in prePopulatedData?.businessTypeList"
            :key="businessType.businessTypeID"
            :value="businessType.businessTypeID"
          >
            {{ businessType.type }}
          </option>
        </select>
        <label
          for="businessProfile-businessTypeID"
          class="form__label  "
        >
          {{ labels.BusinessLabel.LabelBusinessType }}
        </label>
        <Error v-if="v$.businessInputs.businessTypeID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.businessTypeID'">
              {{ error.$message }}
            </small>
          </p>
        </Error> 
      </div>
      <div class="  col-12 col-sm-3 form-group">
        <select
          class="select-group select-group--required"
          name="businessProfile-industryTypeID"
          id="businessProfile-industryTypeID"
          placeholder=""
           :disabled=isDisable
          v-model="businessInputs.industryTypeID"
        >
          <option
            v-for="industryOption in prePopulatedData?.industryTypeList"
            :key="industryOption.industryTypeID"
            :value="industryOption.industryTypeID"
          >
            {{ industryOption.type }}
          </option>
        </select>
        <label for="businessProfile-industryTypeID" class="form__label  ">
          {{ labels.BusinessLabel.LabelIndustry }}
        </label>
        <Error v-if="v$.businessInputs.industryTypeID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.industryTypeID'">
              {{ error.$message }}
            </small>
          </p>
        </Error> 
      </div>
      <div class=" col-12 col-sm-3 form-group">
        <input
          type="url"
          placeholder=" "
          name="businessProfile-Url"
           :disabled=isDisable
          id="businessProfile-Url"
          v-model="businessInputs.url"
        />
        <label for="businessProfile-Url" class="form__label">
          {{ labels.BusinessLabel.LabelBusinessUrl }}
        </label>
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <flat-pickr 
          :config="Dateconfig"
           
          placeholder=" "
          name="businessProfile-startDate"
          id="businessProfile-startDate"
           :disabled=isDisable
          v-model="businessInputs.startDate"
          class="datepicker" >
          </flat-pickr>
          <label for="businessProfile-startDate" class="form__label  ">
          {{ labels.BusinessLabel.LabelBusinessStartDate }}
        </label>
        
      </div>
    </div>

    <div class="row justify-content-between">
      
      <div class=" col-12 col-sm-3  form-group">
        <input
           
          type="text"
          placeholder=" "
           :disabled=isDisable
          @keyup="numberOfYearsInBusiness($event, 3)"
          @blur="numberOfYearsInBusiness($event, 3)"
          name="businessProfile-numberOfYearsInBusiness"
          id="businessProfile-numberOfYearsInBusiness"
          v-model="businessInputs.numberOfYearsInBusiness"
        />
        <label
          for="businessProfile-numberOfYearsInBusiness"
          class="form__label  "
        >
          {{ labels.BusinessLabel.LabelNoOfYearsBusiness }}
        </label>
        
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <input
           
          type="text"
          placeholder=" "
           :disabled=isDisable
          name="businessProfile-employeeStrength"
          id="businessProfile-employeeStrength"
          @keyup="employeeStrength($event)"
          @blur="employeeStrength($event)"
          v-model="businessInputs.employeeStrength"
        />
        <label
          for="businessProfile-employeeStrength"
          class="form__label  "
        >
          {{ labels.BusinessLabel.LabelNoOfEmployess }}
        </label>
        
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <input
           
          type="number"
          placeholder=" "
          name="businessProfile-bankAccountNumber"
           :disabled=isDisable
          id="businessProfile-bankAccountNumber"
          v-model="businessInputs.bankAccountNumber"
        />
        <label
          for="businessProfile-bankAccountNumber"
          class="form__label  "
        >
          {{ labels.BusinessLabel.LabelAccountNo }}
        </label>
        
      </div>
      <div class=" col-12 col-sm-3  form-group">
        <input
           
          type="number"
          
          placeholder=" "
          name="businessProfile-bankRoutingNumber"
           :disabled=isDisable
          id="businessProfile-bankRoutingNumber"
          v-model="businessInputs.bankRoutingNumber"
        />
        <label
          for="businessProfile-bankRoutingNumber"
          class="form__label  "
        >
          {{ labels.BusinessLabel.LabelRoutingNo }}
        </label>
        
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-12 col-sm-3 form-group">
        <select
          class="select-group select-group--required"
          name="businessProfile-siC_ID"
          :disabled=isDisable
          id="businessProfile-siC_ID"
          placeholder=""
          v-model="businessInputs.siC_ID"
        >
          <option
            v-for="(SICCode, index) in sicCodes"
            :key="index + 1"
            :value="index + 1"
          >
            {{ SICCode }}
          </option>
        </select>
        <label for="businessProfile-siC_ID" class="form__label  ">
          {{ labels.BusinessLabel.LabelSICCode }}
        </label>
        <Error v-if="v$.businessInputs.siC_ID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.siC_ID'">
              {{ error.$message }}
            </small>
          </p>
        </Error> 
      </div>
      <div class="col-sm-9  col-12 form-group">
        <input
          type="text"
          placeholder=" "
          name="businessProfile-Note"
          id="businessProfile-Note"
           :disabled=isDisable
          v-model="businessInputs.comment"
        />
        <label for="businessProfile-Note" class="form__label ">
          {{ labels.BusinessLabel.LabelNote }}
        </label>
      </div>
    </div>

    <div class="row">
      
    </div>

    <div class="owner">
      <BusinessProfileOwner
        :prePopulatedData="prePopulatedData"
        ref="ProfileOwner"
      />
    </div>
    <hr />
    <div class="row" v-if="((userRoleID ==1)||(userRoleID ==2)||(userRoleID ==3) || (userRoleID ==5) ||(userRoleID ==7))" >
      <span class="add-owner-btn">
        <BtnTertiary buttonName="ADD OWNER" @click="addOwners" />
      </span>
    </div>
  </div>
</template>
<script>
import Error from "@/components/Error.vue"
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BtnTertiary from "@/components/BtnTertiary.vue";
import BusinessLabel from "@/textStore.js";
import BusinessProfileOwner from "@/components/BusinessProfileOwner.vue";
import useVuelidate from "@vuelidate/core";
// import { required, numeric, email, minLength } from "@vuelidate/validators";
import { required } from "@vuelidate/validators";
export default {
  props: {
    shouldFieldReadOnly: {
      type: Boolean,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
      BusinessLabel,
    };
  },
  validations() {
    return {
      businessInputs: {
        // emailAddress: { email },
        stateID: { required},
        businessTypeID: { required},
        industryTypeID: { required},
        siC_ID: { required},
        // phoneNumber: {  minLength: minLength(12) },
        // zip: {  minLength: minLength(5), numeric },
        // ein: { required, minLength: minLength(10) },
        // numberOfYearsInBusiness: { numeric,minLength: minLength(1) },
        // employeeStrength: { numeric,minLength: minLength(1) },
      },
    };
  },
  components: {
    BtnTertiary,
    BusinessProfileOwner,
    flatPickr,
    Error
  },
  data() {
    return {
      Dateconfig: {
        dateFormat: "m/d/Y",
        disableMobile: "true"
      },
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
      ein: "EIN",
      einSetUp: {
        einValue: "",
        formattedeinValue: "",
        preventNextIteration: false,
      },
      labels: {},
      sicCodes: [
        'A:44205:Agriculture, Forestry, And Fishing',
        'B:44483:Mining',
        'C:15-17:Construction',
        'D:20-39:Manufacturing',
        'E:40-49:Transportation, Communications, Electric, Gas, And Sanitary Services',
        'F:50-51:Wholesale Trade',
        'G:52-59:Retail Trade',
        'H:60-67:Finance, Insurance, And Real Estate',
        'I:70-89:Services',
        'J:90-99:Public Administration'
      ],
      hideOwner:true
    };
  },
  watch() {},
  computed: {
    businessInputs: {
      get() {
        Object.assign(this.labels, BusinessLabel);
        return this.$store.state.borrower.businessProfileData.businessInputs
      },
    },
    ownerDetails:{
      get() {
        return this.$store.state.borrower.businessProfileData.ownersData;
      },
    },
    prePopulatedData: {
      get() {
        return this.$store.state.borrower.businessProfileData.prePopulatedData;
      },
    },
    userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if((this.userRoleID ==1)||(this.userRoleID ==2)||(this.userRoleID ==3) || (this.userRoleID == 5) ||(this.userRoleID ==7)){
          return false
        }
        else{
          return true
        }
      },
    
    },
  },
  created() {
    if(this.$store.getters.getUserRoleID==2 || this.$store.getters.getUserRoleID==7)
    {
        this.statusName = this.$store.state.borrower.applicantData.applicationStatus;
        if(this.statusName=="Initialized" || this.statusName=="Created" || this.statusName=="Drafted" || this.statusName=="Requested More Information"|| this.statusName==undefined)
          this.hideOwner = false;   
        else
          this.hideOwner = true;
    }
  },
  methods: {
    formateDuns() {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.duns.replace(/[^\d]/g, "");
      value = tempFormattingNumber.replace(/[^\d]/g, "");
      this.businessInputs.duns = value;
      return this.businessInputs.duns;
    },
    naicsCode(event){
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.slice(0,6).replace(/[^0-9]/g, "");

      this.businessInputs.naicsCode = value;
      return event.target.value;
    },
    bankAccountNumber(event) {
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.replace(/[^0-9]/g, "");

      this.businessInputs.bankAccountNumber = value;
      return event.target.value;
    },
    bankRoutingNumber(event) {
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.replace(/[^0-9]/g, "");

      this.businessInputs.bankRoutingNumber = value;
      return event.target.value;
    },
    numberOfYearsInBusiness(event, maxNumber) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.numberOfYearsInBusiness
        .replace(/[^\d,]/g, "")
        .slice(0, maxNumber);

      value = tempFormattingNumber.replace(/[^\d]/g, "");
      this.businessInputs.numberOfYearsInBusiness = value;
      return this.businessInputs.numberOfYearsInBusiness;
    },
    employeeStrength(event) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.employeeStrength.replace(
        /[^0-9]/g,
        ""
      );
      value = tempFormattingNumber.replace(/[^0-9]/g, "");
      this.businessInputs.employeeStrength = value;
      return event.target.value;
    },
    formateZip(event, maxNumber) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.zip.replace(/[^\d,]/g, "");

      value = tempFormattingNumber.slice(0, maxNumber).replace(/[^\d]/g, "");
      this.businessInputs.zip = value;
      return this.businessInputs.zip;
    },
    formateEIN() {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.einSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.einSetUp.preventNextIteration = false;
        return;
      }
      let einNumber = this.businessInputs.ein
        .slice(0, 10)
        .replace(/-/g, "")
        .replace(/[^\d,]/g, "");
      // Format display value based on calculated
      this.businessInputs.ein = einNumber.replace(
        /(\d{1,2})(\d{1,7})/g,
        "$1-$2"
      );
    },
    async validationsStore() {
          const isFormCorrect = await this.v$.$validate();
          if (isFormCorrect ===true) {
            return true
          }
          else{
            return false
          }
      
    },
    addOwners() {
      this.$refs.ProfileOwner.addOwners();
    },
    formatePhoneNumberToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      let phoneNumber = this.businessInputs.phoneNumber
        .replace(/[^\d,]/g, "")
        .slice(0, 10);

      // Format display value based on calculated currencyValue
      this.businessInputs.phoneNumber = phoneNumber.replace(
        /(\d{1,3})(\d{1,3})(\d{1,4})/g,
        "$1-$2-$3"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.Business-profile {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  .row {
    margin: 0;
  }
  .owners-info {
    padding: 1rem 0rem;
    position: relative;
    .delete-owner-btn {
      position: absolute;
      right: 3%;
      button {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.01rem;
        border: none;
        img {
          height: 100%;
        }
      }
    }
  }
  .add-owner-btn {
    button {
      font-size: 1.6rem;
      margin: 0rem;
      padding-left: 2.5rem;
      position: relative;
      &::before {
        content: "+";
        position: absolute;
        left: 0rem;
      }
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
  }

  .form-group {
    padding: 0;
    margin-top: 0.5rem;
    position: relative;
    @include input-style;
    
    
  }
}
</style>
