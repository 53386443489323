<template>
  <div class="program-invitation-list">
    <div class="row justify-content-between" v-if="isInvitationListEmpty === false && inputs[0].id!=0">
      <div class="col-4 px-1">
        <p class="para-info d-flex ">
          <b>Program Name</b>
        </p>
      </div>
      <div class="col-3 px-1">
        <p class="para-info d-flex "><b>Contact</b></p>
      </div>
      
      <div class="col-3 px-1">
        <p class="para-info d-flex ">
          <b>Invited Date</b>
        </p>
      </div>
      <div class="status--continer col-sm-2 col-0 px-1">
        
      </div>
    </div>
    <div class="program-invitation-list--content">
      <div
        v-if="isInvitationListEmpty === true"
        class="d-flex justify-content-center"
      >
        <h3>No Program Invitations availiable</h3>
      </div>
      <div v-if="isInvitationListEmpty === false">
        <div
          class="program-invitation-section"
          v-for="input in inputs"
          :key="input"
        >
          <ProgramInvitations :programInvite="input" :businessID="businessID" />
        </div>
      </div>
    </div>
    <div class="btn--section row justify-content-start">
      <span class="btn--tertiary col-3" v-if="(userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)">
        <BtnTertiary
          buttonName="Send Program Invitation"
          @click="addProgramInvitation"
        />
      </span>
    </div>
  </div>
</template>
<script>
// import User from "@/components/BusinessForms/User.vue"
import ProgramInvitations from "@/components/BusinessForms/ProgramInvitations.vue";
import BtnTertiary from "@/components/BtnTertiary.vue";
export default {
  components: {
    // User,
    ProgramInvitations,
    BtnTertiary,
  },
  props: {
    roleList: [],
    salutationList: [],
  },
  data() {
    return {
      isProgramListEmpty: this.isInvitationListEmpty,
    };
  },
  methods: {
    addProgramInvitation() {
        this.isProgramListEmpty = false;
        if ( this.inputs != null && this.inputs.length === 1 && this.inputs[0].id === undefined)
          this.inputs.splice(0);
        let isDirty=false;
        this.inputs.forEach(element => {
          if(element.id===0)
            isDirty = true;
        });
        if(isDirty===false)
        {
          this.inputs.push({
            id: 0,
            programID: "",
            businessID: this.businessID,
            businessName: "",
            programStatus: "",
            programName: "",
            contactID: "",
            firstName: "",
            lastName: "",
            notifiedOn: "",
          });

          this.$store.dispatch("updateProgramInvitations", this.inputs, {
            root: true,
          });
        }
      }
  },
  
  computed: {
    isInvitationListEmpty: {
      get() {
        return this.$store.state.business.isProgramInvitationListEmpty;
      },
    },
    inputs: {
      get() {
        return this.$store.state.business.programs.programInvitation;
      },
    },
    businessID: {
      get() {
        return this.$store.state.business.businessEntityData.id;
      },
    },
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.program-invitation-list {
 
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  &--content {
    height: 80%;
    overflow-y: auto;
    // .user-section{

    // }
  }
  .btn--section {
    margin: 0rem;
    padding: 0rem;
    .btn--tertiary {
      button {
        width: auto;
        padding: 0rem 2rem;
        position: relative;
        &:before {
          position: absolute;
          left: 0;
          content: "+";
        }
      }
    }
  }
  .row {
    padding-right: 2%;

    margin: 0;
  }
 .para-info {
    font-size: 1.6rem;
    padding: 0rem;
    margin: 0rem;
    @media screen and (max-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
  }
}
</style>
