<template>
  <div class="Business-profile">
    <div class="row">
      <div class=" col form-group">
        <input
          required
          type="text"
          name="businessProfile-businessName"
          id="businessProfile-businessName"
          disabled
          placeholder=""
          v-model="businessInputs.businessName"
        />
        <label
          for="businessProfile-businessName"
          class="form__label select-label"
        >
          {{ labels.BusinessLabel.LabelBusinessName }}
        </label>
        <Error v-if="v$.businessInputs.businessName.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.businessName'">
              {{ error.$message }}
            </small>
          </p>
        </Error>      
      </div>
    </div>
      <div class="row justify-content-between">
      <div class="col-sm-7 col-12 form-group">
        <input
          required
          type="text"
          placeholder=" "
          name="businessProfile-dba"
          :disabled="shouldReadOnly"
          id="businessProfile-dba"
          v-model="businessInputs.dba"
        />
        <label for="businessProfile-dba" class="form__label required">
          {{ labels.BusinessLabel.LabelDoingBusiness }}
        </label>
        <Error v-if="v$.businessInputs.dba.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.dba'">
              {{ error.$message }}
            </small>
          </p>
        </Error>      
      </div>
      <div class=" col-sm-5 col-12 form-group">
        <input
          type="text"
          v-model="businessInputs.Affiliate"
          placeholder=" "
          name="businessProfile-Affiliate"
          id="businessProfile-Affiliate"
          disabled
        />
        <label for="businessProfile-Affiliate" class="form__label required">
          {{ labels.BusinessLabel.LabelAffiliate }}
        </label>
      </div>
    </div>

    <div class="row">
      <div class="col  form-group">
        <input
          required
          type="text"
          placeholder=" "
          name="businessProfile-address"
          :disabled="shouldReadOnly"
          id="businessProfile-address"
          v-model="businessInputs.address"
        />
        <label for="businessProfile-address" class="form__label required">
          {{ labels.BusinessLabel.LabelBusinessAddress }}
        </label>
        <Error v-if="v$.businessInputs.address.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.address'">
              {{ error.$message }}
            </small>
          </p>
        </Error>     
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="    col-12 col-sm-3 form-group">
        <input
          required
          :disabled="shouldReadOnly"
          type="text"
          placeholder=" "
          max="5"
          @keyup="formateZip($event, 5)"
          @blur="formateZip($event, 5)"
          name="businessProfile-zip"
          id="businessProfile-zip"
          v-model="businessInputs.zip"
        />
        <label for="businessProfile-zip" class="form__label required">
          {{ labels.BusinessLabel.LabelZipCode }}
        </label>
        <Error v-if="v$.businessInputs.zip.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.zip'">
              {{ "Enter valid zip code" }}
            </small>
          </p>
        </Error>
      </div>
      <div class="    col-12 col-sm-3 form-group">
        <input
          required
          :disabled="shouldReadOnly"
          type="text"
          placeholder=" "
          name="businessProfile-city"
          id="businessProfile-city"
          v-model="businessInputs.city"
        />
        <label for="businessProfile-city" class="form__label required">
          {{ labels.BusinessLabel.LabelCity }}
        </label>
        <Error v-if="v$.businessInputs.city.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.city'">
              {{ error.$message }}
            </small>
          </p>
        </Error>       
      </div>
      <div class="    col-12 col-sm-3 form-group">
        <select
          class="required select-group"
          :disabled="shouldReadOnly"
          placeholder=" "
          name="businessProfile-state"
          id="businessProfile-state"
          v-model="businessInputs.stateID"
          required
        >
          <option
            v-for="StatesOption in prePopulatedData?.stateList"
            :key="StatesOption.stateID"
            :value="StatesOption.stateID"
          >
            {{ StatesOption.stateName }}
          </option>
        </select>
        <label for="businessProfile-state" class="form__label required">
          {{ labels.BusinessLabel.LabelState }}
        </label>
        <Error v-if="v$.businessInputs.stateID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.stateID'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="   col-12 col-sm-3  form-group">
        <input
          type="text"
          max="10"
          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
          required
          placeholder="XX-XXXXXXX"
          name="businessProfile-ein"
          id="businessProfile-ein"
          v-model="businessInputs.ein"
          @keyup="formateEIN"
          @blur="formateEIN"
          disabled
        />
        <label for="businessProfile-ein" class="form__label required">
          {{ labels.BusinessLabel.LabelEIN }}
        </label>
        <Error v-if="v$.businessInputs.ein.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.ein'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="    col-12 col-sm-3 form-group">
        <input
          required
          type="email"
          placeholder=" "
          :disabled="shouldReadOnly"
          name="businessProfile-emailAddress"
          id="businessProfile-emailAddress"
          v-model="businessInputs.emailAddress"
        />
        <label for="businessProfile-emailAddress" class="form__label required">
          {{ labels.BusinessLabel.LabelBusinessEmail }}
        </label>
        <Error v-if="v$.businessInputs.emailAddress.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.emailAddress'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="    col-12 col-sm-3 form-group">
        <input
          required
          placeholder="XXX-XXX-XXXX"
          max="12"
          oninput="this.value=this.value.slice(0,this.max).replace(/[^\d-]/g, '')"
          :disabled="shouldReadOnly"
          name="businessProfile-phoneNumber"
          id="businessProfile-phoneNumber"
          v-model="businessInputs.phoneNumber"
          type="text"
          @keyup="formatePhoneNumberToUSA"
          @blur="formatePhoneNumberToUSA"
        />
        <label for="businessProfile-phoneNumber" class="form__label required">
          {{ labels.BusinessLabel.LabelBusinessPhoneNo }}
        </label>
        <Error v-if="v$.businessInputs.phoneNumber.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.phoneNumber'">
              <!-- {{ error.$message }} -->
              Enter Valid Phone Number
            </small>
          </p>
        </Error>
        
      </div>
      <div class="   col-12 col-sm-3  form-group">
        <input
          type="text"
          placeholder=" "
          name="businessProfile-duns"
          id="businessProfile-duns"
          :disabled="shouldReadOnly"
          @keyup="formateDuns($event)"
          @blur="formateDuns($event)"
          v-model="businessInputs.duns"
        />
        <label for="businessProfile-duns" class="form__label required">
          {{ labels.BusinessLabel.LabelDUNS }}
        </label>
      </div>
      <div class="   col-12 col-sm-3  form-group">
        <input
        required
          type="text"
          placeholder=" "
          name="businessProfile-naicS_ID"
          id="businessProfile-naicS_ID"
          :disabled="shouldReadOnly"
          @keyup="naicsCode($event)"
          @blur="naicsCode($event)"
          v-model="businessInputs.naicsCode"
    
        />
        <label for="businessProfile-naicS_ID" class="form__label required">
          {{ labels.BusinessLabel.LabelNAICSCode }}
        </label>
        <Error v-if="v$.businessInputs.naicsCode.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="error.$propertyPath === 'businessInputs.naicsCode'"
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="    col-12 col-sm-3  form-group">
        <select
          class="required select-group"
          name="businessProfile-businessTypeID"
          id="businessProfile-businessTypeID"
          :disabled="shouldReadOnly"
          placeholder=""
          v-model="businessInputs.businessTypeID"
          required
        >
          <option
            v-for="businessType in prePopulatedData?.businessTypeList"
            :key="businessType.businessTypeID"
            :value="businessType.businessTypeID"
          >
            {{ businessType.type }}
          </option>
        </select>
        <label
          for="businessProfile-businessTypeID"
          class="form__label required"
        >
          {{ labels.BusinessLabel.LabelBusinessType }}
        </label>
        <Error v-if="v$.businessInputs.businessTypeID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="error.$propertyPath === 'businessInputs.businessTypeID'"
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="    col-12 col-sm-3  form-group">
        <select
          class="required select-group"
          required
          name="businessProfile-industryTypeID"
          id="businessProfile-industryTypeID"
          placeholder=""
          :disabled="shouldReadOnly"
          v-model="businessInputs.industryTypeID"
        >
          <option
            v-for="industryOption in prePopulatedData?.industryTypeList"
            :key="industryOption.industryTypeID"
            :value="industryOption.industryTypeID"
          >
            {{ industryOption.type }}
          </option>
        </select>
        <label for="businessProfile-industryTypeID" class="form__label required">
          {{ labels.BusinessLabel.LabelIndustry }}
        </label>
        <Error v-if="v$.businessInputs.industryTypeID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="error.$propertyPath === 'businessInputs.industryTypeID'"
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="   col-12 col-sm-3  form-group">
        <input
          type="url"
          placeholder=" "
          name="businessProfile-Url"
          :disabled="shouldReadOnly"
          id="businessProfile-Url"
          v-model="businessInputs.url"
        />
        <label for="businessProfile-Url" class="form__label">
          {{ labels.BusinessLabel.LabelBusinessUrl }}
        </label>
      </div>
      <div class="col-12 col-sm-3  form-group">
        <flat-pickr 
          :config="Dateconfig"
          required
          placeholder=" "
          name="businessProfile-startDate"
          id="businessProfile-startDate"
          :disabled="shouldReadOnly"
          v-model="businessInputs.startDate"
          type="text"
          class="datepicker" >
          </flat-pickr>
          <label for="businessProfile-startDate" class="form__label required">
          {{ labels.BusinessLabel.LabelBusinessStartDate }}
        </label>
        <Error v-if="v$.businessInputs.startDate.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.startDate'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
    </div>
    <div class="row justify-content-between">
      
      <div class="   col-12 col-sm-3 form-group">
        <input
          required
          type="text"
          placeholder=" "
          :disabled="shouldReadOnly"
          @keyup="numberOfYearsInBusiness($event, 3)"
          @blur="numberOfYearsInBusiness($event, 3)"
          name="businessProfile-numberOfYearsInBusiness"
          id="businessProfile-numberOfYearsInBusiness"
          v-model="businessInputs.numberOfYearsInBusiness"
        />
        <label
          for="businessProfile-numberOfYearsInBusiness"
          class="form__label required"
        >
          {{ labels.BusinessLabel.LabelNoOfYearsBusiness }}
        </label>
        <Error v-if="v$.businessInputs.numberOfYearsInBusiness.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="
                error.$propertyPath === 'businessInputs.numberOfYearsInBusiness'
              "
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="   col-12 col-sm-3 form-group">
        <input
          required
          type="text"
          placeholder=" "
          :disabled="shouldReadOnly"
          name="businessProfile-employeeStrength"
          id="businessProfile-employeeStrength"
          @keyup="employeeStrength($event)"
          @blur="employeeStrength($event)"
          v-model="businessInputs.employeeStrength"
        />
        <label
          for="businessProfile-employeeStrength"
          class="form__label required"
        >
          {{ labels.BusinessLabel.LabelNoOfEmployess }}
        </label>
        <Error v-if="v$.businessInputs.employeeStrength.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="error.$propertyPath === 'businessInputs.employeeStrength'"
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="   col-12 col-sm-3 form-group">
        <input
          required
          type="number"
          placeholder=" "
          name="businessProfile-bankAccountNumber"
          :disabled="shouldReadOnly"
          id="businessProfile-bankAccountNumber"
          v-model="businessInputs.bankAccountNumber"
        />
        <label
          for="businessProfile-bankAccountNumber"
          class="form__label required"
        >
          {{ labels.BusinessLabel.LabelAccountNo }}
        </label>
        <Error v-if="v$.businessInputs.bankAccountNumber.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="error.$propertyPath === 'businessInputs.bankAccountNumber'"
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="   col-12 col-sm-3 form-group">
        <input
          required
          type="number"
          
          placeholder=" "
          name="businessProfile-bankRoutingNumber"
          :disabled="shouldReadOnly"
          id="businessProfile-bankRoutingNumber"
          v-model="businessInputs.bankRoutingNumber"
        />
        <label
          for="businessProfile-bankRoutingNumber"
          class="form__label required"
        >
          {{ labels.BusinessLabel.LabelRoutingNo }}
        </label>
        <Error v-if="v$.businessInputs.bankRoutingNumber.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small
              v-if="error.$propertyPath === 'businessInputs.bankRoutingNumber'"
            >
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="  col-12 col-sm-3 form-group">
        <select
          class="required select-group"
          required
          name="businessProfile-siC_ID"
          :disabled="shouldReadOnly"
          id="businessProfile-siC_ID"
          placeholder=""
          v-model="businessInputs.siC_ID"
        >
          <option
            v-for="(SICCode, index) in sicCodes"
            :key="index + 1"
            :value="index + 1"
          >
            {{ SICCode }}
          </option>
        </select>
        <label for="businessProfile-siC_ID" class="form__label required">
          {{ labels.BusinessLabel.LabelSICCode }}
        </label>
        <Error v-if="v$.businessInputs.siC_ID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'businessInputs.siC_ID'">
              {{ error.$message }}
            </small>
          </p>
        </Error>
        
      </div>
      <div class="col-sm-9  col-12 form-group">
        <input
          type="text"
          placeholder=" "
          name="businessProfile-Note"
          id="businessProfile-Note"
          :disabled="shouldReadOnly"
          v-model="businessInputs.comment"
        />
        <label for="businessProfile-Note" class="form__label required">
          {{ labels.BusinessLabel.LabelNote }}
        </label>
      </div>
      
    </div>

    <div class="owner">
      <Owner
        :prePopulatedData="prePopulatedData"
        :shouldFieldReadOnly="shouldReadOnly"
        ref="Owner"
      />
    </div>
    <hr />
    <div class="row" v-if="shouldReadOnly===false">
      <span class="add-owner-btn">
        <BtnTertiary buttonName="ADD OWNER" @click="addOwners"  />
      </span>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BtnTertiary from "@/components/BtnTertiary.vue";
import BusinessLabel from "@/textStore.js";
import Owner from "@/components/BorrowerForms/Owner.vue";
import Error from "@/components/Error.vue";
import useVuelidate from "@vuelidate/core";
import { required, numeric, email, minLength } from "@vuelidate/validators";
export default {
  props: {
    shouldFieldReadOnly: {
      type: Boolean,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
      BusinessLabel,
    };
  },
  validations() {
    return {
      businessInputs: {
        businessName: { required, $lazy: true, $autoDirty: true },
        dba: { required },
        address: { required },
        emailAddress: { required, email },
        phoneNumber: { required, minLength: minLength(12) },
        zip: { required, minLength: minLength(5), numeric },
        city: { required },
        stateID: { required },
        ein: { required, minLength: minLength(10) },
        naicsCode:{ required },
        siC_ID: { required },
        businessTypeID: { required },
        industryTypeID: { required },
        startDate: { required },
        numberOfYearsInBusiness: { required, numeric },
        employeeStrength: { required, numeric },
        bankAccountNumber: { required },
        bankRoutingNumber: { required },
      },
    };
  },
  components: {
    BtnTertiary,
    Error,
    Owner,
    flatPickr,
  },
  data() {
    return {
      Dateconfig: {
        dateFormat: "m/d/Y",
        disableMobile: "true"
      },
      phoneNumberSetUp: {
        phoneValue: "",
        formattedPhoneValue: "",
        preventNextIteration: false,
      },
      ein: "EIN",
      einSetUp: {
        einValue: "",
        formattedeinValue: "",
        preventNextIteration: false,
      },
      labels: {},
      naicsCodes: [
        "722511:Full-Service Restaurants",
        "722513:Limited-Service Restaurant",
        "722330:Mobile Food Services",
        "722320:Caterers",
      ],
      sicCodes: [
        'A:44205:Agriculture, Forestry, And Fishing',
        'B:44483:Mining',
        'C:15-17:Construction',
        'D:20-39:Manufacturing',
        'E:40-49:Transportation, Communications, Electric, Gas, And Sanitary Services',
        'F:50-51:Wholesale Trade',
        'G:52-59:Retail Trade',
        'H:60-67:Finance, Insurance, And Real Estate',
        'I:70-89:Services',
        'J:90-99:Public Administration'
      ],
      hideOwner:true
    };
  },
  watch() {},
  computed: {
    businessInputs: {
      get() {
        Object.assign(this.labels, BusinessLabel);
        return this.$store.state.borrower.businessData.inputs;
      },
    },
    prePopulatedData: {
      get() {
        return this.$store.state.borrower.prePopulatedData;
      },
    },
    shouldReadOnly: {
      get() {
        return this.shouldFieldReadOnly;
      },
    },
  },
  created() {
    if(this.$store.getters.getUserRoleID==2 || this.$store.getters.getUserRoleID==7)
    {
        this.statusName = this.$store.state.borrower.applicantData.applicationStatus;
        if(this.statusName=="Initialized" || this.statusName=="Created" || this.statusName=="Drafted" || this.statusName=="Requested More Information"|| this.statusName==undefined)
          this.hideOwner = false;   
        else
          this.hideOwner = true;
    }
  },
  methods: {
    formateDuns() {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.duns.replace(/[^\d]/g, "");
      value = tempFormattingNumber.replace(/[^\d]/g, "");
      this.businessInputs.duns = value;
      return this.businessInputs.duns;
    },
    naicsCode(event){
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.slice(0,6).replace(/[^0-9]/g, "");

      this.businessInputs.naicsCode = value;
      return event.target.value;
    },
    bankAccountNumber(event) {
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.slice(0, 12).replace(/[^0-9]/g, "");

      this.businessInputs.bankAccountNumber = value;
      return event.target.value;
    },
    bankRoutingNumber(event) {
      let tempFormattingValue, value;
      tempFormattingValue = event.target.value.replace(/[^0-9]/g, "");

      value = tempFormattingValue.slice(0, 12).replace(/[^0-9]/g, "");

      this.businessInputs.bankRoutingNumber = value;
      return event.target.value;
    },
    numberOfYearsInBusiness(event, maxNumber) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.numberOfYearsInBusiness
        .replace(/[^\d,]/g, "")
        .slice(0, maxNumber);

      value = tempFormattingNumber.replace(/[^\d]/g, "");
      this.businessInputs.numberOfYearsInBusiness = value;
      return this.businessInputs.numberOfYearsInBusiness;
    },
    employeeStrength(event) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.employeeStrength.replace(
        /[^0-9]/g,
        ""
      );
      value = tempFormattingNumber.replace(/[^0-9]/g, "");
      this.businessInputs.employeeStrength = value;
      return event.target.value;
    },
    formateZip(event, maxNumber) {
      let tempFormattingNumber, value;
      tempFormattingNumber = this.businessInputs.zip.replace(/[^\d,]/g, "");

      value = tempFormattingNumber.slice(0, maxNumber).replace(/[^\d]/g, "");
      this.businessInputs.zip = value;
      return this.businessInputs.zip;
    },
    formateEIN() {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.einSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.einSetUp.preventNextIteration = false;
        return;
      }
      let einNumber = this.businessInputs.ein
        .slice(0, 10)
        .replace(/-/g, "")
        .replace(/[^\d,]/g, "");
      // Format display value based on calculated
      this.businessInputs.ein = einNumber.replace(
        /(\d{1,2})(\d{1,7})/g,
        "$1-$2"
      );
    },
    async validationsStore() {
      await this.$refs.Owner.storeOwner();
      
      let applicantData = this.$store.state.borrower.applicantData
      let applicationStatusID=applicantData.applicationStatusID;
      let getUserRoleID=this.$store.getters.getUserRoleID
      if (typeof applicationStatusID == "undefined") {
          applicationStatusID = 1;
        }
        if (
          (
            (applicationStatusID === 1 || applicationStatusID === 2 ||applicationStatusID === 3||applicationStatusID ===5) && ((getUserRoleID==2 || getUserRoleID==7))
            )||
          ((applicationStatusID ===25 || applicationStatusID ===26 ||applicationStatusID ===16 || applicationStatusID ===21) && (getUserRoleID==2 ||getUserRoleID==4 ||getUserRoleID==6))
        ){
          const isFormCorrect = await this.v$.$validate();
          if (isFormCorrect == true) {

        return await this.$store
          .dispatch("UpdatebusinessData", this.businessInputs, { root: true })
          .then(() => {
            return Promise.resolve(true);
          })
          .catch((err) => {
            Promise.reject(err);
            return Promise.resolve(false);
          });
      } else {
        return Promise.resolve(false);
      }
        }
        else{
          return Promise.resolve(true);
        }

      
    },
    addOwners() {
      this.$refs.Owner.addOwners();
    },
    formatePhoneNumberToUSA(event) {
      if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.phoneNumberSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.phoneNumberSetUp.preventNextIteration = false;
        return;
      }
      let phoneNumber = this.businessInputs.phoneNumber
        .replace(/[^\d,]/g, "")
        .slice(0, 10);

      // Format display value based on calculated currencyValue
      this.businessInputs.phoneNumber = phoneNumber.replace(
        /(\d{1,3})(\d{1,3})(\d{1,4})/g,
        "$1-$2-$3"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.Business-profile {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  .row {
    // padding-right: 5%;
    margin: 0;
  }
  .owners-info {
    padding: 1rem 0rem;
    position: relative;
    .delete-owner-btn {
      position: absolute;
      right: 3%;
      button {
        height: 2.5rem;
        width: 2.5rem;
        border-radius: 50%;
        padding: 0.01rem;
        border: none;
        img {
          height: 100%;
        }
      }
    }
  }
  .add-owner-btn {
    // width: 10.4rem;
    button {
      font-size: 1.6rem;
      margin: 0rem;
      padding-left: 2.5rem;
      position: relative;
      &::before {
        content: "+";
        position: absolute;
        left: 0rem;
      }
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
  }

  .form-group {
    padding: 0;
    margin-top: 0.5rem;
    position: relative;
    @include input-style;
  }
}
</style>
