<template>
  <div class="fundform">
    <div class="form-section">
      <div class="form-section__header">
        <nav>
          <ul class="nav-btn-steps nav nav-tabs col-sm ">
            <div class="form-section__step-tabs__arrow-buttons" v-if="canhide==false" >
            <span class="arrow-button" @click="showOnlyActiveTab()" v-if="isShowOnlyActiveTab==false">
              <button class="arrow-button__btn arrow--button__up">
                <img src="@/assets/imgs/Arrow.svg" alt="up arrow" />
              </button>
            </span>
            <span class="arrow-button" @click="showAllTab()" v-if="isShowOnlyActiveTab==true">
              <button class="arrow-button__btn arrow--button__down">
                <img src="@/assets/imgs/Arrow.svg" alt="down arrow" />
              </button>
              
            </span>
          </div>
          <li class="nav-item" v-if="showActiveTab==true">
            <span
            v-if="activeItem=='basicDetails'"
                @click.prevent="setActive('basicDetails')"
                class="nav-btn"
                :class="activeItem == 'basicDetails' ? 'active' : ''"
              >
                <a href="#basicDetails">
                  <p>{{ nav.stepName1 }}</p>
                </a>
              </span>
              <span
              v-if="activeItem=='defineDocuments'"
                @click.prevent="setActive('defineDocuments')"
                class="nav-btn"
                :class="activeItem == 'defineDocuments' ? 'active' : ''"
              >
                <a href="#defineDocuments">
                  <p>{{ nav.stepName2 }}</p>
                </a>
              </span>
              <span
               v-if="activeItem=='defineQuestions'"
                @click.prevent="setActive('defineQuestions')"
                class="nav-btn"
                :class="activeItem == 'defineQuestions' ? 'active' : ''"
              >
                <a href="#defineQuestions">
                  <p>{{ nav.stepName3 }}</p>
                </a>
              </span>
              <span
               v-if="activeItem=='addUserAgreement'"
                @click.prevent="setActive('addUserAgreement')"
                class="nav-btn"
                :class="activeItem == 'addUserAgreement' ? 'active' : ''"
              >
                <a href="#addUserAgreement">
                  <p>{{ nav.stepName4 }}</p>
                </a>
              </span>
              <span
              v-if="activeItem=='AddHelpfulGuideText'"
                @click.prevent="setActive('AddHelpfulGuideText')"
                class="nav-btn"
                :class="activeItem == 'AddHelpfulGuideText' ? 'active' : ''"
              >
                <a href="#AddHelpfulGuideText">
                  <p>{{ nav.stepName5 }}</p>
                </a>
              </span>
              <span
               v-if="activeItem=='invitation'"
                @click.prevent="setActive('invitation')"
                class="nav-btn"
                :class="activeItem == 'invitation' ? 'active' : ''"
              >
                <a href="#invitation" >
                  <p>{{ nav.stepName6 }}</p>
                </a>
              </span>
          </li>
            <li class="nav-item" v-if="showActiveTab==false">
              <span
                @click.prevent="setActive('basicDetails')"
                class="nav-btn"
                :class="activeItem == 'basicDetails' ? 'active' : ''"
              >
                <a href="#basicDetails">
                  <p>{{ nav.stepName1 }}</p>
                </a>
              </span>
            </li>
            <li class="nav-item" v-if="showActiveTab==false">
              <span
              v-if="canhide==false"
                @click.prevent="setActive('defineDocuments')"
                class="nav-btn"
                :class="activeItem == 'defineDocuments' ? 'active' : ''"
              >
                <a href="#defineDocuments">
                  <p>{{ nav.stepName2 }}</p>
                </a>
              </span>
            </li>
            <li class="nav-item" v-if="showActiveTab==false">
              <span
              v-if="canhide==false"
                @click.prevent="setActive('defineQuestions')"
                class="nav-btn"
                :class="activeItem == 'defineQuestions' ? 'active' : ''"
              >
                <a href="#defineQuestions">
                  <p>{{ nav.stepName3 }}</p>
                </a>
              </span>
            </li>
            <li class="nav-item" v-if="showActiveTab==false">
              <span
              v-if="canhide==false"
                @click.prevent="setActive('addUserAgreement')"
                class="nav-btn"
                :class="activeItem == 'addUserAgreement' ? 'active' : ''"
              >
                <a href="#addUserAgreement">
                  <p>{{ nav.stepName4 }}</p>
                </a>
              </span>
            </li>
            <li class="nav-item" v-if="showActiveTab==false">
              <span
              v-if="canhide==false"
                @click.prevent="setActive('AddHelpfulGuideText')"
                class="nav-btn"
                :class="activeItem == 'AddHelpfulGuideText' ? 'active' : ''"
              >
                <a href="#AddHelpfulGuideText">
                  <p>{{ nav.stepName5 }}</p>
                </a>
              </span>
            </li>
            <li class="nav-item" v-if="showActiveTab==false">
              <span
              v-if="canhide==false"
                @click.prevent="setActive('invitation')"
                class="nav-btn"
                :class="activeItem == 'invitation' ? 'active' : ''"
              >
                <a href="#invitation" >
                  <p>{{ nav.stepName6 }}</p>
                </a>
              </span>
            </li>
          </ul>
        </nav>
      </div>
      <div class="row form-section__multi-form m-0">
        <div class="col-12 form-section__multi-form__forms p-0">
          <div class="forms-container tab-content" id="myTabContent">
            <!-- funding entitiy  -->
            <div
              id="basicDetails"
              class="tab-pane fade"
              :class="{ 'active show': isActive('basicDetails') }"
            >
              <!-- basicDetails -->
              <FundSourceForm
                class="fundSourceForm"
                ref="basicDetails"
                :stateList="stateList"
                :fundingTypeList="fundingTypeList"
                :businessTypeList="businessTypeList"
                @showFundAllocationSummary="getFundTransactions"
                @showutilizedAmountSummary="getFundUtilizations"
                @onSave="getFundSources"
              />
            </div>
            <div
              id="defineDocuments"
              class="tab-pane fade"
              :class="{ 'active show': isActive('defineDocuments') }"
            >           
              <Documents ref="defineDocuments" />
            </div>
            <div
              id="defineQuestions"
              class="tab-pane fade"
              :class="{ 'active show': isActive('defineQuestions') }"
            >
              <Questions ref="defineQuestions" />
            </div>
            <div
              id="addUserAgreement"
              class="tab-pane fade"
              :class="{ 'active show': isActive('addUserAgreement') }">
              <Agreement ref="addUserAgreement" />
            </div>
            <!-- invitation -->
            <div
              id="AddHelpfulGuideText"
              class="tab-pane fade"
              :class="{ 'active show': isActive('AddHelpfulGuideText') }"
            >
             

              <HelpfulGuide ref="AddHelpfulGuideText" />
            </div>
            <div
              id="invitation"
              class="tab-pane fade"
              :class="{ 'active show': isActive('invitation') }"
            >
              <Invitation ref="Invitation" />
            </div>
          </div>
          <div class="horizontal">
            <div class="forms-btns row m-0 p-0 py-2 d-flex justify-content-between ">
              <div class="forms-btns__left ">
                <span class="btn--tertiary" v-show="!isActive('basicDetails')">
                  <BtnTertiary buttonName="Previous" @click="previous" />
                </span>
              </div>

              <div class="forms-btns__right ">
                <span class="btn--primary--body flex-wrap d-flex justify-content-center">
                  <BtnPrimary buttonName="Save" @click="save()" />
                </span>
              </div>

              <div class="forms-btns__right  ">
                <span
                  class="btn--tertiary d-flex justify-content-end "
                  v-show="!isActive('invitation')"
                >
                  <BtnTertiary
                  v-show="!isActive('invitation')"
                  v-if="canhide==false" buttonName="Next" @click="next()" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingMask v-if="isLoading === true" />
    </div>
    <PopUp v-if="popup === true" @closePopUp="popup = false" class="popup">
      <div class="my-5"></div>
      <div
        class="fundAllocationSummary popup-body"
        v-if="fundAllocationSummary == true"
      >
        <FundAllocationSummary @showFundForm="showFundForm" />
      </div>
      <div
        class="utilizedAmountSummary popup-body"
        v-if="utilizedAmountSummary == true"
      >
        <UtilizedAmountSummary @showFundForm="showFundForm" />
      </div>
    </PopUp>
  </div>
</template>
<script>
import Documents from "@/components/FundSourceTracking/program/Documents";
// import BasicComponent from "@/components/BasicComponent.vue";
// import BreadCrumb from "@/components/BreadCrumb";
import BtnTertiary from "@/components/BtnTertiary.vue";
import BtnPrimary from "@/components/BtnPrimary.vue";
// import BtnSecondary from "@/components/BtnSecondary.vue";
import PopUp from "@/components/PopUp";
import FundSourceForm from "@/components/FundSourceTracking/FundSource/FundSourceForm";
import FundAllocationSummary from "@/components/FundSourceTracking/FundSource/FundAllocationSummary";
import UtilizedAmountSummary from "@/components/FundSourceTracking/FundSource/UtilizedAmount";
import Questions from "@/components/FundSourceTracking/program/Questions.vue";
import Agreement from "@/components/FundSourceTracking/program/Agreement.vue";
import Invitation from "@/components/FundSourceTracking/program/Invitation.vue";
import HelpfulGuide from "@/components/FundSourceTracking/program/HelpfulGuide.vue";
export default {
  components: {
    // BasicComponent,
    // BreadCrumb,
    // BtnSecondary,
    Invitation,
    HelpfulGuide,
    Agreement,
    Questions,
    BtnPrimary,
    Documents,
    BtnTertiary,
    UtilizedAmountSummary,
    PopUp,
    FundSourceForm,
    FundAllocationSummary,
  },
  props: {
    entityID: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isShowOnlyActiveTab:false,
      showActiveTab:false,
      showNavTab: true,
      popup: false,
      fundSourceForm: false,
      utilizedAmountSummary: false,
      fundAllocationSummary: false,
      tabItems: [
        "basicDetails",
        "defineDocuments",
        "defineQuestions",
        "addUserAgreement",
        "AddHelpfulGuideText",
        "invitation",
      ],
      activeItem: "basicDetails",
      nav: {
        stepName1: "Basic Details",
        stepName2: "Documents",
        stepName3: "Questions",
        stepName4: "User Agreement",
        stepName5: "Helpful Guide",
        stepName6:'Invitation',
      },
      texts: {
        heading: " ",
        subText: " ",
      },
      crumbs: [
        "Funding Entities",
        "Funding Entity",
        "Program",
        "Basic Details",
      ],
      stateList: [],
      fundingTypeList: [],
      businessTypeList: [],
      entity_ID: this.entityID,
      // canhide:true
    };
  },
  created() {
    this.$store
      .dispatch("getmasterData")
      .then((res) => {
        this.stateList = res.data.stateList;
        this.fundingTypeList = res.data.fundingTypeList;
        this.businessTypeList = res.data.businessTypeList;
        var fundEntity = this.$store.state.fundSource.fundingSource;
        if (
          fundEntity.fundingSourceID === undefined ||
          fundEntity.fundingSourceID === 0
        ) {
          this.canhide = true;
        } else {
          this.canhide = false;
        }
      })
      .catch((err) => {
        Promise.reject(err);
      });
  },
  methods: {
    showOnlyActiveTab() {
      this.showActiveTab=true;
      this.isShowOnlyActiveTab=true;
    },
    showAllTab() {
      this.showActiveTab=false;
      this.isShowOnlyActiveTab=false;
    },
    async getPrograms() {
      await this.$store
        .dispatch("createProgramInvitation")
        .then((res) => {
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
        });
    },
  
    selected(crumb) {
      if (crumb == "Funding Entities") {
        this.$router.push("/funding");
      }
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    previous() {
      let activeTab = this.activeItem;
      let tabItems = this.tabItems;
      if (activeTab === tabItems[1]) {
        this.setActive(tabItems[0]);
      }
      if (activeTab === tabItems[2]) {
        this.setActive(tabItems[1]);
      }
      if (activeTab === tabItems[3]) {
        this.setActive(tabItems[2]);
      }
      if (activeTab === tabItems[4]) {
        this.setActive(tabItems[3]);
      }
      if (activeTab === tabItems[5]) {
        this.setActive(tabItems[4]);
      }
    },
    next() {
      let activeTab = this.activeItem;
      let tabItems = this.tabItems;
      if (activeTab === tabItems[0]) {
        this.setActive(tabItems[1]);
      }
      if (activeTab === tabItems[1]) {
        this.setActive(tabItems[2]);
      }
      if (activeTab === tabItems[2]) {
        this.setActive(tabItems[3]);
      }
      if (activeTab === tabItems[3]) {
        this.setActive(tabItems[4]);
      }
      if (activeTab === tabItems[4]) {
        this.setActive(tabItems[5]);
      }
      if (activeTab === tabItems[5]) {
        this.setActive(tabItems[6]);
      }
    },
    getPageHeader() {
      let fundingEntity = this.$store.state.fundSource.fundingEntity
        .fundingEntityName;
      if (
        this.$store.state.fundSource.fundingEntity.fundingEntityID === 0 ||
        this.$store.state.fundSource.fundingEntity.fundingEntityID === undefined
      )
        this.texts.heading = "Add New Program";
      else this.texts.heading = fundingEntity;
      return this.texts.heading;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;

      if (this.activeItem == "basicDetails") {
        this.crumbs[3] = "Basic Details";
      }
      if (this.activeItem == "defineDocuments") {
        this.crumbs[3] = "Define Documents";
      }
      if (this.activeItem == "defineQuestions") {
        this.crumbs[3] = "Define Questions";
      }
      if (this.activeItem == "addUserAgreement") {
        this.crumbs[3] = "User Agreement";
      }
      if (this.activeItem == "AddHelpfulGuideText") {
        this.crumbs[3] = "Helpful Guide Text";
      }
      if (this.activeItem == "invitation") {
        this.crumbs[3] = "Invitation";
        if(this.$store.state.fundSource.fundingSource.fundingSourceID > 0){
        this.$refs.Invitation.GetEmailInvitation();
        }
      }
      
    },
    addFundSource() {
      this.$store.state.fundSource.fundingSource = {};
      (this.utilizedAmountSummary = false),
        (this.fundAllocationSummary = false),
        (this.fundSourceForm = true);
      this.popup = true;
    },
    editFundSource() {
      (this.utilizedAmountSummary = false),
        (this.fundAllocationSummary = false),
        // (this.fundSourceForm = true);
        (this.popup = false);
    },
    showFundAllocationSummary() {
      this.fundSourceForm = false;
      (this.utilizedAmountSummary = false),
        (this.fundAllocationSummary = true),
        (this.popup = true);
    },
    showutilizedAmountSummary() {
      this.fundSourceForm = false;
      (this.fundAllocationSummary = false),
        (this.utilizedAmountSummary = true),
        (this.popup = true);
    },
    showFundForm() {
      this.editFundSource();
    },
    getFundTransactions(fundingSourceID) {
      this.$store.dispatch("updateLoaderFlag", true);
      if (fundingSourceID > 0) {
        this.$store
          .dispatch("getFundTransactionList", fundingSourceID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.showFundAllocationSummary();
            Promise.resolve(res);
          })
          .catch(() => {
            this.LoginError = true;
            this.$store.dispatch("updateLoaderFlag", false);
          });
      } else {
        this.$store.dispatch("updateLoaderFlag", false);
      }
    },
    getFundUtilizations(fundingSourceID) {
      this.$store.dispatch("updateLoaderFlag", true);
      if (fundingSourceID > 0) {
        this.$store
          .dispatch("getFundUtilizationList", fundingSourceID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag", false);
            this.showutilizedAmountSummary();
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.resolve(res);
          })
          .catch(() => {
            this.LoginError = true;
            this.$store.dispatch("updateLoaderFlag", false);
          });
      } else {
        this.$store.dispatch("updateLoaderFlag", false);
      }
    },
    getFundSources(fundingEntityID) {
      if (fundingEntityID > 0) {
        this.$store.dispatch("updateLoaderFlag", true);
        this.$store
          .dispatch("fetchFundingEntitiy", fundingEntityID)
          .then((res) => {
            (this.utilizedAmountSummary = false),
              (this.fundAllocationSummary = false),
              (this.fundSourceForm = true);
            this.popup = false;
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.resolve(res);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag", false);
            Promise.reject(err);
          });
      }
    },
    async save() {
      if (this.activeItem === this.tabItems[0]) {
        await this.$refs.basicDetails.saveFundSource();         
      }
      if (this.activeItem === this.tabItems[1]) {
        await this.$refs.defineDocuments.saveDocument();
      }
      if (this.activeItem === this.tabItems[2]) {
        await this.$refs.defineQuestions.saveQuestion();
      }
      if (this.activeItem === this.tabItems[3]) {
        await this.$refs.addUserAgreement.saveAgreement();
      }
      if (this.activeItem === this.tabItems[4]) {
        await this.$refs.AddHelpfulGuideText.savehelpFulGuide();
      }
      if (this.activeItem === this.tabItems[5]) {
        await this.$refs.Invitation.saveInvitationEmail();
      }
      /*if(this.$store.state.fundSource.fundingSource.fundingSourceID > 0){
        this.$refs.Invitation.GetEmailInvitation();
        }*/
    },
    /*selectinvitation()
    {
        if(this.$store.state.fundingSource.fundingSourceID===0 || this.$store.state.fundingSource.fundingSourceID === undefined){
        {
          this.$store.state.fundSource.fundingSource.fundingSourceID = 
          this.$refs.Invitation.GetEmailInvitation();
        }
        
    }*/
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.state.showLoader;
      },
    },
    canhide: {
      get() {
        if(this.$store.state.fundSource.fundingSource.fundingSourceID===0 || this.$store.state.fundSource.fundingSource.fundingSourceID === undefined){
          return true;
        } else {
          return false;
        }
      },
    },
    programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      },
    },
  },
  mounted() {
    this.getPrograms();
  },
};
</script>
<style lang="scss" scoped>
.fundform {
  .form-section {
    &__header {
      nav {
        ul,
        li {
          list-style: none;
          display: flex;
          flex-wrap: wrap;
          margin: 0;
          a {
            text-decoration: none;
            min-height: 5rem; //54px;
            min-width: 5rem;
            margin-right: 1rem;
            background-color: transparent;
            outline: none;
            border: 1px solid transparent; //$primary-color-red;
            border-radius: 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;

            p {
              font-size: 1.6rem;
              color: $primary-color-grey;
              margin: 0 0.5rem;
              padding: 0rem 4rem;
            }
            &:hover {
              // border: 1px solid $primary-color-red;
              p {
                font-weight: bold;
                color: $primary-color-black;
              }
            }
          }
          .active {
            a {
              border: 1px solid $primary-color-red;
              p {
                font-weight: bold;
                color: $primary-color-black;
              }
            }
          }
        }
      }
    }
    &__multi-form {
      .forms-container {
        padding-top: 1rem;
        height: calc(100vh - 35rem);
        overflow-y: auto;
         @media screen and(max-width:580px) {
            min-height: 70vh;
          }
      }
      .forms-btns {
          padding-right: 5rem;
          margin: 2.5rem 0rem;
          button {
            font-size: 1.4rem;
            font-weight: bold;
            margin: 0rem;
            margin-top: 1.5rem;
            height: 4rem;
          }
          .btn--secondary {
            width: 16.5rem;
          }
          .btn--primary--body {
            width: 100%;
            .btn--primary {
              width: auto;
            }
          }
          &__left {
            padding: 0rem;
            .btn--tertiary {
              button {
                position: relative;
                &:before {
                  position: absolute;
                  left: 0;
                  content: "<";
                }
              }
            }
          }
          &__right {
            padding: 0rem;
            .btn--tertiary {
              button {
                position: relative;
                &:before {
                  position: absolute;
                  right: 0;
                  content: ">";
                }
              }
            }
          }
          
          @media screen and (max-width: 1600px) {
            margin: 1rem 0rem;
          }
        }
    }
    &__step-tabs__arrow-buttons {
      display: none;
      @media screen and(max-width:576px) {
        display: flex;
        position: absolute;
        right: 2.5%;
        .arrow-button {
          &__btn {
            height: 2.5rem;
            width: 2.5rem;
            border: none;
            outline: none;
            background: transparent;
            img {
              height: 100%;
            }
          }
          .arrow--button__down {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .fillter {
    display: flex;
    label,
    input,
    select,
    option {
      font-size: 1.6rem;
      color: $primary-color-black;
      margin: 0;
      outline: none;
    }
    p {
      margin: 0;
      padding: 0;
    }
    select,
    input {
      border: none;
      outline: none;
      border-bottom: 0.2rem solid $tertiary-color-white;
      height: 3rem;
      width: 100%;
    }
  }
  .popup-body {
    height: 40vh;
    overflow-y: auto;
    margin-top: 4rem;
  }
}
</style>
