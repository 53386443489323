import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Borrower/Landing.vue'
import LoanForm from '@/views/Borrower/LoanForm.vue'
import FundForm from '@/views/Fund/FundForm.vue'
import Program from '@/views/Fund/Program.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import ResetForgotPassword from '@/views/ResetForgotPassword.vue'
import UpdatePasswordResetContact from '@/views/UpdatePasswordResetContact'
import ProgramInvitationForm from '@/views/ProgramInvitations/ProgramInvitationsForm.vue'
// import ProgramInvitationsTrackList from '@/views/ProgramInvitations/ProgramInvitationsTrackList.vue'
import TrackingLists from '@/views/Borrower/TrackingLists.vue'
import FundSourceTracking from '@/views/Fund/TrackingLists.vue'
import Login from '@/views/Login.vue'
import Report from '@/views/Report.vue'
import CiviCRMDataExport from '@/views/CiviCRMDataExport.vue'
import DashBoard from '@/views/DashBoard.vue'
import Success from '@/components/Success.vue'
import ExpiredLink from '@/components/ExpiredLink.vue'
import ActivateAccount from '@/components/ActivateAccount.vue'
import BusinessEntityTrackList from '@/components/BusinessForms/BusinessEntityTrackList.vue'
import ErrorPage from '@/views/ErrorPage.vue'
import ContactList from '@/views/Contact/ContactList.vue'
import BusinessContactList from '@/views/Contact/BusinessContactList.vue'
import Contact from '@/components/ContactForms/Contact.vue'
import BusinessContact from '@/components/ContactForms/BusinessContact.vue'
import affiliateLists from '@/views/Affiliate/affiliateListing.vue'
import affiliate from '@/views/Affiliate/affiliate.vue'
import documentLists from '@/views/Document/documentListing.vue'
import document from '@/views/Document/document.vue'
import questionLists from '@/views/Question/questionListing.vue'
import question from '@/views/Question/question.vue';
import profile from '@/views/Profile.vue';
import FundingReport from '@/views/FundingReport.vue';
const routes = [
  {
    path: '/dashBoard',
    name: 'DashBoard',
    component: DashBoard,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path:'/fundingReport',
    name: 'FundingReport',
    props:true,
    component:FundingReport,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/document/:id',
    name:' document',
    component: document,
    props: true,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/question/:id',
    name:' question',
    component: question,
    props: true,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/errorpage',
    name: 'errorpage',
    component: ErrorPage
  },
  {
    path: '/success',
    name: 'success',
    component: Success
  },
  {
    path: '/form',
    name: 'form',
    component: LoanForm,
    props: true,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: process.env.BASE_URL+'form/:programInvitationID/:loanApplicationID', 
    name: 'form',
    component: LoanForm,
    props: true,
    beforeEnter:(to,from,next)=>{       
      if(localStorage.getItem('token')){
        next()
      }
      else{       
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
        
      }
    }
  },
  {
    path: '/tracks',
    name: 'trackingLists',
    component: TrackingLists,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: ChangePassword,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter:(to,from,next)=>{
      next()
    }
  },
  {
    path: '/funding',
    name: 'fundSourceTracking',
    component: FundSourceTracking,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/program',
    name: 'Program',
    component: Program,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/funding/form/:entityID',
    name: 'fundForm',
    props: true,
    component: FundForm,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter:(to,from,next)=>{
      if(localStorage.getItem('token')){
        next('/')
      }
      else{
        next()
      }
    }
  },
  {
    path: '/ale',
    name: 'expiredLink',
    component: ExpiredLink
  },
  {
    path: '/programinvitation',
    name: 'ProgramInvitationTrackingList',
    component: ()=>import(/* webpackChunkName: "ProgramInvitationForm" */ '@/views/ProgramInvitations/ProgramInvitationsTrackList.vue') ,//ProgramInvitationsTrackList,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/bel',
    name: 'businessEntityList',
    component: BusinessEntityTrackList,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/consent',
    name: 'Consent',
    component: ()=>import('@/views/Borrower/Consent.vue'),
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/programinvitation/form/:entityID/:loanApplicationID',
    name: 'ProgramInvitationForm',
    props: true,
    component:ProgramInvitationForm,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/summarypage/:applicationID/:programInvitationID',
    name: 'SummaryPage',
    props: true,
    component: ()=>import('@/components/BorrowerForms/SummaryPage.vue') ,
    beforeEnter:(to,from,next)=>{      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: process.env.BASE_URL+'/summarypage/:applicationID',
    name: 'SummaryPage',
    props: true,
    component: ()=>import('@/components/BorrowerForms/SummaryPage.vue') ,
    beforeEnter:(to,from,next)=>{           
        next()       
    }
  }, 
  {
    path: '/ActivateAccount/:t/:c',
    name: 'activateAccount',
    props: true,
    component: ActivateAccount, 
    beforeEnter:(to,from,next)=>{   
        next()
    }
  },
  {
    path: '/ForgotPasswordActivation/:t/:c',
    name: 'forgotPasswordActivation',
    props: true,
    component: ResetForgotPassword,
    beforeEnter:(to,from,next)=>{
      next()
    }
  },
  {
    path: '/contacts',
    name: 'ContactList',
    component: ContactList,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/contact/:contactID',
    name: 'Contact',
    props: true,
    component:Contact,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/businessContacts',
    name: 'BusinessContactList',
    component: BusinessContactList,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/businessContact/:contactID',
    name: 'BusinessContact',
    props: true,
    component:BusinessContact,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        if(to.fullPath !=undefined )
        {           
          next('/login?redirect='+to.fullPath)
        }
        else{
          
          next('/login')
        }
      }
    }
  },
  {
    path: '/reports',
    name: 'Report',
    component:Report,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/CiviCRM',
    name: 'CiviCRMDataExport',
    component:CiviCRMDataExport,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/affiliates',
    name:' affiliateLists',
    component: affiliateLists,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/affiliate/form/:affiliateID',
    name:' affiliate',
    props:true,
    component: affiliate,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/documentsLists',
    name:'documentLists',
    component: documentLists,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/document/form/:documentID',
    name:' document',
    props:true,
    component: document,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/questionsLists',
    name:' questionLists',
    component: questionLists,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/question/form/:questionID',
    name:' question',
    props:true,
    component: question,
    beforeEnter:(to,from,next)=>{
      
      if(localStorage.getItem('token')){
        next()
      }
      else{
        next('/login')
      }
    }
  },
  {
    path: '/ResetContactPasswordActivation/:t/:c',
    name: 'updatePasswordResetContact',
    props: true,
    component: UpdatePasswordResetContact,
    beforeEnter:(to,from,next)=>{
      next()
    }
  },
  
  
]
 

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
