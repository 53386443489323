<template>
<div class="TrackingList" v-on:mouseleave="mouseleave">
   <HeaderNav />
   <div class="TrackingList--content">
      <div class="TrackingList--content__Header d-flex">
         <div class="TrackingList--content__Header--breadCrumb align-self-start">
            <BreadCrumb
               class="breadcrumb"
               :crumbs="crumbs"
               @selected="selected"
               />
         </div>
      </div>



     <!-- Start -->
      <div class="mt-3 listing-content " v-on:mouseleave="mouseleave">
         <div class="table-view">
           <div class="table-view__header d-flex justify-content-between  align-items-center m-0 p-0">
               <div class="table-view__header--left">
                  <div class="table-heading">
                     <h3>Funding Report</h3>
                  </div>
                  <div class="sort">
                  </div>
               </div>
               
               <div class="table-view__header--right p-0 col d-flex">
                 <div class=" col-sm-6  form-group search search-by-program my-1 table-view__header--right">
                 <label class="form__label" > Business Name :
                             <select
                           class="search-by-program__filter__select" 
                           placeholder=" "
                           name="businessNameList"
                           id="businessNameList"
                           v-model="inputs.businessId"
                            @change="getBussinessId"
                           >
                           <option :value="all" selected   >
                              <p>All</p>
                           </option>
                           <option
                              v-for="businessName in businessNameList"
                              :key="businessName.id"
                              :value="businessName.id"
                              :title="businessName.businessName"
                              >
                              <p  v-if="businessName.businessName.length > 45">
                              {{ businessName.businessName.substring(0, 45)
                                            .trim() + '...' }}
                              </p>
                              <p  v-else>
                               {{ businessName.businessName }}
                              </p>
                           </option>
                        </select>
                         </label>
                      </div>
                       <div class=" col-md-4 ">
                            <div class="multiselect frontlayer p-4" data-bs-toggle="dropdown">
                              <div
                                  class="multiselect__selectBox "
                                  @click="showCheckboxes()"
                                  >
                                  <label class="multiselect__selectBox__label" for=""
                                    >{{selectedPrograms}}</label>
                                  <span
                                    class="multiselect__selectBox__show-selectedBox "
                                    >
                                  </span>
                                  <button class="arrow-buttons" >
                                  <img
                                    class="arrow-buttons__img"
                                    src="@/assets/imgs/arrow-up.png"
                                    alt="up arrow"
                                    />
                                  <img
                                    class="arrow-buttons__img"
                                    src="@/assets/imgs/arrow-down.png"
                                    alt="down arrow"
                                    />
                                  </button>
                              </div>
                              <!-- {{testData}} -->
                              <div class="checkboxes border border-dark" v-if="isShowCheckboxesOption" >
                                  <div
                                    v-for="program in programList"
                                    :key="program.programId"
                                    class="checkbox-div ml-2"
                                    :class="{
                                    'checkbox-checked': showCheckedData.includes(
                                    program
                                    ),
                                    }"
                                    >
                                    <input
                                        class="checkboxes__input p-0 m-0"
                                        type="checkbox"
                                        :id="program.programId"
                                        :value="program"
                                        @change="onChanceOfProgramData($event, program)"
                                        v-model="showCheckedData"
                                        />
                                        <label class="checkboxes__label p-0 m-0 pl-2" :for="program.programId" :title="program.programName" v-if="program.programName.length > 43">
                                          {{ program.programName
                                            .substring(0, 43)
                                            .trim() + '...'  }}
                                      </label>
                                      <label class="checkboxes__label p-0 m-0 pl-2" :for="program.programId" :title="program.programName" v-else>
                                         {{ program.programName }}
                                    </label>
                                  </div>
                              </div>
                            </div>
                          </div>
                          <span class="mx-sm-4 mx-2">
                             <BtnPrimary :buttonName="`Search`" @click="getConsolidatedFundReportDataList()" />
                          </span>
               <!-- -->
                    
                        
                    </div>

               <!-- -->
            </div>
         </div>
      </div>
   </div>
   <div class="TrackingList--content " v-on:mouseleave="mouseleave">
      <FundingReportListTable 
          :dataList="list" 
          :businessNameList="businessNames"  
          @selectRow="fetchLoanApplicationDetails" />
   </div>
   <LoadingMask v-if="isLoading===true" />
   <Footer />
</div>
</template>
<script>
import BreadCrumb from "@/components/BreadCrumb";
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
import FundingReportListTable from "@/components/FundingReportListTable";
import BtnPrimary from "@/components/BtnPrimary.vue";
export default {
  
  components: {
    HeaderNav,
    Footer,
    BreadCrumb,
    FundingReportListTable,
    BtnPrimary
  },
 created() {
    this.getConsolidatedFundReportDataList();
  },

  data() {
    return {
      crumbs: ["Funding Report"],
      itemsPerTable: 10,
      TableHeight: 30,
      list: [],
      businessNameList:[],
      programList:[],
      inputs:{
        businessId:0,
        programId:0

      },
       isShowCheckboxesOption: false,
        showCheckedData: [],
        selectedPrograms : 'Program Name'
    };
  },
      mounted() {
    this.getPrograms()
    
  },
  computed: {
    userName() {
      return this.$store.getters.getUser;
    },
    isLoading:{
      get(){
        return this.$store.state.showLoader;
      }
    },
      userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
     programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      },
    },
  },
  methods: {
    // to get the program names
    async getPrograms() {
      await this.$store
        .dispatch("createProgramInvitation")
        .then((res) => {
          
            //const data = this.programs;
            console.log(this.programs)
            this.programList = this.programs;
            this.programList.unshift({ programId: 0, programName: "All" });
           // this.inputs.programid=this.programs[0].programId*/
          
          Promise.resolve(res);
        })
        .catch((err) => {
          Promise.reject(err);
          this.LoginError = true;
        });
    },  
    showCheckboxes() {
      this.isShowCheckboxesOption = !this.isShowCheckboxesOption;
    },
      onChanceOfProgramData(e, program) {
      //  if    current checkbox is all remove other from showCheckedData
      if (program.programId == 0 && e.target.checked == true) {
        this.showCheckedData = [{ programId: 0, programName: "All" }];
      }
      //else
      // check isthere programId === 0 , then remove it .
      else {
        let AllValueindex = this.showCheckedData.findIndex(
          (x) => x.programId == "0"
        );
        if (AllValueindex >= 0) {
          this.showCheckedData.splice(AllValueindex, 1);
        }
      }
      if(this.showCheckedData.length > 0)
        {
            this.selectedPrograms = this.showCheckedData.length > 1 ? 'Multiple Programs Selected' : this.showCheckedData[0].programName ;
        }
        else
        {
          this.selectedPrograms = 'Program Name';
        }
    },
     getBussinessId(e)
    {
      var id = e.target.value;

     this.businessId = id;
      //this.$emit('getBussinessId',businessId)
    },
     fetchLoanApplicationDetails(loanApplicationID) {
      if (loanApplicationID > 0)
        this.$router.push("/form/" + 0 + "/" + loanApplicationID);
    },
    async getConsolidatedFundReportDataList() {
      this.$store.dispatch("updateLoaderFlag",true)
       let programInvitations = [];
       let businessEntitydata = [];
       //programInvitations[0] = 0;
       //businessEntitydata[0] = 0;
       console.log(this.inputs.businessId)
       businessEntitydata.push(this.inputs.businessId == undefined ? 0 :this.inputs.businessId );
        programInvitations = this.showCheckedData.map((item) => {
            return item.programId;
        });
        if(programInvitations.length<=0){
            programInvitations= [0]
            }
       /*let param = {
        BusinessEntityID: businessEntitydata,
        programID:   programInvitations,
      };*/

      let filterParameters=[];
      let filterParameterArray = [];
      if (programInvitations.length > 0) {
        
        for (let index = 0; index < programInvitations.length; index++) {
          let value = {
            key: "ProgramID",
            value: programInvitations[index],
          }
          filterParameterArray.push(value);
        }
        filterParameters=filterParameterArray
      }
      
      if(businessEntitydata.length > 0)
      {
           for(let ind=0; ind <businessEntitydata.length; ind++)
          {
              let value = {
                key: "BusinessName",
                value: businessEntitydata[ind],
          }
          filterParameterArray.push(value);
        }
        filterParameters=filterParameterArray
      }
     

      await this.$store
        .dispatch("getConsolidatedFundReportData", filterParameters, { root: true })
        .then((res) => {
          this.list = res.data.data;
          this.businessNameList = res.data.businessEntities;
          //this.programList = res.data.programs;
          console.log(this.list);
          this.$store.dispatch("updateLoaderFlag",false)
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag",false)
          Promise.reject(err);
        });
    },
    mouseleave()
    {
        this.isShowCheckboxesOption = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
@include table-style;

.TrackingList {
  &--content {
    padding: 0rem 2%;
    &__Header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn--primary {
        min-width: 19rem;
        button {
          font-size: 1.6rem;
          margin: 1.5rem;
          padding: 0 1rem;
        }
      }
    }
    &__name {
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
      }
      p {
        font: normal normal normal 1.2rem;
        color: $tertiary-color-black;
      }
      
    }
 
  }
}


        .multiselect {
  padding-right: 2%;
  &__selectBox {
    border-bottom: 1px solid $primary-color-black;
    position: relative;
    &__label {
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
      display: inline-block;
      margin-bottom: 0.5rem;
    }
    &__show-selectedBox {
      width: 85%;
      display: flex;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &__display {
        padding: 0.5rem;
        border: 0.2rem solid $primary-color-red;
;
        align-items: center;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        border-radius: 1rem;
        // background-color: $secondary-color-pink;
        white-space: nowrap;
        font-size: 1.4rem;
      }
    }
    .arrow-buttons{
      position: absolute;
      right: 1%;
          bottom: 25%;
      height: 2rem;
    display: flex;
    flex-direction: column;
    background: transparent;
    border: none;
    outline: none;
    &__img{
      height: 50%;
    }
    }
    
  }
  .CheckedData {
    padding: 0.5rem;
  }
  .show-selectedBox {
    display: flex;
    padding: 1rem;
    overflow-x: hidden;
    &__display {
      padding: 0.5rem;
      border: 1px solid;
      align-items: center;
      margin: 1rem;
      border-radius: 1rem;
      background-color: $secondary-color-pink;
      white-space: nowrap;
    }
    .CheckedData {
    }
  }

  .checkboxes {
    max-height: 15rem;
    overflow-y: auto;
      position: absolute;
    background-color:white;
      width:85%;
    .checkbox-div {
      display: flex;
      align-items: center;
      margin: 0.2rem 0rem;
    
    }
    &__label,
    &__input {
      font-size: 1.6rem;
      @media screen and (max-width: 1600px) {
        font-size: 1.4rem;
      }
    }
    &__label {
      width: auto;
      color: $primary-color-black;
    }
    .checkbox-checked {
      background-color: $tertiary-color-white;
    }
  }
}
  .selectBox select {
  width: 100%;
}

</style>
