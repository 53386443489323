let BusinessLabel={
  LabelBusinessName: "Name of Business Applying for Grant",
  LabelDoingBusiness: "Doing Business as",
  LabelBusinessAddress: "Business Address",
  LabelBusinessEmail: "Email Address",
  LabelBusinessPhoneNo: "Phone Number",
  LabelBusinessUrl: "Business URL",
  LabelBusinessFacebookUrl: "Facebook Url",
  LabelZipCode: "Zip Code",
  LabelCity: "City",
  LabelState: "State",
  LabelEIN: "EIN",
  LabelDUNS: "DUNS",
  LabelAffiliate: "Affiliate",
  LabelSICCode: "SIC Code",
  LabelNAICSCode: "NAICS Code",
  LabelBusinessType: "Business Type",
  LabelIndustry: "Industry",
  LabelBusinessStartDate: "Business Start Date",
  LabelNoOfYearsBusiness: "Number of Years in Business",
  LabelNoOfEmployess: "Number of Employees",
  LabelAvgMonthlyPayroll: "Average Monthly Payroll",
  LabelAccountNo: "Bank Account Number",
  LabelRoutingNo: "Bank Routing Number",
  LabelNote: "Note",
  status:"Status"
}
let fundingApplication={
  LabelYes:'Yes',
  LabelNo:'No',
LabelBusinessName: "Business Name ",
LabelLoanProgram: "Grant Program",
LabelFundingSource: "Funding Source ",
LabelUseOfFunds: "Use of Funds ",
LabelLoanAmount: "Fund Allocated",//"Funding Amount Requested",
LabelPeningDisbursementAmount: "Pening Disbursement Amount",
LabelLoanPeriod: "Grant Period ",
LabelPurposeofFunds: "Purpose of Funds",
LabelIsbankruptcyProtection:
  "Has the business ever filed for bankruptcy protection? ",
LabelIsAppliedBankLoan: "Has the business applied to a bank for a grant?",
LabelIsAnyOWnerBankruptcy:
  "Have any owners of the business ever filed for bankruptcy ? ",

LabelIsAtleast51percentBlackOwned:
  "Is Business at least 51% Black-owned, operated and controlled? ",
LabelIsApplicantAtleast25percentBlack: "Is applicant at least 25% Black?",
LabelIsApplicantUScitizen: "Is applicant a U.S. citizen? ",

LabelFoodservicecompaniesTypes:
  "Is Business one of the following types of food service companies ?",
LabelFoodservicecompaniesOptionFSR: "0 722511 Full-Service Restaurants ",
LabelFoodservicecompaniesOptionLSR:
  "0 722513 Limited-Service Restaurants ",
LabelFoodservicecompaniesOptionMSR: "0 722330 Mobile Food Services",
LabelFoodservicecompaniesOptionC: "0 722320 Caterers ",
}
let documents ={
instrctionHeading:'Please upload following Files:',
  BusinessPlan:"Business Plan",
  MarketingPlan:"Marketing Plan",
  PurposeoftheLoan:"Purpose of the Loan",
  Uploadnewdocument:"Upload document",
  FormW9:"Form W-9 - Request for Taxpayer Identification Number and Certification",
  ProofOfOwnership:"Proof of Ownership",
  ACHVendorForm:"ACH Vendor Form",
  InvoiceLetterhead:"Invoice or Estimate on Letterhead for Product or Service to Be Purchased with [Grant/Loan] Proceeds",
  AddDocument:"Additional Document"
}
let ownerLabel={
LabelOwnerName: "Owner Name",
    LabelOwnership: "Ownership %",
    LabelGender: "Gender",
    LabelRace: "Race",
    LabelEthnicity: "Ethnicity",
    LabelVeteran: "Veteran",
    LabelOwnerEmail: "Email",
    LabelOwnerPhoneNo: "Phone Number",
}
let fundSource ={
entity:{
  entityName:"Name Of Entity",
      entityAddress:"Address",
      zipCode: "ZipCode",
  city: "City",
  state:"State",
  ein:"EIN",
  tin:"TIN",
  documentName:'Upload Funding Entity Logo'
},
source:{
  fundingEntity: "Funding Entity",
  programName: "Program Name",
  fundingType: "Funding Type",
  businessTypesAllowed: "Business Type Allowed",
  state:"State",
  documentName:'Upload Program Logo',
  minimumLoanAmount: "Minimum Grant Amount",
  maximumLoanAmount: "Maximum Grant Amount",
  initialFundedAmount: "Initial Funded Amount",
  totalFundedAmount:"Total Funded Amount",
  utilizedAmount:"Utilized Amount",
  availableLimit:"Available Limit",
  fundDetail: {
    fundedAmount: "Funded Amount",
    dateOfFunding: "Date Of Funding",
    originatingBankAccount: "Originating Bank Account",
    notes: "Add note",
    document: "Upload Document",
  },
}
}
let businessEntityLabel={
businessEntity:{
  businessName:"Business Name",
  affiliate:"Affiliate",
  businessType:"businessType",
  ein:"EIN"
},
users:{
 role:"Role",
      status:"Status",
      title:"Title",
      firstName:"First Name",
      middleName:"Middle Name",
      lastName:"Last Name",
      emailAddress:"Email Address",
      phoneNo:"Phone Number",
      active:"Active",
      inactive:"InActive"
},
programInvitation:{
  programName:"Program Name",
  assignUser:"Assign User (one or Multiple)"
}
}
let FundingDataInBorrower={
FundedAmount:"Funded Amount",
  DateOfFunding:"Date Of Funding",
  destinationBankAccount: "Destination Bank Account",
  bankRoutingNumber: "Bank Routing Number",
  OriginatingBankAccount:"Originating Bank Account",
  AddNotes:"Add Notes",
  document:"Upload Document",
  paymentScheduleSummary: {
    fundAllocatedAmount: 'Fund Allocated',
    fundDisbursedAmount: 'Fund Disbursed',
    fundPendingAmount: 'Pending Disbursement',
    fundRequestedAmount: 'Fund Requested',
    loanApplicationID: 0,
  }
}
let affiliateLabel={
affiliateName: "Affiliate",
  affiliateAddress: "Address",
  firstName: "First Name",
  lastName: "Last Name",
  phoneNumber: "Phone Number",
  emailAddress: "Email",
}
export default {
  BusinessLabel,
  fundingApplication,
  documents,
  ownerLabel,
  fundSource,
  businessEntityLabel,
  FundingDataInBorrower,
  affiliateLabel
}