<template>
  <footer>
    <div class="row footer ">
          <div class="col-md-4 col-sm-10 footer-left">
            <p>© 2021 national urban league</p>
          </div>
          <div class="col-md-4 col-sm-10 footer-right">
            <a href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/NUL_Website_Privacy_Policy(BuildBackBlack).docx">Privacy Policy </a>
            <div> &nbsp; </div>
            <a href="https://uefdocumentsdev.blob.core.windows.net/applicationassets/Terms_of_Use_(BuildBackBlack).docx">Terms of Use </a>
          </div>
        </div>
  </footer>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "./../assets/styles/_variables.scss";
footer {
height: 3rem;
    /* margin-top: 1rem; */
    box-shadow: 0px 3px 12px #0000001f;
    background: #322B2B 0% 0% no-repeat;
    position: fixed;
    bottom: 0;
    width: 100vw;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    -o-object-fit: contain;
    object-fit: contain;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2px;
    padding-bottom: 0px !important;
  .footer{
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 500px) {
        justify-content: center;
      }
      a,p{
          font-size: 1.2rem;
    line-height: 1.9rem;
    color: #EEEEEE;
    text-transform: capitalize;
    margin-bottom: 0rem !important;
        }
        
      &-right{
        // position: absolute;
        // right: -15%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        
        div{
          // content: "";
          height: 1.5rem;
          max-width: .1rem;
          background-color: $secondary-color-white;
          margin: 0 1rem;
        }
        
      }
      &-left,&-right{
          @media screen and (max-width: 500px) {
            display: flex;
        justify-content: center;
      }
        }
    }
    @media screen and (max-width:580px) {
      position: relative;
            height: 4rem;
      }
}
</style>
