<template>
  <div class="body">
    <HeaderNav />
    <div class="body-section">
      <slot name="breadCrumb" class="breadcrumb">
        
      </slot>

      <div class="body-section__header" >
        <h3 @click="callParentFuntion">
          {{ heading }}
        </h3>
        <p>
          {{ subText }}
        </p>
      </div>
      <div class="body-section__step-tabs">
          <slot name="mainSection">
          </slot>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav";
import Footer from "@/components/Footer";
export default {
  props: {
    heading: {
      type: String,
      default:""
    },
    subText: {
      type: String,
    },
  },
  components: {
    HeaderNav,
    Footer,
  },
  data() {
    return {
      crumbs: [" Grant Application", "businessprofile"],
    };
  },
  methods: {
    selected() {},
    callParentFuntion(){
      this.$emit('callParentFuntion' )
    }
  },
};
</script>
<style lang="scss" scoped>
.body{
    
    .body-section{
        padding:0% 2%;
        &__header {
      margin:.5rem 0; 
      h3 {
        font-size: 2.6rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-color-red;
        cursor: pointer;
      }
      p {
        font-size: 1.2rem;
      }
    }
    @media screen and (max-width: 580px){
      min-height: 90vh;
    }
    }

}
</style>
