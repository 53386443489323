<template>
<div>
  <nav class="navbar">
    <div class="navbar-left">
      <div class="navbar-toggler">
        <button type="button" @click="menuShow = !menuShow">
          <img src="@/assets/imgs/Menu.svg" alt="" />
        </button>
        <div class="vertical-menu-conatiner" >
        <Menu v-if="!menuShow" class="vertical-menu" :menus="customizeMenuItems"  @click="hideMenu()" />
        </div>
      </div>
      <div class="logo">
        <router-link to="/">
          <img src="@\assets\imgs\NUL_Logo_Standalone.png" alt="logo" />
          <img src="@\assets\imgs\Urban-Empowerment-Fund-2020-cmyk@2x.png" alt="logo" />
        </router-link>
        <!-- <a class="navbar-brand" href="#">
        
      </a> -->
      </div>
    </div>
    <div class="navbar-right">
      <div class="dropdown  dropleft">
        <button
          class="btn dropdown-toggle"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          mx-1
        >
          {{ userName }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
          <button class="dropdown-item" type="button" v-if="userRoleID == 2" @click="routeProfile">Profile</button>
          <button class="dropdown-item" type="button" v-if="userRoleId==2" @click="notification()">Notification</button>
          <button class="dropdown-item" type="button" @click="changePassword">
            Change Password
          </button>
          <button class="dropdown-item" type="button" @click="logout">Log Out</button>
        </div>
      </div>
    </div>
  </nav>
  <Notification 
  v-if="isNotification===true"
  @HideNotificationOriginal="HideNotification()" />
</div>
  
</template>
<script>
import Cookies from "js-cookie";
import Menu from "@/components/Menu";
import Notification from '@/components/Notification';
export default {
  components: {
    Menu,
    Notification
  },
  data() {
    return {
      userRoleId:'',
      isNotification:false,
      menuShow: true,
      menuItems: [
        {
          name: "Home",
          linkTo: "/",
        },
        {
          name: "Grant Application",
          linkTo: "/tracks",
        },
        {
          name: "Program Invitations [Beta]",
          linkTo: "/programinvitation",
        },
        {
          name: "Funding Entities",
          linkTo: "/funding",
        },
      ],
      // UserName:""
    };
  },
  computed: {
     userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    userName() {
      return Cookies.get("firstname");
      // return this.$store.getters.getUser
    },
    customizeMenuItems: {
      get() {
        const roleID = this.$store.getters.getUserRoleID;
        if (roleID == 1 || roleID == 3 || roleID == 7 || roleID == 8  || roleID == 5) {
          return [
            {
              name:'Dashboard',
              linkTo:'/dashBoard',
              isSubMenu:false
            },
            {
              name: "Grant Application",
              linkTo: "/tracks",
              isSubMenu:false
            },
            {
              name: "Grantees",
              linkTo: "",
              isSubMenu:true,
              subMenuList:[
                  {
                    name:"Business Entities",
                    linkTo: "/bel"
                  },
                  {
                    name: "Contacts",
                    linkTo: "/businessContacts",
                  }
              ]
            },
            {
              name: "Admin",
              linkTo: "#",
              isSubMenu:true,
              subMenuList:[
                  {
                    name:"Funding Entities/Programs",
                    linkTo: "/funding"
                  },
                  {
                    
                      name: "NUL Contacts",
                      linkTo: "/contacts"
                  },
                  {
                    
                      name: "Affiliates",
                      linkTo: "/affiliates",
                  },
              ]
            },
             {
              name: "Reports",
              linkTo: "#",
              isSubMenu:true,
              subMenuList:[                    
                    {
                      name: "Cumulative Report",
                      linkTo: "/reports",
                    },            
                    {
                      name: "Funding Report",
                      linkTo: "/fundingReport",
                    },
                    {
                      name: "CiviCRM Data export ",
                      linkTo: "/CiviCRM",
                    },
                    {
                      name: "Program Invitations",
                      linkTo: "/programinvitation",
                    },
                ]
            },
          ];
        }else if (roleID == 4) {
          return [
            {
              name:'Dashboard',
              linkTo:'/dashBoard',
            },
            {
              name: "Grant Application",
              linkTo: "/tracks",
            },
            {
              name: "Grantees",
              linkTo: "#",
              isSubMenu:true,
              subMenuList:[                    
                    {
                     name: "Business Entities",
                      linkTo: "/bel",
                    }           
                    
                ]
            }
          ];
        } 
         else if (roleID == 6) {
          return [
            {
              name: "Grant Application",
              linkTo: "/tracks",
              isSubMenu:false
            },
            {
              name: "Grantees",
              linkTo: "",
              isSubMenu:true,
              subMenuList:[                    
                    {
                     name: "Business Entities",
                      linkTo: "/bel",
                    }           
                    
                ]
            }
          ];
        } else if(localStorage.getItem('hasOwnerPermission')=='true') {
          return [
            {
              name: "Apply for grant",
              linkTo: "/",
              isSubMenu:false
            },
            {
              name: "Grant Application",
              linkTo: "/tracks",
              isSubMenu:false
            },
          ];
        }
        else  {
          return [             
            {
              name: "Grant Application",
              linkTo: "/tracks",
              isSubMenu:false
            },
          ];
        }
      },
    },
  },
  created(){
    this.userRoleId = this.$store.getters.getUserRoleID;
  },
  methods: {
    HideNotification(){
      this.isNotification=false;
    },
    routeProfile(){
      this.$store.state.borrower.businessId=0;
      this.$store.state.borrower.isFromProgramInvitationTOProfile=false
      this.$router.push("/profile");
    },
    hideMenu(){
      this.menuShow= !this.menuShow
    },
    menuHeight() {},
    expandedNav() {},
    notification(){
      this.isNotification=true
    },
    changePassword(){
      this.$router.push("/changepassword");
    },
    logout() {
      this.$store
        .dispatch("UpdateLogout")
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          this.$router.push("/");
        });
    },
    profile() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.navbar {
  margin: 0rem;
  height: 7rem;
  // max-height: 7vh;
  // width: 100%;
  padding: 1rem;
  background: $primary-color-white 0% 0% no-repeat;
  box-shadow: 0px 0.3rem 1.2rem #0000001f;
  display: flex;
  justify-content: space-between;
  &-left {
    display: flex;
    align-items: center;
    .navbar-toggler {
       margin: 0 0rem 0 0rem;
      button {
        border: 0.1rem solid transparent;
        outline: none;
        background-color: transparent;
        width: 2.4rem;
        img {
          height: 100%;
        }
      }
    }
    .logo {
      margin: 0 1rem;
      // max-width: 12rem;
      height: 4rem;
      img {
        height: 100%;
        margin-right: 1rem;
      }
       @media screen and (max-width: 500px) {
        height: 3rem;
      }
    }
  }
  .navbar-left {
    padding-left:0;
    @media screen and (max-width: 760px) {
      position: absolute;
      left: 0%;
    }
  }
  .navbar-right {
    padding-right: 0%;
    @media screen and (max-width: 760px) {
      position: absolute;
      right: 0;
    }
    
    .dropdown {
      min-width: 5.5rem;
      height: 3.6rem;
      background: #eceded 0% 0% no-repeat padding-box;
      border-radius: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-color-black;
      button {
        background-color: transparent;
        border: none;
        outline: none;
        box-shadow: none;

        font-weight: bold;
        font-size: 1.2rem;
      }
    }
    :hover {
      background-color: $primary-color-red;
      button {
        color: $primary-color-white;
      }
      .dropdown-menu {
        background: $primary-color-white;
        button {
          color: $primary-color-black;
        }
      }
    }
  }
  .vertical-menu {
    // border: 1px solid red;
    // background-color: pink;
    position: absolute;
    left: 0;
    top: 100%;
    // height: calc((100vh) - (10rem));
    width: 100%;   
    z-index: 2;

  }
}
</style>
