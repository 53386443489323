<template>
  <div class="businessEntity">
    <div style="color: rgb(224, 11, 11);">     
    <h5 v-if="Object.keys(inputs).length != 0 && inputs['id'] &&inputs.id !=0 && deleteFlag==false"><b>! This Business entity cannot be deleted as this is associated with NUL Application.</b></h5>
  <br />
  </div>
    <div class="row">
      <div class=" col form-group">
        <input
          required
          type="text"
          id="businessEntity-businessName"
          placeholder=" "
          v-model="inputs.businessName"
          :disabled=isDisable
          :readonly=isDisable
        />
          
        <label
          class="form__label select-label" for="businessEntity-businessName"
        >
          {{ labels.businessName }}
        </label>
         <Error v-if="v$.inputs.businessName.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.businessName'">
              {{ error.$message }}
            </small>
          </p>
        </Error> 
    
      </div>
    </div>
    <div class="row justify-content-between">
      <div class="col-sm-8 col-12 form-group">
        <select
          class="select-group"
          placeholder=" "
          id = "businessEntity-Affiliate"
          v-model="inputs.affiliateID"
          :disabled=isDisable
          :readonly=isDisable
        >
          <!-- @change="selectOption($event)" -->
             <option v-for="affiliate in affiliates" 
         :key="affiliate.affiliateID" 
         :value="affiliate.affiliateID">
          {{ affiliate.affiliateName }}
            </option>
        </select>

          <label for="businessEntity-Affiliate" class="form__label required">
          {{ labels.affiliate }}
        </label>
        <span class="edit" @click="routeToAffiliate">
          <button>
          <img src="@/assets/imgs/pencil.svg" alt="edit" />
          </button>
        </span>
        <Error v-if="v$.inputs.affiliateID.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.affiliateID'">
              {{ error.$message }}
            </small>
          </p>
        </Error> 
     
      </div>
      <div class=" col-sm-4 col-12 form-group">
        <input
          type="text"
          required
          v-model="inputs.ein"
          placeholder="XX-XXXXXXX"
          id="businessEntity-ein"
          @keyup="formateEIN"
          @blur="formateEIN"
          :disabled=isDisable
          :readonly=isDisable
        />          
              <label for="businessEntity-ein" class="form__label required">
          {{ labels.ein }}
        </label>
          <Error v-if="v$.inputs.ein.$error">
          <p v-for="(error, index) of v$.$errors" :key="index">
            <small v-if="error.$propertyPath === 'inputs.ein'">
              <!-- {{ error.$message }} -->Enter valid EIN
            </small>
          </p>
        </Error> 

  
      </div>
    </div>
    <div class="row d-flex justify-content-sm-end justify-content-center" v-if="(userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)" >
      <span class="" >
         <BtnPrimary 
      :buttonName="`Save`"
      @click="saveBusinessEntity()"
      />
      </span>
       <span class="mx-3" v-if="inputs.id !=0 && deleteFlag==true && (userRoleID == 1|| userRoleID == 3 || userRoleID == 5|| userRoleID == 7)">
          <BtnPrimary :buttonName="`Delete`" @click="confirmation = true" />
        </span>   
    </div> 
         <div class="central-popup" v-if="confirmation == true">
          <div class="central-popup__main p-5">
            <div>
              <span class="central-popup__main__heading">
                <h3 class="mb-5 mt-2">{{ confirmationHeader }}</h3>
              </span>
            </div>
            <div></div>
            <div>
              <div class=" d-flex justify-content-end">
                <div class="mr-5">
                  <span class="pop-btn">
                    <BtnSecondary
                      :buttonName="`Cancel`"
                      @click="confirmation = false"
                    />
                  </span>
                </div>
                <div class="p-0">
                  <span class="pop-btn">
                    <BtnPrimary :buttonName="`Ok`" @click="deleteBusiness()" />
                  </span>
                </div> 
              </div>
            </div>
          </div>
        </div>
        </div>
</template>
<script>
import Error from "@/components/Error.vue"
//import textStore from "@/textStore.js";
import BtnPrimary from "@/components/BtnPrimary.vue";
import useVuelidate from "@vuelidate/core";
import { required ,minLength} from "@vuelidate/validators";
import axios from 'axios';
import BtnSecondary from "@/components/BtnSecondary.vue";
export default {
  components:{
BtnPrimary,
BtnSecondary,
Error
  },
 props: {
    affiliateList: [],
    entity_ID: {
      type: Number,
      default: 0,
    }
  },
    setup() {
          return { 
      v$: useVuelidate(),
      
     };
  },
   validations() {
    return {
      inputs: {
        businessName: { required },
        affiliateID: { required },
        ein: { required ,
        minLength: minLength(10)},
      
      },
    };
  },
  data() {
    return {
      labels: {
         businessName:'Business Name',
        affiliate:'Affiliate',
        ein:'EIN',
        einSetUp:{
        einValue: '',
      formattedeinValue: "",
      preventNextIteration: false,
      },
      },
      //affiliateOptions:['Urban League','NUL',]
      // affiliateList: [],
      confirmation: false, 
      confirmationHeader: "Are you sure, you want to delete ?"
    };
  },
  computed: {
    inputs: {
      
      get() {
        return this.$store.state.business.businessEntityData;   
      },
      
    },
    affiliates: {
      get() {
        return this.affiliateList;
      },
    },
    deleteFlag:{

      get() {
        return this.$store.state.business.canBeDeleted;
         
      },
    },
       userRoleID() {
      return this.$store.getters.getUserRoleID; 
    },
    isDisable: {
      get() {
        if(this.userRoleID ==8){
          return true
        }
        else{
          return false
        }
      },
    
    },
    
  },
  mounted() {
      if (this.entity_ID > 0) {
        this.$store.state.business.businessEntityData={};
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store
          .dispatch("GetBusinessEntity", this.entity_ID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.reject(err);
          });

        this.$store.state.business.users.roles=[{}];
        this.$store.dispatch("fetchBusinessUsersDetails", this.entity_ID, {
          root: true,
        });

        this.$store.state.business.businessusers ={};
        this.$store.state.business.programs.programInvitation=[{}];
        this.$store.dispatch("getBusinessProgramInvitations", this.entity_ID, { root: true,});

         // Clear the store value before Updating Data
      this.$store.state.borrower.businessProfileData.ownersData = [{}];
      this.$store.state.borrower.businessProfileData.businessInputs = {};
      this.$store.dispatch("clearBusinessProfileStore");

        this.$store.dispatch("GetBusinessProfileData", this.entity_ID, { root: true,});
      }
  },
   methods: {
     routeToAffiliate(){
       this.$router.push("/affiliates");
     },
     formateEIN(){
       if (["Arrow", "Backspace", "Shift"].includes(event.key)) {
        this.einSetUp.preventNextIteration = true;
        return;
      }
      if (this.preventNextIteration) {
        this.einSetUp.preventNextIteration = false;
        return;
      }
      let einNumber = this.inputs.ein
        .slice(0,10)
        .replace(/-/g, "")
        .replace(/[^\d,]/g,'')
      // Format display value based on calculated 
      this.inputs.ein = einNumber.replace(
        /(\d{1,2})(\d{1,7})/g,
        "$1-$2"
      );
     },
    async saveBusinessEntity() {

       const isFormCorrect = await this.v$.$validate();
        this.inputs.businessEntityID = this.$store.state.business.businessEntityData.id;
        this.inputs.id = this.$store.state.business.businessEntityData.id;

         if (isFormCorrect) {
           this.$store.dispatch("updateLoaderFlag",true)
          this.$store.dispatch("SaveBusinessEntity", this.inputs)
       
               .then((res) => {
            if(res.data.isSuccess){
              this.$store.dispatch("updateLoaderFlag",false)
              this.toasterMessage('',res.data.message,'success')
              this.$router.push("/programinvitation/form/"+res.data.id+"/"+0);
              this.$store.state.business.businessEntityData.businessEntityID = res.data.id;
              this.$store.dispatch("GetBusinessEntity",res.data.id)
              this.$store.dispatch("fetchBusinessUsersDetails",res.data.id)
              this.$store.dispatch("getBusinessProgramInvitations",res.data.id)
              this.$store.dispatch("GetBusinessProfileData",res.data.id, { root: true,});
              
            }
            else{
              this.$store.dispatch("updateLoaderFlag",false)
              if((res.data.validationErrors!=null || res.data.validationErrors!=undefined) && res.data.validationErrors.length>0)
              {
                res.data.validationErrors.forEach(element => {
                  this.toasterMessage('',element,'danger')
                });
              }
              else{
                this.toasterMessage('','Failed to save business entity','danger')
              }
              this.$store.dispatch("GetBusinessEntity",this.inputs.id)
              this.$store.dispatch("fetchBusinessUsersDetails",this.inputs.id)
              this.$store.dispatch("getBusinessProgramInvitations",this.inputs.id)
              this.$store.dispatch("GetBusinessProfileData", this.inputs.id, { root: true,});
            }
            
            return Promise.resolve(true);
          })   
            
          .catch(() => {
           this.$store.dispatch("updateLoaderFlag",false)
           this.toasterMessage('','Error while processing request','danger')
            return Promise.resolve(false);
          });
         }
          else {
        return Promise.resolve(false);
      }
      
  },

   GetBusinessEntity(entityID) {
      if (entityID > 0) {
        this.$store.dispatch("updateLoaderFlag",true)
        this.$store.dispatch("GetBusinessEntity", entityID)
          .then((res) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.resolve(res);
            
          })
          .catch((err) => {
            this.$store.dispatch("updateLoaderFlag",false)
            Promise.reject(err);
          });
      }
    },

    deleteBusiness() { 
      const businessId= this.inputs.id;
      if (businessId>0) {
        this.$store.dispatch("updateLoaderFlag",true)
        let token = localStorage.getItem("token");
        let config = {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            businessId: parseInt(businessId),
          },
        }; 
        axios.post("Admin/DeleteBusinessEntity", businessId, config).then((res) => {
            if (res.data.responseStatus === 1) {
               this.toasterMessage('','Business Entity deleted successfully.','success');
               this.$store.dispatch("updateLoaderFlag",false)
               this.$router.push("/bel");
            }
            else
            {
               res.data.validationErrors.forEach(element => {
                  this.toasterMessage('',element,'danger')
                });
               this.confirmation= false;
               this.$store.dispatch("updateLoaderFlag",false)
            }
          })
          .catch(() => {
            this.toasterMessage('','Failed to delete business entity','danger');
            this.confirmation= false;
            this.$store.dispatch("updateLoaderFlag",false)
          });
    }

     
    }
  },


};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
.central-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  &__main {
    z-index: 9999;
    padding: 2rem;
    width: auto;
    border: 1px solid gray;
    background: $primary-color-white 0% 0% no-repeat padding-box;
    box-shadow: 0px -6px 12px #00000010;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .pop-btn {
      button {
        padding: 0 2rem;
        min-width: auto;
      }
    }
    &__heading {
      h3 {
        font-size: 2.4rem;
        font-weight: bold;
        color: $primary-color-red;
      }
    }
  }
}
.businessEntity {
  height: 100%;
  overflow-y: auto;
  padding-top: 2rem;
  .row {
    padding-right: 5%;
    margin: 0;
  }
  .form-group {
    padding: 0;
    position: relative;
    @include input-style;
    .edit{
      position: absolute;
    top: 25%;
      button {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
      padding: 0.01rem;
      margin-left: .5rem;
      border: none;
      background-color: transparent;
      img {
        height: 80%;
      }
    }
    }
  }

}
</style>
