<template>
  <div class="table-view">
    <div class="table-view__table">
            <table class="table">
        <thead>
          <tr>
            <th @click="sortColumn('businessName')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="values.currentSort == 'businessName' ? 'show-icon' : ''"
              >
                <p class="tabel-header__title">Business Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortColumn('loanProgramName')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="values.currentSort == 'loanProgramName' ? 'show-icon' : ''"
              >
                <p class="tabel-header__title">Program Name</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <th @click="sortColumn('loanNumber')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="
                  values.currentSort == 'loanNumber' ? 'show-icon' : ''
                "
              >
                <p class="tabel-header__title">Grant Number</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
             <th @click="sortColumn('RequestedFundAmount')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="
                  values.currentSort == 'RequestedFundAmount' ? 'show-icon' : ''
                "
              >
                <p class="tabel-header__title">Funding Amount Requested</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
             <th @click="sortColumn('fundAllocatedAmount')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="
                  values.currentSort == 'fundAllocatedAmount' ? 'show-icon' : ''
                "
              >
                <p class="tabel-header__title">Funds Allocated</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
             <th @click="sortColumn('loanAmount')" class="tabel-header">
              <span
                class="tabel-header__data"
                :class="
                  values.currentSort == 'loanAmount' ? 'show-icon' : ''
                "
              >
                <p class="tabel-header__title">Funds Disbursed</p>
                <span class="tabel-header__icons functional-icons mx-2">
                  <p class="functional-icons__sorting sort-icon">
                    <strong
                      v-if="values.currentSortDir == 'asc'"
                      class=" sort-iif__up"
                      >&#8593;</strong
                    >
                    <strong
                      v-if="values.currentSortDir == 'desc'"
                      class=" sort-icon__down"
                      >&#8595;</strong
                    >
                  </p>
                </span>
              </span>
            </th>
            <!-- <th @click="sort('ein')">
              <span>
              <p>Ein</p> <p>↑↓</p> 
              </span> 
            </th>
            <th @click="sort('affiliateName')">
              <span>
              <p>Affiliate Name</p> <p>↑↓</p> 
              </span></th> -->
          </tr>
        </thead>
        <tbody class="tbody">
          <tr
            v-for="item in displayedPosts"
            :key="item" @click="selectId(item.loanApplicationID)">
                <td  >
              <p v-html="highlightMatches(item['businessName'])"></p>
            </td>
            <td >
              <p v-html="highlightMatches(item['loanProgramName'])"></p>
            </td>
            <td >
              <p v-html="highlightMatches(item['loanNumber'])"></p>
            </td>
             <td >
               <p ><span v-show="item['requestedFundAmount']">$</span> <span v-html="highlightMatches(item['requestedFundAmount'])"></span></p>  
            </td>
             <td >
              <p ><span v-show="item['fundAllocatedAmount']">$</span> <span v-html="highlightMatches(item['fundAllocatedAmount'])"></span></p>  
            </td>
            <td >
              <p ><span v-show="item['loanAmount']">$</span> <span v-html="highlightMatches(item['loanAmount'])"></span></p>  
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-view__footer">
      <div class="pagination">
        <div class="pagination__container">
          <div class="pagination__container__total-pages">
            <p>
              {{ filteredRows.length }}
              {{ filteredRows.length > 1 ? labels.Results : labels.Result }}
            </p>
          </div>
          <div class="pagination__container__row-per-page">
            <span>
              <label>
                {{ labels.Rowsperpage }}
              </label>
              <input
                type="Number"
                :max="perPage"
                min="1"
                readonly
                :value="perPage"
              />
            </span>
          </div>
          <div class="pagination__container__brief">
            <span>
              <p>{{ values.currentPage }}</p>
              <p>{{ labels.of }}</p>
              <p>{{ values.totalPages }}</p>
              <p>{{ labels.pages }}</p>
            </span>
          </div>
          <div class="pagination__container__inc-dec">
            <button @click="backPage()">
              &#60;
            </button>
            <p>{{ values.currentPage }}</p>
            <button @click="nextPage()">
              &#62;
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
    
  props: {
    dataList: {
      required: true,
      type: Array,
    },
    businessNameList:{
        required:true,
        type:Array
    }
    
  },
 /*created() {
    this.getConsolidatedFundReportDataList();
  },*/
   components: {
    },
  data() {
    return {
        programList:[],
         testData: [],
        items:[],
        perPage: 10,
       
      filter: "",
      unsort: false,
      list: this.dataList,
      selectOptionToFilter: "all",
      isClearSort: false,
      labels: {
        ShowingResults: "Showing Results",
        ShowFilters: "Show Filters",
        Results: "Results",
        Result: "Result",
        Rowsperpage: "Rows per page:",
        pages: "Pages",
        of: "of",
      },
      values: {
        pages: [],
        totalPages: 1,
        currentPage: 1,
        currentSort: "",
        currentSortDir: "asc",
        isSearchByAll: true,
      },
      inputs: {
        programId: 0,
        businessId:0
      },
      businessNames:[],
      programNames:[]
    };
    
  },
  beforeUpdate() {
    this.items = this.dataList;
    this.list = this.items;
  },
  mounted() {
    
   
  },
  methods: {
    clearFilter() {
      this.filter = "";
    },
     
    async getConsolidatedFundReportDataList() {
      this.$store.dispatch("updateLoaderFlag",true)
      let programInvitations = [];
       let businessEntitydata = [];
       //programInvitations[0] = 0;
       businessEntitydata[0] = 0;
       businessEntitydata.push(this.inputs.fundingEntityId == undefined ? 0 :this.inputs.fundingEntityId );
        programInvitations = this.showCheckedData.map((item) => {
            return item.programId;
        });
        if(programInvitations.length<=0){
            programInvitations= [0]
            }
       let param = {
        BusinessEntityID: businessEntitydata,
        programID:   programInvitations,
      };
      await this.$store
        .dispatch("getConsolidatedFundReportData",  param, {root: true,})
        .then((res) => {
          this.list = res.data.consolidatedFundDetails;
          this.businessNames = res.data.businessEntities;
          this.programNames = res.data.programs;
          console.log(res.data);
          this.$store.dispatch("updateLoaderFlag",false)
        })
        .catch((err) => {
          this.$store.dispatch("updateLoaderFlag",false)
          Promise.reject(err);
        });
    },
   
    affiliateNameList() {
      let affiliateName = [
        ...new Set(this.list.map((item) => item.affiliateName)),
      ];
      return affiliateName.sort();
    },
    ResetSort() {
      this.filter = "";
      this.values.currentSort = "";
      this.selectOptionToFilter = "all";
      this.unsort = true;
    },
    highlightMatches(text) {
        if(text == null)
         return text;
      const matchExists = text.toLowerCase().includes(this.filter);
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return text.replace(
        re,
        (matchedText) => `<strong>${matchedText}</strong>`
      );
    },
    selectId(entityID) {
      this.$emit("selectRow", entityID);
    },
    sortColumn(s) {
      this.unsort = false;
      if (s === this.values.currentSort) {
        this.values.currentSortDir =
          this.values.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.values.currentSort = s;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.filteredRows.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.values.pages.push(index);
      }
    },
    paginate(posts) {
      let page = this.values.currentPage;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      this.values.totalPages = Math.ceil(
        this.filteredRows.length / this.perPage
      );
      if (this.values.totalPages === 0) {
        this.values.totalPages = 1;
      }
      return posts.slice(from, to);
    },
    backPage() {
      if (this.values.currentPage > 1) {
        this.values.currentPage--;
      }
    },
    nextPage() {
      if (this.values.currentPage < this.values.totalPages) {
        this.values.currentPage++;
      }
    },
  },
  computed: {
     fundingEntities()
    {
        console.log("test"+this.$store.state.business.temporary.fundingEntity)
          return this.$store.state.business.temporary.fundingEntity;
    },
    programs: {
      get() {
        return this.$store.state.business.temporary.programInvitation;
      }
    },
    setTableHeight() {
      return "height: " + this.TableHeight + "vh";
    },
    sortTable() {
      let lists = this.list;
      let data;
      if (this.unsort === true) {
        return lists;
      }
      data = lists.slice().sort((a, b) => {
        let modifier = 1;
        if (this.values.currentSortDir === "desc") modifier = -1;
        if (a[this.values.currentSort] < b[this.values.currentSort])
          return -1 * modifier;
        if (a[this.values.currentSort] > b[this.values.currentSort])
          return 1 * modifier;
        return 0;
      });
      return data;
    },
    filteredRows() {
      return this.sortTable.filter((row) => {
        /*const businessName = row.businessName.toString().toLowerCase();
        const ein = row.ein.toLowerCase();
        const affiliateName = row.affiliateName.toLowerCase();
        const searchTerm = this.filter.toLowerCase();
        let filtedItems;
        if (this.selectOptionToFilter == "all") {
          filtedItems =
            businessName.includes(searchTerm) ||
            ein.includes(searchTerm) ||
            affiliateName.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "businessName") {
          filtedItems = businessName.includes(searchTerm); //|| ein.includes(searchTerm) || affiliateName.includes(searchTerm)
        }
        if (this.selectOptionToFilter == "ein") {
          filtedItems = ein.includes(searchTerm);
        }
        if (this.selectOptionToFilter == "affiliateName") {
          filtedItems = affiliateName.includes(searchTerm);
        }*/
        return row;//filtedItems;
      });
    },
    displayedPosts() {
      return this.paginate(this.filteredRows);
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
@include table-style;
  
</style>